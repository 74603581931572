import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WeatherResults } from '../models/weather-response-model';
/*
openweathermap.org
api key: b92581fceaaabca90e4f5fa91b3e177f
Limits
Hourly forecast: unavailable
Daily forecast: unavailable
Calls per minute: 60
3 hour forecast: 5 days
*/
const openweathermapApiKey = "b92581fceaaabca90e4f5fa91b3e177f";


@Injectable()
export class WeatherService {

  constructor(public httpClient: HttpClient) { }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }


  getWeather(): Promise<WeatherResults> {
    return new Promise((resolve, reject) => {
      var position = {};
      this.httpClient.get(this.getWeatherEndPoint(position)).subscribe(weatherResults => resolve(weatherResults as WeatherResults))
    })
  }

  getWeatherEndPoint(position): any {

    position.lat = 41.015137;
    position.lng = 28.979530;

    return `http://api.openweathermap.org/data/2.5/onecall?lat=${position.lat}&lon=${position.lng}&exclude=hourly,minutely,alerts&appid=${openweathermapApiKey}`
  }
}