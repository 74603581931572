import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Customer, Representative } from 'src/app/demo/domain/customer';
import { Product } from 'src/app/demo/domain/product';
import { CustomerService } from 'src/app/demo/service/customerservice';
import { ProductService } from 'src/app/demo/service/productservice';
import { Activity } from 'src/app/models/activity.model';
import { Company } from 'src/app/models/company.model';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { NewFolderDialogComponent } from 'src/app/pages/document/modals/new-folder-dialog/new-folder-dialog.component';
import { ActivityService } from 'src/app/services/activity.service';
import { CompanyService } from 'src/app/services/company.service';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-list-offer-request',
  templateUrl: './list-offer-request.component.html',
  styleUrls: ['./list-offer-request.component.scss']
})
export class ListOfferRequestComponent implements OnInit {

  filteredSeaport: SeaportMinimizedModel[] = [];
  seaport: SeaportMinimizedModel[] = [];

  customers1: Customer[];

  customers2: Customer[];

  customers3: Customer[];

  selectedActivities: Activity[];

  selectedCustomer: Customer;

  representatives: Representative[];

  statuses: any[];

  seaGoodReferenceNo: string;

  products: Product[];

  rowGroupMetadata: any;

  activityValues: number[] = [0, 100];

  activityList: Activity[] = [];

  @ViewChild('dt') table: Table;

  subTypes: any[];
  selectedSubTypes: any;
  subType: String;
  etdStart: Date = new Date();
  etdEnd: Date;
  fromPort : SeaportMinimizedModel;
  toPort : SeaportMinimizedModel;
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  items: MenuItem[];
  shipOwner: Company;
  customer: Company;
  transportDirection: "I";
  isLoading = false;
    rememberLastSearch: any;
    privousPage = 0;
  statusText = {
    'pricing': 'Bekleniyor',
    'pendingConfirm': 'Fiyatlandırma Yapıldı',
    'confirmed': 'Rezervasyon',
    'accepted': 'Kabul',
    'rejected': 'Red',
    'revisionRequest': 'Revizyon',
    }

    startDate: Date;
    endDate: Date;
  constructor(private customerService: CustomerService, private messageService: MessageService, private datePipe: DatePipe,  private productService: ProductService, private activityService: ActivityService, private router: Router, private offerExportService: OfferExportService, private companyService: CompanyService) {
    let date = new Date();
    this.etdStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.etdEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  ngOnInit() {
    this.customerService.getCustomersLarge().then(customers => {
      this.customers1 = customers;
      // @ts-ignore
      this.customers1.forEach(customer => customer.date = new Date(customer.date));
    });
    this.customerService.getCustomersMedium().then(customers => this.customers2 = customers);
    this.customerService.getCustomersMedium().then(customers => this.customers3 = customers);
    this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    this.rememberLastSearch = JSON.parse(sessionStorage.getItem('seaImportSearchList'));
    if (this.rememberLastSearch != null) {
          this.activityList = this.rememberLastSearch;
          this.privousPage = +JSON.parse(sessionStorage.getItem('seaImportSearchListPrivousPage'));
      }

  }

  onSearch(subType, onlyReservation = false) {
    this.activityList = [];
    this.isLoading = true;
    if(!this.etdStart || !this.etdEnd) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Uyarı', detail: 'Lütfen tarih aralığı giriniz!' });
      this.isLoading = false;
      return;
    }

    this.activityService.getWithParams(subType, this.datePipe.transform(this.etdStart, 'yyyy-MM-dd'), this.datePipe.transform(this.etdEnd, 'yyyy-MM-dd'), (this.fromPort?.id || null), (this.toPort?.id || null), (this.shipOwner?.id || null), (this.customer?.id || null), 'SEA', onlyReservation, this.seaGoodReferenceNo, null , 'I', 'I').subscribe(res => {
        this.privousPage = 0;
        // @ts-ignore
        this.activityList = res.sort((act1, act2) => new Date(act2.createDate) - new Date(act1.createDate))
            .sort((act1, act2) => {
                // @ts-ignore
                if (new Date(act1.createDate) - new Date(act2.createDate) > 0) {
                    return -1;
                    // @ts-ignore
                } else if (new Date(act1.createDate) - new Date(act2.createDate) < 0) {
                    return 1;
                } else if (act1.name > act2.name) {
                    return -1;
                }
                return 0;
            });
        sessionStorage.setItem('seaImportSearchList', JSON.stringify(this.activityList));
      this.isLoading = false;
    }, error => {
        this.isLoading = false;
    });
  }
  onSort() {
    this.updateRowGroupMetaData();
  }


  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.customers3) {
      for (let i = 0; i < this.customers3.length; i++) {
        const rowData = this.customers3[i];
        const representativeName = rowData.representative.name;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          const previousRowData = this.customers3[i - 1];
          const previousRowGroup = previousRowData.representative.name;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          }
          else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  goDetails(activity: Activity) {
      sessionStorage.setItem('seaImportSearchListPrivousPage', this.table.first.toString());
    this.router.navigate([`activities/offer-request/activity/${activity.id}`]);
  }

  filterSeaport(event) {
    if (event.query.length < 3) return;

    this.offerExportService.getOfferExportSeaport(event.query).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }

    savePrivousPage() {
        sessionStorage.setItem('seaImportSearchListPrivousPage', this.table.first.toString());
    }
}
