import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Leave } from 'src/app/models/leave-models/leave.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';
import { IntranetLeaveService } from 'src/app/services/leave-service';

@Component({
  selector: 'app-leave-approval',
  templateUrl: './leave-approval.component.html',
  styleUrls: ['./leave-approval.component.scss']
})
export class LeaveApprovalComponent implements OnInit {

  model: Leave = <Leave>{};

  leaveTypes: any[] = [];

  selectedLeaveTypes: any;

  advanceUseDate: Date = new Date();

  userLeaveClaimDays: number = 0;

  userAvailableLeaveClaimDays: number = 0;

  canBeUseLeaves: number = 0;

  leaveStartDate: Date = new Date();

  jobBeginDate: Date = new Date();

  leavesDuration: number = 0;

  leaveId: number;

  user: any = <any>{};

  username: string = "";

  constructor(private messageService: MessageService, private advanceService: IntranetAdvanceService, private leaveService: IntranetLeaveService, private route: ActivatedRoute, private router: Router) {


    this.route.params.subscribe(params => {
      this.leaveId = params['id'];

      this.leaveService.get(this.leaveId).subscribe(res => {

        this.model = res;

        this.leaveTypes.push({
          label: 'İzin Tipi Seçiniz',
          value: 0
        })

        this.leaveTypes.push({
          label: 'Yıllık Ücretli İzin',
          value: 1
        })

        this.leaveTypes.push({
          label: 'Ücretsiz İzin',
          value: 2
        })

        this.leaveTypes.push({
          label: 'Diğer İzin',
          value: 3
        })

        this.leaveTypes.push({
          label: 'Doğum İzni',
          value: 4
        })

        this.leaveTypes.push({
          label: 'Mazeret İzni',
          value: 4
        })

        this.leaveService.getUserById(this.model.userId).subscribe(res => {
          this.user = res;
          console.log(res);
          this.username = this.user.firstName + " " + this.user.lastName;
        })

        this.leaveTypes.forEach(item => {

          if (this.model.leaveType == item.value) {
            this.selectedLeaveTypes = item;
          }

        })

      })


    });

  }

  ngOnInit(): void {



    this.leaveService.calculateUserLeaveClaims().subscribe(res => {
      this.userLeaveClaimDays = res;
    })

    this.leaveService.calculateAvailableLeaveClaims().subscribe(res => {
      this.userAvailableLeaveClaimDays = res;
    })


  }

  dateChange(event: any) {

    this.leaveService.totalBusinessDaysBetween(this.leaveStartDate.getTime(), this.jobBeginDate.getTime()).subscribe(res => {
      this.leavesDuration = res;
    })

  }

  isLoading = false;

  approve() {


    this.model.status = 1;

    this.leaveService.approve(this.model.id).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Onaylandı' });


      setTimeout(() => {
        this.router.navigate(['/leave/get-all-leaves'])
      }, 1500)

    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

  cancel() {


    this.model.status = 2;

    this.leaveService.disApprove(this.model.id).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Reddedildi' });
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

}
