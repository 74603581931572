import { Company } from "./company.model";
import { Equimenet } from "./equiment.model";
import { SeaPort } from "./seaport.model";
import {CountryMinimizedModel} from "./country-minimized.model";
import {CityMinimizedModel} from "./city-minimized.model";
import {DistrictMinimizedModel} from "./district-minimized.model";
import {OfferPackMobileModel} from "./offer-pack-mobile.model";
import {SimpleEntity} from "./simple-entity.model";

export class Activity {

    id?: number;
    offerId?: number;
    name?: string;
    customer?: Company;
    abroadAgent?: Company;
    operationType?: string;
    transportDirection?: string;
    airlines?: any[];
    seaAllocations?: any[];
    type?: string;
    subType?: string;
    stackableType?: any;
    reversibleType?: any;
    offerStatus?: string;
    sender?: Company;
    estimatedAmountCurrency?: any;
    estimatedAmount?: any;
    etd?: string;
    startDate?: string;
    endDate?: string;
    validTo?: string;
    goodDefination?: string;
    goodDefinationType?: any;
    shipOwner?: Company;
    portFilling?: boolean;
    salesRepresentative?:any;
    shipOwnerNotShow?: boolean;
    airlineNotShow?: boolean;
    fak?: string;
    quantity?: number;
    packing?: string;
    grossWeight?: number;
    revisedChargeableWeight?: number;
    volume?: number;
    offerPacks?: OfferPackMobileModel[];
    freightPayment?: string;
    imo?: string;
    classInfo?: string;
    unNo?: number;
    flashPoint?: string;
    equipments?: Equimenet[];
    truckBodyType?: SimpleEntity;
    delivery?: string;
    notes?: string;
    partialFull?: string;
    incoterm?: string;
    shipmentUnit?: string;
    toSeaPort?: Company;
    fromSeaPort?: Company;
    fromAirPort?: Company;
    toAirPort?: Company;
    offerType?: string;
    whoseBusiness?: string;
    loading?: string;
    loadCountry?: CountryMinimizedModel;
    loadCity?: CityMinimizedModel;
    loadDistrict?: DistrictMinimizedModel;
    loadZip?: string;
    loadAddress?: string;
    unloading?: string;
    unloadCountry?: CountryMinimizedModel;
    unloadCity?: CityMinimizedModel;
    unloadDistrict?: DistrictMinimizedModel;
    unloadZip?: string;
    unloadAddress?: string;
    loadWareHouse?: SimpleEntity;
    unloadWareHouse?: SimpleEntity;
    loadPlace?: string;
    unloadPlace?: string;
    serviceCode?: string;
    isPricing?: boolean;
    pricingPerson?: SimpleEntity;
    seaFrightBuyTariffDetMobiles?: any[];
    airFrightBuyTariffDetMobiles?: any[];
    incoOfferAirDTOs?: any[];
    incoOfferTruckDTOs?: any[];
    frontEndTariffDetMobiles?: any[];
    frontEndTariffDetDTOs?: any[];
    activitySeaFullEditDTO?: any;
    activitySeaFullImportEditDTO?: any;
    seaFrightRequestDetMobiles?: any;
}
