import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { IntranetPageService } from './services/intranet-page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from "rxjs";
import { ChangeLanguageService } from "./services/change-language.service";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: any[];
    languageChanged: Subscription;

    menuItems: any[] = [];

    newMenuItems: any[] = [];

    firm: any;

    constructor(public appMain: AppMainComponent, private intranetPageService: IntranetPageService, private t: TranslateService, private changeLanguageService: ChangeLanguageService) { }

    ngOnInit() {
        this.languageChanged = this.changeLanguageService.languageChanged.subscribe(() => {
            this.navbarComponents();
        });
        //this.navbarComponents();
        this.menuItems = JSON.parse(localStorage.getItem("menus"));



        this.menuItems.forEach(upper => {

            var menuItems = [];

            upper.items.forEach(lower => {

                var lowerItem = { label: lower.label, icon: 'pi pi-bell', routerLink: [lower.path], class: 'rotated-icon' };
                menuItems.push(lowerItem);

            })

            var newItem = {
                label: upper.label, icon: 'pi pi-sitemap', routerLink: [upper.path],
                items: menuItems
            };

            this.newMenuItems.push(newItem);

        })

        this.addIcons(this.menuItems);
        this.firm = sessionStorage.getItem('firm');
    }

    onMenuClick() {
        this.appMain.menuClick = true;
    }

    navbarComponents() {
        this.t.get(['']).subscribe(() => {
            this.model = [
                { label: this.t.instant('navbar.homepage'), icon: 'pi pi-home', routerLink: ['/'] },

                {
                    label: this.t.instant('navbar.myCompany'), icon: 'pi pi-sitemap', routerLink: ['/mycompany'],
                    items: [
                        { label: 'Yeni Duyuru', icon: 'pi pi-bell', routerLink: ['/company/announcement/create'], class: 'rotated-icon' },
                        { label: 'Duyurular', icon: 'pi pi-bell', routerLink: ['/company/announcement/list'], class: 'rotated-icon' },
                        {
                            label: this.t.instant('navbar.target'), icon: 'pi pi-send', routerLink: ['/kpi/create-kpi-target']
                        },
                        { label: 'Widget Ayarları', icon: 'pi pi-id-card', routerLink: ['/settings/widgets'] },
                    ]
                },
                {
                    label: this.t.instant('navbar.activities'), icon: 'pi pi-star-o', routerLink: ['/activities'],
                    items: [
                        { label: 'Teklif Talebi', icon: 'pi pi-id-card', routerLink: ['/activities/offer-request'] },
                        { label: 'Deniz İhracaat Teklif Listesi', icon: 'pi pi-id-card', routerLink: ['activities/offer/list'] },
                        { label: 'Deniz İhracaat Teklif Talebi', icon: 'pi pi-id-card', routerLink: ['/activities/offer/create'] },
                        { label: 'Tekliflerim', icon: 'pi pi-id-card', routerLink: ['/activities/list-offer-request'] },
                        { label: 'Allokasyonlar', icon: 'pi pi-id-card', routerLink: ['/activities/list-allocation'] },
                    ]
                },
                {
                    label: this.t.instant('navbar.questionnaire'), icon: 'pi pi-sitemap', routerLink: ['/survey'],
                    items: [
                        { label: 'Yeni Anket', icon: 'pi pi-bell', routerLink: ['/survey/create'], class: 'rotated-icon' },
                        { label: 'Anketler', icon: 'pi pi-bell', routerLink: ['/survey/list'], class: 'rotated-icon' },
                    ]
                },
                {
                    label: this.t.instant('navbar.manageFiles'), icon: 'pi pi-sitemap', routerLink: ['/document'],
                    items: [
                        { label: 'Dökümanlar', icon: 'pi pi-bell', routerLink: ['/documents'], class: 'rotated-icon' },
                    ]
                },
                {
                    label: 'Talep İşlemleri', icon: 'pi pi-sitemap', routerLink: ['/advance'],
                    items: [
                        { label: 'Yeni İzin Talebi', icon: 'pi pi-bell', routerLink: ['/leave/create-leave'], class: 'rotated-icon' },
                        { label: 'İzin Taleplerim', icon: 'pi pi-bell', routerLink: ['/leave/get-my-leaves'], class: 'rotated-icon' },
                        { label: 'İzin Talepleri (Yönetici)', icon: 'pi pi-bell', routerLink: ['/leave/get-all-leaves'], class: 'rotated-icon' },
                        { label: 'Yeni Avans Talebi', icon: 'pi pi-bell', routerLink: ['/advance/create'], class: 'rotated-icon' },
                        { label: 'Avans Taleplerim', icon: 'pi pi-bell', routerLink: ['/advance/getMyAdvances'], class: 'rotated-icon' },
                        { label: 'Onay Bekleyen Avanslar', icon: 'pi pi-bell', routerLink: ['/advance/getApprovalAdvances'], class: 'rotated-icon' },
                        { label: 'Masraf Kategorileri', icon: 'pi pi-bell', routerLink: ['/expense/expense-categories'], class: 'rotated-icon' },
                        { label: 'Yeni Masraf Talebi', icon: 'pi pi-bell', routerLink: ['/expense/create-expense-package'], class: 'rotated-icon' },
                        { label: 'Masraf Taleplerim', icon: 'pi pi-bell', routerLink: ['/expense/get-my-expences'], class: 'rotated-icon' },
                        { label: 'Onay Bekleyen Masraflar', icon: 'pi pi-bell', routerLink: ['/expense/approval-expense-packages'], class: 'rotated-icon' },
                    ]
                },
                {
                    label: 'Görev Yönetimi', icon: 'pi pi-sitemap', routerLink: ['/issue'],
                    items: [
                        { label: 'Boards', icon: 'pi pi-bell', routerLink: ['/issue-management/boards'], class: 'rotated-icon' },
                        { label: 'Issues', icon: 'pi pi-bell', routerLink: ['/issue-management/issues'], class: 'rotated-icon' },
                        { label: 'Kanban Boards', icon: 'pi pi-bell', routerLink: ['/issue-management/kanban-board'], class: 'rotated-icon' },
                    ]
                },
                {
                    label: 'Learning', icon: 'pi pi-sitemap', routerLink: ['/learning'],
                    items: [
                        { label: 'Yen Ders Kategorisi', icon: 'pi pi-bell', routerLink: ['/learning/create-lesson-category'], class: 'rotated-icon' },
                        { label: 'Ders Kategorileri', icon: 'pi pi-bell', routerLink: ['/learning/list-lesson-category'], class: 'rotated-icon' },
                        { label: 'Yeni Ders', icon: 'pi pi-bell', routerLink: ['/learning/create-lesson'], class: 'rotated-icon' },
                        { label: 'Ders Listesi (Yönetim)', icon: 'pi pi-bell', routerLink: ['/learning/list-lesson'], class: 'rotated-icon' },
                        { label: 'Derslerim', icon: 'pi pi-bell', routerLink: ['/learning/list-lesson-watcher'], class: 'rotated-icon' },
                    ]
                },
            ];
            this.intranetPageService.getSimpleList().subscribe(res => {

                console.log(res);

                var menuItem = {
                    label: this.t.instant('navbar.corporate'), icon: 'pi pi-sitemap', routerLink: ['/corporate'],
                    items: [
                    ]
                };

                var items: any[] = [];

                var createPage = { label: "Yeni Sayfa", icon: 'pi pi-id-card', routerLink: ['/pages/create-page'] };

                items.push(createPage);

                res.forEach(item => {
                    var newItem = { label: item.pageName, icon: 'pi pi-id-card', routerLink: ['/pages/' + item.id] };
                    items.push(newItem);
                })

                menuItem.items = items;

                this.model.push(menuItem);


            });
        });
    }

    addIcons(menuItems: any[]) {
        for (let menuItem of menuItems) {
            if (menuItem.path.endsWith("biopharma")) {
                menuItem.icon = "fas fa-flask";
            } else if (menuItem.path.endsWith("account")) {
                menuItem.icon = "fas fa-book-open";
            } else if (menuItem.path.endsWith("ecargo")) {
                menuItem.icon = "fas fa-truck-pickup";
            } else if (menuItem.path.endsWith("shipunload")) {
                menuItem.icon = "fas fa-dolly";
            } else if (menuItem.path.endsWith("rail")) {
                menuItem.icon = "fas fa-train";
            } else if (menuItem.path.endsWith("auto")) {
                menuItem.icon = "fas fa-car";
            } else if (menuItem.path.endsWith("repairManagement")) {
                menuItem.icon = "fas fa-tools";
            } else if (menuItem.path.endsWith("bpm")) {
                menuItem.icon = "fas fa-code-branch";
            } else if (menuItem.path.endsWith("salesManagement")) {
                menuItem.icon = "fas fa-shopping-cart";
            } else if (menuItem.path.endsWith("orDelManagement")) {
                menuItem.icon = "fas fa-store";
            } else if (menuItem.path.endsWith("containerManagement")) {
                menuItem.icon = "fas fa-box";
            } else if (menuItem.path.endsWith("containerTransportation")) {
                menuItem.icon = "fas fa-boxes";
            } else if (menuItem.path.endsWith("domesticDistribution")) {
                menuItem.icon = "fas fa-people-carry";
            } else if (menuItem.path.endsWith("orderManagement")) {
                menuItem.icon = "fas fa-globe";
            } else if (menuItem.path.endsWith("truck")) {
                menuItem.icon = "fas fa-truck-moving";
            } else if (menuItem.path.endsWith("inland")) {
                menuItem.icon = "fas fa-truck";
            } else if (menuItem.path.endsWith("air")) {
                menuItem.icon = "fas fa-plane";
            } else if (menuItem.path.endsWith("sea")) {
                menuItem.icon = "fas fa-ship";
            } else if (menuItem.path.endsWith("marketting")) {
                menuItem.icon = "fas fa-chart-line";
            } else if (menuItem.path.endsWith("finance")) {
                menuItem.icon = "fas fa-chart-bar";
            } else if (menuItem.path.endsWith("fleetmanagement")) {
                menuItem.icon = "fas fa-truck-loading";
            } else if (menuItem.path.endsWith("integ")) {
                menuItem.icon = "fas fa-cogs";
            } else if (menuItem.path.endsWith("document")) {
                menuItem.icon = "fas fa-book";
            } else if (menuItem.path.endsWith("stock")) {
                menuItem.icon = "fas fa-cubes";
            } else if (menuItem.path.endsWith("manage")) {
                menuItem.icon = "fas fa-tasks";
            } else if (menuItem.path.endsWith("crm")) {
                menuItem.icon = "fas fa-shipping-fast";
            } else if (menuItem.path.endsWith("arm")) {
                menuItem.icon = "fas fa-building";
            } else if (menuItem.path.endsWith("preOnCarriage")) {
                menuItem.icon = "fas fa-box-open";
            } else if (menuItem.path.endsWith("customer")) {
                menuItem.icon = "fas fa-users";
            } else if (menuItem.path.endsWith("dashboard")) {
                menuItem.icon = "fas fa-th";
            } else if (menuItem.path.endsWith("facilityManagement")) {
                menuItem.icon = "fas fa-industry";
            } else if (menuItem.path.endsWith("cwh")) {
                menuItem.icon = "fas fa-warehouse";
            } else if (menuItem.path.endsWith("pickingAndDelivery")) {
                menuItem.icon = "fas fa-people-carry";
            } else if (menuItem.path.endsWith("wh")) {
                menuItem.icon = "fas fa-warehouse";
            } else if (menuItem.path.endsWith("fuelManagement")) {
                menuItem.icon = "fas fa-gas-pump";
            } else if (menuItem.path.endsWith("chartering")) {
                menuItem.icon = "fas fa-ship";
            }

            var links = [];
            links.push(menuItem.path);
            menuItem.routerLink = links;

        }
        return menuItems;
    }

    ngOnDestroy() {
        this.languageChanged.unsubscribe();
    }


}
