import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Announcement } from 'src/app/models/announcement.model';
import { Career } from 'src/app/models/career.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { CareerService } from 'src/app/services/career.service';

@Component({
  selector: 'app-create-career',
  templateUrl: './create-career.component.html',
  styleUrls: ['./create-career.component.scss']
})
export class CreateCareerComponent implements OnInit {

  editorText: string;

  entity: Career = <Career>{};

  items: MenuItem[];

  home: MenuItem;

  constructor(private messageService: MessageService, private careerService: CareerService,
    private announcementService: AnnouncementService) { }

  ngOnInit(): void {

    this.items = [
      { label: 'Yeni İlan Oluştur' },
    ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };

    var user = JSON.parse(localStorage.getItem("user"));

  }

  isLoading = false;

  save() {

    this.entity.description = this.editorText;

    this.isLoading = true;

    this.careerService.create(this.entity).subscribe(res => {
      this.isLoading = false;


      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "İş İlanı Başarıyla Kaydedildi" });
    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })


  }

}
