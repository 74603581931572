import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Announcement } from '../models/announcement.model';
import { KpiDefinition } from '../models/kpi-definition.model';
import { KpiGroupDetail } from '../models/kpi-group-detail.model';
import { KpiTarget } from '../models/kpi-target.model';
import { Person } from '../models/person.model';
import { Staff } from '../models/staff.model';
import { UpdateKpiGroupRequestModel } from '../models/update-kpi-group-request.model';
import { UserGroup } from '../models/user-group.model';
import { BaseService } from './base.service';


@Injectable()
export class StaffService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'staff',
            httpClient);
    }


    getUpcomingBirthdays(upcomingDay: number, limit: number): Observable<Staff[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getUpcomingBirthdays?token=" + token + "&sessionId=" + sessionId + "&upcomingDay=" + upcomingDay + "&limit=" + limit;

        return this.httpClient.get<Staff[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}