import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { CompanyService } from 'src/app/services/company.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ActivityDTOModel } from 'src/app/models/activity-dto.model';
import { ActivityTableDialogComponent } from '../activity-table-dialog/activity-table-dialog.component';
import { OfferTableDialogComponent } from '../offer-table-dialog/offer-table-dialog.component';
import { GoodTableDialogComponent } from '../good-table-dialog/good-table-dialog.component';
import { MinimizedOfferModel } from 'src/app/models/minimized-offer.model';
import { CompanyCRMDTO } from 'src/app/models/company-crm-dto';
import { CompanyMobileDTO } from 'src/app/models/company-mobile-dto.model';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { DistrictMinimizedModel } from 'src/app/models/district-minimized.model';
import { CityMinimizedModel } from 'src/app/models/city-minimized.model';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { UserService } from 'src/app/services/user.service';
import EntityUtil from '../../../models/framework/utils/entity-util';
import { CompanyFinancialMobileDTO } from "../../../models/company-financial-mobile-dto";
import { CustomerRepresentativeModel } from "../../../models/customer-representative-model";
import { CountryMinimizedModel } from "../../../models/country-minimized.model";
import { Person } from "../../../models/person.model";
import { Incoterms } from 'src/app/models/enums/incoterms';
import { CompanyActivity } from 'src/app/models/company-activity.model';
import { Invoice } from 'src/app/models/invoice.model';
import { ListService } from 'src/app/services/list.service.service';
import { Representative } from 'src/app/models/activity/representative';
import { FinancialQueryService } from 'src/app/services/financial-query/financial-query.service';
import { FinancialQueryModel } from 'src/app/models/financial-query/financial-query.model';
import b64toBlob from 'b64-to-blob';
import { SelectBoxItem } from 'src/app/models/select-box-item.model';
import { SelectboxService } from 'src/app/services/selectbox.service';
import {InfoManagerService} from '../../../services/info-manager.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  @ViewChild('fubauto') uploader: any;

  transportDirectionOptions: any[] = [{ label: 'KTL', value: 'I' }, { label: 'KINAY', value: 'E' }];
  selectedTransportDirection: string = null;
  userTransportDirection: string = "";
  sourceList: SelectBoxItem[] = [];

  company: CompanyMobileDTO = new CompanyMobileDTO();
  companyActivities: ActivityDTOModel[] = [];
  isLoading: boolean = false;

  taxOffices: SimpleEntity[] = [];
  filteredTaxOffices: SimpleEntity[] = [];

  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  district: DistrictMinimizedModel[] = [];

  filteredCityMinimized: CityMinimizedModel[] = [];
  city: CityMinimizedModel[] = [];

  filteredCountryMinimized: CountryMinimizedModel[] = [];
  country: CountryMinimizedModel[] = [];

  financialQuery: FinancialQueryModel[] = [];

  financialList: any[] = [];
  visibleFinancialDialog: boolean = false;

  customerRepresentatives: any[] = [];
  visibleCustomerRepresentativesDialog: boolean = false;

  companyPerson: any[] = [];
  visiblePersonDialog: boolean = false;

  companyActivity: CompanyActivity;
  invoices: Invoice[] = [];
  visibleCompanyActivityDialog: boolean = false;

  filteredUsers: any[] = [];
  sessionUser: any = null;

  filteredMaster: SimpleEntity[] = [];

  companyTypes: [] = [];
  industries: [] = [];

  requiredField = [{ field: 'country', label: 'Ülke' }, { field: 'city', label: 'Şehir' }, { field: 'name', label: 'Adı' }]

  lastActivityWithOffer: ActivityDTOModel = null;
  lastActicityWithOfferRepresentatives: Representative[] = [];
  lastActivity: ActivityDTOModel = null;
  lastActicityRepresentatives: Representative[] = [];

  digitalStore: any = [];
  infoManagers: any = [];

  infoManagerDefId: number;
  infoManagerDefs: any[] = [];
  networks: any[] = [];
  infoManagerDescription: string;

  incoterms: string[] = Object.values(Incoterms)
  //TODO: Backend den gelen data localStorage a yazılacak
  paymentTypes: any[] = [
    {
      name: 'Cari',
      code: 'Cari'
    },
    {
      name: 'Çek',
      code: 'Çek'
    },
    {
      name: 'Peşin',
      code: 'CASH'
    }
  ];
  transportDirections: any[] = [{ "code": "ORTAK", "name": "Genel" }, { "code": "T", "name": "Transshipment" }, { "code": "I", "name": "İthalat" }, { "code": "E", "name": "İhracat" }, { "code": "D", "name": "Yurtiçi" }];

  //TODO: Backend den gelen data localStorage a yazılacak
  paymentPeriods: any[] = [
    {
      name: 'Seçiniz',
      code: null
    },
    {
      name: 'Günlük',
      code: 'DAILY'
    },
    {
      name: 'Haftalık',
      code: 'WEEKLY'
    },
    {
      name: 'Aylık',
      code: 'MONTHLY'
    }
  ]

  //TODO: Backend den gelen data localStorage a yazılacak
  operationTypes: any[] = [
    {
      name: 'Seçiniz',
      code: null
    },
    {
      name: 'Muhasebe',
      code: 'ACCOUNTING'
    },
    {
      name: 'Denizyolu Taşımacılığı',
      code: 'SEA'
    },
    {
      name: 'Havayolu Taşımacılığı',
      code: 'AIR'
    },
    {
      name: 'Yurtiçi',
      code: 'INLAND'
    },
    {
      name: 'Karayolu Operasyon',
      code: 'TRUCK'
    }
  ]

  companyStatus: any[] = [{ code: 'W', name: 'Cari Onay Bekliyor' }, { code: 'N', name: 'Yeni' }, { code: 'A', name: 'Aktif', inactive: true }, { code: 'P', name: 'Pasif', inactive: true }, { code: 'L', inactive: true, name: 'Potansiyel' }, { code: 'B', inactive: true, name: 'Kara Liste' }]
    newEmail: any = {};
    filteredExpoDefs: any[] = [];

    showDescriptonDialog: boolean = false;

  constructor(public _location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private activityService: ActivityService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private offerExportService: OfferExportService,
    private userService: UserService,
    private listService: ListService,
    private companyService: CompanyService,
    private financialQueryService: FinancialQueryService,
    private selectBoxService: SelectboxService,
    private infoManagerService: InfoManagerService) {

    const user = JSON.parse(localStorage.getItem("user"));
    this.sessionUser = !this.route.snapshot.params.registerNo ? { id: user.id, name: `${user.firstName} ${user.lastName}` } : null
  }

  async ngOnInit() {

    this.selectedTransportDirection = this.route.snapshot.params.transportDirection;

    const ctypes = await this.companyService.getCompanyTypes(this.selectedTransportDirection).toPromise();
    const industry = await this.companyService.getIndustries(this.selectedTransportDirection).toPromise();
    this.sourceList = await this.selectBoxService.getItemByBoxName('sourceList', this.selectedTransportDirection).toPromise();

    // @ts-ignore
    industry.forEach(f => this.industries.push({ id: f.id, name: f.name }));

    // @ts-ignore
    ctypes.forEach(f => this.companyTypes.push({ id: f.id, name: f.name }));

    if (this.route.snapshot.params.id) {

      if (['I', 'E'].includes(this.route.snapshot.params.transportDirection)) {
        this.company = await this.companyService.getCompany(this.route.snapshot.params.id, this.route.snapshot.params.transportDirection).toPromise();
        this.getInfoManagers();
        this.financialQuery = await this.financialQueryService.getFinancialQueryByCompanyId(this.route.snapshot.params.id, this.route.snapshot.params.transportDirection).toPromise();
        //orc
        this.userTransportDirection = this.route.snapshot.params.transportDirection;
        this.company = await this.companyService.getCompany(this.route.snapshot.params.id, this.userTransportDirection).toPromise();
        this.financialQuery = await this.financialQueryService.getFinancialQueryByCompanyId(this.route.snapshot.params.id, this.route.snapshot.params.transportDirection).toPromise();
        // Mevcut müşteri detayı
        if (!this.route.snapshot.params.registerNo) {
          const user = JSON.parse(localStorage.getItem('user'));
          Object.assign(user, { name: `${user.firstName} ${user.lastName}` });
          console.log(user);
          this.company.salesRepresentative = EntityUtil.toSimple(user);
        }
      }
      this.getCompanyActivity()
    }
      this.getAllInfoManagerDef();
    this.findByCompanyAgentInfoTitleId();
  }

  getUserByTransportDirection(transportDirection) {
    if(transportDirection == 'I' && localStorage.getItem('import')) {
      return JSON.parse(localStorage.getItem('import')).user
    }
    if(transportDirection == 'E' && localStorage.getItem('export')) {
      return JSON.parse(localStorage.getItem('export')).user
    }

    return JSON.parse(localStorage.getItem('user'))
  }

  async showActivityTableDialog() {

    const user = this.getUserByTransportDirection(this.selectedTransportDirection)

    if (!this.lastActivityWithOffer) await this.getLastActivityWithOffer(user.id);
    if (!this.lastActivity) await this.getLastActivity(user.id);

    this.companyActivities = await this.getActivity();

    this.isLoading = false;
    this.dialog.open(ActivityTableDialogComponent, {
      width: '1200px',
      data: { transportDirection : this.selectedTransportDirection, activities: this.companyActivities, lastActivityWithOffer: this.lastActivityWithOffer, lastActivity: this.lastActivity, lastActicityWithOfferRepresentatives: this.lastActicityWithOfferRepresentatives, lastActicityRepresentatives: this.lastActicityRepresentatives }
    });
  }


  async showOffersTableDialog() {
    try {
      const offers = await this.getOffers();
      this.isLoading = false;
      this.dialog.open(OfferTableDialogComponent, {
        width: '1200px',
        data: offers
      });

    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Hata oluştu!' });
    }

  }

  async showGoodTableDialog() {
    try {
      const goods = await this.getCompanyCRMGood();
      this.isLoading = false;
      this.dialog.open(GoodTableDialogComponent, {
        width: '1200px',
        data: goods
      });
    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Tüm yük modellerine yetkili olduğunuzdan emin olunuz!' });
    }


  }

  async showCustomerRepresentativesDialog() {
    // todo
    try {
      this.customerRepresentatives = await this.getCustomerRepresentatives(this.route.snapshot.params.registerNo);
      this.visibleCustomerRepresentativesDialog = true;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Satış temsilcileri bilgilerine erişilemedi!' });
    }


  }

  async showFinancialListDialog() {
    try {
      this.financialList = await this.getFinancialList(this.route.snapshot.params.registerNo || this.company.registerNo);
      this.visibleFinancialDialog = true;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Finansal tablolara erişilemedi!' });
    }

  }
  async showPersonDialog() {
    try {
      this.companyPerson = await this.getPerson(this.route.snapshot.params.registerNo || this.company.registerNo);
      this.visiblePersonDialog = true;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'İlgili kişi kayıtlarına erişilemedi!' });
    }

  }
    async getCompanyActivity() {
        this.companyActivity = await this.companyService.getCompanyActivity((this.route.snapshot.params.registerNo || this.company.registerNo), this.selectedTransportDirection).toPromise().then().catch(reason => {
            this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'Fatura Bulunamadı!'});
            this.isLoading = false;
        });
    }
    async showCompanyActivity() {
     try {
      this.invoices = [];

      if(this.companyActivity.companyActivityOperationsInvoices && this.companyActivity.companyActivityOperationsInvoices.length > 0) {
          this.invoices = this.companyActivity.companyActivityOperationsInvoices[0].invoices
      }
      if (this.companyActivity === null) {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Fatura Bulunamadı!' });
          this.isLoading = false;
          return;
      }
      if(this.companyActivity.companyActivityOperationsInvoices && this.companyActivity.companyActivityOperationsInvoices.length > 0) {
        this.invoices = this.companyActivity.companyActivityOperationsInvoices[0].invoices
      }

      // backend den sınırlı geliyor o yüzden tarih kısıtlaması kaldırıldı.
      // if(this.invoices.length > 0) {
      //   const sixMonthsAgo = new Date();
      //   sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      //   this.invoices = (this.invoices.filter((invoice) => (new Date(invoice.date)) > sixMonthsAgo));
      // }

      this.visibleCompanyActivityDialog = true;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Beklenmedik bir hata oluştu!' });
    }

  }


    filterExpoDefs(event) {
    this.companyService.searchExpoDef(event.query, this.selectedTransportDirection).subscribe(res => this.filteredExpoDefs = res);
  }

  async getLastActivityWithOffer(userId) {

    const activities = await this.activityService.searchByOrderAndLimit({ 'company.id': this.company.id, 'startType': 'quotationRequest'}, 1, ' order by id desc', this.selectedTransportDirection).toPromise();


    if (activities.length > 0) {
      const activity =  activities[0];
      const activityDetail = await this.activityService.getActivityById(activity.id, this.userTransportDirection).toPromise();
      this.lastActicityWithOfferRepresentatives = activityDetail.customerRepresentativeList;

      // if(activityDetail.operator.id == this.getUserByTransportDirection(this.selectedTransportDirection).id ) {
        this.lastActivityWithOffer =  activity;
      // }

      // if(activityDetail.customerRepresentativeList) {
      //   if(activityDetail.customerRepresentativeList.find(f => f.userId == this.getUserByTransportDirection(this.selectedTransportDirection).id)) {
      //     this.lastActivityWithOffer =  activity;
      //   }
      // }


    }

  }

  async getLastActivity(userId) {

    const activities = await this.activityService.searchByOrderAndLimit({ 'company.id': this.company.id, 'startType/NTIN': 'quotationRequest' }, 1, ' order by id desc', this.selectedTransportDirection).toPromise();

    if (activities.length > 0) {
      const activity =  activities[0];
      const activityDetail = await this.activityService.getActivityById(activity.id, this.userTransportDirection).toPromise();
      this.lastActicityRepresentatives = activityDetail.customerRepresentativeList;


      // if(activityDetail.operator.id == this.getUserByTransportDirection(this.selectedTransportDirection).id ) {
        this.lastActivity =  activity;
      // }

      // if(activityDetail.customerRepresentativeList) {
      //   if(activityDetail.customerRepresentativeList.find(f => f.userId == this.getUserByTransportDirection(this.selectedTransportDirection).id)) {
      //     this.lastActivity =  activity;
      //   }
      // }

    }

  }

  getDigitalArsiv() {
    this.isLoading = true;
    this.offerExportService.getDigitalArsiv(this.company.id, 'Company').subscribe(res => {
        this.digitalStore = res.body;
        this.isLoading = false;
    }, error => {
        this.isLoading = false;
    });
  }

  getDigitalDocument(report: any) {
    this.isLoading = true;
    this.offerExportService.getDocumentType(report?.id, this.route.snapshot.params.transportDirection).subscribe(res => {
        this.offerExportService.getDigitalDocument(res.body.reference, this.route.snapshot.params.transportDirection).subscribe( gt => {
            this.isLoading = false;
            const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
            const fileUrl = window.URL.createObjectURL(file);
            if ( res.body.contentType === 'application/pdf' ||  res.body.contentType.startsWith('image')) {
                window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
            } else {
              const nav = (window.navigator as any)
              if (nav && nav.msSaveOrOpenBlob) {
                  nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
              } else {
                    var a = document.createElement('a');
                    a.href = URL.createObjectURL(file);
                    a.download = res.body.fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
        }, error => {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
            this.isLoading = false;
        });
    },error => {
        this.isLoading = false;
    });
  }

  uploadDocumentsCheck(documents: any) {
    console.log(documents);
    if (documents.files && documents.files.length > 0) {
        for (const file of documents.files) {
            if (file.size <= 15728640) {
                this.uploadDocuments(file);
            } else {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
            }
        }

    } else if (documents.files && documents.files.length > 0) {
        for (const file of documents.files) {
            if (file.getAsFile().size <= 15728640) {
                this.uploadDocuments(file);
            } else {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
            }
        }
    }
  }

  uploadDocuments(file: any) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.offerExportService.uploadDocuments(formData, this.company.id).subscribe(res => {
        const dct = res.body.res;
        this.offerExportService.getPagesByName('/marketing/def/company').subscribe(pages => {
            const pageObject = pages.body.find(pg => pg.path === '/marketing/def/company');
            if (res.status === 200) {
                this.offerExportService.saveDocument({
                    entityid: +dct.entityid,
                    reference: dct.uid,
                    contentType: dct.contentType,
                    fileName: dct.fileName,
                    fileSize: dct.fileSize,
                    page: pageObject,
                    sourceType: 'Company'
                }).subscribe(sv => {
                    this.getDigitalArsiv();
                    this.uploader.clear();
                });
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
            }
        }, error => {
            this.isLoading = false;
        });
        this.isLoading = false;
    }, error => {
        this.isLoading = false;
    });
  }

  async getActivity(): Promise<ActivityDTOModel[]> {
    this.isLoading = true;
    const user = JSON.parse(localStorage.getItem("user"));
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 3);
    return await this.activityService.search({ 'company.id': this.company.id, 'createDate/DT/GT': (currentDate).toJSON().replace(/\..*$/g, '') }, user.id, this.selectedTransportDirection, true).toPromise();
  }

  async getOffers(): Promise<MinimizedOfferModel[]> {
    this.isLoading = true;
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 3);
    return await this.activityService.getAllOffersReportByQuery({ 'customer.id': this.company.id, 'createDate/DT/GT': (currentDate).toJSON().replace(/\..*$/g, '') }, this.selectedTransportDirection).toPromise();
  }

  async getCompanyCRMGood(): Promise<CompanyCRMDTO[]> {
    this.isLoading = true;
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 3);
    return await this.activityService.getCompanyCrmGoods(this.company.id, this.selectedTransportDirection).toPromise();
  }

  async getCustomerRepresentatives(registerNo: string): Promise<any[]> {
    this.isLoading = true;
    return await this.activityService.getCustomerRepresentativeByRegisterNo(registerNo, this.selectedTransportDirection).toPromise();
  }

  async getFinancialList(registerNo: string): Promise<any[]> {
    this.isLoading = true;
    return await this.activityService.getFinancialListByRegisterNo(registerNo, this.selectedTransportDirection).toPromise();
  }

  filterMaster(event){
    if (event?.query?.length > 2) {
      this.offerExportService.filterMaster(event.query,this.selectedTransportDirection).subscribe(res => {
          this.filteredMaster = res.body;
      });
    }
  }

  filterDistrict(event, cityId?: any) {
    if (event?.query?.length > 2) {
      this.offerExportService.getOfferExportDistrict(event.query, cityId?.id, this.selectedTransportDirection).subscribe(res => {
        this.filteredDistrictMinimized = res.body;
        this.district = res.body;
        const filtered: any[] = [];
        const query = event.query;
        this.district.forEach(district => {
          filtered.push(district);
        });
        this.filteredDistrictMinimized = filtered;
      });
    }
  }

  filterCity(event, countryId?: any) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCity(event.query, countryId?.id, this.selectedTransportDirection).subscribe(res => {
        this.filteredCityMinimized = res.body;
        this.city = res.body;
        const filtered: any[] = [];
        this.city.forEach(city => {
          filtered.push(city);
        });

        this.filteredCityMinimized = filtered;
      });
    }
  }
  filterCountry(event) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCountry(event.query, this.selectedTransportDirection).subscribe(res => {
        this.country = res.body;
        this.filteredCountryMinimized = res.body;
        const filtered: any[] = [];
        const query = event.query;
        this.country.forEach(country => {
          filtered.push(country);
        });

        this.filteredCountryMinimized = filtered;
      });
    }
  }

  filterTaxOffice(event) {
    if (event.query.length > 2) {
      this.companyService.getTaxOffice(this.route.snapshot.params.registerNo ? 'orc' : null, event.query).subscribe(r => {
        this.taxOffices = r;
        this.filteredTaxOffices = this.taxOffices;
      });
    }
  }

  autocompleteFocusOut(value, key, isModel = true) {
    if (!isModel) {
      if (!value[key] || !value[key].id) {
        value = null;
      }
      return;
    }
    if ((!value || !value.id) && isModel) {
      this.company[key] = null;
    }
  }

  update() {
    if (!this.validate()) return;
    this.isLoading = true;
    console.log(this.company)
    this.companyService.updateCompany(this.company, 'I').subscribe(res => {
      this.isLoading = false;
      this.company  = res;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kayıt Başarıyla Güncellendi' });
    }, () => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Lütfen adres kartının şirket tipi ve sektörün tanımlı olduğundan emin olunuz!' });
    })
  }

  filterUsers(event) {
    this.userService.searchUsers(event.query, this.selectedTransportDirection).subscribe(res => this.filteredUsers = res);
  }


  updateCompanyFinancial(dataRow) {
    this.companyService.updateCompanyFinancial(dataRow, this.selectedTransportDirection, this.route.snapshot.params.registerNo || this.company.registerNo).subscribe(r => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kayıt Başarıyla Güncellendi' });
    })
  }
  newCompanyFinancial() {
    const companyFinancial = new CompanyFinancialMobileDTO();
    companyFinancial.paymentType = 'Cari';
    this.financialList.push(companyFinancial);
  }
  deleteCompanyFinancial(index) {
    this.financialList.splice(index, 1);
  }
  createCompanyFinancial(dataRow: CompanyFinancialMobileDTO) {
    this.isLoading = true;
    if ((dataRow?.paymentType === undefined || dataRow?.dueDay === undefined || dataRow?.dueDay <= 0)) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Vade tipi seçilmeli ve vade 0 dan farklı girilmelidir.` });
      this.isLoading = false;
      return;
    }
    dataRow.registerNo = String(this.route.snapshot.params.registerNo || this.company.registerNo);
    this.companyService.createCompanyFinancial(dataRow, this.selectedTransportDirection).subscribe((r) => {
      this.getFinancialList(this.route.snapshot.params.registerNo || this.company.registerNo).then(r => {
        this.financialList = r;
        this.isLoading = false;
      });
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kaydedildi.' });
    }, () => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: `Adres kartına ait finansal detaylar oluşturulamadı!` });
    })
  }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

  newCustomerRepresentative() {
      const customerRepresentative = new CustomerRepresentativeModel();
      const date = new Date();
      customerRepresentative.startDate = date;
      customerRepresentative.endDate = this.addDays(date, 180);
    this.customerRepresentatives.push(customerRepresentative);
  }
  deleteCustomerRepresentative(index) {
    this.customerRepresentatives.splice(index, 1);
  }
  createOrUpdateCustomerRepresentative(dataRow: CustomerRepresentativeModel) {
    this.isLoading = true;
    this.companyService.createOrUpdateCustomerRepresentative(dataRow, this.selectedTransportDirection, this.route.snapshot.params.registerNo || this.company.registerNo).subscribe((r) => {

      this.getCustomerRepresentatives(this.route.snapshot.params.registerNo || this.company.registerNo).then(r => {
        this.customerRepresentatives = r;
        this.isLoading = false;
      })
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kaydedildi.' });
    }, () => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: `Adres kartına ait satış temsilcisi oluşturulamadı!` });
    })
  }

  deleteCompanyPerson(index) {
    this.companyPerson.splice(index, 1);
  }
  newCompanyPerson() {
    this.companyPerson.push(new Person());
  }

  async createOrUpdateCompanyPerson(dataRow: Person) {
    this.isLoading = true;
    this.companyService.createOrUpdateCompanyPerson(dataRow, this.selectedTransportDirection, this.route.snapshot.params.registerNo || this.company.registerNo).subscribe((r) => {
      this.isLoading = false;
      dataRow.id = r.body.id;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kaydedildi.' });
    }, () => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: `İşlem başarısız!` });
    })
  }

  onSelectCity(event) {
    this.company.country = event.country;
  }

  onSelectLoadDistrict(event) {
    this.company.country = event.country;
    this.company.city = event.city;
  }

  validate() {
    let isValid = true;
    this.requiredField.forEach(f => {
      if (!this.company[f.field]) {
        isValid = false;
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: `${f.label} zorunlu alandır.` });
      }
    })
    if (this.hasBMselectedCompanyType() && (!this.company.tcKn || String(this.company.tcKn).length != 11)) {
      isValid = false;
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: `Bireysel Müşteri TC No zorunlu alandır ve 11 hane olmalıdır.` });
    }
    return isValid;
  }

  async getPerson(companyRegisterNo) {
    // Todo...
    return await this.companyService.getCompanyPerson(companyRegisterNo, this.selectedTransportDirection).toPromise();
  }

  hasBMselectedCompanyType() {
    if (!this.company.companyTypes || this.company.companyTypes.length == 0) return false;

    const hasBN = this.company.companyTypes.find(f => f.name == 'BİREYSEL MÜŞTERİ');
    if (hasBN) return true;
    return false;
  }

  onChangeCompanyType() {
    console.log(this.company)
    if (this.hasBMselectedCompanyType()) {
      this.company.taxNo = null;
    } else {
      this.company.tcKn = null;
    }
  }

  async changeTransportDirectionOption(event) {
    const company = await this.companyService.search({'registerNo': this.route.snapshot.params.registerNo}, event.value, true).toPromise();
    if(!company || company.length == 0) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Adres kartı bulunamadı!' });
    }
    this.router.navigate([`/activities/customers/edit/${event.value}/${company[0].id}/${this.route.snapshot.params.registerNo}`]).then(() => {
      this.ngOnInit();
    })
  }

    async deleteById(id) {
      this.isLoading = true;
      await  this.companyService.deleteAddressCardEmail(id, this.selectedTransportDirection).toPromise();
      this.company.emails = this.company.emails.filter(e => e.id != id);
      this.isLoading = false;
    }

    addEmail() {
        this.company.emails.push(JSON.parse(JSON.stringify(this.newEmail)));
    }

    deleteEmail(ct: any) {
        if (ct.id) {
            this.deleteById(ct.id);
        } else {
            const index = this.company.emails.indexOf(ct);
            if (index !== -1) {
                this.company.emails.splice(index, 1);
            }
        }
    }

    getInfoManagers(){
      this.infoManagers = [];
        this.infoManagerService.getAllInfoManagers(this.selectedTransportDirection, this.company.id).toPromise().then(res => this.infoManagers.push(...res));
    }

    async getAllInfoManagerDef(){
        this.infoManagerService.getAllInfoManagerDef(this.selectedTransportDirection,'company').toPromise().then(res => this.infoManagerDefs.push(...res));
    }

    async findByCompanyAgentInfoTitleId(){
      if (this.company?.id != null)
        this.activityService.findByCompanyAgentInfoTitleId(this.selectedTransportDirection,this.company.id).toPromise().then(res => this.networks.push(...res));
    }

    async createEntityInfoManager() {
      if(this.infoManagerDescription != null){
          this.isLoading = true;
          await this.infoManagerService.createInfoManagerWithDescription(this.selectedTransportDirection,'company', this.company?.id, this.infoManagerDefId, this.infoManagerDescription).toPromise();
          await this.getInfoManagers();
          this.infoManagerDefId = null;
          this.isLoading = false;
          this.infoManagerDescription = null;
      } else{
          this.isLoading = true;
          await this.infoManagerService.createEntityInfoManager(this.selectedTransportDirection,'company', this.company?.id, this.infoManagerDefId).toPromise();
          await this.getInfoManagers();
          this.infoManagerDefId = null;
          this.isLoading = false;
      }
        this.showDescriptonDialog = false;
    }
}
