import {Holiday, PublicHoliday} from './../../models/holiday.model';
import {Currency} from './../../models/currency.model';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Announcement} from 'src/app/models/announcement.model';
import {IntranetWidget} from 'src/app/models/intranet-widget.model';
import {Person} from 'src/app/models/person.model';
import {Career} from 'src/app/models/career.model';
import {AnnouncementService} from 'src/app/services/announcement.service';
import {DashboardService} from 'src/app/services/dashboard.service';
import {IntranetWidgetService} from 'src/app/services/intranet-widget.service';
import {CurrencyConversionService} from '../../services/currency-conversion.service';
import {MatDialog} from "@angular/material/dialog";
import {NewFolderDialogComponent} from "../document/modals/new-folder-dialog/new-folder-dialog.component";
import {NewOutgoingRequestComponent} from "../new-outgoing-request/new-outgoing-request.component";
import {IntranetLeaveService} from 'src/app/services/leave-service';
import {Leave} from 'src/app/models/leave-models/leave.model';
import {IssueManagementService} from 'src/app/services/issue-management.service';
import {Issue} from 'src/app/models/issue-management-model';
import {CreateIssueComponent} from '../issue-management/create-issue/create-issue.component';
import {WeatherResults} from 'src/app/models/weather-response-model';
import {WeatherService} from 'src/app/services/weather.service';
import {StaffService} from 'src/app/services/staff.service';
import {CareerService} from 'src/app/services/career.service';
import {Staff} from 'src/app/models/staff.model';
import * as _ from 'lodash'
import { hasTransportDirectionBackend } from 'src/app/shared/helper/helper';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    chart1: any;

    chartOptions1: any;

    chart2: any;

    chartOptions2: any;

    dataGBP: any[] = [];
    dataUSD: any[] = [];
    dataEUR: any[] = [];
    monthNames = ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'];

    date: Date = new Date();

    activitiesCount = 0;
    operationsCount = 0;
    salesCount = 0;
    offersCount = 0;
    companiesCount = 0;

    bannerAnnouncements: Announcement[];

    announcements: Announcement[] = [];

    banner: Announcement;

    totalBannerCount = 0;

    bannerIndex = 0;

    staffs: Staff[] = [];

    widgets: IntranetWidget[] = [];

    isLoading = false;

    widgetTypeNames: string[] = [];

    barData: any;

    currency: Currency[] = [];
    currency2: Currency[] = [];

    holiday: Holiday[] = [];

    holidays: PublicHoliday[] = [];

    leaveStartDate: Date = new Date();
    jobBeginDate: Date = new Date();

    myLeaves: Leave[] = [];

    careers: Career[] = [];

    userLeaveClaimDays: number = 0;

    userAvailableLeaveClaimDays: number = 0;

    usedLeaves: number = 0;

    leavesProgress: number = 0;

    weatherDaily: WeatherResults;
    useTransportDirectionBackend: boolean = true;

    userPosition: string;
    importUserPosition: string;

    constructor(public dialog: MatDialog, private dashboardService: DashboardService,
                private announcementService: AnnouncementService, private staffService: StaffService, private intranetWidgetService: IntranetWidgetService, private leaveService: IntranetLeaveService,
                private router: Router, private currencyConversionService: CurrencyConversionService, private careerService: CareerService,
                private issueManagementService: IssueManagementService, private weatherService: WeatherService) {
                    this.useTransportDirectionBackend = hasTransportDirectionBackend();
                    this.userPosition = JSON.parse(localStorage.getItem('user')).position;
                    this.importUserPosition = JSON.parse(localStorage.getItem('userimport')).position;
    }

    assigneeIssues: Issue[] = [];

    reporterIssues: Issue[] = [];

    getAssigneeIssues() {

        var user = JSON.parse(localStorage.getItem('user'));

        this.issueManagementService.getIssuesByAssigneeId(user.id).subscribe(res => {

            this.assigneeIssues = _.orderBy(res, [(obj) => new Date(obj.createDate)], ['desc']);

            if (this.assigneeIssues.length >= 2) {
                this.assigneeIssues = this.assigneeIssues.slice(0, 2);
            }
        })

    }

    getReporterIssues() {

        var user = JSON.parse(localStorage.getItem('user'));

        this.issueManagementService.getIssuesByReporterId(user.id).subscribe(res => {
            this.reporterIssues = _.orderBy(res, [(obj) => new Date(obj.createDate)], ['desc'])

            if (this.reporterIssues.length >= 2) {
                this.reporterIssues = this.reporterIssues.slice(0, 2);
            }

        })

    }


    ngOnInit() {

        this.getAssigneeIssues();

        this.getReporterIssues();
        this.weatherService.getWeather().then(res => {
            res.daily = res.daily.splice(0, 5);
            this.weatherDaily = res;
        });

        this.leaveService.getLeavesByUserId().subscribe(res => {
            // iptal edilenler filtreleniyor.
            this.myLeaves = res.filter(f => f.status <= 1)
            console.log(this.myLeaves, "leaves")
        })

        // this.leaveService.calculateUserLeaveClaims().subscribe(res => {
        //   this.userLeaveClaimDays = res;

        //   this.leaveService.calculateAvailableLeaveClaims().subscribe(res => {
        //     this.userAvailableLeaveClaimDays = res;

        //     this.usedLeaves = this.userLeaveClaimDays - this.userAvailableLeaveClaimDays;
        //     this.leavesProgress = (this.usedLeaves / this.userLeaveClaimDays) * 100;
        //   })

        // })

        this.careerService.getAllCareers().subscribe(res => {
            this.careers = res;
            console.log(this.careers, "careers!")
        });

        this.isLoading = true;

        this.intranetWidgetService.getAvailableWidgets().subscribe(res => {

            console.log(res);

            res.forEach(item => {

                if (item.selected) {
                    this.widgets.push(item);

                    this.widgetTypeNames.push(item.typeName);
                }

            })

            this.isLoading = false;


        })

        this.leaveService.getPublicHolidays().subscribe(res => {
            console.log(res);
            this.holidays = res;
        })


        var user = JSON.parse(localStorage.getItem("user"));
        console.log(user)
        this.staffService.getUpcomingBirthdays(0, 20).subscribe(res => {
            this.staffs = res;
        })

        this.announcementService.getMyAvailableAnnouncements(user.id, true).subscribe(res => {
            this.bannerAnnouncements = res;


            this.totalBannerCount = this.bannerAnnouncements.length - 1;

            this.banner = this.bannerAnnouncements[this.bannerIndex];

            setInterval(() => {

                if (this.bannerIndex + 1 > this.totalBannerCount) {
                    this.bannerIndex = 0;
                    this.banner = this.bannerAnnouncements[this.bannerIndex];
                } else {
                    this.bannerIndex++;
                    this.banner = this.bannerAnnouncements[this.bannerIndex];
                }


            }, 5000);


        });


        this.announcementService.getMyAvailableAnnouncements(user.id, false).subscribe(res => {
            this.announcements = res;
        });

        if(!this.useTransportDirectionBackend) {
            this.dashboardService.getDashboardActivityLayoutList().subscribe(res => {
                this.activitiesCount += res.length
            });
        } else {
            this.dashboardService.getDashboardActivityLayoutList('I').subscribe(res => {
                this.activitiesCount += res.length
            });
            this.dashboardService.getDashboardActivityLayoutList('E').subscribe(res => {
                this.activitiesCount += res.length
            });
        }


        // this.dashboardService.getDashboardOfferLayoutList('I').subscribe(res => {
        //     this.offersCount += res.length;
        // })
        // this.dashboardService.getDashboardOfferLayoutList('E').subscribe(res => {
        //     this.offersCount += res.length;
        // })

        if(!this.useTransportDirectionBackend) { 
            this.dashboardService.getDashboardOperationLayoutList().subscribe(res => {
                this.operationsCount += res.length;
            })
        } else {
            this.dashboardService.getDashboardOperationLayoutList('I').subscribe(res => {
                this.operationsCount += res.length;
            })
            this.dashboardService.getDashboardOperationLayoutList('E').subscribe(res => {
                this.operationsCount += res.length;
            })
        }
       
        if(!this.useTransportDirectionBackend) { 
            this.dashboardService.getDashboardSalesLayoutList().subscribe(res => {
                this.salesCount += res.length;
            });
        } else {
            this.dashboardService.getDashboardSalesLayoutList('I').subscribe(res => {
                this.salesCount += res.length;
            });
            this.dashboardService.getDashboardSalesLayoutList('E').subscribe(res => {
                this.salesCount += res.length;
            });
        }

     
        if(!this.useTransportDirectionBackend) { 
            this.dashboardService.getDashboardOfferLayoutList().subscribe(res => {
                this.offersCount += res.length;
                console.log(this.offersCount, "offerCount")
            });
         } else {
            this.dashboardService.getDashboardOfferLayoutList('I').subscribe(res => {
                this.offersCount += res.length;
            });
            this.dashboardService.getDashboardOfferLayoutList('E').subscribe(res => {
                this.offersCount += res.length;
            });
        }
   

        if(!this.useTransportDirectionBackend) { 
            this.dashboardService.getDashboardCompanyLayoutList().subscribe(res => {
                this.companiesCount += res.length;
                console.log(this.companiesCount, "companiesCount")
            });
         } else {
            this.dashboardService.getDashboardCompanyLayoutList('I').subscribe(res => {
                this.companiesCount += res.length;
            });
            this.dashboardService.getDashboardCompanyLayoutList('E').subscribe(res => {
                this.companiesCount += res.length;
            });
        }



        this.currencyConversionService.getWeeklyCurrencyConversion().subscribe(res => {
            console.log(res.body);
            this.currency2 = res.body;
            const todayDate = new Date();
            const lastDayOfLastMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);
            const todayLocalDate = todayDate.getFullYear() + '-' + ((todayDate.getMonth() + 1) < 10 ? ('0' + (todayDate.getMonth() + 1))
                : (todayDate.getMonth() + 1)) + '-' + (todayDate.getDate() < 10 ? '0' + todayDate.getDate() : todayDate.getDate());
            const weeklyDays = [];

            for (let i = 7; i >= 0; i--) {
                if ((todayDate.getDate() - i) > 0) {
                    weeklyDays.push(todayDate.getDate() - i + ' ' + this.monthNames[todayDate.getMonth()]);
                } else {
                    weeklyDays.push(lastDayOfLastMonth.getDate() - i + 1 + ' ' + this.monthNames[todayDate.getMonth() + 1]);
                }
            }

            for (const c of this.currencyConversionService.requiredCurrencies) {
                for (let i = 0; i <= this.currency2.length; i++) {
                    if (this.currency2[i].date === todayLocalDate
                        && c === this.currency2[i].name) {
                        this.currency.push(this.currency2[i]);
                        break;
                    }
                }
                for (let i = 0; i <= this.currency2.length; i++) {

                    if (this.currency2[i]) {
                        if (c === this.currency2[i].name && c === 'GBP') {
                            this.dataGBP.push(this.currency2[i].price);
                        }
                        if (c === this.currency2[i].name && c === 'USD') {

                            this.dataUSD.push(this.currency2[i].price);
                        }
                        if (c === this.currency2[i].name && c === 'EUR') {
                            this.dataEUR.push(this.currency2[i].price);
                        }
                    }


                }
            }
            this.barData = {
                labels: weeklyDays,
                datasets: [
                    {
                        label: 'Dolar',
                        backgroundColor: '#22009C',
                        borderColor: '#22009C',
                        data: this.dataUSD,
                        tension: .4
                    },
                    {
                        label: 'Euro',
                        backgroundColor: '#6236FF',
                        borderColor: '#6236FF',
                        data: this.dataEUR,
                        tension: .4
                    },
                    {
                        label: 'Sterlin',
                        backgroundColor: '#997DFC',
                        borderColor: '#997DFC',
                        data: this.dataGBP,
                        tension: .4
                    },
                ]
            };
        });

        this.holiday.push({
            name: 'Yılbaşı',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Ulusal Egemenlik ve Çocuk Bayramı',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Emek ve Dayanışma Günü',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Ramazan Bayramı',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Atatürk’ü Anma,Gençlik ve Spor Bayramı',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Demokrasi ve Milli Birlik Günü',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
        this.holiday.push({
            name: 'Kurban Bayramı',
            date: '1 Ocak ',
            permission: '1 Gün',
            addpermission: '3 Gün'
        })
    }

    getLeaveType(leaveType: number) {
        if (leaveType == 1) {
            return "Yıllık İzin";
        } else if (leaveType == 2) {
            return "Ücretsiz İzin"
        }

        return "";
    }


    goToAnnouncementDetail(announcement: Announcement) {
        localStorage.setItem("announcement", JSON.stringify(announcement));
        this.router.navigate(["company/announcement/announcement-detail"]);

    }

    openNewOutgoingRequest(issue?: Issue) {
        let dialogRef = this.dialog.open(CreateIssueComponent, {
            width: '1200px',
            autoFocus: false,
            data: {
                issue: issue,
            }
        });
        dialogRef.afterClosed().subscribe(resObject => {

            this.getReporterIssues();


        });
    }

}
