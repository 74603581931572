import {SimpleEntity} from "./simple-entity.model";

export class CustomerRepresentativeModel {
    id: number;
    startDate: Date;
    endDate: Date;
    operationType: string;
    transportDirection: string;
    incoterm: string;
    company: SimpleEntity;
    salesRepresentative: SimpleEntity;
    customerRepresentative: SimpleEntity;
    agentRepresentative: SimpleEntity;
    operationRepresentative: SimpleEntity;
    loadCity: SimpleEntity;
    unloadCity: SimpleEntity;
    loadCountry: SimpleEntity;
    unloadCountry: SimpleEntity;
    collectionRepresentative: SimpleEntity;
}
