import { Component, OnInit } from '@angular/core';
import {Activity} from '../../../../models/activity.model';
import {AirMinimizedModel} from '../../../../models/air-minimized.model';
import {Company} from '../../../../models/company.model';
import {CompanyMinimizedModel} from '../../../../models/company-minimized.model';
import {OfferExportService} from '../../../../services/offer-export.service';
import {CompanyService} from '../../../../services/company.service';
import {ActivityService} from '../../../../services/activity.service';
import {DatePipe} from '@angular/common';
import {SeaportMinimizedModel} from '../../../../models/SeaportMinimized.model';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-offer-export-price-list',
  templateUrl: './offer-export-price-list.component.html',
  styleUrls: ['./offer-export-price-list.component.scss']
})
export class OfferExportPriceListComponent implements OnInit {

    selectedActivities: Activity = null;
    activites: any[] = [];
    expendedRow = {};

    companies: Company[] = [];
    filteredCompanies: Company[] = [];

    filteredSeaport: SeaportMinimizedModel[] = [];
    seaport: SeaportMinimizedModel[] = [];

    toPort: any;
    fromPort: any;
    customer: any;
    armator: any;
    etdStart: string;
    etdEnd: string;
    isLoading: boolean = false;

    constructor(private offerExportService: OfferExportService,
                private companyService: CompanyService,
                private activityService: ActivityService,
                private datePipe: DatePipe,
                private messageService: MessageService) { }

    ngOnInit(): void {
    }


    search() {
        this.isLoading = true;
        if (this.fromPort === undefined) {
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Uyarı:', detail: 'POL alanı boş girilemez...'});
            this.isLoading = false;
            return;
        }
        this.offerExportService.getOfferSeaDetailMobile(
            (this.fromPort?.id === undefined ? '' : this.fromPort.id),
            (this.toPort?.id === undefined ? '' : this.toPort.id),
            (this.armator?.id === undefined ? '' : this.armator.id),
            (this.customer?.id === undefined ? '' : this.customer.id),
            (this.etdEnd === undefined ? '' : this.datePipe.transform(this.etdEnd, 'yyyy-MM-dd'))).subscribe(res => {
            this.activites = res.body;
            this.isLoading = false;
        }, error => {
            this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Teklif bulunamadı...'});
            this.isLoading = false;
        });
    }

    filterSeaport(event) {
        this.offerExportService.getOfferExportSeaport(event.query, null, 'E').subscribe(res => {
            this.filteredSeaport = res.body;
        });
    }

    companySearch(event) {
        if (event.query.lenght < 3) return;
        this.companyService.getAll(event.query).subscribe(res => {
            this.filteredCompanies = res;
        });
    }

    // TODO
    goDetails(activity: any) {

    }
    getTotalBuyAmount(activity) {
        if(activity.seaContainerRezervations) {
            return activity.seaContainerRezervations.map(a => a.buyFareAmount).reduce((a,b) => (a || 0) + (b || 0));
        }
        return 0;
    }

    getRezervations(activity) {
        return activity.seaContainerRezervations;
    }

    onRowExpand(activity) {
        this.expendedRow = activity;
    }

}
