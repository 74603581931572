import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {any} from 'codelyzer/util/function';
import {environment} from '../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getToken} from './token-interceptor.service';

@Injectable()
export class PremService extends BaseService<any> {

    constructor(public httpClient: HttpClient) {
        super(
            'prem',
            httpClient);
    }

    getAll(): Observable<any[]> {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "?token=" + token + "&sessionId=" + sessionId
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getPremCalculationDet(id : number){
        const token = localStorage.getItem("token");

        const sessionId = localStorage.getItem("sessionId");

        const url = environment.baseUrl + "/" + this.endpoint + "/premCalculationDets" + "?token=" + token + "&sessionId=" + sessionId+ "&id=" + id
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    findByCalculationIdAndName(id , name : string){
        const token = localStorage.getItem("token");

        const sessionId = localStorage.getItem("sessionId");

        let url = environment.baseUrl + "/" + this.endpoint + "/getCalculationDetByIdAndName" + "?token=" + token + "&sessionId=" + sessionId;
        if(id != null) url = url + "&id=" + id ;
        if(name != null) url = url + "&name=" + name ;
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    savePremCalculationDets(premCalculationDets) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/premCalculationDets';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, premCalculationDets, {params: options, observe: 'response'});
    }
    calcUpperScale(premCalculationDet) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/calcUpperScale';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, premCalculationDet, {params: options, observe: 'response'});
    }

    createPremCalculation(periodStart: any, periodEnd: any){
    const token = localStorage.getItem('token');
    const importToken = getToken("I");
    const sessionId = localStorage.getItem('sessionId');

    const url = environment.baseUrl + '/' + this.endpoint + '/createPremCalculation?periodStart=' + JSON.stringify(periodStart).slice(1, 20) + '&periodEnd=' + JSON.stringify(periodEnd).slice(1, 20) ;
    let queryParam: HttpParams;
    const options = this.createRequestOption({token, sessionId, importToken: importToken.token, importSessionId: importToken.sessionId});

    queryParam = new HttpParams().append('token', token).append('sessionId', sessionId);

    return this.httpClient.post<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam },{params: options, observe: 'response'});
    }
    calculatePremCalculation(periodStart: any, periodEnd: any, premCalculationId){
        const token = localStorage.getItem('token');
        const importToken = getToken("I");
        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/calculatePremCalculation?periodStart=' + JSON.stringify(periodStart).slice(1, 20) + '&periodEnd=' + JSON.stringify(periodEnd).slice(1, 20) +'&premCalculationId=' + premCalculationId ;
        let queryParam: HttpParams;
        const options = this.createRequestOption({token, sessionId, importToken: importToken.token, importSessionId: importToken.sessionId});

        queryParam = new HttpParams().append('token', token).append('sessionId', sessionId);

        return this.httpClient.post<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam },{params: options, observe: 'response'});
    }
}
