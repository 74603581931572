import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/calendar/app.calendar.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AuthGuard } from './services/auth-guard';
import { ListAllocationComponent } from './pages/activity/allocation/list-allocation/list-allocation.component';
import { ListOfferRequestComponent } from './pages/activity/offers/list-offer-request/list-offer-request.component';
import { OfferRequestComponent } from './pages/activity/offers/offer-request/offer-request.component';
import { AllocationDetailComponent } from './pages/activity/allocation/allocation-detail/allocation-detail.component';
import { OfferDetailComponent } from './pages/activity/offers/offer-detail/offer-detail.component';
import { ListKpiTargetComponent } from './pages/kpi/kpi-target/list-kpi-target/list-kpi-target.component';
import { CreateKpiTargetComponent } from './pages/kpi/kpi-target/create-kpi-target/create-kpi-target.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CreateAnnouncementComponent } from './pages/announcement/create-announcement/create-announcement.component';
import { CardDetailComponent } from './pages/dashboard/card-detail/card-detail.component';
import { AnnouncementDetailComponent } from './pages/announcement/announcement-detail/announcement-detail.component';
import { EditWidgetsComponent } from './pages/settings/edit-widgets/edit-widgets.component';
import { CreatePageComponent } from './pages/intranet-page/create-page/create-page.component';
import { PageDetailComponent } from './pages/intranet-page/page-detail/page-detail.component';
import { CreateSurveyComponent } from './pages/survey/create-survey/create-survey.component';
import { TakeSurveyComponent } from './pages/survey/take-survey/take-survey.component';
import { ListSurveyComponent } from './pages/survey/list-survey/list-survey.component';
import { DocumentsComponent } from './pages/document/documents/documents.component';
import { CreateAdvanceComponent } from './pages/advance/create-advance/create-advance.component';
import { GetMyAdvancesComponent } from './pages/advance/get-my-advances/get-my-advances.component';
import { CreateLessonCategoryComponent } from './pages/learning/create-lesson-category/create-lesson-category.component';
import { ListLessonCategoryComponent } from './pages/learning/list-lesson-category/list-lesson-category.component';
import { CreateLessonComponent } from './pages/learning/create-lesson/create-lesson.component';
import { ListLessonComponent } from './pages/learning/list-lesson/list-lesson.component';
import { ListLessonWatcherComponent } from './pages/learning/list-lesson-watcher/list-lesson-watcher.component';
import { WatchLessonComponent } from './pages/learning/watch-lesson/watch-lesson.component';
import { CreateLessonQuizComponent } from './pages/learning/quiz/create-lesson-quiz/create-lesson-quiz.component';
import { SolveQuizComponent } from './pages/learning/quiz/solve-quiz/solve-quiz.component';
import { CreateExpensePackageComponent } from './pages/expense/create-expense-package/create-expense-package.component';
import { EditExpensePackageComponent } from './pages/expense/edit-expense-package/edit-expense-package.component';
import { ExpensePackageCategoriesComponent } from './pages/expense/expense-package-categories/expense-package-categories.component';
import { GetMyExpencesComponent } from './pages/expense/get-my-expences/get-my-expences.component';
import { ApprovalExpensePackagesComponent } from './pages/expense/approval-expense-packages/approval-expense-packages.component';
import { ExpensePackageApproveComponent } from './pages/expense/expense-package-approve/expense-package-approve.component';
import { MyLeaveRequestsComponent } from './pages/leave/my-leave-requests/my-leave-requests.component';
import { CreateLeaveComponent } from './pages/leave/create-leave/create-leave.component';
import { LeaveRequestsComponent } from './pages/leave/leave-requests/leave-requests.component';
import { LeaveApprovalComponent } from './pages/leave/leave-approval/leave-approval.component';
import { OfferExportComponent } from "./pages/activity/sea-export/offer-export/offer-export.component";
import { OfferExportListComponent } from "./pages/activity/sea-export/offer-export-list/offer-export-list.component";
import { KanbanBoardComponent } from './pages/issue-management/kanban-board/kanban-board.component';
import { BoardsComponent } from './pages/issue-management/boards/boards.component';
import { IssueListComponent } from './pages/issue-management/issue-list/issue-list.component';
import { WidgetsComponent } from './pages/settings/widgets/widgets.component';
import { ListAnnouncementComponent } from './pages/announcement/list-announcement/list-announcement.component';
import { SurveyReportComponent } from './pages/survey/survey-report/survey-report.component';
import { AppPublicComponent } from './app-public.component';
import { SurveyComponent } from './pages/public/survey/survey.component';
import { GetApprovalAdvancesComponent } from './pages/advance/get-approval-advances/get-approval-advances.component';
import { AdvanceApprovalComponent } from './pages/advance/advance-approval/advance-approval.component';
import { CreateCareerComponent } from './pages/career/create-career/create-career.component';
import { ListCareerComponent } from './pages/career/list-career/list-career.component';
import { CareerDetailComponent } from './pages/career/career-detail/career-detail.component';
import { ListCareerManagerComponent } from './pages/career/list-career-manager/list-career-manager.component';
import { UpdateCareerComponent } from './pages/career/update-career/update-career.component';
import { ListOfferRequestExportComponent } from './pages/activity/offers/list-offer-request-export/list-offer-request-export.component';
import { ListPriceOfferSeaComponent } from './pages/activity/offers/list-price-offer-sea/list-price-offer-sea.component';
import {InstitutionalComponent} from "./pages/career/institutional/institutional.component";
import { HistoryComponent } from './pages/career/history/history.component';
import {AirOfferListComponent} from "./pages/activity/air-good/air-offer-list/air-offer-list.component";
import {AirOfferComponent} from "./pages/activity/air-good/air-offer/air-offer.component";
import { DocumentManagerComponent } from './pages/qdms/document-manager/document-manager.component';
import {AirOfferCreateComponent} from "./pages/activity/air-good/air-offer-create/air-offer-create.component";
import { CustomersComponent } from './pages/activity/customers/customers.component';
import { CustomerDetailComponent } from './pages/activity/customer-detail/customer-detail.component';
import { ActivitiesComponent } from './pages/activity/activities/activities.component';
import {ChangePasswordComponent} from './pages/user/change-password/change-password.component';
import {OfferExportPriceListComponent} from './pages/activity/sea-export/offer-export-price-list/offer-export-price-list.component';
import { FinancialFormsComponent } from './pages/forms/financial-forms/financial-forms.component';
import { FinancialFormListComponent } from './pages/forms/financial-form-list/financial-form-list.component';
import {ListComponent} from "./pages/list/list.component";
import {ProfileComponent} from "./pages/settings/profile/profile.component";
import {CreateActivityComponent} from "./pages/activity/create-activity/create-activity.component";
import {ActivityListComponent} from "./pages/activity/activity-list/activity-list.component";
import { KpiTargetDetailComponent } from './pages/kpi/kpi-target-detail/kpi-target-detail.component';
import { ReportComponent } from './pages/power-bi/report/report.component';
import { KpiComponent } from './pages/kpi/kpi/kpi.component';
import { OfferRequestPartialComponent } from './pages/activity/offers/offer-request-partial/offer-request-partial.component';
import { ListOfferRequestPartialComponent } from './pages/activity/offers/list-offer-request-partial/list-offer-request-partial.component';
import {ListKpiCustomerTargetComponent} from './pages/kpi/list-kpi-customer-target/list-kpi-customer-target.component';
import { CompanyListComponent } from './pages/company-list/company-list.component';
import {PremComponent} from "./pages/prem/prem/prem.component";
import {ForgotPasswordComponent} from "./pages/user/forgot-password/forgot-password.component";
import {ListOfferTruckComponent} from "./pages/activity/offers/truck-offer/list-offer-truck/list-offer-truck.component";
import {
    TruckOfferExportComponent
} from "./pages/activity/offers/truck-offer/truck-offer-export/truck-offer-export.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: '', component: DashboardComponent, canActivate: [AuthGuard], },
                    { path: 'dashboard/card-detail', component: CardDetailComponent, canActivate: [AuthGuard], },
                    { path: 'documentation', component: DocumentationComponent, canActivate: [AuthGuard], },
                    { path: 'activity/list', component: ActivityListComponent, canActivate: [AuthGuard]},
                    { path: 'company/list', component: CompanyListComponent, canActivate: [AuthGuard]},
                    { path: 'activities/offer-request', component: OfferRequestComponent, canActivate: [AuthGuard]},
                    { path: 'activities/import/list-offer-request-partial', component: ListOfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/export/list-offer-request-partial', component: ListOfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/import/offer-request-partial', component: OfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/import/offer-request-partial/:id', component: OfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/export/offer-request-partial', component: OfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/export/offer-request-partial/:id', component: OfferRequestPartialComponent, canActivate: [AuthGuard]},
                    { path: 'activities/offer-request/:allocation', component: OfferRequestComponent, canActivate: [AuthGuard]},
                    { path: 'activities/offer-request/activity/:activityId', component: OfferRequestComponent, canActivate: [AuthGuard]},
                    { path: 'activities/list-offer-request', component: ListOfferRequestComponent, canActivate: [AuthGuard], },
                    { path: 'activities/list-air-offer', component: AirOfferListComponent, canActivate: [AuthGuard], },
                    { path: 'activities/air-offer', component: AirOfferComponent, canActivate: [AuthGuard], },
                    { path: 'activities/air-offer-create', component: AirOfferCreateComponent, canActivate: [AuthGuard], },
                    { path: 'activities/air-offer-detail/:id', component: AirOfferCreateComponent, canActivate: [AuthGuard], },
                    { path: 'activities/export/list-offer-request', component: ListOfferRequestExportComponent, canActivate: [AuthGuard], },
                    { path: 'activities/list-price-offer-sea', component: ListPriceOfferSeaComponent, canActivate: [AuthGuard], },
                    { path: 'activities/list-allocation', component: ListAllocationComponent, canActivate: [AuthGuard], },
                    { path: 'activities/allocation-detail/:id', component: AllocationDetailComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer-detail/:id', component: OfferDetailComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer/create', component: OfferExportComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer-export-detail/:id', component: OfferExportComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer/list/:transportDirection', component: OfferExportListComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer/price-list', component: OfferExportPriceListComponent, canActivate: [AuthGuard], },
                    { path: 'activities/offer-truck-request', component: TruckOfferExportComponent, canActivate: [AuthGuard]},
                    { path: 'activities/offer-truck-request/activity/:id', component: TruckOfferExportComponent, canActivate: [AuthGuard]},
                    { path: 'activities/list-offer-truck', component: ListOfferTruckComponent, canActivate: [AuthGuard]},
                    { path: 'activities/customers', component: CustomersComponent, canActivate: [AuthGuard], },
                    { path: 'activities/customers/edit/:transportDirection/:id/:registerNo', component: CustomerDetailComponent, canActivate: [AuthGuard], },
                    { path: 'activities/customers/edit/:transportDirection/:id', component: CustomerDetailComponent, canActivate: [AuthGuard], },
                    { path: 'activities', component: ActivitiesComponent, canActivate: [AuthGuard], },
                    { path: 'create-activity', component: CreateActivityComponent, canActivate: [AuthGuard], },
                    { path: 'activity/:transportDirection/:id', component: CreateActivityComponent, canActivate: [AuthGuard], },
                    { path: 'kpi/list-kpi-targets', component: ListKpiTargetComponent, canActivate: [AuthGuard], },
                    { path: 'kpi/:id', component: KpiTargetDetailComponent, canActivate: [AuthGuard], },
                    { path: 'kpi/create-kpi-target', component: CreateKpiTargetComponent, canActivate: [AuthGuard], },
                    { path: 'kpi', component: KpiComponent, canActivate: [AuthGuard], },
                    { path: 'kpi/list/list-kpi-customer-targets', component: ListKpiCustomerTargetComponent, canActivate: [AuthGuard], },
                    { path: 'company/announcement/create', component: CreateAnnouncementComponent, canActivate: [AuthGuard], },
                    { path: 'company/announcement/announcement-detail', component: AnnouncementDetailComponent, canActivate: [AuthGuard], },
                    { path: 'company/announcement/list', component: ListAnnouncementComponent, canActivate: [AuthGuard], },
                    { path: 'settings/edit-widgets', component: EditWidgetsComponent, canActivate: [AuthGuard], },
                    { path: 'settings/widgets', component: WidgetsComponent, canActivate: [AuthGuard], },
                    { path: 'pages/create-page', component: CreatePageComponent, canActivate: [AuthGuard], },
                    { path: 'pages/:id', component: PageDetailComponent, canActivate: [AuthGuard], },
                    { path: 'survey/create', component: CreateSurveyComponent, canActivate: [AuthGuard], },
                    { path: 'survey/take/:id', component: TakeSurveyComponent, canActivate: [AuthGuard], },
                    { path: 'survey/report/:id', component: SurveyReportComponent, canActivate: [AuthGuard], },
                    { path: 'survey/list', component: ListSurveyComponent, canActivate: [AuthGuard], },
                    { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard], },
                    { path: 'learning/create-lesson-category', component: CreateLessonCategoryComponent, canActivate: [AuthGuard], },
                    { path: 'learning/list-lesson-category', component: ListLessonCategoryComponent, canActivate: [AuthGuard], },
                    { path: 'learning/create-lesson', component: CreateLessonComponent, canActivate: [AuthGuard], },
                    { path: 'learning/list-lesson', component: ListLessonComponent, canActivate: [AuthGuard], },
                    { path: 'learning/list-lesson-watcher', component: ListLessonWatcherComponent, canActivate: [AuthGuard], },
                    { path: 'learning/watch-lesson/:id', component: WatchLessonComponent, canActivate: [AuthGuard], },
                    { path: 'learning/quiz/create-lesson-quiz/:id', component: CreateLessonQuizComponent, canActivate: [AuthGuard], },
                    { path: 'learning/quiz/solve-lesson-quiz/:id', component: SolveQuizComponent, canActivate: [AuthGuard], },
                    { path: 'expense/create-expense-package', component: CreateExpensePackageComponent, canActivate: [AuthGuard], },
                    { path: 'expense/edit-expense-package', component: EditExpensePackageComponent, canActivate: [AuthGuard], },
                    { path: 'expense/edit-expense-package', component: EditExpensePackageComponent, canActivate: [AuthGuard], },
                    { path: 'expense/expense-package-categories', component: ExpensePackageCategoriesComponent, canActivate: [AuthGuard], },
                    { path: 'expense/get-my-expences', component: GetMyExpencesComponent, canActivate: [AuthGuard], },
                    { path: 'expense/approval-expense-packages', component: ApprovalExpensePackagesComponent, canActivate: [AuthGuard], },
                    { path: 'expense/expense-package-approve/:id', component: ExpensePackageApproveComponent, canActivate: [AuthGuard], },
                    { path: 'expense/expense-categories', component: ExpensePackageCategoriesComponent, canActivate: [AuthGuard], },
                    { path: 'leave/get-my-leaves', component: MyLeaveRequestsComponent, canActivate: [AuthGuard], },
                    { path: 'leave/get-all-leaves', component: LeaveRequestsComponent, canActivate: [AuthGuard], },
                    { path: 'leave/create-leave', component: CreateLeaveComponent, canActivate: [AuthGuard], },
                    { path: 'leave/leave-approval/:id', component: LeaveApprovalComponent, canActivate: [AuthGuard], },
                    {path:  'qdms/document-manager', component: DocumentManagerComponent, canActivate:[AuthGuard]},
                    { path: 'advance/create', component: CreateAdvanceComponent, canActivate: [AuthGuard], },
                    { path: 'advance/getMyAdvances', component: GetMyAdvancesComponent, canActivate: [AuthGuard], },
                    { path: 'advance/getApprovalAdvances', component: GetApprovalAdvancesComponent, canActivate: [AuthGuard], },
                    { path: 'advance/advance-approve/:id', component: AdvanceApprovalComponent, canActivate: [AuthGuard], },

                    { path: 'issue-management/kanban-board', component: KanbanBoardComponent, canActivate: [AuthGuard], },
                    { path: 'issue-management/boards', component: BoardsComponent, canActivate: [AuthGuard], },
                    { path: 'issue-management/issues', component: IssueListComponent, canActivate: [AuthGuard], },

                    { path: 'calendar', component: AppCalendarComponent, canActivate: [AuthGuard], },
                    { path: 'list/:endPoint', component: ListComponent, canActivate: [AuthGuard], },
                    { path: 'list/:endPoint/:transportDirection', component: ListComponent, canActivate: [AuthGuard], },

                    { path: 'institutional', component: InstitutionalComponent, canActivate: [AuthGuard], },
                    { path: 'history', component: HistoryComponent, canActivate: [AuthGuard], },
                    { path: 'career/create', component: CreateCareerComponent, canActivate: [AuthGuard], },
                    { path: 'career/update/:id', component: UpdateCareerComponent, canActivate: [AuthGuard], },
                    { path: 'career/getAllCareers', component: ListCareerComponent, canActivate: [AuthGuard], },
                    { path: 'career/getAllCareersByManager', component: ListCareerManagerComponent, canActivate: [AuthGuard], },
                    { path: 'career/career-detail/:id', component: CareerDetailComponent, canActivate: [AuthGuard], },
                    { path: 'forms/financial-form', component: FinancialFormsComponent, canActivate: [AuthGuard], },
                    { path: 'forms/financial-form/:id/:transportDirection', component: FinancialFormsComponent, canActivate: [AuthGuard], },
                    { path: 'forms/list/financial-form', component: FinancialFormListComponent, canActivate: [AuthGuard], },

                    { path: 'power-bi/report', component: ReportComponent, canActivate: [AuthGuard], },
                    { path: 'prem', component: PremComponent, canActivate: [AuthGuard], },
                ]
            },
            {
                path: '', component: AppPublicComponent,
                children: [
                ]
            },
            { path: 'survey/:hash', component: SurveyComponent },
            { path: 'user/change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
            {path: 'change-password', component: ChangePasswordComponent},
            { path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard] },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: 'forgotPassword', component: ForgotPasswordComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
