import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MinimizedOfferModel } from 'src/app/models/minimized-offer.model';

@Component({
  selector: 'app-offer-table-dialog',
  templateUrl: './offer-table-dialog.component.html',
  styleUrls: ['./offer-table-dialog.component.scss']
})
export class OfferTableDialogComponent implements OnInit {

  date = new Date(); 
  lastThreeYears = [];
 
 constructor(
   public dialogRef: MatDialogRef<OfferTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MinimizedOfferModel[], 
 ) {

   this.date.setFullYear(this.date.getFullYear() -2);
   for (let y =  this.date.getFullYear(); y <= new Date().getFullYear(); y++) {
     this.lastThreeYears.push(y);
   }
  }

  getCountOffer(year:number, month: number = null) {
    if(!month) return this.data.filter(f => new Date(f.createDate).getFullYear() == year).length;
    return this.data.filter(f => (new Date(f.createDate).getMonth()+1 == month) && (new Date(f.createDate).getFullYear() == year)).length;
  }

  getApprovedOffer(year: number, month: number = null) {
    if(!month) return this.data.filter(f => new Date(f.createDate).getFullYear() == year && f.status == 'confirmed').length;
    return this.data.filter(f => (new Date(f.createDate).getMonth()+1 == month) && (new Date(f.createDate).getFullYear() == year) && (f.status == 'confirmed')).length;
  }

  ngOnInit(): void {
  }

}
