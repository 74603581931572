import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{

    username: string;
    isLoading: boolean;
    constructor(private userService: UserService, private messageService: MessageService) {
    }

    ngOnInit(): void {
    }

     submit() {
        this.isLoading = true;
       this.userService.forgotPassword(this.username, window.location.host).toPromise().then(response =>{
           if (response != null && response){
               this.isLoading = false;
               this.messageService.add({severity: 'success', summary: 'Başarılı', detail: 'Mail Gönderimi Başarılı! Mail kutunuzu kontrol ediniz.' });
           }
       }).catch(e => {
           this.messageService.add({severity: 'error', summary: 'Hata', detail: e.error.message});
       });
    }
}
