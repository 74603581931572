import { SimpleEntity } from "./simple-entity.model";
import { OfferPartialActivityMobileGoodPack } from "./offer-partial-activity-mobile-good-pack";
import { OfferPartialActivityMobileSeaCharge } from "./offer-partial-activity-mobile-sea-charge";
import { OfferPartialActivityMobileFrontEndTariff } from "./offer-partial-activity-mobile-front-end-tariff";
import { AirOrSeaGoodsDetailsDTO } from "./air-or-sea-goods-details-dto.model";
import {SelectBoxItem} from './select-box-item.model';

export class OfferPartialActivityMobile {

    transportType: string;
    incoterm: string
    packType: string
    goodPackType: SimpleEntity;
    offerType: string
    note: string
    status: string;
    amount: number;
    validityDate: Date;
    createDate: Date;
    isShipment: boolean = true;
    stackable: boolean;
    imo: boolean = false;
    serviceCode: string;

    incoOfferCurrency: SimpleEntity;
    incoFareLcl: number = 0;
    incoAllInAmount: number = 0;
    incoTotalAmount: number = 0;
    incoAmount: number = 0;
    incoTotalChargeAmount: number = 0;
    chargeableVolume: number = 0;
    revisedChargeableVolume: number = 0;
    frontEndAmount: number ;
    allInBuyAmount: number;
    avgEmptyReturn: number;

    customer: SimpleEntity;
    pol: SimpleEntity;
    pod: SimpleEntity;
    offer: SimpleEntity;
    salesRepresentative: SimpleEntity;
    activity: SimpleEntity;
    activityOperation: SimpleEntity;
    currency: SimpleEntity
    offerSea: SimpleEntity;
    offerSeaDetail: SimpleEntity;
    offerSubStatuses: SelectBoxItem[];
    offerSubStatus: String;
    offerSubStatusCode: String;
    goodPacks: OfferPartialActivityMobileGoodPack[] = [];
    offerSeaCharges: OfferPartialActivityMobileSeaCharge[] = [];
    frontEndTariffs: OfferPartialActivityMobileFrontEndTariff[] = [];
    seaGoods:  AirOrSeaGoodsDetailsDTO[] = [];
}
