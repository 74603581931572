import {Representative} from "./representative";
import {Person} from "./person";
import {Address} from "./address";

export class StepRepresentative {
  customerRepresentativeList: Representative[] = [];
  contactList: Person[] = [];
  address: Address;

}
