import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Allocation } from '../models/allocation.model';
import { BaseService } from './base.service';


@Injectable()
export class AllocationService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'allocation',
            httpClient);
    }

    findSeaAllocation(etdStart:Date, etdEnd: Date, fromPortId?: number, toPortId?: number,  lineAgentId?: number): Observable<Allocation[]> {

        const queryParam = {
            token: localStorage.getItem("token"),
            sessionId: localStorage.getItem("sessionId"),
            etdStart: this.datepipe.transform(etdStart, 'yyyy-MM-dd'),
            etdEnd: this.datepipe.transform(etdEnd, 'yyyy-MM-dd')
        }
        
        if(toPortId) Object.assign(queryParam, {toPortId: toPortId})
        if(fromPortId) Object.assign(queryParam, {fromPortId: fromPortId})
        if(lineAgentId) Object.assign(queryParam, {lineAgentId: lineAgentId})

        const params = new HttpParams({  fromObject: queryParam});

        return this.httpClient.get<Allocation[]>(`${environment.baseUrl}/${this.endpoint}/findSeaAllocation`, { withCredentials: false, headers: this.httpHeaders(), params: params })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    getSeaAllocation(id: number, tonnage = null): Observable<Allocation> {
       
        const queryParam = {
            token: localStorage.getItem("token"),
            sessionId: localStorage.getItem("sessionId"),
            id: id
        }
        if(tonnage) Object.assign(queryParam, {tonnage: tonnage})
        const params = new HttpParams({  fromObject: queryParam});
        return this.httpClient.get<Allocation>(`${environment.baseUrl}/${this.endpoint}/getSeaAllocation`, { withCredentials: false, headers: this.httpHeaders(), params: params })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    //TODO: impersonation !!!
}
