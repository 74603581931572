import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Allocation } from 'src/app/models/allocation.model';
import { IntranetPage } from 'src/app/models/intranet-page.model';
import { IntranetPageService } from 'src/app/services/intranet-page.service';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss']
})
export class PageDetailComponent implements OnInit {

  intranetPage: IntranetPage = <IntranetPage>{};

  constructor(private router: Router, private route: ActivatedRoute, private intranetPageService: IntranetPageService) {

    this.route.params.subscribe(res => {
      var id = res.id;

      this.intranetPageService.get(id).subscribe(res => {

        console.log(res);

        this.intranetPage = res;

      })


    });

  }

  ngOnInit(): void {
  }

}
