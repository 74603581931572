import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Career } from 'src/app/models/career.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { CareerService } from 'src/app/services/career.service';

@Component({
  selector: 'app-update-career',
  templateUrl: './update-career.component.html',
  styleUrls: ['./update-career.component.scss']
})
export class UpdateCareerComponent implements OnInit {

  editorText: string;

  entity: Career = <Career>{};

  entityId: number;

  items: MenuItem[];

  home: MenuItem;

  constructor(private messageService: MessageService, private careerService: CareerService, private router: Router,
    private announcementService: AnnouncementService, private route: ActivatedRoute) {


    this.route.params.subscribe(params => {
      this.entityId = params['id'];

      this.careerService.get(this.entityId).subscribe(res => {
        this.entity = res;

        this.editorText = this.entity.description;
      })



    });

  }

  ngOnInit(): void {

    this.items = [
      { label: 'İlan Listesi', routerLink: ['/career/getAllCareersByManager'] },
      { label: 'İlan Detay' },
    ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };

    var user = JSON.parse(localStorage.getItem("user"));

  }

  isLoading = false;

  save() {

    this.entity.description = this.editorText;

    this.isLoading = true;

    this.careerService.update(this.entity).subscribe(res => {
      this.isLoading = false;


      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "İş İlanı Başarıyla Kaydedildi" });


      setTimeout(() => {
        this.router.navigate(['/career/getAllCareersByManager'])
      }, 1500)

    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })


  }

}
