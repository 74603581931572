import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { QdmsService } from 'src/app/services/qdms.service';
import { MatDialog } from '@angular/material/dialog';
import { FolderDialogComponent } from '../folder-dialog/folder-dialog.component';
import { Folder } from 'src/app/models/qdms/folder-model';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { FileDialogComponent } from '../file-dialog/file-dialog.component';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { QdmsFile } from 'src/app/models/qdms/file-model';

import * as FileSaver from 'file-saver'

@Component({
  selector: 'app-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.scss']
})
export class DocumentManagerComponent implements OnInit {

  folders: TreeNode[];
  selectedFolder: TreeNode;
  selectedFolderId: number;
  isLoading: boolean = false;


  allFolderFiles: QdmsFile[] = [];
  // filtered revized files
  folderFiles: QdmsFile[] = [];
  selectedFile: QdmsFile;

  folderContext: MenuItem[];
  fileContext: MenuItem[];

  constructor(private service: QdmsService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private confirmService: ConfirmationService) { }

  ngOnInit(): void {
    this.getParentFolders();


    this.folderContext = [
      {
        label: 'Yeni Alt Klasör',
        icon: 'pi pi-fw pi-plus',
        command: (event) => this.onFolderDialog({id: Number(this.selectedFolder.data), newFolder: true})
      },
      {
        label: 'Sil',
        icon: 'pi pi-fw pi-trash',
        command: (event) => this.onDeleteFolder()
      },
      {
        label: 'Düzenle',
        icon: 'pi pi-fw pi-pencil',
        command: (event) => this.onFolderDialog({id: Number(this.selectedFolder.data), newFolder: false})
      },
      {
        label: 'Dosya Yükle',
        icon: 'pi pi-upload',
        command: (event) => this.onFileDialog()
      }
    ];

    this.fileContext =  [
      {
        label: 'Görüntüle',
        icon: 'pi pi-fw pi-eye',
        command: (event) => this.onShowFileViewer()
      },
      {
        label: 'İndir',
        icon: 'pi pi-fw pi-cloud-download',
        command: (event) => { this.downloadFile()}
      },
      {
        label: 'Sil',
        icon: 'pi pi-fw pi-trash',
        command: (event) => { this.deleteFile() }
      },
    ];
  }

  onFolderDialog(options = null) {
    let dialogRef = this.dialog.open(FolderDialogComponent, {
      width: '1200px',
      data: options
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getParentFolders();
      this.selectedFolderId = null;
    });

  }


  onFileDialog(file: QdmsFile = null) {
    let dialogRef = this.dialog.open(FileDialogComponent, {
      width: '1000px',
      height: '800px',
      data: {
        selectedFolderId: this.selectedFolderId,
        file: file
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getFilesByFolder(this.selectedFolderId);
    });

  }

  onSelectFolder() {
    this.selectedFolderId = Number(this.selectedFolder.data);
    this.getFilesByFolder(this.selectedFolderId);
  }

  onDeleteFolder() {

    if(this.selectedFolder.children && this.selectedFolder.children.length > 0) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `${this.selectedFolder.label} klasörüne bağlı alt klasörler bulunmaktadır.` });
      return;
    }
    this.service.searchFolderPermissions({'folder.id':Number(this.selectedFolder.data)}).subscribe(r => {
      if(r.length > 0 ) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Silmeden önce yetkilendirilmiş kullanıcıları kaldırmalısınız." });
        return;
      }

      this.confirmService.confirm({
        message: 'Klasörü silmek istediğinize emin misiniz?',
        accept: () => {
          this.service.deleteFolder(Number(this.selectedFolder.data)).subscribe(() => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Klasör silindi." });
            this.getParentFolders();
          }, () => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Bir hata oluştu. Tekrar deneyin." });
          })
        }
      })
    })
 ;


  }

  loadNode(event) {
    if (event.node) {
        this.getChildFolders(Number(event.node.data)).subscribe(r => {
          event.node.children = this.toTreeNode(r);
        })
    }
  }

  getParentFolders() {
    this.isLoading = true;
    this.service.getParentFolders().subscribe(r => {
      this.folders = this.toTreeNode(r);
      this.folders.forEach(f => {
        this.service.getChildFolders(Number(f.data)).subscribe(c => f.children = this.toTreeNode(c));
      })
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  getChildFolders(parentFolderId: number) {
    return this.service.getChildFolders(parentFolderId)
  }

  getFilesByFolder(folderId) {
    this.isLoading = true;
    this.folderFiles = [];
    this.service.getQdmsFilesByFolder(folderId).subscribe(r => {

      this.allFolderFiles = r
      this.allFolderFiles.forEach(f => {
        // hiç revizyon görmemiş dosyaları filtrele.
        if(!f.revizedFile) {
          const isRevized = this.allFolderFiles.findIndex(q =>  q.revizedFile && q.revizedFile.id == f.id)
          if(isRevized < 0) this.folderFiles.push(f);
        } else {

          // revizyon görmüş dosyalardan son revizyonu olan dosyalar filtrele.
          const revizedFile =  this.allFolderFiles.filter(r =>  r.revizedFile &&  r.revizedFile.id ==  f.revizedFile.id);
          const lastRevizedFile = revizedFile.sort((a,b) => { return new Date(b.revisionDate).getTime() - new Date(a.revisionDate).getTime()})[0];
          if(this.folderFiles.findIndex(f => f.id == lastRevizedFile.id) < 0) this.folderFiles.push(lastRevizedFile);
        }
      });
    console.log(this.folderFiles, "files")
      this.isLoading = false;

    });
  }

  toTreeNode(data: Folder[]) {
    const treeData: TreeNode[] = [];
    if(data.length > 0) {
      data.forEach(q => treeData.push(
        {
          "label": q.name,
          "data": q.id,
          "expandedIcon": "pi pi-folder-open",
          "collapsedIcon": "pi pi-folder",
          "leaf": false
        }
      ));
    }
    return treeData;
  }

  onShowFileContext(event, cm, file: QdmsFile) {


    const revIndx = this.fileContext.findIndex(f => f.label == 'Revizyon Yap');
    if(revIndx > -1) this.fileContext.splice(revIndx,1);


    const historyIndex = this.fileContext.findIndex(f => f.label == 'Revizyon Tarihçesi');
    if(historyIndex > -1) this.fileContext.splice(historyIndex,1);

    if(['doc', 'docx'].includes(file.fileExtension)) {
      this.fileContext.push({label: 'Revizyon Yap',icon: 'pi pi-fw pi-pencil',command: (event) => { this.onFileDialog(file)}})

      // add revized files
      const revizedFiles = this.getRevizedFiles(file);
      if(revizedFiles.length > 0) {
        this.fileContext.push({
          label: `Revizyon Tarihçesi`,
          icon: 'pi pi-fw pi-list',
          items:  this._qdmsFileToContext(revizedFiles)
        })
      }

    }

    this.selectedFile = file;
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return;
  }

  _qdmsFileToContext(files: QdmsFile[]) {
    const contextMenuItems = [];
    files.forEach(f => {
      contextMenuItems.push({
        label: `${f.name} ${f.revisionDate ? new Date(f.revisionDate).toLocaleDateString() : ''}`,
        icon: 'pi pi-fw pi-eye',
        command: (event) => { this.onShowFileViewer(f)},
      })
    });
    return contextMenuItems;
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  downloadFile() {
    if(!this.selectedFile) return;
    this.service.getFile(this.selectedFile.fileId).subscribe(r => {
      const byteCharacters = this._base64ToArrayBuffer(r.obj.file.data);
      var blob = new Blob([byteCharacters], { type: r.obj.contentType });
      FileSaver.saveAs(blob, r.obj.fileName);
    });
  }
  deleteFile() {
    this.confirmService.confirm({
      message: 'Silmek istediğinize emin misiniz?',
      accept: () => {
        this.isLoading = true;
        this.service.deleteFile(this.selectedFile).subscribe(() => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Klasör silindi." });
          this.getFilesByFolder(this.selectedFolderId);
        }, (err) => {
          this.isLoading = false;
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: err.error.message });
        })

      }
    })
  }

  searchFileByName(name: string) {
    if(!name) return;

    this.isLoading = true;
    this.folderFiles = [];
    this.service.searchFileByName(name).subscribe(r => {

      this.allFolderFiles = r
      this.allFolderFiles.forEach(f => {
        // hiç revizyon görmemiş dosyaları filtrele.
        if(!f.revizedFile) {
          const isRevized = this.allFolderFiles.findIndex(q =>  q.revizedFile && q.revizedFile.id == f.id)
          if(isRevized < 0) this.folderFiles.push(f);
        } else {

          // revizyon görmüş dosyalardan son revizyonu olan dosyalar filtrele.
          const revizedFile =  this.allFolderFiles.filter(r =>  r.revizedFile &&  r.revizedFile.id ==  f.revizedFile.id);
          const lastRevizedFile = revizedFile.sort((a,b) => { return new Date(b.revisionDate).getTime() - new Date(a.revisionDate).getTime()})[0];
          if(this.folderFiles.findIndex(f => f.id == lastRevizedFile.id) < 0) this.folderFiles.push(lastRevizedFile);
        }
      });
      console.log(this.folderFiles, "result files")
      this.isLoading = false;

    });
  }
  onShowFileViewer(file: QdmsFile = null) {

    this.dialog.open(FileViewerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: ['full-screen-modal'],
      data: file || this.selectedFile
    });
  }

  getRevizedFiles(file: QdmsFile): QdmsFile[] {
    if(!file.revizedFile) return [];
    return this.allFolderFiles.filter(f =>  f.id == file.revizedFile.id || (f.revizedFile && f.revizedFile.id == file.revizedFile.id))
  }

  onNodeContextMenuSelect(event) {
    this.selectedFolderId = this.selectedFolder.data;
  }

}
