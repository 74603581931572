import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetAdvance } from '../models/intranet-advance.model';
import { IntranetDocument } from '../models/intranet-document.model';
import { IntranetPage } from '../models/intranet-page.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';


@Injectable()
export class IntranetAdvanceService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'advance',
            httpClient);
    }


    get(id: number): Observable<IntranetAdvance> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/get?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetAdvance>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    delete(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/delete?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    create(document: IntranetAdvance): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            intranetAdvance: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createAdvance";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    update(obj: IntranetAdvance): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            intranetAdvance: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateAdvance";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getMyAdvances() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getMyAdvances?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<IntranetAdvance[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getApprovalEntities() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getApprovalEntities?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<IntranetAdvance[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    approve(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/approve?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    disApprove(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/disApprove?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}