import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';;
import { QdmsService } from 'src/app/services/qdms.service';
import {FileDialogComponent} from "../file-dialog/file-dialog.component";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  @Input() accept: string;
  @Input() auto: boolean = false;
  @Input() entityId: number;
  @Output() selectedFile: EventEmitter<File> = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: any;
  uploadedFiles: any[] = [];
  isUploadBtnDisabled: boolean = false;



  constructor(private messageService: MessageService, private service: QdmsService, private fileDiag: FileDialogComponent) { }

  ngOnInit(): void {

    this.fileDiag.clearUploadFileEmitter.subscribe(msg => {
      this.fileUpload.clear();
    });
  }

  onSelect(event) {
    if(this.auto) return;
    if(event.currentFiles && event.currentFiles.length > 0) {
      this.selectedFile.emit(event.currentFiles[0] as File);
    } 
  }

}
