import { AddressCard } from "./address-card-model";
import { SimpleEntity } from "./simple-entity.model";

export class CompanyDTOModel{
    id: number;
    name: string;
    city: string;
    code?: string;

    status: string;
    intelligence: string
    title: string
    taxNo: string
    registerNo: string
    tckn: string;
    eoriCode: string
    edifactCode: string
    notes: string
    operationNotes: string
    exchangeRateType: string
    mersisNo: string
    tradeRegistryNo: string
    einvoiceProfileCode: string;
    sourceList: string
    isEInvoice: boolean;
    isIntegrationFromCargomax: boolean;
    isCreatedFromLead: boolean;
    isEIrsaliye: boolean;
    isUseLimit: boolean;
    isUseDue: boolean;
    
    scac: string;
    channel: string;


    einvoiceStart: Date;
    einvoiceEnd: Date;
    firstIntegrationDate: Date;
    lastUpdateDate: Date;
    firstInvoiceDate: Date;
    lastInvoiceDate: Date;
    lastGoodDate: Date;
    firstGoodDate: Date;

    

    subSourceTypeCompany: SimpleEntity;
    subSourceTypeCompanyPerson: SimpleEntity;
    subSourceTypeUser: SimpleEntity;
    invoiceGroup: SimpleEntity;
    branch: SimpleEntity;
    bank: SimpleEntity;
    currency: SimpleEntity;
    taxOffice: SimpleEntity;
    master: SimpleEntity;
    defaultAddressCard: SimpleEntity;
    defaultAddresCardDetail: AddressCard
    createUser: SimpleEntity;
    proposerUser: SimpleEntity;
    lastUpdateUser: SimpleEntity;
    salesChannel: SimpleEntity;
    mainProcess: SimpleEntity;
    mainProcessRef: SimpleEntity;

    addressCards: SimpleEntity[];
    persons: SimpleEntity[];
    companyTypes: SimpleEntity[];
    industries: SimpleEntity[];
    regions: SimpleEntity[];
    departments: SimpleEntity[];
    earsivEmails: SimpleEntity[];
    customCompanies: SimpleEntity[];
}