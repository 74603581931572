import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory } from 'src/app/models/expense.model';
import { Board, Issue } from 'src/app/models/issue-management-model';
import { ExpenseService } from 'src/app/services/expense.service';
import { IssueManagementService } from 'src/app/services/issue-management.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { CreateExpensePackageCategoryComponent } from '../../expense/modals/create-expense-package-category/create-expense-package-category.component';
import { CreateIssueComponent } from '../create-issue/create-issue.component';

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent implements OnInit {

  boards: Issue[] = [];

  selectedCategories: ExpensePackageCategory[] = [];

  dataSource = new MatTableDataSource<Issue>();

  displayedColumns = ['summary', 'issueType', 'issuePriority', 'status', 'actions'];

  constructor(private expenseService: ExpenseService, private issueManagementService: IssueManagementService,
    private messageService: MessageService, public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.getAllIssues();

  }

  getAllIssues() {
    this.issueManagementService.getAllIssues().subscribe(res => {
      this.boards = res;
      this.dataSource.data = this.boards;
    })
  }

  deleteCategory(advance: ExpensePackageCategory) {


  }


  openCreateIssue(issue?: any) {
    let dialogRef = this.dialog.open(CreateIssueComponent, {
      width: '1200px',
      autoFocus: false,
      data: {
        issue: issue,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      this.getAllIssues();



    });
  }

  openConfirmDialog(issue: Issue): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = issue.summary;
    dialogRef.componentInstance.fIndex = issue.id;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.issueManagementService.deleteIssue(issue.id).subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Görev Silindi" });
          this.getAllIssues();
        })
      }
    });
  }

}
