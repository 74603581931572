import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetWidgetUserGroup } from '../models/intranet-widget-group.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';


@Injectable()
export class IntranetWidgetService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'widget',
            httpClient);
    }

    getAllWidgets(): Observable<IntranetWidget[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllWidgets?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<IntranetWidget[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getUserRoles(): Observable<any[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getUserRoles?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getWidgetRoles(): Observable<any[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getWidgetRoles?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAvailableWidgets(): Observable<IntranetWidget[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem("user"));

        var userRoles = user.userRoles as any[];

        var userRoleIds = userRoles.map(u => u.id).join(';');

        var url = environment.baseUrl + "/" + this.endpoint + "/getAvailableWidgets?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id + "&rolesString=" + userRoleIds + "";

        return this.httpClient.get<IntranetWidget[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    createWidget(widget: IntranetWidget): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: widget
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createWidget";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateWidget(widget: IntranetWidget): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: widget
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateWidget";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteWidget(id: number): Observable<IntranetWidget[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteWidget?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetWidget[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    createWidgetUserGroup(widget: IntranetWidgetUserGroup): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: widget
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createWidgetUserGroup";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateWidgetUserGroup(widget: IntranetWidgetUserGroup): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: widget
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateWidgetUserGroup";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateSelectedWidgets(selectedWidgets: IntranetWidget[]): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem("user"));

        var entity = {
            token: token,
            sessionId: sessionId,
            selectedWidgets: selectedWidgets,
            userId: user.id
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateSelectedWidgets";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}