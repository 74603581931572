import { SimpleEntity } from "./simple-entity.model";
export class CompanyMobileDTO {

    id: number;
    name: string;
    master:SimpleEntity;
    taxNo: string;
    tcKn: string;
    taxOffice: SimpleEntity
    addressId: number;
    address: string;
    email: string;
    telephone: string;
    contactPerson: string;
    district: SimpleEntity;
    city: SimpleEntity;
    country: SimpleEntity;
    salesRepresentative: SimpleEntity;
    status: string;
    addressDetail: string;
    registerNo: string;
    companyTypes: SimpleEntity[];
    industries: SimpleEntity[];
    sourceList:string;
    subSource: SimpleEntity;
    emails: any[];


}
