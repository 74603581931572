import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { CompanyMinimizedModel } from 'src/app/models/company-minimized.model';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyDTOModel } from 'src/app/models/company-dto-model';
import { MessageService } from 'primeng/api';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomerComponent } from 'src/app/shared/create-customer/create-customer.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomersComponent implements OnInit {

  isLoading: boolean = false;
  currentPage:number = 0;
  customers:CompanyMinimizedModel[] = [];
  isActiveNextPageBtn:boolean = false;
  searchValue: string = "";
  customer: CompanyDTOModel = new CompanyDTOModel();
  transportDirection = 'I';

  stateOptions = [
    { label: 'existingCustomer', value: 'existingCustomer' },
    { label: 'companyCustomers', value: 'companyCustomers' },
  ];
  status = {"W":"Beklemede","A":"Aktif","P":"Pasif","L":"Potansiyel","B":"Blacklist","N":"Yeni"}
  selectedState: string = "existingCustomer";

  userTransportDirection = JSON.parse(localStorage.getItem('user')).transportDirection || 'I';

  constructor(private activityService: ActivityService, private router: Router,
      private dialog: MatDialog,
      private companyService: CompanyService,
      private messageService: MessageService,
      public _location: Location,
      public activatedRoute: ActivatedRoute) {

      this.customer.id = this.activatedRoute.snapshot.queryParams.id;
      this.transportDirection = this.activatedRoute.snapshot.queryParams.transportDirection;

  }

  async ngOnInit() {
    this.isLoading = true;
    if(this.customer.id) {
      this.customer =  await (await this.companyService.getById(this.customer.id, this.transportDirection).toPromise()).body;

      if(!this.customer?.defaultAddresCardDetail?.address?.city || !this.customer?.defaultAddresCardDetail?.address?.district) {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Adres Bulunamadı', detail: `${this.customer.name} için il veya ilçe bilgisi bulunamadı!` });
        return;
      }
      this.customers = await (await this.companyService.search({'defaultAddressCard.address.city.id': this.customer.defaultAddresCardDetail.address.city.id, 'defaultAddressCard.address.district.id': this.customer.defaultAddresCardDetail.address.district.id}, this.userTransportDirection).toPromise());
    } else {
      this.customers  = await (await this.getCustomers(this.currentPage));
    }

    this.isLoading = false;
  }

  async next() {
    this.currentPage++;
    let nextPageData: CompanyMinimizedModel[] = [];
    if(this.selectedState == 'existingCustomer') {
      nextPageData = await (await this.getCustomers((this.currentPage)));
    } else {
      nextPageData = await (await this.searchPoolCustomer((this.currentPage)));
    }

    this.customers = nextPageData;

    this.isActiveNextPageBtn =  await this.isLastPage();
    if(this.isActiveNextPageBtn) return;
  }

  async prev() {
    if(this.currentPage == 0) return;
    this.currentPage--;
    let prevPageData: CompanyMinimizedModel[] = [];

    if(this.selectedState == 'existingCustomer') {
      prevPageData = await (await this.getCustomers((this.currentPage)))
    } else {
      prevPageData = await (await this.searchPoolCustomer((this.currentPage)));
    }

    this.customers = prevPageData;
    this.isActiveNextPageBtn = false;

  }

  async reset() {
    this.currentPage = 0;
    this.isActiveNextPageBtn =  await this.isLastPage();
    this.customers =  await (await this.getCustomers((this.currentPage)))
  }

  async isLastPage() {
    const nextPage = this.currentPage +1;
    const customers: CompanyMinimizedModel[] = await (await this.getCustomers((nextPage)));
    return customers.length == 0;
  }

  isFirstPage(): boolean {
    return this.currentPage == 0;
  }

  async getCustomers(page) {
      this.isLoading = true;
    let customers: CompanyMinimizedModel[] = [];
    if(this.selectedState == 'existingCustomer') {

        customers =  customers.concat(await this.activityService.myCustomerList(page,
            this.transportDirection ? this.transportDirection : this.userTransportDirection, this.searchValue).toPromise())

      // if(localStorage.getItem('import')) {
      // customers =  customers.concat(await this.activityService.myCustomerList(page, 'I', this.searchValue).toPromise())
      // customers =  customers.concat(await this.activityService.myCustomerList(page, 'E', this.searchValue).toPromise())
      // }
      // if(localStorage.getItem('export')) {
      //   customers =  customers.concat(await this.activityService.myCustomerList(page, 'E', this.searchValue).toPromise())
      //   customers =  customers.concat(await this.activityService.myCustomerList(page, 'I', this.searchValue).toPromise())
      // }
      // if(!localStorage.getItem('export') && !localStorage.getItem('import')) {
      //   customers =  customers.concat(await this.activityService.myCustomerList(page, null, this.searchValue).toPromise())
      // }
        this.isLoading = false;
      return customers;

    }
      this.isLoading = false;
    return [];
  }
  async onSearchCustomers() {
    this.customers =  await (await this.getCustomers((this.currentPage)))
  }

  async searchPoolCustomer(page = 0) {
    if(!this.searchValue || this.searchValue == '') {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Arama kriteri belirtilmeli!` });
      return null;
    }
    this.isLoading = true;

    try {

        this.customers = await this.activityService.searchPoolCustomerByName(this.searchValue, page,
            this.transportDirection ? this.transportDirection : this.userTransportDirection).toPromise();

      // if(localStorage.getItem('orc')) {
      //   this.customers = await this.activityService.searchPoolCustomerByName(this.searchValue, page, 'orc').toPromise();
      // } else {
      //
      //   if(!localStorage.getItem('I') && !localStorage.getItem('E')) {
      //     this.customers = await this.activityService.searchPoolCustomerByName(this.searchValue, page).toPromise();
      //   }
      //
      //   if(localStorage.getItem('I')) {
      //     this.customers = await this.activityService.searchPoolCustomerByName(this.searchValue, page, 'I').toPromise();
      //   }
      //
      //   if(localStorage.getItem('E')) {
      //     this.customers = this.customers.concat(await this.activityService.searchPoolCustomerByName(this.searchValue, page, 'E').toPromise());
      //   }
      // }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }

    return this.customers;
  }

  async onSelectState() {
      this.currentPage = 0;
    this.reset();
  }

  navigateDetail(customer: CompanyMinimizedModel, transportDirection: string){
    this.router.navigate(['/activities/customers/edit/' + transportDirection + '/' + customer.id + '/' +(customer.registerNo || '') ]);
  }

  hasMultipleDomain() {
    return ((localStorage.getItem('export') || localStorage.getItem('import') )&& !localStorage.getItem('orc'));
  }
  filterByTransportDirection(transportDirection: string) {
    return this.customers.filter(f => f.transportDirection == transportDirection)
  }
  hasOrcDomain() {
    return localStorage.getItem('orc');
  }
  showCreateCustomerDialog() {
    let dialogRef = this.dialog.open(CreateCustomerComponent, {
      panelClass: 'modal-lg',
      data: {}
    });
  }

    @HostListener('document:keydown.enter')
    changeTransportDirection(event: any) {
        console.log(event)
        if (event?.index === 0) {
            this.transportDirection = 'I';
        } else if (event?.index === 1) {
            this.transportDirection = 'E';
        }
        if (this.selectedState === 'existingCustomer') {
            this.currentPage = 0;
            this.onSearchCustomers();
        } else {
            this.searchPoolCustomer(0);
        }
    }
}
