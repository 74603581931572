import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ExpensePackageItem } from 'src/app/models/expense.model';
import { IntranetLesson, IntranetLessonVideo } from 'src/app/models/learning.model';
import { FileDialogConfirmComponent } from 'src/app/pages/document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-create-expense-package-item',
  templateUrl: './create-expense-package-item.component.html',
  styleUrls: ['./create-expense-package-item.component.scss']
})
export class CreateExpensePackageItemComponent implements OnInit {

  folderName: string;

  isEdit = false;

  private basePath = '/uploads/expense/images';

  uploadedFiles: any[] = [];

  files: File[] = [];

  entity: IntranetLessonVideo = <IntranetLessonVideo>{};

  videos: IntranetLessonVideo[] = [];

  model: ExpensePackageItem = <ExpensePackageItem>{
    spendDate: new Date()
  };

  isProgressActive = false;

  constructor(public dialogRef: MatDialogRef<CreateExpensePackageItemComponent>, private learningService: LearningService, @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog,
    private messageService: MessageService) {

    if (data && data.expenseItem) {

      this.isEdit = true;
      this.model = data.expenseItem;

    }

  }

  ngOnInit(): void {

  }



  onFileChange(pFileList: File[]) {

    var file = pFileList[0];

    this.uploadedFiles.push(file);

    const filePath = `${this.basePath}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);

    uploadTask.percentageChanges().subscribe(res => {

      this.uploadProgress = res;

    })


    this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {

      if (res.state == 'success') {

        storageRef.getDownloadURL().subscribe(url => {

          this.model.attachmentUrl = url;

          this.setFileInfo(file);

          this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

          this.files.push(file);

        });

      }

    });
  }

  myVideos = [];

  uploadProgress: number = 0;

  setFileInfo(file: File) {
    this.myVideos.push(file);
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;

      var durationRounded = Math.round((duration + Number.EPSILON) * 100) / 100

      var durationMilliseconds = durationRounded * 1000;

      console.log('Duration: ' + durationMilliseconds);

      this.entity.videoDuration = durationMilliseconds;
    }

    video.src = URL.createObjectURL(file);;
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }

  openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  isLoading = false;

  save() {

    if (!(this.model.reasonForSpending && this.model.reasonForSpending.length > 0)) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Harcama Nedeni Boş Geçilemez!" });
      return;

    }

    if (!(this.model.amount)) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Tutar Boş Geçilemez!" });
      return;

    }

    this.dialogRef.close({
      model: this.model,
      isEdit: this.isEdit
    });

  }

}
