import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Announcement } from 'src/app/models/announcement.model';
import { Leave } from 'src/app/models/leave-models/leave.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';
import { IntranetLeaveService } from 'src/app/services/leave-service';

@Component({
  selector: 'app-list-announcement',
  templateUrl: './list-announcement.component.html',
  styleUrls: ['./list-announcement.component.scss']
})
export class ListAnnouncementComponent implements OnInit {

  entities: Announcement[] = [];

  selectedAdvances: Announcement[] = [];

  constructor(private leaveService: IntranetLeaveService, private announcementService: AnnouncementService, private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {

    this.getMyAvailableAnnouncements();

  }

  getMyAvailableAnnouncements() {

    var user = JSON.parse(localStorage.getItem('user'));

    this.announcementService.getMyAvailableAnnouncements(user.id, false).subscribe(res => {
      this.entities = res;
    })
  }

  goToAnnouncementDetail(announcement: Announcement) {
    localStorage.setItem("announcement", JSON.stringify(announcement));
    this.router.navigate(["company/announcement/announcement-detail"]);

  }


}
