import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Customer } from 'src/app/demo/domain/customer';
import { Router } from '@angular/router';
import { Product } from 'src/app/demo/domain/product';
import { CustomerService } from 'src/app/demo/service/customerservice';
import { ProductService } from 'src/app/demo/service/productservice';
import { Activity } from 'src/app/models/activity.model';
import { Company } from 'src/app/models/company.model';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { CompanyService } from 'src/app/services/company.service';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { ListPriceOfferSeaService } from 'src/app/services/list-price-offer-sea.service';
import { DatePipe } from '@angular/common';
import { SeaFreightBuyTariffDetMobile } from 'src/app/models/sea-freight-buy-tariff-det';

@Component({
  selector: 'app-list-price-offer-sea',
  templateUrl: './list-price-offer-sea.component.html',
  styleUrls: ['./list-price-offer-sea.component.scss']
})
export class ListPriceOfferSeaComponent implements OnInit {
  filteredSeaport: SeaportMinimizedModel[] = [];
  seaport: SeaportMinimizedModel[] = [];
  products: Product[];

  seaFreightBuyTariffDetMobile: SeaFreightBuyTariffDetMobile[] = [];

  customers1: Customer[];

  customers2: Customer[];

  customers3: Customer[];

  rowGroupMetadata: any;

  seaOfferValues: number[] = [0, 100];

  seaOfferList: Activity[] = [];

  selectedSeaOffers: Activity[];

  selectedCustomer: Customer;

  etd: Date = new Date();
  fromPort : SeaportMinimizedModel;
  toPort : SeaportMinimizedModel;
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  items: MenuItem[];
  shipOwner: Company;
  customer: Company;
  constructor(private customerService: CustomerService,
    private productService: ProductService,
    private companyService: CompanyService,
    private offerExportService: OfferExportService,
    private router: Router,
    private messageService: MessageService,
    private listPriceOfferSeaService: ListPriceOfferSeaService,
    private datePipe: DatePipe) { }

  ngOnInit() {}

  onSearch() {
    // if(!this.etd) {
    //   this.messageService.add({ key: 'tst', severity: 'error', summary: 'Uyarı', detail: 'Lütfen tarih aralığı giriniz!' });
    //   return;
    // }

    this.listPriceOfferSeaService.getWithParams( this.datePipe.transform(this.etd, 'yyyy-MM-dd'),  (this.fromPort?.id || null), (this.toPort?.id || null), (this.shipOwner?.id || null), (this.customer?.id || null)).subscribe(res => {
      console.dir("listPriceOfferSeaService.getWithParams")
      this.seaFreightBuyTariffDetMobile = res;
      console.dir(this.seaFreightBuyTariffDetMobile, "ALALALALALLA")
    })
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.customers3) {
      for (let i = 0; i < this.customers3.length; i++) {
        const rowData = this.customers3[i];
        const representativeName = rowData.representative.name;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          const previousRowData = this.customers3[i - 1];
          const previousRowGroup = previousRowData.representative.name;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          }
          else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  goDetails(activity: Activity) {
    this.router.navigate([`activities/offer-request/activity/${activity.id}`], {state: {activity: activity}});
  }

  filterSeaport(event) {
    if (event.query.length < 3) return;

    this.offerExportService.getOfferExportSeaport(event.query).subscribe(res => {
      this.filteredSeaports = res.body;
      this.seaports = res.body;
      const filtered: SeaportMinimizedModel[] = [];
      const query = event.query;
      this.seaports.forEach(country => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(country);
        }
      });
      this.filteredSeaports = filtered;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.companies = res;
      this.filteredCompanies = res;
      const filtered: Company[] = [];
      const query = event.query;
      this.companies.forEach(country => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(country);
        }
      });
      this.filteredCompanies = filtered;
    });
  }

}
