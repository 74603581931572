export enum Incoterms {
    CFR = 'CFR',
    CIF = 'CIF',
    CTP = 'CTP',
    CPT = 'CPT',
    DAF = 'DAF',
    DAP = 'DAP',
    DAT = 'DAT',
    DDP = 'DDP',
    DDU = 'DDU',
    DEO = 'DEO',
    DES = 'DES',
    DPU = 'DPU',
    EXW = 'EXW', 
    FAS = 'FAS',
    FCA = 'FCA',
    FOB = 'FOB'
}