import {Component, EventEmitter, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-offer-price',
  templateUrl: './offer-price.component.html',
  styleUrls: ['./offer-price.component.scss']
})
export class OfferPriceComponent implements OnInit {

  @Input() equimenets: EventEmitter<any[]> = new EventEmitter();
  @Input() cutOff: string;

  constructor() { }

  ngOnInit(): void {
      console.log(JSON.stringify(this.equimenets));
  }

}
