import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Activity } from 'src/app/models/activity.model';
import { Allocation } from 'src/app/models/allocation.model';
import { Company } from 'src/app/models/company.model';
import { ContainerModel } from 'src/app/models/container.model';
import { DeliveryPlace } from 'src/app/models/delivery-place.model';
import { Equimenet } from 'src/app/models/equiment.model';
import { KpiDefinition } from 'src/app/models/kpi-definition.model';
import { KpiGroupDetail } from 'src/app/models/kpi-group-detail.model';
import { SeaPort } from 'src/app/models/seaport.model';
import { TextConstants } from 'src/app/models/text-contants.model';
import { ActivityService } from 'src/app/services/activity.service';
import { CompanyService } from 'src/app/services/company.service';
import { ContainerService } from 'src/app/services/container.service';
import { KpiDefinitionService } from 'src/app/services/kpi-definition.service';
import { SeaportService } from 'src/app/services/seaport.service';
import { UserService } from 'src/app/services/user.service';

export class SelectItem {
  name: string;
  value: any;
}

@Component({
  selector: 'app-create-kpi-target',
  templateUrl: './create-kpi-target.component.html',
  styleUrls: ['./create-kpi-target.component.scss']
})
export class CreateKpiTargetComponent implements OnInit {

  companies: Company[] = [];

  filteredCompanies: Company[] = [];

  selectedCompany: Company;

  shipOwners: Company[] = [];

  filteredShipOwners: Company[] = [];

  selectedShipOwner: Company;

  departureSeaport: SeaPort[] = [];

  filteredDepartureSeaport: SeaPort[] = [];

  selectedDepartureSeaport: SeaPort;

  arrivalSeaport: SeaPort[] = [];

  filteredArrivalSeaport: SeaPort[] = [];

  selectedArrivalSeaport: SeaPort;

  containers: ContainerModel[] = [];

  filteredContainers: ContainerModel[] = [];

  selectedContainer: ContainerModel;


  selectedState: any = null;

  selectedStatus: any = null;

  statusOptions: SelectItem[] = [

  ];

  selectedType: any = null;

  typeOptions: SelectItem[] = [

  ];

  values3: string[];

  equipments: Equimenet[] = [];

  equipmentsAdded: Equimenet[] = [];

  notes: string = "";

  allocation: Allocation;

  loadingList: DeliveryPlace[] = [];

  selectedLoading: DeliveryPlace;

  deliveryList: DeliveryPlace[] = [];

  selectedDeliveryPlace: DeliveryPlace;

  kpiDefinitions: KpiDefinition[] = [];

  filteredKpiDefinitions: KpiDefinition[] = [];

  filteredUsers: any[] = [];

  selectedUser: any;

  tieredItems: MenuItem[];

  selectedMonth = 1;

  customerList: any[] = [];
  truckList: any[] = [];
  seaList: any[] = [];

  kpiGroupDetail: KpiGroupDetail = <KpiGroupDetail>{};


  constructor(private messageService: MessageService, private kpiDefinitionService: KpiDefinitionService, private userService: UserService) {


  }

  ngOnInit() {


    var user = JSON.parse(localStorage.getItem('user'));

    this.kpiDefinitionService.getKpiDetail(user.id).subscribe(res => {
      console.log(res);

      var response = res as any;

      this.kpiGroupDetail = response as KpiGroupDetail;

      this.customerList = response.customerList;
      this.seaList = response.seaList;
      this.truckList = response.truckList;

    })


    this.tieredItems = [

      { separator: true },
      {
        label: 'Ocak',
        id: '1',
        command: () => {
          this.selectedMonth = 1;
        },
        escape: true,

      },
      { separator: true },
      {
        label: 'Şubat',
        id: '2',
        command: () => {
          this.selectedMonth = 2;
        }
      },
      { separator: true },
      {
        label: 'Mart',
        id: '3',
        command: () => {
          this.selectedMonth = 3;
        }
      },
      { separator: true },
      {
        label: 'Nisan',
        id: '4',
        command: () => {
          this.selectedMonth = 4;
        }
      },
      { separator: true },
      {
        label: 'Mayıs',
        id: '5',
        command: () => {
          this.selectedMonth = 5;
        }
      },
      { separator: true },
      {
        label: 'Haziran',
        id: '6',
        command: () => {
          this.selectedMonth = 6;
        }
      },
      { separator: true },
      {
        label: 'Temmuz',
        id: '7',
        command: () => {
          this.selectedMonth = 7;
        }
      },
      { separator: true },
      {
        label: 'Ağustos',
        id: '8',
        command: () => {
          this.selectedMonth = 8;
        }
      },
      { separator: true },
      {
        label: 'Eylül',
        id: '9',
        command: () => {
          this.selectedMonth = 9;
        }
      },
      { separator: true },
      {
        label: 'Ekim',
        id: '10',
        command: () => {
          this.selectedMonth = 10;
        }
      },
      { separator: true },
      {
        label: 'Kasım',
        id: '11',
        command: () => {
          this.selectedMonth = 11;
        }
      },
      { separator: true },
      {
        label: 'Aralık',
        id: '12',
        command: () => {
          this.selectedMonth = 12;
        }
      },
    ];



    this.statusOptions.push({ name: "Aktif", value: 'ACTIVE' });
    this.statusOptions.push({ name: "Pasif", value: 'PASSIVE' });

    this.selectedStatus = this.statusOptions[0];

    this.typeOptions.push({ name: "Satış", value: 'SL' });
    this.typeOptions.push({ name: "Operasyon", value: 'OP' });
    this.typeOptions.push({ name: "Diğer", value: 'DG' });

    this.selectedType = this.typeOptions[0];

  }

  isLoading = false;

  save() {

    this.isLoading = true;

    console.log(this.kpiGroupDetail);

    this.kpiDefinitionService.updateKpiGroup(this.kpiGroupDetail).subscribe(res => {

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Kaydedildi' });

      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Kaydedilirken bir hata oluştu' });
    })


  }


  filterKpiDefinitions(event) {
    const filtered: KpiDefinition[] = [];
    const query = event.query;

    this.kpiDefinitionService.searchKpiDefinitions(query).subscribe(res => {
      this.filteredKpiDefinitions = res;
    })
  }

  filterUsers(event) {
    const query = event.query;

    this.userService.searchUsers(query).subscribe(res => {
      this.filteredUsers = res;
    })
  }

  filterCompany(event) {
    const filtered: Company[] = [];
    const query = event.query;
    for (let i = 0; i < this.companies.length; i++) {
      const country = this.companies[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCompanies = filtered;
  }

}
