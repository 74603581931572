import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IntranetWidget } from 'src/app/models/intranet-widget.model';
import { IntranetWidgetService } from 'src/app/services/intranet-widget.service';

@Component({
  selector: 'app-edit-widgets',
  templateUrl: './edit-widgets.component.html',
  styleUrls: ['./edit-widgets.component.scss']
})
export class EditWidgetsComponent implements OnInit {

  constructor(private intranetWidgetService: IntranetWidgetService, private messageService: MessageService) {

  }

  widgets: IntranetWidget[] = [];

  selectedWidgets: IntranetWidget[] = [];

  isLoading = false;

  ngOnInit(): void {

    this.intranetWidgetService.getAvailableWidgets().subscribe(res => {

      console.log(res);

      this.widgets = res;

      this.widgets.forEach(item => {

        if (item.selected) {
          this.selectedWidgets.push(item);
        }

      })



    })

  }

  save() {

    this.isLoading = true;

    this.intranetWidgetService.updateSelectedWidgets(this.selectedWidgets).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Widget Ayarları Kaydedildi" });

    }, err => {

      console.log(err);
      this.isLoading = false;

    })


  }

}
