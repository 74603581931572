import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetAdvance } from '../models/intranet-advance.model';
import { IntranetDocument } from '../models/intranet-document.model';
import { IntranetPage } from '../models/intranet-page.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { Issue, IssueColumn } from '../models/issue-management-model';
import { CreateLessonQuizRequest, IntranetLesson, IntranetLessonCategory, IntranetLessonVideo, IntranetLessonVideoUserState, QuizUserResult } from '../models/learning.model';
import { Person } from '../models/person.model';
import { Board } from '../pages/issue-management/models/board.model';
import { BaseService } from './base.service';


@Injectable()
export class IssueManagementService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'issueManagement',
            httpClient);
    }


    getBoard(id: number): Observable<Board> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getBoard?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Board>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllBoards(): Observable<Board[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllBoards?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<Board[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createBoard(obj: Board): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createBoard";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateBoard(obj: Board): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateBoard";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteBoard(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteBoard?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }






    getColumn(id: number): Observable<IssueColumn> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getColumn?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IssueColumn>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getColumnsByBoardId(boardId: number): Observable<IssueColumn[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getColumnsByBoardId?token=" + token + "&sessionId=" + sessionId + "&boardId=" + boardId;

        return this.httpClient.get<IssueColumn[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createColumn(obj: IssueColumn): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createColumn";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateColumn(obj: IssueColumn): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateColumn";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteColumn(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteColumn?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    getIssue(id: number): Observable<Issue> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getIssue?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Issue>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getIssuesByColumnId(columnId: number): Observable<Issue[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getIssuesByColumnId?token=" + token + "&sessionId=" + sessionId + "&columnId=" + columnId;

        return this.httpClient.get<Issue[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getIssuesByAssigneeId(assigneeId: number): Observable<Issue[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getIssuesByAssigneeId?token=" + token + "&sessionId=" + sessionId + "&assigneeId=" + assigneeId;

        return this.httpClient.get<Issue[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getIssuesByReporterId(reporterId: number): Observable<Issue[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getIssuesByReporterId?token=" + token + "&sessionId=" + sessionId + "&reporterId=" + reporterId;

        return this.httpClient.get<Issue[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createIssue(obj: Issue): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createIssue";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateIssue(obj: Issue): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateIssue";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteIssue(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteIssue?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllIssues(): Observable<Issue[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllIssues?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<Issue[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


}