import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExpensePackage, ExpensePackageCategory, ExpensePackageItem } from 'src/app/models/expense.model';
import { ExpenseService } from 'src/app/services/expense.service';
import { CreateExpensePackageItemComponent } from '../modals/create-expense-package-item/create-expense-package-item.component';
import { ExpensePackageItemInfoComponent } from '../modals/expense-package-item-info/expense-package-item-info.component';

@Component({
  selector: 'app-expense-package-approve',
  templateUrl: './expense-package-approve.component.html',
  styleUrls: ['./expense-package-approve.component.scss']
})
export class ExpensePackageApproveComponent implements OnInit {

  model: ExpensePackage = <ExpensePackage>{
    startDate: new Date(),
    endDate: new Date()
  };

  advanceTypes: any[] = [];

  selectedAdvanceType: any;

  expenseCategories: ExpensePackageCategory[] = [];

  advanceUseDate: Date = new Date();

  expensePackageItems: ExpensePackageItem[] = [];

  selectedExpenseCategory: ExpensePackageCategory;

  displayedColumns = ['spendDate', 'reasonForSpending', 'amount', 'actions'];

  dataSource = new MatTableDataSource<ExpensePackageItem>();

  totalAmount: number = 0;

  expensePackageId: number = 0;

  expensePackageCategory: ExpensePackageCategory = <ExpensePackageCategory>{};

  @ViewChild('filter', { static: true }) filter: ElementRef;

  constructor(public dialog: MatDialog, private messageService: MessageService, private expenseService: ExpenseService, private route: ActivatedRoute,
    private router: Router) {


    this.route.params.subscribe(res => {
      var id = res.id;

      this.expensePackageId = id;

      this.expenseService.getExpensePackage(id).subscribe(res => {
        this.model = res;

        this.model.approvedTotalAmount = this.model.totalAmount;

        this.expenseService.getExpensePackageCategory(this.model.expensePackageCategoryId).subscribe(res => {
          this.expensePackageCategory = res;
        })
      })

      this.expenseService.getExpensePackageItemsByPackageId(id).subscribe(res => {

        this.expensePackageItems = res;

        this.dataSource.data = this.expensePackageItems;

        this.totalAmount = 0;

        this.expensePackageItems.forEach(item => {
          this.totalAmount += item.amount;
        })

      })



    });

  }

  approve() {
    this.model.state = 1;
    this.expenseService.approve(this.model.id).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Masraf talebi onaylandı' });

      setTimeout(() => {

        this.router.navigate(['/expense/approval-expense-packages'])

      }, 1500)
    })
  }

  reject() {
    this.model.state = 2;
    this.expenseService.disApprove(this.model.id).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Masraf talebi reddedildi' });

      setTimeout(() => {

        this.router.navigate(['/expense/approval-expense-packages'])

      }, 1500)
    })
  }

  ngOnInit(): void {

    this.getExpenseCategories();


    this.dataSource.data = this.expensePackageItems;

  }

  getExpenseCategories() {


    this.expenseService.getExpensePackageCategories().subscribe(res => {
      this.expenseCategories = res;

      if (this.expenseCategories.length > 0) {
        this.selectedExpenseCategory = this.expenseCategories[0];
      }
    })

  }



  isLoading = false;

  save() {

    if (!this.selectedExpenseCategory) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Başarılı', detail: 'Masraf kategorisi boş geçilemez' });
      return;
    }

    this.model.startDate = this.advanceUseDate;

    this.isLoading = true;

    this.model.expensePackageCategoryId = this.selectedExpenseCategory.id;

    var user = JSON.parse(localStorage.getItem('user'));

    this.model.userId = user.id;

    this.model.totalAmount = this.totalAmount;

    this.model.state = 0;

    this.model.version = 0;

    this.model.approvedTotalAmount = 0;

    this.model.expensePackageItemList = this.expensePackageItems;


    this.expenseService.createExpensePackage(this.model).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Gönderildi' });
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }
  openLessonVideoDialog(expenseItem?: any) {
    let dialogRef = this.dialog.open(ExpensePackageItemInfoComponent, {
      width: '1200px',
      data: {
        expenseItem: expenseItem,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      if (resObject) {

        var isEdit = resObject.isEdit;
        var res = resObject.model;

        if (!isEdit) {
          this.expensePackageItems.push({
            amount: res.amount,
            attachmentUrl: res.attachmentUrl ? res.attachmentUrl : null,
            description: res.description ? res.description : null,
            expensePackageId: null,
            id: null,
            reasonForSpending: res.reasonForSpending,
            spendDate: res.spendDate,
            version: 0
          })
        }



        this.dataSource.data = this.expensePackageItems;

        this.totalAmount = 0;

        this.expensePackageItems.forEach(item => {
          this.totalAmount += item.amount;
        })
      }



    });
  }

  deleteItem(item: ExpensePackageItem, index: number) {

    this.expensePackageItems.splice(index, 1);

    this.dataSource.data = this.expensePackageItems;

    this.totalAmount = 0;

    this.expensePackageItems.forEach(item => {
      this.totalAmount += item.amount;
    })


  }

}
