import { AbstractEntity } from "../abstract-entity.model";
import { SimpleEntity } from "../simple-entity.model";

export class KpiTargetCustomerDetDTO extends AbstractEntity {

    status: string
    isApproved: boolean;
    category: string;
    companyType: string;
    lossReason: string;

    year:number;
    total: number;
    gpTotal: number;

    kpiTargetCustomer: SimpleEntity;
    kpiTargetMonth: SimpleEntity;
    customer: SimpleEntity;
    kpiCalculates: SimpleEntity[];

    totalActualTeu: number;
    totalTargetTeu: number;
    totalActualTonnage: number;
    totalTargetTonnage: number;
    totalActualFileQuantity: number;
    totalTargetFileQuantity: number;
    totalActualNewCustomer: number;
    totalTargetNewCustomer: number;
    totalActual: number;
    totalTarget: number;
}