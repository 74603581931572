import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FinancialQueryModel } from 'src/app/models/financial-query/financial-query.model';
import { environment } from 'src/environments/environment';
import {getToken} from '../token-interceptor.service';

@Injectable()
export class FinancialQueryService extends BaseService<any> {

  public queryParam: HttpParams;
  public user: any;

  constructor(public httpClient: HttpClient) {
    super('financialQuery', httpClient);

    this.user = JSON.parse(localStorage.getItem("user"));
    this.queryParam = new HttpParams().set('token', localStorage.getItem("token")).set('sessionId', localStorage.getItem("sessionId"));
  }

  get(id: number, transportDirection: string): Observable<FinancialQueryModel> {
      const token = getToken(transportDirection);
      const queryParams = new HttpParams().set('token', token.token).set('sessionId', token.sessionId).set('transportDirection', transportDirection);
      return this.httpClient.get<FinancialQueryModel>(`${environment.baseUrl}/${this.endpoint}/get?id=${id}`, { withCredentials: false, headers: this.httpHeaders(), params: queryParams })
      .pipe(
        tap(),
        catchError(this.handleError)
      )
  }


  create(queryFinancial: FinancialQueryModel, transportDirection: string): Observable<FinancialQueryModel> {
      const token = getToken(transportDirection);
      const queryParams = new HttpParams().set('token', token.token).set('sessionId', token.sessionId).set('transportDirection', transportDirection);
      return this.httpClient.post<FinancialQueryModel>(`${environment.baseUrl}/${this.endpoint}/create`, queryFinancial, { withCredentials: false, headers: this.httpHeaders(), params: queryParams });
  }

  search(params, transportDirection): Observable<FinancialQueryModel[]> {
    const token = getToken(transportDirection);
    const query = encodeURI(JSON.stringify(params));
    const queryParams = new HttpParams().set('token', token.token).set('sessionId', token.sessionId).set('transportDirection', transportDirection);
    return this.httpClient.get<FinancialQueryModel[]>(`${environment.baseUrl}/${this.endpoint}/search?query=${query}`, { withCredentials: false, headers: this.httpHeaders(), params: queryParams })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
}

  getFinancialQueryByCompanyId(companyId: number, transportDirection: string): Observable<FinancialQueryModel[]> {
    const token = getToken(transportDirection);
    const queryParams = new HttpParams().set('token', token.token).set('sessionId', token.sessionId).set('transportDirection', transportDirection);
    return this.httpClient.get<FinancialQueryModel[]>(`${environment.baseUrl}/${this.endpoint}/getFinancialQueryByCompanyId?companyId=`+ companyId, { withCredentials: false, headers: this.httpHeaders(), params: queryParams });
  }  
}
