import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory } from 'src/app/models/expense.model';
import { Issue } from 'src/app/models/issue-management-model';
import { ExpenseService } from 'src/app/services/expense.service';
import { IssueManagementService } from 'src/app/services/issue-management.service';
import { UserService } from 'src/app/services/user.service';
import { CreateExpensePackageCategoryComponent } from '../../expense/modals/create-expense-package-category/create-expense-package-category.component';

@Component({
  selector: 'app-create-issue',
  templateUrl: './create-issue.component.html',
  styleUrls: ['./create-issue.component.scss']
})
export class CreateIssueComponent implements OnInit {

  folderName: string;

  isEdit = false;

  model: Issue = <Issue>{
  };

  isProgressActive = false;

  issueTypes: any[] = [];

  selectedIssueType: any;

  priorities: any[] = [];

  selectedPriority: any;

  statusList: any[] = [];

  selectedStatus: any;

  filteredUsers: any[] = [];

  selectedUser: any;

  filteredReporterUsers: any[] = [];

  selectedReporterUser: any;

  private basePath = '/uploads/issues/';

  uploadedFiles: any[] = [];

  files: File[] = [];

  constructor(public dialogRef: MatDialogRef<CreateIssueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog, private userService: UserService, private issueManagementService: IssueManagementService,
    private messageService: MessageService) {

    if (data && data.issue) {

      this.isEdit = true;
      this.model = data.issue;

    }

  }

  ngOnInit(): void {


    this.issueTypes.push({
      label: 'Issue Tipi Seçiniz',
      value: 0
    })

    this.issueTypes.push({
      label: 'Task',
      value: 1
    })

    this.issueTypes.push({
      label: 'Story',
      value: 2
    })

    this.issueTypes.push({
      label: 'Bug',
      value: 3
    })



    this.priorities.push({
      label: 'Öncelik Seçiniz',
      value: 0
    })

    this.priorities.push({
      label: 'Çok Yüksek',
      value: 1
    })

    this.priorities.push({
      label: 'Yüksek',
      value: 2
    })

    this.priorities.push({
      label: 'Orta',
      value: 3
    })

    this.priorities.push({
      label: 'Düşük',
      value: 4
    })

    this.priorities.push({
      label: 'Çok Düşük',
      value: 5
    })

    this.statusList.push({
      label: 'Durum Seçiniz',
      value: 0
    })

    this.statusList.push({
      label: 'Todo',
      value: 1
    })

    this.statusList.push({
      label: 'In Progress',
      value: 2
    })

    this.statusList.push({
      label: 'Done',
      value: 3
    })

    if (this.isEdit) {

      this.statusList.forEach(item => {
        if (item.value == this.model.status) {
          this.selectedStatus = item.value;
        }
      })

      this.priorities.forEach(item => {
        if (item.value == this.model.issuePriority) {
          this.selectedPriority = item.value;
        }
      })

      this.issueTypes.forEach(item => {
        if (item.value == this.model.issueType) {
          this.selectedIssueType = item.value;
        }
      })

      this.userService.searchUsers('').subscribe(res => {

        res.forEach(item => {

          if (this.model.assigneeId && this.model.assigneeId == item.id) {
            this.selectedUser = item;
          }

          if (this.model.reporterId && this.model.reporterId == item.id) {
            this.selectedReporterUser = item;
          }

        })

      })

    }


  }


  filterUsers(event) {
    const query = event.query;

    this.userService.searchUsers(query).subscribe(res => {
      this.filteredUsers = res;
    })
  }

  filterRepoterUsers(event) {
    const query = event.query;

    this.userService.searchUsers(query).subscribe(res => {
      this.filteredReporterUsers = res;
    })
  }

  onFileChange(pFileList: File[]) {

    var file = pFileList[0];

    this.uploadedFiles.push(file);

    const filePath = `${this.basePath}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);

    this.isProgressActive = true;

    uploadTask.percentageChanges().subscribe(res => {

      this.uploadProgress = res;

    })


    this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {

      if (res.state == 'success') {

        storageRef.getDownloadURL().subscribe(url => {

          this.model.fileUrl = url;

          this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

          this.files.push(file);

        });

      }

    });
  }

  myVideos = [];

  uploadProgress: number = 0;

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.model.fileUrl = null;
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }


  isLoading = false;

  save() {

    if (!this.selectedStatus) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Durum Seçilmelidir' });
      return;
    }

    if (!this.selectedIssueType) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Issue Type Seçilmelidir' });
      return;
    }

    if (!this.selectedPriority) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Öncelik Seçilmelidir' });
      return;
    }


    this.isLoading = true;

    this.model.boardId = null;
    this.model.columnId = null;
    this.model.issueType = this.selectedIssueType;
    this.model.issuePriority = this.selectedPriority;
    this.model.orderNo = 10;
    this.model.status = this.selectedStatus;
    this.model.assigneeId = this.selectedUser?.id || null
    this.model.reporterId = this.selectedReporterUser?.id || null

    if(this.isEdit) {
      this.issueManagementService.updateIssue(this.model).subscribe(res => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Güncellendi' });
      }, err => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Güncellenirken bir hata oluştu' });
      })

    }  else {
      this.issueManagementService.createIssue(this.model).subscribe(res => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Gönderildi' });
      }, err => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Kaydedilirken bir hata oluştu' });
      })

    }




  }

}
