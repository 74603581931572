import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {getToken} from './token-interceptor.service';
import {SimpleEntity} from '../models/simple-entity.model';

@Injectable()
export class InfoManagerService extends BaseService<any> {

    constructor(public httpClient: HttpClient) {
        super(
            'infoManager',
            httpClient);
    }

    getAllInfoManagerDef(transportDirection, context): Observable<SimpleEntity[]> {
        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + '/infoManagerDef/getall' + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId+ "&context=" + context
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    getAllInfoManagers(transportDirection, entityId): Observable<SimpleEntity[]> {
        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + '/findByEntityId' + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId+ "&entityId=" + entityId
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    createEntityInfoManager(transportDirection, context, entityId, infoManagerDefId): Observable<SimpleEntity> {
        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + '/createEntityInfoManager' + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId + "&context=" + context+ "&entityId=" + entityId+ "&infoManagerDefId=" + infoManagerDefId
        return this.httpClient.get<SimpleEntity>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    createInfoManagerWithDescription(transportDirection, context, entityId, infoManagerDefId, description): Observable<SimpleEntity> {
        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + '/createInfoManagerWithDescription' + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId
            + "&context=" + context+ "&entityId=" + entityId+ "&infoManagerDefId=" + infoManagerDefId + "&description=" + description
        return this.httpClient.get<SimpleEntity>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteById(transportDirection, id: number){
        const token = getToken(transportDirection);

        const url = environment.baseUrl + "/" + this.endpoint + "/deleteById" + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId+ "&id=" + id
        return this.httpClient.delete<any>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    savePremCalculationDets(premCalculationDets) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/premCalculationDets';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, premCalculationDets, {params: options, observe: 'response'});
    }
    calcUpperScale(premCalculationDet) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/calcUpperScale';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, premCalculationDet, {params: options, observe: 'response'});
    }

    createPremCalculation(periodStart: any, periodEnd: any){
        const token = localStorage.getItem('token');
        const importToken = getToken("I");
        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/createPremCalculation?periodStart=' + JSON.stringify(periodStart).slice(1, 20) + '&periodEnd=' + JSON.stringify(periodEnd).slice(1, 20) ;
        let queryParam: HttpParams;
        const options = this.createRequestOption({token, sessionId, importToken: importToken.token, importSessionId: importToken.sessionId});

        queryParam = new HttpParams().append('token', token).append('sessionId', sessionId);

        return this.httpClient.post<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam },{params: options, observe: 'response'});
    }
    calculatePremCalculation(periodStart: any, periodEnd: any, premCalculationId){
        const token = localStorage.getItem('token');
        const importToken = getToken("I");
        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/calculatePremCalculation?periodStart=' + JSON.stringify(periodStart).slice(1, 20) + '&periodEnd=' + JSON.stringify(periodEnd).slice(1, 20) +'&premCalculationId=' + premCalculationId ;
        let queryParam: HttpParams;
        const options = this.createRequestOption({token, sessionId, importToken: importToken.token, importSessionId: importToken.sessionId});

        queryParam = new HttpParams().append('token', token).append('sessionId', sessionId);

        return this.httpClient.post<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam },{params: options, observe: 'response'});
    }
}
