import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';

@Component({
  selector: 'app-get-approval-advances',
  templateUrl: './get-approval-advances.component.html',
  styleUrls: ['./get-approval-advances.component.scss']
})
export class GetApprovalAdvancesComponent implements OnInit {

  advances: IntranetAdvance[] = [];

  selectedAdvances: IntranetAdvance[] = [];

  constructor(private advanceService: IntranetAdvanceService, private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {

    this.getMyAdvances();

  }

  getMyAdvances() {
    this.advanceService.getApprovalEntities().subscribe(res => {
      this.advances = res;
    })
  }

  navigateApproval(advance: IntranetAdvance) {

    this.router.navigate(['/advance/advance-approve/' + advance.id]);




  }


}
