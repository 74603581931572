import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DistrictMinimizedModel } from 'src/app/models/district-minimized.model';
import { CityMinimizedModel } from 'src/app/models/city-minimized.model';
import { CountryMinimizedModel } from 'src/app/models/country-minimized.model';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { ConfirmationService, MessageService } from 'primeng/api'
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { CompanyFinancialMobileDTO } from 'src/app/models/company-financial-mobile-dto';
import {CompanyType} from "../../models/company-type.model";
import { SelectBoxItem } from 'src/app/models/select-box-item.model';
import { SelectboxService } from 'src/app/services/selectbox.service';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class CreateCustomerComponent implements OnInit {

  createCustomerForm: UntypedFormGroup;
  sourceList: SelectBoxItem[] = [];
  isLoading: boolean = false;
  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  district: DistrictMinimizedModel[] = [];

  filteredCityMinimized: CityMinimizedModel[] = [];
  city: CityMinimizedModel[] = [];

  filteredCountryMinimized: CountryMinimizedModel[] = [];
  country: CountryMinimizedModel[] = [];

  filteredMaster: SimpleEntity[] = [];

  userTransportDirection: string = "";

  industries: SimpleEntity[] = [];
  selectedIndustries: SimpleEntity[] = [];

  filteredUsers: any[] = [];
  filteredExpoDefs: any[] = [];

  companyTypes: CompanyType[] = [];
  selectedCompanyType: CompanyType[] = [];
  taxOffices: SimpleEntity[] = [];
  filteredTaxOffices: SimpleEntity[] = [];

  selectedTransportDirection = 'I';
  transportDirections = [{name: 'KTL International', value: 'I'}, {name: 'KINAY', value: 'E'}]

  //TODO: Backend den gelen data localStorage a yazılacak
  paymentTypes: any[] = [{name: 'Seçiniz', code: null}, {name: 'Cari', code: 'Current'}, {name: 'Çek', code: 'Cheque'}, {name: 'Bond', code: 'Bond'}];
    invoiceDC: any[] = [{name: 'Seçiniz', code: null}, {name: 'Gelir', code: 'DB'}, {name: 'Gider', code: 'CR'}];
  //TODO: Backend den gelen data localStorage a yazılacak
  paymentPeriods: any[] = [{name: 'Seçiniz', code: null}, {name: 'Günlük', code: 'DAILY'}, {name: 'Haftalık', code: 'WEEKLY'}, {name: 'Aylık', code: 'MONTHLY'}]
  //TODO: Backend den gelen data localStorage a yazılacak
  operationTypes: any[] = [{name: 'Seçiniz', code: null}, {name: 'Muhasebe', code: 'ACCOUNTING'}, {name: 'Denizyolu Taşımacılığı', code: 'SEA'}, {name: 'Havayolu Taşımacılığı', code: 'AIR'}, {name: 'Yurtiçi', code: 'INLAND'}, {name: 'Karayolu Operasyon', code: 'TRUCK'}];

    status = [
        {code: 'N', name: 'Yeni'},
        {code: 'A', name: 'Aktif'},
        {code: 'L', name: 'Potansiyel'},
        {code: 'W', name: 'Cari Onay Bekliyor'},
    ];


  hasExport = localStorage.getItem('import');
  hasImport = localStorage.getItem('export');
  companyFinancial:CompanyFinancialMobileDTO = new CompanyFinancialMobileDTO();

  constructor(private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private offerExportService: OfferExportService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private selectBoxService: SelectboxService,
              public dialogRef: MatDialogRef<CreateCustomerComponent> ,@Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.companyFinancial.paymentType = 'Current'

    const user = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem('orc')) {
      // TODO geçiçi bir süre varsayılan olarak tüm istekler KTL den gerçekleşmekte.
      ///this.userTransportDirection = user.transportDirection || 'orc';
      this.userTransportDirection = 'I'
    }

    companyService.getCompanyTypes(this.selectedTransportDirection).subscribe(r => this.companyTypes = r);
    companyService.getIndustries(this.selectedTransportDirection).subscribe(r => this.industries = r);

    this.createCustomerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      master:[null,[]],
      district: [null, []],
      city: [null, [Validators.required]],
      country: [null, [Validators.required]],
      addressDetail: [null, []],
      address: [null, []],
      telephone: ['', []],
      notes: [null, []],
      taxOffice: [null, []],
      taxNo: [null, []],
      status: ['N', []],
      contactPerson: ['', []],
      sourceList: [null, []],
      subSource: [null, []],
      salesRepresentative: [null, []],
      tcKn: [null, []],
      email: ['', []],
      companyTypes:  this.formBuilder.group({
        id: ['', []],
        name: ['', []],

      }),
      industries:  this.formBuilder.group({
        id: ['', []],
        name: ['', []],

      })
    });
  }


  async ngOnInit(): Promise<void> {
    this.sourceList = await this.selectBoxService.getItemByBoxName('sourceList', this.selectedTransportDirection).toPromise();
    console.log(this.companyFinancial)
    this.setDefaultSalesRep();
  }

  filterMaster(event){
    if (event?.query?.length > 2) {
      this.offerExportService.filterMaster(event.query,this.selectedTransportDirection).subscribe(res => {
          this.filteredMaster = res.body;
      });
    }
  }

  filterDistrict(event, cityId?: any) {
    if (event?.query?.length > 2) {
      this.offerExportService.getOfferExportDistrict(event.query, cityId, this.selectedTransportDirection).subscribe(res => {
        this.filteredDistrictMinimized = res.body;
        this.district = res.body;
        const filtered: any[] = [];
        const query = event.query;
        this.district.forEach(country => {
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
          }
        });
        this.filteredDistrictMinimized = filtered;
      });
    }
  }

  filterCity(event, countryId?: any) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCity(event.query, countryId, this.selectedTransportDirection).subscribe(res => {
        this.filteredCityMinimized = res.body;
        this.city = res.body;
        const filtered: any[] = [];
        const query = event.query;
        this.city.forEach(country => {
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
          }
        });

        this.filteredCityMinimized = filtered;
      });
    }
  }

  filterCountry(event) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCountry(event.query, this.selectedTransportDirection).subscribe(res => {
        this.country = res.body;
        this.filteredCountryMinimized = res.body;
        const filtered: any[] = [];
        const query = event.query;
        this.country.forEach(country => {
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
          }
        });

        this.filteredCountryMinimized = filtered;
      });
    }
  }

  onSelectLoadDistrict2(event) {
    this.createCustomerForm.patchValue({ city: event.city });
    this.createCustomerForm.patchValue({ country: event.country });
  }

  onSelectLoadCity2(event) {
    this.createCustomerForm.patchValue({ country: event.country });
  }

  onChangeTransportDirection(event) {
       this.confirmationService.confirm({
           key: 'confirmMultipleCustomer',
           header: 'Emin Misiniz?',
          message: 'İl, İlçe, Vergi Dairesi, Satış Temsilcisi, İş Tipi alanları, Üst Firma seçimleriniz kaldırılacaktır. İşleme devam etmek istediğinize emin misiniz ?',
          accept: async () => {
              this.selectedTransportDirection = event.value;
              this.selectedCompanyType = [];
              this.companyService.getCompanyTypes(this.selectedTransportDirection).subscribe(r => this.companyTypes = r);
              this.createCustomerForm.get('taxOffice').setValue(null);
              this.setDefaultSalesRep();
              this.createCustomerForm.get('country').setValue(null);
              this.createCustomerForm.get('city').setValue(null);
              this.createCustomerForm.get('district').setValue(null);
              this.createCustomerForm.get('companyTypes').setValue({id: null, name: null});
              this.createCustomerForm.get('master').setValue(null);
              this.createCustomerForm.get('sourceList').setValue(null);
              this.sourceList = await this.selectBoxService.getItemByBoxName('sourceList',this.selectedTransportDirection).toPromise();
          },
           reject: () => {
              this.selectedTransportDirection = event.value == 'I' ? 'E' : 'I'
           }
      });





  }

  setDefaultSalesRep() {
    this.createCustomerForm.get('salesRepresentative').setValue(this.userService.getUserByTransportDirection(this.selectedTransportDirection));
  }

  createCustomer() {
    let paylodObj = this.createCustomerForm.value;
    paylodObj['companyTypes'] = this.selectedCompanyType;
    paylodObj['industries'] = this.selectedIndustries;

    if(!this.validation()) return;

    if(!this.userTransportDirection && this.createCustomerForm.value.salesRepresentative && localStorage.getItem('orc')) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Başarılı', detail: 'Satış temsilcisi seçerek adres kartı tanılamak için lütfen oturum açılan kullanıcının Tmaxx kullanıcı tanımı ekranından taşıma yönünü belirtiniz.' });
      return;
    }
    this.isLoading = true;
    this.offerExportService.checkCompanyNameDublicate({ name: this.createCustomerForm?.get('name')?.value, status: 'N' }, this.userTransportDirection).subscribe(res => {
      let sameCompany: string;
      let c = '';
      if (res.body === null || res.body === '' || res.body.length == 0) {
        sameCompany = null;
      } else {
        const a = String(res.body);
        const b = a.split(',');
        b.forEach(element => {
          c += ('<p>' + element + '</p>');
        });
        sameCompany = c;
      }

      if (sameCompany != null && sameCompany != '') {
       this.confirmationService.confirm({
          key: 'confirmMultipleCustomer',
          message: sameCompany,
          header: 'MÜKERRER KARTLAR',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Yinede Kaydet',
          rejectLabel: 'Kaydetme',
           reject: () => this.isLoading = false,
          accept: () => this.sendCustomer()
        });

      } else { this.sendCustomer(); }

    }, () => this.isLoading = false)
  }

  sendCustomer() {
  //  this.isLoading = true;

    const companyFinancialRequests = [];

    console.log(this.selectedTransportDirection, "this.selectedTransportDirection", this.createCustomerForm.value)



    this.offerExportService.createCompanyFromMobile(this.createCustomerForm.value, this.selectedTransportDirection).subscribe(res => {

        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı',  detail: `Adres kartı oluşturuldu.` });

        if(this.companyFinancial.dueDay && this.companyFinancial.dueDay > 0) {
            this.companyFinancial.registerNo = res.body['registerNo'];
            this.companyService.createCompanyFinancial(this.companyFinancial, this.selectedTransportDirection).subscribe(() => {
                this.isLoading = false;
                this.createCustomerForm.reset();
                this.companyFinancial = new CompanyFinancialMobileDTO();
                this.dialogRef.close()
            }, () => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata',  detail: `Adres kartına ait finansal detaylar oluşturulamadı!` });
            })
        } else {
            this.isLoading = false;
            this.createCustomerForm.reset();
            this.dialogRef.close()
        }
    }, () => this.isLoading = false)
  }

  checkCompanyTypesByForwarderOrGonderici(){
      const companyByForwarderOrGonderici = this.selectedCompanyType?.filter(company => company.code === 'FW' || company.code === 'GN');
      return companyByForwarderOrGonderici?.length > 0;
  }

  validation() {
    if ((!this.createCustomerForm.value.name)) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Müşteri adı girilmelidir.` });
      return false;
    }
      if ((!this.selectedCompanyType.length)) {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Şirket tipi girilmelidir.` });
          return false;
      }
      if ((!this.createCustomerForm.value.city)) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Şehir girilmelidir.` });
        return false;
      }
      if ((this.selectedCompanyType.filter(e => e.code === 'HD').length > 0 || this.selectedCompanyType.filter(e => e.code === 'BM').length > 0 || this.selectedCompanyType.filter(e => e.code === 'FW').length > 0 ||this.selectedCompanyType.filter(e => e.code === 'AM').length > 0 ||this.selectedCompanyType.filter(e => e.code === 'CU').length > 0)
      && this.createCustomerForm?.get('contactPerson')?.value == '' || this.createCustomerForm?.get('email')?.value == '' || this.createCustomerForm?.get('telephone')?.value == '') {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `İlgili kişi, email ve telefon alanı dolu olmalıdır.` });
        return false;
      }
      if(((this.selectedCompanyType.filter(e => e.code === 'CU').length > 0 || this.selectedCompanyType.filter(e => e.code === 'BM').length > 0) && this.createCustomerForm?.get('status')?.value === 'W') && this.createCustomerForm?.get('salesRepresentative')?.value === null){
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Satış temsilcisi zoruludur.` });
        return false;
      }

      if((this.hasBMselectedCompanyType() && this.createCustomerForm.get('tcKn').value && String(this.createCustomerForm.get('tcKn').value) != '' && String(this.createCustomerForm.get('tcKn').value).length != 11 )) {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Kimlik numarası 11 hane olmalıdır.` });
          return false;
      }

      if (!this.checkCompanyTypesByForwarderOrGonderici()){
        if (!this.companyFinancial?.paymentType) {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Ödeme Tipi girilmelidir.` });
          return false;
      }
          if (!this.companyFinancial?.dc) {
              this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Gelir-Gider girilmelidir.` });
              return false;
          }
          if ((this.companyFinancial?.dueDay === undefined || this.companyFinancial?.dueDay <= 0)) {
              this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Vade tipi seçilmeli ve vade 0 dan farklı girilmelidir.` });
              return false;
          }
      }

      if((!this.hasBMselectedCompanyType() && (this.createCustomerForm.value.country?.name == 'TURKEY' && this.createCustomerForm.get('taxNo').value && String(this.createCustomerForm.get('taxNo').value) && String(this.createCustomerForm.get('taxNo').value) != '' && String(this.createCustomerForm.get('taxNo').value).length != 10) )) {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `Vergi numarası 10 hane olmalıdır.` });
          return false;
      }

      for(let item in this.createCustomerForm.controls){
          if (this.createCustomerForm?.controls[item]?.status === 'INVALID') {
              this.createCustomerForm?.get(item)?.markAsDirty();
              this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `${item.toUpperCase()} boş bırakılmamalıdır.` });
              return false;
          }
      }
      return true;
  }

  filterTaxOffice(event) {
    if (event.query.length > 2) {
      this.companyService.getTaxOffice(this.selectedTransportDirection, event.query).subscribe(r =>  {
        this.taxOffices = r;
        this.filteredTaxOffices = this.taxOffices;
      });

    }
  }

  filterUsers(event) {
    console.log(event)
    this.userService.searchUsers(event.query, this.selectedTransportDirection).subscribe(res => this.filteredUsers = res);
  }

  filterExpoDefs(event) {
    console.log(event)
    this.companyService.searchExpoDef(event.query, this.selectedTransportDirection).subscribe(res => this.filteredExpoDefs = res);
  }


  autocompleteFocusOut(value, key) {
    if (!value || !value.id){
        this.createCustomerForm.get(key).setValue(null);
    }
  }

  hasBMselectedCompanyType() {
      if(!this.selectedCompanyType || this.selectedCompanyType.length == 0) return false;

      const hasBN = this.selectedCompanyType.find(f => f.code == 'BM');
      if(hasBN) return true;
      return false;
  }
    onChangeCompanyType() {
      if(this.hasBMselectedCompanyType()) {
          this.createCustomerForm.get('taxNo').setValue('');
      } else {
          this.createCustomerForm.get('tcKn').setValue('');
      }
    }
}
