import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { FinancialQueryService } from 'src/app/services/financial-query/financial-query.service';
import { FinancialQueryModel } from 'src/app/models/financial-query/financial-query.model';
import { MessageService } from 'primeng/api';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { Company } from 'src/app/models/company.model';
import EntityUtil from '../../../models/framework/utils/entity-util';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionService } from 'src/app/services/region.service';
import {UserService} from '../../../services/user.service';
import {ActivityService} from '../../../services/activity.service';

@Component({
  selector: 'app-financial-forms',
  templateUrl: './financial-forms.component.html',
  styleUrls: ['./financial-forms.component.scss']
})

export class FinancialFormsComponent implements OnInit {

  financialQuery: FinancialQueryModel;
  requiredFinancialQuery = [
      {name: 'monthlyPlannedGoodInformation', message: 'Aylık Planlanan Yük Bilgisi'},
      {name: 'monthlyPlannedPrice', message: 'Aylık Planlanan Hacim(Tutar)'},
      {name: 'requestedDueDay', message: 'Talep Edilen Gün'},
      {name: 'requestedPaymentType', message: 'Talep Edilen Çalışma Şekli'},
      {name: 'operationType', message: 'Operasyon Tipi'},
      {name: 'transportDirection', message: 'Taşıma Şekli'},
      {name: 'taxOffice', message: 'Vergi Dairesi'},
      {name: 'currency', message: 'Para Birimi'},
      {name: 'requestUser', message: 'Talep Eden Kişi'},
      {name: 'region', message: 'İlgili Bölge'},
      {name: 'goodType', message: 'Taşınacak Malın Cinsi'},
      {name: 'goodAmount', message: 'Mal Bedeli(Tutar)'},
      {name: 'goodAmountCurrency', message: 'Mal Bedeli Para Birimi'},
      {name: 'salesRepresentative', message: 'Satış Temsilcisi'}
  ];

  isLoading: boolean = false;

  taxOffices: SimpleEntity[] = [];
  filteredTaxOffices: SimpleEntity[] = [];
  filteredUsers: SimpleEntity[] = [];
  transportDirectionCreate: string;

  filteredCompanies: Company[] = [];
  companies: Company[] = [];

  currencies: any[] = [];
  filteredCurrencies: any[] = [];

  regions: any[] = [];
  filteredRegions: any[] = [];

    jobStartDate: Date;
    firstCheckDate: Date;
    lastCheckDate: Date;
    lastCreditUsageDate: Date;

    ratio: string;
    plannedLimitRatio: string;

  paymentTypes: any[] = [
    {name: 'Seçiniz', code: null}, {name: 'Cari', code: 'Current'}, {name: 'Çek', code: 'Cheque'}, {name: 'Peşin', code: 'Cash'}, {name: 'Senet', code: 'Bond'}
  ];

  transportDirection: any[] = [
    {
      name: 'Seçiniz',
      code: null
    },
    {
      name:'Transit',
      code: 'T'
    },
    {
      name:'İhracat',
      code: 'E'
    },
    {
      name:'İthalat',
      code: 'I'
    },
    {
      name:'Yurtiçi',
      code: 'D'
    },
  ]
  operationTypes = [
    {
      'name': 'Seçiniz',
      'code': null
    },
    {
      "name": "MUHASEBE",
      "code": "MUHASEBE"
    },
    {
      "name": "Hava Operasyonu",
      "code": "AIR"
    },
    {
      "name": "Karayolu Operasyon",
      "code": "TRUCK"
    },
    {
      "name": "Deniz Operasyon",
      "code": "SEA"
    },
    {
      "name": "Yurtiçi Taşımacılığı",
      "code": "INLAND"
    },
  ]

    constructor(private companyService: CompanyService,
                private currencyService: CurrencyConversionService,
                private messageService: MessageService,
                private offerExportService: OfferExportService,
                private router: Router,
                private regionService: RegionService,
                private userService: UserService,
                private activetedRouter: ActivatedRoute,
                private financialQueryService: FinancialQueryService,
                private activityService: ActivityService) {

    this.financialQuery = new FinancialQueryModel();
    if (this.activetedRouter.snapshot.params.id) {
        this.getFinancialForm(this.activetedRouter.snapshot.params.id, this.activetedRouter.snapshot.params.transportDirection).subscribe(r => {
            this.financialQuery = r
            this.jobStartDate = this.financialQuery.jobStartDate ?  new Date(this.financialQuery.jobStartDate) : null;
            this.firstCheckDate = this.financialQuery.firstCheckDate ? new Date(this.financialQuery.firstCheckDate) : null;
            this.lastCheckDate = this.financialQuery.lastCheckDate ? new Date(this.financialQuery.lastCheckDate) : null;
            this.lastCreditUsageDate = this.financialQuery.lastCreditUsageDate ? new Date(this.financialQuery.lastCreditUsageDate) : null;
            this.calculatePlannedLimitRatio();
        })    } else {
        this.setUserByTransportDirection('user');
    }


    this.currencyService.getAllCurrencies().subscribe(res => this.currencies = res.body)

  }

    setUserByTransportDirection(userKey) {
        const user = JSON.parse(localStorage.getItem(userKey));
        Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
        this.financialQuery.requestUser = EntityUtil.toSimple(user);
    }

    ngOnInit(): void {
  }

  getFinancialForm(id, transportDirection) {
    return this.financialQueryService.get(id, transportDirection);
  }

  createFinancialForm() {

    if(!this.validation()) return;

    this.setCreateTransportDirection();



    // TODO seachMobile deki api ye request atılacak...
      this.isLoading = !this.isLoading;
      this.financialQueryService.create(this.financialQuery, this.transportDirectionCreate).subscribe((r) => {
          this.isLoading = !this.isLoading;
          this.calculatePlannedLimitRatio();
          this.router.navigate([`forms/financial-form/${r.id}/${this.transportDirectionCreate}`]).then(() => {
              this.messageService.add({ key: 'tst', severity: 'success', summary: 'Kayıt Başarılı', detail: 'Form başarıyla oluşturuldu.' });
          })

    }, () => {
      this.isLoading = !this.isLoading;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Hata oluştu. Tekrar deneyin.' });
    })

  }

    validation() {
        let isValid = true;
        console.log("vl",this.financialQuery.tckn,this.financialQuery.taxNo);

        if ((!this.financialQuery?.tckn || this.financialQuery?.tckn === '') && (!this.financialQuery?.taxNo || this.financialQuery?.taxNo === '')) {
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'T.C. Kimlik No veya Vergi Numarası girilmelidir'});
            return false;
        }
        if ((!this.financialQuery?.profit || this.financialQuery?.profit === 0) && (!this.financialQuery?.avgFreightAmount || this.financialQuery?.avgFreightAmount === 0)) {
          this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Karlılık ve Ortalama Navlun Bedeli girilmelidir'});
          return false;
      }

        this.requiredFinancialQuery.forEach(k => {
            if (!this.financialQuery[k.name] || this.financialQuery[k.name] == '') {
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: `${k.message} boş bırakılmamalıdır.`});
                isValid = false;
            }
        });
        return isValid;
    }

    filterTaxOffice(event) {
        if (this.financialQuery.operationType && this.financialQuery.transportDirection) {
            this.setCreateTransportDirection();
            if (event.query.length > 2) {
                this.companyService.getTaxOffice(this.transportDirectionCreate, event.query).subscribe(r => {
                    this.taxOffices = r;
                    this.filteredTaxOffices = this.taxOffices;
                });
            }
        } else {
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Lütfen Operasyon Tipi ve Taşıma Şeklini Seçiniz'});
        }
    }

  filterCurrencies(event) {
    const filtered: any = [];
    const query = event.query;
    for (let i = 0; i < this.currencies.length; i++) {
      const currency = this.currencies[i];
      if (currency.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(currency);
      }
    }

    this.filteredCurrencies = filtered;
  }


  filterCompany(event) {
      if (this.financialQuery.operationType && this.financialQuery.transportDirection) {
          this.setCreateTransportDirection();
          this.offerExportService.getCompany(event.query,undefined,undefined,this.transportDirectionCreate).subscribe(res => {
              this.companies = res.body;
              this.filteredCompanies = res.body;
              const filtered: Company[] = [];
              const query = event.query;
              this.companies.forEach(company => {
                  if (company.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                      filtered.push(company);
                  }
              });

              this.filteredCompanies = filtered;
          });

      }else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Lütfen Operasyon Tipi ve Taşıma Şeklini Seçiniz' });
      }
  }

  filterRegion(event) {
      if (this.financialQuery.operationType && this.financialQuery.transportDirection) {
          this.setCreateTransportDirection();
          this.regionService.search({ "name/SW": event.query }, this.transportDirectionCreate).subscribe(res => this.filteredRegions = res);
      }else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Lütfen Operasyon Tipi ve Taşıma Şeklini Seçiniz' });
      }
  }

  autocompleteFocusOut(value, key) {
    if (!value || !value.id) this.financialQuery[key] = null
  }

    filterUsers(event) {
      if (this.financialQuery.operationType && this.financialQuery.transportDirection){
          this.setCreateTransportDirection();
          this.userService.searchUsers(event.query, this.transportDirectionCreate).subscribe(res => {
              this.filteredUsers = res;
          })
      }else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Lütfen Operasyon Tipi ve Taşıma Şeklini Seçiniz' });
      }
    }

    private setCreateTransportDirection() {
        const operationType = this.financialQuery.operationType;
        const transportDirection = this.financialQuery.transportDirection;
        if (operationType && transportDirection){
        let transportDirectionUpdate ;
        if (operationType === 'AIR') {
            transportDirectionUpdate = 'I';
        } else if (operationType === 'SEA') {
            transportDirectionUpdate = transportDirection;
        } else {
            transportDirectionUpdate = 'E';
        }
        if (this.transportDirectionCreate !== transportDirectionUpdate) {
            this.financialQuery.salesRepresentative = null;
            this.financialQuery.region = null;
            this.financialQuery.company = null;
            this.financialQuery.taxOffice = null;
        }
        this.transportDirectionCreate = transportDirectionUpdate;
        if (transportDirectionUpdate === 'E'){
            this.setUserByTransportDirection('user');
        }else {
            this.setUserByTransportDirection('userimport');
        }
        }
    }

    onSelectCompany(value: Company) {
        this.activityService.getsalesRepresentativeByCompanyId(value.id, this.transportDirectionCreate).subscribe(r => {
            this.financialQuery.salesRepresentative = r?.body[0]?.salesRepresentative;
        });
        this.companyService.getCompany(value.id,this.financialQuery.transportDirection).subscribe(data=>{
            data.taxOffice ? this.financialQuery.taxOffice = data.taxOffice : this.financialQuery.taxOffice = null;
            data.taxNo ? this.financialQuery.taxNo = data.taxNo : this.financialQuery.taxNo = null;
            data.tcKn ? this.financialQuery.tckn = data.tcKn : this.financialQuery.tckn = null;
        })
    }

    async calculatePlannedLimitRatio() {
        const fixedLimitRatio = this.financialQuery.plannedVolumeLimitClearance ? this.financialQuery.plannedVolumeLimitClearance * 100: null;
        const fixedRation = this.financialQuery.borrowingLimitRatio * 100 ? this.financialQuery.borrowingLimitRatio * 100 : null;
        this.ratio = fixedRation.toFixed(2);
        this.plannedLimitRatio =  fixedLimitRatio.toFixed(2);
    }
}
