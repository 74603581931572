export class TextConstants {
    static CUSTOMER: string = "Müşteri";
    static OFFER: string = "Talep";
    static CREATE_OFFER: string = "Teklif Talebi Oluştur";
    static OFFER_REQUEST: string = "Teklif Talebi";


    static OFFER_WAITING: string = "toDo";
    static OFFER_DONE = "done";
    static OFFER_REJECTED = "rejected";
    static OFFER_APPLY = "accepted";

    //SEA
    static LOAD_DESTINATION: string = "Yükleme Yeri";
    static POL: string = "POL";
    static POD: string = "POD";
    static END_DESTINATION: string = "Teslimat Yeri";
    static SHIPOWNER: string = "Armatör";
    static EQUIPMENT: string = "Ekipman";
    static NOTE: string = "Not";

    static SEA_PORT: string = "LİMAN";
    static SEA_ADDRESS: string = "MÜŞTERİ ORTAMI";

    static OFFER_TYPE_PRICE: string = "FİYAT";
    static OFFER_TYPE_REQUEST: string = "TALEP";
    static OFFER_TYPE_SEA: string = "DENİZ";
    static OFFER_TYPE_AIR: string = "HAVA";
    static OFFER_TYPE_LAND: string = "KARA";

    static SEA: string = "SEA";
}