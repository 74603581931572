import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {tap, catchError, map, filter} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivityRequestModel } from '../models/activity-request.model';
import { Activity } from '../models/activity.model';
import { Company } from '../models/company.model';
import { SeaPort } from '../models/seaport.model';
import { BaseService } from './base.service';
import {getToken} from "./token-interceptor.service";


@Injectable()
export class DashboardService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'dashboard',
            httpClient);
    }

    getDashboardActivityLayoutList(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardActivityLayoutList?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');

        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardOfferLayoutList(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardOfferLayoutList?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(map(op => {
                    op = op.filter(fop => ['AIR', 'SEA'].includes(fop.operationType));
                    return op;
                }),
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardSalesLayoutList(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardSalesLayoutList?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardOperationLayoutList(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardOperationLayoutList?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(map(op => {
                    op = op.filter(fop =>  ['AIR', 'SEA'].includes(fop.operationType));
                    return op;
                }),
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardOperationCard(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardOperationCard?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardCompanyLayoutList(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardCompanyLayoutList?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    getDashboardSalesCard(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardSalesCard?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardActivityCard(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardActivityCard?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDashboardOfferCard(transportDirection?: string): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getDashboardOfferCard?token=" + token.token + "&sessionId=" + token.sessionId + (transportDirection !== undefined ? "&transportDirection=" + transportDirection : '');
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    getAppropriateOfferSeasWithSeaGoodId(seaGoodId, transportDirection): Observable<any[]> {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + "/" + this.endpoint + "/getAppropriateOfferSeasWithSeaGoodId";
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('seaGoodId', seaGoodId);
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    setOfferSeaDetailToSeaGoodWithId(seaGoodId, offerSeaDetailId, transportDirection): Observable<any[]> {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + "/" + this.endpoint + "/setOfferSeaDetailToSeaGoodWithId";
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('seaGoodId', seaGoodId).append('offerSeaDetailId', offerSeaDetailId)
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    setOfferAirDetailToAirGoodWithId(airGoodId, offerAirDetailId, transportDirection): Observable<any[]> {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + "/" + this.endpoint + "/setOfferAirDetailToAirGoodWithId";
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('airGoodId', airGoodId).append('offerAirDetailId', offerAirDetailId)
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

}
