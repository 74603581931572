import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetDocument } from '../models/intranet-document.model';
import { IntranetPage } from '../models/intranet-page.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';


@Injectable()
export class IntranetDocumentService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'document',
            httpClient);
    }


    get(id: number): Observable<IntranetDocument> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/get?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetDocument>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    delete(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/delete?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    create(document: IntranetDocument): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            intranetDocument: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createDocument";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    update(document: IntranetDocument): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            intranetDocument: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateDocument";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDocumentTypes() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getDocumentTypes?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDepartments() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getDepartments?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getParentDocuments() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getParentDocuments?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<IntranetDocument[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getDocumentsByParentId(parentId: number) {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getDocumentsByParentId?token=" + token + "&sessionId=" + sessionId + "&parentId=" + parentId;

        return this.httpClient.get<IntranetDocument[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}