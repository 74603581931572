import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from 'src/app/services/dashboard.service';
import { hasTransportDirectionBackend } from 'src/app/shared/helper/helper';

@Component({
    selector: 'app-card-detail',
    templateUrl: './card-detail.component.html',
    styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit {
    type: string = "";

    title = "";

    activities: any[];
    activitiesImport: any[];
    activitiesExport: any[];
    showActivityList = false;

    operationsImport: any[];
    operationsExport: any[];
    allOperations: any[] = [];

    offersExport: any[];
    offersImport: any[];
    allOffers: any[] = [];

    companies: any[];
    companiesImport: any[];
    companiesExport: any[];

    sales: any[];

    seaList = false;
    airList = false;

    cardDetailId: number;
    dashboardDetail: any = null;

    selectedTransportDirection = 'E';

    isLoading: boolean = true;
    isLoadingImport: boolean = true;

    showOfferList = false;
    showCompanyList = false;

    exportCount = '0';
    importCount = '0';

    useTransportDirectionBackend: boolean = true;

    constructor(private dashboardService: DashboardService, private route: ActivatedRoute) {
        route.queryParams.subscribe(res => this.type = res.type)
        this.useTransportDirectionBackend = hasTransportDirectionBackend();
    }

    ngOnInit(): void {

        this.isLoading = true;

        if (this.type === 'activity') {
            this.title = "Aktivite";
            if(!this.useTransportDirectionBackend) {
                this.dashboardService.getDashboardActivityLayoutList().subscribe(res => {
                    this.importCount = res.length.toString();
                    this.activities = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
            } else {

                this.dashboardService.getDashboardActivityLayoutList('I').subscribe(res => {
                    this.importCount = res.length.toString();
                    this.activitiesImport = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
                this.dashboardService.getDashboardActivityLayoutList('E').subscribe(res => {
                    this.exportCount = res.length.toString();
                    this.activitiesExport = res;
                    if (res.length === 0) {
                        this.selectedTransportDirection = 'I';
                    }

                    if (!this.isLoadingImport) {
                        this.isLoading = false;
                    }
                });

            }

        }

        if (this.type === 'sales') {
            this.dashboardService.getDashboardSalesLayoutList().subscribe(res => {
                this.title = "Satış";
                this.sales = res;
            })
        }

        if (this.type === 'offer') {
            this.title = "Teklif";

            if(!this.useTransportDirectionBackend) {
                this.dashboardService.getDashboardOfferLayoutList().subscribe(res => {
                    this.importCount = res.length.toString();
                    this.isLoading = false;
                    this.isLoadingImport = false;
                    this.allOffers =  res
                });

            } else {
                this.dashboardService.getDashboardOfferLayoutList('I').subscribe(res => {
                    this.importCount = res.length.toString();
                    this.offersImport = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
                this.dashboardService.getDashboardOfferLayoutList('E').subscribe(res => {
                    this.exportCount = res.length.toString();
                    this.offersExport = res;
                    if (res.length === 0) {
                        this.selectedTransportDirection = 'I';
                    }
                    if (!this.isLoadingImport) {
                        this.isLoading = false;
                    }
                });
            }

        }

        if (this.type === 'operation') {
            this.title = "Operasyon";
            if(!this.useTransportDirectionBackend) {
                this.dashboardService.getDashboardOperationLayoutList().subscribe(res => {
                    this.importCount = res.length.toString();
                    this.allOperations = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
            } else {

                this.dashboardService.getDashboardOperationLayoutList('I').subscribe(res => {
                    this.importCount = res.length.toString();
                    this.operationsImport = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
                this.dashboardService.getDashboardOperationLayoutList('E').subscribe(res => {
                    this.exportCount = res.length.toString();
                    this.operationsExport = res;
                    if (res.length === 0) {
                        this.selectedTransportDirection = 'I';
                    }

                    if (!this.isLoadingImport) {
                        this.isLoading = false;
                    }
                });

            }

        }

        if (this.type === 'company') {
            this.title = "Adres Kartı";
            if(!this.useTransportDirectionBackend) {
                this.dashboardService.getDashboardCompanyLayoutList().subscribe(res => {
                    this.importCount = res.length.toString();;
                    this.title = "Adres Kartı";
                    this.companies = res;
                    this.isLoading = false;
                })
            }
            else{
                this.dashboardService.getDashboardCompanyLayoutList('I').subscribe(res => {
                    this.importCount = res.length.toString();
                    this.companiesImport = res;
                    this.isLoading = false;
                    this.isLoadingImport = false;
                });
                this.dashboardService.getDashboardCompanyLayoutList('E').subscribe(res => {
                    this.exportCount = res.length.toString();
                    this.companiesExport = res;
                    if (res.length === 0) {
                        this.selectedTransportDirection = 'E';
                    }

                    if (!this.isLoadingImport) {
                        this.isLoading = false;
                    }
                });
            }

        }


    }

    selectOperationType(){
        const operationType = this.dashboardDetail.operationType.toLowerCase()
        this.seaList = operationType === "sea" ? true : false;
        this.airList = operationType === "air" ? true : false;
    }
    onChangeTransportDirection() {
        switch (this.type) {
            case 'operation' : {
                this.getOperations();
                break;
            }
            case 'offer' : {
                this.getOffers();
                break;
            }
        }
    }

    async getOperations() {
        this.isLoading = true;
        try {
            if (this.selectedTransportDirection === 'I') {
                this.operationsImport = await this.dashboardService.getDashboardOperationLayoutList(this.selectedTransportDirection).toPromise();
                this.importCount = this.operationsImport.length.toString();
            } else {
                this.operationsExport = await this.dashboardService.getDashboardOperationLayoutList(this.selectedTransportDirection).toPromise();
                this.exportCount = this.operationsExport.length.toString();
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }

    async getOffers() {
        this.isLoading = true;
        try {
            if (this.selectedTransportDirection === 'I') {
                this.offersImport = await this.dashboardService.getDashboardOfferLayoutList(this.selectedTransportDirection).toPromise();
                this.importCount = this.offersImport.length.toString();
            } else {
                this.offersExport = await this.dashboardService.getDashboardOfferLayoutList(this.selectedTransportDirection).toPromise();
                this.exportCount = this.offersExport.length.toString();
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }
}
