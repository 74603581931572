import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetAdvance } from '../models/intranet-advance.model';
import { IntranetDocument } from '../models/intranet-document.model';
import { IntranetPage } from '../models/intranet-page.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { CreateLessonQuizRequest, IntranetLesson, IntranetLessonCategory, IntranetLessonVideo, IntranetLessonVideoUserState, QuizUserResult, IntranetLessionVideoAssigment } from '../models/learning.model';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';


@Injectable()
export class LearningService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'learning',
            httpClient);
    }


    getLessonCategory(id: number): Observable<IntranetLessonCategory> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getLessonCategory?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetLessonCategory>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllLessonCategories(): Observable<IntranetLessonCategory[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllLessonCategories?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<IntranetLessonCategory[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createLessonCategory(obj: IntranetLessonCategory): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createLessonCategory";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateLessonCategory(obj: IntranetLessonCategory): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateLessonCategory";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteLessonCategory(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteLessonCategory?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getLesson(id: number): Observable<IntranetLesson> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getLesson?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetLesson>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteLesson(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteLesson?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllLessons(): Observable<IntranetLesson[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllLessons?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<IntranetLesson[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllMyLessons(): Observable<IntranetLesson[]> {

        var user = JSON.parse(localStorage.getItem('user'));

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllMyLessons?token=" + token + "&sessionId=" + sessionId + "&userId=" +  user.id;

        return this.httpClient.get<IntranetLesson[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
      

    createLesson(obj: IntranetLesson): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createLesson";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateLesson(obj: IntranetLesson): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateLesson";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getVideo(id: number): Observable<IntranetLessonVideo> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getVideo?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetLessonVideo>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getVideosByLessonId(lessonId: number): Observable<IntranetLessonVideo[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getVideosByLessonId?token=" + token + "&sessionId=" + sessionId + "&lessonId=" + lessonId + "&userId=" + user.id;

        return this.httpClient.get<IntranetLessonVideo[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getMyVideosByLessonId(lessonId: number): Observable<IntranetLessonVideo[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getMyVideosByLessonId?token=" + token + "&sessionId=" + sessionId + "&lessonId=" + lessonId + "&userId=" + user.id;

        return this.httpClient.get<IntranetLessonVideo[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createVideo(obj: IntranetLessonVideo): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createVideo";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateVideo(obj: IntranetLessonVideo): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateVideo";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteVideo(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteVideo?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    getLessonVideoUserState(videoId: number): Observable<IntranetLessonVideo[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getLessonVideoUserState?token=" + token + "&sessionId=" + sessionId + "&videoId=" + videoId + "&userId=" + user.id;

        return this.httpClient.get<IntranetLessonVideo[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    createVideoUserState(obj: IntranetLessonVideoUserState): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createVideoUserState";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateVideoUserState(obj: IntranetLessonVideoUserState): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateVideoUserState";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteVideoUserState(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteVideoUserState?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    createLessonQuiz(request: CreateLessonQuizRequest): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity: CreateLessonQuizRequest = {
            token: token,
            sessionId: sessionId,
            lessonId: request.lessonId,
            quizQuestions: request.quizQuestions
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createLessonQuiz";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    completeQuiz(lesson: IntranetLesson): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var entity: any = {
            token: token,
            sessionId: sessionId,
            userId: user.id,
            lesson: lesson
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/completeQuiz";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getLessonWithQuizObjects(id: number): Observable<IntranetLesson> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getLessonWithQuizObjects?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<IntranetLesson>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getResultsByLessonAndUser(lessonId: number): Observable<QuizUserResult[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getResultsByLessonAndUser?token=" + token + "&sessionId=" + sessionId + "&lessonId=" + lessonId + "&userId=" + user.id;

        return this.httpClient.get<QuizUserResult[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAssigmentUsers(videoId: number): Observable<IntranetLessionVideoAssigment[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getAssigmentUsers?token=" + token + "&sessionId=" + sessionId + "&videoId=" + videoId;

        return this.httpClient.get<IntranetLessionVideoAssigment[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    
    assignmentToUser(entity: IntranetLessionVideoAssigment): Observable<IntranetLessionVideoAssigment> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

      
        var url = environment.baseUrl + "/" + this.endpoint + "/assignmentToUser?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.post<IntranetLessionVideoAssigment>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    deleteAssignmentUser(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteAssignmentUser?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


}