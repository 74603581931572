import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyCRMDTO } from 'src/app/models/company-crm-dto';


@Component({
  selector: 'app-good-table-dialog',
  templateUrl: './good-table-dialog.component.html',
  styleUrls: ['./good-table-dialog.component.scss']
})
export class GoodTableDialogComponent implements OnInit {


  lastThreeYears = [];

 constructor(
   public dialogRef: MatDialogRef<GoodTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompanyCRMDTO[],
 ) {

  this.lastThreeYears =  [...new Set(this.data.sort((data1, data2) => (data1.year < data2.year) ?  1 : (data1.year > data2.year) ? -1 : 0).map(item => item.year))];
  }

  getCount(year:number, month: number = null) {
    if(!month) return this.data.filter(f => f.year == year).map(q => q.count).reduce((a,b) => a+b)
    const filtered = this.data.filter(f => f.year == year && f.month == month).map(q => q.count);
    return filtered.length > 0 ? filtered.reduce((a,b) => a+b) : 0;

  }

  ngOnInit(): void {
  }

}
