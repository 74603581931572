import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetLessonCategory } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-create-lesson-category',
  templateUrl: './create-lesson-category.component.html',
  styleUrls: ['./create-lesson-category.component.scss']
})
export class CreateLessonCategoryComponent implements OnInit {

  model: IntranetLessonCategory = <IntranetLessonCategory>{};

  constructor(private learningService: LearningService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
  }

  isLoading = false;

  save() {
    if(!this.model.name) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Kategori adı belirtiniz." });
      return;
    }
    this.isLoading = true;

    this.learningService.createLessonCategory(this.model).subscribe(res => {

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kategori Kaydedildi" });
      this.isLoading = false;

      setTimeout(() => {
        this.router.navigate(['/learning/list-lesson-category']);
      }, 1500)

    }, err => {

      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;

    })

  }

}
