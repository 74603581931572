import {AbstractEntity} from '../abstract-entity.model';
import { SimpleEntity } from '../simple-entity.model';

export class QdmsFile extends AbstractEntity {
    name: string;
    revision: string;
    documentCode: string;
    fileExtension: string;
    folder: SimpleEntity;
    createdUser: SimpleEntity;
    revizedUser: SimpleEntity;
    revizedFile: SimpleEntity;
    revisionDate: Date;
    fileId: string;
    contentType: string;
}
