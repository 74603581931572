import {Component, Inject, OnInit} from '@angular/core';
import {SendSurveyRequestModel} from "../../../models/survey-models/send-survey-request.model";
import {CompanyService} from "../../../services/company.service";
import {SimpleEntity} from "../../../models/simple-entity.model";
import {OfferExportService} from "../../../services/offer-export.service";
import {CountryMinimizedModel} from "../../../models/country-minimized.model";
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Survey} from "../../../models/survey-models/survey.model";
import {SurveyService} from "../../../services/survey.service";
import EntityUtil from "../../../models/framework/utils/entity-util";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-send-survey',
  templateUrl: './send-survey.component.html',
  styleUrls: ['./send-survey.component.scss']
})
export class SendSurveyComponent implements OnInit {
  companies: SimpleEntity[] = [];
  industries: SimpleEntity[] = [];
  countries: CountryMinimizedModel[] = [];
  isLoading: boolean = false;
  surveyRequestModel = new SendSurveyRequestModel();
  companyTypes: SimpleEntity[] = [];

  constructor(private companyService: CompanyService,
              private surveyService: SurveyService,
              private messageService: MessageService,
              @Inject(MAT_DIALOG_DATA) public data: Survey,
              private offerExportService: OfferExportService) {
    this.surveyRequestModel.survey = EntityUtil.toSimple(data);
  }

  async ngOnInit() {
    this.companyTypes = (await this.companyService.getCompanyTypes().toPromise()).map(r => EntityUtil.toSimple(r));
    this.industries = await  this.companyService.getIndustries().toPromise();
  }

  sendSurvey() {
    this.isLoading = true;
    this.surveyService.sendSurvey(this.surveyRequestModel).subscribe(r => {
      this.messageService.add({severity:'success', detail: 'Anket bildirimleri iletildi.' });
    }, () => this.isLoading = false)
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.companies = res.map(r => EntityUtil.toSimple(r))
    });
  }
  filterCountry(event) {
    if(event.query.len > 2) return;
    this.offerExportService.getOfferExportCountry(event.query).subscribe(res => {
      this.countries = res.body;
    });

  }


}
