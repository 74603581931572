import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivityRequestModel } from '../models/activity-request.model';
import { Activity } from '../models/activity.model';
import { Company } from '../models/company.model';
import { SeaPort } from '../models/seaport.model';
import { BaseService } from './base.service';
import { DatePipe } from '@angular/common';
import { SeaportMinimizedModel } from '../models/SeaportMinimized.model';
import { UpdateOfferStatusDTO } from '../models/update-offer-status-dto.model';
import { CompanyMinimizedModel } from '../models/company-minimized.model';
import { ActivityDTOModel } from '../models/activity-dto.model';
import { SimpleEntity } from '../models/simple-entity.model';
import { MinimizedOfferModel } from '../models/minimized-offer.model';
import { CompanyCRMDTO } from '../models/company-crm-dto';
import { getToken } from './token-interceptor.service';
import {StepRepresentative} from "../models/activity/step-representative";
import {MobileActivity} from "../models/activity/mobile-activity";
import {Person} from "../models/activity/person";
import {ActivityOperation} from "../models/activity/activity-operation";
import { OfferPartialActivityMobile } from '../models/offer-partial-activity-mobile';
@Injectable()
export class ActivityService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe : DatePipe) {
        super(
            'activity',
            httpClient);
    }

    getAll(subTypes: string): Observable<Activity[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        const params = new HttpParams();
        params.append('subTypes', subTypes );
        params.set('logNamespace', "spacetest");
        console.dir("getAll-Service")
        var url = environment.baseUrl + "/" + this.endpoint + "/getall?token=" + token + "&sessionId=" + sessionId + "&subTypes=" + subTypes;
        console.dir("getAll-Service-url")
        return this.httpClient.get<Activity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: params })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getWithParams(subTypes: string,etdStart : String, etdEnd : String, fromPortId?: number, toPortId?: number,  shipOwnerId?: number,  customerId?: number, operationType?: string, onlyReservation?:boolean, seaGoodReferenceNo?: string, referenceNo?: string, transportDirection?:string, offerTransportDirection?: string): Observable<any[]> {

        var token = getToken(transportDirection);

        const params = new HttpParams();
        params.append('subTypes', subTypes );

        var url = environment.baseUrl + "/" + this.endpoint + "/getall?token=" + token.token + "&sessionId=" + token.sessionId;

        if(etdStart) url +=  "&etdStart=" + etdStart;
        if(etdEnd) url +=  "&etdEnd=" + etdEnd;
        if(subTypes) url +=  "&subTypes=" + subTypes;
        if(fromPortId) url +=  "&fromPortId=" + fromPortId;
        if(toPortId) url +=  "&toPortId=" + toPortId;
        if(shipOwnerId) url +=  "&shipOwnerId=" + shipOwnerId;
        if(customerId) url +=  "&customerId=" + customerId;
        if(operationType) url +=  "&operationType=" + operationType;
        if(offerTransportDirection) url += "&offerTransportDirection=" + offerTransportDirection;
        if(transportDirection) url += "&transportDirection=" + transportDirection;
        if(onlyReservation) url += "&onlyReservation=" + onlyReservation;
        if(seaGoodReferenceNo) url +=  "&seaGoodReferenceNo=" + seaGoodReferenceNo;
        if(referenceNo) url +=  "&referenceNo=" + referenceNo;

        return this.httpClient.get<Activity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: params })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getWithParamsExport(subTypes: string,etdStart : String, etdEnd : String, fromPortId?: number, toPortId?: number,  shipOwnerId?: number, customerId?: number, operationType?: string, transportDirection?: string, onlyReservation?:boolean, seaGoodReferenceNo?: string,): Observable<any[]> {

        var token = getToken(transportDirection);

        const params = new HttpParams();
        params.append('subTypes', subTypes );

        var url = environment.baseUrl + "/" + this.endpoint + "/getall?token=" + token.token + "&sessionId=" + token.sessionId + "&subTypes=" + subTypes + "&etdStart=" + etdStart + "&etdEnd=" + etdEnd;
        if(fromPortId) url +=  "&fromPortId=" + fromPortId;
        if(toPortId) url +=  "&toPortId=" + toPortId;
        if(shipOwnerId) url +=  "&shipOwnerId=" + shipOwnerId;
        if(customerId) url +=  "&customerId=" + customerId;
        if(transportDirection) url += "&offerTransportDirection=" + transportDirection;
        if(transportDirection) url += "&transportDirection=" + transportDirection;
        if(operationType) url += "&operationType=" + operationType;
        if(onlyReservation) url += "&onlyReservation=" + onlyReservation;
        if(seaGoodReferenceNo) url +=  "&seaGoodReferenceNo=" + seaGoodReferenceNo;

        return this.httpClient.get<Activity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: params })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    createActivity(activityFromMobile: Activity): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/createActivity';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, activityFromMobile, {params: options, observe: 'response'});
    }



    getMyActivityById(id: number, transportDirection = null): Observable<any> {

        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getMyActivityById?token=" + token.token + "&sessionId=" + token.sessionId + "&id=" + id;
        if(transportDirection) url += "&transportDirection=" + transportDirection;

        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    updateOfferStatus(updateOfferStatusDTO: UpdateOfferStatusDTO[], transportDirection?:string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection).token;
        const sessionId = transportDirection == 'I' ? JSON.parse(localStorage.getItem('import')).sessionId : localStorage.getItem('sessionId');
        let url = environment.baseUrl + '/' + this.endpoint + '/updateOfferStatus';
        const options = this.createRequestOption({token, sessionId, transportDirection});
        return this.httpClient.post(url, updateOfferStatusDTO, {params: options, observe: 'response', responseType: 'text'})
    }

    myCustomerList(page:number, transportDirection = null, name = null): Observable<CompanyMinimizedModel[]> {
        const token = getToken(transportDirection);
        var url = `${environment.baseUrl}/${this.endpoint}/myCustomerList?token=${token.token}&sessionId=${token.sessionId}&page=${page}`;
        if(transportDirection) url += `&transportDirection=${transportDirection}`;
        if(name) url += `&name=${name}`;

        return this.httpClient.get<CompanyMinimizedModel[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(tap(),catchError(this.handleError))
    }

    poolCustomerList(page:number): Observable<CompanyMinimizedModel[]> {
        var token = localStorage.getItem("token");
        var sessionId = localStorage.getItem("sessionId");
        var url = `${environment.baseUrl}/${this.endpoint}/poolCustomerList?token=${token}&sessionId=${sessionId}&page=${page}`;

        return this.httpClient.get<CompanyMinimizedModel[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(tap(),catchError(this.handleError))
    }

    searchPoolCustomerByName(name: string, page: number, transportDirection: string = null, statu: string  = null) {
        const token = getToken(transportDirection)
        let url = `${environment.baseUrl}/${this.endpoint}/searchPoolCustomerByName?token=${token.token}&sessionId=${token.sessionId}&page=${page}&name=${name}&transportDirection=${transportDirection}`;

        return this.httpClient.get<CompanyMinimizedModel[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(tap(),catchError(this.handleError))

    }

    search(params, operatorId, transportDirection = null, isAll = false): Observable<ActivityDTOModel[]> {

        const query = encodeURI(JSON.stringify(params));
        let token = getToken(transportDirection);
        let url = `${environment.baseUrl}/${this.endpoint}/activity/search?query=${query}`;


        if(transportDirection) url += "&transportDirection=" + transportDirection;
        if(operatorId) url += "&operatorId=" + operatorId;
        return this.httpClient.get<ActivityDTOModel[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: {'token': token.token,'sessionId': token.sessionId, isAll: isAll} })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    searchByOrderAndLimit(params, limit, orderBy, transportDirection = null): Observable<ActivityDTOModel[]> {

        const query = encodeURI(JSON.stringify(params));
        let token = getToken(transportDirection);
        let url = `${environment.baseUrl}/${this.endpoint}/activity/searchByOrderAndLimit?query=${query}&limit=${limit}&orderBy=${orderBy}`


        if(transportDirection) url += "&transportDirection=" + transportDirection;

        return this.httpClient.get<ActivityDTOModel[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: {'token': token.token,'sessionId': token.sessionId} })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllOffersReportByQuery(params, transportDirection = null): Observable<MinimizedOfferModel[]> {

        const query = encodeURI(JSON.stringify(params));
        let token = getToken(transportDirection);
        let url =  `${environment.baseUrl}/${this.endpoint}/getAllOffersReportByQuery?query=${query}`;
        if(transportDirection) url += "&transportDirection=" + transportDirection;
        return this.httpClient.get<MinimizedOfferModel[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: {'token': token.token,'sessionId': token.sessionId} })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getCompanyCrmGoods(companyId: number, transportDirection = null): Observable<CompanyCRMDTO[]> {
        let token = getToken(transportDirection);
        var url = environment.baseUrl + "/" + this.endpoint + "/getCompanyCrmGoods?token=" + token.token + "&sessionId=" + token.sessionId + "&companyId=" + companyId;
        if(transportDirection) url += "&transportDirection=" + transportDirection;
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getCustomerRepresentativeByRegisterNo(registerNo: string, transportDirection: string): Observable<any[]> {
        let token = getToken(transportDirection);
        var url = environment.baseUrl + "/" + this.endpoint + "/customerRepresentativeListFindRegister?token=" + token.token + "&sessionId=" + token.sessionId + "&registerNo=" + registerNo + "&transportDirection=" + transportDirection;
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getsalesRepresentativeByCompanyId(companyId: number, transportDirection: string) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/marketing/def/customerRepresentative';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, companyId, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getFinancialListByRegisterNo(registerNo: string, transportDirection: string): Observable<any[]> {
        var token = localStorage.getItem("token");
        var sessionId = localStorage.getItem("sessionId");
        var url = environment.baseUrl + "/" + this.endpoint + "/companyFinancialListFindRegister?token=" + token + "&sessionId=" + sessionId + "&registerNo=" + registerNo + "&transportDirection=" + transportDirection;
        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    copy(id): Observable<Activity> {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/createCopyOffer?token=" + token + "&sessionId=" + sessionId + "&entityId=" + id;

        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }


    getActivityAddStepRepresentative(companyId, transportDirection = null, ): Observable<StepRepresentative> {
        const url = `${environment.baseUrl}/${this.endpoint}/getActivityAddStepRepresentative`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('companyId', companyId)

        return this.httpClient.get<StepRepresentative>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    saveActivityByCompanyId(mobileActivity: MobileActivity, transportDirection = null, ): Observable<MobileActivity> {
        const url = `${environment.baseUrl}/${this.endpoint}/saveActivityByCompanyId`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)
        console.log(mobileActivity);

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.post<MobileActivity>(url, mobileActivity, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    getActivityById(activityId, transportDirection = null, ): Observable<MobileActivity> {
        const url = `${environment.baseUrl}/${this.endpoint}/getActivityById`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('activityId', activityId)

        return this.httpClient.get<MobileActivity>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    deleteActivityRepresentativeById(id , transportDirection = null, ): Observable<boolean> {
        const url = `${environment.baseUrl}/${this.endpoint}/deleteActivityRepresentativeById?id=${id}`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.post<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    deleteActivityPersonById(activityId, personId, transportDirection = null, ): Observable<boolean> {
        const url = `${environment.baseUrl}/${this.endpoint}/deleteActivityPersonById?activityId=${activityId}&personId=${personId}`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }
    deleteActivityOperation(activityOperationId, transportDirection = null, ): Observable<boolean> {
        const url = `${environment.baseUrl}/${this.endpoint}/deleteActivityOperation?activityOperationId=${activityOperationId}`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }
    createActivityPerson(person: Person , transportDirection = null, ): Observable<Person> {
        const url = `${environment.baseUrl}/${this.endpoint}/createActivityPerson`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<Person>(url, person, {params: queryParam}).pipe(
          tap(),
          catchError(this.handleError)
        );


    }

    getActivityOperationsByCompanyId(companyId, transportDirection = null, ): Observable<ActivityOperation[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/getActivityOperationsByCompanyId`
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('companyId', companyId)

        return this.httpClient.get<ActivityOperation[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }



    offerPartialActivity(dto: OfferPartialActivityMobile, transportDirection: string = null): Observable<OfferPartialActivityMobile> {
        const url = `${environment.baseUrl}/${this.endpoint}/offerPartialActivity`
        let queryParam: HttpParams;
        let token = this.getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.post<OfferPartialActivityMobile>(url,dto, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    getOfferPartialActivity(activityId: number, transportDirection: string = null): Observable<OfferPartialActivityMobile> {
        const url = `${environment.baseUrl}/${this.endpoint}/getOfferPartialActivity`
        let queryParam: HttpParams;
        let token = this.getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('activityId', activityId);

        return this.httpClient.get<OfferPartialActivityMobile>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }
    searchOfferPartialActivity(seaGoodReferenceNo: string, referenceNo: string, podId: number, polId: number, customerId: number, validFrom: string, validTo: string, status: string, onlyReservation, transportDirection: string = null): Observable<OfferPartialActivityMobile[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/searchOfferPartialActivity`
        let queryParam: HttpParams;
        let token = this.getToken(transportDirection)
        queryParam = new HttpParams().append('token', token.token)
        .append('sessionId', token.sessionId)
        .append('transportDirection', transportDirection)
        .append('polId', polId)
        .append('podId', podId)
        .append('customerId', customerId)
        .append('validFrom', validFrom)
        .append('validTo', validTo)
        .append('status', status)
        .append('onlyReservation', onlyReservation);

        if (seaGoodReferenceNo) queryParam = queryParam.append('seaGoodReferenceNo', seaGoodReferenceNo);
        if (referenceNo) queryParam = queryParam.append('referenceNo', referenceNo);

        return this.httpClient.get<OfferPartialActivityMobile[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }
    getInvoice(id: number, transportDirection?: string) {
        const url = environment.baseUrl + '/' + this.endpoint + '/getInvoice';
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('id', id)

        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    geteinvoiceIncome(id: number, transportDirection?: string) {
        const url = environment.baseUrl + '/' + this.endpoint + '/getEinvoiceIncome';
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('id', id)

        return this.httpClient.get<any>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }
    getPDFDraftEinvoiceIncomeDet(id: number, transportDirection?: string) {
        const url = environment.baseUrl + '/' + this.endpoint + '/getPDFDraftEinvoiceIncomeDet';
        let token;
        let queryParam: HttpParams;
        token = getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('id', id)

        return this.httpClient.get<ArrayBuffer>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam, responseType: 'arraybuffer' as 'json' })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    searchOfferTruckPartialActivity(unloadCityId:number, loadCityId: number, customerId: number, validFrom: string, validTo: string, status: string, onlyReservation, transportDirection): Observable<OfferPartialActivityMobile[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/searchOfferTruckPartialActivity`
        let queryParam: HttpParams;
        let token = this.getToken(transportDirection)
        queryParam = new HttpParams().append('token', token.token)
            .append('sessionId', token.sessionId)
            .append('transportDirection', transportDirection)
            .append('loadCityId', loadCityId)
            .append('unloadCityId', unloadCityId)
            .append('customerId', customerId)
            .append('validFrom', validFrom)
            .append('validTo', validTo)
            .append('status', status)
            .append('onlyReservation', onlyReservation)

        return this.httpClient.get<OfferPartialActivityMobile[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    getfromWarehouse(name: string, transportDirection = null) {
        const url = environment.baseUrl + '/' + this.endpoint + '/getfromWarehouse';
        const token = getToken(transportDirection);
        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('name', name);
        return this.httpClient.get<SimpleEntity[]>(url, {params: queryParam, observe: 'response'});
    }

    findByCompanyAgentInfoTitleId(transportDirection, companyId): Observable<SimpleEntity[]> {
        const token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + '/findByCompanyAgentInfoTitleId' + "?token=" + token.token + "&transportDirection=" + transportDirection + "&sessionId=" + token.sessionId+ "&companyId=" + companyId
        return this.httpClient.get<any[]>(url,{ withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    // intranet endpoint'inde session ve token a bu şekilde erişemiyor token ve session queryParam olarak bekleniyor.
    // backend değiştirilirse offer-export.component deki istek çalışmıyor o nedenle offer-export.service deki şekilde değiştirilmiştir.
    // createActivity(activity: Activity) {

    //     var token = localStorage.getItem("token");

    //     var sessionId = localStorage.getItem("sessionId");

    //     var request: ActivityRequestModel = {
    //         activity: activity,
    //         token: token,
    //         sessionId: sessionId
    //     }

    //     var url = environment.baseUrl + "/" + this.endpoint + "/createActivity";

    //     return this.httpClient.post(url, request, { withCredentials: false, headers: this.httpHeaders() })
    //         .pipe(
    //             tap(),
    //             catchError(this.handleError)
    //         )

    // }

    //TODO: impersonation !!!
}
