import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, tap} from "rxjs/operators";
import {getToken} from "./token-interceptor.service";
import {QdmsFile} from "../models/qdms/file-model";

@Injectable({
  providedIn: 'root'
})
export class ListService extends BaseService<any>{

    constructor(public httpClient: HttpClient) {
        super(
            'list',
            httpClient);
    }

    search(params, endPoint, transportDirection = null): Observable<any[]> {
        let query = encodeURI(JSON.stringify(params || {})).split('#').join('%23');
        const token = getToken(transportDirection);

        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('endPoint', endPoint).append('query', query);
        return this.httpClient.get<any[]>(`${environment.baseUrl}/${this.endpoint}/search`, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    exportExcel(params, endPoint, transportDirection = null): any {

        let headers = new HttpHeaders();
        headers.append("Accept", "*");
        headers.append('Content-Type', '*');
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", 'true');
        headers.append("Access-Control-Allow-Methods", '*');

        const query = encodeURI(JSON.stringify(params || {}));
        const token = getToken(transportDirection);
        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('endPoint', endPoint).append('query', query);
        // @ts-ignore
        return this.httpClient.get<any>(`${environment.baseUrl}/${this.endpoint}/excelExport`, { withCredentials: false, headers: headers, responseType: 'blob', params: queryParam,
            observe: 'body' })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    // goodMobileSearch(params, url, transportDirection = null, operation): Observable<any[]> {
    //     const query = encodeURI(JSON.stringify(params || {}));
    //     const token = getToken(transportDirection);
    //     const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('query', query);
    //     return this.httpClient.get<any[]>(`${environment.baseUrl}/${this.endpoint}/${operation}`, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
    //       .pipe(
    //         tap(),
    //         catchError(this.handleError)
    //       );
    // }

    goodMobileSearch(params, url, transportDirection = null) {

        const token = getToken(transportDirection);

        const query = encodeURI(JSON.stringify(params || {}));

        const urlB = environment.baseUrl + '/' + this.endpoint + '/goodDetailListSearch';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, url, transportDirection, query});

        return this.httpClient.get<any>(urlB, {params: options, observe: 'response'});
    }
}
