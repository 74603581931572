import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IntranetLesson, IntranetLessonCategory } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';

@Component({
  selector: 'app-list-lesson-category',
  templateUrl: './list-lesson-category.component.html',
  styleUrls: ['./list-lesson-category.component.scss']
})
export class ListLessonCategoryComponent implements OnInit {


  categories: IntranetLessonCategory[] = [];

  selectedCategories: IntranetLessonCategory[] = [];

  constructor(private learningService: LearningService, public dialog: MatDialog, private messageService: MessageService) { }

  ngOnInit(): void {

    this.getCategories();

  }

  openDeleteConfirmDialog(entity: IntranetLessonCategory): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = entity.name
    dialogRef.componentInstance.fIndex = entity.id


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.learningService.deleteLessonCategory(entity.id).subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Ders Silindi" });
          this.getCategories();
        })
      }
    });
  }

  getCategories() {

    this.learningService.getAllLessonCategories().subscribe(res => {

      this.categories = res;

    })

  }

  delete(category: IntranetLessonCategory) {

  }

}
