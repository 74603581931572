import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Career } from '../models/career.model';
import { IntranetDocument } from '../models/intranet-document.model';
import { BaseService } from './base.service';


@Injectable()
export class CareerService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'career',
            httpClient);
    }


    get(id: number): Observable<Career> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/get?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Career>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    delete(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/delete?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    create(document: Career): Observable<Career> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/create";

        return this.httpClient.post<Career>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    update(document: Career): Observable<Career> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/save";

        return this.httpClient.post<Career>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllCareers() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllCareers?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<Career[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}