import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SimpleEntity } from '../models/simple-entity.model';
import {getToken} from './token-interceptor.service';

@Injectable()
export class RegionService extends BaseService<any> {

  public queryParam: HttpParams;

  constructor(public httpClient: HttpClient) {
    super('region', httpClient);
    this.queryParam = new HttpParams().set('token', localStorage.getItem("token")).set('sessionId', localStorage.getItem("sessionId"));
  }

  search(params, transportDirection  = null): Observable<SimpleEntity[]> {
    const query = encodeURI(JSON.stringify(params));
    if (transportDirection){
        const token = getToken(transportDirection);
        this.queryParam = new HttpParams().set('token', token.token).set('sessionId', token.sessionId).set('transportDirection',transportDirection);
    }

    return this.httpClient.get<SimpleEntity[]>(`${environment.baseUrl}/${this.endpoint}/search?query=${query}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
  }

}
