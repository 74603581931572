import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory, ExpensePackageItem } from 'src/app/models/expense.model';
import { IntranetLessonVideo } from 'src/app/models/learning.model';
import { FileDialogConfirmComponent } from 'src/app/pages/document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { ExpenseService } from 'src/app/services/expense.service';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-create-expense-package-category',
  templateUrl: './create-expense-package-category.component.html',
  styleUrls: ['./create-expense-package-category.component.scss']
})
export class CreateExpensePackageCategoryComponent implements OnInit {

  folderName: string;

  isEdit = false;

  model: ExpensePackageCategory = <ExpensePackageCategory>{
  };

  isProgressActive = false;

  constructor(public dialogRef: MatDialogRef<CreateExpensePackageCategoryComponent>, private expenseService: ExpenseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog,
    private messageService: MessageService) {

    if (data && data.expenseItem) {

      this.isEdit = true;
      this.model = data.expenseItem;

    }

  }

  ngOnInit(): void {

  }




  isLoading = false;

  save() {

    if (!this.isEdit) {
      this.expenseService.createExpensePackageCategory(this.model).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Kategori Eklendi" });
        this.dialogRef.close({
          model: res,
          isEdit: this.isEdit
        });
      })
    } else {

      this.expenseService.updateExpensePackageCategory(this.model).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Kategori Güncellendi" });
        this.dialogRef.close({
          model: res,
          isEdit: this.isEdit
        });
      })

    }





  }

}
