import { Injectable } from "@angular/core";
import { fakeAsync } from "@angular/core/testing";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        var authToken = localStorage.getItem("token");
        if (authToken) {
            return true;
        } else {
            this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}