import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { IntranetLessionVideoAssigment } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-lesson-assignment-dialog',
  templateUrl: './lesson-assignment-dialog.component.html',
  styleUrls: ['./lesson-assignment-dialog.component.scss']
})
export class LessonAssignmentDialogComponent implements OnInit {

  filteredUsers: any[] = [];
  selectedUser: any;
  intranetLessionVideoAssigments: IntranetLessionVideoAssigment[] = [];
  intranetLessionVideoAssigment: IntranetLessionVideoAssigment = new IntranetLessionVideoAssigment ();

  constructor(private userService: UserService,
    private learningService: LearningService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.learningService.getVideo(this.data.videoId).subscribe(r => {
      this.intranetLessionVideoAssigment.intranetLessonVideo = r;
    })

    this.learningService.getAssigmentUsers(this.data.videoId).subscribe(r => {
      this.intranetLessionVideoAssigments = r
    });

  }

  assignVideoToUser() {
    if(!this.selectedUser || !this.selectedUser.id) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Personel seçiniz." });
      return;
    }
    const existUser = this.intranetLessionVideoAssigments.find(f => f.user.id == this.selectedUser.id);
    if(existUser) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `${this.selectedUser.name} ataması mevcut.` });
      return;
    }
    Object.assign(this.intranetLessionVideoAssigment, {user: this.selectedUser});
    this.learningService.assignmentToUser(this.intranetLessionVideoAssigment).subscribe(r => {
      this.intranetLessionVideoAssigments.push(r);
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kullanıcı ataması başarılı." });
    }, error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Hata oluştu. Tekrar deneyin." });
    })
  }
  deleteAssignmentUser(intranetLessionVideoAssigment: IntranetLessionVideoAssigment) {
    if(!intranetLessionVideoAssigment) return;
    this.learningService.deleteAssignmentUser(intranetLessionVideoAssigment.id).subscribe(r => {
      const deletedAssignmentIndx = this.intranetLessionVideoAssigments.findIndex(f => f.id == intranetLessionVideoAssigment.id);
      if(deletedAssignmentIndx > -1) this.intranetLessionVideoAssigments.splice(deletedAssignmentIndx,1);
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kayıt silindi." });
    }, error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Hata oluştu. Tekrar deneyin." });
    })
  }

  filterUsers(event) {
    const query = event.query;
    this.userService.searchUsers(query).subscribe(res => {
      this.filteredUsers = res;
    })
  }


}
