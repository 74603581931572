import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Customer, Representative } from 'src/app/demo/domain/customer';
import { Product } from 'src/app/demo/domain/product';
import { CustomerService } from 'src/app/demo/service/customerservice';
import { ProductService } from 'src/app/demo/service/productservice';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { Activity } from 'src/app/models/activity.model';
import { CityMinimizedModel } from 'src/app/models/city-minimized.model';
import { Company } from 'src/app/models/company.model';
import { ActivityService } from 'src/app/services/activity.service';
import { CompanyService } from 'src/app/services/company.service';
import { OfferExportService } from 'src/app/services/offer-export.service';

@Component({
  selector: 'app-list-offer-truck',
  templateUrl: './list-offer-truck.component.html',
  styleUrls: ['./list-offer-truck.component.scss']
})
export class ListOfferTruckComponent implements OnInit {

  filteredCity: CityMinimizedModel[] = [];
  city: CityMinimizedModel[] = [];

  customers1: Customer[];

  customers2: Customer[];

  customers3: Customer[];

  selectedActivities: Activity[];

  selectedCustomer: Customer;

  representatives: Representative[];

  statuses: any[];

  seaGoodReferenceNo: string;

  products: Product[];

  rowGroupMetadata: any;

  activityValues: number[] = [0, 100];

  activityList: any[] = [];

  @ViewChild('dt') table: Table;

  subTypes: any[];
  selectedSubTypes: any;
  subType: String;
  etdStart: Date = new Date();
  etdEnd: Date;
  partialFull: string;
  loadCity : CityMinimizedModel;
  unloadCity : CityMinimizedModel;
  cities: CityMinimizedModel[] = [];
  filteredCities: CityMinimizedModel[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  items: MenuItem[];
  shipOwner: Company;
  customer: Company;
  transportDirection: "";
  isLoading = false;
  rememberLastSearch: any;
  privousPage = 0;
  statusText = {
    'pricing': 'Bekleniyor',
    'pendingConfirm': 'Fiyatlandırma Yapıldı',
    'confirmed': 'Rezervasyon',
    'accepted': 'Kabul',
    'rejected': 'Red',
    'revisionRequest': 'Revizyon',
  }
  filterPartialFull = [{name: 'Komple', value: 'F'}, {name: 'Parsiyel', value: 'P'}]

  startDate: Date;
  endDate: Date;
  constructor(private customerService: CustomerService, private messageService: MessageService, private datePipe: DatePipe,  private productService: ProductService, private activityService: ActivityService, private router: Router, private offerExportService: OfferExportService, private companyService: CompanyService) {
    let date = new Date();
    this.etdStart = new Date(date.getFullYear(), date.getMonth(), 1);
    this.etdEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  ngOnInit() {
    this.transportDirection = '';
    this.customerService.getCustomersLarge().then(customers => {
      this.customers1 = customers;
      // @ts-ignore
      this.customers1.forEach(customer => customer.date = new Date(customer.date));
    });
    this.customerService.getCustomersMedium().then(customers => this.customers2 = customers);
    this.customerService.getCustomersMedium().then(customers => this.customers3 = customers);
    this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    this.rememberLastSearch = JSON.parse(sessionStorage.getItem('truckSearchList'));
    if (this.rememberLastSearch != null) {
      this.activityList = this.rememberLastSearch;
      this.privousPage = +JSON.parse(sessionStorage.getItem('truckSearchListPrivousPage'));
    }

  }

  async onSearch(subType, onlyReservation = false) {
    this.activityList = [];
    this.isLoading = true;
    if(!this.etdStart || !this.etdEnd ) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Uyarı', detail: 'Lütfen tarih aralığı giriniz!' });
      this.isLoading = false;
      return;
    }
    if(!this.partialFull) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Uyarı', detail: 'Lütfen K-P tipini seçiniz!' });
      this.isLoading = false;
      return;
    }

    if(this.partialFull === 'P') {
      this.isLoading = true;
      try {
        this.activityList =  await this.activityService.searchOfferTruckPartialActivity(this.unloadCity?.id || 0, this.loadCity?.id || 0, this.customer?.id || 0, this.datePipe.transform(this.etdStart, 'yyyy-MM-dd'), this.datePipe.transform(this.etdEnd, 'yyyy-MM-dd'), subType, onlyReservation, this.transportDirection).toPromise();
        this.isLoading = false;
      } catch(e) {
        this.isLoading = false;
      }
    } else {
      this.activityService.getWithParams(subType, this.datePipe.transform(this.etdStart, 'yyyy-MM-dd'), this.datePipe.transform(this.etdEnd, 'yyyy-MM-dd'), (this.loadCity?.id || null), (this.unloadCity?.id || null), (this.shipOwner?.id || null), (this.customer?.id || null), 'TRUCK', onlyReservation, this.transportDirection, this.seaGoodReferenceNo).subscribe(res => {
        this.privousPage = 0;
        // @ts-ignore
        this.activityList = res.sort((act1, act2) => new Date(act2.createDate) - new Date(act1.createDate))
            .sort((act1, act2) => {
              // @ts-ignore
              if (new Date(act1.createDate) - new Date(act2.createDate) > 0) {
                return -1;
                // @ts-ignore
              } else if (new Date(act1.createDate) - new Date(act2.createDate) < 0) {
                return 1;
              } else if (act1.name > act2.name) {
                return -1;
              }
              return 0;
            });
        sessionStorage.setItem('truckSearchList', JSON.stringify(this.activityList));
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });
    }


  }
  onSort() {
    this.updateRowGroupMetaData();
  }


  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.customers3) {
      for (let i = 0; i < this.customers3.length; i++) {
        const rowData = this.customers3[i];
        const representativeName = rowData.representative.name;

        if (i === 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          const previousRowData = this.customers3[i - 1];
          const previousRowGroup = previousRowData.representative.name;
          if (representativeName === previousRowGroup) {
            this.rowGroupMetadata[representativeName].size++;
          }
          else {
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  goDetails(activity: any) {
    sessionStorage.setItem('truckSearchListPrivousPage', this.table.first.toString());
    let id: string;
    if(this.partialFull === 'P') id = activity.activity.id;
    else id = activity.id;
    this.router.navigate([`activities/offer-truck-request/activity/${id}`]);
  }

  filterCity(event) {
    if (event.query.length < 3) return;

    this.offerExportService.getOfferExportCity(event.query).subscribe(res => {
      this.filteredCities = res.body;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }

  savePrivousPage() {
    sessionStorage.setItem('truckSearchListPrivousPage', this.table.first.toString());
  }

}
