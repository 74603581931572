import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {

  justifyOptions = [
    {name: 'Rapor 1', icon: 'pi pi-chart-bar', justify: 'Left'},
    {name: 'Rapor 2', icon: 'pi pi-chart-bar', justify: 'Right'},
    {name: 'Rapor 3', icon: 'pi pi-chart-bar', justify: 'Center'},
    {name: 'Rapor 4', icon: 'pi pi-chart-bar', justify: 'Justify'}
  ];

  isFullScreen:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

}
