import {AbstractEntity} from './abstract-entity.model';
import {SimpleEntity} from './simple-entity.model';

export class EmailAttachment extends AbstractEntity {
    name: string;
    fileName: string;
    fileExtension: string;
    fileContentType: string;
    fileSize: number;
    reportId: number;
    createDate: Date;
    email: SimpleEntity;
}
