import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';


@Injectable()
export class SeaportService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'authentication',
            httpClient);
    }

    getAll(): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/seaport/getall?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }



    //TODO: impersonation !!!
}