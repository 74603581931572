import { KpiDefDTO } from "./kpi-def-dto";
import { KpiTargetCustomerDetDTO } from "./kpi-target-customer-det-dto";
import { KpiTargetCustomerMonthDetDTO } from "./kpi-target-customer-month-dets-dto";


export class KpiTargetCustomerMobile {
    kpiDef: KpiDefDTO;
    kpiTargetCustomerDet: KpiTargetCustomerDetDTO;
    editable: boolean = true;
    kpiTargetCustomerMonthDets: KpiTargetCustomerMonthDetDTO[];
    companyTypes: any;
    isNew: boolean = false;
}
