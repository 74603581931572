import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { SelectBoxItem } from '../models/select-box-item.model';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class SelectboxService  extends BaseService<any>{

  constructor(public httpClient: HttpClient) {
    super(
        'selectbox',
        httpClient);
  }


  getItemByBoxName(name: string, transportDirection: string = null): Observable<SelectBoxItem[]> {

    /* localStorage a kaydet varsa localStorage den getir. */
    const url = `${environment.baseUrl}/${this.endpoint}/getItemByBoxName`
    let queryParam: HttpParams;
    let token = this.getToken(transportDirection)

    queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("name", name)

    return this.httpClient.get<SelectBoxItem[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );
}



}
