import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from "../../services/base.service";
import { Observable } from "rxjs";
import { Currency } from "../../models/currency.model";
import { environment } from "../../../environments/environment";
import { CalendarEvent } from "../../models/calendar.model";

@Injectable()
export class EventService extends BaseService<any>{

    constructor(private http: HttpClient) {
        super(
            'calendar',
            http);
    }

    getEventsByDate(fromDate: Date, toDate: Date): Observable<any> {
        const token = localStorage.getItem('token');

        var user = JSON.parse(localStorage.getItem('user'));

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/api/' + this.endpoint + '/getEventsByDate';

        const options = this.createRequestOption({
            token: token,
            sessionId: sessionId, calendarId: '8053907', fromDate: '2021-10-31T21:00:00.000Z', toDate: '2021-12-12T21:00:00.000Z', userId: user.id
        });

        return this.httpClient.get<CalendarEvent[]>(url, { params: options, observe: 'response' });
    }
}
