import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {PremService} from '../../../services/prem.service';
import {DatePipe} from '@angular/common';
import {any} from 'codelyzer/util/function';
import * as moment from 'moment/moment';
import {MessageService} from 'primeng/api';
import {getToken} from '../../../services/token-interceptor.service';

@Component({
  selector: 'app-prem',
  templateUrl: './prem.component.html',
  styleUrls: ['./prem.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PremComponent implements OnInit {

    periodStartDate: Date = null;
    periodEndDate: Date = null;
    premCalculations: any;
    premCalculationDets: any;
    selectedPremCalculation: any;
    loading: any = false;
    expandPrice: any = false;
    showDescription: boolean = false;
    descIndex: number;
    protected readonly String = String;
    protected readonly Number = Number;
    showPremSubUser: any;
    premCalculationDetSubUsers: any;
    id: number;


  constructor(private premService: PremService, private datePipe: DatePipe, private messageService: MessageService) { }


  ngOnInit(): void {
      this.loading = true;
      this.premService.getAll().subscribe(res => {
          this.premCalculations = res;
          this.loading = false;
      },error => {
          this.loading = false;
      });
  }

  getPremCalculationDet(id){
      this.loading = true;
          this.premService.getPremCalculationDet(id).subscribe(res => {
              this.premCalculationDets = res;
              this.premCalculationDets.sort((a, b) => a.user.name.localeCompare(b.user.name));
              this.calculatePremPercent();
              this.loading = false;
          },error => {
              this.loading = false;
          });
  }

  calculatePremPercent(){

      const monthValue =this.differenceInMonths(new Date(this.selectedPremCalculation.periodEnd),new Date(this.selectedPremCalculation.periodStart)) + 1;
      this.premCalculationDets.forEach(premCalculationDet => {
        let percentUpperScale;
          const monthlyGoodProfit = premCalculationDet.totalGoodProfit / monthValue;
        if (monthlyGoodProfit >= premCalculationDet.percent10) {
          premCalculationDet.calculatedPercent = 10;
        } else if (monthlyGoodProfit >= premCalculationDet.percent8) {
            percentUpperScale = (premCalculationDet.percent10 - monthlyGoodProfit) / premCalculationDet.percent10;
            if ( percentUpperScale <= 0.05) {
                premCalculationDet.upperScale = 10;
            }
            premCalculationDet.calculatedPercent = premCalculationDet.isCalcUpperScale ? 10 : 8;
        } else if (monthlyGoodProfit >= premCalculationDet.percent6) {
            percentUpperScale = (premCalculationDet.percent8 - monthlyGoodProfit) / premCalculationDet.percent8;
            if ( percentUpperScale <= 0.05) {
                premCalculationDet.upperScale = 8;
            }
            premCalculationDet.calculatedPercent = premCalculationDet.isCalcUpperScale ? 8 : 6;
        } else if (monthlyGoodProfit >= premCalculationDet.percent4) {
            percentUpperScale = (premCalculationDet.percent6 - monthlyGoodProfit) / premCalculationDet.percent6;
            if ( percentUpperScale <= 0.05) {
                premCalculationDet.upperScale = 6;
            }
            premCalculationDet.calculatedPercent = premCalculationDet.isCalcUpperScale ? 6 : 4;
        }
      });
  }

  isUpperScaleOrCalculatedPercent(percent: number, item: any){

      if (percent === 4) {
          const differenceMonth = this.differenceInMonths(new Date(this.selectedPremCalculation.periodEnd),new Date(this.selectedPremCalculation.periodStart)) + 1;
          if ((item.generalCost * 3) > (item.percent4 * differenceMonth)) {
              return 'low-prem-det';
          }
      }

      if (item.calculatedPercent === percent) {
          return 'calculated-prem-det';
      }
      if (item.upperScale === percent) {
          return 'upper-scale-prem-det';
      }
      return '';
  }

    setTooltip(percent: number, item: any){

      let tooltip = ''
        if (percent === 4) {
            const differenceMonth = this.differenceInMonths(new Date(this.selectedPremCalculation.periodEnd),new Date(this.selectedPremCalculation.periodStart)) + 1;
            if ((item.generalCost * 3) > (item.percent4 * differenceMonth)) {
                tooltip = 'İlk hedef motivasyon priminden düşük \n';
            }
        }

        if (item.calculatedPercent === percent) {
            tooltip += 'Ulaştığı hedef\n';
        }
        if (item.upperScale === percent) {
            tooltip += 'Alt baremle arasındaki fark %5ten az';
        }
        return tooltip;
    }

  savePremCalculationDets(premCalculationDet?: any){
      if (premCalculationDet){
          const monthValue =this.differenceInMonths(new Date(this.selectedPremCalculation.periodEnd),new Date(this.selectedPremCalculation.periodStart)) + 1;

          const monthlyGoodProfit = premCalculationDet.totalGoodProfit / monthValue;
              let percentUpperScale;
              if (monthlyGoodProfit >= premCalculationDet.percent10) {

              } else if (monthlyGoodProfit >= premCalculationDet.percent8) {
                  percentUpperScale = (premCalculationDet.percent10 - monthlyGoodProfit) / premCalculationDet.percent10;
                  if ( percentUpperScale <= 0.05 && !premCalculationDet.isCalcUpperScale) {
                      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Üst baremle fark %5\'ten az' });
                  }
              } else if (monthlyGoodProfit >= premCalculationDet.percent6) {
                  percentUpperScale = (premCalculationDet.percent8 - monthlyGoodProfit) / premCalculationDet.percent8 ;
                  if ( percentUpperScale <= 0.05 && !premCalculationDet.isCalcUpperScale) {
                      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Üst baremle fark %5\'ten az' });
                  }
              } else if (monthlyGoodProfit >= premCalculationDet.percent4) {
                  percentUpperScale = (premCalculationDet.percent6 - monthlyGoodProfit) / premCalculationDet.percent6;
                  if ( percentUpperScale <= 0.05 && !premCalculationDet.isCalcUpperScale) {
                      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Üst baremle fark %5\'ten az' });
                  }
              }

          this.loading = true;
          this.premService.savePremCalculationDets([premCalculationDet]).subscribe(res => {
              this.premCalculationDets[this.premCalculationDets.indexOf(premCalculationDet)] = res.body[0];
              this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Başarıyla güncellendi!' });
              this.calculatePremPercent();
              this.loading = false;
          },error => {
              this.loading = false;
          });
      }else {
          this.loading = true;
          this.premService.savePremCalculationDets(this.premCalculationDets).subscribe(res => {
              this.premCalculationDets = res.body;
              this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Başarıyla güncellendi!' });
              this.calculatePremPercent();
              this.loading = false;
          },error => {
              this.loading = false;
          });
      }
  }

  createPremCalculation() {
      this.loading = true;
      this.periodEndDate.setHours(0,0,0,0);
      this.premService.createPremCalculation(this.datePipe.transform(this.periodStartDate, 'yyyy-MM-ddTHH:mm:ss'),
          this.datePipe.transform(this.periodEndDate, 'yyyy-MM-ddTHH:mm:ss')).subscribe(res => {
              const premCalculation = res.body.premCalculationDTO;
              this.premCalculations.push(premCalculation);
              this.selectedPremCalculation = premCalculation;
              this.premCalculationDets = res.body.premCalculationDetDTOS;
          this.calculatePremPercent();
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Başarıyla Oluşturuldu!' });

          this.loading = false;
      },error => {
          this.loading = false;
      });
  }

  dateFormat(period: Date){
      let date = moment(period, 'YYYY.MM.DD')
    return  date.format('DD/MM/YYYY')
  }



    calcUpperScale(premCalculationDetDto) {
    this.loading = true;
    this.premService.calcUpperScale(premCalculationDetDto).subscribe(res => {
        this.premCalculationDets[this.premCalculationDets.indexOf(premCalculationDetDto)] = res.body;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Üst Baremden Hesaplandı!' });
        this.calculatePremPercent();
        this.loading = false;
    }, error => {
        this.loading = false;
    });
    }

    calculatePremCalculation() {
        this.loading = true;
        this.premService.calculatePremCalculation(this.datePipe.transform(this.periodStartDate, 'yyyy-MM-ddTHH:mm:ss'), this.datePipe.transform(this.periodEndDate, 'yyyy-MM-ddTHH:mm:ss') , this.selectedPremCalculation.id)
            .subscribe(async res => {
                await this.getPremCalculationDet(this.selectedPremCalculation.id);
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Başarıyla Hesaplandı!' });
            });
    }

    differenceInMonths(date1, date2) {
        const monthDiff = date1.getMonth() - date2.getMonth();
        const yearDiff = date1.getYear() - date2.getYear();

        return monthDiff + yearDiff * 12;
    }



    openDesc(i: any) {
        setTimeout(() =>   {
            this.showDescription = !this.showDescription;
            this.descIndex = Number(i);
        } , 100);
    }

    getBranches(premCalculationDets: any[]) {
        const list = premCalculationDets?.map(p => p.userBranch).filter(p => p != null);

        const unique = (arr, track = new Set()) =>
            list.filter(({ id }) => (track.has(id) ? false : track.add(id)));

        return list ? unique(list) : [];
    }

    setSubUsers(item: any) {
      console.log(item);
      if (item?.premCalculationDetSubUserDTOList?.length > 0){
          this.premCalculationDetSubUsers = item.premCalculationDetSubUserDTOList;
          this.showPremSubUser = true;
      }

    }
}
