import {Component, OnInit, ViewChild, ViewChildren} from '@angular/core';
import { DatePipe } from '@angular/common';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { AirMinimizedModel } from 'src/app/models/air-minimized.model';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.model';
import { CompanyMinimizedModel } from 'src/app/models/company-minimized.model';
import { ActivityService } from 'src/app/services/activity.service';
import { Activity } from 'src/app/models/activity.model';
import {Table} from "primeng/table";
import {Router} from "@angular/router";
@Component({
  selector: 'app-air-offer-list',
  templateUrl: './air-offer-list.component.html',
  styleUrls: ['./air-offer-list.component.scss']
})
export class AirOfferListComponent implements OnInit {


  statusText = {
    'inProgress': 'Bekleniyor',
    'inProgressCompleted': 'Fiyatlandırma Yapıldı',
    'confirmed': 'Rezervasyon',
    'accepted': 'Kabul',
    'rejected': 'Red',
    'revisionRequest': 'Revizyon',
    'pricing': 'Fiyatlandırma Aşamasında',
    'pendingCustomer': 'Müşteriye İletildi'
    }
    subType: string = null;

  selectedActivities: Activity = null;
  activites: Activity[] = [];

  filteredAirport: AirMinimizedModel[] = [];
  airport: AirMinimizedModel[] = [];

  companies: Company[] = [];
  filteredCompanies: Company[] = [];

  filteredAirlineMinimized: CompanyMinimizedModel[] = [];
  airlines: CompanyMinimizedModel[] = [];

  statusPricing: any[] = [
    {name: 'Fiyatlandırma Aşamasında', code: 'pricing'},{name: 'Bekleniyor', code: 'inProgress'}
  ]

  statusProgressCompleted: any[] = [
    {name: 'Fiyatlandırma Yapıldı', code: 'inProgressCompleted'},{name: 'Müşteriye İletildi', code: 'pendingCustomer'}
  ]

  toPort: any;
  fromPort: any;
  customer: any;
  airline: any;
  isLoading: boolean = false;
  etdStart: Date = new Date();
  etdEnd: Date;
  selectedTransportDirection:string;

    rememberLastSearch: any;
    privousPage = 0;
    @ViewChild(Table) table: Table;
    referenceNo: any;

  constructor(private offerExportService: OfferExportService,
    private companyService: CompanyService,
    private activityService: ActivityService,
    private datePipe: DatePipe,
              private router: Router) { }

  ngOnInit(): void {
    this.selectedTransportDirection = '';
      this.rememberLastSearch = JSON.parse(sessionStorage.getItem('airSearchList'));
      if (this.rememberLastSearch != null) {
          this.activites = this.rememberLastSearch;
          this.privousPage = +JSON.parse(sessionStorage.getItem('airSearchListPrivousPage'));
      }
      const date = new Date();
      this.etdStart = new Date(date.getFullYear(), date.getMonth(), 1);
      this.etdEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }


  search(onlyReservation = false) {
    this.isLoading = true;
    this.activityService.getWithParams(this.subType, this.datePipe.transform(this.etdStart, 'yyyy-MM-dd'), this.datePipe.transform(this.etdEnd, 'yyyy-MM-dd'), this.fromPort?.id, this.toPort?.id, null, this.customer?.id, 'AIR', onlyReservation, null, this.referenceNo, 'I', this.selectedTransportDirection).subscribe(r => {
        this.privousPage = 0;
        // @ts-ignore
        this.activites = r.sort((act1, act2) => new Date(act2.createDate) - new Date(act1.createDate))
            .sort((act1, act2) => {
            // @ts-ignore
            if (new Date(act1.createDate) - new Date(act2.createDate) > 0) {
                return -1;
            // @ts-ignore
            } else if (new Date(act1.createDate) - new Date(act2.createDate) < 0) {
                return 1;
            } else if (act1.name > act2.name) {
                return -1;
            }
            return 0;
        });
      this.isLoading = false;
      sessionStorage.setItem('airSearchList', JSON.stringify(r));
    }, error => {
        this.isLoading = false;
    })
  }

  onChangeDirection(event){
    this.selectedTransportDirection = event.value.toString();
    this.search();
  }

  airPortSearch(event) {
    if (event.query.length > 1) {
      this.offerExportService.airportSearch(event.query, 'I').subscribe(res => {
        this.filteredAirport = res.body;
        this.airport = res.body;
        const filtered: AirMinimizedModel[] = [];
        const query = event.query;
        this.airport.forEach(country => {
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
          }
        });
        this.filteredAirport = filtered;
      });
    }
  }

  companySearch(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.companies = res;
      this.filteredCompanies = res;
      const filtered: Company[] = [];
      const query = event.query;
      this.companies.forEach(country => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(country);
        }
      });
      this.filteredCompanies = filtered;
    });
  }

  airlineSearch(event) {
    if (event.query.length > 1) {
      this.offerExportService.airlineSearch(event.query).subscribe(res => {
        this.filteredAirlineMinimized = res.body;
        this.airlines = res.body;
        const filtered: CompanyMinimizedModel[] = [];
        const query = event.query;
        this.airlines.forEach(country => {
          if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
          }
        });
        this.filteredAirlineMinimized = filtered;
      });
    }
  }

    savePrivousPage(activity: any) {
      sessionStorage.setItem('airSearchListPrivousPage', this.table.first.toString());
      this.router.navigate(['/activities/air-offer-detail/' + activity.id])
    }
    onChangeStatus(event: any) {
      this.subType = event.value.code;
      this.search()
    }
}
