import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Survey } from 'src/app/models/survey-models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  survey: Survey = <Survey>{};
  isLoading = false;
  hash: string = null;
  hasParamError: boolean = false;

  constructor(private surveyService: SurveyService, private route: ActivatedRoute, private messageService: MessageService) {}

  ngOnInit(): void {
    this.hash = String(this.route.snapshot.params.hash);
       
    if(!this.hash) {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Parametre hatası" });
      this.hasParamError = true;
      return;
    }

    this.surveyService.getPublicSurvey(this.hash).subscribe(res => {
      this.survey = res;
      this.survey.questions.filter(f => f.isTextAnswer).map(q => q.selectedAnswer = q.questionAnswers[0]);
    });

   }

  save() {
    this.isLoading = true;
    this.surveyService.completeSurvey(this.survey, this.hash).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ severity: 'success', summary: 'Başarılı', detail: "Anket Sonuçlarınız Başarıyla Kaydedildi" });
    }, err => {
      this.messageService.add({severity: 'error', summary: 'Hata', detail: "Anket yanıtları kaydedilemedi!" });
      this.isLoading = false;

    })
  }
}
