import { ThisReceiver } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IntranetWidgetUserGroup } from 'src/app/models/intranet-widget-group.model';
import { IntranetWidget } from 'src/app/models/intranet-widget.model';
import { IntranetWidgetService } from 'src/app/services/intranet-widget.service';

@Component({
  selector: 'app-create-widget-user-role',
  templateUrl: './create-widget-user-role.component.html',
  styleUrls: ['./create-widget-user-role.component.scss']
})
export class CreateWidgetUserRoleComponent implements OnInit {

  isEdit = false;

  widget: IntranetWidget = <IntranetWidget>{};

  userRoles: any[] = [];

  selectedUserRoles: any[] = [];

  constructor(private intranetWidgetService: IntranetWidgetService, private messageService: MessageService,
    public dialogRef: MatDialogRef<CreateWidgetUserRoleComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,) {

    if (data && data.widget) {

      this.isEdit = true;
      this.widget = data.widget;

    }

  }

  widgets: IntranetWidget[] = [];

  selectedWidgets: IntranetWidget[] = [];

  isLoading = false;

  selectedWidgetUserGroup: IntranetWidgetUserGroup;

  ngOnInit(): void {

    this.intranetWidgetService.getWidgetRoles().subscribe(res => {

      res.forEach(item => {
        if (item.widgetId == this.widget.id) {
          this.selectedWidgetUserGroup = item;
        }
      })

    })

    this.intranetWidgetService.getUserRoles().subscribe(res => {

      console.log(res);

      this.userRoles = res;



      this.userRoles.forEach(item => {



      })

    })

    this.intranetWidgetService.getAvailableWidgets().subscribe(res => {

      console.log(res);

      this.widgets = res;

      this.widgets.forEach(item => {

        if (item.selected) {
          this.selectedWidgets.push(item);
        }

      })



    })

  }

  save() {

    this.isLoading = true;


    if (this.selectedWidgetUserGroup) {

      var userRoles = this.selectedUserRoles;

      var userRoleIds = userRoles.map(u => u.id).join(';');

      this.selectedWidgetUserGroup.userGroups = userRoleIds;

      this.selectedWidgetUserGroup.widgetId = this.widget.id;

      this.intranetWidgetService.updateWidgetUserGroup(this.selectedWidgetUserGroup).subscribe(res => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Widget Ayarları Kaydedildi" });
      })


    } else {

      var userRoles = this.selectedUserRoles;

      var userRoleIds = userRoles.map(u => u.id).join(';');

      var newModel: IntranetWidgetUserGroup = {
        id: null,
        userGroups: userRoleIds,
        widgetId: this.widget.id
      };

      this.intranetWidgetService.createWidgetUserGroup(newModel).subscribe(res => {
        this.isLoading = false;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Widget Ayarları Kaydedildi" });
      })


    }

  }

}
