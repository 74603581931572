import {AbstractEntity} from '../abstract-entity.model';
import { SimpleEntity } from '../simple-entity.model';

export class FolderPermissions extends AbstractEntity {
    folder: SimpleEntity;
    user: SimpleEntity;
    write: Boolean;
    read: Boolean;
    delete: Boolean;
    userGroups: number[] = [];

}
