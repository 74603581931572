import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetLesson } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';

@Component({
  selector: 'app-create-lesson',
  templateUrl: './create-lesson.component.html',
  styleUrls: ['./create-lesson.component.scss']
})
export class CreateLessonComponent implements OnInit {

  model: IntranetLesson = <IntranetLesson>{};

  editorText: string;

  categoryList: any[] = [];

  categorySelectedItem: any;

  constructor(private learningService: LearningService, private messageService: MessageService, private router: Router,
    private storage: AngularFireStorage, public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.getCategories();

  }

  getCategories() {

    this.learningService.getAllLessonCategories().subscribe(res => {

      this.categoryList = res;

    })

  }

  isLoading = false;

  save() {

    if (!this.categorySelectedItem) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: "Kategori Seçimi Zorunludur" });
      return;

    }

    this.isLoading = true;

    this.model.categoryId = this.categorySelectedItem.id;

    this.model.description = this.editorText;

    this.learningService.createLesson(this.model).subscribe(res => {

      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Ders Kaydedildi" });

      setTimeout(() => {

        this.router.navigate(['/learning/list-lesson']);

      }, 2000)

    }, err => {

      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;

    })

  }

  onFileChange(pFileList: File[]) {

    Array.from(pFileList).map(f => f.name).forEach(fName => {
      let extenstion = fName.split('.').pop();
      if (['jpg', 'jpeg', 'bpm', 'png', 'gif'].includes(extenstion)) {
        var file = pFileList[0];
        this.uploadedFiles.push(file);
        const filePath = `${this.basePath}/${file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, file);

        uploadTask.percentageChanges().subscribe(res => {
          this.uploadProgress = res;
        })


        this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {
          if (res.state == 'success') {

            storageRef.getDownloadURL().subscribe(url => {

              this.model.logoUrl = url;

              this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

              this.files.push(file);

            });

          }

        });
      } else {
        this.messageService.add({ key:'tst', severity: 'warn', summary: 'Dosya formatı hatalı', detail: `${fName} görsel formatında olmalıdır.` });
      }
    })


  }

  myVideos = [];

  uploadProgress: number = 0;

  private basePath = '/uploads/learning/logos';

  uploadedFiles: any[] = [];

  files: File[] = [];

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }

  openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
    this.model.logoUrl = null;
  }

}
