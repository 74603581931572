import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {OfferExportService} from "../../../../services/offer-export.service";


@Component({
    selector: 'app-card-offer-list',
    templateUrl: 'card-offer-list.component.html'
})
export class CardOfferListComponent implements OnInit {

    @Input() id?: number;
    @Input() dashboardDetail: any = null;
    @Output() previousPage = new EventEmitter<boolean>();
    @Input() showPreviousBtn: boolean = true;
    @Output() onClose = new EventEmitter<boolean>();
    @Input() seaOffer: any;
    @Input() selectedTransportDirection: string;
    isLoading = false;
    offers: any;
    activityId: any;
    displayOfferDetail: boolean;

    constructor(private offerExportService: OfferExportService) {
    }

    ngOnInit(): void {
        if (this.id) { this.listSearch(this.id); }
    }

    onVisibleChange() {
        this.onClose.emit(false);
    }


    listSearch(id) {
        console.log(this.dashboardDetail)
        this.isLoading = true;
        const listName = this.dashboardDetail.operationType.charAt(0).toUpperCase() + this.dashboardDetail.operationType.slice(1).toLowerCase() + (this.dashboardDetail?.positionGoodType != null ? this.dashboardDetail?.positionGoodType[0]?.toUpperCase() : '') +
            (this.dashboardDetail?.positionGoodType != null ? this.dashboardDetail?.positionGoodType?.toLowerCase()?.slice(1) : '');
        const url = `/offers/list/offer${listName}DetailList/listSearch?name=offer${listName}DetailList&query=%7B%22dashboardOfferId%22%3A%22` + id + '%22%7D';

        this.offerExportService.listSearch(url, this.selectedTransportDirection).subscribe(res => {
            this.offers = res.body;
            this.isLoading = false;
        });
    }

}
