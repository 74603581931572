import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ListService} from "../../../services/list.service.service";
import * as FileSaver from 'file-saver'
import { v4 } from 'uuid';
import * as moment from "moment";
import * as XLSX from "xlsx";
import { UserService } from 'src/app/services/user.service';
import {DatePipe} from '@angular/common';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import {Company} from '../../../models/company.model';
import {CompanyService} from '../../../services/company.service';
import { KpiService } from 'src/app/services/kpi.service';

@Component({
  selector: 'app-list-kpi-customer-target',
  templateUrl: './list-kpi-customer-target.component.html',
  styleUrls: ['./list-kpi-customer-target.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListKpiCustomerTargetComponent implements OnInit {

    isEnableSalesRepFilter: Boolean = false;
    filteredUsers: SimpleEntity[] = [];
    transportDirections = [{name: 'KTL International', value: 'I'}, {name: 'KINAY', value: 'E'}];
    filterPeriods = [{name: 'Hepsi', value: -1} , {name: 'Aylık', value: 'MONTHLY'}, {name: 'Yıllık', value: 'YEARLY'}];
    statusType = [{name: 'Hepsi', value: -1} , {name: 'Aktif', value: 'ACTIVE'}, {name: 'Pasif', value: 'PASSIVE'}];
    filterOperationTypes = [{name: 'Hepsi', value: -1} , {name: 'Denizyolu Taşımacılığı', value: 'SEA'}, {name: 'Havayolu Taşımacılığı', value: 'AIR'}, {name: 'Yurtiçi', value: 'INLAND'}, {name: 'Karayolu Taşımacılığı', value: 'TRUCK'}, {name: 'Muhasebe', value: 'ACCOUNTING'}]
    filterPartialFull = [{name: 'Hepsi', value: -1} , {name: 'FCL', value: 'F'}, {name: 'LCL', value: 'P'}, {name: 'FCL/LCL', value: 'FP'}, {name: 'LCL/FCL', value: 'PF'}]
    filterCategories = [{name: 'Hepsi', value: -1} , {name: 'Özel Yük', value: 'SG'}, {name: 'Mermer', value: 'MB'}, {name: 'Genel Yük', value: 'GG'}, {name: 'Reefer', value: 'RF'}, {name: 'Mineral', value: 'MD'}]
    selectedTransportDirection = String(this.userService.getUserFromLocalStorage()["company"]["name"]).includes('KTL') ? 'I': 'E';
    status = null;
    selectedPartialFull = null;
    selectedCategory = null;
    selectedOperationType = null;
    selectedPeriod = null;
    activityRepresentative = JSON.parse(localStorage.getItem('userimport')).firstName + ' ' + JSON.parse(localStorage.getItem('userimport')).lastName;
    filteredCompanies: Company[] = [];
    selectedCompany: any = null;
    selectedKpiTransportDirection: any = 'I';
    teuTextTranslate = {
        'TONNAGE': 'Tonaj',
        'FILEQUANTITY': 'Dosya Ad.',
        'NEWCUSTOMER': 'Yeni Müşteri',
        'TEU': 'Teu',
        'target': 'Hedef'
      }
    cols= [{
        header: 'KPI',
        key: 'kpiDef',
        type: 'SimpleEntity'
    }, {
        header: 'Kullanıcı',
        key: 'user',
        type: 'SimpleEntity'
    } , {
        header: 'Müşteri',
        key: 'customer',
        type: 'SimpleEntity'
    }, {
        header: 'Müşteri Tip',
        key: 'companyType',
        type: 'string'
    },{
        header: 'Tip',
        key: 'teuText',
        type: 'string'
    },
    {
        header: 'Toplam',
        key: 'total',
        type: 'number'
    }, {
        header: 'Potansiyel Top.',
        key: 'potentialTotalTeu',
        type: 'number'
    },  {
        header: 'Ocak',
        onlyHeader: true,
        type: 'number'
    },{
        header: 'Şubat',
        onlyHeader: true,
        type: 'number'
    },
     {
        header: 'Mart',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Nisan',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Mayıs',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Haziran',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Temmuz',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Ağustos',
        onlyHeader: true,
        type: 'number'
    }, {
        header: 'Eylül',
        onlyHeader: true,
        type: 'number'
    },{
        header: 'Ekim',
        onlyHeader: true,
        type: 'number'
    },{
        header: 'Kasım',
        onlyHeader: true,
        type: 'number'
    },{
        header: 'Aralık',
        onlyHeader: true,
        type: 'number'
    }]


    filterDates = [{
        label: 'Açılış Tarihi',
        value: 'createDate'
    }];

    teuText = {
        'TONNAGE': 'targetTonnage',
        'FILEQUANTITY': 'targetFileQuantity',
        'NEWCUSTOMER': 'targetNewCustomer',
        'TEU': 'targetTeu',
        'target': 'target'
    }
    teuTextActual = {
        'TONNAGE': 'actualTonnage',
        'FILEQUANTITY': 'actualFileQuantity',
        'NEWCUSTOMER': 'actualNewCustomer',
        'TEU': 'actualTeu',
        'target': 'actual'
    }

    selectedFilterDate = 'createDate';

    isLoading: boolean = false;
    result: any = null;
    kpiTransportDirections: any[] = [{ "code": "ORTAK", "name": "Genel" }, { "code": "T", "name": "Transshipment" }, { "code": "I", "name": "İthalat" }, { "code": "E", "name": "İhracat" }, { "code": "D", "name": "Yurtiçi" }];
    tmaxxSearchEndPoints = "/marketing/list/kpiTargetCustomerMonthDetList/listSearch?name=kpiTargetCustomerMonthDetList"


    filters = {
        dateStart: {
            isDate: true,
            prefix: '/DT/GT',
            value: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        },
        dateEnd: {
            isDate: true,
            prefix: '/DT/LT',
            value: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
        operator: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.user.id',
            value: this.getOperatorId()
        },
        customer: {
            prefix: 'kpiTargetCustomerDet.customer.id',
            value: this.selectedCompany,
        },
        kpiTransportDirection: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.transportDirection',
            value: this.selectedKpiTransportDirection,
        },
        status: {
            prefix: 'kpiTargetCustomerDet.status',
            value: 'value',
        },
        partialFull: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.partialFull',
            value: 'value',
        },
        category: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.category',
            value: 'value',
        },
        operationType: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.operationType',
            value: 'value',
        },
        period: {
            prefix: 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.period',
            value: 'value',
        },



        getParams: () => {
            let params = {};
            Object.keys(this.filters).forEach(k => {

                if(typeof this.filters[k] == "function") return;
                // @ts-ignore
                if(this.filters[k].value) {
                    // @ts-ignore
                    let value = this.filters[k].value;
                    if(typeof value  == 'object') {
                        value = value instanceof Date ? value.toISOString().slice(0, -1): (value.code || value.id)
                    }

                    // date format fix
                    if(this.filters[k]?.isDate) {
                        let d = new Date(value);
                        d.setDate(d.getDate() +1);
                        if(this.filters[k].prefix.includes('/GT') ) {
                            value = d.toISOString().split('T')[0] + 'T00:00:00.000'
                        }
                        if(this.filters[k].prefix.includes('/LT') ) {
                            value = d.toISOString().split('T')[0] + 'T23:59:59.000'
                        }
                        Object.assign(params, {[this.selectedFilterDate + this.filters[k].prefix]: value});
                        return;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.status') {
                        value = this.status;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.transportDirection') {
                        value = this.selectedKpiTransportDirection;
                    }
                    if (this.filters[k].prefix === 'customer') {
                        value = this.selectedCompany.id;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.partialFull') {
                        value = this.selectedPartialFull;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.category') {
                        value = this.selectedCategory;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.operationType') {
                        value = this.selectedOperationType;
                    }
                    if (this.filters[k].prefix === 'kpiTargetCustomerDet.kpiTargetMonth.kpiTarget.kpiDef.period') {
                        value = this.selectedPeriod;
                    }
                    if (value !== null && value !== -1)
                        Object.assign(params, {[this.filters[k].prefix]: value});
                }
            })
            return params;
        }
    }


    constructor(private companyService: CompanyService, private listService: ListService, private userService: UserService, private datepipe: DatePipe, private kpiService: KpiService) { }

    async ngOnInit() {
        this.isEnableSalesRepFilter = await  this.getUserEnableSalesRepFilter();
        this.selectedKpiTransportDirection = this.selectedTransportDirection;
        const user = this.getUserByTransportDirection(this.selectedTransportDirection);
        Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
        this.filters.operator.value = user
    }

    async onChangeTransportDirection() {
        this.isEnableSalesRepFilter = await  this.getUserEnableSalesRepFilter();
        const user = this.getUserByTransportDirection(this.selectedTransportDirection);
        Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
        this.filters.operator.value = user
    }

    async getUserEnableSalesRepFilter() {
        return await this.userService.function("USER_ENABLE_SALESREP_FILTER", this.selectedTransportDirection).toPromise()
    }

    async search() {
        try {
            this.isLoading = true;
            let par = this.filters.getParams();
            this.result = await this.getData(par);
            console.log(this.result);
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }

    }

    async getData(params = null) {
        return this.listService.search(params, this.tmaxxSearchEndPoints, this.selectedTransportDirection).toPromise();
    }

    filterCompany(event) {
        if (event.query.lenght < 3) return;

        this.companyService.search({'name/SW': event.query}, this.selectedTransportDirection, false).subscribe(res => {
            this.filteredCompanies = res;
        });
    }



    getUserByTransportDirection(transportDirection) {
        if(transportDirection == 'I' && localStorage.getItem('import')) {
            return JSON.parse(localStorage.getItem('import')).user
        }
        if(transportDirection == 'E' && localStorage.getItem('export')) {
            return JSON.parse(localStorage.getItem('export')).user
        }

        return JSON.parse(localStorage.getItem('user'))
    }

    getOperatorId() {
        const a =  this.selectedTransportDirection == 'I' ? (this.userService.getUserFromLocalStorage('userimport') ? this.userService.getUserFromLocalStorage('userimport') : this.userService.getUserFromLocalStorage('user') ) : this.userService.getUserFromLocalStorage('user');
    }

    async exportToExcel() {

        if(!this.result) return;

        var rows = [];
        var header = [];
        const colSizes = [];
        const rowSizes = [];

        for (let column of this.cols) {
            header.push(column.header);
            colSizes.push({ wch: 100 / 7 });
        }
        rows.push(header);

        for (let data of this.result) {
            rowSizes.push({ hpt: 25 });
            var row = [];

            for (let column of this.cols) {
                if(!column.onlyHeader) {
                    if (column.type == "number") {
                        let number = String(data[column.key]);

                        if(number.indexOf(',') > -1 && number.indexOf('.') > -1) {
                            number = number.replace(/\./g, '').replace(/\,/g, '.');
                        } else {
                            number = number.replace(/\,/g, '.');
                        }

                        row.push(Number(number));
                    } else if (column.type == "SimpleEntity") {
                        row.push(data[column.key]?.name);
                    } else if (column.type == "date") {
                        if (data[column.key] != null) {
                            try {
                                row.push(this.datepipe.transform(data[column.key], 'dd.MM.YYYY'));
                            } catch (error) {
                                row.push(data[column.key]);
                            }
                        } else {
                            row.push(null);
                        }
                    } else {
                        row.push(data[column.key]);
                    }
                }


            }

            let gpRow = [...row];

            if(data.isUseGp) {
                gpRow[4] = "GP"
                gpRow[5] =  data.totalGp;
                gpRow[6] =  data.potentialTotal;
                data.monthDets.forEach(det => {
                    gpRow.push(`${det.actual}/ ${det.target}`);
                })
                console.log(gpRow, "gpRowgpRowgpRowgpRow")
                rows.push(gpRow);
                gpRow = [];
            }

            //aylık detaylar
            data.monthDets.forEach(det => {
                row.push(`${det[this.teuTextActual[data.teuText]]}/ ${det[this.teuText[data.teuText]]}`);
            })




            rows.push(row);
            row = [];
        }

        console.log(rows,"rowsssss")
        const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
        workSheet['!cols'] = colSizes;
        workSheet['!rows'] = rowSizes;
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Tmaxx');
        XLSX.writeFile(workBook, "kpiListResult" + ".xlsx");
    }

    formatValue(column, value) {
        if (column?.type == "number") {
            let number = value;
            const val = (number)?.toLocaleString('tr-TR', {style: 'currency',currency: 'TRY',});
            if(val?.length == 1) return val;
            return (number)?.toLocaleString('tr-TR', {style: 'currency',currency: 'TRY',}).substring(1)
        } else if (column.type == "date") {
            if (value != null) {
                try {
                    return new Date(value).toLocaleString();
                } catch (error) {
                    return "";
                }
            }
        } else {
            return value;
        }
        return value;
    }

    getTotal(column, formatted = true) {
        let sum = 0;
        if (column.type == "number" && this.result) {

            this.result.datas.forEach( f =>  sum += Number(f[column.id]))
            if(formatted) return this.formatValue(column, sum);
            return sum;
        }
        return "";
    }

    filterUsers(event) {
        this.userService.searchUsers(event.query, this.selectedTransportDirection).subscribe(res => {
            this.filteredUsers = res;
            console.log(this.filteredUsers)
        })
    }

    precisionRoundMod(number, precision) {
        var factor = Math.pow(10, precision);
        var n = precision < 0 ? number : 0.01 / factor + number;
        return Math.round( n * factor) / factor;
      }
}
