import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';

import {Email} from '../../../../models/email.model';
import {EmailAttachment} from '../../../../models/email-attachment.model';
import EntityUtil from '../../../../models/framework/utils/entity-util';

import { Activity } from 'src/app/models/activity.model';
import { Allocation, seaFreightTariffDetMobiles } from 'src/app/models/allocation.model';
import { Company } from 'src/app/models/company.model';
import { ContainerModel } from 'src/app/models/container.model';
import { Equimenet } from 'src/app/models/equiment.model';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { UpdateOfferStatusDTO } from 'src/app/models/update-offer-status-dto.model';
import {DistrictMinimizedModel} from '../../../../models/district-minimized.model';
import {CityMinimizedModel} from '../../../../models/city-minimized.model';
import {CountryMinimizedModel} from '../../../../models/country-minimized.model';

import { Incoterms } from 'src/app/models/enums/incoterms';
import { TransportTypes } from 'src/app/models/enums/transport-types';
import { SeaAllocationEditDTOs } from 'src/app/models/response/sea-allocation-edit-dtos.model';

import { ActivityService } from 'src/app/services/activity.service';
import { CompanyService } from 'src/app/services/company.service';
import { ContainerService } from 'src/app/services/container.service';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { AllocationService } from 'src/app/services/allocation.service';
import { PersonService } from 'src/app/services/person.service';

import { CreateCustomerComponent } from 'src/app/shared/create-customer/create-customer.component';
import { SeaFrightRequestDetMobiles } from 'src/app/models/sea-fright-request-det-mobiles';
import { SeaContainerRezervations } from 'src/app/models/sea-fright-request-det-mobiles';
import {ListService} from "../../../../services/list.service.service";
import {DashboardService} from "../../../../services/dashboard.service";
import b64toBlob from 'b64-to-blob';
import { UserService } from 'src/app/services/user.service';
import { GoodDefinationMinimized } from 'src/app/models/good-defination-minimized';
import {data} from 'browserslist';
import {SimpleEntity} from "../../../../models/simple-entity.model";

@Component({
  selector: 'app-offer-request',
  templateUrl: './offer-request.component.html',
  styleUrls: ['./offer-request.component.scss']
})

export class OfferRequestComponent implements OnInit {

  isLoading = false;
  isChecked = true;
  @Input() equipments: any = [];
  @Input() activityId: number;
  @Input() changeBuyPrice: boolean;
  @Input() etd: string;
  @Input() pol: string;
  @Input() pod: string;
  @ViewChild('fubauto') uploader: any;
  allocation: Allocation[];
  projects: any[] = null;
  activityOffer: any;
  offerRequestForm: UntypedFormGroup;
  reservationForm: UntypedFormGroup;
  transportTypes: string[] =  Object.values(TransportTypes);
  incoterms: string[] = Object.values(Incoterms)
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];
  diffTariffDetMobiles: UntypedFormArray[] = [];
  totalLocalAmount = 0;

  visibleAllocationDialog: boolean = false;
  selectedActivity: any = null;

  validatedRezervationFields = {
    'consigneId': 'Alıcı',
    'senderId': 'Gönderici',
    'operationRepresentativeId': 'Operasyon Temsilcisi'
  }

  revisionDescription: String;
  showRevisionDescDialog: boolean = false;

  reportList: any = [];
  digitalStore: any = [];

  displayBasic = false;
  displayMail = false;
  searchRouteParam: any;
  context = '';
  attachment: any;
  reportId: number;
  file: any;
  mailTo: string[] = [];
  mailFrom = '';
  cc: string[] = [];
  bcc: string[] = [];
  subject: string;
  companyEmails: string[] = [];
  tariffDetAmounts: any[] = [];
  initialSeaAllocationEditDTOs: any[] = [];

  filteredUsers: any[] = [];

  filteredGoodDefinationType:GoodDefinationMinimized[] = [];
  filteredGoodDefinationType2:GoodDefinationMinimized[] = [];

  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  district: DistrictMinimizedModel[] = [];

  filteredCityMinimized: CityMinimizedModel[] = [];
  city: CityMinimizedModel[] = [];

  filteredCountryMinimized: CountryMinimizedModel[] = [];
  country: CountryMinimizedModel[] = [];

  operationRepresentativeList: any[];
  operationRepresentative: any[];

  companies: Company[] = [];
  filteredCompanies: Company[] = [];

  containers: ContainerModel[] = [];
  filteredContainers: ContainerModel[] = [];

  currencyType: any[];
  currency: any[]

  lastFilteredGoods: any [] = [];
  childGoods: any[] = [];

  editContainer: any[] = [];
  editContainer2: any[] = [];
  editContainerType: any;
  editContainerType2: any;
  firstEquimenetsLenght: number;
  businessType: any[] = [{type: 'B', name: 'Bizim'}, {type: 'A', name: 'Acenta'}, {type: 'O', name: 'Ortak'}];
  business: any[] = [{type: 'B', name: 'Bizim'}, {type: 'A', name: 'Acenta'}, {type: 'O', name: 'Ortak'}];
  invoiceTypes: any[] = [{value: 'CN', label: 'Alacak Dekontu'}, {value: 'DN', label: 'Borç Dekontu'}, {value: 'IN', label: 'Fatura'}, {value: 'RI', label: 'İade Fatura'}, {value: 'IR', label: 'İç Dekont'}, {value: 'OC', label: 'Operasyon Gider'}];

    showPreviousBtn = false;
    listResultItem: any = null;
    showDetailDialog: boolean = false;
    displaySeaGoodDetail: boolean = false;
    reservationDetail: any[] = [];

    stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'reservation', value: 'reservation', disabled: true}
    ];

  selectedOfferSeaDetailId: any;
  shipOwnersAndOfferDetails: any[] = [];

  showReservationConfirmDialog: boolean = false;

  freightPaymentType: any[] = ['CC', 'PP'];
  salesChannel: any[] = [{code: 'ST', name: 'Sales Team'}, {code: 'BD', name: 'BusinessDevelopment'}, {code: 'TM', name: 'Telemarketing'}, {code: 'DM', name: 'Digital Marketing'}, {code: 'NVO', name: 'NVOCC'}];

  activeTab: String = 'offerRequest';
  // Fiyat butonlarının hem tek click hemde veya cift click olma durumunu kontrol etmek için kullanılıyor.
  clickCount = 0;
  seaGood: any;

  // Response ile gelen fiyat bilgileri
  seaAllocationEditDTOs: any[] = [];

  // Test'de alt tip tanımları bu şekilde..
  rejectDescription: String;
  rejectSubType = null;

  isEnableOperationRepFilter: Boolean = false;

  // Fiyat listesinde iptal veya onay için son seçilen fiyat bilgisi
  selectedOfferPrice: SeaAllocationEditDTOs[] = [];

  // Aktivite'nin son durumu
  SubTypeText = {'pendingConfirm': 'Fiyatlandırma Yapıldı.', 'confirmed': 'Fiyat Onaylandı', 'inProgressCompleted': 'Fiyatlandırma Yapıldı', 'accepted': 'Kabul Edildi', 'rejected': 'Red Edildi', 'revisionRequest': 'Revizyon Yapıldı', 'pricing': 'Fiyatlandırma Bekleniyor'}

  // Allokasyon konteyner fiyatı karışlaştırmak için kullanılıyor.
  seaContaienrPrices = [];

  selectedFrontCarryTariff: any[] = [];
  selectedEndCarryTariff: any[] = [];

  frontTariffDetMobile: any = [];
  frontTariffDetMobileChosen: any = [];

  endTariffDetMobile: any = [];
  endTariffDetMobileChosen: any = [];
  displayFrontEndCarryList = false;

  seaFreightTariffDetMobiles(): UntypedFormArray {
    return this.offerRequestForm.get("seaFreightTariffDetMobiles") as UntypedFormArray;
  }

  tariffseaContainerRezervations(tariff: any) : UntypedFormArray {
    return tariff.get("seaContainerRezervations") as UntypedFormArray;
  }

  get _seaFreightTariffDetMobiles(): UntypedFormGroup {
    return this.fb.group({
      id: [null, []],
      vessel: ['', []],
      cutOffDate: [null, []],
      seaAllocationId: [null, []],
      amount: [null, []],
      buyAmount: [null, []],
      shipOwner: this.createSimpleFormGroup(),
      fromPort: this.createSimpleFormGroup(),
      toPort: this.createSimpleFormGroup(),
      currency: this.createSimpleFormGroup(),
      freeTime: [null, []],
      seaContainerRezervations: this.fb.array([this._seaContainerRezervations])
    });
  }

  get _seaContainerRezervations(): UntypedFormGroup {
    return this.fb.group({
      id: [null, []],
      fareAmount: [null, []],
      minAmount: [null, []],
      buyFareAmount: [null, []],
      tonnage: [{value:null, disabled: true}, []],
      totalAmount: [null, []],
      seaContainerType: this.createSimpleFormGroup({value:null, disabled: true}),
      seaTariffDetMobiles: this.fb.array([this._seaTariffDetMobiles]),
      currency: this.createSimpleFormGroup(),
      isSelected: [false, []]
    });
  }

  get _seaTariffDetMobiles(): UntypedFormGroup {
    return this.fb.group({
      amount: [null, []],
      minAmount: [null, []],
      buyAmount: [null, []],
      calculateType: [null, []],
      ctype: [null, []],
      currency: this.createSimpleFormGroup({value:null, disabled: false}),
      id: [null, []],
      invoiceItemType: this.createSimpleFormGroup({value:null, disabled: false}),
      invoiceType: [null, []],
      isFreightCharge: [null, []],
      itemCompany: [null, []]
    })
  }

  newSeaFrightRequestDetMobiles(seaContainerType: any): any {
    return {
      id: null,
      name: null,
      vessel: null,
      cutOffDate: null,
      amount: null,
      buyAmount: null,
      customer: this.offerRequestForm.value?.customer,
      shipOwner: this.offerRequestForm.value?.shipOwner,
      fromPort: this.offerRequestForm.value?.fromSeaPort,
      toPort: this.offerRequestForm.value?.toSeaPort,
      currency: null,
      seaContainerRezervations: []
    };
  }

  seaFrightRequestDetMobiles: SeaFrightRequestDetMobiles[];

  selectedContainerTypes = [];
    showStatusHistory = false;
    offerStatusHistory: any;

  createSimpleFormGroup(value = {value: null, disabled: false}): UntypedFormGroup {
    return this.fb.group({
      id: [null, []],
      name: ['', []]
    })
  }

  constructor(private datePipe: DatePipe,  private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private dahsboardService: DashboardService,
    private companyService: CompanyService,
    private offerExportService: OfferExportService,
    private containerService: ContainerService,
    private activityService: ActivityService,
    private messageService: MessageService,
    private personService: PersonService,
    private confirmService: ConfirmationService,
    private allocationService: AllocationService,
    private dialog: MatDialog,
    private userService: UserService,
    private listService: ListService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    ) {
    //OfferRequest form initialize
    this.offerRequestForm = this.fb.group({
      id: [null, []],
      customer: [null, [Validators.required]],
      etd: [new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), []],
      validTo: [new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), []],
      endDate: [new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), []],
      startDate:[this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'), []],
      delivery: ['PTP', [Validators.required]],
      incoterm: ['FOB', [Validators.required]],
      fromSeaPort: [null, [Validators.required]],
      toSeaPort: [null, [Validators.required]],
      salesRepresentative: [null, []],
      loading: ['', []],
      notes: [null, []],
      customerNote: [null, []],
      goodDefination: ['', []],
      goodDefinationType: ['', []],
      transportDirection: ['I', []],
      subType:[null, []],
      fak: [false, []],
      equipments: this.fb.array([
        this.fb.group({
          seaContainerType: [null, []],
          id: [null, []],
          containerId: [null, []],
          count: [1, Validators.required],
          tonnage: [0, Validators.required]
        })
      ]),
      seaAllocations: [[], []],
      shipOwner: [null, []],
      operationType: ['SEA', Validators.required],
      seaFreightTariffDetMobiles: this.fb.array([this._seaFreightTariffDetMobiles]),
      seaFrightRequestDetMobiles: this.seaFrightRequestDetMobiles,
      loadCountry: [null, []],
      loadCity: [null, []],
      loadDistrict: [null, []],
      loadZip: [null, []],
      loadAddress: [null, []],
      unloadCountry: [null, []],
      unloadCity: [null, []],
      unloadDistrict: [null, []],
      unloadZip: [null, []],
      unloadAddress: [null, []],
      freeTime: [null, []],
      avgEmptyReturn: [null, []],
      isPricing:[true,[]],
      frontBuyAmount:[null, []],
      endBuyAmount:[null, []]
    })

    this.reservationForm = this.fb.group({
      senderId: [this.offerRequestForm?.get(['customer'])?.value, [Validators.required]],
      consigneId: ['', [Validators.required]],
      abroadAgentId: ['', []],
      operationRepresentativeId: ['', [Validators.required]],
      whoseBusiness: ['', [Validators.required]],
      operationNote: ['', []],
      customerRef: ['', []],
      masterOnly: ['', []],
      freightPayment: ['', []],
      salesChannel: ['', [Validators.required]],
      equipments: this.fb.array([])
    });

    this.seaFrightRequestDetMobiles =  [this.newSeaFrightRequestDetMobiles(null)];

    console.log(this.seaFrightRequestDetMobiles, "seaFrightRequestDetMobiles")

      if (this.router?.getCurrentNavigation()?.extras?.state?.seaGood) {
          this.seaGood = this.router.getCurrentNavigation()?.extras?.state?.seaGood;

          const promiseArray = [];
          promiseArray.push(this.companyService.getAll(this.seaGood.customer).toPromise());
          promiseArray.push(this.companyService.getAll(this.seaGood.shipOwner).toPromise());
          promiseArray.push(this.offerExportService.getOfferExportSeaport(this.seaGood.fromPort).toPromise());
          promiseArray.push(this.offerExportService.getOfferExportSeaport(this.seaGood.toPort).toPromise());
          promiseArray.push(this.offerExportService.getAllCurrency('I').toPromise());
          promiseArray.push(this.containerService.getAll().toPromise());
          Promise.all(promiseArray).then(response => {
              console.log(response)
              this.offerRequestForm.patchValue({
                  customer: response[0][0],
                  shipOwner: response[1][0],
                  fromSeaPort: response[2].body[0],
                  toSeaPort: response[3].body[0],
                  incoterm: this.seaGood.incoterm,
                  goodDefination: this.seaGood.goodDefination,
              });
              const containerArray = this.seaGood.containerInfo.split(',');
              for (let i = 0; i < containerArray.length; i++) {
                  const goodContainer = response[5].find(ct => ct.name === containerArray[i].split(':')[0].trimStart().trimEnd());
                  if (i === 0) {
                      (this.offerRequestForm.get('equipments') as UntypedFormArray).controls[0].get('containerId').setValue(goodContainer.id);
                      (this.offerRequestForm.get('equipments') as UntypedFormArray).controls[0].get('seaContainerType').setValue(goodContainer);
                  } else {
                      const equipment = {seaContainerType: goodContainer, count: 1, containerId: goodContainer.id, id: null, tonnage: 0};
                      this.addEquipment(equipment);
                  }
                  this.onChangeContainer(goodContainer);
              }
          });
      }

    // Allocation listesinden bir allocation seçildiyse.
    if(String(this.route.snapshot.params.allocation) == "allocation") {
      if(this.router.getCurrentNavigation()?.extras?.state?.allocations) {
        this.allocation = this.router.getCurrentNavigation()?.extras?.state.allocations as Allocation[];
        // Aramadan gelen POL POD ve Lineer varsayılan olarak bind edilir.
        const search = this.router.getCurrentNavigation()?.extras?.state.search;
        this.offerRequestForm.patchValue({'fromSeaPort': search?.pol || null, 'toSeaPort': search?.pod || null, 'shipOwner': this.allocation[0].lineAgent});
        if(this.allocation) {
          this.allocation.forEach(a => {
            (this.offerRequestForm.get('seaFreightTariffDetMobiles') as UntypedFormArray).removeAt(0);


            // Allocation listesinden seçilen allocation POD ve POL'e göre seaFreightTariffDetMobiles filtrelediği için yeniden get ediyoruz.
            this.allocationService.getSeaAllocation(a.id, search.tonnage).toPromise().then(allocation_ => {
              allocation_.seaFreightTariffDetMobiles.forEach(t => {
                t.seaAllocationId = allocation_.id;
                this.addTariff(t as seaFreightTariffDetMobiles)
              });
              this.offerRequestForm.patchValue({'seaAllocations': this.offerRequestForm.value.seaAllocations.concat(allocation_)});
            })
          })
        }
      }
    }
    if(this.router?.getCurrentNavigation()?.extras?.state?.projects) {

      (this.offerRequestForm.get('seaFreightTariffDetMobiles') as UntypedFormArray).removeAt(0);
        (this.offerRequestForm.get('equipments') as UntypedFormArray).removeAt(0);
      this.projects = this.router?.getCurrentNavigation()?.extras?.state?.projects;
      this.projects.forEach(p => {

        p.project.seaContainerRezervations = p.project.seaContainerRezervations.filter(f => f.selected);
        p.project.seaContainerRezervations.forEach(f => {
          const equipment = {
            seaContainerType: f.seaContainerType,
            count: 1,
            containerId: f.seaContainerType.id,
            id: null,
            tonnage: f.tonnage
          }
          this.addEquipment(equipment)
          this.selectedContainerTypes.push(f.seaContainerType);
        })
      })

      this.offerRequestForm.patchValue({'fromSeaPort': this.projects[0].project.fromPort})
      this.offerRequestForm.patchValue({'toSeaPort': this.projects[0].project.toPort})



      this.projects.map(p => p.project).forEach(s => {
        const tariff_ = this._seaFreightTariffDetMobiles;
        (tariff_.get('seaContainerRezervations') as UntypedFormArray).removeAt(0);
        if(s.seaContainerRezervations) {
          s.seaContainerRezervations.forEach(q => {
            const seaContainerRezervation = this._seaContainerRezervations;
            (seaContainerRezervation.get('seaTariffDetMobiles') as UntypedFormArray).removeAt(0);
            seaContainerRezervation.patchValue(q);
            (tariff_.get('seaContainerRezervations') as UntypedFormArray).push(seaContainerRezervation);
          })
        }
        (tariff_.get('freeTime') as UntypedFormGroup).patchValue(s.freeTime);
        (tariff_.get('currency') as UntypedFormGroup).patchValue(s.currency);
        (tariff_.get('vessel') as UntypedFormGroup).patchValue(s.vessel);
        (tariff_.get('amount') as UntypedFormGroup).patchValue(s.amount);
        (tariff_.get('buyAmount') as UntypedFormGroup).patchValue(s.buyAmount);
        (tariff_.get('shipOwner') as UntypedFormGroup).patchValue(s.shipOwner);
        (tariff_.get('fromPort') as UntypedFormGroup).patchValue(s.fromPort);
        (tariff_.get('toPort') as UntypedFormGroup).patchValue(s.toPort);


        (this.offerRequestForm.get('seaFreightTariffDetMobiles') as UntypedFormArray).push(tariff_);

        this.totalTariffDetAmount()
      });

    }

      this.searchRouteParam = this.router.getCurrentNavigation()?.extras?.state?.search;
    // Offer listesinden bir offer seçildiyse.

    const activity = this.router?.getCurrentNavigation()?.extras?.state?.activity || null
    if(activity) {

      this.seaAllocationEditDTOs = activity.activitySeaFullImportEditDTO.seaAllocationEditDTOs;
      this.activityOffer = activity;
      this.offerRequestForm.patchValue(activity);

      // equipment formArray'yi response ile gelen arasında fark olduğu için veri uygun hale getiriliyor.
      (this.offerRequestForm.get('equipments') as UntypedFormArray).removeAt(0);
      this.activityOffer.equipments.forEach(q => {
        const equipment = {
          seaContainerType: { id: q.id,name: q.name},
          count: q.count,
          tonnage: q.tonnage
        }
        this.addEquipment(equipment)
      })
    }

    this.offerRequestForm.patchValue({toSeaPort:this.router.getCurrentNavigation()?.extras?.state?.pod ? this.router.getCurrentNavigation()?.extras?.state?.pod : null});
    this.offerRequestForm.patchValue({fromSeaPort:this.router.getCurrentNavigation()?.extras?.state?.pol ? this.router.getCurrentNavigation()?.extras?.state?.pol : null});
    this.offerRequestForm.patchValue({endDate:this.router.getCurrentNavigation()?.extras?.state?.etd ? this.router.getCurrentNavigation()?.extras?.state?.etd : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)});
  }

  getIdContainer(name) {
      console.log('filtered' , name)
     return  this.filteredContainers.find(c => c.name === name).id;
  }

    prepareReservation() {
        (this.reservationForm.get('equipments') as UntypedFormArray).clear();
        this.activityOffer.equipments.forEach(q => {
            const equipment = {
                seaContainerType: {id: this.getIdContainer(q.name), name: q.name},
                count: q.count,
                containerId: q.containerId,
                id: q.id,
                tonnage: q.tonnage
            };
            (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(equipment));
        });
    }

  async ngOnInit() {
      if(this.route.snapshot.params.activityId || this.activityId) {
          this.isEnableOperationRepFilter = await this.userService.function("USER_ENABLE_OPERATIONREP_FILTER").toPromise();
          this.activityService.getMyActivityById(this.route.snapshot.params.activityId  || this.activityId, 'I').subscribe(activity => {

              this.containerService.getAll().subscribe(res => {
                  this.containers = res;
                  this.filteredContainers = res;

                  activity.endDate = new Date(activity.endDate);
                  activity.etd = new Date(activity.etd)
                  activity.validTo = new Date(activity.validTo);


                  this.activityOffer = activity;
                  this.rejectSubType = activity.offerSubStatusCode
                  this.equipments = activity.seaAllocationEditDTOs;

                  this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs.forEach(v => v.activityOperationDetailEditDTOs.forEach(s => s.unitAmount = s.amount / s.count));

                  console.log(this.activityOffer)
                  this.offerRequestForm.patchValue({salesRepresentative: this.activityOffer.salesRepresentative})

                  if(this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs) {
                      this.activityOffer.activitySeaFullImportEditDTO.seaAllocationEditDTOs.forEach(f => {
                          if(f.offerSeaChargesDTOs) {
                              f.offerSeaChargesDTOs.forEach(s => s["min"] = s.amount);
                          }
                      })
                  }

                  this.allocation = activity['seaAllocations'];
                  this.seaAllocationEditDTOs = activity['activitySeaFullImportEditDTO']?.seaAllocationEditDTOs;

                  this.equipments=[];
                  console.log(this.seaAllocationEditDTOs)
                  this.seaAllocationEditDTOs.forEach(product =>{
                    this.equipments.push({
                      project: {
                          currency: product.totalCurrency,
                          cutOffDate: product.etd,
                          fromPort: product.fromPort,
                          toPort: product.toPort,
                          seaContainerRezervations: product.activityOperationDetailEditDTOs,
                          shipOwner: product.shipOwner,
                          vessel: product.vessel
                      }
                    });
                    console.log(this.equipments)
                  });

                  // Min Prices
                  if(this.seaAllocationEditDTOs && this.seaAllocationEditDTOs.length > 0) {
                      this.seaAllocationEditDTOs.forEach(f => {
                          this.reservationDetail = this.reservationDetail.concat(f.seaGoods);
                          if(f.activityOperationDetailEditDTOs && f.activityOperationDetailEditDTOs.length > 0) {
                              f.activityOperationDetailEditDTOs.forEach(i =>  i["minAmount"] = Number(i['amount']))
                          }
                      })
                  }
                  // Teklif durumuna göre rezervasyon tabını aktif ediyoruz.
                  let offerStatus = null;
                  if(activity['activitySeaFullImportEditDTO']) {
                      offerStatus = activity['activitySeaFullImportEditDTO']['activityOperationEditDTO']['offerStatus'];
                  }

                  if(this.activityOffer.frontEndTariffDetDTOs.length > 0){
                    this.offerRequestForm.patchValue({frontBuyAmount: this.activityOffer.frontEndTariffDetDTOs.find(tariff => tariff.frontEnd === 'FRONT')?.buyAmount})
                    this.offerRequestForm.patchValue({endBuyAmount: this.activityOffer.frontEndTariffDetDTOs.find(tariff => tariff.frontEnd === 'END')?.buyAmount})
                  }

                  if( ['confirmed', 'accepted'].includes(offerStatus && offerStatus)) this.activeRezervationButton();
                  this.reservationForm.patchValue({
                      senderId: this.reservationDetail[this.reservationDetail?.length - 1]?.senderId,
                      consigneId: this.reservationDetail[this.reservationDetail?.length - 1]?.consigneId,
                      operationNote: this.reservationDetail[this.reservationDetail?.length - 1]?.operationNote,
                      operationRepresentativeId: this.reservationDetail[this.reservationDetail?.length - 1]?.operationRepresentativeId,
                      masterOnly: this.reservationDetail[this.reservationDetail?.length - 1]?.masterOnly,
                      salesChannel: this.reservationDetail[this.reservationDetail?.length - 1]?.salesChannel,
                      freightPayment: this.reservationDetail[this.reservationDetail?.length - 1]?.freightPayment,
                      whoseBusiness: this.business.find(b => b.type === this.reservationDetail[this.reservationDetail?.length - 1]?.whoseBusiness)?.type,
                      abroadAgentId: this.reservationDetail[this.reservationDetail?.length - 1]?.abroadAgentId,
                      customerRef: this.reservationDetail[this.reservationDetail?.length - 1]?.customerRef,
                  });

                  if (this.reservationDetail.length === 0) {
                      this.prepareReservation();
                  }
                  this.reservationDetail[this.reservationDetail?.length - 1]?.seaContainerDetails?.forEach(eq => {
                      (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(this.lastReservationContainers(eq)));
                  });

                  this.findConfirmedOffersAndPrepareShipOwner();


                  this.offerRequestForm.patchValue(this.activityOffer);
                  (this.offerRequestForm.get('equipments') as UntypedFormArray).removeAt(0);
                  this.activityOffer.equipments.forEach(q => {
                      const equipment = {
                          seaContainerType: { id: q.id,name: q.name},
                          count: q.count,
                          containerId: q.containerId,
                          id: q.id,
                          tonnage: q.tonnage
                      }
                      this.addEquipment(equipment)
                  })

                  // Fiyat tablosunu renderlamak için
                  this.onChangeContainer();

                  if(this.allocation) {
                      this.allocation.forEach(a => {
                          (this.offerRequestForm.get('seaFreightTariffDetMobiles') as UntypedFormArray).removeAt(0);

                          // Allocation listesinden seçilen allocation POD ve POL'e göre seaFreightTariffDetMobiles filtrelediği için yeniden get ediyoruz.
                          this.allocationService.getSeaAllocation(a.id, this.searchRouteParam?.tonnage).toPromise().then(allocation_ => {

                              allocation_.seaFreightTariffDetMobiles.forEach(t => {

                                  t.seaAllocationId = allocation_.id;
                                  this.addTariff(t as seaFreightTariffDetMobiles)
                              });
                              this.offerRequestForm.patchValue({'seaAllocations': [].concat(allocation_)});
                          })
                      })
                  }

                  if(this.seaAllocationEditDTOs && this.seaAllocationEditDTOs.length > 0) {
                    this.offerRequestForm.patchValue({
                      shipOwner: this.seaAllocationEditDTOs[0].shipOwner,
                    });
                  }


              })
          })
      }
      if (!this.route.snapshot.params.activityId && !this.activityId){
          this.containerService.getAll().subscribe(res => {
              this.containers = res;
              this.filteredContainers = res;
          })
          const user = this.userService.getUserByTransportDirection('I');
          this.offerRequestForm.patchValue({salesRepresentative: user})

          this.pod ? this.offerRequestForm.patchValue({toSeaPort:this.pod}) : null;
          this.pol ? this.offerRequestForm.patchValue({fromSeaPort:this.pol}) : null;
          this.etd ? this.offerRequestForm.patchValue({endDate:this.etd}) : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      }

        await this.createNewGoodDefinationList()

    this.checkValidation();
  }

  createNewGoodDefinationList(){
    this.offerExportService.filterGoodDefinationType().subscribe(res=>{
            this.filteredGoodDefinationType = res.body
            let topGoodType = this.filteredGoodDefinationType.filter(good => good.topGoodType == null)
            topGoodType.forEach(res =>{
                this.filteredGoodDefinationType.forEach(good=>{
                    if(good.topGoodType !=null && (good.topGoodType.id == res.id)){
                        this.childGoods.push(good);
                    }
                })
            })
            this.childGoods.forEach(childGood => {
                this.filteredGoodDefinationType.forEach(good => {
                    if(good.topGoodType != null && (childGood.id == good.topGoodType.id)){
                        this.lastFilteredGoods.push(good)
                    }
                })
            })
            this.filteredGoodDefinationType = this.lastFilteredGoods
            this.filteredGoodDefinationType2 = this.lastFilteredGoods
        })
  }

  findConfirmedOffersAndPrepareShipOwner(){
      const confirmedOffers = this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs.filter(offer => offer?.offerStatus === 'confirmed');
      if (confirmedOffers.length > 1) {
          this.shipOwnersAndOfferDetails = confirmedOffers?.map(offer => {
              return {shipOwner: offer.shipOwner, offerSeaDetailId: offer.offerSeaDetailId};
          });
          this.selectedOfferSeaDetailId = null;
      }else {
          this.shipOwnersAndOfferDetails = [];
      }
  }

  manupulationActivityObject(): Activity {
    let payloadObj =  this.offerRequestForm.value;

    payloadObj.endDate = this.datePipe.transform(payloadObj.endDate, 'yyyy-MM-dd HH:mm:ss');
    payloadObj.etd = this.datePipe.transform(payloadObj.etd, 'yyyy-MM-dd HH:mm:ss');
    payloadObj.validTo = this.datePipe.transform(payloadObj.validTo, 'yyyy-MM-dd HH:mm:ss');
    payloadObj.isPricing = this.changeBuyPrice ? !this.changeBuyPrice : true


    if(!payloadObj.shipOwner || payloadObj.shipOwner == '') payloadObj.shipOwner = null;

    if(payloadObj.seaAllocations) {
      /*
      reactiveForm da iki 'seaFreightTariffDetMobiles' bulunuyor. biri root objesi diğeride seaAllocations içinde
      Konteyner seçimi ve fiyat güncellemesi için seaFreightTariffDetMobiles daki bilgi kullanılıyor. Ancak backend'de  esas olan
      SeaAllocation içindeki seaFreightTariffDetMobiles olduğundan esas alınan bilgi güncelleniyor.
      Seçili konteynerlar aşağıda güncellenmekdir. Fiyat bilgisi ise onChangeContainerFareAmount içinde güncelleniyor.
      */
      payloadObj.seaAllocations.forEach(a => {

        const tariffDets = payloadObj.seaFreightTariffDetMobiles.filter(f => f.seaAllocationId == a.id);
        if(tariffDets) {
          tariffDets.forEach(t => {
            const selectedContainerRezervationId = t.seaContainerRezervations.filter(c => c.isSelected).map(q => q.id);

            const tariff = a.seaFreightTariffDetMobiles.find(f => f.id == t.id);

            if(selectedContainerRezervationId.length > 0) {
              // tariff deki sadece seçili konteynerları filtreler.
             tariff.seaContainerRezervations = tariff.seaContainerRezervations.filter(g => selectedContainerRezervationId.includes(g.id));
            } else {
              // Konteyner seçilmemiş tariff'i payload dan kaldırır.
              a.seaFreightTariffDetMobiles = a.seaFreightTariffDetMobiles.filter(w => w.id != tariff.id);
            }

          });
        }
      });
    }


    if(!this.projects)  delete payloadObj.seaFreightTariffDetMobiles;


    //equipments seaContainerType payload manipulation
    payloadObj.equipments.forEach(f => {
        let equipment: Equimenet = {}
        equipment.id = null;
        equipment.containerId = f.seaContainerType?.id;
        equipment.name = f?.seaContainerType?.name;
        equipment.count = f.count;
        equipment.tonnage = f.tonnage;
        Object.assign(f, equipment);
      })
      return payloadObj as Activity;
    }


  manupulationActivityObjectForUpdate(): Activity {
    let payloadObj =  this.offerRequestForm.value;

    if(payloadObj.seaAllocations) {
      /*
      reactiveForm da iki 'seaFreightTariffDetMobiles' bulunuyor. biri root objesi diğeride seaAllocations içinde
      Konteyner seçimi ve fiyat güncellemesi için seaFreightTariffDetMobiles daki bilgi kullanılıyor. Ancak backend'de  esas olan
      SeaAllocation içindeki seaFreightTariffDetMobiles olduğundan esas alınan bilgi güncelleniyor.
      Seçili konteynerlar aşağıda güncellenmekdir. Fiyat bilgisi ise onChangeContainerFareAmount içinde güncelleniyor.
      */
      payloadObj.seaAllocations.forEach(a => {

        const tariffDets = payloadObj.seaFreightTariffDetMobiles.filter(f => f.seaAllocationId == a.id);
        if(tariffDets) {
          tariffDets.forEach(t => {
            const selectedContainerRezervationId = t.seaContainerRezervations.filter(c => c.isSelected).map(q => q.id);

            const tariff = a.seaFreightTariffDetMobiles.find(f => f.id == t.id);

            if(selectedContainerRezervationId.length > 0) {
              // tariff deki sadece seçili konteynerları filtreler.
             tariff.seaContainerRezervations = tariff.seaContainerRezervations.filter(g => selectedContainerRezervationId.includes(g.id));
            } else {
              // Konteyner seçilmemiş tariff'i payload dan kaldırır.
              a.seaFreightTariffDetMobiles = a.seaFreightTariffDetMobiles.filter(w => w.id != tariff.id);
            }

          });
        }
      });
    }



    //equipments seaContainerType payload manipulation
    payloadObj.equipments.forEach(f => {
          let equipment: Equimenet = {}
          equipment.id = null;
          equipment.containerId = f.seaContainerType.id;
          equipment.name = f.seaContainerType.name;
          equipment.count = f.count;
          equipment.tonnage = f.tonnage;
          Object.assign(f, equipment)
          delete f['seaContainerType']
    })
    return payloadObj as Activity;
  }


    async createActivity() {
      if(!this.goodDefinationValidation()) return;
    if(this.seaFrightRequestDetMobiles && this.seaFrightRequestDetMobiles.length > 0 && this.seaFrightRequestDetMobiles[0].seaContainerRezervations.length > 0  ) {
        this.seaFrightRequestDetMobiles.forEach(data => {
            if(!data.currency){
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Lütfen para birimi seçin.' });
                return;
            }
        })
    }
    if (this.checkQuantitiyValidation()) return;
    const payloadObj = this.manupulationActivityObject();
    this.isLoading = true;

    if(this.seaFrightRequestDetMobiles) {

      this.seaFrightRequestDetMobiles.forEach(f => {
        f.customer = this.offerRequestForm.value?.customer;
        // f.shipOwner = this.offerRequestForm.value?.shipOwner;
        // f.fromPort = this.offerRequestForm.value?.fromSeaPort;
        // f.toPort = this.offerRequestForm.value?.toSeaPort;
          f.seaContainerRezervations = this.seaFrightRequestDetMobiles[0].seaContainerRezervations;
      });
      payloadObj['seaFrightRequestDetMobiles'] = this.seaFrightRequestDetMobiles;
    }
    console.log(payloadObj, "payload");


    this.activityService.createActivity(payloadObj as Activity).subscribe(async res => {
      this.activityOffer = res.body;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Oluşturuldu' });
        if (this.seaGood) {
            await this.setSeaGoodToOffer(this.seaGood.id, this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs[0]?.offerSeaDetailId);
        } else {
            this.router.navigate([`activities/offer-request/activity/${this.activityOffer.id}`]);
        }
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Teklif Oluşturulamadı' });
    })
  }

    async setSeaGoodToOffer(seaGoodId: any, offerId) {
        this.confirmService.confirm({
            header: 'İşlem Onayı',
            message: 'Teklif oluştu, yük teklife bağlansın mı?',
            accept: async () => {
                this.isLoading = true;
                await this.dahsboardService.setOfferSeaDetailToSeaGoodWithId(seaGoodId, offerId, 'I').toPromise().then(
                    res => { // Success
                        this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kayıt başarılı." });
                        this.router.navigate([`activities/offer-request/activity/${this.activityOffer.id}`]);
                    },
                    msg => { // Error
                        this.messageService.add({key: 'tst', severity: 'warning', summary: 'Hata', detail: "Yük teklife bağlanamadı" });
                        this.router.navigate([`activities/offer-request/activity/${this.activityOffer.id}`]);
                    }
                );
                this.isLoading = false;
            }
        });
    }

  updateActivity() {
    if(this.offerRequestForm.get('goodDefination').value == ''){
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      return;
    }
    if(this.activityOffer?.goodDefinationType && this.offerRequestForm.get('goodDefinationType').value == null){
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      return;
    }
    if((this.offerRequestForm.get('incoterm').value === 'EXW' || this.offerRequestForm.get('incoterm').value === 'DDP' || this.offerRequestForm.get('incoterm').value === 'DDU'
    || this.offerRequestForm.get('incoterm').value === 'DAP') && this.offerRequestForm.get('delivery').value === 'PTD'){
      if(this.offerRequestForm.get('unloadAddress').value === null){
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Lütfen adres alanını doldurun.' });
      return;
      }
    }
      this.fillCtequipmentsWithContainerForm()
    if (this.checkQuantitiyValidation()) return;
    this.isLoading = true;
    const payloadObj = this.activityOffer;

    payloadObj['activitySeaFullImportEditDTO'] = this.activityOffer.activitySeaFullImportEditDTO;
    // tarih formatlanmazsa backend de hata veriyor.
      try {
          payloadObj.etd = this.datePipe.transform(payloadObj.etd, 'yyyy-MM-dd HH:mm:ss')
          payloadObj.validTo = this.datePipe.transform(payloadObj.validTo, 'yyyy-MM-dd HH:mm:ss')
          payloadObj.startDate = this.datePipe.transform(payloadObj.startDate, 'yyyy-MM-dd HH:mm:ss')
          payloadObj.endDate = this.datePipe.transform(payloadObj.endDate, 'yyyy-MM-dd HH:mm:ss')
      } catch (e){

      }

    Object.assign(payloadObj, {customer: this.offerRequestForm.value.customer});
    Object.assign(payloadObj, {avgEmptyReturn: this.offerRequestForm.value.avgEmptyReturn});
    Object.assign(payloadObj, {incoterm: this.offerRequestForm.value.incoterm});
    Object.assign(payloadObj, {fromSeaPort: this.offerRequestForm.value.fromSeaPort});
    Object.assign(payloadObj, {toSeaPort: this.offerRequestForm.value.toSeaPort});
    Object.assign(payloadObj, {notes: this.offerRequestForm.value.notes});
    Object.assign(payloadObj, {customerNote: this.offerRequestForm.value.customerNote});
    Object.assign(payloadObj, {shipOwner: this.offerRequestForm.value.shipOwner});
    Object.assign(payloadObj, {goodDefination: this.offerRequestForm.value.goodDefination});
    Object.assign(payloadObj, {goodDefinationType: this.offerRequestForm.value.goodDefinationType});
    Object.assign(payloadObj, {delivery: this.offerRequestForm.value.delivery});
    Object.assign(payloadObj, {salesRepresentative: this.offerRequestForm.value.salesRepresentative});
    Object.assign(payloadObj, {etd : this.datePipe.transform(this.offerRequestForm.value.etd, 'yyyy-MM-dd HH:mm:ss')})
    Object.assign(payloadObj, {validTo : this.datePipe.transform(this.offerRequestForm.value.validTo, 'yyyy-MM-dd HH:mm:ss')})

    Object.assign(payloadObj, {loadCountry: this.offerRequestForm.value.loadCountry});
    Object.assign(payloadObj, {loadCity: this.offerRequestForm.value.loadCity});
    Object.assign(payloadObj, {loadDistrict: this.offerRequestForm.value.loadDistrict});
    Object.assign(payloadObj, {loadZip: this.offerRequestForm.value.loadZip});
    Object.assign(payloadObj, {loadAddress: this.offerRequestForm.value.loadAddress});
    Object.assign(payloadObj, {unloadCountry: this.offerRequestForm.value.unloadCountry});
    Object.assign(payloadObj, {unloadCity: this.offerRequestForm.value.unloadCity});
    Object.assign(payloadObj, {unloadDistrict: this.offerRequestForm.value.unloadDistrict});
    Object.assign(payloadObj, {unloadZip: this.offerRequestForm.value.unloadZip});
    Object.assign(payloadObj, {unloadAddress: this.offerRequestForm.value.unloadAddress});

    this.offerExportService.updateActivity(payloadObj).subscribe(res => {
        this.activityOffer.frontEndTariffDetDTOs = res.body.frontEndTariffDetDTOs;
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Güncellendi', detail: 'Teklif güncellendi.' });
        this.isLoading = false;
      }, () => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu!' });
        this.isLoading = false;
      }
    )
  }

  copyActivity() {
    this.confirmService.confirm({
      message: 'İşleme devam etmek istediğinizden emin misiniz?',
      accept: () => {
        this.activityService.copy(this.activityOffer.id).subscribe(r => {
          this.changeOfferStatus('pricing', r);
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Kopyalandı', detail: 'Kopya oluşturuldu.' });
          window.location.href = `/activities/offer-request/activity/${r.id}`;
        }, () => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu!' });
        })
      }
    })


  }

  addEquipment(equipment: any): void {
    (this.offerRequestForm.get('equipments') as UntypedFormArray).push(this.createEquipmentFormControl(equipment));
  }

  deleteEquipment() {
    this.diffTariffDetMobiles = [];
      this.deleteContainer(this.offerRequestForm.value.equipments[this.offerRequestForm.value.equipments.length-1].seaContainerType, null);
      (this.offerRequestForm.get('equipments') as UntypedFormArray).removeAt(this.offerRequestForm.value.equipments.length -1);
      this.onChangeContainer(null);
  }

  onChangeContainer(selectedContainer = null) {
      console.log(selectedContainer)
    this.diffTariffDetMobiles = [];
    this.selectedContainerTypes = [...new Map(this.offerRequestForm.get('equipments').value.map((item) => [item["seaContainerType"].name, item])).values()].map(i => i['seaContainerType']);

    if(this.allocation) {
      this.totalTariffDetAmount();
      return;
    }

    // Teklifsiz fiyatlandırma
    if(this.seaFrightRequestDetMobiles.length == 0) {
      const seaFrightRequestDetMobiles = new SeaFrightRequestDetMobiles();
      seaFrightRequestDetMobiles.customer = this.offerRequestForm.value?.customer;
      seaFrightRequestDetMobiles.shipOwner = this.offerRequestForm.value?.shipOwner;
      seaFrightRequestDetMobiles.fromPort = this.offerRequestForm.value?.fromSeaPort;
      seaFrightRequestDetMobiles.toPort = this.offerRequestForm.value?.toSeaPort;
      this.seaFrightRequestDetMobiles.push(seaFrightRequestDetMobiles);
    } else {
      this.seaFrightRequestDetMobiles[0].customer = this.offerRequestForm.value?.customer;
      this.seaFrightRequestDetMobiles[0].shipOwner = this.offerRequestForm.value?.shipOwner;
      this.seaFrightRequestDetMobiles[0].fromPort = this.offerRequestForm.value?.fromSeaPort;
      this.seaFrightRequestDetMobiles[0].toPort = this.offerRequestForm.value?.toSeaPort;
    }

    if(this.seaFrightRequestDetMobiles && this.seaFrightRequestDetMobiles.length > 0) {
      if(!this.seaFrightRequestDetMobiles[0].seaContainerRezervations.find(r => r.seaContainerType.name == selectedContainer.name)) {
        const seaContainer = new SeaContainerRezervations();
        seaContainer.seaContainerType = selectedContainer;
        seaContainer.fareAmount = 0;
        this.seaFrightRequestDetMobiles[0].seaContainerRezervations.push(seaContainer);
      }

    }
  }

  onChangeContainerFareAmount(tariff: any, seaContainer, fareAmount) {
    // seaAllocation daki fiyat bilgisini güncelliyor.
    // Todo: Aşağıdaki kod parçacığından kurtulmak için offer-request.component.html de containerları direkt seaAllocation'dan listele.
    if(this.offerRequestForm.value.seaAllocations) {
      const alllocations = this.offerRequestForm.value.seaAllocations;
      const seaAllocation = alllocations.find(f => f.id == tariff.seaAllocationId);
      const tariffDet = seaAllocation.seaFreightTariffDetMobiles.find(f => f.id == tariff.id);
      tariffDet.seaContainerRezervations.find(f => f.id == seaContainer.id).fareAmount = fareAmount < seaContainer.minAmount ? seaContainer.minAmount : fareAmount;
    }
  }

  onFocusOutFareAmount(seaContainer) {
    if(seaContainer.value.fareAmount < seaContainer.value.minAmount) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar ${seaContainer.value.minAmount} den daha az olmamalıdır.` });
      setTimeout(() => {seaContainer.patchValue({fareAmount: seaContainer.value.minAmount})}, 500)
      return;
    }
  }
  onFocusOutContainerAmount(seaContainer) {
    console.log(seaContainer, "container")
     if(seaContainer.unitAmount < (seaContainer.minAmount / seaContainer.count)) {
       this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar ${seaContainer.minAmount} den daha az olmamalıdır.` });
       setTimeout(() => {
        seaContainer.unitAmount = (seaContainer.minAmount / seaContainer.count)
        seaContainer.amount = seaContainer.minAmount;
       }, 500)
       return;
     }
   }
  onFocusOutSeaCharges(seaCharges: any) {
    if(seaCharges.amount < seaCharges.min) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar ${seaCharges.min} den daha az olmamalıdır.` });
      setTimeout(() => { seaCharges.amount = seaCharges.min}, 500)
    }

  }
  onChangeContainerCurrency(tariff: any, seaContainer, currency) {
    const alllocations = this.offerRequestForm.value.seaAllocations;
    const seaAllocation = alllocations.find(f => f.id == tariff.seaAllocationId);
    const tariffDet = seaAllocation.seaFreightTariffDetMobiles.find(f => f.id == tariff.id);
    tariffDet.currency = currency;
    tariffDet.seaContainerRezervations.find(f => f.id == seaContainer.id).currency = currency;
  }

  onChangeFreeTime(tariff: any, event) {
    const alllocations = this.offerRequestForm.value.seaAllocations;
    const seaAllocation = alllocations.find(f => f.id == tariff.seaAllocationId);
    const tariffDet = seaAllocation.seaFreightTariffDetMobiles.find(f => f.id == tariff.id);
    tariffDet.freeTime = event.value
  }

  changeTariffDetAmount(tariffDet:any, tariff: any, seaContainer: any, event: any) {

    if(tariffDet.value.amount < tariffDet.value.minAmount) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar ${tariffDet.value.minAmount} den daha az olmamalıdır.` });
      setTimeout(() => {  tariffDet.patchValue({amount: tariffDet.value.minAmount})}, 500)
      return;
    }

    let existTariffDetAmount = this.onFocusTariffDetAmount(tariffDet);

    if(this.offerRequestForm.value.seaAllocations && this.offerRequestForm.value.seaAllocations.length > 0) {
      const alllocations = this.offerRequestForm.value.seaAllocations;
      const seaAllocation = alllocations.find(f => f.id == tariff.value.seaAllocationId);
      const tariffDetAllocation = seaAllocation.seaFreightTariffDetMobiles.find(f => f.id == tariff.value.id);


      const containers = tariffDet.value.ctype == 'perContainer' ? tariffDetAllocation.seaContainerRezervations : tariffDetAllocation.seaContainerRezervations.filter(f => f.id == seaContainer.value.id);

      if(existTariffDetAmount.amount > Number(event.target.value.replaceAll('.', ''))) {
        setTimeout(() => {
          tariffDet.patchValue({'amount': existTariffDetAmount.amount})
          this.totalTariffDetAmount();
        }, 1000);
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Tutar ${existTariffDetAmount.amount} den daha az olmamalıdır.` });
        return;
      }

      if(containers) {
        containers.forEach(f => {
          f.seaTariffDetMobiles.find(s => s.id == tariffDet.value.id).amount = Number(event.target.value.replaceAll('.', ''));
        })
      }

      this.totalTariffDetAmount();

    } else {
      const tariffDetAllocation = this.offerRequestForm.value.seaFreightTariffDetMobiles.find(f => f.id == tariff.value.id);
      const containers = tariffDet.value.ctype == 'perContainer' ? tariffDetAllocation.seaContainerRezervations : tariffDetAllocation.seaContainerRezervations.filter(f => f.id == seaContainer.value.id);

      if(containers) {
        containers.forEach(f => {
          f.seaTariffDetMobiles.find(s => s.id == tariffDet.value.id).amount = Number(event.target.value.replaceAll('.', ''));
        })
      }
      this.totalTariffDetAmount();
    }
  }

  totalTariffDetAmount() {
    // seçili konteynera ait lokal masrafların toplamını geri döndürür.
    let totalAmount = 0;
    const tariffs = this.filterTariffDet(this.seaFreightTariffDetMobiles().controls);
    if(!tariffs || tariffs.length == 0) return;

    if(this.offerRequestForm.value.seaAllocations && this.offerRequestForm.value.seaAllocations.length > 0) {
      tariffs.forEach(tariff => {
        const seaContainers = this.filterSeaContainer(this.tariffseaContainerRezervations(tariff).controls);
        if(seaContainers.length > 0) {
          seaContainers.forEach(container => {
            const tariffDets = container.get('seaTariffDetMobiles').controls;
            if(tariffDets.length > 0) {
              tariffDets.forEach(t => {
                const alllocations = this.offerRequestForm.value.seaAllocations;
                const seaAllocation = alllocations.find(f => f.id == tariff.value.seaAllocationId);
                const tariffDetAllocation = seaAllocation.seaFreightTariffDetMobiles.find(f => f.id == tariff.value.id);
                tariffDetAllocation.seaContainerRezervations
                const containers = tariffDetAllocation.seaContainerRezervations.find(f => f.id == container.value.id);
                const amount = containers.seaTariffDetMobiles.find(s => s.id == t.value.id)?.amount || 0
                const equipment = this.offerRequestForm.get('equipments').value.find(f => f.seaContainerType.id == container.value.seaContainerType.id);
                if(t.value.ctype == 'perContainer') {
                  totalAmount += amount * equipment.count;
                } else {
                  totalAmount += amount * equipment.count
                }
              })
            }
          })
        }
      })
    } else {
      tariffs.forEach(tariff => {
        const seaContainers = this.filterSeaContainer(this.tariffseaContainerRezervations(tariff).controls);
        if(seaContainers.length > 0) {
          seaContainers.forEach(container => {
            const tariffDets = container.get('seaTariffDetMobiles').controls;
            if(tariffDets.length > 0) {
              tariffDets.forEach(t => {
                const amount = t.value.amount || 0;
                const equipment = this.offerRequestForm.get('equipments').value.find(f => f.seaContainerType.id == container.value.seaContainerType.id);
                if(t.value.ctype == 'perContainer') {
                  totalAmount += amount * equipment.count;
                } else {
                  totalAmount += amount * equipment.count
                }
              })
            }
          })
        }
      })
    }

    this.totalLocalAmount = totalAmount;
  }




  totalSeaContainerAmount() {
    // Seçili konteynerların girilen tutarlarını geri döndüdür.
    let totalAmount = 0;
    const tariffDetMobiles = this.filterTariffDet(this.seaFreightTariffDetMobiles().controls);
    if(tariffDetMobiles.length > 0) {
      tariffDetMobiles.forEach(tariff => {

        const seaContainers = this.filterSeaContainer(this.tariffseaContainerRezervations(tariff).controls)
        if(seaContainers.length > 0) {
          seaContainers.forEach(container => {
            const equipments = this.offerRequestForm.value.equipments || [];
            let equipment = equipments.find(f => f.seaContainerType.id == container.value.seaContainerType.id);
            if(container.value.isSelected)  totalAmount += (container.value.fareAmount * (equipment?.count || 1));
          })
        }
      })

    }
    return totalAmount;
  }

  onFocusTariffDetAmount(tariffDet: any) {
    return tariffDet.value;
  }
  createEquipmentFormControl(equipment: any) {
    if (equipment !== null || equipment !== undefined) {
        return this.fb.group({
            id: [equipment?.id, []],
            containerId: [equipment?.containerId || equipment?.seaContainerType.id , []],
            seaContainerType: [equipment?.seaContainerType, []],
            count: [equipment?.count || 1, [Validators.required]],
            tonnage: [equipment?.tonnage, [Validators.required]]
        });
    } else {
        return this.fb.group({
            seaContainerType: [''],
            count: [''],
            tonnage: ['']
        });
    }
  }

  onClickOffer(offer) {

    // todo birden durum seçilecek.
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 1) {
            const existOfferIndex = this.selectedOfferPrice.findIndex(f => f.offerSeaDetailId == offer.offerSeaDetailId);
            if(existOfferIndex > -1) {
              this.selectedOfferPrice[existOfferIndex].offerStatus = 'rejected';
            } else {
              offer.offerStatus = 'rejected';
              this.selectedOfferPrice.push(offer);
            }
        } else if (this.clickCount === 2) {
          const existOfferIndex = this.selectedOfferPrice.findIndex(f => f.offerSeaDetailId == offer.offerSeaDetailId);
          if(existOfferIndex > -1) {
            this.selectedOfferPrice[existOfferIndex].offerStatus = 'confirmed';
          } else {
            offer.offerStatus = offer.offerStatus == 'inProgressCompleted' ? 'confirmed' : 'inProgressCompleted';
            this.selectedOfferPrice.push(offer);
          }
        }
        this.clickCount = 0;
    }, 250)
  }

  hasStatusOffer(status) {
    return this.seaAllocationEditDTOs.findIndex(f => f.offerStatus == status) > -1;
  }

    changeOfferStatus(updateStatus = null, activity = this.activityOffer) {
        this.isLoading = true;
        const payloadUpdateStatus = [];
        activity.activitySeaFullImportEditDTO.seaAllocationEditDTOs.forEach(s => {
            payloadUpdateStatus.push({
                offerSeaDetailId: s.offerSeaDetailId,
                status: updateStatus,
                substatus: s.offerStatus == 'rejected' ? this.rejectSubType : '',
                description: 'Status update',
            });
        });
        this.offerExportService.updateOfferStatus(payloadUpdateStatus, 'I').subscribe(
            data  => {
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        )
    }

  onUpdateStatus(updateStatus = null) {
    if(!this.validation()) return;
    let payloadUpdateStatus = {};
    if(!updateStatus){
      if(this.selectedOfferPrice.length == 0) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata Oluştu', detail: 'Lütfen işlem yapmak istediğiniz fiyat bilgisini seçin.' });
        return;
      }
      const payloadOfferStatus = [];
      this.selectedOfferPrice.forEach(f => {
        payloadUpdateStatus = {
          offerSeaDetailId: f.offerSeaDetailId,
          status: f.offerStatus,
          substatus:  f.offerStatus == 'rejected' ? this.rejectSubType : '',
          description: f.offerStatus == 'rejected' ? this.rejectDescription : '',
        }
        payloadOfferStatus.push(payloadUpdateStatus)
      })

      // Onaylanmış kayıtlarda alt durum tmaxx'de boş görünüyor sor?
      // if(this.selectedOfferPrice.offerStatus == 'rejected') {
      //   Object.assign(payloadUpdateStatus, {substatus: this.rejectSubType, description: this.rejectDescription })
      // }
    }

    // Todo 2 fiyat onaylanıp 1 fiyat red edilme durumunda istek gönderilecek mi uyarımı verilecek bilgi al.

     this.isLoading = true;
     this.activityService.updateOfferStatus(updateStatus || [payloadUpdateStatus as UpdateOfferStatusDTO], 'I').subscribe(
      data  => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Güncellendi', detail: 'Durum bilgisi başarıyla güncellendi.' });

        this.activityService.getMyActivityById(this.route.snapshot.params.activityId || this.activityId, 'I').subscribe(activity => {

          this.activityOffer = activity;
          this.activityOffer.offerStatus = activity.offerStatus;
          this.rejectSubType = activity.offerSubStatusCode;
          // const activity_ = this.activityOffer;
          // activity_.offerStatus = activity.offerStatus;
          // this.activityOffer = activity_;

          // Tab Button durumu güncelle.
           this.activeRezervationButton();
           this.findConfirmedOffersAndPrepareShipOwner();

            if (this.reservationDetail.length === 0 && this.filteredContainers.length > 0) {
               this.prepareReservation();
           }
        });
      },
      error => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Durum bilgisi güncellenmedi.' });
      },
      () => {
        this.isLoading = false;
        if(this.showRevisionDescDialog) this.toggleRevisionDialog();
       }
      )
  }

  addTariff(tariff: seaFreightTariffDetMobiles): void {
    (this.offerRequestForm.get('seaFreightTariffDetMobiles') as UntypedFormArray).push(this.createTariffFormControl(tariff));
  }

  onSelectUnLoadDistrict(event) {
    this.offerRequestForm.patchValue({unloadCity: event.city});
    this.offerRequestForm.patchValue({unloadCountry: event.country});
  }

  onSelectLoadDistrict(event) {
      this.offerRequestForm.patchValue({loadCity: event.city});
      this.offerRequestForm.patchValue({loadCountry: event.country});
  }

  onSelectCity(event, key) {
      this.offerRequestForm.patchValue( { [key]: event.country});
  }

  activeRezervationButton() {
    // Tab Button durumu güncelle.
    this.stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'reservation', value: 'reservation', disabled:  ['confirmed', 'accepted'].includes(this.activityOffer.offerStatus) ? false : true}
    ];
  }
  getSeaContainerTypeIds() {
    const seaContainerTypeIds: number[] = [];
    (this.reservationForm?.get('equipments') as UntypedFormArray).controls.forEach(ct => {
        seaContainerTypeIds.push(ct.value.seaContainerType.id);
    });
    return seaContainerTypeIds;
  }

  getFrontEndTariffDetMobileFront(type: string) {
    this.equipments.forEach(eq => {
      console.log(eq);
        if (this.offerRequestForm?.get(['loadCity'])?.value?.id && eq?.project?.fromPort?.id) {
            this.offerExportService.getFrontEndTariffDetMobileFront(type, this.offerRequestForm?.get(['loadCity'])?.value.id, eq.project.fromPort.id, 'SEA', this.getSeaContainerTypeIds(),
                this.datepipe.transform((this.offerRequestForm?.get(['endDate'])?.value === '' ? Date.now() : this.offerRequestForm?.get(['endDate'])?.value), 'yyyy-MM-dd'),this.offerRequestForm?.get(['loadDistrict'])?.value?.id).subscribe(res => {
                const list: any[] = [];
                res.body.forEach(tff => {
                    const exitstariff = this.frontTariffDetMobileChosen.find(al => al?.id === tff.id);
                    if (!exitstariff) {
                        list.push(tff);
                    }
                });
                this.frontTariffDetMobileChosen = this.frontTariffDetMobileChosen.concat(list);
            });
        }
    });
}


getFrontEndTariffDetMobileEnd(type: string) {
  this.equipments.forEach(eq => {
      if (this.offerRequestForm?.get(['unloadCity'])?.value?.id && eq?.project?.toPort?.id) {
          this.offerExportService.getFrontEndTariffDetMobileEnd(type, this.offerRequestForm?.get(['unloadCity'])?.value?.id, eq.project.toPort.id, 'SEA', this.getSeaContainerTypeIds(),
              this.datepipe.transform((this.offerRequestForm?.get(['endDate'])?.value === '' ? Date.now() : this.offerRequestForm?.get(['endDate'])?.value), 'yyyy-MM-dd'),this.offerRequestForm?.get(['unloadDistrict'])?.value?.id).subscribe(res => {
              const list: any[] = [];
              res.body.forEach(tff => {
                  const exitstariff = this.endTariffDetMobileChosen.find(al => al?.id === tff.id);
                  if (!exitstariff) {
                      list.push(tff);
                  }
              });
              this.endTariffDetMobileChosen = this.endTariffDetMobileChosen.concat(list);
          });
      }
  });
}

  createContainerFormControl(container: any) {
    if (container !== null || container !== undefined) {
        return this.fb.group({
            seaContainerType: [container?.seaContainerType],
            count: [container?.count || 1],
            tonnage: [container?.tonnage],
            overflow: [false],
            length: [container?.length],
            width: [container?.width],
            height: [container?.height],
            setDegree: [container?.setDegree],
            frontTransport: this.fb.group({
                buyAmount: [0],
                amount: [0],
                currency: ['']
            }),
            endTransport: this.fb.group({
                buyAmount: [0],
                amount: [0],
                currency: ['']
            }),
        });
    } else {
        return this.fb.group({
            seaContainerType: [''],
            count: [''],
            tonnage: [''],
            overflow: [false],
            length: [''],
            width: [''],
            height: [''],
            setDegree: [''],
            frontTransport: this.fb.group({
                buyAmount: [0],
                amount: [0],
                currency: ['']
            }),
            endTransport: this.fb.group({
                buyAmount: [0],
                amount: [0],
                currency: ['']
            }),
        });
    }
  }

  addSelectedFrontEndTariff() {
    const frontList =  [];
    const endList =  [];
    this.selectedFrontCarryTariff.forEach(sc => {
        // Basetariff without id, exitsTariff has an id
        let baseTariff = this.frontTariffDetMobile.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0);
        if (baseTariff !== -1) {
            this.frontTariffDetMobile.splice(baseTariff, 1);
        }
        let exitsTariff = this.frontTariffDetMobile.find(fr => fr?.id === sc.id);
        if (!exitsTariff) {
            frontList.push(sc);
        }
        if (this.activityOffer) {
            baseTariff = this.activityOffer?.frontEndTariffDetDTOs.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0 && fr?.frontEnd === 'frontTransport');
            if (baseTariff !== -1) {
                this.activityOffer?.frontEndTariffDetDTOs.splice(baseTariff, 1);
            }
            exitsTariff = this.activityOffer?.frontEndTariffDetDTOs.find(fr => fr?.id === sc.id);
            if (!exitsTariff) {
                let frontTariff = this.activityOffer?.frontEndTariffDetDTOs.filter(item => item.frontEnd === 'FRONT')[0];
                this.offerExportService.getFrontEndTariffDet(sc.id).toPromise().then(data => {
                    data.body.frontEnd = 'FRONT'
                    data.body.offerSea = frontTariff.offerSea
                    data.body.toSeaPort = frontTariff.toSeaPort
                    this.activityOffer.frontEndTariffDetDTOs = this.activityOffer.frontEndTariffDetDTOs.filter(item => {
                        return !(item.seaContainerType?.id === data.body.seaContainerType.id && item.frontEnd === 'FRONT');
                    });

                    this.activityOffer?.frontEndTariffDetDTOs.push(data.body);
                })
            }
        }
    });
    this.selectedEndCarryTariff.forEach(sc => {
        let baseTariff = this.endTariffDetMobile.findIndex(fr => fr?.fromSeaport?.id === sc?.fromSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0 && fr?.frontEnd === 'endTransport');
        if (baseTariff !== -1) {
            this.endTariffDetMobile.splice(baseTariff, 1);
        }
        let exitsTariff = this.endTariffDetMobile.find(fr => fr?.id === sc.id);
        if (!exitsTariff) {
            endList.push(sc);
        }
        if (this.activityOffer) {
            baseTariff = this.activityOffer?.frontEndTariffDetDTOs.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0 && fr?.frontEnd === 'endTransport');
            if (baseTariff !== -1) {
                this.activityOffer?.frontEndTariffDetDTOs.splice(baseTariff, 1);
            }
            exitsTariff = this.activityOffer?.frontEndTariffDetDTOs.find(fr => fr?.id === sc.id);
            if (!exitsTariff) {
                let endTariff = this.activityOffer?.frontEndTariffDetDTOs.filter(item => item.frontEnd === 'END')[0];
                    this.offerExportService.getFrontEndTariffDet(sc.id).toPromise().then(data => {
                        data.body.frontEnd = 'END'
                        data.body.offerSea = endTariff.offerSea
                        data.body.fromSeaPort = endTariff.fromSeaPort
                        this.activityOffer.frontEndTariffDetDTOs = this.activityOffer.frontEndTariffDetDTOs.filter(item => {
                            return !(item.seaContainerType?.id === data.body.seaContainerType.id && item.frontEnd === 'END');
                        });
                        this.activityOffer?.frontEndTariffDetDTOs.push(data.body);
                    })
            }
        }
    });
    this.frontTariffDetMobile = this.frontTariffDetMobile.concat(frontList);
    this.endTariffDetMobile = this.endTariffDetMobile.concat(endList);

    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Tarifeler eklendi.' });
    this.displayFrontEndCarryList = false;
    }
  addItemReservation() {
    (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(null));
  }

  deleteItemFromReservation(idx: number) {
    (this.reservationForm.get('equipments') as UntypedFormArray).removeAt(idx);
  }

  showReservationPreviewDialog() {
    if(!this.checkQuantitiyValidation()){
        this.showReservationConfirmDialog = !this.showReservationConfirmDialog;
    }
  }

  checkValidation() {
      for(let item in this.offerRequestForm.controls){
          if (this.offerRequestForm?.controls[item]?.status === 'INVALID') {
              this.offerRequestForm?.get(item)?.markAsDirty();
          }
      }
  }

  validation(){
    const hasRejected = this.selectedOfferPrice.find(f => f.offerStatus == 'rejected');
    if (hasRejected && (this.rejectSubType == null || this.rejectSubType == '')) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı',  detail: `İptal nedeni seçilmelidir.` });
      return false;
    }
    return true;
  }

  goodDefinationValidation(){
    if(((this.activatedRoute.snapshot.params.id === undefined || this.activatedRoute.snapshot.params.id !== undefined ) && this.offerRequestForm.get('goodDefinationType').value == null || this.offerRequestForm.get('goodDefinationType').value == "") ){
      this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
      return false;
    }
    return true;
  }
  async makeReservation() {
    for (let control in this.reservationForm.controls) {
      if(!this.reservationForm.get(control).valid) {
        this.messageService.add({key: 'tst',severity: 'warn',summary: 'Uyarı',detail: `${this.validatedRezervationFields[control]} alanı boş bırakılamaz.`});
        return;
      }
    }

      if (this.shipOwnersAndOfferDetails.length > 1) {
          if (this.selectedOfferSeaDetailId === null) {
              this.messageService.add({key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Lütfen Armatör Seçin`});
              return;
          }
      } else {
          this.selectedOfferSeaDetailId = this.activityOffer.activitySeaFullImportEditDTO.seaAllocationEditDTOs.find(offer => offer.offerStatus === 'confirmed').offerSeaDetailId;
          console.log(this.selectedOfferSeaDetailId)
      }


    if(String(this.reservationForm.value?.operationNote).length > 255) {
      this.messageService.add({key: 'tst',severity: 'warn',summary: 'Uyarı',detail: `Operasyon notu en fazla 255 karakter olabilir.`});
      return;
    }

    this.isLoading = true;
    const equipments = this.reservationForm.get(['equipments'])!.value;
    equipments.forEach(e =>  Object.assign(e, {'containerId': e.seaContainerType.id}));

    const response = await this.offerExportService.createReservation({
        offerSeaDetailId: this.selectedOfferSeaDetailId,
        senderId:  this.reservationForm.get(['senderId'])!.value?.id,
        consigneId: this.reservationForm.get(['consigneId'])!.value?.id,
        abroadAgentId: this.reservationForm.get(['abroadAgentId'])!.value?.id,
        operationRepresentativeId: this.reservationForm.get(['operationRepresentativeId'])!.value?.id,
        whoseBusiness: this.reservationForm.get(['whoseBusiness'])!.value,
        operationNote: this.reservationForm.get(['operationNote'])!.value,
        customerRef: this.reservationForm.get(['customerRef'])!.value,
        masterOnly: this.reservationForm.get(['masterOnly'])!.value,
        salesChannel: this.reservationForm.get(['salesChannel'])!.value,
        freightPayment: this.reservationForm.get(['freightPayment'])!.value,
        equipments: equipments
    }, 'I');

    if (response.status === 200) {
          this.isLoading = false;
          this.showReservationConfirmDialog = false;
          this.messageService.add({
              key: 'tst',
              severity: 'success',
              summary: 'Başarılı',
              detail: 'Rezervasyon Başarıyla Kaydedildi'
          });
          this.reservationDetail.push(JSON.parse(response.body));
    } else {
          this.isLoading = false;
          this.messageService.add({
              key: 'tst',
              severity: 'error',
              summary: 'Hata',
              detail: 'İstek gönderilirken hata oldu!'
          });
    }
  }

  getReportList() {
    this.mailFrom = JSON.parse(localStorage.getItem('user')).email;
    if (this.reportList?.length >= 0) {
        this.offerExportService.getReportList(this.activityOffer.activitySeaFullImportEditDTO.offerId).subscribe( rp => {
            this.reportList = rp.body;
        });
    }
  }

    addPdfAsAttachment(report: any) {
        this.isLoading = true;

        if (this.offerRequestForm.value.customer) {
            this.personService.search({'company.id': this.offerRequestForm.value.customer.id}, 'I').subscribe(persons => {
                this.companyEmails = this.companyEmails.concat(persons.body.filter(f => f.email).map(q => q.email));

                this.companyService.getById(this.offerRequestForm.value.customer.id, 'I').subscribe(c => {
                    this.companyEmails = this.companyEmails.concat(c.body.defaultAddresCardDetail.emails.map(e => e.name));
                })
            });
        }

        const renderedParams = {
            entityId: this.activityOffer.activitySeaFullImportEditDTO.offerId,
            companyTypeCode: 'customer',
            pageId: report?.page?.id
        };
        this.offerExportService.getEMailObjectsByParams(JSON.stringify(renderedParams), 'I').subscribe(res => {
            if (!this.mailTo?.length) {
                if (!res.body[0].email) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Uyarı',
                        detail: 'Şirket e-posta adresi bulunamadı.'
                    });
                    return;
                }
                this.mailTo.push(res.body[0].email);
            }
        });
        this.displayMail = !this.displayMail;
        this.offerExportService.getReport(report?.id, this.activityOffer?.activitySeaFullImportEditDTO?.offerId).subscribe(gt => {
            this.isLoading = false;
            const file = new Blob([gt], {type: 'application/pdf'});
            this.file = file;
            this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Eklendi'});
        }, error => {
            this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF Eklenemedi!'});
            this.isLoading = false;
        });
        this.offerExportService.getEmailSettingsByID(report?.id, this.activityOffer.activitySeaFullImportEditDTO.offerId, 'I').subscribe(doc => {
            this.context = doc?.body?.mailBody;
            this.subject = doc?.body?.mailSubject;
        });
    }

  showPdf(report: any) {
    this.isLoading = true;
    this.offerExportService.getReport(report?.id, this.activityOffer?.activitySeaFullImportEditDTO?.offerId).subscribe( gt => {
        this.isLoading = false;
        const file = new Blob([gt], { type: 'application/pdf' });
        this.file = file;
        const fileUrl = window.URL.createObjectURL(file);
        window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
    }, error => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
        this.isLoading = false;
    });
  }

  sendReportMail() {
    this.isLoading = true;
    const email: Email = new Email();
    email.fromAddress = JSON.parse(localStorage.getItem('user')).email;;
    email.user = JSON.parse(localStorage.getItem('user'));
    email.toAddress = this.mailTo?.toString();
    email.ccAddress = this.cc?.toString();
    email.bccAddress = this.bcc?.toString();
    email.subject = this.subject;
    email.body = this.context;

    if (!email.toAddress?.length || email.subject === '' || email.body === '') {
        this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'Alıcı, Konu ve içerik boş olamaz!'});
        this.isLoading = false;
    } else {
        const attachment: EmailAttachment = new EmailAttachment();
        let emailAttachment: EmailAttachment;
        let uploadedAttachment: EmailAttachment;
        this.offerExportService.saveEmail(email).subscribe(res => {
            attachment.email = EntityUtil.toSimple(res.body);
            attachment.createDate = new Date();
            attachment.fileName = 'Teklif' + '.pdf';
            attachment.fileContentType = '.pdf';
            attachment.fileExtension = '.pdf';
            attachment.name = 'Teklif' + '.pdf';
            attachment.reportId = this.reportId;
            this.attachment = attachment;

            this.offerExportService.saveAttachment(this.attachment).subscribe(atc => {
                emailAttachment = atc.body;
                this.file = new File([this.file], 'fileName');
                const formData: FormData = new FormData();
                formData.append('file', this.file);
                this.offerExportService.uploadAttachment(formData, atc.body.id).subscribe(up => {
                    uploadedAttachment = up.body;
                    this.offerExportService.sendEmail(res.body.id).subscribe(ans => {
                        this.isLoading = false;
                        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Mail Gönderildi!' });
                        this.displayMail = false;
                    }, error => {
                        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Konu ve içerik boş olamaz!' });
                        this.isLoading = false;
                    });
                });
            });
        });
    }
  }

  createTariffFormControl(tariff: seaFreightTariffDetMobiles) {

    // Tariff'in bağlı olduğu allocation'dan vessel'e erişiliyor. seaFreightTariffDetMobiles vessel null geliyor.
    if(this.allocation) {
      const allocationTariff = this.allocation.find(f => f.id == tariff.seaAllocationId);
      tariff.vessel = allocationTariff?.vessel?.name;
    }

    const tariff_ = this._seaFreightTariffDetMobiles

    tariff_.patchValue(tariff);
    (tariff_.get('seaContainerRezervations') as UntypedFormArray).removeAt(0);

    if (tariff !== null || tariff !== undefined) {
       tariff.seaContainerRezervations.forEach(q => {
        const seaContainerRezervation = this._seaContainerRezervations;
        (seaContainerRezervation.get('seaTariffDetMobiles') as UntypedFormArray).removeAt(0);

        seaContainerRezervation.patchValue(q);
        seaContainerRezervation.patchValue({minAmount: q.fareAmount});

         if(q.seaTariffDetMobiles && q.seaTariffDetMobiles.length > 0) {
           q.seaTariffDetMobiles.forEach(st => {
             let seaTariffDet = this._seaTariffDetMobiles;
             seaTariffDet.patchValue(st);
             seaTariffDet.patchValue({minAmount: st.amount});
             (seaContainerRezervation.get('seaTariffDetMobiles') as UntypedFormArray).push(seaTariffDet);
           })
         }
         (tariff_.get('seaContainerRezervations') as UntypedFormArray).push(seaContainerRezervation);
       })
      return tariff_
    } else {
        return this.fb.group(new seaFreightTariffDetMobiles());
    }
  }

  sendRevision() {
    let payloadUpdateStatus = [];
    if(this.seaAllocationEditDTOs && this.seaAllocationEditDTOs.length > 0) {

      this.seaAllocationEditDTOs.forEach(s => {
        payloadUpdateStatus.push({
          offerSeaDetailId: s.offerSeaDetailId,
          status: 'revisionRequest',
          substatus: null,
          description: this.revisionDescription,
        })
      })
        this.onUpdateStatus(payloadUpdateStatus);
    }
  }

  toggleRevisionDialog() {
    this.showRevisionDescDialog = !this.showRevisionDescDialog;
  }

  filterGoodDefinationType(event){
    const filtered: any[] = [];
        const query = event.query;
        this.filteredGoodDefinationType.forEach(goodTypeDef => {
            if (goodTypeDef.name.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(goodTypeDef);
            }
        });
        this.filteredGoodDefinationType2 = filtered;
  }

  filterSeaContainer(seaContainers) {
    let filteredArr = [];
    filteredArr = [];

    if (!seaContainers || seaContainers.length == 0) return [];
    if (!this.selectedContainerTypes || this.selectedContainerTypes.length == 0) [];
    seaContainers.forEach(i => {
        const foundedIndex = this.selectedContainerTypes.findIndex(f => f['name']  == i.value.seaContainerType.name)
        if(foundedIndex > -1 ) {
          const exist = filteredArr.find(w => w.value.id == i.value.id);
          if(!exist) filteredArr.push(i);
        }
    });
    return filteredArr;
  }

  filterSeaTariffDetMobiles(tariffDetMobiles: UntypedFormGroup[]) {
    tariffDetMobiles.forEach(f => {
      const val = f.value;
      if(!val.seaTariffDetMobiles) return;
      val.seaTariffDetMobiles.forEach((st, indx) => {

        const existTariff = this.diffTariffDetMobiles.find(d => d.value.ctype == st.ctype && d.value.invoiceItemType.id == st.invoiceItemType.id);
        if(!existTariff) {
          const a = this._seaTariffDetMobiles;
          a.patchValue(st)
          // @ts-ignore
          this.diffTariffDetMobiles.push(a)
        }

        // if(st.ctype != 'perContainer') {
        //   this.diffTariffDetMobiles.push(f.get("seaTariffDetMobiles")["controls"][indx]);
        // } else {
        //
        // }
      })
    })
    return this.diffTariffDetMobiles;
  }

  filterTariffDet(tariffs) {
    let filteredArr = tariffs;
    const fromSeaPort = this.offerRequestForm.get('fromSeaPort')
    const toSeaPort = this.offerRequestForm.get('toSeaPort');

    if(fromSeaPort && fromSeaPort.value && fromSeaPort.value.id) {
      filteredArr = filteredArr.filter(f => f.value.fromPort.id == fromSeaPort.value.id);
    }

    if(toSeaPort && toSeaPort.value && toSeaPort.value.id) {
      filteredArr = filteredArr.filter(f => f.value.toPort.id == toSeaPort.value.id);
    }
    return filteredArr;
  }

  filterCompany(event) {
    if (event.query.length < 3) {
        return;
    } else {
        this.companyService.getAll(event.query).subscribe(res => {
            this.filteredCompanies = res;
        });
  }
  }

    filterShipownerCompany(event) {
        this.offerExportService.getCompany(event.query, ['SO']).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

  filterContainer(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.containers.length; i++) {
      const country = this.containers[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    this.filteredContainers = filtered;
  }

  filterSeaport(event) {
    if (event.query.length < 3) return;

    this.offerExportService.getOfferExportSeaport(event.query).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }

  filterCountry(event) {
    if (event.query.length > 2) {

        this.offerExportService.getOfferExportCountry(event.query).subscribe(res => {
            this.filteredCountryMinimized = res.body;
        });
    }
  }

  filterCity(event) {
    if (event.query.length > 2) {
        this.offerExportService.getOfferExportCity(event.query).subscribe(res => {
            this.filteredCityMinimized = res.body;
        });
    }
  }

    filterDistrict(event, cityId) {
    if (event.query.length > 2) {
            this.offerExportService.getOfferExportDistrict(event.query, cityId).subscribe(res => {
            this.filteredDistrictMinimized = res.body;
        });
    }
  }

  filterOperationRepresentative(event) {
    this.offerExportService.getUserWithName(event.query, 'I').subscribe(res => {
        this.operationRepresentativeList = res.body;
    });
  }

  filterBusinessType(event) {
    const filtered: any[] = [];
    const query = event.query;
    this.business.forEach(country => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
            filtered.push(country);
        }
    });
    this.businessType = filtered;
  }

  showCreateCustomerDialog() {
    let dialogRef = this.dialog.open(CreateCustomerComponent, {
      panelClass: 'modal-md',
      width: '1000px',
      height: 'auto',
      data: {}
    });
  }

  getAllocationById(tariff) {
    if(!this.allocation) return null;
    return this.allocation.find(f => f.id == tariff.seaAllocationId);
  }

  filterCurrency(event) {
      this.offerExportService.getAllCurrency('I').subscribe(res => {
          this.currencyType = res.body;
          this.currency = res.body;
          const filtered: any[] = [];
          const query = event.query;
          this.currency.forEach(country => {
              if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                  filtered.push(country);
              }
          });
          this.currencyType = filtered;
      });
  }

  autocompleteFocusOut(value, key, form, field = 'id') {
    if((value && !value[field]) || value?.name === undefined) form.get(key).setValue(null);
    console.log(this.offerRequestForm.get('goodDefination').value);
    console.log(this.offerRequestForm.get('goodDefinationType').value);
  }
  focusOutContainer(item) {
    if(!item.value || !item.value.id) item.setValue(null);
  }

    changeAmountWithCount(event: any, rz: any, buyOrSell: string) {
        rz.amount = event * rz.count;
    }

    changeAmountWithCount2(event: any, rz: any) {
        rz.amount = rz.unitAmount * event;
    }

    async view(item: any) {
        // SeaGood Detail
        const resp = await this.seaGoodMobileSearch({'seaGoodId': item.id})
        this.displaySeaGoodDetail = true;
        this.showPreviousBtn = false;
        this.listResultItem = resp['datas'][0]
        if (this.listResultItem) this.showDetailDialog = true;
    }

    async seaGoodMobileSearch(param) {
        let response = [];
        await this.listService.goodMobileSearch(param, '/sea/list/seaGoodMobileList/listSearch?name=seaGoodMobileList&query=', 'E').toPromise().then(res => {
            response = res.body;
        });
        return response;
    }

    onCloseDetail() {
        this.showDetailDialog = false;
    }

    lastReservationContainers(eq: any) {
        return {seaContainerType: eq.containerType, count: eq.quantity};
    }

    showStatusStory(offerId: number) {
      this.offerExportService.getAllOfferStatusByOfferId(offerId, 'I').subscribe(res => {
          this.offerStatusHistory = res.body;
      }, error => {
          this.messageService.add({ key: 'tst', severity:'warning', summary: 'Uyarı', detail: 'Teklif durumu henüz değişmemiş' });
      });
    }

    getDigitalArsiv() {
      this.isLoading = true;
      this.offerExportService.getDigitalArsiv(this.activityOffer?.activitySeaFullImportEditDTO?.offerId, 'Offer').subscribe(res => {
          this.digitalStore = res.body;
          this.isLoading = false;
      }, error => {
          this.isLoading = false;
      });
    }

    getDigitalDocument(report: any) {
      this.isLoading = true;
      this.offerExportService.getDocumentType(report?.id, 'I').subscribe(res => {
          this.offerExportService.getDigitalDocument(res.body.reference, 'I').subscribe( gt => {
              this.isLoading = false;
              const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
              const fileUrl = window.URL.createObjectURL(file);
              if ( res.body.contentType === 'application/pdf' ||  res.body.contentType.startsWith('image')) {
                  window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
              } else {
                const nav = (window.navigator as any)
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
                } else {
                      var a = document.createElement('a');
                      a.href = URL.createObjectURL(file);
                      a.download = res.body.fileName;
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                  }
              }
              this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
          }, error => {
              this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
              this.isLoading = false;
          });
      },error => {
          this.isLoading = false;
      });
    }

    uploadDocumentsCheck(documents: any) {
      console.log(documents);
      if (documents.files && documents.files.length > 0) {
          for (const file of documents.files) {
              if (file.size <= 15728640) {
                  this.uploadDocuments(file);
              } else {
                  this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
              }
          }

      } else if (documents.files && documents.files.length > 0) {
          for (const file of documents.files) {
              if (file.getAsFile().size <= 15728640) {
                  this.uploadDocuments(file);
              } else {
                  this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
              }
          }
      }
    }

    uploadDocuments(file: any) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', file);
      this.offerExportService.uploadDocuments(formData, this.activityOffer?.activitySeaFullImportEditDTO?.offerId).subscribe(res => {
          const dct = res.body.res;
          this.offerExportService.getPagesByName('/offers/offer').subscribe(pages => {
              const pageObject = pages.body.find(pg => pg.name === 'Teklif');
              if (res.status === 200) {
                  this.offerExportService.saveDocument({
                      entityid: +dct.entityid,
                      reference: dct.uid,
                      contentType: dct.contentType,
                      fileName: dct.fileName,
                      fileSize: dct.fileSize,
                      page: pageObject,
                      sourceType: 'Offer'
                  }).subscribe(sv => {
                      this.getDigitalArsiv();
                      this.uploader.clear();
                  });
                  // this.user = this.cacheService.get(Storage.USER);
                  this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
              }
          }, error => {
              this.isLoading = false;
          });
          this.isLoading = false;
      }, error => {
          this.isLoading = false;
      });
    }

    filterUsers(event) {
      if(event.query.lenght < 3) return;
      this.userService.searchUsers(event.query, null).subscribe(res => this.filteredUsers = res);
    }

    async getAvgEmptyReturn(val: any) {
        if (val.id){
            this.isLoading = true;
            const data = await this.companyService.getAvgEmptyReturn(val.id, 'I').toPromise();
            this.isLoading = false;
            this.offerRequestForm.patchValue({avgEmptyReturn: data});
        }
    }

    deleteContainer(container: SeaContainerRezervations, item: SeaFrightRequestDetMobiles) {
        if (item == null){
            this.seaFrightRequestDetMobiles.forEach(currentItem => {
                currentItem.seaContainerRezervations = currentItem.seaContainerRezervations.filter(c => c.seaContainerType.id !== container.id);
            });
        } else {
            const itemIndex = this.seaFrightRequestDetMobiles.findIndex(i => i === item);
            if (itemIndex !== -1) {
                const containerIndex = this.seaFrightRequestDetMobiles[itemIndex].seaContainerRezervations.indexOf(container);
                if (containerIndex !== -1) {
                    this.seaFrightRequestDetMobiles[itemIndex].seaContainerRezervations.splice(containerIndex, 1);
                    this.deleteEquipmentByContainerType(container.seaContainerType);

                }
            }
        }

    }

    deleteEquipmentByContainerType(containerType: SimpleEntity): void {
        const equipmentFormArray = this.offerRequestForm.get('equipments') as UntypedFormArray;
        for (let i = equipmentFormArray.length - 1; i >= 0; i--) {
            const equipmentFormGroup = equipmentFormArray.at(i) as UntypedFormGroup;
            if (equipmentFormGroup.value.seaContainerType === containerType) {
                equipmentFormArray.removeAt(i);
            }
        }
    }

    checkQuantitiyValidation(): boolean{
        let hasError = false;
        if (this.offerRequestForm.valid) {
            const containersFormArray = this.offerRequestForm.get('equipments') as UntypedFormArray;
            containersFormArray.controls.forEach((containerGroup: UntypedFormGroup, index: number) => {
                const countControl = containerGroup.get('count') as UntypedFormControl;
                if (countControl.value != null && countControl.value > 100) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'warn',
                        summary: 'Hata',
                        detail: `Teklif Talebi sayfasında ${index + 1}. sıradaki konteyner için, Miktar 100'den büyük olamaz!`
                    });
                    hasError =  true;
                }
            });
        }
        if (this.reservationForm.valid) {
            const equipmentsFormArray = this.reservationForm.get('equipments') as UntypedFormArray;
            equipmentsFormArray.controls.forEach((equipmentGroup: UntypedFormGroup, index: number) => {
                const quantityControl = equipmentGroup.get('count') as UntypedFormControl;

                if (quantityControl.value != null && quantityControl.value > 100) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'warn',
                        summary: 'Hata',
                        detail: `Rezervasyon  sayfasında ${index + 1}. sıradaki konteyner için, Miktar 100'den büyük olamaz!`
                    });
                    hasError =  true;
                }
            });
        }
        this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs.forEach(seaAllocation => {
            seaAllocation.activityOperationDetailEditDTOs.forEach(item => {
                if (item.count != null && item.count > 100) {
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Miktar bilgisi 100\'den büyük olamaz.' });
                    hasError =  true;
                }
            });
        })
        return hasError;
    }

    createRequestDetMobile() {
      this.seaFrightRequestDetMobiles.push(this.newSeaFrightRequestDetMobiles(null));

    }


    fillCtequipmentsWithContainerForm() {
        this.activityOffer.equipments = [];
        this.activityOffer?.activitySeaFullImportEditDTO?.seaAllocationEditDTOs.forEach(seaAllocation => {
            seaAllocation.activityOperationDetailEditDTOs.forEach(item => {
                this.activityOffer.equipments.push({
                    id: item.id,
                    containerId: item.seaContainerType?.id,
                    name: item.seaContainerType?.name,
                    tonnage: item.tonnage,
                    count: item.count,
                    overflow: item.overflow,
                    length: item.length,
                    width: item.width,
                    height: item.height,
                    setDegree: item.setDegree,
                });
            });
        })

    }

}
