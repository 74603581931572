import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetLesson } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { CreateLessonVideoDialogComponent } from '../dialog/create-lesson-video-dialog/create-lesson-video-dialog.component';

@Component({
  selector: 'app-list-lesson',
  templateUrl: './list-lesson.component.html',
  styleUrls: ['./list-lesson.component.scss']
})
export class ListLessonComponent implements OnInit {

  lessons: IntranetLesson[] = [];

  selectedLessons: IntranetLesson[] = [];

  constructor(private learningService: LearningService, public dialog: MatDialog, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {

    this.getLessons();

  }

  getLessons() {

    this.learningService.getAllLessons().subscribe(res => {

      this.lessons = res;

    })

  }

  openDeleteConfirmDialog(lesson: IntranetLesson): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = lesson.name
    dialogRef.componentInstance.fIndex = lesson.id


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.learningService.deleteLesson(lesson.id).subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Ders Silindi" });
          this.getLessons();
        })
      }
    });
  }

  createQuiz(lesson: IntranetLesson) {

    this.router.navigate(['/learning/quiz/create-lesson-quiz/' + lesson.id]);

  }

  openLessonVideoDialog(lesson: IntranetLesson) {
    let dialogRef = this.dialog.open(CreateLessonVideoDialogComponent, {
      width: '1200px',
      data: {
        lesson: lesson,
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {

      }
    });
  }

}
