import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activity } from '../models/activity.model';
import { BaseService } from './base.service';
import {environment} from '../../environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SeaFreightBuyTariffDetMobile } from '../models/sea-freight-buy-tariff-det';

@Injectable({
  providedIn: 'root'
})
export class ListPriceOfferSeaService extends BaseService<any>{

  constructor(public httpClient: HttpClient, public datepipe : DatePipe) {
    super(
        'activity',
        httpClient);
}

  getWithParams(etd : String, fromPortId?: number, toPortId?: number,  shipOwnerId?: number,  customerId?: number): Observable<SeaFreightBuyTariffDetMobile[]> {

    var token = localStorage.getItem("token");
    var sessionId = localStorage.getItem("sessionId");

    const params = new HttpParams();
    
    var url = environment.baseUrl + "/" + this.endpoint + "/getAllPriceOffer?token=" + token + "&sessionId=" + sessionId + "&etd=" + etd;
    if(fromPortId) url +=  "&fromPortId=" + fromPortId;
    if(toPortId) url +=  "&toPortId=" + toPortId;
    if(shipOwnerId) url +=  "&shipOwnerId=" + shipOwnerId;
    if(customerId) url +=  "&customerId=" + customerId;

    return this.httpClient.get<SeaFreightBuyTariffDetMobile[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: params })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
}
}
