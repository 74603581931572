import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';
import {StatusDTO} from '../models/status-dto.model';
import {BaseService} from './base.service';
import {getToken} from './token-interceptor.service';


@Injectable()
export class StatusService extends BaseService<any> {

    public queryParam: HttpParams;
    public user: any;

    constructor(public httpClient: HttpClient) {
        super('status', httpClient);
        this.user = JSON.parse(localStorage.getItem('user'));
        this.queryParam = new HttpParams().set('token', localStorage.getItem('token')).set('sessionId', localStorage.getItem('sessionId'));
    }

    getAllByOperationTypeAndStatusTypeAndTransportDirection(operationType: string, transportDirectionForStatus: string, statusType: string , transportDirection: string): Observable<HttpResponse<StatusDTO[]>> {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/operationTypeAndStatusTypeAndTransportDirection';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, operationType, transportDirectionForStatus, transportDirection, statusType });

        return this.httpClient.get<StatusDTO[]>(url, {params: options, observe: 'response'});
    }


}
