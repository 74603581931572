import {SimpleEntity} from './simple-entity.model';
import {AbstractEntity} from './abstract-entity.model';

export class Email extends AbstractEntity {

    name: string;
    entityType: string;
    entityId: number;
    fromAddress: string;
    toAddress: string;
    ccAddress: string;
    bccAddress: string;
    subject: string;
    body: string;
    isSend: boolean;
    isTemplete: boolean;
    sentDate: Date;
    user: SimpleEntity;
    emailAttachments: SimpleEntity[];
}
