import { Component, OnInit } from '@angular/core';
import { FinancialQueryModel } from 'src/app/models/financial-query/financial-query.model';
import { FinancialQueryService } from 'src/app/services/financial-query/financial-query.service';
import { Router } from '@angular/router';
import {Company} from '../../../models/company.model';
import {OfferExportService} from '../../../services/offer-export.service';
import {UserService} from '../../../services/user.service';
import {MessageService} from 'primeng/api';
@Component({
  selector: 'app-financial-form-list',
  templateUrl: './financial-form-list.component.html',
  styleUrls: ['./financial-form-list.component.scss']
})
export class FinancialFormListComponent implements OnInit {

  financialQueries: FinancialQueryModel[] = [];
  user: any;
    filteredCompanies: Company[] = [];
    companies: Company[] = [];
    selectedCompany: Company;
    selectedStatus: any;
    statuses =  [{code:"FSY",name: 'Finansal sorgu yapıldı'}, {code:"FSB",name: 'Finansal sorgu bekliyor'}, {code:"FDA",name: 'Değerlendirme Aşamasında'}];
    transportDirections = [{name: 'KTL International', value: 'I'}, {name: 'KINAY', value: 'E'}];
    selectedTransportDirection: any;
    lastSearchedTransportDirection: any;
    isLoading: boolean = true;

    constructor(private messageService: MessageService, private financialQueryService: FinancialQueryService, private userService: UserService, private offerExportService: OfferExportService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit(): void {
      const user = this.userService.getUserFromLocalStorage();
      this.selectedStatus = 'FSB';

      if (!user.company) {
          this.messageService.add({key: 'tst', severity:'warn', detail: 'Kullanıcı tanımına ait adres kartı bilgisi bulunamadı!' });
      } else {
          this.selectedTransportDirection = user.company.name.includes('KTL') ? 'I' : 'E';
      }
      this.search();
  }

  search(){
      this.isLoading = true;
      this.lastSearchedTransportDirection = this.selectedTransportDirection;
      this.selectedTransportDirection == 'I' ? this.user = this.userService.getUserByTransportDirection('I') : this.user = this.userService.getUserByTransportDirection('E') 
      this.financialQueryService.search({'requestUser.id': this.user.id , 'status': this.selectedStatus, 'company.id': this.selectedCompany?.id}, this.selectedTransportDirection).subscribe(r => {
          this.financialQueries = r;
          this.isLoading = false;
      } )
  }

  navigateDetail(form: FinancialQueryModel) {
    this.router.navigate([`forms/financial-form/${form.id}/${this.lastSearchedTransportDirection}`]);
  }

    filterCompany(event) {
        this.offerExportService.getCompany(event.query,undefined,undefined,this.selectedTransportDirection).subscribe(res => {
            this.companies = res.body;
            this.filteredCompanies = res.body;
            const filtered: Company[] = [];
            const query = event.query;
            this.companies.forEach(company => {
                if (company.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(company);
                }
            });
            this.filteredCompanies = filtered;
        });
    }

    autocompleteFocusOut(value) {
        if (!value || !value.id) this.selectedCompany = null
    }

}
