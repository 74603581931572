export {};

declare global {
    interface URL {
        updateQueryParam(key, value): URL 
    }

}

if (!URL.prototype.updateQueryParam) {  
    URL.prototype.updateQueryParam = function (key, value): URL {
        this.searchParams.delete(key);
        this.searchParams.set(key, value);
        return this;
    }
}