import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Message, MessageService, PrimeIcons } from 'primeng/api';
import { Activity } from 'src/app/models/activity.model';
import { Allocation } from 'src/app/models/allocation.model';
import { Company } from 'src/app/models/company.model';
import { ContainerModel } from 'src/app/models/container.model';
import { DeliveryPlace } from 'src/app/models/delivery-place.model';
import { Equimenet } from 'src/app/models/equiment.model';
import { SeaPort } from 'src/app/models/seaport.model';
import { TextConstants } from 'src/app/models/text-contants.model';
import { ActivityService } from 'src/app/services/activity.service';
import { CompanyService } from 'src/app/services/company.service';
import { ContainerService } from 'src/app/services/container.service';
import { SeaportService } from 'src/app/services/seaport.service';

@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss']
})
export class OfferDetailComponent implements OnInit {

  companies: Company[] = [];

  filteredCompanies: Company[] = [];

  selectedCompany: Company;

  shipOwners: Company[] = [];

  filteredShipOwners: Company[] = [];

  selectedShipOwner: Company;

  departureSeaport: SeaPort[] = [];

  filteredDepartureSeaport: SeaPort[] = [];

  selectedDepartureSeaport: SeaPort;

  arrivalSeaport: SeaPort[] = [];

  filteredArrivalSeaport: SeaPort[] = [];

  selectedArrivalSeaport: SeaPort;

  containers: ContainerModel[] = [];

  filteredContainers: ContainerModel[] = [];

  selectedContainer: ContainerModel;


  selectedState: any = null;

  selectedOffer: any = null;

  offerList: string[] = [];

  offerOptions: any[] = [

  ];

  values3: string[];

  equipments: Equimenet[] = [];

  equipmentsAdded: Equimenet[] = [];

  notes: string = "";

  loadingList: DeliveryPlace[] = [];

  selectedLoading: DeliveryPlace;

  deliveryList: DeliveryPlace[] = [];

  selectedDeliveryPlace: DeliveryPlace;

  activity: Activity;

  msgs: Message[] = [];

  constructor(private seaportService: SeaportService, private companyService: CompanyService, private router: Router, private route: ActivatedRoute,
    private containerService: ContainerService, private activityService: ActivityService, private messageService: MessageService) {

    this.route.params.subscribe(res => {
      var id = res.id;

      if (localStorage.getItem("activity")) {

        var activity = JSON.parse(localStorage.getItem("activity")) as Activity;

        if (activity.id != id) {

          this.router.navigate(["/activities/list-offer-request"]);

        } else {
          this.activity = activity;

          console.log(this.activity);
        }

      } else {
        this.router.navigate(["/activities/list-offer-request"]);
      }

    });

    var deliveryPlacePort: DeliveryPlace = {
      name: 'LİMAN',
      value: 'port'
    };

    var deliveryPlaceAddress: DeliveryPlace = {
      name: 'MÜŞTERİ ORTAMI',
      value: 'address'
    };

    this.deliveryList.push(deliveryPlacePort);
    this.deliveryList.push(deliveryPlaceAddress);

    var loadingPlacePort: DeliveryPlace = {
      name: 'LİMAN',
      value: 'port'
    };

    var loadingPlaceAddress: DeliveryPlace = {
      name: 'MÜŞTERİ ORTAMI',
      value: 'address'
    };

    this.loadingList.push(loadingPlacePort);
    this.loadingList.push(loadingPlaceAddress);

    if (this.activity.loading != "") {
      if (this.activity.loading == "port") {
        this.selectedLoading = loadingPlacePort;
      }

      if (this.activity.loading == "address") {
        this.selectedLoading = loadingPlaceAddress;
      }
    }

    if (this.activity.unloading != "") {
      if (this.activity.unloading == "port") {
        this.selectedDeliveryPlace = deliveryPlacePort;
      }

      if (this.activity.unloading == "address") {
        this.selectedDeliveryPlace = deliveryPlaceAddress;
      }
    }

    this.notes = this.activity.notes;


    this.activity.equipments.forEach(item => {

      this.equipments.push(item);


    })

    this.msgs = [];
    this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Message sent' });


  }

  events: any[];

  ngOnInit() {

    if (this.activity.subType == 'accepted') {
      this.events = [
        { status: 'TEKLİF VERİLDİ', color: '#9C27B0' },
        { status: 'ONAYLANDI', color: '#9C27B0' },
      ];
    }

    if (this.activity.subType == 'rejected') {
      this.events = [
        { status: 'İPTAL EDİLDİ', color: '#9C27B0' },

      ];
    }


    var offer1 = TextConstants.OFFER_TYPE_SEA + "-" + TextConstants.OFFER_TYPE_PRICE;
    var offer2 = TextConstants.OFFER_TYPE_SEA + "-" + TextConstants.OFFER_TYPE_REQUEST;
    var offer3 = TextConstants.OFFER_TYPE_AIR + "-" + TextConstants.OFFER_TYPE_PRICE;
    var offer4 = TextConstants.OFFER_TYPE_AIR + "-" + TextConstants.OFFER_TYPE_REQUEST;

    this.offerOptions.push({ name: offer1, code: offer1 });
    this.offerOptions.push({ name: offer2, code: offer2 });
    this.offerOptions.push({ name: offer3, code: offer3 });
    this.offerOptions.push({ name: offer4, code: offer4 });

    this.selectedOffer = this.offerOptions[0];


    this.seaportService.getAll().subscribe(res => {
      this.arrivalSeaport = res;
      this.departureSeaport = res;
      this.filteredArrivalSeaport = res;
      this.filteredDepartureSeaport = res;

      res.forEach(item => {

        if (this.activity && item.id == this.activity.fromSeaPort.id) {
          this.selectedDepartureSeaport = item;
        }

        if (this.activity && item.id == this.activity.toSeaPort.id) {
          this.selectedArrivalSeaport = item;
        }
      })

    })

    this.companyService.getAll().subscribe(res => {
      this.companies = res;
      this.filteredCompanies = res;
      this.shipOwners = res;
      this.filteredShipOwners = res;

      this.shipOwners.forEach(item => {

        if (this.activity && item.id == this.activity.shipOwner.id) {
          this.selectedShipOwner = item;
        }

        if (this.activity && item.id == this.activity.customer.id) {
          this.selectedCompany = item;
        }
      })

    })

    this.containerService.getAll().subscribe(res => {

      this.containers = res;
      this.filteredContainers = res;

      console.log(res);

    })

  }

  isLoading = false;

  createActivity() {
    var activity: Activity = {
      customer: this.selectedCompany,
      equipments: this.equipments,
      fromSeaPort: this.selectedDepartureSeaport,
      toSeaPort: this.selectedArrivalSeaport,
      id: null,
      loading: this.selectedLoading ? this.selectedLoading.value : "",
      notes: this.notes,
      shipOwner: this.selectedShipOwner,
      operationType: 'SEA',
      unloading: this.selectedDeliveryPlace ? this.selectedDeliveryPlace.value : "",
    }





    this.isLoading = true;

    this.activityService.createActivity(activity).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Oluşturuldu' });

      setTimeout(() => {
        this.isLoading = false;
      }, 1500)
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Teklif Oluşturulamadı' });
    })


  }


  onChipAdd(event: any) {
    console.log(event);
    var count = parseInt(event.value);



    if (this.selectedContainer) {

      var find = this.equipments.find(v => v.containerId == this.selectedContainer.id);

      if (find == null) {
        var equiment: Equimenet = {
          containerId: this.selectedContainer.id,
          name: this.selectedContainer.name,
          count: count,
          id: null
        }

        this.equipments.push(equiment);
      }


    }

    this.equipments = this.equipments.filter(v => v.containerId != null);
  }

  onChipRemove(event: any) {
    console.log(event);
  }

  filterCompany(event) {
    const filtered: Company[] = [];
    const query = event.query;
    for (let i = 0; i < this.companies.length; i++) {
      const country = this.companies[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCompanies = filtered;
  }

  filterShipOwner(event) {
    const filtered: Company[] = [];
    const query = event.query;
    for (let i = 0; i < this.shipOwners.length; i++) {
      const country = this.shipOwners[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredShipOwners = filtered;
  }

  filterDepartureSeaport(event) {
    const filtered: Company[] = [];
    const query = event.query;
    for (let i = 0; i < this.departureSeaport.length; i++) {
      const country = this.departureSeaport[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredDepartureSeaport = filtered;
  }

  filterArrivalSeaport(event) {
    const filtered: Company[] = [];
    const query = event.query;
    for (let i = 0; i < this.arrivalSeaport.length; i++) {
      const country = this.arrivalSeaport[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredArrivalSeaport = filtered;
  }

  filterContainer(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.containers.length; i++) {
      const country = this.containers[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredContainers = filtered;
  }

}
