import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContainerModel } from '../models/container.model';
import { BaseService } from './base.service';


@Injectable()
export class ContainerService extends BaseService<ContainerModel>{
    constructor(public httpClient: HttpClient) {
        super(
            'container',
            httpClient);
    }

    //TODO: impersonation !!!
}