import { Inject, Injectable } from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, delay, retry, tap } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Base } from "../models/base.model";
import {getToken} from "./token-interceptor.service";


@Injectable()
export class BaseService<T extends Base> {

    protected httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'q=0.8;application/json;q=0.9'
        }),
    };

    url = '';

    public getToken = getToken;

    constructor(@Inject(String) protected endpoint: string, protected httpClient: HttpClient) {
        this.url = environment.baseUrl;

        this.httpOptions.headers = this.httpHeaders();
    }

    protected httpHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers.append("Accept", "application/json");
        headers.append('Content-Type', 'application/json');
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", 'true');
        headers.append("Access-Control-Allow-Methods", '*');
        headers.append("timeout", '10000');

        return headers;
    }

    createRequestOption = (req?: any): HttpParams => {
        let options: HttpParams = new HttpParams();
        if (req) {
            Object.keys(req).forEach((key) => {
                if (key !== 'sort') {
                    options = options.set(key, req[key]);
                }
            });
            if (req.sort) {
                req.sort.forEach((val) => {
                    options = options.append('sort', val);
                });
            }
        }
        return options;
    }

    getAll(name: string = "", transportDirection?: string): Observable<T[]> {

        var token = getToken(transportDirection);

        var url = environment.baseUrl + "/" + this.endpoint + "/getall";

        var option = this.createRequestOption({token: token.token, sessionId: token.sessionId, name, transportDirection})

        return this.httpClient.get<T[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: option})
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    protected handleError(error: any) {
        let errorMessage = '';

        try {
            if (error.error instanceof ErrorEvent) {
                //error client
                errorMessage = error.error.message;
            } else {
                //error server

                errorMessage = `Error status: ${error.status}, ` + `message: ${error.error.message}`;

                // if (errorMessage.includes('Session')) {
                //    console.error("İthalat İhracat session veya token hatası yaşanmış olabilir!", errorMessage);
                //    localStorage.removeItem("user");
                //    localStorage.removeItem("token");
                //    localStorage.removeItem("export");
                //    localStorage.removeItem("import");
                //    localStorage.removeItem("menu");
                //    localStorage.removeItem("orc");
                //    window.location.href = "/login";
                //}

                if (error.status == 401) {

                    console.log(error)
                    // window.location.reload();

                }
            }
        } catch (ex) {
            return throwError(error);
        }

        return throwError(error);
    }

    /** Log a service with the LogService
     * Not implemented yet!!
    */
    protected log(message: string) {
        // TODO: Logging implementation
    }
}
