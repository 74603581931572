import { Component, OnInit } from '@angular/core';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { Company } from 'src/app/models/company.model';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { CompanyService } from 'src/app/services/company.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DatePipe } from '@angular/common';
import { OfferPartialActivityMobile } from 'src/app/models/offer-partial-activity-mobile';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

export class offerRequestPartialSearch {
  podId: SimpleEntity = null;
  polId: SimpleEntity = null;
  customerId: SimpleEntity = null;
  validFrom: Date = null
  validTo: Date = null;
  status: string = "";
  seaGoodReferenceNo: string = null;
  referenceNo: string = null;
}


@Component({
  selector: 'app-list-offer-request-partial',
  templateUrl: './list-offer-request-partial.component.html',
  styleUrls: ['./list-offer-request-partial.component.scss']
})
export class ListOfferRequestPartialComponent implements OnInit {

  toPort : SeaportMinimizedModel;
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  searchModel: offerRequestPartialSearch = new offerRequestPartialSearch();
  offerPartialActivityMobiles: OfferPartialActivityMobile[] = [];
  isLoading: boolean = false;

  constructor(private offerExportService: OfferExportService,
    private router: Router,
    private messageService: MessageService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private activityService: ActivityService) {
      let date = new Date();
      this.searchModel.validFrom = new Date(date.getFullYear(), date.getMonth(), 1);
      this.searchModel.validTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
     }

  ngOnInit(): void {}


  filterSeaport(event) {
    if (event.query.length < 3) return;

    this.offerExportService.getOfferExportSeaport(event.query).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }

  async onSearch(status: string, onlyReservation = false) {

    if(!this.searchModel.validTo) {
      this.messageService.add({key: 'tst',severity: 'warn',summary: '',detail: 'Geçerlilik bitiş tarihi boş bırakılamaz.'});
      return;
    }

    this.isLoading = true;
    try {
      this.offerPartialActivityMobiles =  await this.activityService.searchOfferPartialActivity(this.searchModel.seaGoodReferenceNo, this.searchModel.referenceNo,this.searchModel.podId?.id || 0, this.searchModel.polId?.id || 0, this.searchModel.customerId?.id || 0, (this.datePipe.transform(this.searchModel.validFrom, 'yyyy-MM-dd')),  (this.datePipe.transform(this.searchModel.validTo, 'yyyy-MM-dd')), status, onlyReservation).toPromise();
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
    }
  }

  goDetails(item: OfferPartialActivityMobile) {
     this.router.navigate([`${location.pathname.split('/')[1]}/${location.pathname.split('/')[2] }/offer-request-partial/${item.activity.id}`]);
  }
}
