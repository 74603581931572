import {SimpleEntity} from "../simple-entity.model";

export class ActivityOperation {

  id: number;
  transportDirection: string;
  operationType: string;
  frequency: string;
  volume: number;
  unit: string;
  gp: number;
  statusCode: string;
  departureCity: SimpleEntity;
  arrivalCity: SimpleEntity;
    airDeparturePort: SimpleEntity;
    airArrivalPort: SimpleEntity;
    seaDeparturePort: SimpleEntity;
    seaArrivalPort: SimpleEntity;
  competitor: SimpleEntity;
  region: SimpleEntity;
  activity: SimpleEntity;

}
