import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { Department, DocumentTypeModel, IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetPage } from 'src/app/models/intranet-page.model';
import { IntranetDocumentService } from 'src/app/services/document.service';
import { FileService } from 'src/app/services/file.service';
import { NewFolderDialogComponent } from '../modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from '../modals/rename-dialog/rename-dialog.component';
import { DatePipe } from '@angular/common';

export class FileElement {
  id?: string
  isFolder: boolean
  name: string
  parent?: number
  intranetDocument: IntranetDocument
}

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public fileElements: Observable<FileElement[]>;

  constructor(public fileService: FileService, private intranetDocumentService: IntranetDocumentService, private datePipe: DatePipe) { }

  currentRoot: IntranetDocument;

  parentRoot: IntranetDocument;

  currentPath: string;
  canNavigateUp = false;


  fileIds: number[] = [];

  documentTypes: DocumentTypeModel[] = [];

  selectedDocumentType: DocumentTypeModel;

  expireDate: Date;

  departments: Department[] = [];

  selectedDepartment: Department = <Department>{};


  ngOnInit() {

    this.intranetDocumentService.getDocumentTypes().subscribe(res => {
      console.log(res);
      // this.documentTypes.push({
      //   name: 'Döküman Tipi Seçiniz',
      //   id: 0
      // })


      res.forEach(item => {
        this.documentTypes.push(item);
      })


    })

    this.intranetDocumentService.getDepartments().subscribe(res => {
      console.log(res);


      this.departments.push({
        label: 'Departman Seçiniz',
        context: 'department',
        id: 0
      })

      res.forEach(item => {
        this.departments.push(item);
      })

      if (this.departments.length > 0) {
        this.selectedDepartment = this.departments[0];
      }


    })


    this.getParentDocuments();
  }

  getParentDocuments() {

    this.fileService.refresh();

    this.intranetDocumentService.getParentDocuments().subscribe(res => {

      var documents = res;

      console.log(res);

      documents.forEach(item => {

        var add = true;

        if (this.selectedDocumentType) {

          if (item.documentTypeId != null && item.documentTypeId != this.selectedDocumentType.id) {
            add = false;
          }

        }

        if (this.selectedDepartment && this.selectedDepartment.id != 0) {

          if (item.departmentId != null && item.departmentId != this.selectedDepartment.id) {
            add = false;
          }

        }

        if (this.selectedDate && item.expireDate != null) {

          var date = new Date(item.expireDate);

          if (item.expireDate != null && date.getTime() > this.selectedDate.getTime()) {
            add = false;
          }

        }


        if (item.parentDocumentId == null && add) {
          if (item.mimeType == 'folder') {
            this.fileService.add({ name: item.fileName, isFolder: true, parent: 0, intranetDocument: item });
          } else if (item.mimeType == 'file') {
            this.fileService.add({ name: item.fileName, isFolder: false, parent: 0, intranetDocument: item });
          }
        }





      })

      this.updateFileElementQuery();

    })

  }

  selectedDate: Date;

  onTimeChange(event) {
    console.log(event);
    this.selectedDate = event;

    this.refreshDocuments();
  }

  onChangeDocumentType(event) {
    console.log('event :' + event);
    console.log(event.value);

    this.refreshDocuments();
  }

  onChangeDepartment(event) {
    console.log('event :' + event);
    console.log(event.value);

    this.refreshDocuments();
  }

  refreshDocuments() {

    if (this.parentRoot) {

      this.getDocumentsByParentId(this.currentRoot.id);

    } else {
      this.getParentDocuments();
    }

  }

  getDocumentsByParentId(parentId: number) {

    this.fileService.refresh();

    if (this.currentRoot) {

      this.intranetDocumentService.get(this.currentRoot.id).subscribe(res => {

        this.parentRoot = res;

      })

    }

    this.intranetDocumentService.getDocumentsByParentId(parentId).subscribe(res => {

      var documents = res;

      console.log(res, "filessss");
      console.log(this.selectedDate, "selectedDate");

      documents.forEach(item => {

        var add = true;

        if (this.selectedDocumentType && this.selectedDocumentType.id != 0) {

          if (item.documentTypeId != null && item.documentTypeId != this.selectedDocumentType.id) {
            add = false;
          }

        }

        if (this.selectedDepartment && this.selectedDepartment.id != 0) {

          if (item.departmentId != null && item.departmentId != this.selectedDepartment.id) {
            add = false;
          }
        }


        if(this.selectedDate) {
          const itemExpireTime = new Date(this.datePipe.transform(item.expireDate, 'yyyy-MM-dd')).getTime();
          const selectedDateTime = new Date(this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd')).getTime();
          if(itemExpireTime != selectedDateTime) add = false;
        }

        if (add) {
          if (item.mimeType == 'folder') {
            this.fileService.add({ name: item.fileName, isFolder: true, parent: parentId, intranetDocument: item });
          } else if (item.mimeType == 'file') {
            this.fileService.add({ name: item.fileName, isFolder: false, parent: parentId, intranetDocument: item });
          }
        }


      })

      this.updateFileElementQuery();

    })

  }

  addFolder(folder: { name: string }) {
    // this.fileService.add({ isFolder: true, name: folder.name, parent: this.currentRoot.id ? this.currentRoot.id : 0 });

    this.refreshDocuments();

  }

  removeElement(element: FileElement) {
    this.fileService.delete(element.id);
    this.updateFileElementQuery();
  }

  navigateToFolder(element: FileElement) {

    this.parentRoot = this.currentRoot;

    this.intranetDocumentService.get(element.intranetDocument.id).subscribe(res => {

      this.currentRoot = res;

      this.getDocumentsByParentId(this.currentRoot.id)

      this.currentPath = this.pushToPath(this.currentPath, element.name);
      this.canNavigateUp = true;


    })


  }

  navigateUp() {

    if (this.parentRoot == null) {

      this.getParentDocuments();

      this.currentRoot = null;
      this.canNavigateUp = false;

    } else {

      if (this.parentRoot && this.parentRoot.parentDocumentId && this.parentRoot.parentDocumentId != 0) {

        this.intranetDocumentService.get(this.parentRoot.parentDocumentId).subscribe(res => {

          this.currentRoot = res;

          if (this.currentRoot.parentDocumentId) {
            this.intranetDocumentService.get(this.currentRoot.parentDocumentId).subscribe(res => {

              this.parentRoot = res;

            })
          } else {
            this.parentRoot = null;
          }

          this.getDocumentsByParentId(this.currentRoot.id);

        })

      } else {

        this.getParentDocuments();

        this.currentRoot = null;
        this.parentRoot = null;
        this.canNavigateUp = false;

      }
    }

    this.currentPath = this.popFromPath(this.currentPath);
  }

  moveElement(event: { element: FileElement; moveTo: FileElement }) {
    //this.fileService.update(event.element.id, { parent: event.moveTo.id });
    this.updateFileElementQuery();
  }

  renameElement(element: FileElement) {
    console.log(element);
    this.fileService.update(element.id, { name: element.name });
    this.updateFileElementQuery();
  }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 0);
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

}
