import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Announcement } from 'src/app/models/announcement.model';
import { Career } from 'src/app/models/career.model';
import { CareerService } from 'src/app/services/career.service';

@Component({
  selector: 'app-list-career-manager',
  templateUrl: './list-career-manager.component.html',
  styleUrls: ['./list-career-manager.component.scss']
})
export class ListCareerManagerComponent implements OnInit {

  entities: Career[] = [];

  selectedAdvances: Career[] = [];

  constructor(private careerServşce: CareerService, private messageService: MessageService,
    private router: Router) { }

  ngOnInit(): void {

    this.getCareers();

  }

  getCareers() {


    this.careerServşce.getAllCareers().subscribe(res => {
      this.entities = res;
    })
  }

  navigateDetail(entity: any) {

    this.router.navigate(["career/career-detail/" + entity.id]);

  }

  navigateEdit(entity: any) {

    this.router.navigate(["career/update/" + entity.id]);

  }

  delete(entity: any) {

    this.careerServşce.delete(entity.id).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "İlan Silindi" });
      this.getCareers();
    })

  }

}
