import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {MessageService} from 'primeng/api';
import {tap} from "rxjs/operators";

export const getToken = (transportDirection: string = null): any => {
    if (transportDirection == 'I') {
        if (localStorage.getItem('import')) {
            return JSON.parse(localStorage.getItem('import'));
        }
        return getMainToken();
    }
    if (transportDirection == 'E') {
        if (localStorage.getItem('export')) {
            return JSON.parse(localStorage.getItem('export'));
        }
        return getMainToken();
    }
    if (transportDirection == 'orc') {
        if (localStorage.getItem('orc')) {
            return JSON.parse(localStorage.getItem('orc'));
        }
        return getMainToken();
    }
    return getMainToken();
};

export const getMainToken: any = () => {
    if (!localStorage.getItem('token') || !localStorage.getItem('sessionId')) {
        return null;
    }
    return {token: localStorage.getItem('token'), sessionId: localStorage.getItem('sessionId')};
};

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    // Todo Import ve Export pathleri yazılacak.
    // İlgili endpointlerin  transportDirection query parametersi  olmalı.
    importRouteUrl = ['/activities/list-allocation', '/activities/offer-request', 'activities/offer-request/activity', '/activities/list-offer-request', '/activities/air-offer-create', '/activities/list-air-offer', '/activities/air-offer', '/activities/customers/edit/I', '/activities/air-offer-detail', '/activities/offer/list/import', 'activities/import/offer-request-partial', '/import/list-offer-request-partial'];


    exportRouteUrl = ['activities/export', '/activities/offer-export-detail', '/activities/offer/list/export', '/activities/offer/create', '/activities/customers/edit/E', 'activities/export/offer-request-partial', '/export/list-offer-request-partial'];

    constructor(public auth: AuthenticationService, private router: Router, private messageService: MessageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // /assets/tr.json vb url için.
        let requestHandle: Observable<HttpEvent<any>>;
        if (!this.ValidURL(request.url)) {
            return next.handle(request);
        }

        let transportDirectionInUrl =  new URL(request.url).searchParams.get('transportDirection')


        /* request url deki transportDirection varmı kontrol edilirken queryparams olmayan request deki url kontrol edilmiş bu yüzden request e gönderilen transportDirection bulunamıyordu.
           importRouteUrl ve exportRouteUrl içinde bulunan bir path den request atıldığında transportDirection yokmuş gibi varsayılıyordu düzeltme başka bir soruna neden olmaması için farklı farklı route üzerinden request atılabilecek path olan adres kart oluşturma pathi üzerinde istisna uygulandı.
           
        */
   
        if(request.url.includes('activity/createCompany') || request.url.includes('createCompanyFinancialByRegisterNo')) {
            transportDirectionInUrl =  new URL(request.urlWithParams).searchParams.get('transportDirection') 
        }
        
        
        const token = getToken();
        const reqUrl = new URL(request.url);
        if (!token) {
           return next.handle(request);
        }


        if(!transportDirectionInUrl) {
            // Import domain.
            if (this.importRouteUrl.some(el => this.router.url.toString().includes(el))) {                
                console.log("import girdi")
                requestHandle = next.handle(this.setTransportDirection(request, reqUrl, getToken('I'), 'I'));
            }

            // Export domain.
            if (this.exportRouteUrl.some(el => this.router.url.toString().includes(el))) {
                console.log("export girdi")
                requestHandle = next.handle(this.setTransportDirection(request, reqUrl, getToken('E'), 'E'));
            }
        } else {
            requestHandle = next.handle(this.setTransportDirection(request, reqUrl, getToken(transportDirectionInUrl), transportDirectionInUrl));
        }


        if (!requestHandle) {
            requestHandle = next.handle(request);
        }

        return requestHandle.pipe(tap(response => {}, err => {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: err?.error?.message ? err?.error?.message : JSON.parse(err?.error)?.message });
            let msg = err?.error.message || err.message;
            if (msg.includes('Session')) {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Giriş Ekranına Yönlendiriliyorsunuz!' });
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem("export");
                localStorage.removeItem("import");
                localStorage.removeItem("menu");
                localStorage.removeItem("orc");

                setTimeout(() => {
                    this.router.navigate([`login`]);
                }, 3000);
            }
            return throwError(err);
        }));
    }

    setTransportDirection(request: HttpRequest<any>, reqUrl: URL, token: any, transportDirection?: string) {
        let newRequest: HttpRequest<any>;
        reqUrl.searchParams.delete('token');
        reqUrl.searchParams.delete('sessionId');
        reqUrl.searchParams.delete('transportDirection');
        newRequest = request.clone({
            url: reqUrl.toString(),
            setParams: {
                token: token.token,
                sessionId: token.sessionId,
                transportDirection: transportDirection
            }
        });
        return newRequest;
    }

    ValidURL(str) {
        var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            return false;
        } else {
            return true;
        }
    }
}

