import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { IntranetPage } from 'src/app/models/intranet-page.model';
import { IntranetPageService } from 'src/app/services/intranet-page.service';


@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss']
})
export class CreatePageComponent implements OnInit {

  private basePath = '/uploads/pages';

  uploadedFiles: any[] = [];

  entity: IntranetPage = <IntranetPage>{};

  descriptionNames: any[] = [];

  selectedDescriptionName: any;

  editorText: string;

  shortMessage: string = "";

  pageName: string = "";

  constructor(private messageService: MessageService, private storage: AngularFireStorage, private intranetPageService: IntranetPageService) { }
  ngOnInit(): void {

  }

  onUpload(event) {

    this.uploadedFiles = [];

    var file = event.files[0];

    this.uploadedFiles.push(file);

    const filePath = `${this.basePath}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);


    this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {

      if (res.state == 'success') {

        storageRef.getDownloadURL().subscribe(url => {

          this.entity.logoUrl = url;

          this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

          event.files = [];

        });

      }

    });




  }


  isLoading = false;

  save() {

    this.isLoading = true;

    this.entity.pageName = this.pageName;

    this.entity.header = this.shortMessage;

    this.entity.body = this.editorText;


    this.intranetPageService.create(this.entity).subscribe(res => {
      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Sayfa Başarıyla Kaydedildi" });
    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })



  }


}
