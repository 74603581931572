import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Leave } from 'src/app/models/leave-models/leave.model';
import { IntranetLeaveService } from 'src/app/services/leave-service';

@Component({
  selector: 'app-my-leave-requests',
  templateUrl: './my-leave-requests.component.html',
  styleUrls: ['./my-leave-requests.component.scss']
})
export class MyLeaveRequestsComponent implements OnInit {

  advances: Leave[] = [];

  selectedAdvances: Leave[] = [];

  constructor(private leaveService: IntranetLeaveService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.getMyLeaves();

  }

  getMyLeaves() {
    this.leaveService.getLeavesByUserId().subscribe(res => {
      this.advances = res;
    })
  }

  cancelAdvance(advance: Leave) {

    this.leaveService.get(advance.id).subscribe(res => {

      var realAdvance = res;
      realAdvance.status = 3;

      this.leaveService.update(realAdvance).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep İptal Edildi' });
        this.getMyLeaves();
      })

    })

  }

}
