import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QdmsFile } from 'src/app/models/qdms/file-model';
import {ConfirmationService, MessageService} from 'primeng/api';
import { QdmsService } from 'src/app/services/qdms.service';
import EntityUtil from '../../../models/framework/utils/entity-util';
import {Folder} from "../../../models/qdms/folder-model";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss']
})
export class FileDialogComponent implements OnInit {

  public qdmsFile: QdmsFile = new QdmsFile();
  public selectedFolderId: number = null;
  public selectedFile: File = null;
  public isLoading: boolean = false;
  public folders: Folder[] = [];
  public filteredFolder: Folder[] = [];
  public uploadedFiles: QdmsFile[] = [];
  clearUploadFileEmitter = new Subject<boolean>();


  constructor( public dialogRef: MatDialogRef<FileDialogComponent>,
     @Inject(MAT_DIALOG_DATA) private data: any,
     private confirmService: ConfirmationService,
     private messageService: MessageService,
     private service: QdmsService) {

      this.selectedFolderId = Number(this.data.selectedFolderId);

      // revision file
      if(this.data.file) {
        this.qdmsFile.name = this.data.file.name
        this.qdmsFile.documentCode = this.data.file.documentCode
        this.qdmsFile.revisionDate = new Date();
        this.qdmsFile.revizedFile = EntityUtil.toSimple((this.data.file as QdmsFile).revizedFile || this.data.file);
      }

      this.service.getFolder(this.selectedFolderId).subscribe(f => {
        this.qdmsFile.folder = EntityUtil.toSimple(f);
        this.folders.push(f);
        this.service.getChildFolders(this.selectedFolderId).subscribe(sf => this.folders = this.folders.concat(sf));
      });
  }


  ngOnInit(): void {
  }

  onSelectedFile(selectedFile: File) {
    this.selectedFile = selectedFile as File;
    this.qdmsFile.fileExtension = this.selectedFile.name.split(".").pop()
  }

  onUpload() {
    if (!this.selectedFile) {
      this.messageService.add({ severity: 'warn', summary: 'Dosya seçiniz', detail: '' });
      return;
    }
    if (!this.qdmsFile.documentCode) {
      this.messageService.add({ severity: 'warn', summary: 'Doküman numarası boş bırakılmamalıdır.', detail: '' });
      return;
    }

    if (!this.qdmsFile.name) {
      this.messageService.add({ severity: 'warn', summary: 'Dosya adı boş bırakılmamalıdır.', detail: '' });
      return;
    }

    this.isLoading = true;
    this.service.upload(this.selectedFile, this.selectedFolderId, this.qdmsFile).subscribe(s => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Dosya yüklendi', detail: '' });
      this.qdmsFile.name = null
      this.qdmsFile.documentCode = null;
      this.qdmsFile.revisionDate = null;
      this.qdmsFile.revizedFile = null;
      this.selectedFile = null;

      this.clearUploadFileEmitter.next(true);
      this.uploadedFiles.push(s);

    }, (err) => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: err.error.message});
    });
  }

  filterFolder(event) {
    if (event.query.lenght < 3) return;
    this.filteredFolder = this.folders.filter(f => f.name.includes(event.query));
  }

  deleteFile(qdmsFile: QdmsFile, index) {
    this.confirmService.confirm({
      message: 'Silmek istediğinize emin misiniz?',
      accept: () => {
        this.isLoading = true;
        this.service.deleteFile(qdmsFile).subscribe(() => {
          this.messageService.add({severity: 'success', summary: 'Başarılı', detail: "Klasör silindi." });
          this.uploadedFiles.splice(index, 1);
        }, (err) => {
          this.isLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Hata', detail: err.error.message });
        })

      }
    })
  }
}
