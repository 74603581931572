import { Component, OnInit } from '@angular/core';
import { Announcement } from 'src/app/models/announcement.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { EventService } from '../../demo/service/eventservice';

@Component({
    templateUrl: './app.calendar.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .fc-header-toolbar {
                display: flex;
                flex-wrap: wrap;
            }
        }
    `]
})
export class AppCalendarComponent implements OnInit {

    events: any[];

    options: any;

    header: any;

    eventDialog: boolean;

    changedEvent: any;

    clickedEvent = null;

    fromDate: Date;

    toDate: Date;

    announcements: Announcement[] = [];

    constructor(private eventService: EventService, private announcementService: AnnouncementService) {
    }

    ngOnInit() {
        this.eventService.getEventsByDate(this.fromDate, this.toDate).subscribe(events => {
            console.log(events.body);
            this.options = { ...this.options, ...{ events: events.body } };
        });
        this.options = {
            headerToolbar: {
                left: 'prev next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            // datesRender: (info) => {
            //     console.log(info);
            //     this.fromDate = info.view.activeStart;
            //     this.toDate = info.view.activeEnd;
            //     this.eventService.getEventsByDate(info.view.activeStart, info.view.activeEnd).subscribe(events => {
            //         console.log(JSON.stringify(events.body.data));
            //         this.options = {...this.options, ...{events: events.body.data}};
            //     });
            //
            // },
            eventClick: (e) => {
                this.eventDialog = true;

                this.clickedEvent = e.event;

                this.changedEvent.title = this.clickedEvent.title;
                this.changedEvent.start = this.clickedEvent.start;
                this.changedEvent.end = this.clickedEvent.end;
            }
        };

        this.changedEvent = { title: '', start: null, end: '', allDay: null };
    }

    save() {
        this.eventDialog = false;

        this.clickedEvent.setProp('title', this.changedEvent.title);
        this.clickedEvent.setStart(this.changedEvent.start);
        this.clickedEvent.setEnd(this.changedEvent.end);
        this.clickedEvent.setAllDay(this.changedEvent.allDay);

        this.changedEvent = { title: '', start: null, end: '', allDay: null };
    }

    reset() {
        this.changedEvent.title = this.clickedEvent.title;
        this.changedEvent.start = this.clickedEvent.start;
        this.changedEvent.end = this.clickedEvent.end;
    }
}
