import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IntranetAdvance } from '../models/intranet-advance.model';
import { IntranetDocument } from '../models/intranet-document.model';
import { IntranetPage } from '../models/intranet-page.model';
import { IntranetWidget } from '../models/intranet-widget.model';
import { Leave } from '../models/leave-models/leave.model';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';


@Injectable()
export class IntranetLeaveService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'leave',
            httpClient);
    }

    approve(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/approve?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    disApprove(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/disApprove?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }


    get(id: number): Observable<Leave> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getById?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Leave>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getUserById(id: number): Observable<Leave> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getUserById?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Leave>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    delete(id: number): Observable<boolean> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/deleteById?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<boolean>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    create(document: Leave): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: document
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createLeave";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    update(obj: Leave): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity = {
            token: token,
            sessionId: sessionId,
            document: obj
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateLeave";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getLeavesByUserId() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getLeavesByUserId?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<Leave[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getApprovalLeaves() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/getApprovalLeaves?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<Leave[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    calculateUserLeaveClaims(): Observable<number> {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/calculateUserLeaveClaims?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<number>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    calculateAvailableLeaveClaims(): Observable<number> {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/calculateAvailableLeaveClaims?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<number>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    totalBusinessDaysBetween(startDate: number, endDate: number): Observable<number> {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem('user'));

        var url = environment.baseUrl + "/" + this.endpoint + "/totalBusinessDaysBetween?startDate=" + startDate + "&endDate=" + endDate;

        return this.httpClient.get<number>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllLeaves() {
        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllLeaves?token=" + token + "&sessionId=" + sessionId;;

        return this.httpClient.get<Leave[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getPublicHolidays() {

        var url = environment.baseUrl + "/" + this.endpoint + "/getPublicHolidays";

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
}
