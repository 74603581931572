import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  menuMode = 'horizontal';

  darkMode = 'light';

  topbarTheme = 'light';

  menuTheme = 'light';

  inputStyle = 'outlined';

  ripple: boolean;

  constructor(private primengConfig: PrimeNGConfig, private translate: TranslateService) {
      translate.addLangs(['en', 'tr']);
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|tr/) ? browserLang : 'en');
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
