import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExpensePackage, ExpensePackageItem } from 'src/app/models/expense.model';
import { ExpenseService } from 'src/app/services/expense.service';

@Component({
  selector: 'app-approval-expense-packages',
  templateUrl: './approval-expense-packages.component.html',
  styleUrls: ['./approval-expense-packages.component.scss']
})
export class ApprovalExpensePackagesComponent implements OnInit {

  expences: ExpensePackage[] = [];

  selectedExpences: ExpensePackage[] = [];

  constructor(private expenseService: ExpenseService, private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {

    this.getMyExpences();

  }

  getMyExpences() {
    this.expenseService.getApprovalEntities().subscribe(res => {
      this.expences = res;
      console.log(res);
    })
  }

  cancelExpense(advance: ExpensePackage) {

    this.expenseService.getExpensePackage(advance.id).subscribe(res => {

      var realAdvance = res;
      realAdvance.state = 3;

      this.expenseService.updateExpensePackage(realAdvance).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep İptal Edildi' });
        this.getMyExpences();
      })

    })

  }

  navigateApprovePage(expense: ExpensePackage) {

    this.router.navigate(['expense/expense-package-approve/' + expense.id])

  }

}
