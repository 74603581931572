import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { Leave } from 'src/app/models/leave-models/leave.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';
import { IntranetLeaveService } from 'src/app/services/leave-service';

@Component({
  selector: 'app-create-leave',
  templateUrl: './create-leave.component.html',
  styleUrls: ['./create-leave.component.scss']
})
export class CreateLeaveComponent implements OnInit {

  model: Leave = <Leave>{};

  leaveTypes: any[] = [];

  selectedLeaveTypes: any;

  advanceUseDate: Date = new Date();

  userLeaveClaimDays: number = 0;

  userAvailableLeaveClaimDays: number = 0;

  canBeUseLeaves: number = 0;

  leaveStartDate: Date = new Date();

  jobBeginDate: Date = new Date();

  leavesDuration: number = 0;

  constructor(private messageService: MessageService, private advanceService: IntranetAdvanceService, private leaveService: IntranetLeaveService) { }

  ngOnInit(): void {

    this.leaveTypes.push({
      label: 'İzin Tipi Seçiniz',
      value: 0
    })

    this.leaveTypes.push({
      label: 'Yıllık Ücretli İzin',
      value: 1
    })

    this.leaveTypes.push({
      label: 'Ücretsiz İzin',
      value: 2
    })

    this.leaveTypes.push({
      label: 'Diğer İzin',
      value: 3
    })

    this.leaveTypes.push({
      label: 'Doğum İzni',
      value: 4
    })

    this.leaveTypes.push({
      label: 'Mazeret İzni',
      value: 4
    })

    this.leaveService.calculateUserLeaveClaims().subscribe(res => {
      this.userLeaveClaimDays = res;
    })

    this.leaveService.calculateAvailableLeaveClaims().subscribe(res => {
      this.userAvailableLeaveClaimDays = res;
    })

  }

  dateChange(event: any) {

    this.leaveService.totalBusinessDaysBetween(this.leaveStartDate.getTime(), this.jobBeginDate.getTime()).subscribe(res => {
      this.leavesDuration = res;
    })

  }

  isLoading = false;

  save() {

    if (!this.selectedLeaveTypes) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'İzin tipi boş geçilemez' });
      return;
    }

    if (this.leavesDuration == 0) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Talep edilen izin günü 0 olamaz' });
      return;
    }

    if (this.leavesDuration > this.userAvailableLeaveClaimDays) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Talep edilen izin günü kullanılabilir izin gününden büyük olamaz' });
      return;
    }

    this.model.leaveStartDate = this.leaveStartDate;
    this.model.leaveEndDate = this.jobBeginDate;
    this.model.duration = this.leavesDuration;
    this.model.status = 0;

    this.isLoading = true;

    this.model.leaveType = this.selectedLeaveTypes;

    var user = JSON.parse(localStorage.getItem('user'));

    this.model.userId = user.id;

    this.leaveService.create(this.model).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Gönderildi' });
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

}
