import {Component, OnInit, ViewChild} from '@angular/core';

import {DatePipe, Location} from '@angular/common';

import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {MatDialog} from '@angular/material/dialog';

import { CountryMinimizedModel } from 'src/app/models/country-minimized.model';
import { SelectBoxItem } from 'src/app/models/select-box-item.model';
import { GoodDefinationMinimized } from 'src/app/models/good-defination-minimized';
import { MinDTO } from 'src/app/models/min-dto.model';
import { getbaseUrl } from 'ngx-doc-viewer';
import b64toBlob from "b64-to-blob";
import {Incoterms} from "../../../../../models/enums/incoterms";
import {Activity} from "../../../../../models/activity.model";
import {Company} from "../../../../../models/company.model";
import {DistrictMinimizedModel} from "../../../../../models/district-minimized.model";
import {CityMinimizedModel} from "../../../../../models/city-minimized.model";
import {SimpleEntity} from "../../../../../models/simple-entity.model";
import {CompanyService} from "../../../../../services/company.service";
import {OfferExportService} from "../../../../../services/offer-export.service";
import {SelectboxService} from "../../../../../services/selectbox.service";
import {PersonService} from "../../../../../services/person.service";
import {ActivityService} from "../../../../../services/activity.service";
import {OfferPackMobileModel} from "../../../../../models/offer-pack-mobile.model";
import {UpdateOfferStatusDTO} from "../../../../../models/update-offer-status-dto.model";



export class reservationModel {
  senderId: MinDTO;
  consigneeId: MinDTO;
  operationNote: string;
  operationRepresentativeId: MinDTO;
  salesChannel: string;
  freightPayment: string;
  whoseBusiness: string;
  abroadAgentId: MinDTO;
  supplierId: MinDTO;
  offerTruckDetailId: number;
}

@Component({
  selector: 'app-truck-offer-export',
  templateUrl: './truck-offer-export.component.html',
  styleUrls: ['./truck-offer-export.component.scss']
})
export class TruckOfferExportComponent implements OnInit {

  @ViewChild('fubauto') uploader: any;

  isLoading = false;
  isStackable: any[] = [{code: 'YES', name: 'true'}, {code: 'NO', name: 'false'}]
  stateOptions = [
    {label: 'offerRequest', value: 'offerRequest'},
    {label: 'priceInformation', value: 'price', disabled: true},
    {label: 'reservation', value: 'reservation', disabled: true}
  ];
  serviceCodeType: any[];
  activeTab = 'offerRequest';
  incoterms: string[] = Object.values(Incoterms)

  offerTruckActivityMobile: Activity = new Activity();
  filteredCompanies: Company[] = [];
  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  filteredCityMinimized: CityMinimizedModel[] = [];
  filteredCountryMinimized: CountryMinimizedModel[] = [];
  filteredWarehouseMinimized: SimpleEntity[] = [];
  filteredGoodDefinationType:GoodDefinationMinimized[] = [];
  filteredTruckBodyType: SimpleEntity[];
  userTransportDirection: any;
  reservation: reservationModel = new reservationModel();
  isReservation = false;
  operationRepresentativeList: any[];
  offerTypes: any[] = [{name: 'Baremli Teklif', code: 'BAREM_CHARGE'}, {name: 'Tek Fiyat', code: 'UNIQUE_CHARGE'}]
  isCreatedActivity: boolean = false;
  value2 = '';
  requestSucceed = false;
  saved = false;
  isInvoiceItem: any;
  transporterNumber: any;
  sellOrBuyInvoice = false;

  showReservationConfirmDialog: boolean = false;
  reservationDetail: any[] = [];

  invoice: any;
  invoiceCurrency: any;
  invoiceAmount = 0;
  invoiceBuyamount = 0;
  isFreightCharge = false;
  itemCompany: any;
  invoiceType = 'IN';

  clickCount = 0;
  offerStatusHistory: any[] = [];
  showStatusHistory = false;

  mailFrom = '';
  reportList: any = [];
  file: any;
  digitalStore: any = [];
  report: any;
  offerSubStatuse: any;
  rejectReason: any;
  displayMail = false;

  packing = {
    name: '',
    json: ''
  };

  validationFields = [{code:'customer',value:'Müşteri'},{code:'loadCountry',value:'Yükleme Ülke'},{code:'loadCity',value:'Yükleme Şehri'},
    {code:'unloadCity', value:'Boşaltma Şehri'},{code:'partialFull', value:'K - P'},{code:'goodDefinationType', value:'Mal Cinsi'},
    {code:'transportDirection', value:'Taşıma Yönü'}, {code:'loadAddress', value:'Yükleme Adres'}, {code:'unloadAddress', value:'Boşaltma Adres'}];

  reservationValidationFields = [{code:'senderId',value:'Gönderici'},{code:'consigneeId',value:'Alıcı'}, {code:'operationRepresentativeId',value:'Operasyon Temsilcisi'},
    {code:'salesChannel', value:'Satış Kanalı'},{code:'freightPayment', value:'Navlun Ödeme'},{code:'whoseBusiness', value:'Kimin işi'}];

  invoiceTypes: any[] = [{value: 'CN', label: 'Alacak Dekontu'}, {value: 'DN', label: 'Borç Dekontu'}, {value: 'IN', label: 'Fatura'}, {value: 'RI', label: 'İade Fatura'}, {value: 'IR', label: 'İç Dekont'}, {value: 'OC', label: 'Operasyon Gider'}];

  countType: any[] = [{json: 'perContainer', name: 'Konteyner Başı'}, {json: 'perHouse', name: 'Yük Başı'}, {json: 'WM', name: 'WM'}, {json: 'per20', name: '20 lik'}, {json: 'per40', name: '40 lık'}, {json: 'perMaster', name: 'Master Başı'}, {json: 'freightSalesPercent', name: 'Navlun Satış Yüzdesi'}];
  count: any[] = [{json: 'perContainer', name: 'Konteyner Başı'}, {json: 'perHouse', name: 'Yük Başı'}, {json: 'WM', name: 'WM'}, {json: 'per20', name: '20 lik'}, {json: 'per40', name: '40 lık'}, {json: 'perMaster', name: 'Master Başı'}, {json: 'freightSalesPercent', name: 'Navlun Satış Yüzdesi'}];
  invoiceCountType = this.count[0];

  currencyType: any[];
  currency: any[];

  invoiceItemType: any[];
  invoiceItem: any[];

  offerNameId: any[] = [];

  visibleAllocationDialog: boolean = false;
  selectedActivity: any = null;
  rejectSubType = null;
  rejectDescription: String;
  selectedOfferPrice: any[] = [];

  sellOrBuyOptions: any[] = [
    { label: 'Alış', value: true },
    { label: 'Satış', value: false }];

  SubTypeText = {pendingConfirm: 'Bekleniyor', confirmed: 'Kabul Edildi', inProgressCompleted: 'Fiyatlandırma Yapıldı', accepted: 'Kabul Edildi', rejected: 'Red Edildi', revisionRequest: 'Revizyon Yapıldı', pricing: 'Fiyatlandırma Bekleniyor'};

  freightPaymentType: any[] = ['CC', 'PP'];
  salesChannel: any[] = [{code: 'ST', name: 'Sales Team'}, {code: 'BD', name: 'BusinessDevelopment'}, {code: 'TM', name: 'Telemarketing'}, {code: 'DM', name: 'Digital Marketing'}, {code: 'NVO', name: 'NVOCC'}];
  businessType: any[] = [{type: 'B', name: 'Bizim'}, {type: 'A', name: 'Acenta'}, {type: 'O', name: 'Ortak'}];

  frontEndLoadingTypes = [{ label: 'address', value: 'ADDRESS' }, { label: 'warehouse', value: 'WAREHOUSE' }, { label: 'port', value: 'PORT' }, { label: 'entrepot', value: 'FRONT' }]
  filterPartialFull = [{name: 'Komple', value: 'F'}, {name: 'Parsiyel', value: 'P'}]


  goodDefinationType: any[] = [{name: 'askılı', json: 'ASKILI'},
    {name: 'Bag', json: 'BAG'},
    {name: 'BAGS', json: 'BAGS'},
    {name: 'BALE', json: 'BALE'},
    {name: 'bale', json: 'BALES'},
    {name: 'barrel', json: 'BARREL'},
    {name: 'bars', json: 'BARS'},
    {name: 'Big bag', json: 'BIG BAG'},
    {name: 'BIG BAGS', json: 'BIG BAGS'},
    {name: 'Bins', json: 'BINS'},
    {name: 'BLOCK', json: 'BLOCK'},
    {name: 'Bolts', json: 'BOLTS'},
    {name: 'BONDS', json: 'BONDS'},
    {name: 'Bottle', json: 'BOTTLES'},
    {name: 'Boxes', json: 'BOXES'},
    {name: 'Bucket', json: 'BUCKETS'},
    {name: 'bulks', json: 'BULKS'},
    {name: 'Bunches', json: 'BUNCHES'},
    {name: 'Bundles', json: 'BUNDLES'},
    {name: 'Cans', json: 'CANS'},
    {name: 'Canvas', json: 'CANVAS'},
    {name: 'cap', json: 'KAP'},
    {name: 'CAPS', json: 'CAPS'},
    {name: 'Cartons', json: 'CARTONS'},
    {name: 'Cases', json: 'CASES'},
    {name: 'coil', json: 'COIL'},
    {name: 'COLLIES', json: 'COLLIES'},
    {name: 'container', json: 'CONTAINER'},
    {name: 'CUPS', json: 'CUPS'},
    {name: 'Drums', json: 'DRUMS'},
    {name: 'euroPallet', json: 'EURO_PALLET'},
    {name: 'Frames', json: 'FRAMES'},
    {name: 'IBC', json: 'IBC'},
    {name: 'Jars', json: 'JARS'},
    {name: 'LOT', json: 'LOT'},
    {name: 'PACKAGE', json: 'PACKAGE'},
    {name: 'packages', json: 'PACKAGES'},
    {name: 'Pails', json: 'PAILS'},
    {name: 'Pairs', json: 'PAIRS'},
    {name: 'PALLET', json: 'PALLET'},
    {name: 'PALLETS', json: 'PALLETS'},
    {name: 'Parcels', json: 'PARCELS'},
    {name: 'Parcels', json: 'Parcels'},
    {name: 'Pieces', json: 'PIECES'},
    {name: 'Pipes', json: 'PIPES'},
    {name: 'PK', json: 'PK'},
    {name: 'Plates', json: 'PLATES'},
    {name: 'Reels', json: 'REELS'},
    {name: 'Rolls', json: 'ROLLS'},
    {name: 'Sacks', json: 'SACKS'},
    {name: 'SheetMetals', json: 'SHEETMETALS'},
    {name: 'Sheets', json: 'SHEETS'},
    {name: 'Shrink', json: 'SHRINK'},
    {name: 'SLING BAGS', json: 'SLING BAGS'},
    {name: 'Slipsheets', json: 'SLIPSHEETS'},
    {name: 'Tins', json: 'TINS'},
    {name: 'Tubes', json: 'TUBES'},
    {name: 'UNITS', json: 'UNITS'},
    {name: 'usPallet', json: 'us_pallet'},
    {name: 'WOODEN BOX', json: 'WOODEN BOX'},
    {name: 'WOODEN CASES', json: 'WOODEN CASES'},
    {name: 'WOODEN PACKAGES', json: 'WOODEN PACKAGES'},
    {name: 'WOODEN PALLETS', json: 'WOODEN PALLETS'},
    {name: 'Z69R', json: 'Z69R'},
  ];

  goodDefination: any[] = [{name: 'askılı', json: 'ASKILI'},
    {name: 'Bag', json: 'BAG'},
    {name: 'BAGS', json: 'BAGS'},
    {name: 'BALE', json: 'BALE'},
    {name: 'bale', json: 'BALES'},
    {name: 'barrel', json: 'BARREL'},
    {name: 'bars', json: 'BARS'},
    {name: 'Big bag', json: 'BIG BAG'},
    {name: 'BIG BAGS', json: 'BIG BAGS'},
    {name: 'Bins', json: 'BINS'},
    {name: 'BLOCK', json: 'BLOCK'},
    {name: 'Bolts', json: 'BOLTS'},
    {name: 'BONDS', json: 'BONDS'},
    {name: 'Bottle', json: 'BOTTLES'},
    {name: 'Boxes', json: 'BOXES'},
    {name: 'Bucket', json: 'BUCKETS'},
    {name: 'bulks', json: 'BULKS'},
    {name: 'Bunches', json: 'BUNCHES'},
    {name: 'Bundles', json: 'BUNDLES'},
    {name: 'Cans', json: 'CANS'},
    {name: 'Canvas', json: 'CANVAS'},
    {name: 'cap', json: 'KAP'},
    {name: 'CAPS', json: 'CAPS'},
    {name: 'Cartons', json: 'CARTONS'},
    {name: 'Cases', json: 'CASES'},
    {name: 'coil', json: 'COIL'},
    {name: 'COLLIES', json: 'COLLIES'},
    {name: 'container', json: 'CONTAINER'},
    {name: 'CUPS', json: 'CUPS'},
    {name: 'Drums', json: 'DRUMS'},
    {name: 'euroPallet', json: 'EURO_PALLET'},
    {name: 'Frames', json: 'FRAMES'},
    {name: 'IBC', json: 'IBC'},
    {name: 'Jars', json: 'JARS'},
    {name: 'LOT', json: 'LOT'},
    {name: 'PACKAGE', json: 'PACKAGE'},
    {name: 'packages', json: 'PACKAGES'},
    {name: 'Pails', json: 'PAILS'},
    {name: 'Pairs', json: 'PAIRS'},
    {name: 'PALLET', json: 'PALLET'},
    {name: 'PALLETS', json: 'PALLETS'},
    {name: 'Parcels', json: 'PARCELS'},
    {name: 'Parcels', json: 'Parcels'},
    {name: 'Pieces', json: 'PIECES'},
    {name: 'Pipes', json: 'PIPES'},
    {name: 'PK', json: 'PK'},
    {name: 'Plates', json: 'PLATES'},
    {name: 'Reels', json: 'REELS'},
    {name: 'Rolls', json: 'ROLLS'},
    {name: 'Sacks', json: 'SACKS'},
    {name: 'SheetMetals', json: 'SHEETMETALS'},
    {name: 'Sheets', json: 'SHEETS'},
    {name: 'Shrink', json: 'SHRINK'},
    {name: 'SLING BAGS', json: 'SLING BAGS'},
    {name: 'Slipsheets', json: 'SLIPSHEETS'},
    {name: 'Tins', json: 'TINS'},
    {name: 'Tubes', json: 'TUBES'},
    {name: 'UNITS', json: 'UNITS'},
    {name: 'usPallet', json: 'us_pallet'},
    {name: 'WOODEN BOX', json: 'WOODEN BOX'},
    {name: 'WOODEN CASES', json: 'WOODEN CASES'},
    {name: 'WOODEN PACKAGES', json: 'WOODEN PACKAGES'},
    {name: 'WOODEN PALLETS', json: 'WOODEN PALLETS'},
    {name: 'Z69R', json: 'Z69R'},
  ];

  constructor(private companyService: CompanyService, private offerExportService: OfferExportService, private datePipe: DatePipe,
              private selectBoxService: SelectboxService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private location: Location,
              public datepipe: DatePipe,
              private dialog: MatDialog,
              private personService: PersonService,
              private activityService: ActivityService,
              private router: Router) {
    this.activeTab = 'offerRequest';

  }

  async ngOnInit(): Promise<void> {
    if(this.route.snapshot.params.id){
      this.activityService.getMyActivityById(this.route.snapshot.params.id, 'E').subscribe(activity=>{
        activity.endDate = new Date(activity.endDate);
        activity.etd = new Date(activity.etd)
        this.isCreatedActivity = true;
        this.offerTruckActivityMobile = activity;
        this.offerNameId.push(activity);
        this.reservationDetail = activity.incoOfferTruckDTOs[0].truckGoods;

        if(this.reservationDetail && this.reservationDetail.length > 0) {
          const lastSeaGood = this.reservationDetail[this.reservationDetail.length - 1];
          this.reservation.abroadAgentId = lastSeaGood.abroadAgentId;
          this.reservation.senderId = lastSeaGood.senderId;
          this.reservation.consigneeId = lastSeaGood.consigneId;
          this.reservation.freightPayment = lastSeaGood.freightPayment;
          this.reservation.operationNote = lastSeaGood.operationNote;
          this.reservation.operationRepresentativeId = lastSeaGood.operationRepresentativeId;
          this.reservation.salesChannel = lastSeaGood.salesChannel;
          this.reservation.whoseBusiness = lastSeaGood.whoseBusiness;
        }

        if (['onlinequote', 'inProgressCompleted', 'confirmed', 'accepted'].includes(this.offerTruckActivityMobile?.offerStatus)) {
          this.activePriceButton();
          if (['confirmed', 'accepted'].includes(this.offerTruckActivityMobile?.offerStatus)) {
            this.activeRezervationButton();
          }
        }
      })
    }
    this.addGoodPack();
    this.getGoodDefinations();


    this.serviceCodeType = await this.selectBoxService.getItemByBoxName('truckServiceType').toPromise();
  }


  activeRezervationButton() {
    // Tab Button durumu güncelle.
    this.stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'priceInformation', value: 'price', disabled:  false},
      {label: 'reservation', value: 'reservation', disabled:  ['confirmed', 'accepted', null].includes(this.offerTruckActivityMobile?.offerStatus) ? false : true}
    ];
    console.log(this.stateOptions);
  }

  activePriceButton() {
    // Tab Button durumu güncelle.
    this.stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'priceInformation', value: 'price', disabled:  [undefined, 'onlinequote', 'inProgressCompleted', 'confirmed', 'accepted'].includes(this.offerTruckActivityMobile?.offerStatus) ? false : true},
      {label: 'reservation', value: 'reservation', disabled:  true}
    ];
    console.log(this.stateOptions);
  }

  addLabels(label: any) {
    this.stateOptions.push(label);
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }

  filterCountry(event) {
    if (event.query.length > 2) {

      this.offerExportService.getOfferExportCountry(event.query).subscribe(res => {
        this.filteredCountryMinimized = res.body;
      });
    }
  }

  filterWarehouse(event) {
    if (event.query.length > 2) {

      this.activityService.getfromWarehouse(event.query).subscribe(res => {
        this.filteredWarehouseMinimized = res.body;
      });
    }
  }

  filterCity(event, countryId?: any) {
    this.offerExportService.getOfferExportCity(event.query, countryId).subscribe(res => {
      this.filteredCityMinimized = res.body;
    });
  }


  filterDistrict(event, cityId?: any) {
    console.log(event)
    if (event?.query?.length > 2) {
      this.offerExportService.getOfferExportDistrict(event.query, cityId, this.userTransportDirection).subscribe(res => {
        this.filteredDistrictMinimized = res.body;
      });
    }
  }

  filterGoodDefinationType(event){
    if (event?.query?.length > 2) {
      this.offerExportService.filterGoodDefinationType(event.query).subscribe(res => {
        this.filteredGoodDefinationType = res.body;
      });
    }
  }

  setCityandCountry(){
    if(this.offerTruckActivityMobile.loadDistrict && !this.offerTruckActivityMobile.loadCity){
      this.offerTruckActivityMobile.loadCity = this.offerTruckActivityMobile.loadDistrict.city;
      let country:any = this.offerTruckActivityMobile.loadDistrict.country
      this.offerTruckActivityMobile.loadCountry = country;
    }
    if(this.offerTruckActivityMobile.unloadDistrict && !this.offerTruckActivityMobile.unloadCity){
      this.offerTruckActivityMobile.unloadCity = this.offerTruckActivityMobile.unloadDistrict.city;
      let country:any = this.offerTruckActivityMobile.unloadDistrict.country
      this.offerTruckActivityMobile.unloadCountry = country;
      console.log(this.offerTruckActivityMobile.unloadCountry)
    }
  }

  filterTruckBodyType(event){
    if (event.query.length > 1) {
      this.offerExportService.searchTruckBodyType(event.query, this.userTransportDirection).subscribe(res => {
        this.filteredTruckBodyType = res.body;
      });
    }
  }

  filterCountType(event) {
    const filtered: any[] = [];
    const query = event.query;
    this.count.forEach(country => {
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    });
    this.countType = filtered;
  }

  filterCurrency(event) {
    this.offerExportService.getAllCurrency().subscribe(res => {
      this.currencyType = res.body;
      this.currency = res.body;
      const filtered: any[] = [];
      const query = event.query;
      this.currency.forEach(country => {
        if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
          filtered.push(country);
        }
      });
      this.currencyType = filtered;
    });
  }

  filterInvoiceItem(event) {
    this.offerExportService.invoiceItemType('TRUCK',this.offerTruckActivityMobile.transportDirection).subscribe(res => {
      this.invoiceItemType = res.body;
      this.invoiceItem = res.body;
      const filtered: any[] = [];
      const query = event.query;
      this.invoiceItem.forEach(country => {
        if (country.name.toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) === 0) {
          filtered.push(country);
        }
      });
      this.invoiceItemType = filtered;
    });
  }

  filterUser(event) {
    this.offerExportService.getUserWithName(event.query, this.userTransportDirection).subscribe(res => {
      this.operationRepresentativeList = res.body;
    });
  }

  addGoodPack() {
      if (this.offerTruckActivityMobile.offerPacks == null){
          this.offerTruckActivityMobile.offerPacks = [];
      }
    this.offerTruckActivityMobile.offerPacks.push(this.newGoodPack());
  }

  newGoodPack() {
    const pack = new OfferPackMobileModel();
    pack.quantity = 0;
    pack.height = 0;
    pack.width = 0;
    pack.length = 0;
    pack.grossWeight = 0;
    pack.volume = 0;
    pack.loadingMeter = 0;
    return pack;

  }
  getGoodDefinations(){
    this.offerExportService.filterGoodDefinationType('E').subscribe(res => {
      this.filteredGoodDefinationType = res.body;
    });
  }


  removeGoodPack(index) {
    this.offerTruckActivityMobile.offerPacks.splice(index, 1);
  }

  createActivity(){
    if(!this.validation()){return}

    this.isLoading = true;

    this.offerTruckActivityMobile.etd = this.datepipe.transform(this.offerTruckActivityMobile?.etd , 'yyyy-MM-dd HH:mm:ss');
    this.offerTruckActivityMobile.equipments = [];
    this.offerTruckActivityMobile.operationType = 'TRUCK';
    this.offerTruckActivityMobile.delivery = 'PTP';
    this.offerExportService.createActivity(this.offerTruckActivityMobile).subscribe(res => {
      this.offerTruckActivityMobile = res.body;
      this.offerNameId.push(res.body);
      this.saved = true;
      this.requestSucceed = true;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Oluşturuldu' });
      this.router.navigate([`activities/offer-truck-request/activity/${this.offerTruckActivityMobile.id}`]);
    },err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Teklif Oluşturulamadı' });
    })
  }

  validation(){
    let isValid = false;
    if(this.offerTruckActivityMobile.truckBodyType == null && this.offerTruckActivityMobile.partialFull === 'F') {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu Araç Gövde Tipi alan doldurulmalı!'});
      isValid = false;
      return isValid;
    }
    if((this.offerTruckActivityMobile.loadPlace == "WAREHOUSE" && this.offerTruckActivityMobile.loadWareHouse == null) || (this.offerTruckActivityMobile.unloadPlace == "WAREHOUSE" && this.offerTruckActivityMobile.unloadWareHouse == null)) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu Liman / Depo / Antrepo alan doldurulmalı!'});
      isValid = false;
      return isValid;
    }
    for(var field of this.validationFields){
      if(this.offerTruckActivityMobile[field.code] === undefined || this.offerTruckActivityMobile[field.code] === null){
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + field.value.toUpperCase() + ' alan doldurulmalı!'});
        isValid = false;
        break;
      }
      else{
        isValid = true;
      }
    }
    return isValid;
  }

  reservationValidation() {
    let isValid = true;

    for (var field of this.reservationValidationFields){
      if (this.reservation[field.code] == null) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + field.value.toUpperCase() + ' alan doldurulmalı!'});
        isValid = false;
        break;
      }
    }
    return isValid;
  }
  addTruckTariffDetMobiles(transporterNumber: number) {
    if (this.itemCompany?.id && this.itemCompany?.status !== 'A') {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Seçilen müşterinin muhasebe kaydı yoktur!' });
      return ;
    }
    if (this.invoice !== undefined && this.invoice !== '' && this.invoiceCountType !== undefined && this.invoiceCountType.name !== '' && this.invoiceCurrency !== undefined && this.invoiceCurrency?.name !== '') {
      if (this.offerTruckActivityMobile) {
        if (this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckBuyCharges === (null || '') && this.sellOrBuyInvoice === true) {
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckBuyCharges = [];
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckBuyCharges.push({amount: this.invoiceBuyamount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: this.invoiceCurrency, invoiceItemType: this.invoice, isFreightCharge : this.isFreightCharge});
        } else if (this.sellOrBuyInvoice === true) {
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckBuyCharges.push({amount: this.invoiceBuyamount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: this.invoiceCurrency, invoiceItemType: this.invoice, isFreightCharge : this.isFreightCharge});
        }
        if (this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckSaleCharges === (null || '') && this.sellOrBuyInvoice === false) {
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckSaleCharges = [];
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckSaleCharges.push({amount: this.invoiceAmount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name}, invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name}, isFreightCharge : this.isFreightCharge});
        } else if (this.sellOrBuyInvoice === false) {
          this.offerTruckActivityMobile.incoOfferTruckDTOs[transporterNumber].offerTruckSaleCharges.push({amount: this.invoiceAmount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name}, invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name}, isFreightCharge : this.isFreightCharge});
        }
      }
    } else {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Ek masraf eklenemedi!' });
    }
    this.isInvoiceItem = false;
    this.invoice = ''; this.invoiceCurrency = ''; this.invoiceBuyamount = 0; this.invoiceAmount = 0; this.isFreightCharge = false; this.itemCompany = undefined;
  }

  showReservationPreviewDialog() {
    if(this.reservationValidation()){
      this.showReservationConfirmDialog = !this.showReservationConfirmDialog;
    }
  }

  async makeReservation() {
    this.isLoading = true;
    const reservationPayload = {};
    reservationPayload['offerTruckDetailId'] = this.offerTruckActivityMobile.incoOfferTruckDTOs[0]?.offerDetailId;
    console.log(this.offerTruckActivityMobile.incoOfferTruckDTOs[0].offerDetailId)
    console.log(this.offerTruckActivityMobile.incoOfferTruckDTOs[0])
    reservationPayload['consigneId'] = this.reservation?.consigneeId  ? this.reservation?.consigneeId["id"] : null;
    reservationPayload['senderId'] = this.reservation?.senderId ? this.reservation?.senderId["id"] : null
    reservationPayload['abroadAgentId'] = this.reservation?.abroadAgentId ? this.reservation?.abroadAgentId["id"] : null
    reservationPayload['operationRepresentativeId'] = this.reservation?.operationRepresentativeId ? this.reservation?.operationRepresentativeId["id"] : null
    reservationPayload['operationNote'] = this.reservation.operationNote;
    reservationPayload['salesChannel'] = this.reservation.salesChannel;
    reservationPayload['freightPayment'] = this.reservation.freightPayment;
    reservationPayload['whoseBusiness'] = this.reservation.whoseBusiness;
    reservationPayload['offerTruckPackages'] = this.offerTruckActivityMobile?.offerPacks;

    const response = await this.offerExportService.createReservation(reservationPayload, 'E');

    if (response.status === 200) {
      this.isLoading = false;
      this.showReservationConfirmDialog = false;
      this.messageService.add({
        key: 'tst',
        severity: 'success',
        summary: 'Başarılı',
        detail: 'Rezervasyon Başarıyla Kaydedildi'
      });
    } else {
      this.isLoading = false;
      this.messageService.add({
        key: 'tst',
        severity: 'error',
        summary: 'Hata',
        detail: 'İstek gönderilirken hata oldu!'
      });
    }
  }

  updateRequest() {
    this.isLoading = true;
    if (!this.validation()) {
      this.isLoading = false;
      return;
    }
    this.dateFix();

    this.offerExportService.updateActivity(this.offerTruckActivityMobile).subscribe(res => {
      // this.offerExportService.getReportList(res.body?.offerId).subscribe( rp => {
      //     this.reportList = rp.body;
      // });
      res.body.endDate =  new Date(res.body.endDate);
      res.body.etd = new Date(res.body.etd);
      this.offerTruckActivityMobile = res.body;
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Güncellendi' });
    }, error => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
    });
  }

  dateFix(){
    this.offerTruckActivityMobile.etd = this.datepipe.transform((this.offerTruckActivityMobile.etd === (undefined || null || '') ? Date.now() : this.offerTruckActivityMobile.etd), 'yyyy-MM-dd HH:mm:ss')
    this.offerTruckActivityMobile.startDate = this.datepipe.transform((this.offerTruckActivityMobile.startDate === (undefined || null || '') ? Date.now() : this.offerTruckActivityMobile.startDate), 'yyyy-MM-dd HH:mm:ss')
    this.offerTruckActivityMobile.endDate = this.datepipe.transform((this.offerTruckActivityMobile.endDate === (undefined || null || '') ? Date.now() : this.offerTruckActivityMobile.endDate), 'yyyy-MM-dd HH:mm:ss')
  }

  openTheCopy(id: number) {
    window.open(getbaseUrl() + '/activities/offer-truck-request/activity/' + id, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
  }

  changePageReservation(){
    this.stateOptions = [
      {label: 'offerRequest', value: 'offerRequest'},
      {label: 'priceInformation', value: 'price', disabled:  false},
      {label: 'reservation', value: 'reservation', disabled:  false}
    ];
    this.activeTab = 'reservation';
  }

  onClickOffer(offer) {
    console.log(offer)
    this.clickCount++;
    setTimeout(() => {
      if (this.clickCount === 1) {
        const existOfferIndex = this.selectedOfferPrice.findIndex(f => f.offerTruckDetailId == offer.offerTruckDetailId);
        if(existOfferIndex > -1) {
          this.selectedOfferPrice[existOfferIndex].offerStatus = 'rejected';
        } else {
          offer.offerStatus = 'rejected';
          this.selectedOfferPrice.push(offer);
        }
      } else if (this.clickCount === 2) {
        const existOfferIndex = this.selectedOfferPrice.findIndex(f => f.offerTruckDetailId == offer.offerTruckDetailId);
        if(existOfferIndex > -1) {
          this.selectedOfferPrice[existOfferIndex].offerStatus = 'confirmed';
        } else {
          offer.offerStatus = offer.offerStatus == 'inProgressCompleted' ? 'confirmed' : 'inProgressCompleted';
          this.selectedOfferPrice.push(offer);
        }
      }
      this.clickCount = 0;
    }, 250);
  }

  setStatusFunction(updateStatus, offerDetailId) {
    const payloadUpdateStatus = [];
    payloadUpdateStatus.push({
      offerTruckDetailId: offerDetailId,
      status: updateStatus,
      substatus: null,
      description: 'Status update',
    });
    this.offerExportService.updateOfferStatus(payloadUpdateStatus, 'E').subscribe(
        data => {
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
    );
    return payloadUpdateStatus;
  }

  async showStatusStory() {
    for (let index = 0; index < this.offerTruckActivityMobile.incoOfferTruckDTOs.length; index++) {
      const allocatedOffer =  this.offerTruckActivityMobile.incoOfferTruckDTOs[index];
      console.log(this.offerTruckActivityMobile.incoOfferTruckDTOs[index])
      const res = await this.offerExportService.getAllOfferStatusByOfferId(allocatedOffer.offerId, 'E').toPromise();
      this.offerStatusHistory = this.offerStatusHistory.concat(res.body);
    }
    this.showStatusHistory = true;

    if(this.offerStatusHistory.length == 0)   this.messageService.add({ key: 'tst', severity:'warn', summary: 'Uyarı', detail: 'Teklif durumu henüz değişmemiş' });

  }

  filterDetTariff(incoOffer: any) {
    let buyData: any;
    let sellData: any;
    buyData = [];
    sellData = [];

    buyData = this.offerTruckActivityMobile ? incoOffer?.offerTruckBuyCharges : null;
    sellData = this.offerTruckActivityMobile ? incoOffer?.offerTruckSaleCharges : null;

    if (buyData != null || sellData != null ) {
      return [...buyData, ...sellData];
    } else {
      return [];
    }
  }

  deleteOfferTruckChargeWithId(id: number, deleteObject?: any, buyOrSell?: string, index?: number) {
    this.isLoading = true;
    console.log('id', id);
    if (this.offerTruckActivityMobile) {
      if (id === undefined) {
        if (buyOrSell === 'buy') {
          deleteObject?.offerTruckBuyCharges.splice(index, 1);
        } else {
          deleteObject?.offerTruckSaleCharges.splice(index, 1);
        }
        this.isLoading = false;
      } else {
        this.offerExportService.deleteOfferTruckChargeWithId(id, 'E').subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Ek masraf silindi!' });
          if (buyOrSell === 'buy') {
            deleteObject?.offerTruckBuyCharges.splice(deleteObject?.offerTruckBuyCharges.findIndex(q => q.id === id), 1);
          } else {
            deleteObject?.offerTruckSaleCharges.splice(deleteObject?.offerTruckSaleCharges.findIndex(q => q.id === id), 1);
          }
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          this.messageService.add({key: 'messageTruckList', severity: 'warn', summary: 'Hata', detail: error.error ? error.error.message : error.message});
        });
      }
    }
  }

  onSelectionChange(event: any) {
    const selectedValue = event.value;
    this.sellOrBuyInvoice = selectedValue;
    this.invoiceBuyamount = 0;
    this.invoiceAmount = 0;
  }

  filterGoodDefination(event) {
    const filtered: any[] = [];
    const query = event.query;
    this.goodDefination.forEach(country => {
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    });

    this.goodDefinationType = filtered;
  }


  onSelectLoadCity(event) {
    this.offerTruckActivityMobile.loadCountry = event.country
  }
  onSelectLoadDistrict(event) {
    this.offerTruckActivityMobile.loadCity = event.city;
    this.offerTruckActivityMobile.loadCountry = event.country;
  }

  onSelectUnloadCity(event) {
    this.offerTruckActivityMobile.unloadCountry = event.country
  }
  onSelectUnloadDistrict(event) {
    this.offerTruckActivityMobile.unloadCity = event.city;
    this.offerTruckActivityMobile.unloadCountry = event.country;
  }

  offerTruckBodyChange() {
    if (this.offerTruckActivityMobile.incoOfferTruckDTOs != null && this.offerTruckActivityMobile.incoOfferTruckDTOs.length > 0) {
      this.offerTruckActivityMobile.incoOfferTruckDTOs[0].truckBodyType = this.offerTruckActivityMobile.truckBodyType;
    }
  }
  getReportList() {
    this.mailFrom = JSON.parse(localStorage.getItem('user')).email;
    if (this.reportList?.length >= 0) {
      this.offerExportService.getReportList(this.offerTruckActivityMobile.offerId, 'E').subscribe( rp => {
        this.reportList = rp.body;
      });
    }
  }

  showPdf(report: any) {
    this.isLoading = true;
    this.offerExportService.getReport(report?.id, this.offerTruckActivityMobile.offerId).subscribe( gt => {
      this.isLoading = false;
      const file = new Blob([gt], { type: 'application/pdf' });
      this.file = file;
      const fileUrl = window.URL.createObjectURL(file);
      window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
    }, error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
      this.isLoading = false;
    });
  }
  getDigitalArsiv() {
    console.log(this.offerTruckActivityMobile.offerId)
    this.isLoading = true;
    this.offerExportService.getDigitalArsiv(this.offerTruckActivityMobile.offerId, 'Offer', 'E').subscribe(res => {
      this.digitalStore = res.body;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  getDigitalDocument(report: any) {
    this.isLoading = true;
    this.offerExportService.getDocumentType(report?.id, 'E').subscribe(res => {
      this.offerExportService.getDigitalDocument(res.body.reference, 'E').subscribe( gt => {
        this.isLoading = false;
        const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
        const fileUrl = window.URL.createObjectURL(file);
        if ( res.body.contentType === 'application/pdf' ||  res.body.contentType.startsWith('image')) {
          window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
        } else {
          const nav = (window.navigator as any)
          if (nav && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
          } else {
            var a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = res.body.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
      }, error => {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
        this.isLoading = false;
      });
    },error => {
      this.isLoading = false;
    });
  }

  uploadDocumentsCheck(documents: any) {
    console.log(documents);
    if (documents.files && documents.files.length > 0) {
      for (const file of documents.files) {
        if (file.size <= 15728640) {
          this.uploadDocuments(file);
        } else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
        }
      }

    } else if (documents.files && documents.files.length > 0) {
      for (const file of documents.files) {
        if (file.getAsFile().size <= 15728640) {
          this.uploadDocuments(file);
        } else {
          this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
        }
      }
    }
  }

  uploadDocuments(file: any) {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', file);
    this.offerExportService.uploadDocuments(formData, this.offerTruckActivityMobile.offerId, 'E').subscribe(res => {
      const dct = res.body.res;
      this.offerExportService.getPagesByName('/offers/offer').subscribe(pages => {
        const pageObject = pages.body.find(pg => pg.path === '/offers/offer');
        if (res.status === 200) {
          this.offerExportService.saveDocument({
            entityid: +dct.entityid,
            reference: dct.uid,
            contentType: dct.contentType,
            fileName: dct.fileName,
            fileSize: dct.fileSize,
            page: pageObject,
            sourceType: 'Offer'
          }, 'E').subscribe(sv => {
            this.getDigitalArsiv();
            this.uploader.clear();
          });
          // this.user = this.cacheService.get(Storage.USER);
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
        }
      }, error => {
        this.isLoading = false;
      });
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  rejectAllOffer(substatus?: string, description?: string) {
    this.isLoading = true;
    const updateOfferStatusDTO: UpdateOfferStatusDTO[] = [];
    this.offerTruckActivityMobile.incoOfferTruckDTOs?.forEach(of => {
      updateOfferStatusDTO.push({
        ...new UpdateOfferStatusDTO(),
        status: 'rejected',
        substatus: document.getElementById('rejectReason').innerText,
        offerSeaDetailId: of.offerSeaDetailId,
        description
      });
    });
    this.offerExportService.updateOfferStatus(updateOfferStatusDTO, 'E').subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklifler başarıyla reddedildi.' });
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklifler reddedilirken hata oluştu!' });
    });
  }


  hasStatusOffer(status) {
    return this.offerTruckActivityMobile.incoOfferTruckDTOs?.findIndex(f => f.offerStatus == status) > -1;
  }

  onUpdateStatus(updateStatus = null) {
    let payloadUpdateStatus = {};
    if(!updateStatus){
      if(this.selectedOfferPrice.length == 0) {
        this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata Oluştu', detail: 'Lütfen işlem yapmak istediğiniz fiyat bilgisini seçin.' });
        return;
      }
      const payloadOfferStatus = [];
      this.selectedOfferPrice.forEach(f => {
        payloadUpdateStatus = {
          offerTruckDetailId: f.offerDetailId,
          status: f.offerStatus,
          substatus:  f.offerStatus == 'rejected' ? this.rejectSubType : '',
          description: f.offerStatus == 'rejected' ? this.rejectDescription : '',
        }
        payloadOfferStatus.push(payloadUpdateStatus)
      })

    }

    this.isLoading = true;
    this.activityService.updateOfferStatus(updateStatus || [payloadUpdateStatus as UpdateOfferStatusDTO], 'E').subscribe(
        data  => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Güncellendi', detail: 'Durum bilgisi başarıyla güncellendi.' });

          this.activityService.getMyActivityById(this.route.snapshot.params.id, 'E').subscribe(activity => {

            this.offerTruckActivityMobile.offerStatus = activity.offerStatus;
            const activity_ = this.offerTruckActivityMobile;
            activity_.offerStatus = activity.offerStatus;
            this.offerTruckActivityMobile = activity_;

            // Tab Button durumu güncelle.
            this.activeRezervationButton();

          });
        },
        error => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'Durum bilgisi güncellenmedi.' });
        },
        () => {
          this.isLoading = false;
        }
    )
  }
  filterOperationRepresentative(event) {
    this.offerExportService.getUserWithName(event.query).subscribe(res => {
      this.operationRepresentativeList = res.body;
    });
  }

    loadDistrictFocusOut(loadDistrict: DistrictMinimizedModel) {
        if (loadDistrict.id == null){
            this.offerTruckActivityMobile.loadDistrict = null;
        }
    }
    unloadDistrictFocusOut(unloadDistrict: DistrictMinimizedModel) {
        if (unloadDistrict.id == null){
            this.offerTruckActivityMobile.unloadDistrict = null;
        }
    }
}
