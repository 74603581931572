import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ExpensePackageItem } from 'src/app/models/expense.model';
import { FileDialogConfirmComponent } from 'src/app/pages/document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { LearningService } from 'src/app/services/learning.service';
import { CreateExpensePackageItemComponent } from '../create-expense-package-item/create-expense-package-item.component';

@Component({
  selector: 'app-expense-package-item-info',
  templateUrl: './expense-package-item-info.component.html',
  styleUrls: ['./expense-package-item-info.component.scss']
})
export class ExpensePackageItemInfoComponent implements OnInit {

  folderName: string;

  isEdit = false;

  private basePath = '/uploads/expense/images';

  uploadedFiles: any[] = [];

  files: File[] = [];

  model: ExpensePackageItem = <ExpensePackageItem>{
    spendDate: new Date()
  };

  isProgressActive = false;

  constructor(public dialogRef: MatDialogRef<ExpensePackageItemInfoComponent>, private learningService: LearningService, @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog,
    private messageService: MessageService) {

    if (data && data.expenseItem) {

      this.isEdit = true;
      this.model = data.expenseItem;

    }

  }

  ngOnInit(): void {

  }


  myVideos = [];

  uploadProgress: number = 0;

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }

  openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  isLoading = false;

  save() {

    if (!(this.model.reasonForSpending && this.model.reasonForSpending.length > 0)) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Harcama Nedeni Boş Geçilemez!" });
      return;

    }

    if (!(this.model.amount)) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Tutar Boş Geçilemez!" });
      return;

    }

    this.dialogRef.close({
      model: this.model,
      isEdit: this.isEdit
    });

  }

}
