import { HttpClient } from '@angular/common/http';
import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { LoginDto } from '../models/login-dto.model';
import { AuthenticationService } from '../services/authentication.service';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    styleUrls: ['./app.login.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class AppLoginComponent implements OnInit {

    loginDto: LoginDto  = new LoginDto();
    loginDtos: LoginDto[] = [];
    isLoading = false;

    descriptionNames: any[] = [];
    rememberMe = true;
    isShowOTP: boolean = false;

    selectedDescriptionName: any;

    constructor(private authService: AuthenticationService, private router: Router, private messageService: MessageService) {
    this.isShowOTP = false;
    }

    ngOnInit(): void {
        if(localStorage.getItem('rememberMe')) {
            this.rememberMe = true;
            this.username = JSON.parse(localStorage.getItem('rememberMe')).username;
            this.password = JSON.parse(localStorage.getItem('rememberMe')).password;
            console.log(JSON.parse(localStorage.getItem('rememberMe')))
        }
        this.authService.loginNews().subscribe(res => {
            if (res) {
                this.loginDtos = res;
                const mainDomain = this.loginDtos.find(f => !f.subDomain);

                this.loginDtos.forEach(f => {
                    f.language = f.languages.find(f => f.code == 'tr')
                })
                if(mainDomain) this.loginDto = mainDomain;

                console.log(this.loginDtos, "loginDtos")
            }
        });
    }

    username: string = ""
    //Show Hide Password
    password: string = "";
    otp: string = null;

    authPromises = [];

    @HostListener('document:keydown.enter')
    authenticate() {
        if (this.isLoading) {
            return null;
        }
        this.isLoading = true;
        this.authPromises = [];
        this.loginDto.username = this.username;
        this.loginDto.otp = this.otp;
        this.loginDto.password = this.password;

        if(this.rememberMe) {
            // @ts-ignore
            localStorage.setItem('rememberMe', JSON.stringify({'username': this.username, 'password': this.password}));
        }
        this.loginDtos.forEach(l => {
            l.username = this.username;
            l.password = this.password;
            l.otp = this.otp;
        })
        this.authService.otpControl(this.loginDtos).toPromise().then(data => {
            if (data.otpSet == true) {
                this.isLoading = false;
                const message = this.isShowOTP ? 'Kullanıcı adı veya şifre hatalı veya OTP numarasi hatalı' : 'Lütfen e-posta adresinize gönderilen OTP numarası ile oturum açınız.'
                this.messageService.add({severity: 'error', summary: 'Hata', detail: message});
                this.isShowOTP = true;
            } else {
                    data.returnLoginDTOs.forEach(l => {
                        this.authPromises.push(this.authService.login(l).toPromise());
                    })

                    Promise.all(this.authPromises).then(r => {
                        r.forEach((auth, index) => {
                            const loginDto = data.returnLoginDTOs[index];
                            let token = auth.token;
                            let user = auth.user;
                            let sessionId = auth.sessionId;
                            let menus = auth.menus;
                            let selectBoxDTOS = auth.selectBoxDTOS;

                            if(!loginDto.subDomain) {
                                localStorage.setItem("token", token);
                                localStorage.setItem("sessionId", sessionId);
                                localStorage.setItem('user', JSON.stringify(user));
                                localStorage.setItem('menus', JSON.stringify(menus));
                                sessionStorage.setItem('firm', this.loginDto.firm.name);
                                localStorage.setItem('selectBoxDTOS', JSON.stringify(selectBoxDTOS));
                            }

                            if(loginDto.subDomain) {
                                let subDomainSessions = {
                                    token: token,
                                    sessionId: sessionId,
                                    menus: menus,
                                    user: auth.user,
                                    domain: loginDto.subDomain
                                };
                                localStorage.setItem(loginDto.subDomain, JSON.stringify(subDomainSessions));
                                localStorage.setItem('user' + loginDto.subDomain, JSON.stringify(auth.user));
                            }
                        })
                        this.isLoading = false;
                        this.router.navigate([this.password == '123456' ? '/user/change-password' :'/' ]);
                    }).catch(e => {
                        console.log(e);
                        this.isLoading = false;
                        if(e.error.message.search("Server Error") != -1) {
                            const message = this.isShowOTP ? 'Kullanıcı adı veya şifre hatalı veya OTP numarasi hatalı' : 'Lütfen e-posta adresinize gönderilen OTP numarası ile oturum açınız.'
                            this.messageService.add({severity: 'error', summary: 'Hata', detail: message});
                        }
                        else{
                            this.messageService.add({severity: 'error', summary: 'Hata', detail: e.error.message});
                        }

                        this.isShowOTP = true;
                    })
            }
        }).catch(e => {
            this.messageService.add({severity: 'error', summary: 'Hata', detail: e.error.message});
        })
    }

    navigateToForgotPassword() {
        this.router.navigate(['/forgotPassword']);
    }
}
