import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { KpiService } from 'src/app/services/kpi.service';
import { CompanyService } from 'src/app/services/company.service';
import {ConfirmationService, SortEvent} from 'primeng/api';
import { MessageService } from "primeng/api";
import { MatDialog } from '@angular/material/dialog';
import { ListService } from 'src/app/services/list.service.service';

import { KpiMonthlyMobile } from 'src/app/models/kpi/kpi-monthly-mobile';
import { KpiTargetCustomerMobile } from 'src/app/models/kpi/kpi-target-customer-mobile';
import { KpiTargetMonthDetDTO } from 'src/app/models/kpi/kpi-target-month-det-dto';
import { Company } from 'src/app/models/company.model';
import { KpiTargetCustomerMonthDetDTO } from 'src/app/models/kpi/kpi-target-customer-month-dets-dto';
import { KpiDefDTO } from 'src/app/models/kpi/kpi-def-dto';
import { KpiTargetCustomerMonthMobile } from 'src/app/models/kpi/kpi-target-customer-month-mobile';
import { KpiTargetCustomerDetDTO } from 'src/app/models/kpi/kpi-target-customer-det-dto';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { CreateCustomerComponent } from 'src/app/shared/create-customer/create-customer.component';
import {ActivityService} from "../../../services/activity.service";
import {OfferExportService} from "../../../services/offer-export.service";
import {KpiTargetMonthMobile} from '../../../models/kpi/kpi-target-month-mobile';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KpiComponent implements OnInit {

  displayEditGpModal: boolean;
  displaySeaGoodDetail: boolean = false;
  selectedKpiDto: KpiDefDTO = null;
  selectedTotalValue:number;
  selectedKpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO[] = [];
  selectedKpiTargetCustomerDet: KpiTargetCustomerDetDTO = null;
  listResultItem: any = null;

  isLoading: boolean = true;
  inputFocusedValue: number = 0;
  selectedList: any = [];
  selectedGood: SimpleEntity = null;

  filterUsers: SimpleEntity[] = [];
  filteredKpiTargetCustomerMonths: KpiTargetCustomerMonthMobile[] = [];
  filteredKpiTargetMonthMobile: KpiTargetMonthMobile[] = [];
    selectedCompanyFilter: any;
    selectedCustomer: any;
  airOrSeaGoodSources: SimpleEntity[] = [];
  showGoodList: boolean = false;

  companyTypes = [
    {name: 'Mevcut Müşteri', code: 'CURRENTCUSTOMER', inactive: false},
    {name: 'Hedef Müşteri', code: 'TOTALTARGET', inactive: false},
    {name: 'Geri Kazanlınacak', code: 'WILLBEREGAINED', inactive: false},
    {name: 'Diğer', code: 'OTHER' , inactive: false}
  ];
  teuText = {
    'TONNAGE': 'targetTonnage',
    'FILEQUANTITY': 'targetFileQuantity',
    'NEWCUSTOMER': 'targetNewCustomer',
    'TEU': 'targetTeu',
    'target': 'target'
  }
  actualTeuText = {
    'TONNAGE': 'actualTonnage',
    'FILEQUANTITY': 'actualFileQuantity',
    'NEWCUSTOMER': 'actualNewCustomer',
    'TEU': 'actualTeu',
    'target': 'actual'
  }
  teuTextTranslate = {
    'TONNAGE': 'Tonaj',
    'FILEQUANTITY': 'Dosya Ad.',
    'NEWCUSTOMER': 'Yeni Müşteri',
    'TEU': 'Teu',
    'target': 'Hedef'
  }
  kpiMonthlyMobile: KpiMonthlyMobile = null;
  allKpiTargetMonthDets:KpiTargetMonthDetDTO[] = []
  allKpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO[] = [];

  user: any = null;
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  userTransportDirection: string = null;

  usersByleader: SimpleEntity[] = [];

  filteredUsersByleader: SimpleEntity[] = [];
  selectedTeamUser: SimpleEntity = null;
  selectedApprovalUser: SimpleEntity = null;


    constructor(private kpiService: KpiService, private companyService: CompanyService, private dialog: MatDialog, private listService: ListService, private confirmationService: ConfirmationService,
     private userService: UserService, private messageService: MessageService, private offerExportService: OfferExportService) { }

  async ngOnInit() {

    /* set user by transportDirection */
    let  user = this.userService.getUserFromLocalStorage();

    if(!user.company) {
      this.messageService.add({key: 'tst', severity:'warn', detail: 'Kullanıcı tanımına ait adres kartı bilgisi bulunamadı!' });
    } else {
      this.userTransportDirection = user.company.name.includes('KTL') ? 'I' : 'E';
    }

    if(this.userTransportDirection == 'I') {
      if(localStorage.getItem('userimport'))  user = JSON.parse(localStorage.getItem("userimport"));
    }

    /* set default session user */
    this.selectedTeamUser = Object.assign({name: user.firstName + " " + user.lastName}, user);


    /* get kpi targets */
    this.kpiMonthlyMobile = await this.getKpiTargetMonthly()


    /* get users by team leader */
    this.usersByleader = await this.getScoreUserByLead();
    this.user = user;


  }

  prepareKpiMonthlyMobile(kpiMonthlyMobile, selectedApprovalUser?){

      this.isOtherExist(kpiMonthlyMobile);
      /* set all kpiTargetMonthDets for calculation */
      kpiMonthlyMobile.kpiTargetMonths.map(w => w.kpiTargetMonthDets).forEach(b => this.allKpiTargetMonthDets = this.allKpiTargetMonthDets.concat(b))

      /* set all kpiTargetCustomerMonthDet  for calculation  */
      kpiMonthlyMobile.kpiTargetCustomerMonths.forEach(i => {
          i.kpiTargetCustomerMobile.map(t => t.kpiTargetCustomerMonthDets).forEach(b => {
              this.allKpiTargetCustomerMonthDetDTO = this.allKpiTargetCustomerMonthDetDTO.concat(b)
          })
      })

      if (kpiMonthlyMobile.approvalStatus > 0){
          this.filterUsers = [];
          const ids = [];
          kpiMonthlyMobile.kpiTargetMonths.forEach(k => {
                  if (!ids.includes(k.kpiTargetMonth.user.id)) {
                      ids.push(k.kpiTargetMonth.user.id);
                      this.filterUsers.push(k.kpiTargetMonth.user);
                  }
              }
          );
          this.selectedApprovalUser = selectedApprovalUser ? selectedApprovalUser : this.filterUsers[0];
          setTimeout(() => {
              this.onSelectApprovalUser();
          }, 300);
      }

  }

  totalTargetMonthGP(month: number) {
    if(!this.allKpiTargetMonthDets || this.allKpiTargetMonthDets.length == 0) return 0;
    return this.precisionRoundMod(this.totalFromCustomerTargets(null, month),1);
  }

    totalkpiTargetCustomerDet(kpiDef: KpiDefDTO,  kpiTargetCustomerMonths: KpiTargetCustomerMonthMobile) {
        const isGp = (!(kpiDef.useGp === null || kpiDef.useGp === false));

        const allkpiTargetCustomerDet = kpiTargetCustomerMonths.kpiTargetCustomerMobile.map(c => c.kpiTargetCustomerDet);
        if(allkpiTargetCustomerDet.length == 0 ) return 0;

        if(allkpiTargetCustomerDet) return this.precisionRoundMod(allkpiTargetCustomerDet.map(s => isGp ? s.gpTotal : s.total).reduce((a,b) => a+b),1)
        return 0;
    }

  totalTargetCustomerMonthDetGP(kpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO[] = null) {
    if(kpiTargetCustomerMonthDetDTO.length == 0 ) return 0;

    if(kpiTargetCustomerMonthDetDTO) return this.precisionRoundMod(kpiTargetCustomerMonthDetDTO.map(s => s.target).reduce((a,b) => a+b),1)
    if(this.allKpiTargetCustomerMonthDetDTO) return this.precisionRoundMod(this.allKpiTargetCustomerMonthDetDTO.map(s => s.target).reduce((a,b) => a+b), 1);
    return 0;
  }

  totalTargetCustomerTeu(kpiDef: KpiDefDTO, kpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO[]) {
    if(!kpiTargetCustomerMonthDetDTO || kpiTargetCustomerMonthDetDTO.length == 0) return 0;
    return this.precisionRoundMod(kpiTargetCustomerMonthDetDTO.map(s => s[this.teuText[kpiDef.teuText]]).reduce((a,b) => a+b), 1)
  }

    allTotalTargetCustomerTeu(kpiDef: KpiDefDTO,  kpiTargetCustomerMonths: KpiTargetCustomerMonthMobile) {
        const isGp = (!(kpiDef.useGp === null || kpiDef.useGp === false));

        const kpiTargetCustomerMonthDetDTO : KpiTargetCustomerMonthDetDTO[] = [];

        kpiTargetCustomerMonths.kpiTargetCustomerMobile.forEach(c => c.kpiTargetCustomerMonthDets.forEach(d => kpiTargetCustomerMonthDetDTO.push(d)));

        if(!kpiTargetCustomerMonthDetDTO || kpiTargetCustomerMonthDetDTO.length == 0) return 0;
        return isGp ? this.precisionRoundMod(kpiTargetCustomerMonthDetDTO.map(s => s.target).reduce((a,b) => a+b), 1) : this.precisionRoundMod(kpiTargetCustomerMonthDetDTO.map(s => s[this.teuText[kpiDef.teuText]]).reduce((a,b) => a+b), 1)
    }

  totalGPFromKpiTargetCustomerMonths(kpiTargetCustomerMonths: KpiTargetCustomerMonthMobile) {
    let totalGp = 0;
    if(!kpiTargetCustomerMonths) return 0;
    if(!kpiTargetCustomerMonths.kpiTargetCustomerMobile) return 0;
    kpiTargetCustomerMonths.kpiTargetCustomerMobile.map(s => s.kpiTargetCustomerMonthDets).forEach(i => {
      if(!i || i.length == 0) return;
      totalGp += i.map(b => b.target).reduce((a,b) => a+b);
    })
    return this.precisionRoundMod(totalGp, 1)
  }

    totalGPOrTeuFromKpiTargetCustomerMonthsByMonth(kpiDefDto: KpiDefDTO , kpiTargetCustomerMonths: KpiTargetCustomerMonthMobile, month?: number, actual?: boolean) {
        const isGp = (!(kpiDefDto.useGp === null || kpiDefDto.useGp === false));
        let total = 0;
        if(!kpiTargetCustomerMonths) return 0;
        if(!kpiTargetCustomerMonths.kpiTargetCustomerMobile) return 0;
        kpiTargetCustomerMonths.kpiTargetCustomerMobile.map(s => s.kpiTargetCustomerMonthDets).forEach(i => {
            if(!i || i.length == 0) return;
            total += i.map(b => {
                if (actual && b.month === month){
                    return b[this.actualTeuText[kpiDefDto.teuText]] ? b[this.actualTeuText[kpiDefDto.teuText]] : 0;
                }
                if (b.month === month){
                    return isGp ? b.target : b[this.teuText[kpiDefDto.teuText]];
                }
                return 0;
            }).reduce((a,b) => a+b);
        })
        return this.precisionRoundMod(total, 1)
    }


  filterCompany(event, companyType: any) {
    if (event.query.lenght < 3) return;
      if (companyType === 'CURRENTCUSTOMER') {
          var user;
          if (this.userTransportDirection === 'I') {
              user = JSON.parse(localStorage.getItem('userimport'));
          } else {
              user = JSON.parse(localStorage.getItem('user'));
          }
          this.offerExportService.getCompany(event.query, undefined, user?.id, this.userTransportDirection).subscribe(res => {
            this.filteredCompanies = res.body;
        });
      } else {
          this.companyService.getAll(event.query, this.userTransportDirection).subscribe(res => {
              this.filteredCompanies = res;
          });
      }

  }


  onFocusKpiTargetCustomerMonthDet(kpiTargetCustomerMobile: KpiTargetCustomerMobile, kpiDef: KpiDefDTO, event: FocusEvent) {
    this.selectedKpiDto = kpiDef;
    this.selectedKpiTargetCustomerDet = kpiTargetCustomerMobile.kpiTargetCustomerDet;
    this.selectedKpiTargetCustomerMonthDetDTO = kpiTargetCustomerMobile.kpiTargetCustomerMonthDets;
    this.selectedTotalValue =  Number(event.target['value'].replaceAll(".",""));
  }
  distributeEvenlyToMonth(event, isTeu = false) {

    const totalVal =  Number(event.target.value.replaceAll(".",""));
    if(totalVal == 0 || this.selectedTotalValue == totalVal) return


    this.confirmationService.confirm({
      message: 'Toplam değer üzerinden tüm aylara eşit olarak dağılım uygulanacaktır. İşleme devam etmek istediğinize emin misiniz ?',
      accept: async () => {

        if(!isTeu && (totalVal || totalVal == 0)) {

          if(this.selectedKpiTargetCustomerDet.gpTotal && this.selectedKpiTargetCustomerDet.gpTotal > 0 && this.selectedKpiTargetCustomerDet.gpTotal < totalVal) {
            this.messageService.add({key: 'tst', severity:'warn', detail: `Potansiyel toplam ${this.selectedKpiTargetCustomerDet.gpTotal} dan büyük olamaz!` });
            return;
          }

          const monthlyGP = totalVal / 12;
          this.selectedKpiTargetCustomerMonthDetDTO.forEach(s => {
              s.target = monthlyGP;
              s.isChanged = true;
          });
          this.setKpiTargetMonthDetValue(this.selectedKpiDto)

        }

        if(isTeu && (totalVal || totalVal == 0) ) {

          if(this.selectedKpiTargetCustomerDet.total  && this.selectedKpiTargetCustomerDet.total  > 0 && this.selectedKpiTargetCustomerDet.total < totalVal) {
            this.messageService.add({key: 'tst', severity:'warn', detail: `Potansiyel toplam  ${this.selectedKpiTargetCustomerDet.total} dan büyük olamaz!` });
            return;
          }

          const monthlyTEU = totalVal / 12;
          this.selectedKpiTargetCustomerMonthDetDTO.forEach(s => {
              s[this.teuText[this.selectedKpiDto.teuText]] = monthlyTEU
              s.isChanged = true;
          });

          this.setKpiTargetMonthDetValue(this.selectedKpiDto, this.selectedKpiDto.teuText)
        }
      },
      reject: () => {

       event.target.value = this.selectedTotalValue || null;
      }
    });


  }

  precisionRoundMod(number, precision) {
    var factor = Math.pow(10, precision);
    var n = precision < 0 ? number : 0.01 / factor + number;
    return Math.round( n * factor) / factor;
  }

  addKpiTargetCustomerDet(kpiTargetCustomerMonthMobile: KpiTargetCustomerMonthMobile) {
    const newKpiTargetCustomerMobile = new KpiTargetCustomerMobile();
    newKpiTargetCustomerMobile.isNew = true;
    newKpiTargetCustomerMobile.kpiDef = kpiTargetCustomerMonthMobile.kpiDef;
    newKpiTargetCustomerMobile.companyTypes = JSON.parse(JSON.stringify(this.companyTypes));
    let isOtherExist = 0;
    kpiTargetCustomerMonthMobile.kpiTargetCustomerMobile.forEach(value => {
          if (value.kpiTargetCustomerDet.companyType === 'OTHER') {
              isOtherExist++;
          }
      });
    if (isOtherExist !== 0) {
          newKpiTargetCustomerMobile.companyTypes.forEach(companyType => {
              if (companyType.code === 'OTHER') {
                  companyType.inactive = true;
              }
          });
      }

    const kpiTargetCustomerDet = new KpiTargetCustomerDetDTO();
    kpiTargetCustomerDet.status = "ACTIVE";
    kpiTargetCustomerDet.category = kpiTargetCustomerMonthMobile.kpiTargetCustomerMobile.slice(-1)[0].kpiTargetCustomerDet.category;
    kpiTargetCustomerDet.year = kpiTargetCustomerMonthMobile.kpiTargetCustomerMobile.slice(-1)[0].kpiTargetCustomerDet.year;
    newKpiTargetCustomerMobile.kpiTargetCustomerDet = kpiTargetCustomerDet;

    const kpiTargetCustomerMonthDets: KpiTargetCustomerMonthDetDTO[] = [];
    for (let index = 1; index < 13; index++) {
      const kpiTargetCustomerMonthDetDTO = new KpiTargetCustomerMonthDetDTO();
      kpiTargetCustomerMonthDetDTO.month = index;
      kpiTargetCustomerMonthDetDTO.target = 0;
      kpiTargetCustomerMonthDetDTO.targetFileQuantity = 0;
      kpiTargetCustomerMonthDetDTO.targetTonnage = 0;
      kpiTargetCustomerMonthDetDTO.targetTeu = 0;
      kpiTargetCustomerMonthDetDTO.targetNewCustomer = 0;
      kpiTargetCustomerMonthDets.push(kpiTargetCustomerMonthDetDTO);
    }
    newKpiTargetCustomerMobile.kpiTargetCustomerMonthDets = kpiTargetCustomerMonthDets;
    kpiTargetCustomerMonthMobile.kpiTargetCustomerMobile.unshift(newKpiTargetCustomerMobile);
  }

  totalFromCustomerTargets(kpiDef: KpiDefDTO, month: number, teuText = "target") {
    let total = 0;
    if(!this.kpiMonthlyMobile  || !this.kpiMonthlyMobile.kpiTargetCustomerMonths || this.kpiMonthlyMobile.kpiTargetCustomerMonths.length == 0) return total;

    if (this.kpiMonthlyMobile.approvalStatus === null) {
        let kpiTargetCustomerMonthMobile: KpiTargetCustomerMonthMobile[] = this.kpiMonthlyMobile.kpiTargetCustomerMonths;
        if(kpiDef) {
            kpiTargetCustomerMonthMobile = this.kpiMonthlyMobile.kpiTargetCustomerMonths.filter(f => f.kpiDef.id == kpiDef.id);
        }

        kpiTargetCustomerMonthMobile.forEach(i => {
            i.kpiTargetCustomerMobile.map(r => r.kpiTargetCustomerMonthDets).forEach(w => {
                total += w.filter(s => s.month == month).map(e => e[teuText]).reduce((a,b) => a+b)
            })
        })
    }else {
        let kpiTargetCustomerMonthMobile: KpiTargetCustomerMonthMobile[] = this.filteredKpiTargetCustomerMonths;
        if(kpiDef) {
            kpiTargetCustomerMonthMobile = this.filteredKpiTargetCustomerMonths.filter(f => f.kpiDef.id == kpiDef.id);
        }

        kpiTargetCustomerMonthMobile.forEach(i => {
            i.kpiTargetCustomerMobile.map(r => r.kpiTargetCustomerMonthDets).forEach(w => {
                total += w.filter(s => s.month == month).map(e => e[teuText]).reduce((a,b) => a+b)
            })
        })
    }

    return total;

  }

  setKpiTargetMonthDetValue(kpiDefDto: KpiDefDTO, teuText = "target") {

    const kpiTargetMonthDets =  (this.kpiMonthlyMobile?.approvalStatus === null ? this.kpiMonthlyMobile.kpiTargetMonths : this.filteredKpiTargetMonthMobile).find(f => f.kpiDef.id == kpiDefDto.id).kpiTargetMonthDets;

    const teuTextKey = teuText == 'target' ? 'target' : this.teuText[teuText];
    for (let i = 1; i < 13; i++) {
      if(kpiTargetMonthDets && kpiTargetMonthDets.length > 0) {
        const monthDet = kpiTargetMonthDets.find(f => f.month == i);
        if(monthDet) {
            const totalFromCustomerTargets = this.totalFromCustomerTargets(kpiDefDto, i, teuTextKey);
            console.log('neden',monthDet[teuTextKey],totalFromCustomerTargets);
            if (monthDet[teuTextKey] !== totalFromCustomerTargets){
                monthDet.isChanged = true;
            }
            monthDet[teuTextKey] =  totalFromCustomerTargets;
            console.log(monthDet)
        }
      }
    }
  }


  async deleteKpiTargetCustomerDet(kpi: any, kpiTargetCustomerMonthMobiles: KpiTargetCustomerMobile[], kpiTargetCustomerMobile: KpiTargetCustomerMobile) {
        const index = kpiTargetCustomerMonthMobiles.indexOf(kpiTargetCustomerMobile);

    try {
      this.confirmationService.confirm({
        message: 'İşleme devam etmek istediğinize emin misiniz ?',
        accept: async () => {
          if(kpiTargetCustomerMonthMobiles[index].kpiTargetCustomerDet.id) {
            await this.kpiService.deleteKpiTargetMonthDet(kpiTargetCustomerMonthMobiles[index].kpiTargetCustomerDet.id, this.userTransportDirection).toPromise();
          }
          kpiTargetCustomerMonthMobiles.splice(index, 1);
          this.messageService.add({key: 'tst', severity:'success', detail: 'Kayıt silindi.' });
          this.kpiMonthlyMobile = await this.getKpiTargetMonthly();
        }
      });
    } catch(e) {
      this.messageService.add({key: 'tst', severity:'error', detail: e.message || 'KPI hedef kaydı silinirken bir hata oluştu!' });
    }
  }

  onFocusInput(kpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO, teuTextKey = "target") {
    this.inputFocusedValue =  Math.round(kpiTargetCustomerMonthDetDTO[teuTextKey] * 100 + Number.EPSILON ) / 100
  }

  onBlurInputValue(kpiTargetCustomerMonthMobile: KpiTargetCustomerMonthMobile, kpiTargetCustomerMobile: KpiTargetCustomerMobile, kpiTargetCustomerMonthDetDTO: KpiTargetCustomerMonthDetDTO, teuTextKey = "target") {
    if (this.inputFocusedValue == kpiTargetCustomerMonthDetDTO[this.teuText[teuTextKey]]) return;

    this.setKpiTargetMonthDetValue(kpiTargetCustomerMonthMobile.kpiDef, teuTextKey == 'target' ? 'target' : teuTextKey)
  }

  validation() {
      let createOrUpload = true;
      this.kpiMonthlyMobile.kpiTargetCustomerMonths.forEach(value => {
          value.kpiTargetCustomerMobile.forEach(kpiTargetCustomerMobile => {
              if (kpiTargetCustomerMobile.isNew) {
                  if (kpiTargetCustomerMobile.kpiTargetCustomerDet.companyType === null || kpiTargetCustomerMobile.kpiTargetCustomerDet.companyType === undefined) {
                      this.messageService.add({key: 'tst', severity: 'warn', detail: 'Firma Tipi Girilmemiş Kpi Hedefi Mevcut'});
                      createOrUpload = false;
                  }
                  if (kpiTargetCustomerMobile.kpiTargetCustomerDet.companyType !== 'OTHER' && !kpiTargetCustomerMobile.kpiTargetCustomerDet.customer?.id) {
                      this.messageService.add({key: 'tst', severity: 'warn', detail: 'Müşteri Girilmemiş Kpi Hedefi Mevcut'});
                      createOrUpload = false;
                  }
                  if (kpiTargetCustomerMobile.kpiTargetCustomerDet.companyType === 'OTHER') {
                      kpiTargetCustomerMobile.kpiTargetCustomerDet.customer = null;
                  }
              }
          });
      });
      return createOrUpload;
  }

  async createOrUpdateKpiTarget() {
      if (this.validation()) {
          this.isLoading = true;
          try {
              this.kpiMonthlyMobile = await this.kpiService.createOrUpdateKpiTarget(this.kpiMonthlyMobile, this.userTransportDirection).toPromise();
              this.messageService.add({key: 'tst', severity: 'success', detail: 'KPI Hedefleriniz güncellendi.'});
              this.isLoading = false;
              this.prepareKpiMonthlyMobile(this.kpiMonthlyMobile, this.selectedApprovalUser);
          } catch (e) {
              this.isLoading = false;
          }
      }

  }

  async getKpiTargetMonthly() {
    this.isLoading = true;
    try {
      const result = await this.kpiService.getKpiMonthlyByUser(this.userTransportDirection, this.selectedTeamUser?.id).toPromise();
      this.isLoading = false;
      this.prepareKpiMonthlyMobile(result);
      return result;

    } catch {
      this.isLoading = false;
    }
    return null;
  }

  private isOtherExist(result: KpiMonthlyMobile) {
        result.kpiTargetCustomerMonths.forEach(value => {
            let isOtherExist = 0;
            value.kpiTargetCustomerMobile.forEach(kpiTargetCustomerMobile => {
                kpiTargetCustomerMobile.companyTypes = JSON.parse(JSON.stringify(this.companyTypes));
                if (kpiTargetCustomerMobile.kpiTargetCustomerDet.companyType === 'OTHER') {
                    kpiTargetCustomerMobile.kpiTargetCustomerDet.customer = null;
                    isOtherExist++;
                }
            });
            if (isOtherExist !== 0) {
                value.kpiTargetCustomerMobile.forEach(kpiTargetCustomerMobile => {
                    kpiTargetCustomerMobile.companyTypes.forEach(companyType => {
                        if (companyType.code === 'OTHER') {
                            companyType.inactive = true;
                        }
                    });
                });
            }
        });
  }

  async getScoreUserByLead() {
    return await this.kpiService.getScoreUserByLead(this.userTransportDirection).toPromise();
  }

  filterTeamUser(event) {
    if (event.query.lenght < 3) return;
    this.filteredUsersByleader = this.usersByleader.filter(s => s.name.toLocaleLowerCase().includes(event.query.toLocaleLowerCase()));
  }

    filterApprovalUser(event) {
        if (event.query.lenght < 3) return;
        this.filterUsers = this.filterUsers.filter(s => s.name.toLocaleLowerCase().includes(event.query.toLocaleLowerCase()));
    }

  async onSelectTeamUser() {
    /* get kpi targets */
    this.kpiMonthlyMobile = await this.getKpiTargetMonthly()

  }

  onSelectApprovalUser() {
        const indexes = [];
        const filterKpi: KpiTargetCustomerMonthMobile[] = [];
        const filteredKpiTargetMonthMobile: KpiTargetMonthMobile[] = [];
        this.kpiMonthlyMobile.kpiTargetMonths.forEach(value => {
            if (value.kpiTargetMonth.user.id === this.selectedApprovalUser.id){
                filteredKpiTargetMonthMobile.push(value);
                indexes.push(this.kpiMonthlyMobile.kpiTargetMonths.indexOf(value));
            }
        });
        this.filteredKpiTargetMonthMobile = filteredKpiTargetMonthMobile;
        indexes.forEach(index => filterKpi.push(this.kpiMonthlyMobile.kpiTargetCustomerMonths[index]));
        this.filteredKpiTargetCustomerMonths = filterKpi;
  }

  async onSelectCustomer(event) {
    if(!event.id) return;
    const kpiTargetMonths = await this.kpiService.findByCustomerMonthDetCompany(event.id, this.userTransportDirection).toPromise();


      const existKpiTargetNames = kpiTargetMonths.filter(k => k.kpiTargetStatus == 'ACTIVE').map(s => s.kpiTarget).map(r => r.name).join(",");
      if (existKpiTargetNames.length > 0){
      this.messageService.add({key: 'tst', severity:'warn', detail: `${event.name} müşteri zaten ${existKpiTargetNames} isimli KPI hedeflerinde kullanılmış görünmektedir.` });
      }

  }

  onSelectCompanyType(event, kpiTargetCustomerDet: KpiTargetCustomerDetDTO, kpiTargetCustomerMonths) {
      kpiTargetCustomerDet.customer = null;
      if (event.value === 'OTHER') {
          kpiTargetCustomerMonths.kpiTargetCustomerMobile.forEach(value1 => {
              if (value1.kpiTargetCustomerDet !== kpiTargetCustomerDet) {
                  value1.companyTypes.forEach(value => {
                      if (value.code === 'OTHER') {
                          value.inactive = true;
                      }
                  });
              }
          });
      } else {
          let isOtherExist = 0;
          kpiTargetCustomerMonths.kpiTargetCustomerMobile.forEach(value => {
              if (value.kpiTargetCustomerDet.companyType === 'OTHER') {
                  isOtherExist++;
              }
          });
          if (isOtherExist === 0) {
              kpiTargetCustomerMonths.kpiTargetCustomerMobile.forEach(value1 => {
                  value1.companyTypes.forEach(value => {
                      value.inactive = false;
                  });
              });
          }
      }
  }

  updateKpiMonthlyManually() {
        this.kpiMonthlyMobile?.kpiTargetCustomerMonths?.forEach(value => {
            const updateKpi = this.filteredKpiTargetCustomerMonths?.filter(value1 => value1.kpiTargetMonth.id === value.kpiTargetMonth.id)[0];
            if (updateKpi){
                this.kpiMonthlyMobile.kpiTargetCustomerMonths[this.kpiMonthlyMobile.kpiTargetCustomerMonths.indexOf(value)] = updateKpi;
            }
        });
  }


  showCreateCustomerDialog() {
    window.scrollTo(0, 0);
    let dialogRef = this.dialog.open(CreateCustomerComponent, {
      panelClass: 'modal-md',
      width: '1000px',
      height: 'auto',
      data: {}
    });
  }

  async approveKpiTargetCustomerDet() {
    const approveKpiTargetCustomerMonthDets = [];

    try {
      this.confirmationService.confirm({
        message: 'İşleme devam etmek istediğinize emin misiniz ?',
        accept: async () => {

          if(this.kpiMonthlyMobile.kpiTargetCustomerMonths) {
            this.kpiMonthlyMobile.kpiTargetCustomerMonths.forEach(t => {
              t.kpiTargetCustomerMobile.forEach(k => {
                if(k.kpiTargetCustomerDet.id) {
                  k.kpiTargetCustomerDet.isApproved = true;
                  approveKpiTargetCustomerMonthDets.push(this.kpiService.approveKpiTargetCustomerDet(k.kpiTargetCustomerDet.id, this.userTransportDirection).toPromise());
                }
              });
            })

          }
          await Promise.all(approveKpiTargetCustomerMonthDets);
          this.messageService.add({key: 'tst', severity:'success', detail: `Kpi hedefi onaylandı` });
        }
      });
    } catch(e) {
      this.messageService.add({key: 'tst', severity:'error', detail: e.message || 'KPI hedef kaydı onaylanırken bir hata oluştu!' });
    }
  }

  isSelected(name){
      return this.selectedList.includes(name);
  }

  selectChange(event: any, name: string | undefined) {
      if (name !== undefined) {
          event ? this.selectedList.push(name) : this.selectedList = this.selectedList.filter(item => item !== name);
      }
  }

    approveKpiTargetCustomerMonthMobile(value: KpiTargetCustomerMonthMobile[]) {
        try {
            this.confirmationService.confirm({
                message: 'İşleme devam etmek istediğinize emin misiniz ?',
                accept: async () => {
                    this.isLoading = true;
                    value.forEach(dto => dto.approvalStatus = this.kpiMonthlyMobile.approvalStatus);
                    this.filteredKpiTargetCustomerMonths = await this.kpiService.approveKpiTargetCustomerDetWithApprovalStatus(value, this.userTransportDirection).toPromise();
                    this.updateKpiMonthlyManually();
                    this.messageService.add({key: 'tst', severity: 'success', detail: `Kpi hedefi onaylandı`});
                    this.isLoading = false;
                }
            });

        } catch (e) {
            this.messageService.add({key: 'tst', severity: 'error', detail: e.message || 'KPI hedef kaydı onaylanırken bir hata oluştu!'});
        }
    }


    isDisableByApproval(isApprovedStatus: number) {
        return  (isApprovedStatus > 0);
    }

    isDisableApprove(kpiTargetCustomerMonth){
        if (this.kpiMonthlyMobile?.approvalStatus === 3) {
            if (kpiTargetCustomerMonth?.title === 'region_manager' && kpiTargetCustomerMonth?.isApprovedStatus === 0) {
                return false;
            }else if (kpiTargetCustomerMonth?.title !== 'region_manager' && kpiTargetCustomerMonth?.isApprovedStatus === 2){
                return false;
            }else {
                return true;
            }
        }else if (this.kpiMonthlyMobile?.approvalStatus === 2){
            if (kpiTargetCustomerMonth?.title === 'branch_manager' && kpiTargetCustomerMonth?.isApprovedStatus === 0){
                return false;
            }else if (kpiTargetCustomerMonth?.title !== 'branch_manager' && kpiTargetCustomerMonth?.isApprovedStatus === 1){
                return false;
            }else {
                return true;
            }
        }else {
            return kpiTargetCustomerMonth?.isApprovedStatus >= this.kpiMonthlyMobile?.approvalStatus;
        }
    }

     onChangeKpiTargetCustomerMonthDet(kpiTargetCustomerMonthDet: KpiTargetCustomerMonthDetDTO) {
        console.log('changed',kpiTargetCustomerMonthDet)
        if (kpiTargetCustomerMonthDet?.id){
            kpiTargetCustomerMonthDet.isChanged = true;
        }
    }

    async getCalculatedTargetSources(kpiTargetCustomerMonthDet: KpiTargetCustomerMonthDetDTO) {
      try {
        this.isLoading = true;
        this.airOrSeaGoodSources = await this.kpiService.getCalculatedTargetSources(kpiTargetCustomerMonthDet.id, kpiTargetCustomerMonthDet.month, this.userTransportDirection).toPromise();
        this.isLoading = false;
        if(this.airOrSeaGoodSources && this.airOrSeaGoodSources.length > 0){
           this.showGoodList = true;
        } else {
          this.messageService.add({key: 'tst', severity:'warn', detail: 'Kayıt bulunamadı!' });
        }
      } catch(e) {
        this.isLoading = false;
      }

    }

    customSort(event: SortEvent, kpiTargetCustomerMonthMobile: KpiTargetCustomerMonthMobile) {
        const isGp = (!(kpiTargetCustomerMonthMobile.kpiDef.useGp === null || kpiTargetCustomerMonthMobile.kpiDef.useGp === false));
        event.data.sort((data1, data2) => {

            if (event.field === 'total'){
                const value1 = data1.kpiTargetCustomerDet[isGp  ? 'gpTotal' : 'total'];
                const value2 = data2.kpiTargetCustomerDet[isGp  ? 'gpTotal' : 'total'];

                let result = null;

                if (value1 == null && value2 != null)
                    result = -1;
                else if (value1 != null && value2 == null)
                    result = 1;
                else if (value1 == null && value2 == null)
                    result = 0;
                else if (typeof value1 === 'string' && typeof value2 === 'string')
                    result = value1.localeCompare(value2);
                else
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

                return (event.order * result);
            }else if (event.field === 'totalGpOrTeu'){
                const value1 = isGp ? this.totalTargetCustomerMonthDetGP(data1.kpiTargetCustomerMonthDets) : this.totalTargetCustomerTeu(kpiTargetCustomerMonthMobile.kpiDef, data1.kpiTargetCustomerMonthDets);
                const value2 = isGp ? this.totalTargetCustomerMonthDetGP(data2.kpiTargetCustomerMonthDets) : this.totalTargetCustomerTeu(kpiTargetCustomerMonthMobile.kpiDef, data2.kpiTargetCustomerMonthDets);
                let result = null;

                if (value1 == null && value2 != null)
                    result = -1;
                else if (value1 != null && value2 == null)
                    result = 1;
                else if (value1 == null && value2 == null)
                    result = 0;
                else
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

                return (event.order * result);
            }
            else {
                const value1 = isGp ? data1.kpiTargetCustomerMonthDets[Number(event.field) - 1].target : data1.kpiTargetCustomerMonthDets[Number(event.field) - 1][this.teuText[kpiTargetCustomerMonthMobile.kpiDef.teuText]];
                const value2 = isGp ? data2.kpiTargetCustomerMonthDets[Number(event.field) - 1].target : data2.kpiTargetCustomerMonthDets[Number(event.field) - 1][this.teuText[kpiTargetCustomerMonthMobile.kpiDef.teuText]];
                let result = null;

                if (value1 == null && value2 != null)
                    result = -1;
                else if (value1 != null && value2 == null)
                    result = 1;
                else if (value1 == null && value2 == null)
                    result = 0;
                else if (typeof value1 === 'string' && typeof value2 === 'string')
                    result = value1.localeCompare(value2);
                else
                    result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

                return (event.order * result);
            }
        });
    }

    getCustomers(kpiTargetCustomerMonths: KpiTargetCustomerMobile[]) {
        return kpiTargetCustomerMonths?.map(c => c?.kpiTargetCustomerDet?.customer);
    }
    async showGoodDetail(event: PointerEvent) {
      this.isLoading = true;
      try {
        const resp = await this.listService.goodMobileSearch({'seaGoodId': String(event['data'].id)}, '/sea/list/seaGoodMobileList/listSearch?name=seaGoodMobileList&query=', this.userTransportDirection).toPromise();
        this.displaySeaGoodDetail = true;
        this.listResultItem =  resp.body.datas[0]
        this.selectedGood = null;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }

    }
}
