

export class OfferPackMobileModel {
    id?: number;
    quantity?: number;
    packing?: string;
    measureUnit?: string;
    length?: number;
    width?: number;
    height?: number;
    grossWeight?: number;
    volume?: number;
    loadingMeter?:number;
}
