import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetDocumentService } from 'src/app/services/document.service';
import { FileElement } from '../../documents/documents.component';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {

  folderName: string;

  currentElement: FileElement;

  parentElement: FileElement;

  constructor(public dialogRef: MatDialogRef<RenameDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private intranetDocumentService: IntranetDocumentService) { }

  ngOnInit(): void {

    this.currentElement = this.data.currentElement;
    this.parentElement = this.data.parentElement;

    this.folderName = this.currentElement.intranetDocument.fileName;

    console.log(this.data);

  }

  save() {

    var document = this.currentElement.intranetDocument;

    document.fileName = this.folderName;

    this.intranetDocumentService.update(document).subscribe(res => {

      this.dialogRef.close(document.fileName);

    })

  }

}
