import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../../../../services/company.service';
import {ContainerService} from '../../../../services/container.service';
import {OfferExportService} from '../../../../services/offer-export.service';
import {Company} from '../../../../models/company.model';
import {SeaportMinimizedModel} from '../../../../models/SeaportMinimized.model';
import {Activity} from '../../../../models/activity.model';
import {Equimenet} from '../../../../models/equiment.model';
import {DatePipe} from '@angular/common';
import {error} from 'protractor';
import {MessageService} from 'primeng/api';
import {EquimenetDTO} from '../../../../models/equimentDTO.model';
import { MatDialog } from "@angular/material/dialog";
import { OfferTariffDetailComponent } from '../offer-tariff-detail/offer-tariff-detail.component';
import {CityMinimizedModel} from '../../../../models/city-minimized.model';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-offer-export-list',
    templateUrl: './offer-export-list.component.html',
    styleUrls: ['./offer-export-list.component.scss']
})
export class OfferExportListComponent implements OnInit {

    containersChosen = false;
    isOfferRequest = false;
    editForm: UntypedFormGroup;
    filteredCompanies: Company[] = [];
    companies: Company[] = [];
    listOfOfferExports: any[] = [];
    selectedContainer: any = [];
    selectedContainerOrdered: any = [];
    filteredSeaport: SeaportMinimizedModel[] = [];
    seaport: SeaportMinimizedModel[] = [];
    dataFound = false;
    changeBuyPrice = false;
    loading = false;
    equimenets: any = [];
    filteredCityMinimized: CityMinimizedModel[] = [];
    city: CityMinimizedModel[] = [];

    constructor(private formBuilder: UntypedFormBuilder,   private activetedRouter: ActivatedRoute, private route: Router, private companyService: CompanyService, private dialog: MatDialog, private offerExportService: OfferExportService, public datepipe: DatePipe, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.editForm = this.formBuilder.group({
            pol: ['', [Validators.required]],
            pod: ['', [Validators.required]],
            fromCityId: ['', []],
            toCityId: ['', []],
            armator: ['', []],
            cutOff: ['', []],
            portFilling: ['', []],
            tonnage: ['', []],
            etd: ['', [Validators.required]]
        });
    }

    filterCompany(event) {
        this.offerExportService.getCompany(event.query, ['SO']).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

    filterSeaport(event) {
        this.offerExportService.getOfferExportSeaport(event.query, null).subscribe(res => {
            this.filteredSeaport = res.body;
        });
    }

    search() {
        if (((this.editForm?.get(['pol'])?.value !== '' || this.editForm?.get(['fromCityId'])?.value !== '') &&
            (this.editForm?.get(['pod'])?.value !== '' || this.editForm?.get(['toCityId'])?.value !== ''))  && this.editForm?.get(['etd'])?.value !== ('')) {
            this.loading = true;
            this.dataFound = true;
            this.offerExportService.getSeaFreightBuyTariffDetMobile(
                (this.editForm?.get(['pol'])?.value?.id === undefined ? '' : this.editForm?.get(['pol'])?.value?.id),
                (this.editForm?.get(['pod'])?.value?.id === undefined ? '' : this.editForm?.get(['pod'])?.value?.id),
                (this.editForm?.get(['fromCityId'])?.value?.id === undefined ? '' : this.editForm?.get(['fromCityId'])?.value?.id),
                (this.editForm?.get(['toCityId'])?.value?.id === undefined ? '' : this.editForm?.get(['toCityId'])?.value?.id),
                (this.editForm?.get(['armator'])?.value?.id === undefined ? '' : this.editForm?.get(['armator'])?.value?.id),
                this.datepipe.transform(this.editForm?.get(['etd'])?.value, 'yyyy-MM-dd'),
                this.datepipe.transform(this.editForm?.get(['cutOff'])?.value, 'yyyy-MM-dd'),
                (this.editForm?.get(['tonnage'])?.value === (undefined || null) ? '' : this.editForm?.get(['tonnage'])?.value),
                (this.editForm?.get(['portFilling'])?.value === undefined ? '' : this.editForm?.get(['portFilling'])?.value),
                (this.activetedRouter.snapshot.params.transportDirection == 'import' ? true : false)
            ).subscribe(res => {
                this.listOfOfferExports = res.body;
                if (!this.listOfOfferExports?.length) {
                    this.messageService.add({key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Uygun fiyat bulunamadı!\nAşağıdan yeni teklif veya fiyat girişi yapabilirsiniz.'});
                }
                console.log(this.listOfOfferExports);
                this.loading = false;
            }, err => {
                this.loading = false;
                this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata Oluştu', detail: 'İhracaat talebi bulunamadı...'});
            });
        } else {
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Uyarı', detail: '(POL || POL city) ve (POD || POD city) en az biri ve ETD tarihi ile arama yapınız!'});
        }
    }

    chosen(p: any, ind: number) {
        console.log(p);
        console.log(ind);
        // Miktar alanı ekleniyor. offer-export'da kullanılmak üzere2
        p.seaContainerRezervations.map(s => { Object.assign(s, {count: 1})});

        const isExitsProduct = this.selectedContainer.find(q => q.project.id === p.id);
        if (isExitsProduct) {
            const isExitsContainer = isExitsProduct.project.seaContainerRezervations.find(ct => ct.id === p.seaContainerRezervations[ind].id);
            Object.assign(isExitsContainer, {'selected': !isExitsContainer['selected']});

            // seçili contaner kalmadıysa product'ı sil.
            const selectedIndex = isExitsProduct.project.seaContainerRezervations.findIndex(q => q.selected);
            if(selectedIndex < 0) {
                const projectIndex = this.selectedContainer.findIndex(q => q.project.id === p.id);
                this.selectedContainer.splice(projectIndex, 1);
            } else {}

        } else {
            const selectedContainer = p.seaContainerRezervations.find(ct => ct.id === p.seaContainerRezervations[ind].id);
            Object.assign(selectedContainer, {'selected': true});
            this.selectedContainer.push({project: p});
        }
    }

    checkSelected(project: any, id: number) {
        if (this.selectedContainer.length > 0) {
            const selectedProduct = this.selectedContainer.find(f => f.project.id === project.id);
            if (selectedProduct) {
                const selectedContainer = selectedProduct.project.seaContainerRezervations.find(s => s.id === id);
                if (selectedContainer !== undefined) {
                    return selectedContainer['selected'];
                }
            }
        }
        return false;
    }

    navigateOfferCreate(){
        if(this.activetedRouter.snapshot.params.transportDirection === 'import'){
            this.route.navigate(['activities/offer-request'], { state: { etd: this.editForm?.get(['etd'])?.value, pol:this.editForm?.get(['pol'])?.value , pod:this.editForm?.get(['pod'])?.value}});
        }
        else{
            this.route.navigate(['activities/offer/create'], { state: { etd: this.editForm?.get(['etd'])?.value, pol:this.editForm?.get(['pol'])?.value , pod:this.editForm?.get(['pod'])?.value}});
        }
    }
    
    scrollTop() {
        if (this.selectedContainer?.length > 0) {
            if(this.activetedRouter.snapshot.params.transportDirection == 'import') {
                this.route.navigate([`activities/offer-request`], {state: {projects: this.selectedContainer}});
                return;
            }
            this.containersChosen = true;
            console.log(this.listOfOfferExports);
            console.log(this.selectedContainer);
            // this.listOfOfferExports.forEach(lot => {
            //     this.selectedContainer.forEach(sC => {
            //         if (lot.id === sC.project.id) {
            //             this.selectedContainerOrdered.push(sC);
            //         }
            //     });
            // });
            // console.log(this.selectedContainerOrdered);
            const scrollToPayment = window.setTimeout(() => {
                // this.scrollToElement("beginning");
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 4);
        }
    }

    scrollToElement(element: string): void {
        document.getElementById(element).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    onShowTariffDetail(offer, container) {
        console.log(container);
        this.dialog.open(OfferTariffDetailComponent, {
            width: '780px',
            autoFocus: false,
            data: {
              offer: offer,
              container: container,
              transportDirection: this.activetedRouter.snapshot.params.transportDirection
            }
          });
    }
    onPreviousChange(event) {
        this.containersChosen = event;
        this.selectedContainerOrdered = [];
        this.search();
    }

    filterCity(event) {
        if (event.query.length > 2) {
            this.offerExportService.getOfferExportCity(event.query).subscribe(res => {
                this.filteredCityMinimized = res.body;
            });
        }
    }

    onFocusOutCheckInputIsEmpty(name: string) {
        if (this.editForm?.get([name])?.value?.name === undefined) {
            this.editForm?.controls[name]?.setValue('');
        }
    }
}
