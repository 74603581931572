import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CreateLessonQuizRequest, IntranetLesson, QuizQuestion } from 'src/app/models/learning.model';
import { CreateSurveyRequestModel } from 'src/app/models/survey-models/create-survey-request.model';
import { Question } from 'src/app/models/survey-models/question';
import { LearningService } from 'src/app/services/learning.service';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-create-lesson-quiz',
  templateUrl: './create-lesson-quiz.component.html',
  styleUrls: ['./create-lesson-quiz.component.scss']
})
export class CreateLessonQuizComponent implements OnInit {

  surveyName: string;

  questions: QuizQuestion[] = [];

  isLoading: boolean = false;

  lessonId: number;

  lesson: IntranetLesson = <IntranetLesson>{};

  constructor(private surveyService: SurveyService, private messageService: MessageService, private learningService: LearningService, private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this.lessonId = params['id']


      this.learningService.getLessonWithQuizObjects(this.lessonId).subscribe(res => {
        console.log(res);
        this.lesson = res;


        if (this.lesson.questions != null && this.lesson.questions.length > 0) {

          this.questions = this.lesson.questions;



        } else {

          var question: QuizQuestion = {
            id: null,
            questionAnswers: [
              {
                id: null,
                questionId: null,
                text: '',
                isCorrect: false
              },
              {
                id: null,
                questionId: null,
                text: '',
                isCorrect: false
              }
            ],
            text: ''
          }

          this.questions.push(question);

        }

      })

    });

  }

  ngOnInit(): void {



  }

  newAnswer(question: QuizQuestion) {

    question.questionAnswers.push({
      id: null,
      questionId: null,
      text: '',
      isCorrect: false
    })

  }

  removeAnswer(question: QuizQuestion, index: number) {

    question.questionAnswers.splice(index, 1);

  }

  newQuestion() {

    this.questions.push({
      id: null,
      questionAnswers: [
        {
          id: null,
          questionId: null,
          text: '',
          isCorrect: false
        },
        {
          id: null,
          questionId: null,
          text: '',
          isCorrect: false
        }
      ],
      text: ''
    });

  }

  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  save() {

    var entity: CreateLessonQuizRequest = {
      quizQuestions: this.questions,
      sessionId: null,
      token: null,
      lessonId: this.lessonId
    }

    this.isLoading = true

    this.learningService.createLessonQuiz(entity).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Quiz Başarıyla Oluşturuldu" });


    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })


  }
}
