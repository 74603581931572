import { SimpleEntity } from "./simple-entity.model";
export class SeaContainerRezervations {
    id:number;
    fareAmount: number;
    salesTargetAmount: number;
    buyFareAmount: number;
    tonnage: number;
    totalAmount: number;
    seaContainerType: SimpleEntity;
    currency: SimpleEntity;
    isTargetAmount: boolean = false;
 }


export class SeaFrightRequestDetMobiles {
    id: number;
    name: string;
    vessel: string;
    cutOffDate: Date;
    amount: number;
    buyAmount: number;
    freeTime: number;
    validTo?: string;
    customer: SimpleEntity;
    shipOwner: SimpleEntity;
    fromPort: SimpleEntity;
    toPort: SimpleEntity;
    currency: SimpleEntity;
    seaContainerRezervations:SeaContainerRezervations[] = []
}
