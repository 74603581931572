import { Component, OnInit } from '@angular/core';
import {ListService} from "../../services/list.service.service";
import {ActivatedRoute} from "@angular/router";
import {MenuItem} from "primeng/api";
import {OfferExportService} from "../../services/offer-export.service";
import {SeaportMinimizedModel} from "../../models/SeaportMinimized.model";
import {UserService} from "../../services/user.service";
import {CompanyService} from "../../services/company.service";
import {SimpleEntity} from "../../models/simple-entity.model";
import {CompanyMinimizedModel} from "../../models/company-minimized.model";
import * as XLSX from 'xlsx';
import { v4 } from 'uuid';
import * as FileSaver from 'file-saver'
import {AirMinimizedModel} from "../../models/air-minimized.model";
import * as moment from 'moment';
import {StatusService} from '../../services/status.service';
import {StatusDTO} from '../../models/status-dto.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  transportDirections = [{name: 'KTL International', value: 'I'}, {name: 'KINAY', value: 'E'}]
  result: any = null;
  isLoading: boolean = false;
  items: MenuItem[] = [{label: 'Detay', icon: 'pi pi-fw pi-search', command: () => this.view(this.selectedRow)}];
  selectedRow: any;
  tmaxxSearchEndPoints =  {
    'airPositionIntranet': '/air/list/airPositionList/listSearch?name=airPositionList',
    'airGoodIntranet': '/air/list/airGoodList/listSearch?name=airGoodList',
    'offerAirDetailList': '/offers/list/offerAirDetailList/listSearch?name=offerAirDetailList',
    'seaPositionIntranet': '/sea/list/seaPositionList/listSearch?name=seaPositionList',
    'seaGoodIntranet': '/sea/list/seaGoodList/listSearch?name=seaGoodList',
    'truckGoodIntranet': 'truck/list/truckGoodList/listSearch?name=truckGoodList',
    'truckPositionIntranet' : 'truck/list/truckPositionList/listSearch?name=truckPositionList',
    'offerSeaDetailList' : '/offers/list/offerSeaDetailList/listSearch?name=offerSeaDetailList'
  }
  tmaxxExportExcel = {
    'airPositionIntranet': '/air/list/airPositionList/exportToExcel?name=airPositionList',
    'airGoodIntranet': '/air/list/airGoodList/exportToExcel?name=airGoodList',
    'offerAirDetailList': '/offers/list/offerAirDetailList/exportToExcel?name=offerAirDetailList',
    'seaPositionIntranet': '/sea/list/seaPositionList/exportToExcel?name=seaPositionList',
    'seaGoodIntranet': '/sea/list/seaGoodList/exportToExcel?name=seaGoodList',
    'truckGoodIntranet': 'truck/list/truckGoodList/exportToExcel?name=truckGoodList',
    'truckPositionIntranet' : 'truck/list/truckPositionList/exportToExcel?name=truckPositionList',
    'offerSeaDetailList' : '/offers/list/offerSeaDetailList/exportToExcel?name=offerSeaDetailList'
  }
  selectedTransportDirection = 'I';
  filterDates = [
    {
      label:'Açılış Tarihi',
      value: 'createDate'
    },
    {
      label:'Rapor Tarihi',
      value: 'activityDate'
    },
    {
      label:'Tahmini Varış (ETA)',
      value: 'eta'
    },
    {
      label:'Tahmini Kalkış',
      value: 'etd'
    },
    {
      label:'Gerçekleşen Varış (ATA)',
      value: 'ata'
    },
    {
      label:'Gerçekleşen Kalkış Tarihi',
      value: 'atd'
    },
    {
      label:'Teyid Tarihi',
      value: 'confirmationDate'
    }
  ]
  selectedFilterDate = 'createDate';

  /* TODO
  *  Durum ve Alt Durum filtresi eksik.
  *  Filtreleri Hava Pozisyon, Deniz Yük, Hava Yük içinde kontrol et ekle.
  * */


  // Todo localStorage den alınacak.
  transportDirection: any[] = [
    {
      name: 'Taşıma Yönü',
      code: null
    },
    {
      name:'Transit',
      code: 'T'
    },
    {
      name:'İhracat',
      code: 'E'
    },
    {
      name:'İthalat',
      code: 'I'
    },
    {
      name:'Yurtiçi',
      code: 'D'
    },
  ]
  kp: any[] = [
    {
      name: 'K/P',
      code: null
    },
    {
      name: 'Komple',
      code: "F"
    },
    {
      name: 'Parsiyel',
      code: "P"
    },
  ]
  whoseBusiness = [
    {
      name: 'Kimin İşi',
      code: null
    },
    {
      name: 'Bizim',
      code: 'B'
    },
    {
      name: 'Acente',
      code: 'A'
    },
    {
      name: 'Ortak',
      code: 'O'
    },
  ]

  filteredSeaports: SeaportMinimizedModel[] = [];
  filteredUsers: SimpleEntity[] = [];
  filteredBranch: SimpleEntity[] = [];
  filteredCompanies: CompanyMinimizedModel[] = [];
  filteredAirlines: CompanyMinimizedModel[] = [];
  filteredAirport: AirMinimizedModel[] = [];
  filteredStatus: StatusDTO[] = [];
  filters = {
    transportDirection: {
      prefix: 'transportDirection',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airGoodIntranet']
    },
    kp: {
      prefix: 'partialFull',
      value: '',
      usedLists: ['seaPositionIntranet', 'airPositionIntranet', 'seaGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    referenceNo: {
      prefix: 'referenceNo/LIKE',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    customerReferenceNo: {
      prefix: 'seaGoods.customerRef/JOIN',
      value: '',
      usedLists: ['seaPositionIntranet']
    },
    mblNumber: {
      prefix: 'masterBlNo/LIKE',
      value: '',
      usedLists: ['seaPositionIntranet']
    },
    fromPort: {
      prefix: 'fromPort.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    toPort: {
      prefix: 'toPort.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    fromPortOfferSea: {
        prefix: 'offerSea.fromPort.id',
        value: '',
        usedLists: ['offerSeaDetailList']
    },
    toPortOfferSea: {
        prefix: 'offerSea.toPort.id',
        value: '',
        usedLists: ['offerSeaDetailList']
    },
    fromPortOfferAir: {
        prefix: 'offerAir.fromPort.id',
        value: '',
        usedLists: [ 'offerAirDetailList']
    },
    toPortOfferAir: {
        prefix: 'offerAir.toPort.id',
        value: '',
        usedLists: [ 'offerAirDetailList']
    },
    fromAirport: {
      prefix: 'fromPort.id',
      value: '',
      usedLists: ['airGoodIntranet']
    },
    toAirport: {
      prefix: 'toPort.id',
      value: '',
      usedLists: ['airGoodIntranet']
    },
    operationRepresentative: {
      prefix: 'operationRepresentative.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet']
    },
    salesRepresentative: {
      prefix: 'salesRepresentative.id',
      value: null,
      usedLists: ['seaGoodIntranet', 'airGoodIntranet', 'truckGoodIntranet']
    },
    salesRepresentativePosition: {
      prefix: 'salesRepresentative',
      value: null,
      usedLists: ['seaPositionIntranet', 'truckPositionIntranet', 'airPositionIntranet']
    },
    salesRepresentativeOfferSea:{
        prefix: 'offerSea.salesRepresentative.id',
        value: null,
        usedLists: ['offerSeaDetailList']
    },
    salesRepresentativeOfferAir:{
        prefix: 'offerAir.salesRepresentative.id',
        value: null,
        usedLists: ['offerAirDetailList']
    },
    customer: {
      prefix: 'customer.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    customerId: {
      prefix: 'customerId',
      value: '',
      usedLists: ['airPositionIntranet']
    },
    customerIdOfferSea: {
        prefix: 'offerSea.customer.id',
        value: '',
        usedLists: ['offerSeaDetailList']
    },
    customerIdOfferAir: {
        prefix: 'offerAir.customer.id',
        value: '',
        usedLists: ['offerAirDetailList']
    },
    shipOwner: {
      prefix: 'shipOwner.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'offerSeaDetailList']
    },
    lineAgent: {
      prefix: 'lineAgent.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    abroadAgent: {
      prefix: 'abroadAgent.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet']
    },
    shipper: {
      prefix: 'shipper.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    receiver: {
      prefix: 'receiver.id',
      value: '',
      usedLists: ['seaPositionIntranet']
    },
    sender: {
      prefix: 'sender.id',
      value: '',
      usedLists: ['airPositionIntranet', 'airGoodIntranet']
    },
    status: {
      prefix: 'status.id',
      value: '',
      usedLists: ['airGoodIntranet']
    },
    consignee: {
      prefix: 'consignee.id',
      value: '',
      usedLists: ['airPositionIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    consigneeOfferSea: {
        prefix: 'offerSea.consignee.id',
        value: '',
        usedLists: ['offerSeaDetailList']
    },
    consigneeOfferAir: {
        prefix: 'offerAir.consignee.id',
        value: '',
        usedLists: ['offerAirDetailList']
    },
    creationDateStart: {
      prefix: 'createDate/DT/GT',
      value: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet', 'offerSeaDetailList', 'offerAirDetailList']
    },
    creationDateEnd: {
      prefix: 'createDate/DT/LT',
      value: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet', 'offerSeaDetailList', 'offerAirDetailList']
    },
    activityDateStart: {
      prefix: 'activityDate/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet', 'offerSeaDetailList', 'offerAirDetailList']
    },
    activityDateEnd: {
      prefix: 'activityDate/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet', 'airPositionIntranet', 'airGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet', 'offerSeaDetailList', 'offerAirDetailList']
    },
    etaDateStart: {
      prefix: 'eta/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    etaDateEnd: {
      prefix: 'eta/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    etdDateStart: {
      prefix: 'etd/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    etdDateEnd: {
      prefix: 'etd/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    ataDateStart: {
      prefix: 'ata/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    ataDateEnd: {
      prefix: 'ata/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    atdDateStart: {
      prefix: 'atd/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    atdDateEnd: {
      prefix: 'atd/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    confirmationDateStart: {
      prefix: 'confirmationDate/DT/GT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    confirmationDateEnd: {
      prefix: 'confirmationDate/DT/LT',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']
    },
    hblNo: {
      prefix: 'hblNo/LIKE',
      value: '',
      usedLists: ['seaGoodIntranet']
    },
    whoseBusiness: {
      prefix: 'whoseBusiness',
      value: '',
      usedLists: ['seaGoodIntranet', 'truckGoodIntranet', 'truckPositionIntranet']
    },
    airline: {
      prefix: 'e.airline.id/JOIN',
      value: '',
      usedLists: ['airPositionIntranet']
    },
    preferredAirline: {
      prefix: 'prefferedAirline.id',
      value: '',
      usedLists: ['airGoodIntranet']
    },
    branch: {
      prefix: 'branch.id',
      value: '',
      usedLists: ['seaPositionIntranet', 'airPositionIntranet', 'seaGoodIntranet']
    },
    salesRepBranch: {
      prefix: '#branchid#',
      value: '',
      usedLists: ['seaPositionIntranet', 'seaGoodIntranet']

    },
    bookingNo: {
      prefix: 'bookingNo',
      value: '',
      usedLists: [ 'seaGoodIntranet']

    },
    seaContainerNo: {
      prefix: 'seaContainers.no/JOIN/LIKE',
      value: '',
      usedLists: [ 'seaGoodIntranet']
    },
    positionSeaContainerNo: {
          prefix: 'SEAPOSITIONCONTAINER/LIKE',
          value: '',
          usedLists: ['seaPositionIntranet']
    },
    getParams: () => {
      let params = {};
      Object.keys(this.filters).forEach(k => {
        if(typeof this.filters[k] == "function") return;
        // @ts-ignore
        if(this.isFilterUse(k) && this.filters[k].value) {
          // @ts-ignore
          let value = this.filters[k].value;
          if(typeof value  == 'object') {
            value = value instanceof Date ? value.toISOString().slice(0, -1) : ((this.filters[k] === this.filters.fromAirport || this.filters[k] === this.filters.toAirport) ? value.id : (value.code || value.id));
          }

            if(this.filters[k] === this.filters.status) {
                value = this.filters[k].value.id;
            }

          // date format fix
          if(this.filters[k].prefix.includes('/DT/')) {
            let d = new Date(value);
            d.setDate(d.getDate() +1);
            if(this.filters[k].prefix.includes('/GT') ) {
              value = d.toISOString().split('T')[0] + 'T00:00:00.000'
            }
            if(this.filters[k].prefix.includes('/LT') ) {
              value = d.toISOString().split('T')[0] + 'T23:59:59.000'
            }
          }
          Object.assign(params, {[this.filters[k].prefix]: value})
        }
      })
      return params;
    }
  }
  userPosition: string = null;
  user: any = null;

  showPreviousBtn: boolean = false;
  listResultItem: any = null;
  showSeaDetailDialog: boolean = false;
    showAirDetaiLDialog: boolean = false;
  displaySeaGoodDetail: boolean = false;
  displayAirGoodDetail: boolean = false;
  isEnableSalesRepFilter: Boolean = false;

  constructor(private listService: ListService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private companyService: CompanyService,
              private offerExportService: OfferExportService,
              private statusService: StatusService) {

  this.bindSalesOrOperationAndBranchFilter();

  }

  async ngOnInit() {
   this.isEnableSalesRepFilter = await this.userService.function("USER_ENABLE_SALESREP_FILTER", this.selectedTransportDirection).toPromise();
  }

  async getData(params = null) {
     return this.listService.search(params, this.tmaxxSearchEndPoints[this.activatedRoute.snapshot.params.endPoint], this.selectedTransportDirection).toPromise();
  }
  async search() {
    try {
      this.isLoading = true;
      let par = this.filters.getParams();
      this.result = await this.getData(par);
      console.log(this.result, "ressss")
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }

  }

  async exportToExcel() {
    if(!this.result) return;
    var rows = [];
    var header = [];
    const colSizes = [];
    const rowSizes = [];

    //UFUK : Headerlari ekliyoruz
    for (let column of this.result.dynamicListFieldDefs) {
      header.push(column["header"]);
      colSizes.push({ wch: column["width"] / 7 });//7 ekrandakine yakın olsun diye oranti normalde width 100 150 geliyor excelin olcu birimi farkli
    }
    rows.push(header);

    //UFUK : Datayi ekliyoruz

    let filteredOrFull: any[];
    filteredOrFull = this.result.datas;



    for (let data of filteredOrFull) {
      rowSizes.push({ hpt: 25 });
      var row = [];
      for (let column of this.result.dynamicListFieldDefs) {//UFUK stunlarda gozukmeyen id posid index vs alanlari aktarmıyoruz o yuzden stun bazlı donuyoruz
        if (column.type == "number") {
          let number = String(data[column.id]);
          //let number = data[column.id].replace(/\./g, '');
          //number = number.replace(/\,/g, '.');

          // column type number olsa da subQuery ile gelen sonuç 4.222,33 gibi olabiliyor
          // değer 4.000,33 gibi bir rakkamsal değer olduğunu anlıyoruz.
          if(number.indexOf(',') > -1 && number.indexOf('.') > -1) {
            number = number.replace(/\./g, '').replace(/\,/g, '.');
          } else {
            // bu durumda 2.202 gibi bir rakkamsal değer olduğunu anlıyoruz.
            number = number.replace(/\,/g, '.');
          }

          row.push(Number(number));
        } else if (column.type == "date") {
          if (data[column.id] != null) {
            try {
              let date = moment(data[column.id], 'YYYY.MM.DD');
              row.push(date.format('DD.MM.YYYY'));
            } catch (error) {
              row.push(data[column.id]);
            }
          } else {
            row.push(null);
          }
        } else {
          row.push(data[column.id]);
        }
      }
      rows.push(row);
      row = [];
    }

    var row = [];
    var emptyRow = [];

    for (let column of this.result.dynamicListFieldDefs) {//UFUK toplamları ekliyoruz
      emptyRow.push("");
      if (column == null || column == "") {
        row.push("");
      } else {
        console.log(column)
        row.push(this.getTotal(column, false));
      }
    }

    rows.push(emptyRow);
    rows.push(row);

    const workSheet = XLSX.utils.json_to_sheet(rows, { skipHeader: true, dateNF: 'dd/MM/yyyy', cellDates: true });
    workSheet['!cols'] = colSizes;
    workSheet['!rows'] = rowSizes;
    //const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Tmaxx');
    XLSX.writeFile(workBook, this.result.dynamicListDef.name + ".xlsx");
  }

  async exportFromTmaxx() {
    this.isLoading = true;
    try {
      let result = await this.listService.exportExcel(this.filters.getParams(), this.tmaxxExportExcel[this.activatedRoute.snapshot.params.endPoint], this.selectedTransportDirection).toPromise();
      let blob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      this.isLoading = false;
      FileSaver.saveAs(blob,  `${v4()}.xlsx`);
    } catch (e) {
      this.isLoading = false
    }


  }

  async view(item: any) {
    // SeaGood Detail
    if(this.activatedRoute.snapshot.params.endPoint == 'seaGoodIntranet') {
      const resp = await this.goodMobileSearch({'seaGoodId': item['id'].toString()}, '/sea/list/seaGoodMobileList/listSearch?name=seaGoodMobileList&query=')
      console.log(resp, "resp")
      this.displaySeaGoodDetail = true;
      this.showPreviousBtn = false;
      this.listResultItem =  resp['datas'][0]
      if(this.listResultItem)  this.showSeaDetailDialog = true;
    }
    // SeaPosition Detail
    if(this.activatedRoute.snapshot.params.endPoint == 'seaPositionIntranet') {
      const resp = await this.goodMobileSearch({'seaPositionId': item['id'].toString()}, '/sea/list/seaGoodMobileList/listSearch?name=seaGoodMobileList&query=')
      this.listResultItem =  resp;
      this.displaySeaGoodDetail = false;
      this.showPreviousBtn = true;
      if(this.listResultItem)  this.showSeaDetailDialog = true;
    }
      // AirGood Detail
      if(this.activatedRoute.snapshot.params.endPoint == 'airGoodIntranet') {
          const resp = await this.goodMobileSearch({'airGoodId': item['id'].toString()}, '/air/list/airGoodMobileList/listSearch?name=airGoodMobileList&query=')
          console.log(resp, "resp")
          this.displayAirGoodDetail = true;
          this.showPreviousBtn = false;
          this.listResultItem =  resp['datas'][0]
          if(this.listResultItem)  this.showAirDetaiLDialog = true;
      }
  }
  isFilterUse(filterName) {
    return this.filters[filterName].usedLists.includes(this.activatedRoute.snapshot.params.endPoint)
  }

  getUserByTransportDirection(transportDirection) {
    if(transportDirection == 'I' && localStorage.getItem('import')) {
      return JSON.parse(localStorage.getItem('import')).user
    }
    if(transportDirection == 'E' && localStorage.getItem('export')) {
      return JSON.parse(localStorage.getItem('export')).user
    }

    return JSON.parse(localStorage.getItem('user'))
  }

  onChangeDate() {
    this.filters.etaDateStart.value = null;
    this.filters.etaDateEnd.value = null;
    this.filters.etdDateStart.value = null;
    this.filters.etdDateEnd.value = null;
    this.filters.ataDateStart.value = null;
    this.filters.ataDateEnd.value = null;
    this.filters.atdDateStart.value = null;
    this.filters.atdDateEnd.value = null;
    this.filters.creationDateStart.value = null;
    this.filters.creationDateEnd.value = null;
    this.filters.activityDateStart.value = null;
    this.filters.activityDateEnd.value = null;

  }

  formatValue(column, value) {
    if (column?.type == "number") {
      let number = value;
      return new Intl.NumberFormat("tr-TR", {  style: 'currency', currency: 'TRY',currencyDisplay: "code"  }).formatToParts(number).filter(x => x.type !== "currency").map(x => x.value).join("").trim();
    } else if (column.type == "date") {
      if (value != null) {
        try {
          return moment(value, 'YYYY-MM-DDT00:00:00.000').format('DD.MM.YYYY');
        } catch (error) {
          return "";
        }
      }
    } else {
      return value;
    }
    return value;
  }

  getTotal(column, formatted = true) {
    let sum = 0;
    if (column.type == "number" && this.result) {

      this.result.datas.forEach( f =>  sum += Number(f[column.id]))
      if(formatted) return this.formatValue(column, sum);
      return sum;
    }
    return "";
  }
  async bindSalesOrOperationAndBranchFilter() {
    const user =   this.getUserByTransportDirection(this.selectedTransportDirection);
    this.userPosition = user.position;
    Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
    this.filters.branch.value = null;
    this.filters.operationRepresentative.value = null;
    this.filters.salesRepresentative.value = null;
    this.filters.salesRepresentative.value = user;
    this.filters.salesRepresentativePosition.value = null;
    this.filters.salesRepresentativePosition.value = user;
    this.filters.salesRepresentativeOfferSea.value = null;
    this.filters.salesRepresentativeOfferSea.value = user;

    if(this.selectedTransportDirection == 'I') {
      this.filters.salesRepBranch.value = null;

      if(this.userPosition != 'SR') {
        this.filters.branch.value = user.branch;
      }

    } else {
      this.filters.branch.value = null;
      if(user.task == 'SP') {
        this.filters.branch.value = null;
        this.filters.salesRepBranch.value = user.branch;
      }
    }



    this.isEnableSalesRepFilter = await this.userService.function("USER_ENABLE_SALESREP_FILTER", this.selectedTransportDirection).toPromise()


    // @ts-ignore
    if(user.position == 'OR') {
      this.filters.operationRepresentative.value = user;
    }
  }

    async goodMobileSearch(param, url) {
        let response = [];
        await this.listService.goodMobileSearch(param, url, this.selectedTransportDirection).toPromise().then(res => {
            response = res.body;
        });
        return response;
    }

  onCloseDetail() {
    this.showSeaDetailDialog = false;
    this.showAirDetaiLDialog = false;
  }

  filterSeaport(event) {
    this.offerExportService.getOfferExportSeaport(event.query, null, this.selectedTransportDirection).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }
  filterUsers(event) {
    this.userService.searchUsers(event.query, this.selectedTransportDirection).subscribe(res => {
      this.filteredUsers = res;
    })
  }
  filterCompany(event, params) {
    if (event.query.lenght < 3) return;
    this.companyService.search(Object.assign(params, {'name/SW"': event.query}), this.selectedTransportDirection, false).subscribe(res => {
      this.filteredCompanies = res;
    });
  }
  filterAirline(event) {
    if (event.query.length > 1) {
      this.offerExportService.airlineSearch(event.query, this.selectedTransportDirection).subscribe(res => {
        this.filteredAirlines = res.body;
      });
    }
  }
  filterAirport(event) {
    if (event.query.length > 1) {
      this.offerExportService.airportSearch(event.query, this.selectedTransportDirection).subscribe(res => {
        this.filteredAirport = res.body;
      });
    }
  }
  filterBranch(event) {
    if (event.query.length > 1) {
      this.companyService.searchBranchByName(event.query, this.selectedTransportDirection).subscribe(res => {
        this.filteredBranch = res;
      });
    }
  }

  filterOperationTypeAndStatus(event) {
      if (event.value && this.isFilterUse('status')) {
          this.statusService.getAllByOperationTypeAndStatusTypeAndTransportDirection('AIR', event.value.code, 'GOOD', this.selectedTransportDirection).subscribe(res => {
             this.filteredStatus = res.body;
          });
      }
  }

}
