import {SimpleEntity} from '../simple-entity.model'
import {AbstractEntity} from '../abstract-entity.model'

export class FinancialQueryModel extends AbstractEntity {

    name: string;
    taxNo: string;
    tckn: string;
    monthlyPlannedGoodInformation: string;
    monthlyPlannedPrice: number;
    requestedDueDay: number;
    requestedPaymentType: string;
    operationType: string;
    transportDirection: string;
    status: string;
    finalNote: string;
    finalPaymentType: string;
    finalDueDay: number;
    goodType: string;
    resultingCreditLimit: number;
    goodAmount: number;
    profit: number;
    avgFreightAmount: number;

// description
    mainActivityCodeAndName: string
    jobStartDate: string;
    numberOfWrittenChecks: number;
    pricesOfChecksBePaid: number;
    averagePricesOfChecksBePaid: number;
    unpaidChecks: number;
    unpaidChecksAmount: number;
    firstCheckDate: string;
    paidChecks: number;
    paidChecksAmount: number;
    lastCheckDate: string;
    numberOfChecksWillBePaid: number;
    pricesOfChecksWillBePaid: number;
    averagePricesOfChecksWillBePaid: number;
    totalLimitAmount : number;
    cashRiskAmount: number;
    nonCashRiskAmount: number;
    borrowingLimitRatio: number;
    lastCreditUsageDate: string;
    plannedVolumeLimitClearance: number;

    goodAmountCurrency: SimpleEntity;
    salesRepresentative: SimpleEntity;
    resultingCreditLimitCurrency: SimpleEntity;

    taxOffice: SimpleEntity;
    currency: SimpleEntity;
    requestUser: SimpleEntity;
    region: SimpleEntity;
    company: SimpleEntity;
    companyFinancial: SimpleEntity;

}
