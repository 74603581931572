import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferExportService} from '../../../../../services/offer-export.service';
import {DatePipe} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Company} from '../../../../../models/company.model';
import {FormArray} from "@angular/forms";
import {DashboardService} from "../../../../../services/dashboard.service";
import {getbaseUrl} from 'ngx-doc-viewer';
import {Router} from "@angular/router";
import {InfoManagerService} from '../../../../../services/info-manager.service';

@Component({
    selector: 'app-card-air-list',
    templateUrl: './card-air-list.component.html',
    styleUrls: ['./card-air-list.component.scss']
})
export class CardAirListComponent implements OnInit {
    @Input() id?: number;
    @Input() airGood: any;
    @Input() airGoods: any;
    @Input() showPreviousBtn: boolean = true;
    @Input() displayAirGoodDetail: boolean = false;
    @Output() previousPage = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter<any>();
    @Input() selectedTransportDirection: string;
    @Input() dashboardDetail: any = null;

    isLoading = false;
    displayAddInvoiceItem = false;
    displayAppropriateOfferDialog = false;
    containers: any[] = [];
    reportList: any;
    invoiceDataPositionLenght: number;
    invoiceDataGoodLenght: number;
    goodOrPositionState = [{name: 'Pozisyon', json: 'POSITION'}, {name: 'Yük', json: 'GOOD'}];
    state = {name: 'Pozisyon', json: 'POSITION'};

    unitOpt = ['piece', 'm3', 'kg', '20 DC', '20 FR', '20 OT', '20 RF', '40 DC', '40 FR', '40 HC', '40 OT', '40 PW', '40 RF', '45 HC'];
    unit: any;

    incomeCostOpt = [{code:'DB', name:'Gelir'}, {code:'CR', name: 'Gider'}];
    incomeCost: any;

    statusType = [{code:'I', name:'İptal Edildi'}, {code:'O', name: 'Onaylandı'}, {code:'B', name: 'Bekleniyor'}, {code:'D', name: 'Kesin Değil'}, {code:'F', name: 'Faturalı'}, {code:'A', name: 'Kabul Edilmedi'}];

    invoiceTypes: any[] = [{value: 'CN', label: 'Alacak Dekontu'}, {value: 'DN', label: 'Borç Dekontu'}, {value: 'IN', label: 'Fatura'}, {value: 'RI', label: 'İade Fatura'}, {value: 'IR', label: 'İç Dekont'}, {value: 'OC', label: 'Operasyon Gider'}];

    invoiceItemType: any[];
    invoiceItem: any[];

    currencyType: any[];
    currency: any[];

    invoiceAmount: any;
    invoiceBuyamount: any;

    filteredCompanies: Company[] = [];
    companies: Company[] = [];

    invoiceDataPosition: any = [];
    invoiceDataGood: any = [];

    appropriatedOffers: any[] = [];

    sessionUser: any = null;
    infoManagerDefId: number;
    infoManagerDefs: any[] = [];
    infoManagers: any[] = [];



    constructor(private infoManagerService: InfoManagerService, private offerExportService: OfferExportService, private confirmService: ConfirmationService, private dahsboardService: DashboardService, public datepipe: DatePipe, private messageService: MessageService, private route: Router) {
        this.sessionUser = JSON.parse(localStorage.getItem('user'));
    }

    ngOnInit(): void {
        console.log(this.selectedTransportDirection, "tranposrt!")
        if(this.id) this.listSearch(this.id);

        this.getAllInfoManagerDef();
        this.getAllInfoManagers();
    }

    onVisibleChange() {
        this.previousPage.emit(false)
        this.onClose.emit(null);
    }
    listSearch(id) {
        this.isLoading = true;
        const listName = this.dashboardDetail.operationType.toLowerCase() + this.dashboardDetail.positionGoodType[0].toUpperCase() + this.dashboardDetail.positionGoodType.toLowerCase().slice(1);
        const url = `/air/list/${listName}MobileList/listSearch?name=${listName}MobileList&query=%7B%22dashboardOperationId%22%3A%22` + id + '%22%7D';
        this.offerExportService.listSearch(url, this.selectedTransportDirection).subscribe(res => {
            this.airGoods = res.body;
            this.isLoading = false;
        });
    }

    sepreateContainerInformation() {
        const containerArray = this.airGood.containerInfo.split(',');
        containerArray.forEach(ct => this.containers.push({name: ct.split(':')[0], count: ct.split(':')[1]}));
        console.log(this.containers);
    }

    filterCompany(event) {
        this.offerExportService.getCompany(event.query, undefined, undefined, this.selectedTransportDirection).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

    filterInvoiceItem(event) {
        this.offerExportService.invoiceItemType('AIR', this.selectedTransportDirection).subscribe(res => {
            this.invoiceItemType = res.body;
            this.invoiceItem = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.invoiceItem.forEach(country => {
                if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(country);
                }
            });
            this.invoiceItemType = filtered;
        });
    }

    filterCurrency(event) {
        this.offerExportService.getAllCurrency(this.selectedTransportDirection).subscribe(res => {
            this.currencyType = res.body;
            this.currency = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.currency.forEach(country => {
                if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(country);
                }
            });
            this.currencyType = filtered;
        });
    }

    createOrUpdateInvoiceItems() {
        this.isLoading = true;
        let objParse = [];
        objParse = objParse.concat(this.invoiceDataPosition);
        objParse = objParse.concat(this.invoiceDataGood);
        objParse.forEach(inv => {
            if (inv.dc?.json) {
                inv.dc = inv.dc.json;
            }
        });
        this.offerExportService.createOrUpdateInvoiceItems( objParse, this.selectedTransportDirection).subscribe(res => {
                this.isLoading = false;
                this.invoiceDataGood = res.body.goodInvoiceItems.reverse();
                this.invoiceDataPosition = res.body.positionInvoiceItems.reverse();
                this.invoiceDataPositionLenght = this.invoiceDataPosition?.length;
                this.invoiceDataGoodLenght = this.invoiceDataGood?.length;
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Fatura Kalemi Başarıyla Eklendi!'});
        }, error => {
            this.isLoading = false;
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: error.error ? error.error.message : error.message});
        });
    }

    getInvoiceItemsWithEntityId(id?: number) {

        this.offerExportService.getInvoiceItemsWithEntityId(id, 'AIR', 'GOOD', this.selectedTransportDirection).subscribe(res => {
            this.invoiceDataGood = res.body.goodInvoiceItems;
            this.invoiceDataPosition = res.body.positionInvoiceItems;
            this.invoiceDataPositionLenght = this.invoiceDataPosition?.length;
            this.invoiceDataGoodLenght = this.invoiceDataGood?.length;
        });
    }

    addInvoiceData(goodOrPosition?: string) {
        if (goodOrPosition === 'Good') {
            this.invoiceDataGood.push(this.getInvoiceItemData('Good'));
        }
        if (goodOrPosition === 'Position') {
            this.invoiceDataPosition.push(this.getInvoiceItemData('Position'));
        }
    }

    getInvoiceItemData(goodOrPosition?: string) {
        return {
            entityid: goodOrPosition === 'Good' ? this.airGood.id : this.airGood.positionId,
            status: 'B',
            dc: 'DB',
            operationType: 'AIR',
            transportDirection: this.airGood.transportDirection,
            positionGoodType: this.state.json,
            amount: 0,
            vatAmount: 0,
            totalAmount: 0,
            grandTotal: 0,
            quantity: 1,
            unit: '',
            description: null,
            invoiceItemType: {},
            currency: {},
            company: {}
        };
    }

    deleteItem(idx: number, goodOrPosition?: string) {
        if (goodOrPosition === 'Good') {
            this.invoiceDataGood.splice(idx, 1);
        }
        if (goodOrPosition === 'Position') {
            this.invoiceDataPosition.splice(idx, 1);
        }
    }

    showAppropriateOfferDialog(seaGoodId: any) {
        this.displayAppropriateOfferDialog = true;
        this.dahsboardService.getAppropriateOfferSeasWithSeaGoodId(seaGoodId, this.selectedTransportDirection).subscribe(r => this.appropriatedOffers = r);
    }

    setSeaGoodToOffer(seaGoodId: any, offerId) {
        this.confirmService.confirm({
            key:'confirmSeaList',
            header: 'İşlem Onayı',
            message: 'İşleme devam etmek istediğinize emin misiniz?',
            accept: () => {
                this.isLoading = true;
                this.dahsboardService.setOfferSeaDetailToSeaGoodWithId(seaGoodId, offerId, this.selectedTransportDirection).subscribe(() => {
                    this.displayAppropriateOfferDialog = false;
                    this.isLoading = false;
                    this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kayıt başarılı." });
                }, () =>  this.isLoading = false)
            }
        })
    }

    deleteInvoiceItemWithId(id: number, index: number) {
        this.isLoading = true;
        this.offerExportService.deleteInvoiceItemWithId(id, this.selectedTransportDirection).subscribe(res => {
            if (res.status === 200) {
                this.invoiceDataGood.splice(this.invoiceDataGood.findIndex(inv => inv.id === id), 1);
                this.invoiceDataPosition.splice(this.invoiceDataPosition.findIndex(inv => inv.id === id), 1);
                this.messageService.add({key: 'messageSeaList', severity: 'success', summary: 'Başarılı', detail: 'Fatura Kalemi silindi!'});
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.messageService.add({key: 'messageSeaList', severity: 'warn', summary: 'Hata', detail: error.error ? error.error.message : error.message});
        });
    }

    profitAndLossReportList() {
        this.offerExportService.getAllReportList(0, 'isSea', this.selectedTransportDirection).subscribe(res => {
            this.offerExportService.getReportAsPDF({
                    reportId: res.body[0].id,
                    viewType: '.pdf',
                    userId: this.selectedTransportDirection === 'E' ? JSON.parse(localStorage.getItem('user')).id : JSON.parse(localStorage.getItem('userimport')).id,
                    parameters: {
                        entityid: this.airGood.positionId
                    },
                },
                '/document/def/report/getReport/',
                this.selectedTransportDirection).subscribe(pdf => {
                const file = new Blob([pdf], {type: 'application/pdf'});
                const fileUrl = window.URL.createObjectURL(file);
                window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
            });
        });
    }

    getReportAsPDF(id?: number) {
        this.offerExportService.getAllReportList(90332, 'isSea', this.selectedTransportDirection).subscribe(res => {
            this.offerExportService.getReportAsPDF({
                    reportId: res.body[0].id,
                    viewType: '.pdf',
                    userId: this.selectedTransportDirection === 'E' ? JSON.parse(localStorage.getItem('user')).id : JSON.parse(localStorage.getItem('userimport')).id,
                    parameters: {
                        entityid: id
                    },
                },
                '/document/def/report/getReport/',
                this.selectedTransportDirection).subscribe(pdf => {
                const file = new Blob([pdf], {type: 'application/pdf'});
                const fileUrl = window.URL.createObjectURL(file);
                window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
            });
        });
    }

    showInvoiceList() {
        this.isLoading = true;
        const url = '/finance/invoiceEdit/listSearch?name=invoiceEdit&query=%7B%22entityid%2FLNG%22%3A' + (this.state.name === 'Yük' ? this.airGood.id : this.airGood.positionId) + '%7D';
        this.offerExportService.listSearch(url, this.selectedTransportDirection).subscribe(res => {
            this.reportList = res.body;
            this.isLoading = false;
        }, error => this.isLoading = false);
    }

    getSeaGood(id: number) {
        this.offerExportService.getSeaGood(id, 'E').subscribe(res => {
            console.log(res.body);
        });
    }

    filterInvoiced(tariff: any, trueOrFalse: boolean) {
        if (trueOrFalse === true) {
            return tariff?.filter(tff => tff?.status === 'F');
        } else {
            return tariff?.filter(tff => tff?.status !== 'F');
        }
    }

    openTheCopy(id: any, transportDirection: any) {
        window.open(getbaseUrl() + '/activities/air-offer-detail/' + id, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
    }

    setOfferAirDetailToAirGoodWithId(airGood: any) {
        this.route.navigate([`/activities/air-offer-create`], {state: {airGood}});
    }

    async createEntityInfoManager() {
        this.isLoading = true;
        await this.infoManagerService.createEntityInfoManager(this.selectedTransportDirection,'airGood', this.airGood?.id, this.infoManagerDefId).toPromise();
        await this.getAllInfoManagers();
        this.infoManagerDefId = null;
        this.isLoading = false;
    }

    async deleteById(id) {
        this.isLoading = true;
        await this.infoManagerService.deleteById(this.selectedTransportDirection,id).toPromise();
        await this.getAllInfoManagers();
        this.isLoading = false;
    }
    async getAllInfoManagers(){
        console.log('infomanager');
        this.infoManagers = [];
        this.infoManagerService.getAllInfoManagers(this.selectedTransportDirection, this.airGood?.id).toPromise().then(res => this.infoManagers.push(...res));
    }

    async getAllInfoManagerDef(){
        console.log('infomanager');
        this.infoManagerService.getAllInfoManagerDef(this.selectedTransportDirection,'airGood').toPromise().then(res => this.infoManagerDefs.push(...res));
    }
}
