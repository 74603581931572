import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileObj } from 'src/app/models/qdms/file-obj-model';
import { QdmsService } from 'src/app/services/qdms.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { QdmsFile } from 'src/app/models/qdms/file-model';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  fileUrl: string;
  fileObj: FileObj;
  safeUrl: SafeUrl
  qdmsFile: QdmsFile;
  isLoading: boolean = true;
  isOfficeDocument: boolean = false;
  isPlaintexOrPdf: boolean = false;
  isImage: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) private data: QdmsFile,
   private qdmsService: QdmsService,
   private sanitizer: DomSanitizer) {

    this.qdmsFile = data;


  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async ngOnInit() {

    //docx doc ppt ..
    if(this.qdmsFile.contentType.includes('officedocument') || this.qdmsFile.contentType.includes('application/msword') || this.qdmsFile.contentType.includes('application/octet-stream')) {
      this.isOfficeDocument = true;
      this.fileUrl = this.qdmsService.getDownloadUrl(this.qdmsFile);
      return;
    }

    // pdf img txt
    await this.qdmsService.getFile(this.qdmsFile.fileId).subscribe(r => {
      this.fileObj = r;

      this.isPlaintexOrPdf = ['text/plain', 'application/pdf'].includes(this.fileObj.obj.contentType)
      this.isImage = this.fileObj.obj.contentType.includes('image');

      const byteCharacters = this._base64ToArrayBuffer(r.obj.file.data);
      var blob = new Blob([byteCharacters], { type: r.obj.contentType });

      this.fileUrl = URL.createObjectURL(blob);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);

    })
  }

  srcLoaded() { this.isLoading = false;  }
}
