export class LoginDto {
    id: number;
    version: number;
    firm: any = {};
    createDate: any;
    copyId: any;
    username: string;
    password: string;
    otp: string;
    rememberMe: boolean;
    user: any;
    language: any;
    firms: any[] = [];
    languages: any[];
    subDomain: string;
}