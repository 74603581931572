import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Survey } from 'src/app/models/survey-models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-list-survey',
  templateUrl: './list-survey.component.html',
  styleUrls: ['./list-survey.component.scss']
})
export class ListSurveyComponent implements OnInit {

  surveys: Survey[] = [];

  selectedSurveys: Survey[] = [];

  userId: number = 0;

  constructor(private surveyService: SurveyService, private router: Router) { }

  ngOnInit(): void {

    var user = JSON.parse(localStorage.getItem('user'));
    this.userId = user.id;

    this.surveyService.getMySurveys().subscribe(res => {
      console.log(res);
      this.surveys = res;
    })

  }

  goDetails(survey: Survey) {
    this.router.navigate(['/survey/take/' + survey.id]);
  }

  navigateReportPage(survey: Survey) {
    this.router.navigate(['/survey/report/' + survey.id]);
  }

}
