import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ExpensePackage } from 'src/app/models/expense.model';
import { ExpenseService } from 'src/app/services/expense.service';

@Component({
  selector: 'app-get-my-expences',
  templateUrl: './get-my-expences.component.html',
  styleUrls: ['./get-my-expences.component.scss']
})
export class GetMyExpencesComponent implements OnInit {

  expences: ExpensePackage[] = [];

  selectedExpences: ExpensePackage[] = [];

  constructor(private expenseService: ExpenseService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.getMyExpences();

  }

  getMyExpences() {
    this.expenseService.getMyExpensePackages().subscribe(res => {
      this.expences = res;
    })
  }

  cancelExpence(advance: ExpensePackage) {

    this.expenseService.getExpensePackage(advance.id).subscribe(res => {

      var realAdvance = res;
      realAdvance.state = 3;

      this.expenseService.updateExpensePackage(realAdvance).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep İptal Edildi' });
        this.getMyExpences();
      }, err => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Uyarı', detail: 'Hata Oluştu' });
      })

    })



  }

}
