import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivityDTOModel } from 'src/app/models/activity-dto.model';
import { Representative } from 'src/app/models/activity/representative';
@Component({
  selector: 'app-activity-table-dialog',
  templateUrl: './activity-table-dialog.component.html',
  styleUrls: ['./activity-table-dialog.component.scss']
})
export class ActivityTableDialogComponent implements OnInit {
   date = new Date();
   lastThreeYears = [];
   transportDirection: string;

   activitesWithOffer: ActivityDTOModel[] = [];
   activities: ActivityDTOModel[] = [];
   lastActicityWithOfferRepresentatives: Representative[] = [];
   lastActicityWithOfferRepresentativesDisplay: string = "Kayıt buluamadı.";
   lastActicityRepresentatives: Representative[] = [];
   lastActicityRepresentativesDisplay: string = "Kayıt bulunamadı";

   lastActivityWithOffer: ActivityDTOModel = null;
   lastActivity: ActivityDTOModel = null;

  constructor( private router: Router,
    public dialogRef: MatDialogRef<ActivityTableDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any[],
  ) {

    this.date.setFullYear(this.date.getFullYear() -2);
    for (let y =  this.date.getFullYear(); y <= new Date().getFullYear(); y++) {
      this.lastThreeYears.push(y);
    }
    this.transportDirection = this.data['transportDirection'];

    this.lastActicityWithOfferRepresentatives = this.data['lastActicityWithOfferRepresentatives']
    if(this.lastActicityWithOfferRepresentatives) {
     this.lastActicityWithOfferRepresentativesDisplay = [...new Set(this.lastActicityWithOfferRepresentatives.map(s => s.name))].join(',')
    }

    this.lastActicityRepresentatives = this.data['lastActicityRepresentatives']

    if(this.lastActicityRepresentatives) {
      this.lastActicityRepresentativesDisplay = [...new Set(this.lastActicityRepresentatives.map(s => s.name))].join(',')
     }

    this.activitesWithOffer = this.data['activities'].filter(f => f.startType == 'quotationRequest');
    this.activities = this.data['activities'].filter(f => f.startType != 'quotationRequest');

   }

   getCountActivityWithOffer(year:number, month: number = null) {
     if(!month) return this.activitesWithOffer.filter(f => new Date(f.createDate).getFullYear() == year).length;
     return this.activitesWithOffer.filter(f =>  new Date(f.createDate).getFullYear() == year && (new Date(f.createDate).getMonth()+1 == month)).length;
   }

   getCountActivity(year:number, month: number = null) {
    if(!month) return this.activities.filter(f => new Date(f.createDate).getFullYear() == year).length;
    return this.activities.filter(f =>  new Date(f.createDate).getFullYear() == year && (new Date(f.createDate).getMonth()+1 == month)).length;
  }

  ngOnInit(): void {
  }


  openNewTabActivity(id) {

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/activity/${this.transportDirection}/${id}`])
    );

    window.open(url, '_blank');
  }

}
