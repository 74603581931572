import {SimpleEntity} from "../simple-entity.model";

export class Address {
  name: string;
  postCode: string;
  addressDetail: string;
  district: string;
  city: string;
  country: string;
  fullAddress: string;
  cityDTO: SimpleEntity;
  districtDTO: SimpleEntity;
}
