import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CompanyDTOModel } from '../models/company-dto-model';
import { BaseService } from './base.service';
import { CompanyMinimizedModel } from '../models/company-minimized.model';
import { CompanyMobileDTO } from '../models/company-mobile-dto.model';
import { SimpleEntity } from '../models/simple-entity.model';
import { getToken } from './token-interceptor.service';
import { CompanyFinancialMobileDTO } from '../models/company-financial-mobile-dto';
import {CustomerRepresentativeModel} from "../models/customer-representative-model";
import {CompanyType} from "../models/company-type.model";
import { Person } from '../models/person.model';
import { CompanyActivity } from '../models/company-activity.model';

@Injectable()
export class CompanyService extends BaseService<CompanyDTOModel>{

    public queryParam: HttpParams;
    public user: any;

    constructor(public httpClient: HttpClient) {
        super('company',httpClient);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.queryParam = new HttpParams().set('token', localStorage.getItem("token")).set('sessionId', localStorage.getItem("sessionId"));
    }

    getById(companyId: number, transportDirection?: string): Observable<HttpResponse<CompanyDTOModel>> {
        const token = getToken(transportDirection);

        let url = `${environment.baseUrl}/${this.endpoint}/getById?companyId=${companyId}`;
        if(transportDirection) url += `&transportDirection=${transportDirection}`;

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId});

        return this.httpClient.get<CompanyDTOModel>(url, {params: options, observe: 'response'});
    }

    getCompany(companyId: number, transportDirection: string = null): Observable<CompanyMobileDTO> {

        if(transportDirection) this.queryParam.append('transportDirection', transportDirection)

        if(transportDirection) {
            const token = getToken(transportDirection);
            this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        } else {
            if(localStorage.getItem('orc')) {
                if(transportDirection == 'orc' ) {
                    if(!localStorage.getItem('orc')) {
                        console.error('Orc token bulunamadı!');
                        return null;
                    }
                    const orchAuth = JSON.parse(localStorage.getItem('orc'));
                    this.queryParam = new HttpParams().append('token', orchAuth.token).append('sessionId', orchAuth.sessionId).append('transportDirection', transportDirection);
                }
            }

        }

        return this.httpClient.get<CompanyMobileDTO>(`${environment.baseUrl}/${this.endpoint}/getCompany?companyId=${companyId}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getTaxOffice(transportDirection = null, name = null): Observable<SimpleEntity[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/getTaxOffice`
        let token;

        token = getToken(transportDirection)
        // if (localStorage.getItem(transportDirection)) {
        //     token = JSON.parse(localStorage.getItem(transportDirection));
        // } else {
        //     token = getToken(transportDirection)
        // }

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('name', name)

        return this.httpClient.get<SimpleEntity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    updateCompany(company: CompanyMobileDTO, transportDirection = null): Observable<CompanyMobileDTO> {
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.post<CompanyMobileDTO>(`${environment.baseUrl}/${this.endpoint}/updateCompany`, company, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    search(params, transportDirection = null, isSimple = false): Observable<CompanyMinimizedModel[]> {
        const query = encodeURI(JSON.stringify(params));
        return this.httpClient.get<CompanyMinimizedModel[]>(`${environment.baseUrl}/${this.endpoint}/search?query=${query}&isSimple=${isSimple}&transportDirection=${transportDirection}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    searchExpoDef(params, transportDirection = null, isSimple = false): Observable<any[]> {
        let url = environment.baseUrl + '/' + this.endpoint + '/searchExpoDef';
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('query', params);

        return this.httpClient.get<any[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    getCompanyTypes(transportDirection = null): Observable<CompanyType[]> {


        if(transportDirection) this.queryParam.append('transportDirection', transportDirection)

        if(transportDirection) {
            const token = getToken(transportDirection);
            this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        } else {

            if(transportDirection == 'orc') {
                if(!localStorage.getItem('orc')) {
                    console.error('Orc token bulunamadı!');
                    return null;
                } else {
                    const orchAuth = JSON.parse(localStorage.getItem('orc'));
                    this.queryParam = new HttpParams().append('token', orchAuth.token).append('sessionId', orchAuth.sessionId).append('transportDirection', transportDirection);
                }
            }

        }

        return this.httpClient.get<CompanyType[]>(`${environment.baseUrl}/${this.endpoint}/getCompanyTypes`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    createCompanyFinancial(companyFinancialMobile: CompanyFinancialMobileDTO, transportDirection = null) {
        let url = environment.baseUrl + '/' + this.endpoint + '/createCompanyFinancialByRegisterNo';
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<any>(url, companyFinancialMobile, {params: queryParam, observe: 'response'});
    }

    updateCompanyFinancial(companyFinancialMobile: CompanyFinancialMobileDTO, transportDirection = null, companyRegisterNo:string) {
        let url = environment.baseUrl + '/' + this.endpoint + '/updateCompanyFinancialById?companyRegisterNo=' + companyRegisterNo;
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<any>(url, companyFinancialMobile, {params: queryParam, observe: 'response'});
    }

    createOrUpdateCustomerRepresentative(customerRepresentative: CustomerRepresentativeModel, transportDirection = null, companyRegisterNo) {
        let url = environment.baseUrl + '/' + this.endpoint + '/createOrUpdateCustomerRepresentative?companyRegisterNo=' + companyRegisterNo;
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<any>(url, customerRepresentative, {params: queryParam, observe: 'response'});
    }

    getCompanyPerson(companyRegisterNo, transportDirection = null): Observable<Person[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/companyPerson`
        const token = getToken(transportDirection)

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('companyRegisterNo', companyRegisterNo);
        return this.httpClient.get<Person[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    createOrUpdateCompanyPerson(companyPerson: Person, transportDirection = null, companyRegisterNo) {
        let url = environment.baseUrl + '/' + this.endpoint + '/createOrUpdatePerson?companyRegisterNo=' + companyRegisterNo;
        let token = getToken(transportDirection);
        let queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<any>(url, companyPerson, {params: queryParam, observe: 'response'});
    }

    searchForCompanyByType(type, transportDirection = null): Observable<SimpleEntity[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/searchForCompanyByType`
        const token = getToken(transportDirection)

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('type', type);
        return this.httpClient.get<SimpleEntity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    getIndustries(transportDirection = null): Observable<SimpleEntity[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/getIndustries`
        const token = getToken(transportDirection)

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.get<SimpleEntity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    searchBranchByName(name: string, transportDirection = null): Observable<SimpleEntity[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/searchBranchByName`
        const token = getToken(transportDirection)

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('name', name).append('transportDirection', transportDirection);
        return this.httpClient.get<SimpleEntity[]>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    getCompanyActivity(registerNo: string,transportDirection = null): Observable<any> {
        const url = `${environment.baseUrl}/${this.endpoint}/getCompanyActivity`
        const token = getToken(transportDirection)

        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("registerNo", registerNo)
        return this.httpClient.get<CompanyActivity>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    deleteAddressCardEmail(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/addressCardEmail' + '/' + id;

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    getAvgEmptyReturn(customerId, transportDirection){
        const url = `${environment.baseUrl}/${this.endpoint}/getAvgEmptyReturn`;
        const token = getToken(transportDirection);

        this.queryParam = new HttpParams().append('token', token.token).append('customerId', customerId).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.get<number>(url, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }



    //TODO: impersonation !!!
}
