import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { KpiDefinition } from '../models/kpi-definition.model';
import { KpiGroupDetail } from '../models/kpi-group-detail.model';
import { KpiTarget } from '../models/kpi-target.model';
import { UpdateKpiGroupRequestModel } from '../models/update-kpi-group-request.model';
import { BaseService } from './base.service';
import { MinDTO } from '../models/min-dto.model';
import { KpiTargetMonthDetMobile } from '../models/kpi-target-month-det-mobile.model';
import { KpiTargetMonthDet } from '../models/kpi-target-month-det.model';

@Injectable()
export class KpiDefinitionService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'kpiDefinition',
            httpClient);
    }

    public queryParam: HttpParams;


    updateKpiGroup(kpiGroupDetail: KpiGroupDetail) {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var request: UpdateKpiGroupRequestModel = {
            kpiGroupDetail: kpiGroupDetail,
            token: token,
            sessionId: sessionId
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/updateKpiGroup";

        return this.httpClient.post(url, request, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    searchKpiDefinitions(name: string): Observable<KpiDefinition[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/searchKpiDefinitions?token=" + token + "&sessionId=" + sessionId + "&name=" + name;

        return this.httpClient.get<KpiDefinition[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    searchKpiTargets(userId?: number, hierarchyId?: number, branchId?: number, type?: string, status?: string): Observable<KpiTarget[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        if (userId === undefined) {
            userId = 0;
        }

        if (hierarchyId === undefined) {
            hierarchyId = 0;
        }

        if (branchId === undefined) {
            branchId = 0;
        }

        if (type === undefined) {
            type = '';
        }

        if (status === undefined) {
            status = ''
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/searchKpiTargets?token=" + token + "&sessionId=" + sessionId + "&userId=" + userId + "&hierarchyId=" + hierarchyId + "&branchId=" + branchId + "&type=" + type + "&status=" + status;

        return this.httpClient.get<KpiTarget[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getKpiDetail(userId: number): Observable<KpiGroupDetail> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getKpiGroupDetail?token=" + token + "&sessionId=" + sessionId + "&userId=" + userId;

        return this.httpClient.get<KpiGroupDetail>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getKpiTargetByUser(userId: number, transportDirection: string): Observable<MinDTO[]> {
        const token = this.getToken(transportDirection)
        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("userId", userId)

        return this.httpClient.get<MinDTO[]>( `${environment.baseUrl}/${this.endpoint}/getKpiTargetByUser`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        );
    }

    getKpiTargetMonthDet(kpiTargetId: number, transportDirection: string): Observable<KpiTargetMonthDetMobile> {
        const token = this.getToken(transportDirection)
        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("kpiTargetId", kpiTargetId)

        return this.httpClient.get<KpiTargetMonthDetMobile>( `${environment.baseUrl}/${this.endpoint}/getKpiTargetMonthDet`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        );
    }
    updateKpiTarget(kpiTargetMonthDet: KpiTargetMonthDet[], transportDirection: string): Observable<HttpResponse<KpiTargetMonthDet[]>> {
        const token = this.getToken(transportDirection)
        this.queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);
        return this.httpClient.post<KpiTargetMonthDet[]>(`${environment.baseUrl}/${this.endpoint}/updateKpiTarget`, kpiTargetMonthDet, {params:  this.queryParam, observe: 'response'}).pipe(
            tap(),
            catchError(this.handleError)
        )    
    }

}