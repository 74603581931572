import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Survey } from 'src/app/models/survey-models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';
import { MatDialog } from '@angular/material/dialog';
import {SendSurveyComponent} from "../send-survey/send-survey.component";

@Component({
  selector: 'app-take-survey',
  templateUrl: './take-survey.component.html',
  styleUrls: ['./take-survey.component.scss']
})
export class TakeSurveyComponent implements OnInit {

  survey: Survey = <Survey>{};

  isLoading = false;

  constructor(private surveyService: SurveyService, private dialog: MatDialog, private route: ActivatedRoute, private messageService: MessageService) {

    this.route.params.subscribe(res => {
      var id = res.id;

      this.surveyService.get(id).subscribe(res => {
        console.log(res);
        this.survey = res;
        this.survey.questions.filter(f => f.isTextAnswer).map(q => q.selectedAnswer = q.questionAnswers[0]);
      })

    });

  }

  ngOnInit(): void {



  }

  save() {

    this.isLoading = true;

    this.surveyService.completeSurvey(this.survey).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Anket Sonuçlarınız Başarıyla Kaydedildi" });


    }, err => {

      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;

    })


  }

  showSendSurveyDialog() {
    let dialogRef = this.dialog.open(SendSurveyComponent, {
        panelClass: 'modal-md',
        width: '900px',
        data:this.survey
      }
    );
  }

}
