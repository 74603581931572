import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferExportService} from '../../../../../services/offer-export.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-card-activity-list',
    templateUrl: './card-activity-list.component.html',
    styleUrls: ['./card-activity-list.component.scss']
})
export class CardActivityListComponent implements OnInit {
    @Input() id?: number;

    @Input() activities: any;
    @Input() showPreviousBtn: boolean = true;
    @Output() previousPage = new EventEmitter<boolean>();
    @Output() onClose = new EventEmitter<any>();
    @Input() selectedTransportDirection: string;
    @Input() dashboardDetail: any = null;
    activityId: any;
    displayOfferDetail: boolean;

   
    isLoading: boolean = true;
    sessionUser: any = null;



    constructor(private offerExportService: OfferExportService, public datepipe: DatePipe) {
        this.sessionUser = JSON.parse(localStorage.getItem('user'));
    }

    ngOnInit(): void {
        if(this.id) this.listSearch(this.id);
    }

    onVisibleChange() {

        this.previousPage.emit(false)
        this.onClose.emit(null);
    }
    listSearch(id) {
        this.isLoading = true;

        
        
        const listName =  "activityMobileList"
        const url = `/marketing/list/${listName}/listSearch?name=${listName}&query=%7B%22dashboardActivityId%22%3A%22` + id + '%22%7D';
        this.offerExportService.listSearch(url, this.selectedTransportDirection).subscribe(res => {
            this.activities = res.body;
            this.isLoading = false;
        });
    }



}
