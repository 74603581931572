import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfferExportService } from 'src/app/services/offer-export.service';
import {CompanyMinimizedModel} from '../../../../../models/company-minimized.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-card-company-list',
  templateUrl: './card-company-list.component.html',
  styleUrls: ['./card-company-list.component.scss']
})
export class CardCompanyListComponent implements OnInit {

  @Input() id?: number;
    @Input() dashboardDetail: any = null;
    @Output() previousPage = new EventEmitter<boolean>();
    @Input() showPreviousBtn: boolean = true;
    @Output() onClose = new EventEmitter<boolean>();
    @Input() selectedTransportDirection: string;
    listName: string;
    isLoading = false;
    companies: any;
    activityId: any;
    displayCompanyDetail: boolean;

    constructor(private offerExportService: OfferExportService, private router: Router) {
    }

    ngOnInit(): void {
        if (this.id) { this.listSearch(this.id); }
    }

    onVisibleChange() {
        this.onClose.emit(false);
    }


    listSearch(id) {
        console.log(this.dashboardDetail,this.selectedTransportDirection)
        this.isLoading = true;

        const url = `/marketing/list/companyList/listSearch?name=companyList&query=%7B%22dashboardCompanyId%22%3A%22` + id + '%22%7D';

        this.offerExportService.listSearch(url, this.selectedTransportDirection).subscribe(res => {
            console.log(res);
            this.companies = res.body;
            this.listName = res.body.dynamicListDef.name;
            this.isLoading = false;
        });
    }

    navigateDetail(customerId: number,registerNo ){
        this.router.navigate(['/activities/customers/edit/' + this.selectedTransportDirection + '/' + customerId + '/' + (registerNo || '') ]);
    }


}
