import { HttpClient, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, observable} from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginDto } from '../models/login-dto.model';
import { BaseService } from './base.service';
import {LoginMobile} from "../models/login-mobile.model";


@Injectable()
export class AuthenticationService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'authentication',
            httpClient);
    }

    authenticate(username: string, password: string): Observable<any> {

        var url = environment.baseUrl + "/user/login?username=" + username + "&password=" + password;


        return this.httpClient.get(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    loginNew(): Observable<any> {

        var url = environment.baseUrl + "/user/loginNew";


        return this.httpClient.get(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    loginNews(): Observable<LoginDto[]> {

        var url = environment.baseUrl + "/user/loginNews";

        return this.httpClient.get<LoginDto[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    login(entity: LoginDto): Observable<any> {

        var url = environment.baseUrl + "/user/loginWithModel";

        return this.httpClient.post(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    loginToSubDomain(entity: LoginDto): Observable<any> {

        var url = environment.baseUrl + "/user/loginToSubDomain";

        return this.httpClient.post(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    loginMobile(entity: LoginDto): Observable<any> {

        var url = environment.baseUrl + "/user/loginMobile";

        const username = 'test';
        const password = 'testkny@xnrj?2019';

        const options = this.createRequestOption({username, password});

        return this.httpClient.post(url, entity, { params: {username, password}, withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    otpControl(entities: LoginDto[]){
        var url = environment.baseUrl + "/user/otpControl";
        return this.httpClient.post<LoginMobile>(url, entities,);
    }



    // TODO: impersonation !!!
}
