import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { FileElement, Tile } from '../documents/documents.component';
import { NewFolderDialogComponent } from '../modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from '../modals/rename-dialog/rename-dialog.component';
import { UploadFileDialogComponent } from '../modals/upload-file-dialog/upload-file-dialog.component';
import { IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetDocumentService } from 'src/app/services/document.service';


@Component({
  selector: 'file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnChanges {
  constructor(public dialog: MatDialog, private intranetDocumentService: IntranetDocumentService) { }

  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;
  @Input() parentElement: FileElement;

  @Output() folderAdded = new EventEmitter<{ name: string }>();
  @Output() elementRemoved = new EventEmitter<FileElement>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedUp = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void { }

  deleteElement(element: FileElement) {

    this.intranetDocumentService.delete(element.intranetDocument.id).subscribe(res => {
      this.elementRemoved.emit(element);
    })


  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    } else {

      window.open(element.intranetDocument.url);

    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  moveElement(element: FileElement, moveTo: FileElement) {

    var first = element.intranetDocument;

    var moveToElement = moveTo.intranetDocument;

    first.parentDocumentId = moveToElement.id;

    this.intranetDocumentService.update(first).subscribe(res => {
      this.elementMoved.emit({ element: element, moveTo: moveTo });
    });


  }

  openNewFolderDialog(currentRoot?: FileElement) {
    let dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: {
        parentElement: this.parentElement,
        currentElement: currentRoot
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  openNewFileDialog(currentRoot?: FileElement) {
    let dialogRef = this.dialog.open(UploadFileDialogComponent, {
      panelClass: 'modal-md',
      width: '1000px',
      data: {
        parentElement: this.parentElement,
        currentElement: currentRoot
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  openRenameDialog(element: FileElement) {
    let dialogRef = this.dialog.open(RenameDialogComponent, {
      data: {
        parentElement: this.parentElement,
        currentElement: element
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    });
  }

  openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }
}
