import { Component, OnInit, Inject } from '@angular/core';
import { Allocation } from 'src/app/models/allocation.model';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AllocationService } from 'src/app/services/allocation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allocation-detail',
  templateUrl: './allocation-detail.component.html',
  styleUrls: ['./allocation-detail.component.scss']
})
export class AllocationDetailComponent implements OnInit {

  allocation: Allocation;
  searchParam: any;
  loading = false;
  seaFreightTariffDetMobiles: any[] = [];
  
  constructor(private allocationService: AllocationService,
     public dialogRef: MatDialogRef<AllocationDetailComponent> ,@Inject(MAT_DIALOG_DATA) public data: any,
     private route: Router) {}

  ngOnInit() {
    this.searchParam = this.data.search;
    this.allocationService.getSeaAllocation(this.data.allocation.id, this.searchParam.tonnage).subscribe(res => {
      this.allocation = res as Allocation;
      this.seaFreightTariffDetMobiles = this.allocation?.seaFreightTariffDetMobiles;
    });  
  }

  onClose() {
    this.dialogRef.close();
  }

  navigateOfferRequest() {
    this.onClose();
    this.route.navigate([`activities/offer-request/allocation`], {state: {allocations: [this.allocation], search: this.searchParam}});
  }

  /*
  Filtreleme tmaxx backend'de uygulandığı için kaldırıldı.
  filterTariffDet() {
    let filtered = [];
    if(!this.allocation?.seaFreightTariffDetMobiles || this.allocation?.seaFreightTariffDetMobiles.length == 0) return filtered;

    if(this.searchParam.pod) {
      filtered = filtered.concat(this.allocation?.seaFreightTariffDetMobiles.filter(f => f.toPort.id == this.searchParam.pod.id && (this.searchParam.pol ?  f.fromPort.id == this.searchParam.pol.id: true)));  
    }

    if(this.searchParam.pol) {
      filtered = filtered.concat(this.allocation?.seaFreightTariffDetMobiles.filter(f => f.fromPort.id == this.searchParam.pol.id && (this.searchParam.pod ? f.toPort.id == this.searchParam.pod.id : true)));  
    }
    
    filtered = [...new Set(filtered.map(item => item))];
    return filtered;
  } */

  sumTariffDetAmount(seaTariffDet: []) {
    if(!seaTariffDet || seaTariffDet.length == 0) return 0;
    return seaTariffDet.filter(f => f['amount']).map(f => Number(f['amount'])).reduce((q,b) =>  q+b)

  }



}
