import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import b64toBlob from 'b64-to-blob';
import {MessageService} from 'primeng/api';
import {OfferExportService} from '../../../../services/offer-export.service';

@Component({
  selector: 'app-offer-tariff-detail',
  templateUrl: './offer-tariff-detail.component.html',
  styleUrls: ['./offer-tariff-detail.component.scss']
})

export class OfferTariffDetailComponent implements OnInit {
  offer: any;
  container: any;
  isLoading = false;
  displayDigitalArsiv = false;
  transportDirection: any;
  digitalStore: any = [];

  constructor( public dialogRef: MatDialogRef<OfferTariffDetailComponent> ,@Inject(MAT_DIALOG_DATA) public data: any,
               private messageService: MessageService, private offerExportService: OfferExportService) {
    this.offer = data.offer;
    this.container = data.container;
    this.transportDirection = data.transportDirection;
   }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

  calculateTotal(){
      var total = 0;
      if (this.container && this.container.seaTariffDetMobiles) {
          this.container.seaTariffDetMobiles.forEach(detPrice => {
              total += detPrice.buyAmount;
          });
          return total + ' ' + this.container?.seaTariffDetMobiles[0]?.currency?.name;
      }
      return total;

  }

    getDigitalDocument(report: any) {
        this.isLoading = true;
        this.offerExportService.getDocumentType(report?.id, this.transportDirection).subscribe(res => {
            this.offerExportService.getDigitalDocument(res.body.reference, this.transportDirection).subscribe( gt => {
                this.isLoading = false;
                const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
                const fileUrl = window.URL.createObjectURL(file);
                if ( res.body.contentType === 'application/pdf' ||  res.body.contentType.startsWith('image')) {
                    window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                } else {
                    const nav = (window.navigator as any)
                    if (nav && nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
                    } else {
                        var a = document.createElement('a');
                        a.href = URL.createObjectURL(file);
                        a.download = res.body.fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak\'a ulaşıldı' });
            }, error => {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
                this.isLoading = false;
            });
        },error => {
            this.isLoading = false;
        });
    }

    getDigitalArsiv() {
        this.isLoading = true;
        this.offerExportService.getDigitalArsiv(this.offer.seaFreightBuyTariff.id, 'seaFreightBuyTariff', this.transportDirection).subscribe(res => {
            this.digitalStore = res.body;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    // uploadDocumentsCheck(documents: any) {
    //     if (documents.files && documents.files.length > 0) {
    //         for (const file of documents.files) {
    //             if (file.size <= 15728640) {
    //                 this.uploadDocuments(file);
    //             } else {
    //                 this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
    //             }
    //         }
    //
    //     } else if (documents.files && documents.files.length > 0) {
    //         for (const file of documents.files) {
    //             if (file.getAsFile().size <= 15728640) {
    //                 this.uploadDocuments(file);
    //             } else {
    //                 this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
    //             }
    //         }
    //     }
    // }

    // uploadDocuments(file: any) {
    //     this.isLoading = true;
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     this.offerExportService.uploadDocuments(formData, this.createdActivity?.offerId).subscribe(res => {
    //         const dct = res.body.res;
    //         this.offerExportService.getPagesByName('Teklif').subscribe(pages => {
    //             const pageObject = pages.body.find(pg => pg.name === 'Teklif');
    //             if (res.status === 200) {
    //                 this.offerExportService.saveDocument({
    //                     entityid: +dct.entityid,
    //                     reference: dct.uid,
    //                     contentType: dct.contentType,
    //                     fileName: dct.fileName,
    //                     fileSize: dct.fileSize,
    //                     page: pageObject,
    //                     sourceType: 'Offer'
    //                 }).subscribe(sv => {
    //                     this.getDigitalArsiv();
    //                     this.uploader.clear();
    //                 });
    //                 // this.user = this.cacheService.get(Storage.USER);
    //                 this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
    //             }
    //         }, error => {
    //             this.isLoading = false;
    //         });
    //         this.isLoading = false;
    //     }, error => {
    //         this.isLoading = false;
    //     });
    // }
}
