import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';

@Component({
  selector: 'app-advance-approval',
  templateUrl: './advance-approval.component.html',
  styleUrls: ['./advance-approval.component.scss']
})
export class AdvanceApprovalComponent implements OnInit {

  model: IntranetAdvance = <IntranetAdvance>{};

  advanceTypes: any[] = [];

  selectedAdvanceType: any;

  advanceUseDate: Date = new Date();

  advanceId: number;

  constructor(private messageService: MessageService, private advanceService: IntranetAdvanceService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.advanceId = params['id'];

      this.advanceService.get(this.advanceId).subscribe(res => {

        this.model = res;

      })

    });

    this.advanceTypes.push({
      label: 'Avans Tipi Seçiniz',
      value: 0
    })

    this.advanceTypes.push({
      label: 'Bireysel İhtiyaç',
      value: 1
    })

    this.advanceTypes.push({
      label: 'Şirketiçi Harcama',
      value: 2
    })

  }

  isLoading = false;

  approve() {

    this.isLoading = true;

    this.advanceService.approve(this.model.id).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Onaylandı' });

      setTimeout(() => {
        this.router.navigate(['/advance/getApprovalAdvances']);
      }, 1500);

    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

  disapprove() {

    this.isLoading = true;

    this.advanceService.disApprove(this.model.id).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Reddedildi' });

      setTimeout(() => {
        this.router.navigate(['/advance/getApprovalAdvances']);
      }, 1500);
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

}
