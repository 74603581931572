import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExpensePackage, ExpensePackageCategory, ExpensePackageItem } from 'src/app/models/expense.model';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { ExpenseService } from 'src/app/services/expense.service';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';
import { CreateExpensePackageItemComponent } from '../modals/create-expense-package-item/create-expense-package-item.component';

@Component({
  selector: 'app-create-expense-package',
  templateUrl: './create-expense-package.component.html',
  styleUrls: ['./create-expense-package.component.scss']
})
export class CreateExpensePackageComponent implements OnInit {

  model: ExpensePackage = <ExpensePackage>{
    startDate: new Date(),
    endDate: new Date()
  };

  advanceTypes: any[] = [];

  selectedAdvanceType: any;

  expenseCategories: ExpensePackageCategory[] = [];

  advanceUseDate: Date = new Date();

  expensePackageItems: ExpensePackageItem[] = [];

  selectedExpenseCategory: ExpensePackageCategory;

  displayedColumns = ['spendDate', 'reasonForSpending', 'amount', 'actions'];

  dataSource = new MatTableDataSource<ExpensePackageItem>();

  totalAmount: number = 0;

  currencies: any[] = [];

  filteredCurrencies: any[] = [];

  selectedCurrency: any;

  @ViewChild('filter', { static: true }) filter: ElementRef;

  constructor(public dialog: MatDialog, private messageService: MessageService, private expenseService: ExpenseService, private currencyService: CurrencyConversionService,
    private router: Router) { }

  ngOnInit(): void {

    this.getExpenseCategories();

    this.getAllCurrencies();


    this.dataSource.data = this.expensePackageItems;

  }

  getExpenseCategories() {


    this.expenseService.getExpensePackageCategories().subscribe(res => {
      this.expenseCategories = res;

      if (this.expenseCategories.length > 0) {
        this.selectedExpenseCategory = this.expenseCategories[0];
      }
    })

  }

  getAllCurrencies() {
    this.currencyService.getAllCurrencies().subscribe(res => {
      this.currencies = res.body;
    })
  }

  filterCurrencies(event) {
    const filtered: any = [];
    const query = event.query;
    for (let i = 0; i < this.currencies.length; i++) {
      const country = this.currencies[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCurrencies = filtered;
  }



  isLoading = false;

  save() {

    if(!this.totalAmount) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Toplam tutar boş geçilemez.' });
      return;
    }

    if(!this.model.endDate || !this.model.startDate) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Masraf bitiş ve başlangıç tarihi boş geçilemez.' });
      return;
    }

    if (!this.selectedExpenseCategory || !this.selectedExpenseCategory.id) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Masraf kategorisi boş geçilemez.' });
      return;
    }

    if (!this.selectedCurrency || !this.selectedCurrency.id) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Para birimi boş geçilemez.' });
      return;
    }

    this.model.startDate = this.advanceUseDate;

    this.isLoading = true;

    this.model.expensePackageCategoryId = Number(this.selectedExpenseCategory.id);

    this.model.currency = this.selectedCurrency.id;

    var user = JSON.parse(localStorage.getItem('user'));

    this.model.userId = user.id;

    this.model.totalAmount = this.totalAmount;

    this.model.state = 0;

    this.model.approvedTotalAmount = 0;

    this.model.expensePackageItemList = this.expensePackageItems;


    this.expenseService.createExpensePackage(this.model).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Gönderildi' });

      setTimeout(() => {
        this.router.navigate(['/expense/get-my-expences']);
      }, 1500)

    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }
  openLessonVideoDialog(expenseItem?: any) {
    let dialogRef = this.dialog.open(CreateExpensePackageItemComponent, {
      width: '1200px',
      data: {
        expenseItem: expenseItem,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      if (resObject) {

        var isEdit = resObject.isEdit;
        var res = resObject.model;

        if (!isEdit) {
          this.expensePackageItems.push({
            amount: res.amount,
            attachmentUrl: res.attachmentUrl ? res.attachmentUrl : null,
            description: res.description ? res.description : null,
            expensePackageId: null,
            id: null,
            reasonForSpending: res.reasonForSpending,
            spendDate: res.spendDate,
            version: 0
          })
        }



        this.dataSource.data = this.expensePackageItems;

        this.totalAmount = 0;

        this.expensePackageItems.forEach(item => {
          this.totalAmount += item.amount;
        })
      }



    });
  }

  deleteItem(item: ExpensePackageItem, index: number) {

    this.expensePackageItems.splice(index, 1);

    this.dataSource.data = this.expensePackageItems;

    this.totalAmount = 0;

    this.expensePackageItems.forEach(item => {
      this.totalAmount += item.amount;
    })


  }

}
