import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Announcement } from '../models/announcement.model';
import { KpiDefinition } from '../models/kpi-definition.model';
import { KpiGroupDetail } from '../models/kpi-group-detail.model';
import { KpiTarget } from '../models/kpi-target.model';
import { UpdateKpiGroupRequestModel } from '../models/update-kpi-group-request.model';
import { UserGroup } from '../models/user-group.model';
import { BaseService } from './base.service';


@Injectable()
export class AnnouncementService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe) {
        super(
            'announcement',
            httpClient);
    }

    addAnnouncement(entity: Announcement) {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var request: any = {
            announcement: entity,
            token: token,
            sessionId: sessionId
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/addAnnouncement";

        return this.httpClient.post(url, request, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )

    }

    getMyAvailableAnnouncements(userId: number, isBanner: boolean): Observable<Announcement[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getMyAvailableAnnouncements?token=" + token + "&sessionId=" + sessionId + "&userId=" + userId + "&isBanner=" + isBanner;

        return this.httpClient.get<Announcement[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getAllAnnouncementTypes(): Observable<any[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getAllAnnouncementTypes?token=" + token + "&sessionId=" + sessionId;;

        return this.httpClient.get<KpiTarget[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getUserGroupsByUserId(userId: number): Observable<UserGroup[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getUserGroupsByUserId?token=" + token + "&sessionId=" + sessionId + "&userId=" + userId;

        return this.httpClient.get<UserGroup[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    //TODO: impersonation !!!
}