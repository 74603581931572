import { Component, OnInit } from '@angular/core';
import { Announcement } from 'src/app/models/announcement.model';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.scss']
})
export class AnnouncementDetailComponent implements OnInit {

  announcement: Announcement;

  constructor() { }

  ngOnInit(): void {

    this.announcement = JSON.parse(localStorage.getItem("announcement"));


  }

}
