import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';

@Component({
  selector: 'app-create-advance',
  templateUrl: './create-advance.component.html',
  styleUrls: ['./create-advance.component.scss']
})
export class CreateAdvanceComponent implements OnInit {

  model: IntranetAdvance = <IntranetAdvance>{
    installment: 1
  };

  advanceTypes: any[] = [];

  selectedAdvanceType: any;

  advanceUseDate: Date = new Date();

  constructor(private messageService: MessageService, private advanceService: IntranetAdvanceService, private router: Router) { }

  ngOnInit(): void {

    this.advanceTypes.push({
      label: 'Avans Tipi Seçiniz',
      value: 0
    })

    this.advanceTypes.push({
      label: 'Bireysel İhtiyaç',
      value: 1
    })

    this.advanceTypes.push({
      label: 'Şirketiçi Harcama',
      value: 2
    })

  }

  isLoading = false;

  save() {

    if (!this.selectedAdvanceType) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Başarılı', detail: 'Avans tipi boş geçilemez' });
      return;
    }

    this.model.advanceUseDate = this.advanceUseDate;

    this.isLoading = true;

    this.model.advanceType = this.selectedAdvanceType.toString();

    var user = JSON.parse(localStorage.getItem('user'));

    this.model.userId = user.id;

    this.advanceService.create(this.model).subscribe(res => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep Gönderildi' });

      setTimeout(() => {
        this.router.navigate(['/advance/getMyAdvances']);
      }, 1500)
    }, err => {
      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Server Hatası' });
    })



  }

}
