import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory } from 'src/app/models/expense.model';
import { IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetWidget } from 'src/app/models/intranet-widget.model';
import { IntranetDocumentService } from 'src/app/services/document.service';
import { ExpenseService } from 'src/app/services/expense.service';
import { IntranetWidgetService } from 'src/app/services/intranet-widget.service';
import { FileElement } from '../../document/documents/documents.component';
import { NewFolderDialogComponent } from '../../document/modals/new-folder-dialog/new-folder-dialog.component';
import { CreateExpensePackageCategoryComponent } from '../../expense/modals/create-expense-package-category/create-expense-package-category.component';

@Component({
  selector: 'app-create-widget',
  templateUrl: './create-widget.component.html',
  styleUrls: ['./create-widget.component.scss']
})
export class CreateWidgetComponent implements OnInit {

  model: IntranetWidget = <IntranetWidget>{};

  isEdit = false;

  isProgressActive = false;

  constructor(public dialogRef: MatDialogRef<CreateExpensePackageCategoryComponent>, private expenseService: ExpenseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog, private widgetService: IntranetWidgetService,
    private messageService: MessageService) {

    if (data && data.widget) {

      this.isEdit = true;
      this.model = data.widget;

    }

  }

  ngOnInit(): void {

  }




  isLoading = false;

  save() {

    if (!this.isEdit) {
      this.widgetService.createWidget(this.model).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Widget Eklendi" });
        this.dialogRef.close({
          model: res,
          isEdit: this.isEdit
        });
      })
    } else {

      this.widgetService.updateWidget(this.model).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Widget Eklendi" });
        this.dialogRef.close({
          model: res,
          isEdit: this.isEdit
        });
      })

    }





  }

}
