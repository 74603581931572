
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Person } from '../models/person.model';
import { BaseService } from './base.service';
import {UpdateOfferStatusDTO} from "../models/update-offer-status-dto.model";
import {getToken} from "./token-interceptor.service";


@Injectable()
export class PersonService extends BaseService<any>{

    public queryParam: HttpParams;
    public user: any;

    constructor(public httpClient: HttpClient) {
        super('person', httpClient);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.queryParam = new HttpParams().set('token', localStorage.getItem("token")).set('sessionId', localStorage.getItem("sessionId"));
    }


    // search(params): Observable<Person[]> {
    //     const query = encodeURI(JSON.stringify(params));
    //     return this.httpClient.get<Person[]>(`${environment.baseUrl}/${this.endpoint}/search?query=${query}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
    //         .pipe(
    //             tap(),
    //             catchError(this.handleError)
    //         )
    // }

    search(params, transportDirection: string): Observable<HttpResponse<Person[]>> {

        const token = getToken(transportDirection);

        const query = encodeURI(JSON.stringify(params));

        const url = `${environment.baseUrl}/${this.endpoint}/search?query=${query}`;

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId});

        return this.httpClient.get<Person[]>(url, {params: options, observe: 'response'});
    }


}
