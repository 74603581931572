import { SimpleEntity } from "../simple-entity.model";

export class KpiTargetCustomerMonthDetDTO {
    id: number;
    month: number;
    actualTeu: number;
    targetTeu: number;
    isChanged: boolean = false;

    actualFileQuantity: number;
    targetFileQuantity: number;

    actualTonnage: number;
    targetTonnage: number;

    actualNewCustomer: number;
    targetNewCustomer: number;

    target: number;
    actual: number = 0;
    failed: number;

    targetScore: number;
    actualScore: number;
    kpiTargetCustomerDet: SimpleEntity;

}
