import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IntranetLesson, IntranetLessonVideo, IntranetLessonVideoUserState, QuizUserResult } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-watch-lesson',
  templateUrl: './watch-lesson.component.html',
  styleUrls: ['./watch-lesson.component.scss']
})
export class WatchLessonComponent implements OnInit {

  videos: IntranetLessonVideo[] = [];

  lessonId: number;

  hasQuestions: Boolean = false;

  lesson: IntranetLesson = <IntranetLesson>{};

  preload: string = 'auto';
  api: VgApiService;

  points: any;

  previousTime = 0;
  currentTime = 0;
  seekStart = null;
  @ViewChild('media', { static: false }) media: any;




  onTimeUpdate(e) {

    this.previousTime = this.currentTime;
    this.currentTime = this.getCurrentTime();
  }

  onSeeking(e) {
    if (this.seekStart === null) {
      this.seekStart = this.previousTime;
    }
  }

  onSeeked(e) {
    if (this.currentTime > this.seekStart) {
      this.setCurrentTime();
    }
    this.seekStart = null;
  }

  onEnd(e) {
    console.log(e);
  }

  setCurrentTime() {
    //console.log('Setting Time: ' + this.currentTime)
    this.api.seekTime(this.previousTime);
  }


  getCurrentTime() {
    return this.media.nativeElement.currentTime;
  }

  quizResults: QuizUserResult[] = [];

  constructor(private learningService: LearningService, private route: ActivatedRoute, private messageService: MessageService, private router: Router) {

    this.route.params.subscribe(params => {
      this.lessonId = params['id']


      this.learningService.getLesson(this.lessonId).subscribe(res => {

        this.lesson = res;

      })

      this.learningService.getResultsByLessonAndUser(this.lessonId).subscribe(res => {
        this.quizResults = res;
      })

      this.learningService.getLessonWithQuizObjects(this.lessonId).subscribe(res => {
        this.hasQuestions = res.questions.length > 0;
      })


    });

  }

  solveQuiz() {

    this.router.navigate(['/learning/quiz/solve-lesson-quiz/' + this.lessonId]);

  }

  percentageTimerSubscription: any;

  percentageTimerSubscription2: any;

  onPlayerReady(api: VgApiService) {
    this.api = api;

    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
      this.playVideo.bind(this)



    );

    this.api.getDefaultMedia().subscriptions.play.subscribe(res => {


      var userState = this.currentVideo.videoUserState;

      if (userState != null && userState.currentTimeData && userState.currentTimeData > 0) {

        this.api.seekTime(userState.currentTimeData);

      }


      this.percentageTimerSubscription = setInterval(() => {

        if(!this.api.getDefaultMedia().currentTime) return;
        
        var currentTime = this.api.getDefaultMedia().currentTime;

        this.currentTime = currentTime;

        var durationRounded = Math.round((currentTime + Number.EPSILON) * 100) / 100

        var currentTimeMilliseconds = durationRounded * 1000;

        var videoDuration = this.currentVideo.videoDuration;

        var percentage = (currentTimeMilliseconds / videoDuration) * 100;


        if (percentage == 100) {

          clearInterval(this.percentageTimerSubscription);
          clearInterval(this.percentageTimerSubscription2);
        } else {

          var user = JSON.parse(localStorage.getItem('user'));

          if (this.currentVideo.videoUserState == null) {

            var userState: IntranetLessonVideoUserState = {
              copyId: null,
              createDate: null,
              firm: null,
              id: null,
              lessonId: this.lessonId,
              progressPercentage: percentage,
              state: 'playing',
              userId: user.id,
              videoId: this.currentVideo.id,
              currentTimeData: this.currentTime,
              version: null,
            }

            this.learningService.createVideoUserState(userState).subscribe(res => {

              this.currentVideo.videoUserState = res;

            })

          } else {


            this.currentVideo.videoUserState.progressPercentage = percentage;

            var fixNumber = parseInt(percentage.toFixed());



            if (this.currentVideo.videoUserState.progressPercentage != 100 && fixNumber != 0 && fixNumber % 10 == 0) {

              var userState = this.currentVideo.videoUserState;

              userState.currentTimeData = this.currentTime;

              userState.progressPercentage = percentage;

              this.learningService.updateVideoUserState(userState).subscribe(res => {

                this.currentVideo.videoUserState = res;


              })


            }

          }

        }

      }, 100)





    })


    this.api.getDefaultMedia().subscriptions.ended.subscribe(res => {

      clearInterval(this.percentageTimerSubscription);
      clearInterval(this.percentageTimerSubscription2);


      var user = JSON.parse(localStorage.getItem('user'));

      if (this.currentVideo.videoUserState == null) {

        var userState: IntranetLessonVideoUserState = {
          copyId: null,
          createDate: null,
          firm: null,
          id: null,
          lessonId: this.lessonId,
          progressPercentage: 100,
          state: 'ended',
          userId: user.id,
          videoId: this.currentVideo.id,
          currentTimeData: this.currentTime,
          version: null,
        }

        this.learningService.createVideoUserState(userState).subscribe(res => {

          this.currentVideo.videoUserState = res;

        })

      } else {

        var userState = this.currentVideo.videoUserState;

        if (userState.progressPercentage != 100) {

          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Eğitim Videosu Tamamlandı" });

          userState.progressPercentage = 100;
          userState.currentTimeData = this.currentTime;

          this.learningService.updateVideoUserState(userState).subscribe(res => {

            this.currentVideo.videoUserState = res;

          })

        }



      }


    })

  }

  playVideo() {
    this.api.play();
  }

  currentVideo: IntranetLessonVideo;

  selectVideo(video: IntranetLessonVideo) {

    this.currentVideo = null;

    setTimeout(() => {
      this.currentVideo = video;

      this.api.seekTime(0);

      this.api.pause();

      var userState = this.currentVideo.videoUserState;

      if (userState != null && userState.currentTimeData && userState.currentTimeData > 0) {

        this.api.play();

        this.api.seekTime(userState.currentTimeData);

      }

    }, 100);



  }

  ngOnInit(): void {
    this.getLessonVideos();
  }

  getLessonVideos() {

    this.learningService.getMyVideosByLessonId(this.lessonId).subscribe(res => {

      this.videos = res;

      if (this.videos.length > 0) {

        this.currentVideo = this.videos[0];

      }


    })

  }

}
