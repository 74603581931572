import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {DistrictMinimizedModel} from '../models/district-minimized.model';
import {Observable} from 'rxjs';
import {CityMinimizedModel} from '../models/city-minimized.model';
import {CountryMinimizedModel} from '../models/country-minimized.model';
import {Activity} from '../models/activity.model';
import {Email} from '../models/email.model';
import {EmailAttachment} from '../models/email-attachment.model';
import {map} from 'rxjs/operators';
import {UpdateOfferStatusDTO} from '../models/update-offer-status-dto.model';
import {getMainToken, getToken} from './token-interceptor.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OfferExportService extends BaseService<any> {

    constructor(public httpClient: HttpClient) {
        super(
            'activity',
            httpClient);
    }

    getOfferExportCountry(name: string, transportDirection = null) {
        const url = environment.baseUrl + '/' + this.endpoint + '/getAllCountry';
        const token = getToken(transportDirection);
        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('name', name);
        return this.httpClient.get<CountryMinimizedModel[]>(url, {params: queryParam, observe: 'response'});
    }

    getOfferExportCity(name: string, countryId?: number, transportDirection = null) {

        const url =  environment.baseUrl + '/' + this.endpoint + '/getAllCity';
        const token = getToken(transportDirection);

        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('name', name).append('countryId', countryId || 0);

        return this.httpClient.get<any[]>(url, {params: queryParam, observe: 'response'}).pipe(map(res => {
            res.body.forEach(city => {
                city.name = city.name + ' - ' + city.country.name;
            });
            return res;
        }));
    }

    getAllOfferStatusByOfferId(offerId: number, transportDirection: string) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/offers/offerStatus/getAllOfferStatusByOfferId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, offerId, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getOfferExportDistrict(name: string, cityId?: number, transportDirection = null) {

        const url = environment.baseUrl + '/' + this.endpoint + '/getAllDistrict';
        const token = getToken(transportDirection);


        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('name', name).append('cityId', cityId || 0);

        return this.httpClient.get<any[]>(url, {params: queryParam, observe: 'response'}).pipe(map(res => {
            res.body.forEach(dt => {
                dt.name = dt?.name + ' - ' + dt?.city?.name + ' - ' + dt?.country?.name;
            });
            return res;
        }));
    }

    getOfferExportSeaport(name: string, where: string = null, transportDirection?: string) {
        const token = getToken(transportDirection);

        let url = environment.baseUrl + '/' + this.endpoint + '/getOfferExportSeaport';
        if (where) { url += `?where=${where}`; }

        const options = this.createRequestOption({token, sessionId: token.sessionId, name, transportDirection});

        return this.httpClient.get<DistrictMinimizedModel[]>(url, {params: options, observe: 'response'}).pipe(map(seaport => {
            seaport.body.forEach(sea => {
                sea.name = sea.name + ' - ' + sea.country;
            });
            return seaport;
        }));
    }

    getUserWithName(name: string, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/user';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, name, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getCompany(name: string, companyType?: any[], representative?: number, transportDirection?: string): Observable<HttpResponse<any[]>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getCompany';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, name, representative, companyType, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'}).pipe(map(cp => {
            cp.body.forEach(c => {
                if (c?.status === 'B') {
                    c.name = c.name + ' - Kara Listede';
                }
            });
            return cp;
        }));
    }

    getAllCurrency(transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getAllCurrency';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getSeaFreightBuyTariffDetMobile(fromPortId: number, toPortId: number, fromCityId: number, toCityId: number, shipOwnerId: number, etd: string, cutOffDate: string, tonnage, portFilling, isSales = false): Observable<HttpResponse<any[]>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + (isSales ? '/getSeaFreightTariffDetMobile' : '/getSeaFreightBuyTariffDetMobile');

        const options = this.createRequestOption({
            token, sessionId, toPortId, fromPortId, fromCityId, toCityId, shipOwnerId, etd, cutOffDate, tonnage, portFilling
        });

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getReportList(offerId: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getReportList';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, offerId});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getDigitalArsiv(offerId: number, sourceType: string, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getDigitalArsiv';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, sourceType, offerId});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    createActivity(activityFromMobile: Activity): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/createActivity';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, activityFromMobile, {params: options, observe: 'response'});
    }

    updateOfferStatus(updateOfferStatusDTO: UpdateOfferStatusDTO[], transportDirection: string): Observable<HttpResponse<any>> {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/updateOfferStatus';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId});

        return this.httpClient.post(url, updateOfferStatusDTO, {params: options, observe: 'response', responseType: 'text'});
    }

    getEMailObjectsByParams(query: string, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getEMailObjectsByParams';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, query});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    filterGoodDefinationType(transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getGoodDefinationTypeByQuery';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    filterMaster(query: string, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getMasterByQuery';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, query, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getMyActivityById(id: number): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getMyActivityById';

        const options = this.createRequestOption({token, sessionId, id});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getDocTemplate(reportId: number): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getDocTemplate';

        const options = this.createRequestOption({token, sessionId, reportId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getDocumentType(reportId: number, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getDocumentType';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, reportId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    saveEmail(email: Email, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/saveEmail';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, email, {params: options, observe: 'response'});
    }

    saveAttachment(attachment: EmailAttachment, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/saveAttachment';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, attachment, {params: options, observe: 'response'});
    }

    uploadAttachment(formdata: FormData, id: number, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/uploadAttachment';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection, id});

        return this.httpClient.post<any>(url, formdata, {params: options, observe: 'response'});
    }

    sendEmail(id: number, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/sendEmail';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection, id});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    updateActivity(activityFromMobile: Activity): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/updateActivityFromMobile';

        const options = this.createRequestOption({token, sessionId});

        return this.httpClient.post<any>(url, activityFromMobile, {params: options, observe: 'response'});
    }

    checkCompanyNameDublicate(dto: any, transportDirection = null) {
        const url = environment.baseUrl + '/' + this.endpoint + '/checkCompanyNameDublicate';
        const token = getToken(transportDirection);
        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

        return this.httpClient.post<any>(url, dto, {params: queryParam, observe: 'response'});
    }

    async createReservation(reateReservationFromMobile: any, transportDirection?: string): Promise<any> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/createReservation';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection: transportDirection });
        try {
            const resp = await this.httpClient.post<string>(url, reateReservationFromMobile, {params: options, observe: 'response', responseType: 'text' as 'json'}).toPromise();
            return resp;
        } catch (e) {
            return e;
        }
    }

    createCompanyFromMobile(createCompanyFromMobile: any, transportDirection = null) {
        console.log(transportDirection," transportDirection createCompanyFromMobile")
        const url = environment.baseUrl + '/' + this.endpoint + '/createCompany';
        const token = getToken(transportDirection);
        const token2 = getToken(transportDirection == 'E' ? 'I': 'E')
        const userEmail = JSON.parse(localStorage.getItem('user')).email;
        const queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('sessionId2', token2.sessionId).append('token2', token2.token).append('userEmail', userEmail);
        return this.httpClient.post<any>(url, createCompanyFromMobile, {params: queryParam, observe: 'response'});
    }

    uploadDocuments(file: any, entityid: any, transportDirection = null) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/uploader';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection, entityid});

        return this.httpClient.post<any>(url, file, {params: options, observe: 'response'});
    }

    saveDocument(file: any, transportDirection = null) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/saveDocument';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, file, {params: options, observe: 'response'});
    }

    getReport(reportId: number, offerId: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getReport';

        const options = this.createRequestOption({token, sessionId, reportId, offerId});

        return this.httpClient.get<ArrayBuffer>(url, {params: options, responseType: 'arraybuffer' as 'json'});
    }

    getPagesByName(pageName: string, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getPagesByName';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, pageName});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getDigitalDocument(reportId: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getDigitalDocument';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, reportId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getReportAsPDF(reportDetail: any, url?: string, transportDirection?: string) {

        const token = getToken(transportDirection);

        const urlb = environment.baseUrl + '/' + this.endpoint + '/getReportAsPDF';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, url, transportDirection});

        return this.httpClient.post<ArrayBuffer>(urlb, reportDetail, {params: options, responseType: 'arraybuffer' as 'json'});
    }

    getEmailSettingsByID(reportId: number, offerId: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getEmailSettingsByID';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, reportId, offerId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    sendReportMail(reportId: number, offerId: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/sendReportMail';

        const options = this.createRequestOption({token, sessionId, reportId, offerId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response', responseType: 'text' as 'json'});
    }

    getFrontEndTariffDetMobileFront(type: string, fromCityId: number, toPortId: number, operationType?: string, seaContainerTypeIds?: number[], etd?: string, fromDistrictId?: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getFrontEndTariffDetMobile';

        const options = this.createRequestOption({token, sessionId, type, fromPortId: '', toPortId, fromCityId, toCityId: '', fromDistrictId, toDistrictId: '', fromZip: '', toZip: '', etd, operationType, seaContainerTypeIds: seaContainerTypeIds === null ? '' : seaContainerTypeIds});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    getFrontEndTariffDetMobileEnd(type: string, toCityId: number, fromPortId: number, operationType?: string, seaContainerTypeIds?: number[], etd?: string, toDistrictId?: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getFrontEndTariffDetMobile';

        const options = this.createRequestOption({token, sessionId, type, fromPortId, toPortId: '', fromCityId: '', toCityId, fromDistrictId: '', toDistrictId, fromZip: '', toZip: '', etd, operationType, seaContainerTypeIds: seaContainerTypeIds === null ? '' : seaContainerTypeIds});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    airlineSearch(name: string, transportDirection = null) {
        const token = getToken(transportDirection);
        const url = environment.baseUrl + '/' + this.endpoint + '/airlineSearch';
        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, name, transportDirection});
        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'}).pipe(map(res => {
            res.body.forEach(airport => {
                airport.name = airport.name + ' - ' + airport.airCode;
            });
            return res;
        }));
    }

    getAirFrightBuyTariffDetMobile(fromPortId: number, toPortId: number, fromCityId: number, toCityId: number, airlineId: number, validTo: string, isViaport: string, offerOrTariff: string, pageSize: number, page: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getAirFrightBuyTariffDetMobile';

        const options = this.createRequestOption({token, sessionId, fromPortId, toPortId, fromCityId, toCityId, airlineId, validTo, isViaport, offerOrTariff, pageSize, page});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'});
    }

    airportSearch(name: string, transportDirection?: string) {
        const token = getToken(transportDirection);

        let url = environment.baseUrl + '/' + this.endpoint + '/airportSearch';
        if (transportDirection) { url += `?transportDirection=${transportDirection}`; }

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, name});

        return this.httpClient.get<any[]>(url, {params: options, observe: 'response'}).pipe(map(res => {
            res.body.forEach((airport, index) => {
                airport.name = airport.code + ' - ' + airport.name;
                const exitsAirline = res.body.findIndex((f, idk) => airport.code === f.code && idk > index);
                if (exitsAirline !== -1) {
                    res.body.splice(exitsAirline, 1);
                }
            });
            return res;
        } ));
    }

    createCopyOffer(entityId: number, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/createCopyOffer';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, entityId});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    invoiceItemType(operationType?: string, transportDirection?: string): Observable<HttpResponse<any>> {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/invoiceItemType';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, operationType, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getOfferSeaDetailMobile(fromPortId: number, toPortId: number, shipOwnerId: number, customerId: number, etd: string): Observable<HttpResponse<any>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getOfferSeaDetailMobile';

        const options = this.createRequestOption({token, sessionId, fromPortId, toPortId, shipOwnerId, customerId, etd});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    listSearch(url: string, transportDirection?: string): Observable<HttpResponse<any>> {

        const token = getToken(transportDirection);

        const urlB = environment.baseUrl + '/' + this.endpoint + '/listSearch';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, url, transportDirection});

        return this.httpClient.get<any>(urlB, {params: options, observe: 'response'});
    }

    getInvoiceItemsWithEntityId(id?: number, operationType?: string, positionGoodType?: string, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getInvoiceItemsWithEntityId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection, operationType, positionGoodType});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    deleteInvoiceItemWithId(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteInvoiceItemWithId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    deleteOfferPack(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteOfferPack';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    deleteOfferSeaChargeWithId(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteOfferSeaChargeWithId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    deleteOfferAirChargeWithId(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteOfferAirChargeWithId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    getAllReportList(pageId = 0, reportType?: string, transportDirection?: string) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/getAllReportList';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, pageId, reportType, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    getSeaGood(id: number, transportDirection?: string) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/invoiceEdit';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    createOrUpdateInvoiceItems(invoiceItemMobiles: any[], transportDirection?: string) {

        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/createOrUpdateInvoiceItems';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, invoiceItemMobiles, {params: options, observe: 'response'});
    }

    deleteIncoOfferAirDTO(incoOfferAirDTO: any, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteIncoOfferAirDTO';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, incoOfferAirDTO, {params: options, observe: 'response'});
    }

    createFromOffer(offerId: number, incoOfferAirDTO: any, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/createIncoOfferAirDTOfromOffer';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, offerId, transportDirection});

        return this.httpClient.post<any>(url, incoOfferAirDTO, {params: options, observe: 'response'});
    }

    saveIncoOfferAirDTO(incoOfferAirDTO: any, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/saveIncoOfferAirDTO';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.post<any>(url, incoOfferAirDTO, {params: options, observe: 'response'});
    }

    getHasProblem(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        let url = environment.baseUrl + '/' + this.endpoint + '/getHasProblem';

        if (id) { url += `?entityId=${id}`; }

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, transportDirection});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    searchTruckBodyType(query: string, transportDirection?: string) {
        const token = getToken(transportDirection);
        let url = environment.baseUrl + '/' + this.endpoint + '/searchTruckBodyType';
        if (transportDirection) { url += `?transportDirection=${transportDirection}`; }
        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, query});
        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

    deleteOfferTruckChargeWithId(id?: number, transportDirection?: string) {
        const token = getToken(transportDirection);

        const url = environment.baseUrl + '/' + this.endpoint + '/deleteOfferTruckChargeWithId';

        const options = this.createRequestOption({token: token.token, sessionId: token.sessionId, id, transportDirection});

        return this.httpClient.delete<any>(url, {params: options, observe: 'response'});
    }

    getFrontEndTariffDet(id: number) {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/' + this.endpoint + '/getFrontEndTariffDet';

        const options = this.createRequestOption({token, sessionId, id});

        return this.httpClient.get<any>(url, {params: options, observe: 'response'});
    }

}
