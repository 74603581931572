import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory } from 'src/app/models/expense.model';
import { Issue } from 'src/app/models/issue-management-model';
import { ExpenseService } from 'src/app/services/expense.service';
import { IntranetWidgetService } from 'src/app/services/intranet-widget.service';
import { IssueManagementService } from 'src/app/services/issue-management.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { CreateIssueComponent } from '../../issue-management/create-issue/create-issue.component';
import { CreateWidgetUserRoleComponent } from '../create-widget-user-role/create-widget-user-role.component';
import { CreateWidgetComponent } from '../create-widget/create-widget.component';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})
export class WidgetsComponent implements OnInit {

  boards: any[] = [];

  selectedCategories: ExpensePackageCategory[] = [];

  dataSource = new MatTableDataSource<Issue>();

  displayedColumns = ['name', 'typeName', 'actions'];

  constructor(private expenseService: ExpenseService, private issueManagementService: IssueManagementService, private widgetService: IntranetWidgetService,
    private messageService: MessageService, public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.getAllWidgets();

  }

  getAllWidgets() {
    this.widgetService.getAllWidgets().subscribe(res => {
      this.boards = res;
      this.dataSource.data = this.boards;
    })
  }

  deleteCategory(advance: ExpensePackageCategory) {


  }

  openCreateWidgetUserRole(issue?: any) {
    let dialogRef = this.dialog.open(CreateWidgetUserRoleComponent, {
      width: '800px',
      autoFocus: false,
      data: {
        widget: issue,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      this.getAllWidgets();



    });
  }


  openCreateWidget(issue?: any) {
    let dialogRef = this.dialog.open(CreateWidgetComponent, {
      width: '1200px',
      autoFocus: false,
      data: {
        widget: issue,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      this.getAllWidgets();



    });
  }

  openConfirmDialog(category: Issue): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = category.summary;
    dialogRef.componentInstance.fIndex = category.id;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

        this.expenseService.deleteExpensePackageCategory(category.id).subscribe(res => {

          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Kategori Silindi" });

          this.getAllWidgets();
        })



      }
    });
  }

}
