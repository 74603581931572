import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KpiTargetMonthDetMobile } from 'src/app/models/kpi-target-month-det-mobile.model';
import { KpiDefinitionService } from 'src/app/services/kpi-definition.service';
import { KpiTargetMonthDet } from 'src/app/models/kpi-target-month-det.model';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-kpi-target-detail',
  templateUrl: './kpi-target-detail.component.html',
  styleUrls: ['./kpi-target-detail.component.scss']
})
export class KpiTargetDetailComponent implements OnInit {
  userTransportDirection = null;
  kpiTargetMonthMobile: KpiTargetMonthDetMobile;
  isLoading: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private messageService: MessageService, private userService: UserService, private kpiDefitionService: KpiDefinitionService) { 

  }

  async ngOnInit() {
    this.userTransportDirection = String(this.userService.getUserFromLocalStorage()["company"]["name"]).includes('KTL') ? 'I': 'E';
    this.kpiTargetMonthMobile =  await this.kpiDefitionService.getKpiTargetMonthDet(this.activatedRoute.snapshot.params.id, this.userTransportDirection).toPromise();
    this.kpiTargetMonthMobile.kpiTargetMonthDet.sort((a,b) => a.month - b.month);

    console.log(this.kpiTargetMonthMobile)
  }

  async updateKpiTarget() {

    try {
      this.isLoading = true;
      await this.kpiDefitionService.updateKpiTarget(this.kpiTargetMonthMobile.kpiTargetMonthDet, this.userTransportDirection).toPromise();
      this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kayıt başarılı.'});
      this.isLoading = false;

    } catch {
      this.isLoading = false;
    }

  }

}
