import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    user: any = null;
    userImport: any = null;
    displayModal = false;
    userProfile: any = {};
    confirmPassword: string;

    constructor(private userService: UserService) {
    }

    ngOnInit(): void {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.userImport = JSON.parse(localStorage.getItem('userimport'));
        this.getUserProfile(this.user.id, 'E');
        this.getUserProfile(this.userImport.id, 'I');
    }

    getUserProfile(userId: number, transportDirection?: string) {
        this.userService.getUserProfile(userId, transportDirection).subscribe(res => {
            if (transportDirection === 'E') {
                this.user = res.body;
                if (this.user?.smtpProfile === null) {
                    this.user.smtpProfile = this.getFirstByIsActiveIsTrue('E');
                }
            } else {
                this.userImport = res.body;
                if (this.user?.smtpProfile === null) {
                    this.userImport.smtpProfile = this.getFirstByIsActiveIsTrue('I');
                }
            }
        });
    }

    updateUserProfile() {
        if (this.confirmPassword === this.userProfile.mailPassword) {
            this.user.email = this.userProfile.email;
            this.user.mailUser = this.userProfile.mailUser;
            this.user.mailPassword = this.userProfile.mailPassword;
            // ithalat sunucusu için
            this.userImport.email = this.userProfile.email;
            this.userImport.mailUser = this.userProfile.mailUser;
            this.userImport.mailPassword = this.userProfile.mailPassword;
        }
        this.userService.updateUserProfile(this.user, 'E').subscribe(res => {
            this.user = res.body;
        });
        this.userService.updateUserProfile(this.userImport, 'I').subscribe(res => {
            this.userImport = res.body;
        });
    }

    getFirstByIsActiveIsTrue(transportDirection?: string) {
        this.userService.getFirstByIsActiveIsTrue(transportDirection).subscribe(res => {
            if (transportDirection === 'E') {
                this.user.smtpProfile = res.body;
            } else {
                this.userImport.smtpProfile = res.body;
            }
        });
    }
}
