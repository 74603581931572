import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetLessonVideo } from 'src/app/models/learning.model';
import { IntranetDocumentService } from 'src/app/services/document.service';
import { FileElement } from '../../documents/documents.component';
import { FileDialogConfirmComponent } from '../file-dialog-confirm/file-dialog-confirm.component';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  folderName: string;

  expireDate: Date;

  private basePath = '/uploads/documents';

  uploadedFiles: any[] = [];

  files: File[] = [];

  entity: IntranetDocument = <IntranetDocument>{};

  currentElement: FileElement;

  parentElement: FileElement;

  documentTypes: any[] = [];

  selectedDocumentType: any;

  departments: any[] = [];

  selectedDepartment: any;


  constructor(public dialogRef: MatDialogRef<UploadFileDialogComponent>, private intranetDocumentService: IntranetDocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog,
    private messageService: MessageService) {

    this.currentElement = this.data.currentElement;
    this.parentElement = this.data.parentElement;

  }

  ngOnInit(): void {

    this.intranetDocumentService.getDocumentTypes().subscribe(res => {
      console.log(res);
      this.documentTypes = [];

      this.documentTypes.push({
        name: 'Döküman Tipi Seçiniz',
        id: 0
      })

      res.forEach(item => {
        this.documentTypes.push(item);
      })

      this.selectedDocumentType = this.documentTypes[0];
    })

    this.intranetDocumentService.getDepartments().subscribe(res => {
      console.log(res);

      this.departments = [];

      this.departments.push({
        label: 'Departman Seçiniz',
        id: 0
      })

      res.forEach(item => {
        this.departments.push(item);
      })

      this.selectedDepartment = this.departments[0];
    })

  }

  uploadProgress: number = 0;

  onFileChange(pFileList: File[]) {

    var file = pFileList[0];

    this.uploadedFiles.push(file);

    const filePath = `${this.basePath}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);

    uploadTask.percentageChanges().subscribe(res => {

      var rounded = Math.round((res + Number.EPSILON) * 100) / 100

      this.uploadProgress = rounded;

    })


    this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {

      if (res.state == 'success') {

        storageRef.getDownloadURL().subscribe(url => {

          this.entity.url = url;


          this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

          this.files.push(file);

        });

      }

    });
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }

  openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  save() {

    var parentDocumentId = null;

    if (this.parentElement) {
      parentDocumentId = this.parentElement.id;
    }

    var document: IntranetDocument = {
      fileName: this.folderName,
      fileSize: this.files[0].size,
      fileType: this.files[0].type,
      id: null,
      mimeType: 'file',
      type: 'file',
      url: this.entity.url,
      parentDocumentId: parentDocumentId,
      firmId: null,
      documentTypeId: this.selectedDocumentType.id != 0 ? this.selectedDocumentType.id : null,
      expireDate: this.expireDate,
      departmentId: this.selectedDepartment.id != 0 ? this.selectedDepartment.id : null
    };

    this.intranetDocumentService.create(document).subscribe(res => {

      this.dialogRef.close(document.fileName);

    })

  }

}
