import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import './shared/prototypes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { AppPublicComponent } from './app-public.component';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightpanelComponent } from './app.rightpanel.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppCalendarComponent } from './pages/calendar/app.calendar.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { EmailAutocompleteComponent } from './shared/email/email-autocomplete/email-autocomplete.component';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';
import { FinancialFormsComponent } from './pages/forms/financial-forms/financial-forms.component';
import { FinancialFormListComponent } from './pages/forms/financial-form-list/financial-form-list.component';
import { SendSurveyComponent } from "./pages/survey/send-survey/send-survey.component";
import { ActivityListComponent } from "./pages/activity/activity-list/activity-list.component";
import { KpiTargetDetailComponent } from './pages/kpi/kpi-target-detail/kpi-target-detail.component';
import { ReportComponent } from './pages/power-bi/report/report.component';
import { KpiComponent } from './pages/kpi/kpi/kpi.component';
import { CompanyListComponent } from './pages/company-list/company-list.component';

import { CountryService } from './demo/service/countryservice';
import { CustomerService } from './demo/service/customerservice';
import { EventService } from './demo/service/eventservice';
import { IconService } from './demo/service/iconservice';
import { NodeService } from './demo/service/nodeservice';
import { PhotoService } from './demo/service/photoservice';
import { ProductService } from './demo/service/productservice';
import { MenuService } from './app.menu.service';
import { WeatherService } from './services/weather.service';
import { QdmsService } from './services/qdms.service';
import { PersonService } from './services/person.service';
import { KpiService } from './services/kpi.service';
import { SelectboxService } from './services/selectbox.service';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TokenInterceptor } from './services/token-interceptor.service';
import { AuthGuard } from './services/auth-guard';
import { AuthenticationService } from './services/authentication.service';
import { SeaportService } from './services/seaport.service';
import { CompanyService } from './services/company.service';
import { ContainerService } from './services/container.service';
import { ActivityService } from './services/activity.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ListAllocationComponent } from './pages/activity/allocation/list-allocation/list-allocation.component';
import { ListPriceOfferSeaComponent } from './pages/activity/offers/list-price-offer-sea/list-price-offer-sea.component';
import { AllocationService } from './services/allocation.service';
import { ListOfferRequestComponent } from './pages/activity/offers/list-offer-request/list-offer-request.component';
import { OfferRequestComponent } from './pages/activity/offers/offer-request/offer-request.component';
import { AllocationDetailComponent } from './pages/activity/allocation/allocation-detail/allocation-detail.component';
import { ListKpiTargetComponent } from './pages/kpi/kpi-target/list-kpi-target/list-kpi-target.component';
import { CreateKpiTargetComponent } from './pages/kpi/kpi-target/create-kpi-target/create-kpi-target.component';
import { KpiDefinitionService } from './services/kpi-definition.service';
import { UserService } from './services/user.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CreateAnnouncementComponent } from './pages/announcement/create-announcement/create-announcement.component';
import { EditorModule } from 'primeng/editor';
import { DashboardService } from './services/dashboard.service';
import { CardDetailComponent } from './pages/dashboard/card-detail/card-detail.component';
import { Announcement } from './models/announcement.model';
import { AnnouncementService } from './services/announcement.service';
import { AnnouncementDetailComponent } from './pages/announcement/announcement-detail/announcement-detail.component';
import { EditWidgetsComponent } from './pages/settings/edit-widgets/edit-widgets.component';
import { IntranetWidgetService } from './services/intranet-widget.service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { CreatePageComponent } from './pages/intranet-page/create-page/create-page.component';
import { IntranetPageService } from './services/intranet-page.service';
import { PageDetailComponent } from './pages/intranet-page/page-detail/page-detail.component';
import { CreateSurveyComponent } from './pages/survey/create-survey/create-survey.component';
import { TakeSurveyComponent } from './pages/survey/take-survey/take-survey.component';
import { SurveyService } from './services/survey.service';
import { ListSurveyComponent } from './pages/survey/list-survey/list-survey.component';
import { CreateDocumentComponent } from './pages/document/create-document/create-document.component';
import { DocumentsComponent } from './pages/document/documents/documents.component';
import { IntranetDocumentService } from './services/document.service';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatMenuModule } from '@angular/material/menu'
import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NewFolderDialogComponent } from './pages/document/modals/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from './pages/document/modals/rename-dialog/rename-dialog.component';
import { FileManagerComponent } from './pages/document/file-manager/file-manager.component'
import { FileService } from './services/file.service';
import { UploadFileDialogComponent } from './pages/document/modals/upload-file-dialog/upload-file-dialog.component';
import { FileDialogConfirmComponent } from './pages/document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { FileDragNDropDirective } from './pages/document/file-drag-n-drop.directive';
import { MatListModule } from '@angular/material/list';
import { CurrencyConversionService } from './services/currency-conversion.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CreateAdvanceComponent } from './pages/advance/create-advance/create-advance.component';
import { GetMyAdvancesComponent } from './pages/advance/get-my-advances/get-my-advances.component';
import { IntranetAdvanceService } from './services/intranet-advance.service';
import { CreateLessonCategoryComponent } from './pages/learning/create-lesson-category/create-lesson-category.component';
import { ListLessonCategoryComponent } from './pages/learning/list-lesson-category/list-lesson-category.component';
import { CreateLessonComponent } from './pages/learning/create-lesson/create-lesson.component';
import { EditLessonComponent } from './pages/learning/edit-lesson/edit-lesson.component';
import { CreateLessonVideoComponent } from './pages/learning/create-lesson-video/create-lesson-video.component';
import { ListLessonComponent } from './pages/learning/list-lesson/list-lesson.component';
import { CreateLessonVideoDialogComponent } from './pages/learning/dialog/create-lesson-video-dialog/create-lesson-video-dialog.component';
import { LessonAssignmentDialogComponent} from './pages/learning/dialog/lesson-assignment-dialog/lesson-assignment-dialog.component';
import { WatchLessonComponent } from './pages/learning/watch-lesson/watch-lesson.component';
import { ListLessonWatcherComponent } from './pages/learning/list-lesson-watcher/list-lesson-watcher.component';
import { LearningService } from './services/learning.service';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { CreateLessonQuizComponent } from './pages/learning/quiz/create-lesson-quiz/create-lesson-quiz.component';
import { SolveQuizComponent } from './pages/learning/quiz/solve-quiz/solve-quiz.component';
import { NewOutgoingRequestComponent } from './pages/new-outgoing-request/new-outgoing-request.component';
import { CreateExpensePackageComponent } from './pages/expense/create-expense-package/create-expense-package.component';
import { EditExpensePackageComponent } from './pages/expense/edit-expense-package/edit-expense-package.component';
import { ExpensePackageCategoriesComponent } from './pages/expense/expense-package-categories/expense-package-categories.component';
import { CreateExpensePackageCategoryComponent } from './pages/expense/modals/create-expense-package-category/create-expense-package-category.component';
import { EditExpensePackageCategoryComponent } from './pages/expense/modals/edit-expense-package-category/edit-expense-package-category.component';
import { EditExpensePackageItemComponent } from './pages/expense/modals/edit-expense-package-item/edit-expense-package-item.component';
import { CreateExpensePackageItemComponent } from './pages/expense/modals/create-expense-package-item/create-expense-package-item.component';
import { ExpenseService } from './services/expense.service';
import { MatTableModule } from '@angular/material/table';
import { GetMyExpencesComponent } from './pages/expense/get-my-expences/get-my-expences.component';
import { ApprovalExpensePackagesComponent } from './pages/expense/approval-expense-packages/approval-expense-packages.component';
import { ExpensePackageApproveComponent } from './pages/expense/expense-package-approve/expense-package-approve.component';
import { ExpensePackageItemInfoComponent } from './pages/expense/modals/expense-package-item-info/expense-package-item-info.component';
import { CreateLeaveComponent } from './pages/leave/create-leave/create-leave.component';
import { MyLeaveRequestsComponent } from './pages/leave/my-leave-requests/my-leave-requests.component';
import { LeaveRequestsComponent } from './pages/leave/leave-requests/leave-requests.component';
import { IntranetLeaveService } from './services/leave-service';
import { LeaveApprovalComponent } from './pages/leave/leave-approval/leave-approval.component';
import { OfferExportComponent } from './pages/activity/sea-export/offer-export/offer-export.component';
import { OfferExportListComponent } from './pages/activity/sea-export/offer-export-list/offer-export-list.component';
import { KanbanBoardComponent } from './pages/issue-management/kanban-board/kanban-board.component';
import { CreateIssueComponent } from './pages/issue-management/create-issue/create-issue.component';
import { IssueListComponent } from './pages/issue-management/issue-list/issue-list.component';
import { CreateColumnComponent } from './pages/issue-management/create-column/create-column.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BoardsComponent } from './pages/issue-management/boards/boards.component';
import { CreateBoardComponent } from './pages/issue-management/create-board/create-board.component';
import { IssueManagementService } from './services/issue-management.service';
import { CreateWidgetUserRoleComponent } from './pages/settings/create-widget-user-role/create-widget-user-role.component';
import { CreateWidgetComponent } from './pages/settings/create-widget/create-widget.component';
import { OfferPriceComponent } from './pages/activity/sea-export/offer-price/offer-price.component';
import { SurveyComponent } from './pages/public/survey/survey.component';
import { WidgetsComponent } from './pages/settings/widgets/widgets.component';
import { StaffService } from './services/staff.service';
import { ListAnnouncementComponent } from './pages/announcement/list-announcement/list-announcement.component';
import { SurveyReportComponent } from './pages/survey/survey-report/survey-report.component';
import { GetApprovalAdvancesComponent } from './pages/advance/get-approval-advances/get-approval-advances.component';
import { AdvanceApprovalComponent } from './pages/advance/advance-approval/advance-approval.component';
import { OfferDetailComponent } from './pages/activity/offers/offer-detail/offer-detail.component';
import { OfferTariffDetailComponent } from './pages/activity/sea-export/offer-tariff-detail/offer-tariff-detail.component';
import { OrderByPipe } from './utilities/pipes/orderby-pipe';
import { CreateCareerComponent } from './pages/career/create-career/create-career.component';
import { ListCareerComponent } from './pages/career/list-career/list-career.component';
import { CareerDetailComponent } from './pages/career/career-detail/career-detail.component';
import { CareerService } from './services/career.service';
import { ListCareerManagerComponent } from './pages/career/list-career-manager/list-career-manager.component';
import { UpdateCareerComponent } from './pages/career/update-career/update-career.component';
import { ListOfferRequestExportComponent } from './pages/activity/offers/list-offer-request-export/list-offer-request-export.component';
import {LoadingSpinnerComponent} from "./shared/loading-spinner/loading-spinner.component";
import { InstitutionalComponent } from './pages/career/institutional/institutional.component';
import { HistoryComponent } from './pages/career/history/history.component';
import { DocumentManagerComponent } from './pages/qdms/document-manager/document-manager.component';
import {AirOfferListComponent} from "./pages/activity/air-good/air-offer-list/air-offer-list.component";
import {AirOfferComponent} from "./pages/activity/air-good/air-offer/air-offer.component";
import { FolderDialogComponent} from './pages/qdms/folder-dialog/folder-dialog.component';
import { AirOfferCreateComponent } from './pages/activity/air-good/air-offer-create/air-offer-create.component';
import { FileUploaderComponent } from './pages/qdms/file-uploader/file-uploader.component';
import { FileDialogComponent } from './pages/qdms/file-dialog/file-dialog.component';
import { FileViewerComponent } from './pages/qdms/file-viewer/file-viewer.component';
import { CustomersComponent} from './pages/activity/customers/customers.component';
import { CustomerDetailComponent } from './pages/activity/customer-detail/customer-detail.component';
import { ActivitiesComponent } from './pages/activity/activities/activities.component';
import { ActivityTableDialogComponent } from './pages/activity/activity-table-dialog/activity-table-dialog.component';
import { OfferTableDialogComponent } from './pages/activity/offer-table-dialog/offer-table-dialog.component';
import { GoodTableDialogComponent } from './pages/activity/good-table-dialog/good-table-dialog.component';
import { CreateCustomerComponent } from './shared/create-customer/create-customer.component';
import { OfferExportPriceListComponent } from './pages/activity/sea-export/offer-export-price-list/offer-export-price-list.component';
import { FinancialQueryService } from './services/financial-query/financial-query.service';
import { RegionService } from './services/region.service';
import { CardSeaListComponent } from './pages/dashboard/card-open-detail/card-open-list/card-sea-list/card-sea-list.component';
import { CardActivityListComponent } from './pages/dashboard/card-open-detail/card-open-list/card-activity-list/card-activity-list.component';
import { ListComponent } from "./pages/list/list.component";
import { ProfileComponent } from './pages/settings/profile/profile.component';
import { CreateActivityComponent } from "./pages/activity/create-activity/create-activity.component";
import { StatusService } from './services/status.service';
import {EmailSendComponent} from "./shared/email/email-send/email-send.component";
import {OfferStatusComponent} from "./pages/activity/offers/offer-status/offer-status.component";
import { ListOfferRequestPartialComponent } from './pages/activity/offers/list-offer-request-partial/list-offer-request-partial.component';
import {
    OfferRequestPartialComponent
} from "./pages/activity/offers/offer-request-partial/offer-request-partial.component";
import {
    CardOfferListComponent
} from "./pages/dashboard/card-open-detail/card-open-list-offer/card-offer-list.component";
import { ListKpiCustomerTargetComponent } from './pages/kpi/list-kpi-customer-target/list-kpi-customer-target.component';
import {
    CardAirListComponent
} from "./pages/dashboard/card-open-detail/card-open-list/card-air-list/card-air-list.component";
import { PremComponent } from './pages/prem/prem/prem.component';
import {PremService} from './services/prem.service';
import { CardCompanyListComponent } from './pages/dashboard/card-open-detail/card-open-list/card-company-list/card-company-list.component';
import {InfoManagerService} from './services/info-manager.service';
import {PositiveValueDirective} from "./utilities/positive-value-directive";
import { ForgotPasswordComponent } from './pages/user/forgot-password/forgot-password.component';
import { ListOfferTruckComponent } from './pages/activity/offers/truck-offer/list-offer-truck/list-offer-truck.component';
import { TruckOfferExportComponent } from './pages/activity/offers/truck-offer/truck-offer-export/truck-offer-export.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const firebaseConfig = {
    apiKey: "AIzaSyAuN9UV5dKVDhY4plh23B6kW7GnBRJPFDY",
    authDomain: "tmax-52ac4.firebaseapp.com",
    projectId: "tmax-52ac4",
    storageBucket: "tmax-52ac4.appspot.com",
    messagingSenderId: "772523388495",
    appId: "1:772523388495:web:7608538b1c026d4201843a",
    measurementId: "G-J2QB60NHC0"
};


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SkeletonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        EditorModule,
        AngularFireModule.initializeApp(firebaseConfig),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatTableModule,
        MatIconModule,
        MatGridListModule,
        MatMenuModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatListModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        DragDropModule,
        NgxDocViewerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppPublicComponent,
        AppRightpanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        CardOfferListComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        ListAllocationComponent,
        OfferRequestComponent,
        ListOfferRequestComponent,
        ListOfferRequestExportComponent,
        AllocationDetailComponent,
        OfferDetailComponent,
        OfferTariffDetailComponent,
        ListKpiTargetComponent,
        CreateKpiTargetComponent,
        DashboardComponent,
        CreateAnnouncementComponent,
        CardDetailComponent,
        AnnouncementDetailComponent,
        EditWidgetsComponent,
        CreatePageComponent,
        PageDetailComponent,
        CreateSurveyComponent,
        TakeSurveyComponent,
        ListSurveyComponent,
        CreateDocumentComponent,
        DocumentsComponent,
        NewFolderDialogComponent,
        RenameDialogComponent,
        FileManagerComponent,
        UploadFileDialogComponent,
        FileDialogConfirmComponent,
        FileDragNDropDirective,
        CreateAdvanceComponent,
        GetMyAdvancesComponent,
        CreateLessonCategoryComponent,
        ListLessonCategoryComponent,
        CreateLessonComponent,
        EditLessonComponent,
        CreateLessonVideoComponent,
        ListLessonComponent,
        CreateLessonVideoDialogComponent,
        LessonAssignmentDialogComponent,
        FolderDialogComponent,
        WatchLessonComponent,
        ListLessonWatcherComponent,
        CreateLessonQuizComponent,
        SolveQuizComponent,
        NewOutgoingRequestComponent,
        CreateExpensePackageComponent,
        EditExpensePackageComponent,
        ExpensePackageCategoriesComponent,
        CreateExpensePackageCategoryComponent,
        EditExpensePackageCategoryComponent,
        EditExpensePackageItemComponent,
        CreateExpensePackageItemComponent,
        GetMyExpencesComponent,
        ApprovalExpensePackagesComponent,
        ExpensePackageApproveComponent,
        ExpensePackageItemInfoComponent,
        CreateLeaveComponent,
        MyLeaveRequestsComponent,
        LeaveRequestsComponent,
        LeaveApprovalComponent,
        OfferExportComponent,
        OfferExportListComponent,
        KanbanBoardComponent,
        CreateIssueComponent,
        IssueListComponent,
        CreateColumnComponent,
        BoardsComponent,
        CreateBoardComponent,
        CreateWidgetUserRoleComponent,
        CreateWidgetComponent,
        WidgetsComponent,
        ListAnnouncementComponent,
        SurveyReportComponent,
        OfferPriceComponent,
        SurveyComponent,
        GetApprovalAdvancesComponent,
        AdvanceApprovalComponent,
        OfferDetailComponent,
        OfferTariffDetailComponent,
        CreateCareerComponent,
        ListCareerComponent,
        CareerDetailComponent,
        ListCareerManagerComponent,
        UpdateCareerComponent,
        LoadingSpinnerComponent,
        ListPriceOfferSeaComponent,
        InstitutionalComponent,
        HistoryComponent,
        AirOfferListComponent,
        AirOfferComponent,
        DocumentManagerComponent,
        AirOfferCreateComponent,
        FileUploaderComponent,
        FileDialogComponent,
        FileViewerComponent,
        CustomersComponent,
        CustomerDetailComponent,
        ActivitiesComponent,
        ActivityTableDialogComponent,
        EmailAutocompleteComponent,
        OfferTableDialogComponent,
        GoodTableDialogComponent,
        CreateCustomerComponent,
        ChangePasswordComponent,
        CreateCustomerComponent,
        OfferExportPriceListComponent,
        FinancialFormsComponent,
        FinancialFormListComponent,
        CardSeaListComponent,
        CardAirListComponent,
        CardActivityListComponent,
        ListComponent,
        ProfileComponent,
        SendSurveyComponent,
        CreateActivityComponent,
        ActivityListComponent,
        OfferStatusComponent,
        KpiTargetDetailComponent,
        EmailSendComponent,
        ReportComponent,
        KpiComponent,
        OfferRequestPartialComponent,
        ListOfferRequestPartialComponent,
        ListKpiCustomerTargetComponent,
        CompanyListComponent,
        PremComponent,
        CardCompanyListComponent,
        PositiveValueDirective,
        ForgotPasswordComponent,
        ListOfferTruckComponent,
        TruckOfferExportComponent
    ],
    providers: [
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MenuService, AppMainComponent, AppPublicComponent, DatePipe, OrderByPipe,
        AuthenticationService,
        SeaportService,
        CompanyService,
        ContainerService,
        ActivityService,
        MessageService,
        ConfirmationService,
        AllocationService,
        KpiDefinitionService,
        UserService,
        DashboardService,
        AnnouncementService,
        StaffService,
        IntranetWidgetService,
        IntranetPageService,
        SurveyService,
        IntranetDocumentService,
        CurrencyConversionService,
        FileService,
        IntranetAdvanceService,
        LearningService,
        ExpenseService,
        IntranetLeaveService,
        IssueManagementService,
        WeatherService,
        CareerService,
        QdmsService,
        PersonService,
        FinancialQueryService,
        RegionService,
        AuthGuard,
        StatusService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        KpiService,
        SelectboxService,
        PremService,
        InfoManagerService
    ],
    exports: [FileManagerComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
}
