import {Directive, HostListener, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[appPositiveValue]'
})
export class PositiveValueDirective {
    constructor(private el: ElementRef) {}

    @HostListener('keyup', ['$event'])
    onKeyUp(): void {
        if(this.el.nativeElement.value <= 0)
        this.el.nativeElement.value = Number(this.el.nativeElement.value) * -1;
    }

}
