import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Announcement } from 'src/app/models/announcement.model';
import { Career } from 'src/app/models/career.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { CareerService } from 'src/app/services/career.service';
import { IntranetLeaveService } from 'src/app/services/leave-service';

@Component({
  selector: 'app-list-career',
  templateUrl: './list-career.component.html',
  styleUrls: ['./list-career.component.scss']
})
export class ListCareerComponent implements OnInit {

  entities: Career[] = [];

  selectedAdvances: Career[] = [];

  constructor(private careerServşce: CareerService,
    private router: Router) { }

  ngOnInit(): void {

    this.getCareers();

  }

  getCareers() {


    this.careerServşce.getAllCareers().subscribe(res => {
      this.entities = res;
    })
  }

  navigateDetail(entity: any) {

    this.router.navigate(["career/career-detail/" + entity.id]);

  }

}
