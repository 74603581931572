import { Question } from "./question";

export class Survey {

    id: number;
    name: string;
    surveyType: string;
    isPublic: boolean;
    questions: Question[];
    canReportable: boolean;

}