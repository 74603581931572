import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { KpiTarget } from 'src/app/models/kpi-target.model';
import { UserService } from 'src/app/services/user.service';
import { MinDTO } from 'src/app/models/min-dto.model';
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import { KpiDefinitionService } from 'src/app/services/kpi-definition.service';

@Component({
  selector: 'app-list-kpi-target',
  templateUrl: './list-kpi-target.component.html',
  styleUrls: ['./list-kpi-target.component.scss']
})
export class ListKpiTargetComponent implements OnInit {

  kpiTargets: MinDTO[] = [];
  userTransportDirection = null;
  userId: number = null;
  isLoading: boolean = false;

  constructor(private router: Router, private userService: UserService, private kpiDefinitionService: KpiDefinitionService) { }

  async ngOnInit()  {
    this.userTransportDirection = String(this.userService.getUserFromLocalStorage()["company"]["name"]).includes('KTL') ? 'I': 'E';
    this.userId = Number(this.userService.getUserFromLocalStorage()["id"]);
    try {
      this.isLoading = true;
      this.kpiTargets = await this.getKpiTargetByUser(this.userId, this.userTransportDirection);
      this.isLoading = false;
    } catch {
      this.isLoading = false;
    }
    
  }

  async getKpiTargetByUser(userId, userTransportDirection) {
    return await  this.kpiDefinitionService.getKpiTargetByUser(userId, userTransportDirection).toPromise();
  }

  navigateDetail(kpiTarget: MinDTO) { this.router.navigate([`kpi/${kpiTarget.id}`]) }


}
