import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ChangeLanguageService} from "./services/change-language.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    items: MenuItem[];

    firm: any;

    constructor(public app: AppComponent, public appMain: AppMainComponent, private router: Router, private translateService: TranslateService,
                private changeLanguage: ChangeLanguageService) { }
    ngOnInit(): void {
        this.firm = sessionStorage.getItem('firm');
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    navigateEditWidgets() {

        alert('clicked');

        this.router.navigate(["/settings/edit-widgets"]);
    }

    useTr() {
        this.translateService.use('tr');
        this.changeLanguage.languageChanged.next('tr');
    }
    useEn() {
        this.translateService.use('en');
        this.changeLanguage.languageChanged.next('en');
    }
}
