import { Component, OnInit } from '@angular/core';
import { ListService } from 'src/app/services/list.service.service';
import * as FileSaver from 'file-saver'
import { v4 } from 'uuid';
import * as moment from "moment";
import * as XLSX from "xlsx";
import { UserService } from 'src/app/services/user.service';
import {DatePipe} from '@angular/common';
import { SimpleEntity } from 'src/app/models/simple-entity.model';


@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  isEnableSalesRepFilter: Boolean = false;
  filteredUsers: SimpleEntity[] = [];
  transportDirections = [{name: 'KTL International', value: 'I'}, {name: 'KINAY', value: 'E'}]


  selectedTransportDirection = 'I';
  searchedTransportDirection = null;

  activityRepresentative = JSON.parse(localStorage.getItem('userimport')).firstName + ' ' + JSON.parse(localStorage.getItem('userimport')).lastName;

  selectedFilterDate = 'createDate';
  tmaxxSearchEndPoints = "/marketing/list/companyList/listSearch?name=companyList"
  tmaxxExportExcel = "/marketing/list/companyList/exportToExcel?name=companyList"

  isLoading: boolean = false;
  result: any = null;
  filters = {
    dateStart: {
      isDate: true,
      prefix: '/DT/GT',
      value: null,
    },
      dateEnd: {
      isDate: true,
      prefix: '/DT/LT',
      value: null,
    },
    operator: {
      prefix: 'salesRepresentative',
      value: this.getOperatorId()
    },
    getParams: () => {
      let params = {};
      Object.keys(this.filters).forEach(k => {
          console.log(this.filters[k].value)

        if(typeof this.filters[k] == "function") return;
        // @ts-ignore
        if(this.filters[k].value) {
          // @ts-ignore
          let value = this.filters[k].value;
          if(typeof value  == 'object') {
            value = value instanceof Date ? value.toISOString().slice(0, -1): (value.code || value.id)
          }

          // date format fix
          if(this.filters[k]?.isDate) {
            let d = new Date(value);
            d.setDate(d.getDate() +1);
            if(this.filters[k].prefix.includes('/GT') ) {
              value = d.toISOString().split('T')[0] + 'T00:00:00.000'
            }
            if(this.filters[k].prefix.includes('/LT') ) {
              value = d.toISOString().split('T')[0] + 'T23:59:59.000'
            }
            Object.assign(params, {[this.selectedFilterDate + this.filters[k].prefix]: value});
            return;
          }

          if (value !== null)
          Object.assign(params, {[this.filters[k].prefix]: value});
        }
      })
      return params;
    }
  }


  constructor(private listService: ListService, private userService: UserService, private datepipe: DatePipe) { }

  async ngOnInit() {
      this.isEnableSalesRepFilter = await  this.getUserEnableSalesRepFilter();
      const user = this.getUserByTransportDirection(this.selectedTransportDirection);
      Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
      this.filters.operator.value = user
  }

  async onChangeTransportDirection() {
    this.isEnableSalesRepFilter = await  this.getUserEnableSalesRepFilter();
    const user = this.getUserByTransportDirection(this.selectedTransportDirection);
    Object.assign(user, {name: `${user.firstName} ${user.lastName}`});
    this.filters.operator.value = user
  }

  async getUserEnableSalesRepFilter() {
    return await this.userService.function("USER_ENABLE_SALESREP_FILTER", this.selectedTransportDirection).toPromise()
  }

  async search() {
    try {
      this.isLoading = true;
      let par = this.filters.getParams();
      console.log(par)
      this.result = await this.getData(par);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }

  }

  async getData(params = null) {
    this.searchedTransportDirection = this.selectedTransportDirection;
    return this.listService.search(params, this.tmaxxSearchEndPoints, this.selectedTransportDirection).toPromise();
  }



  async exportFromTmaxx() {
    this.isLoading = true;
    try {
      let result = await this.listService.exportExcel(this.filters.getParams(), this.tmaxxExportExcel, this.selectedTransportDirection).toPromise();
      let blob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      this.isLoading = false;
      FileSaver.saveAs(blob,  `${v4()}.xlsx`);
    } catch (e) {
      this.isLoading = false
    }


  }


  getUserByTransportDirection(transportDirection) {
    if(transportDirection == 'I' && localStorage.getItem('import')) {
      return JSON.parse(localStorage.getItem('import')).user
    }
    if(transportDirection == 'E' && localStorage.getItem('export')) {
      return JSON.parse(localStorage.getItem('export')).user
    }

    return JSON.parse(localStorage.getItem('user'))
  }

  getOperatorId() {
    const a =  this.selectedTransportDirection == 'I' ? (this.userService.getUserFromLocalStorage('userimport') ? this.userService.getUserFromLocalStorage('userimport') : this.userService.getUserFromLocalStorage('user') ) : this.userService.getUserFromLocalStorage('user');
    console.log(a, "operatorrrrrrr")
  }

  async exportToExcel() {
    if(!this.result) return;
    var rows = [];
    var header = [];
    const colSizes = [];
    const rowSizes = [];

    //UFUK : Headerlari ekliyoruz
    for (let column of this.result.dynamicListFieldDefs) {
      header.push(column["header"]);
      colSizes.push({ wch: column["width"] / 7 });//7 ekrandakine yakın olsun diye oranti normalde width 100 150 geliyor excelin olcu birimi farkli
    }
    rows.push(header);

    //UFUK : Datayi ekliyoruz

    let filteredOrFull: any[];
    filteredOrFull = this.result.datas;



    for (let data of filteredOrFull) {
      rowSizes.push({ hpt: 25 });
      var row = [];
      for (let column of this.result.dynamicListFieldDefs) {//UFUK stunlarda gozukmeyen id posid index vs alanlari aktarmıyoruz o yuzden stun bazlı donuyoruz
        if (column.type == "number") {
          let number = String(data[column.id]);
          //let number = data[column.id].replace(/\./g, '');
          //number = number.replace(/\,/g, '.');

          // column type number olsa da subQuery ile gelen sonuç 4.222,33 gibi olabiliyor
          // değer 4.000,33 gibi bir rakkamsal değer olduğunu anlıyoruz.
          if(number.indexOf(',') > -1 && number.indexOf('.') > -1) {
            number = number.replace(/\./g, '').replace(/\,/g, '.');
          } else {
            // bu durumda 2.202 gibi bir rakkamsal değer olduğunu anlıyoruz.
            number = number.replace(/\,/g, '.');
          }

          row.push(Number(number));
        } else if (column.type == "date") {
          if (data[column.id] != null) {
            try {
                console.log(data[column.id])
              // let date = moment(data[column.id], 'YYYY.MM.DD');
                row.push(this.datepipe.transform(data[column.id], 'dd.MM.YYYY'));
            } catch (error) {
              row.push(data[column.id]);
            }
          } else {
            row.push(null);
          }
        } else {
          row.push(data[column.id]);
        }
      }
      rows.push(row);
      row = [];
    }

    var row = [];
    var emptyRow = [];

    for (let column of this.result.dynamicListFieldDefs) {//UFUK toplamları ekliyoruz
      emptyRow.push("");
      if (column == null || column == "") {
        row.push("");
      } else {
        console.log(column)
        row.push(this.getTotal(column, false));
      }
    }

    rows.push(emptyRow);
    rows.push(row);

    const workSheet = XLSX.utils.json_to_sheet(rows, { skipHeader: true, dateNF: 'dd/MM/yyyy', cellDates: true });
    workSheet['!cols'] = colSizes;
    workSheet['!rows'] = rowSizes;
    //const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Tmaxx');
    XLSX.writeFile(workBook, this.result.dynamicListDef.name + ".xlsx");
  }

  formatValue(column, value) {
    if (column?.type == "number") {
      let number = value;
      const val = (number)?.toLocaleString('tr-TR', {style: 'currency',currency: 'TRY',});
      if(val?.length == 1) return val;
      return (number)?.toLocaleString('tr-TR', {style: 'currency',currency: 'TRY',}).substring(1)
    } else if (column.type == "date") {
      if (value != null) {
        try {
          return new Date(value).toLocaleString();
        } catch (error) {
          return "";
        }
      }
    } else {
      return value;
    }
    return value;
  }

  getTotal(column, formatted = true) {
    let sum = 0;
    if (column.type == "number" && this.result) {

      this.result.datas.forEach( f =>  sum += Number(f[column.id]))
      if(formatted) return this.formatValue(column, sum);
      return sum;
    }
    return "";
  }

  onActivitySelect(event) {
    window.open(`/activities/customers/edit/${this.searchedTransportDirection}/${event['id']}`)
  }
  filterUsers(event) {
    this.userService.searchUsers(event.query, this.selectedTransportDirection).subscribe(res => {
      this.filteredUsers = res;
      console.log(this.filteredUsers)
    })
  }


}
