import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { KpiMonthlyMobile } from "../models/kpi/kpi-monthly-mobile";
import { SimpleEntity } from "../models/simple-entity.model";
import { KpiTargetMonthDTO } from "../models/kpi/kpi-target-month-dto";
import {KpiTargetCustomerMonthMobile} from '../models/kpi/kpi-target-customer-month-mobile';
import { KpiTargetListResultMobile } from "../models/kpi/kpi-target-list-result-mobile";

@Injectable()
export class KpiService extends BaseService<any>{

    constructor(public httpClient: HttpClient) {
        super('kpi', httpClient);
    }


    getKpiMonthlyByUser(transportDirection: string = null, userId  = null): Observable<KpiMonthlyMobile> {
        const url = `${environment.baseUrl}/${this.endpoint}/getKpiMonthlyByUser`
        let queryParam: HttpParams;
        let token = this.getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("userId", userId)

        return this.httpClient.get<KpiMonthlyMobile>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          );
    }

    createOrUpdateKpiTarget(dto: KpiMonthlyMobile, transportDirection: string = null): Observable<KpiMonthlyMobile> {
      const url = `${environment.baseUrl}/${this.endpoint}/createOrUpdateKpiTarget`
      let queryParam: HttpParams;
      let token = this.getToken(transportDirection)

      queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

      return this.httpClient.post<KpiMonthlyMobile>(url,dto, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
        .pipe(
          tap(),
          catchError(this.handleError)
        );
  }

  deleteKpiTargetMonthDet(kpiTargetCustomerDetId: number, transportDirection: string = null): Observable<boolean> {
    const url = `${environment.baseUrl}/${this.endpoint}/deleteKpiTargetMonthDet`
    let queryParam: HttpParams;
    let token = this.getToken(transportDirection)

    queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("kpiTargetCustomerDetId", kpiTargetCustomerDetId)

    return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );
  }
  getScoreUserByLead(transportDirection: string = null): Observable<SimpleEntity[]> {
    const url = `${environment.baseUrl}/${this.endpoint}/getScoreUserByLead`
    let queryParam: HttpParams;
    let token = this.getToken(transportDirection)

    queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection);

    return this.httpClient.get<SimpleEntity[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );
  }

  findByCustomerMonthDetCompany(customerId: number, transportDirection: string = null): Observable<KpiTargetMonthDTO[]> {
    const url = `${environment.baseUrl}/${this.endpoint}/findByCustomerMonthDetCompany`
    let queryParam: HttpParams;
    let token = this.getToken(transportDirection)

    queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append('customerId', customerId)

    return this.httpClient.get<KpiTargetMonthDTO[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );
  }

  approveKpiTargetCustomerDet(id: number, transportDirection: string = null): Observable<boolean> {
    const url = `${environment.baseUrl}/${this.endpoint}/approveKpiTargetCustomerDet`
    let queryParam: HttpParams;
    let token = this.getToken(transportDirection)

    queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("id", id)

    return this.httpClient.get<boolean>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );
  }

    approveKpiTargetCustomerDetWithApprovalStatus(dto: KpiTargetCustomerMonthMobile[], transportDirection: string = null): Observable<KpiTargetCustomerMonthMobile[]> {
        const url = `${environment.baseUrl}/${this.endpoint}/approveKpiTargetCustomerMonthMobile`
        let queryParam: HttpParams;
        const token = this.getToken(transportDirection)

        queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection)

        return this.httpClient.post<KpiTargetCustomerMonthMobile[]>(url, dto, { withCredentials: false, headers: this.httpHeaders(), params: queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            );
    }

    getCalculatedTargetSources(kpiTargetCustomerMonthDetId:number, month: number, transportDirection: string = null,): Observable<SimpleEntity[]> {
      const url = `${environment.baseUrl}/${this.endpoint}/getCalculatedTargetSources`
      let queryParam: HttpParams;
      let token = this.getToken(transportDirection)

      queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("kpiTargetCustomerMonthDetId", kpiTargetCustomerMonthDetId).append("month", month)

      return this.httpClient.get<SimpleEntity[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
        .pipe(
          tap(),
          catchError(this.handleError)
        );
    }

    getKpiListResult(params: any, transportDirection: string = null):  Observable<KpiTargetListResultMobile[]> {

      const url = `${environment.baseUrl}/${this.endpoint}/getKpiListResult`
      let query = encodeURI(JSON.stringify(params || {})).split('#').join('%23');
      let queryParam: HttpParams;
      let token = this.getToken(transportDirection)
      queryParam = new HttpParams().append('token', token.token).append('sessionId', token.sessionId).append('transportDirection', transportDirection).append("query", query);

      return this.httpClient.get<KpiTargetListResultMobile[]>(url,{ withCredentials: false, headers: this.httpHeaders(), params: queryParam })
      .pipe(
        tap(),
        catchError(this.handleError)
      );

    }


}
