import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IntranetLesson } from 'src/app/models/learning.model';
import { Survey } from 'src/app/models/survey-models/survey.model';
import { LearningService } from 'src/app/services/learning.service';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'app-solve-quiz',
  templateUrl: './solve-quiz.component.html',
  styleUrls: ['./solve-quiz.component.scss']
})
export class SolveQuizComponent implements OnInit {

  survey: Survey = <Survey>{};

  lesson: IntranetLesson = <IntranetLesson>{};

  isLoading = false;

  constructor(private surveyService: SurveyService, private learningService: LearningService, private route: ActivatedRoute, private messageService: MessageService) {

    this.route.params.subscribe(res => {
      var id = res.id;

      this.learningService.getLessonWithQuizObjects(id).subscribe(res => {
        console.log(res);
        this.lesson = res;
      })

    });

  }

  ngOnInit(): void {



  }

  save() {

    this.isLoading = true;

    this.learningService.completeQuiz(this.lesson).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Cevaplarınız Gönderildi" });


    }, err => {

      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;

    })


  }

}
