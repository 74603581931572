import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Announcement } from 'src/app/models/announcement.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { AnnouncementService } from 'src/app/services/announcement.service';

@Component({
  selector: 'app-create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['./create-announcement.component.scss']
})


export class CreateAnnouncementComponent implements OnInit {

  descriptionNames: any[] = [];

  selectedDescriptionName: any;

  editorText: string;

  authorizations: City[];

  selectedAuthorizations: City[];

  userGroups: UserGroup[] = [];

  selectedUserGroups: UserGroup[] = [];

  types: any[] = [];

  selectedType: any;

  startDate: Date = new Date();

  endDate: Date = new Date();

  shortMessage: string = "";

  isBanner: boolean = false;

  isBannerTypeList: any[] = [];

  isBannerTypeSelectedItem: any;

  onCalendarList: any[] = [];

  onCalendarSelectedItem: any;

  nameText: string;

  onCalendar: boolean = false;

  CALENDER_CONFIG_EN = {
    firstDayOfWeek: 1,
    dayNames: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
    dayNamesShort: ['Pzt', 'Sal', 'Çrşb', 'Thu', 'Cum', 'Cumt', 'Paz'],
    dayNamesMin: ['Pt', 'Sa', 'Çş', 'Pş', 'Cm', 'Ct', 'Pz'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim',
      'Kasım', 'Aralık'],
    monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Aug', 'Eyl', 'Ekm', 'Kas', 'Ara'],
    today: 'Bugün',
    clear: 'Temizle',
  }

  constructor(private messageService: MessageService, private announcementService: AnnouncementService) { }

  ngOnInit(): void {

    var user = JSON.parse(localStorage.getItem("user"));

    this.announcementService.getUserGroupsByUserId(user.id).subscribe(res => {
      console.log(res);
      this.userGroups = res;
    })

    this.announcementService.getAllAnnouncementTypes().subscribe(res => {
      this.types = [{id:0, name:'Seçiniz'}].concat(res);
    })

    this.authorizations = [
      { name: 'Yönetim Grubu', code: 'NY' },
      { name: 'Satış Yönetimi', code: 'RM' },
      { name: 'Bilgi İşlem', code: 'LDN' },
    ];

    this.descriptionNames.push({
      name: 'İnstan Kaynakları Yönetimi',
      value: ''
    })

    this.isBannerTypeList.push({
      name: "Banner",
      value: true
    });

    this.isBannerTypeList.push({
      name: "Anasayfa",
      value: false
    });

    this.onCalendarList.push({
      name: "Takvimde Görünsün",
      value: true
    });

    this.onCalendarList.push({
      name: "Takvimde Görünmesin",
      value: false
    });

    this.isBannerTypeSelectedItem = this.isBannerTypeList[0];

  }

  isLoading = false;

  save() {
    if(!this.startDate || !this.endDate || !this.nameText || this.selectedUserGroups.length == 0 || !this.shortMessage || !this.editorText) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Dikkat', detail: "Lütfen tüm alanları doldurunuz!" });
      return;
    }
    if (!this.nameText && (!this.selectedType || !this.selectedType.id)) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Dikkat', detail: "Duyuru adı seçimi zorunludur!" });
      return;
    }

    if (!this.onCalendarSelectedItem) {
      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Dikkat', detail: "Takvimde görüntüleme seçimi zorunludur!" });
      return;
    }



    var userGroupIds: string = "";

    if (this.selectedUserGroups) {

      userGroupIds = this.selectedUserGroups.map(u => u.id).join(';');

    }

    var announcement: Announcement = {
      description: this.editorText,
      isBanner: this.isBannerTypeSelectedItem.value,
      shortMessage: this.shortMessage,
      typeIds: (this.selectedType) ? this.selectedType.id : null,
      nameText: this.nameText,
      userGroupIds: userGroupIds,
      createDate: new Date(),
      endDate: this.endDate,
      startDate: this.startDate,
      onCalendar: this.onCalendarSelectedItem.value
    }

    this.isLoading = true;

    this.announcementService.addAnnouncement(announcement).subscribe(res => {
      this.isLoading = false;


      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Duyuru Başarıyla Kaydedildi" });
    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })


  }

  onChangeType(event) {
    this.nameText = !event.value.id ? null: event.value.name;
  }

}

interface City {
  name: string,
  code: string
}
