import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Email} from "../../../models/email.model";
import {EmailAttachment} from "../../../models/email-attachment.model";
import EntityUtil from "../../../models/framework/utils/entity-util";
import {OfferExportService} from "../../../services/offer-export.service";
import {MessageService} from "primeng/api";
import {PersonService} from "../../../services/person.service";

@Component({
    selector: 'app-email-send',
    templateUrl: './email-send.component.html',
})
export class EmailSendComponent implements OnInit{

    @Output() displayMail = new EventEmitter<boolean>();
    mailFrom = '';
    mailTo: string[] = [];
    cc: string[] = [];
    bcc: string[] = [];
    subject: string;
    context = '';
    isLoading = false;
    visible = true;
    attachment: any;
    file: any;
    @Input() report: any;
    @Input() offerId: number;
    @Input() customerId: number;
    @Input() transportDirection: string;

    constructor(private offerExportService: OfferExportService, private messageService: MessageService, private personService: PersonService,) {
    }

    ngOnInit(): void {
        this.mailFrom = JSON.parse(localStorage.getItem('user')).email;
        this.addPdfAsAttachment();
    }

    sendReportMail() {
        this.isLoading = true;
        const email: Email = new Email();
        email.fromAddress = JSON.parse(localStorage.getItem('user')).email;

        
        email.user = JSON.parse(localStorage.getItem('user'));

        if(this.transportDirection == 'I') {
            email.user = JSON.parse(localStorage.getItem('userimport'));
        } 

        email.toAddress = this.mailTo?.toString();
        email.ccAddress = this.cc?.toString();
        email.bccAddress = this.bcc?.toString();
        email.subject = this.subject;
        email.body = this.context;

        if (!email.toAddress?.length || email.subject === '' || email.body === '') {
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Alıcı, Konu ve içerik boş olamaz!'});
            this.isLoading = false;
        } else {
            const attachment: EmailAttachment = new EmailAttachment();
            let emailAttachment: EmailAttachment;
            let uploadedAttachment: EmailAttachment;
            this.offerExportService.saveEmail(email, this.transportDirection).subscribe(res => {
                attachment.email = EntityUtil.toSimple(res.body);
                attachment.createDate = new Date();
                attachment.fileName = 'Teklif' + '.pdf';
                attachment.fileContentType = '.pdf';
                attachment.fileExtension = '.pdf';
                attachment.name = 'Teklif' + '.pdf';
                attachment.reportId = this.report?.id;
                this.attachment = attachment;

                this.offerExportService.saveAttachment(this.attachment, this.transportDirection).subscribe(atc => {
                    emailAttachment = atc.body;
                    this.file = new File([this.file], 'fileName');
                    const formData: FormData = new FormData();
                    formData.append('file', this.file);
                    this.offerExportService.uploadAttachment(formData, atc.body.id).subscribe(up => {
                        uploadedAttachment = up.body;
                        this.offerExportService.sendEmail(res.body.id).subscribe(ans => {
                            this.isLoading = false;
                            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Mail Gönderildi!' });
                            this.displayMail.emit(false);
                        }, error => {
                            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Konu ve içerik boş olamaz!' });
                            this.isLoading = false;
                        });
                    });
                });
            });
        }
    }

    addPdfAsAttachment() {
        this.isLoading = true;

        // if (this.customerId) {
        //     this.personService.search({'company.id': this.customerId}, 'I').subscribe(persons => {
        //         this.companyEmails = this.companyEmails.concat(persons.body.filter(f => f.email).map(q => q.email));
        //
        //         this.companyService.getById(this.customerId, 'I').subscribe(c => {
        //             this.companyEmails = this.companyEmails.concat(c.body.defaultAddresCardDetail.emails.map(e => e.name));
        //         })
        //     });
        // }

        const renderedParams = {
            entityId: this.offerId,
            companyTypeCode: 'customer',
            pageId: this.report?.page?.id
        };
        this.offerExportService.getEMailObjectsByParams(JSON.stringify(renderedParams), this.transportDirection).subscribe(res => {
            if (!this.mailTo?.length) {
                if (!res?.body[0]?.email) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Uyarı',
                        detail: 'Şirket e-posta adresi bulunamadı.'
                    });
                    return;
                }
                this.mailTo.push(res.body[0].email);
            }
        });
        this.offerExportService.getReport(this.report?.id, this.offerId).subscribe(gt => {
            this.isLoading = false;
            const file = new Blob([gt], {type: 'application/pdf'});
            this.file = file;
            this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Eklendi'});
        }, error => {
            this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF Eklenemedi!'});
            this.isLoading = false;
        });
        this.offerExportService.getEmailSettingsByID(this.report?.id, this.offerId, this.transportDirection).subscribe(doc => {
            this.context = doc?.body?.mailBody;
            this.subject = doc?.body?.mailSubject;
        });
    }

    showPdf() {
        this.isLoading = true;
        this.isLoading = false;
        const file = this.file;
        const fileUrl = window.URL.createObjectURL(file);
        window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
        this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu'});
    }

}
