
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { QdmsFile } from '../models/qdms/file-model';
import { FileObj } from '../models/qdms/file-obj-model';
import { Folder } from '../models/qdms/folder-model';
import { FolderPermissions } from '../models/qdms/folder-permissions';
import { BaseService } from './base.service';
import {SimpleEntity} from "../models/simple-entity.model";


@Injectable()
export class QdmsService extends BaseService<any>{

    public queryParam: HttpParams;
    public user: any;

    constructor(public httpClient: HttpClient) {
        super('qdms', httpClient);
        this.user = JSON.parse(localStorage.getItem("user"));
        this.queryParam = new HttpParams().set('token', localStorage.getItem("token")).set('sessionId', localStorage.getItem("sessionId"));
    }

    getParentFolders(): Observable<Folder[]> {
        return this.httpClient.get<Folder[]>(`${environment.baseUrl}/${this.endpoint}/getParentFolders?userId=${this.user.id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getFolder(id: number): Observable<Folder> {
        return this.httpClient.get<Folder>(`${environment.baseUrl}/${this.endpoint}/folder/${id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getChildFolders(parentFolderId: number): Observable<Folder[]> {
        return this.httpClient.get<Folder[]>(`${environment.baseUrl}/${this.endpoint}/getChildFolders/${parentFolderId}?userId=${this.user.id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    saveFolder(folder: Folder): Observable<Folder> {
        return this.httpClient.post<Folder>(`${environment.baseUrl}/${this.endpoint}/folder`, folder, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    updateFolder(folder: Folder): Observable<Folder> {
        return this.httpClient.put<Folder>(`${environment.baseUrl}/${this.endpoint}/folder`, folder, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    saveFolderPermission(permissions: FolderPermissions): Observable<FolderPermissions> {
        return this.httpClient.post<FolderPermissions>(`${environment.baseUrl}/${this.endpoint}/folderPermissions`, permissions, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    deleteFolderPermissions(id: number): Observable<any> {
        return this.httpClient.delete<Boolean>(`${environment.baseUrl}/${this.endpoint}/folderPermissions/${id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    deleteFolder(id: number): Observable<any> {
        return this.httpClient.delete<Boolean>(`${environment.baseUrl}/${this.endpoint}/folder/${id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    searchFolderPermissions(params): Observable<FolderPermissions[]> {
        const query = encodeURI(JSON.stringify(params));
        return this.httpClient.get<FolderPermissions[]>(`${environment.baseUrl}/${this.endpoint}/folderPermissions/search?query=${query}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    updateFolderPermissions(folderPermissions: FolderPermissions): Observable<FolderPermissions> {
        return this.httpClient.put<FolderPermissions>(`${environment.baseUrl}/${this.endpoint}/folderPermissions`, folderPermissions, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    upload(file: File, entityId: number, qdmsFile: QdmsFile): Observable<QdmsFile> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('qdmsFile',  new Blob([JSON.stringify(qdmsFile)], {type : 'application/json'}));

        return this.httpClient.post<QdmsFile>(`${environment.baseUrl}/${this.endpoint}/upload?entityId=${entityId}&userId=${this.user.id}`, formData, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    getQdmsFilesByFolder(folderId: number): Observable<QdmsFile[]> {
        return this.httpClient.get<QdmsFile[]>(`${environment.baseUrl}/${this.endpoint}/getFilesByFolder?folderId=${folderId}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
    }

    getFilesByEntityId(entityId): Observable<any[]> {
        return this.httpClient.get<Folder[]>(`${environment.baseUrl}/${this.endpoint}/getFilesByEntityId/${entityId}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getFile(fileId): Observable<FileObj> {
        return this.httpClient.get<FileObj>(`${environment.baseUrl}/${this.endpoint}/getFile/${fileId}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
    }

    deleteFile(qdmsFile: QdmsFile): Observable<any> {
        return this.httpClient.delete<Boolean>(`${environment.baseUrl}/${this.endpoint}/file?userId=${this.user.id}&fileId=${qdmsFile.id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam });
    }

    isUserQdmsAdmin(): Observable<any> {
        return this.httpClient.get<FileObj>(`${environment.baseUrl}/${this.endpoint}/isUserQdsmAdmin?userId=${this.user.id}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
        .pipe(
            tap(),
            catchError(this.handleError)
        )
    }

    getDownloadUrl(file: QdmsFile ) {
       return `${environment.baseUrl}/${this.endpoint}/download/file/${file.fileId}?token=${localStorage.getItem("token")}&sessionId=${localStorage.getItem("sessionId")}` 

    }

    searchFileByName(name: string): Observable<QdmsFile[]> {
        return this.httpClient.get<QdmsFile[]>(`${environment.baseUrl}/${this.endpoint}/searchFileByName?name=${name}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          )
    }

    getUserGroups(): Observable<SimpleEntity[]> {
        return this.httpClient.get<SimpleEntity[]>(`${environment.baseUrl}/${this.endpoint}/userGroups`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          )
    }

    getUsersByGroups(groupIds): Observable<SimpleEntity[]> {
        return this.httpClient.get<SimpleEntity[]>(`${environment.baseUrl}/${this.endpoint}/getUsersByGroups?groupIds=${groupIds.join()}`, { withCredentials: false, headers: this.httpHeaders(), params: this.queryParam })
          .pipe(
            tap(),
            catchError(this.handleError)
          )
    }

}
