import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { ExpensePackageCategory } from 'src/app/models/expense.model';
import { ExpenseService } from 'src/app/services/expense.service';
import { IssueManagementService } from 'src/app/services/issue-management.service';
import { FileDialogConfirmComponent } from '../../document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { CreateExpensePackageCategoryComponent } from '../../expense/modals/create-expense-package-category/create-expense-package-category.component';
import { Board } from '../models/board.model';

@Component({
  selector: 'app-boards',
  templateUrl: './boards.component.html',
  styleUrls: ['./boards.component.scss']
})
export class BoardsComponent implements OnInit {

  boards: Board[] = [];

  selectedCategories: ExpensePackageCategory[] = [];

  dataSource = new MatTableDataSource<Board>();

  displayedColumns = ['name', 'actions'];

  constructor(private expenseService: ExpenseService, private issueManagementService: IssueManagementService,
    private messageService: MessageService, public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.getAllBoards();

  }

  getAllBoards() {
    this.issueManagementService.getAllBoards().subscribe(res => {
      this.boards = res;
      this.dataSource.data = this.boards;
    })
  }

  deleteCategory(advance: ExpensePackageCategory) {


  }


  openCreateCategory(expenseItem?: any) {
    let dialogRef = this.dialog.open(CreateExpensePackageCategoryComponent, {
      width: '1200px',
      data: {
        expenseItem: expenseItem,
      }
    });
    dialogRef.afterClosed().subscribe(resObject => {

      this.getAllBoards();



    });
  }

  openConfirmDialog(category: ExpensePackageCategory): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = category.name;
    dialogRef.componentInstance.fIndex = category.id;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

        this.expenseService.deleteExpensePackageCategory(category.id).subscribe(res => {

          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: "Kategori Silindi" });

          this.getAllBoards();
        })



      }
    });
  }

}
