import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CompanyService} from "../../../services/company.service";
import {Company} from "../../../models/company.model";
import {ActivityService} from "../../../services/activity.service";
import {RegionService} from "../../../services/region.service";
import {MobileActivity} from "../../../models/activity/mobile-activity";
import {StepRepresentative} from "../../../models/activity/step-representative";
import {MessageService} from "primeng/api";
import {ActivityOperation} from "../../../models/activity/activity-operation";
import {OfferExportService} from "../../../services/offer-export.service";
import {CityMinimizedModel} from "../../../models/city-minimized.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationService} from "primeng/api";
import {Representative} from "../../../models/activity/representative";
import {Person} from "../../../models/activity/person";
import {DatePipe} from '@angular/common';
import {UserService} from "../../../services/user.service";
import {DistrictMinimizedModel} from "../../../models/district-minimized.model";
import {CreateCustomerComponent} from "../../../shared/create-customer/create-customer.component";
import { MatDialog } from '@angular/material/dialog';
import {CompanyMobileDTO} from "../../../models/company-mobile-dto.model";
import {ActivityDTOModel} from "../../../models/activity-dto.model";
import {AirMinimizedModel} from "../../../models/air-minimized.model";
import {SeaportMinimizedModel} from "../../../models/SeaportMinimized.model";
import { SimpleEntity } from 'src/app/models/simple-entity.model';
import {SelectboxService} from '../../../services/selectbox.service';
import {SelectBoxItem} from '../../../models/select-box-item.model';

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateActivityComponent implements OnInit {

  @Input() activityId: number;
  @Input() transportDirection: string;

  public userTransportDirection = null;
  public mobileActivity: MobileActivity = new MobileActivity();
  public alLActivityOperations: ActivityOperation[] = [];
  public stepRepresentatives: StepRepresentative = new StepRepresentative()
  public company: CompanyMobileDTO = new CompanyMobileDTO();

  userId = JSON.parse(localStorage.getItem("user")).id;

  allCustomerRepresentativeList: Representative[] = [];
  allContactList: Person[] = [];

    filteredAirport: AirMinimizedModel[] = [];
    filteredSeaport: SeaportMinimizedModel[] = [];

  isLoading: boolean = false;
  showAddressDialog: boolean = false;
  filteredCompanies: Company[] = [];
  filteredCityMinimized: CityMinimizedModel[] = [];
  filteredDistrictMinimized: DistrictMinimizedModel[] = [];
  filteredRegions: any[] = [];
  contactPerson: string;
  activitiesByCompany: ActivityDTOModel[] = [];
  lastContactDateCompany: Date = null;
  checkedContactDate: boolean = false;
  salesRepresentatives;
  isVerify: boolean = false;
  statuses: SelectBoxItem[] = [];

  minDate: Date = new Date();
  maxDate: Date = new Date();
  // Todo selectBoxes dan alınacak.
  contactChannels = [{name: 'Seçiniz', code: ''}, {name: 'Telefon', code: 'Telefon'}, {name: 'Ofis', code: 'Ofis'}, {name: 'Mail', code: 'Mail'}, {name: 'Ziyaret', code: 'Ziyaret'}, {name: 'Yurtdışı Fuar', code: 'Yurtdışı Fuar'}, {name: 'Yurtiçi Fuar', code: 'Yurtiçi Fuar'}, {name: 'Zoom', code: 'Zoom'}];
  startingTypes = [{name: 'Seçiniz', code: ''}, {name: 'Kalite', code: 'Kalite'}, {name: 'Finans', code: 'Finans'}, {name: 'Satış ve Pazarlama', code: 'Satış ve Pazarlama'}, {name: 'Network', code: 'Network'},]
  status = [{name: 'Seçiniz', code: ''}, {name: 'Yapıldı', code: 'Yapıldı'}, {name: 'Cevap Bekleniyor', code: 'Cevap Bekleniyor'}, {name: 'İptal Edildi', code: 'İptal Edildi'}, {name: 'Planlanan', code: 'Planlanan'}, {name: 'Hedef Müşteri', code: 'Hedef Müşteri'}]
  operationTypes = [{name: 'Seçiniz', code: ''}, {name:'Denizyolu Taşımacılığı', code: 'SEA'}, {name:'Havayolu Taşımacılığı', code: 'AIR'}, {name:'Yurtiçi', code: 'INLAND'}, {name:'Karayolu Taşımacılığı', code: 'TRUCK'}, {name:'Muhasebe', code: 'ACCOUNTING'},]
  transportDirections: any[] = [{name: 'Seçiniz', code: ''}, {"code":"ORTAK","name":"Genel"},{"code":"T","name":"Transshipment"},{"code":"I","name":"İthalat"},{"code":"E","name":"İhracat"},{"code":"D","name":"Yurtiçi"}];subTypes = [{name: 'Seçiniz', code: ''}, {name: 'Şirket Tanıtımı', code: 'companyIntroduction'}, {name: 'Teklif', code: 'offer'}, {name: 'BI', code: 'BI'}, {name: 'Randevu', code: 'appointment'}]
  frequances = [{name: 'Seçiniz', code: ''}, {name: 'Yıllık', code: 'yearly'}, {name: 'Aylık', code: 'monthly'}, {name: 'Haftalık', code: 'weekly'}]
  companyStatus = [{name: 'Aktif', code: 'A'}, {name: 'Pasif', code: 'P'}, {name: 'L', code: 'Potansiyel'}, {name: 'Blacklist', code: 'B'}, {name: 'Yeni', code: 'N'}, {name: 'Beklemede', code: 'W'},]

  units = [{name: 'Teu', code: 'teu'}, {name: 'Tonaj', code: 'tonnage'}, {name: 'Araç', code: 'car'}]

  constructor(private companyService: CompanyService,
              private activityService: ActivityService,
              private offerExportService: OfferExportService,
              private regionService: RegionService,
              private confirmService: ConfirmationService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private router: Router,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private messageService: MessageService,
              private selectBoxItemService: SelectboxService) {

    this.userTransportDirection = this.activatedRoute.snapshot.params.transportDirection;
    if(!this.mobileActivity.referenceNo) this.mobileActivity.startingType = 'Satış ve Pazarlama';

    /*
      TODO: Adres kartına taşıma yönü eklenebilir. şimdilik ismine bakarak varsayılan taşıma yönüne belirlenmiştir.
    * */
    const user = userService.getUserFromLocalStorage();
    if(!user.company) {
      this.messageService.add({key: 'tst', severity:'warn', detail: 'Kullanıcı tanımına ait adres kartı bilgisi bulunamadı!' });
    } else {
        if (!this.activatedRoute.snapshot.params.id && !this.activityId){
            this.userTransportDirection = user.company.name.includes('KTL') ? 'I' : 'E';
        }
      if(this.userTransportDirection == 'I') {
        if(localStorage.getItem('userimport'))  this.userId = JSON.parse(localStorage.getItem("userimport")).id;
      }
    }

    this.salesRepresentatives = {"I": [],"E": []}

  }

    async ngOnInit() {
        this.userTransportDirection = this.transportDirection ? this.transportDirection : this.userTransportDirection
        this.minDate.setDate(this.minDate.getDate() - 7);

        this.statuses = await this.selectBoxItemService.getItemByBoxName('activityOperationStatus', this.userTransportDirection).toPromise();

        console.log('test')
        if (this.activatedRoute.snapshot.params.id || this.activityId) {
            await this.getActivity();
            this.company = await this.companyService.getCompany(this.mobileActivity.company.id, this.userTransportDirection).toPromise();
            await this.getActiviiesByCompanyAndCheckDates();
        } else {
            this.mobileActivity.contactDate = new Date();
            this.isVerify = true;
        }

        if (this.company?.id) {
            this.onChangeCustomer();
        }

    }

  async getActivity(id = this.activatedRoute.snapshot.params.id || this.activityId) {

    const activity =  await this.activityService.getActivityById(id, this.userTransportDirection).toPromise();

    activity.activityDate = activity.activityDate ? new Date(activity.activityDate) : null;
    activity.contactDate = activity.contactDate ? new Date(activity.contactDate) : null;

    this.alLActivityOperations = await this.getAllActivityOperations(activity);
    if(this.alLActivityOperations) this.alLActivityOperations = this.alLActivityOperations.filter(f => f.activity.id != this.activatedRoute.snapshot.params.id);

    this.mobileActivity = activity;
    if(activity.contactDate) {
      activity.activityDate =  new Date(this.datePipe.transform(activity.activityDate, 'yyyy.MM.dd'));
    }
    if(activity.activityDate) {
      activity.contactDate =  new Date(this.datePipe.transform(activity.contactDate, 'yyyy.MM.dd'));
    }
      this.mobileActivity = activity;


    if(!activity.activityOperationList) activity.activityOperationList = [];
    if(!activity.contactList) activity.contactList = [];
    if(!activity.customerRepresentativeList) activity.customerRepresentativeList = [];

    const stepRepresentative = await this.activityService.getActivityAddStepRepresentative(this.mobileActivity.company.id, this.userTransportDirection).toPromise();
    this.allContactList = stepRepresentative.contactList;
    this.allCustomerRepresentativeList = stepRepresentative.customerRepresentativeList;

    const userIdList = [];

    if(this.mobileActivity.customerRepresentativeList) {
      this.mobileActivity.customerRepresentativeList.forEach(f => f.isExists = true);
      this.stepRepresentatives.customerRepresentativeList =  this.mobileActivity.customerRepresentativeList || [];
      this.mobileActivity.customerRepresentativeList.forEach(c => userIdList.push(c.userId));
    }

    userIdList.push(this.mobileActivity?.operator?.id);

    if(this.mobileActivity.contactList) {
      this.mobileActivity.contactList.forEach(f => f.isExists = true);
      this.stepRepresentatives.contactList =  this.mobileActivity.contactList || [];
    }
    this.isVerify = userIdList.includes(this.userId);

  }

  isVerifyToCreate(){
      if (!this.isVerify){
          this.messageService.add({key: 'tst', severity:'warn', detail: 'Güncelleme yetkiniz bulunmamaktadır' });
          return false;
      }else{
          return true;
      }
  }

  async getAllActivityOperations(activity: MobileActivity) {
    return await this.activityService.getActivityOperationsByCompanyId(activity.company.id, this.userTransportDirection).toPromise();
  }
  addCustomerRepresentative(event) {
    if(!this.mobileActivity.referenceNo) return;
    const isUnselected = event.value.find(f => f.id == event.itemValue.id);
    if(isUnselected) {
      event.itemValue.isExists = false;
     this.mobileActivity.customerRepresentativeList.push(event.itemValue);
    }
    this.save();

  }
  addContact(event) {
    if(!this.mobileActivity.referenceNo) return;
    const isUnselected = event.value.find(f => f.id == event.itemValue.id);
    if(isUnselected) {
      event.itemValue.isExists = false;
      this.mobileActivity.contactList.push(event.itemValue);
    }
    this.save();
  }

  customerRepresentativeChange(event) {

    if(!this.mobileActivity.referenceNo) return;
    const isUnselected = event.value.find(f => f.id == event.itemValue.id);
    if(!isUnselected) {
      this.deleteCustomerRepresentative(event.itemValue)
      this.getActivity();
    }
  }

  contactChange(event) {
    if(!this.mobileActivity.referenceNo) return;
    const isUnselected = event.value.find(f => f.id == event.itemValue.id);
    if(!isUnselected) {
      this.deleteContact(event.itemValue)
    }
  }

  createContactPerson() {
    if(!this.contactPerson || this.contactPerson == "") return;

      if (!this.isVerifyToCreate()) {
          return;
      }

    this.isLoading = true;
    const p = new Person();
    p.name = this.contactPerson;
    p.companyId = this.mobileActivity.company.id;
    this.activityService.createActivityPerson(p, this.userTransportDirection).subscribe(r => {
      r.isExists = false;
      this.stepRepresentatives.contactList.push(r);
      this.allContactList.push(r)
      this.contactPerson = "";
      this.messageService.add({key: 'tst', severity:'success', detail: 'Kaydedildi.' });
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    })


  }

  save = () => {
    if (!this.isVerifyToCreate()) {
        return;
    }
    if(this.mobileActivity.address.district) this.mobileActivity.address.district = this.mobileActivity.address.district['id']
    if(this.mobileActivity.address.city) this.mobileActivity.address.city = this.mobileActivity.address.city['id']


    this.isLoading = true;
    const isNew = this.mobileActivity.id ? false : true;
    if(this.mobileActivity.activityDate)  this.mobileActivity.activityDate.setHours(12);
    if(this.mobileActivity.contactDate)   this.mobileActivity.contactDate = new Date(this.mobileActivity.contactDate.getTime() + Math.abs(this.mobileActivity.contactDate.getTimezoneOffset() * 60000));

      this.activityService.saveActivityByCompanyId(this.mobileActivity, this.userTransportDirection).subscribe((r) => {
      this.getActivity(r.id)

      this.messageService.add({key: 'tst', severity:'success', detail: 'Kaydedildi.' });
      if(isNew) this.router.navigate([`/activity/${this.userTransportDirection}/${r.id}`]);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    })

  }

  async getLastActivityByCompany() {
    const params = {
          'status': 'done',
          'company.id/LNG': this.company.id,
    }
    return await this.activityService.search(params, this.userId, this.userTransportDirection, true).toPromise();
  }

  deleteCustomerRepresentative(item) {
      if (!this.isVerifyToCreate()) {
          return;
      }
    this.confirmService.confirm({
      message: `<b>${item.name}</b> silmek istediğinize emin misiniz ?`,
      accept: () => {
        this.activityService.deleteActivityRepresentativeById(item.id, this.userTransportDirection).subscribe(r => {
          this.messageService.add({key: 'tst', severity:'success', detail: 'Kayıt silindi.' });
          this.getActivity();
        })
      },
      reject: () => {
        this.mobileActivity.customerRepresentativeList.push(item);
        this.getActivity();
      }
    });
  }
  deleteContact(item) {
      if (!this.isVerifyToCreate()) {
          return;
      }
    this.confirmService.confirm({
      message: `<b>${item.name}</b> silmek istediğinize emin misiniz ?`,
      accept: () => {
        this.activityService.deleteActivityPersonById(this.mobileActivity.id, item.id, this.userTransportDirection).subscribe(r => {
          this.messageService.add({key: 'tst', severity:'success', detail: 'Kayıt silindi.' });
          this.getActivity();
        })
      },
      reject: () => {
        this.mobileActivity.contactList.push(item);
      }
    });
  }

  addActivityOperation = () => {
    this.mobileActivity.activityOperationList.push(new ActivityOperation());
  }
  deleteActivityOperation = (index) => {
      if (!this.isVerifyToCreate()) {
          return;
      }
    if(index < 0) return;
    const activityOperation = this.mobileActivity.activityOperationList[index]
    if(!activityOperation.id) {
      this.mobileActivity.activityOperationList.splice(index, 1);
      this.messageService.add({key: 'tst', severity:'success', detail: 'Kayıt silindi.' });
      return;
    }
    this.activityService.deleteActivityOperation(activityOperation.id, this.userTransportDirection).subscribe(() => {
      this.mobileActivity.activityOperationList.splice(index, 1);
      this.messageService.add({key: 'tst', severity:'success', detail: 'Kayıt silindi.' });
    })


  }
  onChangeCustomer =  async () => {
      this.alLActivityOperations = [];
      if (!this.mobileActivity.company) return;


      if(!this.mobileActivity.id) {
        this.stepRepresentatives = await this.activityService.getActivityAddStepRepresentative(this.mobileActivity.company?.id, this.userTransportDirection).toPromise();
        this.stepRepresentatives.customerRepresentativeList.forEach(f => f.isExists = false);
        this.stepRepresentatives.contactList.forEach(f => f.isExists = false);
      }


      this.alLActivityOperations = await this.getAllActivityOperations(this.mobileActivity);

      if (this.mobileActivity.company) {
          this.company = await this.companyService.getCompany(this.mobileActivity.company.id, this.userTransportDirection).toPromise();

          // Satış temsilcisinin varsayılan seçimi.
          this.salesRepresentatives['I'] = await this.activityService.getCustomerRepresentativeByRegisterNo(this.company.registerNo, 'I').toPromise();
          this.salesRepresentatives['E'] = await this.activityService.getCustomerRepresentativeByRegisterNo(this.company.registerNo, 'E').toPromise();

          if (!this.mobileActivity.address.cityDTO) this.mobileActivity.address.cityDTO = this.company.city;
          if (!this.mobileActivity.address.districtDTO) this.mobileActivity.address.districtDTO = this.company.district;
          if (!this.mobileActivity.address.addressDetail) this.mobileActivity.address.addressDetail = this.company.address;

      }




    this.getActiviiesByCompanyAndCheckDates();
  }


  filterCompany(event) {
    if (event.query.lenght < 3) return;

    this.companyService.getAll(event.query, this.userTransportDirection).subscribe(res => {
      this.filteredCompanies = res;
    });
  }
  filterCity(event) {
    if (event.query.length > 2) {
      this.offerExportService.getOfferExportCity(event.query, null, this.userTransportDirection).subscribe(res => {
        this.filteredCityMinimized = res.body;
      });
    }
  }
  filterDistrict(event, cityId?: any) {
    if (event?.query?.length > 2) {
      this.offerExportService.getOfferExportDistrict(event.query, cityId, this.userTransportDirection).subscribe(res => {
        this.filteredDistrictMinimized = res.body;
      });
    }
  }
  filterRegion(event) {
    this.regionService.search({ "name/SW": event.query, "userRegion/BLN": "false" }, this.userTransportDirection).subscribe(res => this.filteredRegions = res);
  }

  showCreateCustomerDialog() {
      if (!this.isVerifyToCreate()) {
          return;
      }
    let dialogRef = this.dialog.open(CreateCustomerComponent, {
      panelClass: 'modal-md',
      width: '1000px',
      height: 'auto',
      data: {}
    });
  }

  checkContactDate(): boolean {
    if(this.lastContactDateCompany) {
      const now = new Date();
      now.setMonth(now.getMonth() -6);
      return this.lastContactDateCompany < now;
    }
    return false;
  }

  async getActiviiesByCompanyAndCheckDates() {
    this.activitiesByCompany = await this.getLastActivityByCompany();
    if(this.activitiesByCompany) {
      // @ts-ignore
      this.activitiesByCompany.sort((a, b) => new Date(a.contactDate) - new Date(b.contactDate));
      if(this.activitiesByCompany[this.activitiesByCompany.length - 1]?.contactDate) {
        this.lastContactDateCompany = new Date(this.activitiesByCompany[this.activitiesByCompany.length -1].contactDate);
        this.checkedContactDate = this.checkContactDate()
      }

    }
  }

    airPortSearch(event) {
        if (event.query.length > 1) {
            this.offerExportService.airportSearch(event.query, this.userTransportDirection).subscribe(res => {
                this.filteredAirport = res.body;
            });
        }
    }

    filterSeaport(event) {
        this.offerExportService.getOfferExportSeaport(event.query, null, this.userTransportDirection).subscribe(res => {
            this.filteredSeaport = res.body;
        });
    }

    goTocreateNewActivity(){
        window.location.href = `/create-activity`;
    }
}
