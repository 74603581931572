import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {OfferExportService} from "../../../../services/offer-export.service";
import {SeaportMinimizedModel} from "../../../../models/SeaportMinimized.model";
import {AirMinimizedModel} from "../../../../models/air-minimized.model";
import {CityMinimizedModel} from "../../../../models/city-minimized.model";
import {CompanyMinimizedModel} from "../../../../models/company-minimized.model";
import {MessageService} from "primeng/api";
import {DatePipe} from "@angular/common";
import { AirTariffDetMobileModel } from 'src/app/models/air-tariff-det-mobile.model';

@Component({
    selector: 'app-air-offer',
    templateUrl: './air-offer.component.html',
    styleUrls: ['./air-offer.component.scss']
})
export class AirOfferComponent implements OnInit {

    editForm: UntypedFormGroup;
    isLoading: boolean;

    filteredAirport: AirMinimizedModel[] = [];
    airport: AirMinimizedModel[] = [];

    filteredCityMinimized: CityMinimizedModel[] = [];
    city: CityMinimizedModel[] = [];

    filteredAirlineMinimized: CompanyMinimizedModel[] = [];
    airline: CompanyMinimizedModel[] = [];

    viaportType: any[] = [{json: 'true', name: 'Aktarmalı'}, {json: 'false', name: 'Direkt'}];
    viaport: any[] = [{json: 'true', name: 'Aktarmalı'}, {json: 'false', name: 'Direkt'}];

    offerOrTariff: any[] = [{json: 'tariff', name: 'Tarife'}, {json: 'offer', name: 'Teklif'}];
    offerOrTariffType: any[] = [{json: 'tariff', name: 'Tarife'}, {json: 'offer', name: 'Teklif'}];

    airFrightTariffs: any[];

    tariffMobileDets: AirTariffDetMobileModel[] = [];
    tariffDetInvoiceItemNames: string[] = [];

    stateOptions: any[];
    state = 'tariff';
    back = false;
    selectedContainer: any = [];
    containersChosen: boolean;

    constructor(private formBuilder: UntypedFormBuilder, private offerExportService: OfferExportService, private messageService: MessageService,
                public datepipe: DatePipe) {
    }

    ngOnInit(): void {
        this.editForm = this.formBuilder.group({
            toPort: ['', [Validators.required]],
            fromPort: ['', []],
            toCity: ['', []],
            fromCity: ['', []],
            airline: ['', []],
            isViaport: ['', []],
            offerOrTariff: [{json: 'tariff', name: 'Tarife'}, []],
            endDate: ['', [Validators.required]],
        });
    }

    airPortSearch(event) {
        if (event.query.length > 1) {
            this.offerExportService.airportSearch(event.query).subscribe(res => {
                this.filteredAirport = res.body;
                this.airport = res.body;
            });
        }
    }

    airlineSearch(event) {
        if (event.query.length > 1) {
            this.offerExportService.airlineSearch(event.query).subscribe(res => {
                this.filteredAirlineMinimized = res.body;
                this.airline = res.body;
                const filtered: CompanyMinimizedModel[] = [];
                const query = event.query;
                this.airline.forEach(country => {
                    if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                        filtered.push(country);
                    }
                });
                this.filteredAirlineMinimized = filtered;
            });
        }
    }

    filterViaport(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.viaport.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.viaportType = filtered;
    }

    filterTariffOrOffer(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.offerOrTariff.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.offerOrTariffType = filtered;
    }

    filterCity(event, countryId?: any) {
        if (event.query.length > 2) {
            this.offerExportService.getOfferExportCity(event.query, countryId?.id).subscribe(res => {
                this.filteredCityMinimized = res.body;
                this.city = res.body;
                const filtered: any[] = [];
                const query = event.query;
                this.city.forEach(country => {
                    if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                        filtered.push(country);
                    }
                });

                this.filteredCityMinimized = filtered;
            });
        }
    }

    onFocusOutCheckInputIsEmpty(name: string) {
        if (this.editForm?.get([name])?.value?.name === undefined) {
            this.editForm?.controls[name]?.setValue('');
        }
    }

    sendRequest() {
        this.checkValidation();
        this.airFrightTariffs = [];
        this.stateOptions = [];
        if (this.editForm?.get(['offerOrTariff'])?.value?.json === 'offer') {
            this.stateOptions = [
                {label: 'offer', value: 'offer'}
            ];
        } else if (this.editForm?.get(['offerOrTariff'])?.value?.json === 'tariff') {
            this.stateOptions = [
                {label: 'tariff', value: 'tariff'}
            ];
        } else {
            this.stateOptions = [
                {label: 'tariff', value: 'tariff'},
                {label: 'offer', value: 'offer'}
            ];
        }
        this.back = false;
        if ((this.editForm?.get(['toPort'])?.value !== '' && this.editForm?.get(['endDate'])?.value !== '')) {
            this.isLoading = true;
            this.offerExportService.getAirFrightBuyTariffDetMobile(
                (this.editForm?.get(['fromPort'])?.value?.id === undefined ? '' : this.editForm?.get(['fromPort'])?.value?.id),
                (this.editForm?.get(['toPort'])?.value?.id === undefined ? '' : this.editForm?.get(['toPort'])?.value?.id),
                (this.editForm?.get(['fromCity'])?.value?.id === undefined ? '' : this.editForm?.get(['fromCity'])?.value?.id),
                (this.editForm?.get(['toCity'])?.value?.id === undefined ? '' : this.editForm?.get(['toCity'])?.value?.id),
                (this.editForm?.get(['airline'])?.value?.id === undefined ? '' : this.editForm?.get(['airline'])?.value?.id),
                this.datepipe.transform(this.editForm?.get(['endDate'])?.value, 'yyyy-MM-dd'),
                (this.editForm?.get(['isViaport'])?.value?.id === undefined ? '' : this.editForm?.get(['isViaport'])?.value?.id),
                (this.editForm?.get(['offerOrTariff'])?.value?.name === undefined ? '' : this.editForm?.get(['offerOrTariff'])?.value?.json),
                20,
                0
            ).subscribe(res => {
                this.isLoading = false;
                if (!res.body?.length) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'warn',
                        summary: 'Tarife bulunamadı.',
                        detail: 'Hiçbir veri bulunamadı.'
                    });
                }
                this.airFrightTariffs = res.body;

                this.airFrightTariffs.map(e => e.airTariffDetMobiles).filter(f => f).forEach(d => this.tariffMobileDets = this.tariffMobileDets.concat(d));
                const unqiueDetail = [...new Map(this.tariffMobileDets.map(item => [item['id'], item])).values()] as AirTariffDetMobileModel[];
                this.tariffDetInvoiceItemNames = unqiueDetail.map(q => q.invoiceItemType.name);

            }, err => {
                this.isLoading = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: 'Hata Oluştu',
                    detail: 'İhracaat talebi bulunamadı...'
                });
            });
        } else {
            this.messageService.add({
                key: 'tst',
                severity: 'warn',
                summary: 'Hata Oluştu',
                detail: '(Kalkış Havalimanı || Şehir) ve ya (Varış Havalimanı || Şehir) en az biri ve ETD tarihi ile arama yapınız!'
            });
        }
    }

    getInvoiceItemAmount(airFrightTariffs: AirTariffDetMobileModel[], invoiceItemName) {
        const invoiceItemAmounts = [];
        if (airFrightTariffs && airFrightTariffs.length > 0 && invoiceItemName) {
            airFrightTariffs.filter(q => q.invoiceItemType.name === invoiceItemName).forEach(i => {
                invoiceItemAmounts.push(`${i.buyAmount} ${i.currency.name}`);
            });
        }
        return invoiceItemAmounts.join(' ,');
    }

    checkSelected(project: any) {
        if (this.selectedContainer?.length > 0) {
            const selectedProduct = this.selectedContainer.find(f => f.id === project.id);
            if (selectedProduct !== undefined) {
                return true;
            }
        }
        return false;
    }

    chosen(p: any) {
        const isExitsProduct = this.selectedContainer.find(q => q.id === p.id);
        if (isExitsProduct) {
            const rowIndex = this.selectedContainer.findIndex(q => q.id === p.id);
            this.selectedContainer.splice(rowIndex, 1);
        } else {
            Object.assign(p, {selected: true});
            this.selectedContainer.push(p);
        }
    }

    changeComponent() {
        this.containersChosen = true;
        const scrollToPayment = window.setTimeout(() => {
            // this.scrollToElement("beginning");
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 4);
    }

    onPreviousChange(event) {
        this.containersChosen = event;
        this.selectedContainer = [];
        this.sendRequest();
    }

    checkValidation() {
        for(let item in this.editForm.controls){
            if (this.editForm?.controls[item]?.status === 'INVALID') {
                this.editForm?.get(item)?.markAsDirty();
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + item.toUpperCase() + ' alan doldurulmalı!'});
            }
        }
    }
}
