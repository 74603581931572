import { BaseService } from './base.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Currency } from "../models/currency.model";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class CurrencyConversionService extends BaseService<any> {
    constructor(public httpClient: HttpClient) {
        super(
            'currency',
            httpClient);
    }

    requiredCurrencies = ['USD', 'EUR', 'GBP'];

    getWeeklyCurrencyConversion(): Observable<HttpResponse<Currency[]>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/api/' + this.endpoint + '/getWeeklyCurrency';

        const today = new Date();

        const convertLocalDate = today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? ('0' + (today.getMonth() + 1)) : (today.getMonth() + 1)) + '-' + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate());

        const options = this.createRequestOption({ token: token, requiredCurrencies: this.requiredCurrencies, sessionId: sessionId, fromDate: convertLocalDate, toDate: convertLocalDate, bankName: 'CENTRAL_BANK' });

        return this.httpClient.get<Currency[]>(url, { params: options, observe: 'response' });
    }

    getAllCurrencies(): Observable<HttpResponse<Currency[]>> {
        const token = localStorage.getItem('token');

        const sessionId = localStorage.getItem('sessionId');

        const url = environment.baseUrl + '/api/' + this.endpoint + '/getAllCurrencies';

        const options = this.createRequestOption({ token: token, sessionId: sessionId });

        return this.httpClient.get<Currency[]>(url, { params: options, observe: 'response' });
    }
}
