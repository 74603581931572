import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Survey } from 'src/app/models/survey-models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';
import {CompanyService} from "../../../services/company.service";
import {CompanyMinimizedModel} from "../../../models/company-minimized.model";
import {QuestionAnswer} from "../../../models/survey-models/question-answer";

@Component({
  selector: 'app-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.scss']
})
export class SurveyReportComponent implements OnInit {

  survey: Survey = <Survey>{};
  companiesInSurvey: CompanyMinimizedModel[] = [];
  selectedCompany: CompanyMinimizedModel = null;

  isLoading = false;

  constructor(private surveyService: SurveyService, private companyService: CompanyService, private route: ActivatedRoute, private messageService: MessageService) {

    this.route.params.subscribe(res => {
      var id = res.id;

      this.surveyService.getReport(id).subscribe(res => {
        console.log(res);
        this.survey = res;
        this.searchCompanyByIds(this.getCompanyIdInSurvey()).subscribe(r =>  this.companiesInSurvey = r)
      })

    });

  }

  ngOnInit(): void { }

  searchCompanyByIds(id:number[]){
   return this.companyService.search({'id/LNG/IN"':`[${id.toString()}]`}, null, true)
  }
  getCompanyIdInSurvey(){
    let companyId = [];
    this.survey.questions.map(f => f.questionAnswers ).forEach(questionAnswer => {
      questionAnswer.map(r => r.questionResponses).forEach(qr => {
        companyId = companyId.concat(qr.map(q => q.companyId));
      })
    })
    return [...new Set(companyId)];
  }

  getQuestionAnswersByCompanyId(questionResponses: QuestionAnswer[]) {
   return questionResponses.find(f => f.companyId == this.selectedCompany?.id);
  }

}
