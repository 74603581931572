import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IntranetAdvance } from 'src/app/models/intranet-advance.model';
import { IntranetAdvanceService } from 'src/app/services/intranet-advance.service';

@Component({
  selector: 'app-get-my-advances',
  templateUrl: './get-my-advances.component.html',
  styleUrls: ['./get-my-advances.component.scss']
})
export class GetMyAdvancesComponent implements OnInit {

  advances: IntranetAdvance[] = [];

  selectedAdvances: IntranetAdvance[] = [];

  constructor(private advanceService: IntranetAdvanceService, private messageService: MessageService) { }

  ngOnInit(): void {

    this.getMyAdvances();

  }

  getMyAdvances() {
    this.advanceService.getMyAdvances().subscribe(res => {
      this.advances = res;
    })
  }

  cancelAdvance(advance: IntranetAdvance) {

    this.advanceService.get(advance.id).subscribe(res => {

      var realAdvance = res;
      realAdvance.status = 3;

      this.advanceService.update(realAdvance).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Uyarı', detail: 'Talep İptal Edildi' });
        this.getMyAdvances();
      })

    })



  }

}
