export class Person {
    id: number;
    name: string;
    task: string;
    tel: string;
    fax: string;
    gsm: string;
    email: string;
    notes: string;
    status: string;
    gender: string;
    title: string;
    isauthorized: boolean;
    isdefault: boolean;
    birthDay: Date;
}
