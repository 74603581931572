import {Company} from './company';
import {Address} from './address'
import {Representative} from "./representative";
import {Person} from "./person";
import {ActivityOperation} from "./activity-operation";
import {SimpleEntity} from '../simple-entity.model';
export class MobileActivity {
  id: number;
  referenceNo: string;
  company: Company
  contactType: string;
  startingType: string;
  subType: string;
  status: string;
  activityDate: Date;
  contactDate: Date;
  durationByMin: number;
  customerRepresentativeList: Representative[];
  contactList: Person[];
  address: Address = new Address();
  meetingDetail: string;
  activityOperationList: ActivityOperation[] = [];
  resultNote: string;
  result: string;
  subjectTitle: string;
  subject: string;
  operator: SimpleEntity;
}
