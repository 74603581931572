import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AllocationDetailComponent } from '../allocation-detail/allocation-detail.component';

import { Allocation } from 'src/app/models/allocation.model';
import { SeaportMinimizedModel } from 'src/app/models/SeaportMinimized.model';
import { Company } from 'src/app/models/company.model';

import { CompanyService } from 'src/app/services/company.service';
import { OfferExportService } from 'src/app/services/offer-export.service';
import { AllocationService } from 'src/app/services/allocation.service';


@Component({
    selector: 'app-list-allocation',
    templateUrl: './list-allocation.component.html',
    styleUrls: ['./list-allocation.component.scss']
})
export class ListAllocationComponent implements OnInit {

  @ViewChild('dt') table: Table;

  allocationList: Allocation[] = [];
  selectedAllocations: Allocation[];
  searchForm: UntypedFormGroup;
  seaports: SeaportMinimizedModel[] = [];
  filteredSeaports: SeaportMinimizedModel[] = [];
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  loading = false;

  constructor(private dialog: MatDialog, private messageService: MessageService, private allocationService: AllocationService, private formBuilder: UntypedFormBuilder, private route: Router,
    private offerExportService: OfferExportService,private companyService: CompanyService) {

    this.searchForm = this.formBuilder.group({
      pod: ['', []],
      pol: ['', []],
      tonnage: ['', []],
      etdStart: [new Date(), [Validators.required]],
      etdEnd: ['', [Validators.required]],
      lineer: ['', []]
    })

  }

  ngOnInit() {  }

  onSearch() {

    if(!this.searchForm.valid) {
      this.messageService.add({ key: 'tst', summary: 'Uyarı', detail: 'EDT başlangıç ve ETD bitiş tarihi belirtilmelidir.' });
      return;
    }
    if((!this.searchForm.value.pod && !this.searchForm.value.pol)) {
      this.messageService.add({ key: 'tst', summary: 'Uyarı', detail: 'POL veya POD seçilmelidir.' });
      return;
    }

    this.loading = true;
    this.allocationService.findSeaAllocation(this.searchForm.value.etdStart, this.searchForm.value.etdEnd, this.searchForm.value?.pol?.id, this.searchForm.value?.pod?.id, this.searchForm.value?.lineer?.id ).subscribe(res => {
      this.loading = false;
      this.allocationList =  [...new Map(res.map(item => [item["id"], item])).values()];
    })
  }

  onDetail(allocation: Allocation) {
    this.dialog.open(AllocationDetailComponent, {
      width: '100%',
      minHeight: '400px',
      autoFocus: false,
      data: {allocation: allocation, search: this.searchForm.value}
    });
  }

  filterSeaport(event, where) {
    this.offerExportService.getOfferExportSeaport(event.query, where).subscribe(res => {
      this.filteredSeaports = res.body;
    });
  }

  filterCompany(event) {
    if (event.query.lenght < 3) return;
    this.companyService.getAll(event.query).subscribe(res => {
      this.filteredCompanies = res;
    });
  }

  navigateOfferRequest() {
    if(this.selectedAllocations.length > 0) {

      this.route.navigate([`activities/offer-request/allocation`], {state: {allocations: this.selectedAllocations, search: this.searchForm.value}});
    }
  }

  autocompleteFocusOut(value, key) {
    if(!value?.id) this.searchForm.get(key).setValue(null)
  }
}
