export interface ILesson {
    id: number;
    version: any;
    firm: any;
    createDate: any;
    copyId: any;
}


export class CreateLessonCategoryRequest {
    token: string;
    sessionId: string;
    document: IntranetLessonCategory;
}

export class IntranetLessonCategory implements ILesson {
    id: number;
    name: string;
    description: string;

    version: any;
    firm: any;
    createDate: any;
    copyId: any;
}

export class IntranetLesson implements ILesson {
    version: any;
    firm: any;
    createDate: any;
    copyId: any;
    id: number;
    name: string;
    categoryId: number;
    description: string;
    logoUrl?: string;

    questions?: QuizQuestion[];
}

export class CreateLessonRequest {
    token: string;
    sessionId: string;
    document: IntranetLesson;
}

export class IntranetLessonVideo implements ILesson {
    id: number;
    version: any;
    firm: any;
    createDate: any;
    copyId: any;

    lessonId: number;
    videoUrl: string;
    extension: string;
    videoDuration: number;
    groupTag: string;
    name: string;

    videoUserState?: IntranetLessonVideoUserState;
}

export class CreateLessonVideoRequest {
    token: string;
    sessionId: string;
    document: IntranetLessonVideo;
}

export class IntranetLessonVideoUserState implements ILesson {
    version: any;
    firm: any;
    createDate: any;
    copyId: any;

    id: number;
    userId: number;
    videoId: number;
    lessonId: number;
    state: string;
    progressPercentage: number;
    currentTimeData?: number;

}


export class QuizQuestion {

    id: number;
    text: string;

    questionAnswers: QuizQuestionAnswer[];

    selectedAnswer?: QuizQuestionAnswer;

}

export class QuizQuestionAnswer {

    id: number;
    text: string;
    questionId: string;
    isCorrect: boolean;

}

export class CreateLessonQuizRequest {
    token: string;
    sessionId: string;
    lessonId: number;
    quizQuestions: QuizQuestion[];
}

export class QuizUserResult {
    id: number;
    userId: number;
    lessonId: number;

    totalCorrectAnswers: number;
    totalWrongAnwers: number;
    totalEmptyAnswers: number;
    score: number;
}

export class SimpleModel {
    id: number;
    name: string;
}
export class IntranetLessionVideoAssigment {
    id: number;
    user: SimpleModel;
    intranetLessonVideo: SimpleModel;

}
