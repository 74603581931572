import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivityRequestModel } from '../models/activity-request.model';
import { Activity } from '../models/activity.model';
import { Company } from '../models/company.model';
import { SeaPort } from '../models/seaport.model';
import { CompleteSurveyRequest } from '../models/survey-models/complete-survey-request.model';
import { CreateSurveyRequestModel } from '../models/survey-models/create-survey-request.model';
import { Survey } from '../models/survey-models/survey.model';
import { BaseService } from './base.service';
import {SendSurveyRequestModel} from "../models/survey-models/send-survey-request.model";

@Injectable()
export class SurveyService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'survey',
            httpClient);
    }

    createSurvey(createSurveyRequest: CreateSurveyRequestModel): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity: CreateSurveyRequestModel = {
            token: token,
            sessionId: sessionId,
            surveyName: createSurveyRequest.surveyName,
            questions: createSurveyRequest.questions,
            isPublic: createSurveyRequest.isPublic,
            isReportable: createSurveyRequest.isReportable,
            publisherUserId: createSurveyRequest.publisherUserId,
            userGroupIds: createSurveyRequest.userGroupIds
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/createSurvey";

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    completeSurvey(survey: Survey, hash: string = null): Observable<any> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var entity: CompleteSurveyRequest = {
            token: token,
            sessionId: sessionId,
            survey
        }

        var url = environment.baseUrl + "/" + this.endpoint + "/completeSurvey";
        if(hash)  url += `?hash=${hash}`;

        return this.httpClient.post<any>(url, entity, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getSurveyList(): Observable<Survey[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getSurveyList?token=" + token + "&sessionId=" + sessionId;

        return this.httpClient.get<Survey[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getMySurveys(): Observable<Survey[]> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var user = JSON.parse(localStorage.getItem("user"));

        var url = environment.baseUrl + "/" + this.endpoint + "/getSurveyList?token=" + token + "&sessionId=" + sessionId + "&userId=" + user.id;

        return this.httpClient.get<Survey[]>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    get(id: number): Observable<Survey> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/get?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Survey>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    getPublicSurvey(hash: string): Observable<Survey> {
        var url = environment.baseUrl + "/" + this.endpoint + "/publicSurvey?hash=" + hash;

        return this.httpClient.get<Survey>(url, { withCredentials: false, headers: this.httpHeaders() })
          .pipe(
            tap(),
            catchError(this.handleError)
          )
    }

    getReport(id: number): Observable<Survey> {

        var token = localStorage.getItem("token");

        var sessionId = localStorage.getItem("sessionId");

        var url = environment.baseUrl + "/" + this.endpoint + "/getSurveyReport?token=" + token + "&sessionId=" + sessionId + "&id=" + id;

        return this.httpClient.get<Survey>(url, { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    sendSurvey(sendSurveyRequestModel: SendSurveyRequestModel): Observable<any> {
        var url = environment.baseUrl + "/" + this.endpoint + "/sendSurvey";
        return this.httpClient.post<any>(url, sendSurveyRequestModel, { withCredentials: false, headers: this.httpHeaders() })
          .pipe(
            tap(),
            catchError(this.handleError)
          )
    }

}
