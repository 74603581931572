export class OfferPartialActivityMobileGoodPack {
    id: number
    quantity: number;
    grossWeight: number;
    length: number;
    width: number;
    height: number;
    volume: number;
    type: string;
    measureUnit: string;
}
