import { Component, OnInit, Inject } from '@angular/core';
import { Folder } from 'src/app/models/qdms/folder-model';
import { UserService } from 'src/app/services/user.service';
import { QdmsService } from 'src/app/services/qdms.service';
import { MessageService } from 'primeng/api';
import { FolderPermissions } from 'src/app/models/qdms/folder-permissions';
import EntityUtil from 'src/app/models/framework/utils/entity-util';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {SimpleEntity} from "../../../models/simple-entity.model";

@Component({
  selector: 'app-folder-dialog',
  templateUrl: './folder-dialog.component.html',
  styleUrls: ['./folder-dialog.component.scss']
})
export class FolderDialogComponent implements OnInit {

  public folder: Folder;
  public isLoading: Boolean = false;
  public userIsQdmsAdmin: Boolean = false;
  filteredUsers: any[] = [];
  userGroups: SimpleEntity[] = [];
  selectedUserGroups: SimpleEntity[] = [];

  /*
  data: id: number, newFolder: boolean}
  */
  constructor(private userService: UserService,
    private qdmsService: QdmsService, 
    @Inject(MAT_DIALOG_DATA) private data: any,
    private messageService: MessageService) {

      this.folder = new Folder();

      if(this.data) {
        this.isLoading = true;
        this.qdmsService.getFolder(this.data.id).subscribe(r => {
          this.isLoading = false;
          // get folder permissions
          this.getFolderPermissions(this.data.id);

          if(this.data.newFolder) {
            // new child folder
            this.folder.parentFolder = EntityUtil.toSimple(r);
          } else { 
            // edit folder
            this.folder = r;
          }
        });
      }
  }

  ngOnInit(): void {
    this.qdmsService.isUserQdmsAdmin().subscribe(r => this.userIsQdmsAdmin = r);
    this.qdmsService.getUserGroups().subscribe(r => this.userGroups = r);
  }

  filterUsers(event) {
    this.userService.searchUsers(event.query).subscribe(res => this.filteredUsers = res);
  }

  saveOrUpdateFolder() {
    this.isLoading = true;
    if(this.folder.id) {
      //update 
      this.qdmsService.updateFolder(this.folder).subscribe(() => {
        this.showToast('success', 'Klasör Güncellendi');
        this.isLoading = false;
      }, () => {
        this.showToast('error')
        this.isLoading = false;
      })
    } else {
      // save
      this.qdmsService.saveFolder(this.folder).subscribe(r => {
        this.folder = r;
          this.isLoading = false;
        this.showToast('success', 'Klasör Oluşturuldu')
      },
      () => {
        this.isLoading = false;
        this.showToast('error')
      })
    }
    
  }

  saveOrUpdatePermission(permission: FolderPermissions, index: number) {

    if(!this.folder.id) return;

    if(!permission.user || !permission.user.id) {
      this.showToast('warn', 'Lütfen yetkilendirilecek personel seçini yapın.');
      return;
    }

    this.isLoading = true;
    if(permission.id) {
      // update
      this.qdmsService.updateFolderPermissions(permission).subscribe(() => this.showToast('success', 'Yetki Güncellendi'), () =>  this.showToast('error'))
    } else {
      // save
      this.qdmsService.saveFolderPermission(permission).subscribe(
        res => {
          this.folder.permissions[index] = res;
          this.showToast('success', 'Yetki Oluşturuldu');
        },
        () => {this.showToast('error')}
      )
    }
  }
  
  addPermission() {
    const permission = new FolderPermissions()
    permission.folder = EntityUtil.toSimple(this.folder);
    this.folder.permissions.push(permission);
  }

  deletePermissions(permission: FolderPermissions, index) {
    if(!permission) return;
    
    if(permission.id) {
      this.isLoading = true;
      this.qdmsService.deleteFolderPermissions(permission.id).subscribe(() => {
        this.showToast('success', 'Yetki Kaldırıldı')
        this.folder.permissions.splice(index, 1);
      }, () => this.showToast('error'))
    } else {
      this.folder.permissions.splice(index, 1);
    }

  }

  getFolderPermissions(folderId) {
    this.qdmsService.searchFolderPermissions({'folder.id':folderId}).subscribe(r => this.folder.permissions = r)
  }

  showToast(severity, message = null) {
    this.isLoading = false;
    const summary = {'error': 'Hata','success': 'Başarılı','warn': 'Uyarı'};
    if(!message && severity == 'error')  message = 'Bir hata oluştu. Tekrar deneyin.';
    this.messageService.add({ key: 'tst', severity: severity, summary: summary[severity], detail: message });
  }

  onChangeUserGroup() {
    // Kaydedilmemişleri seçimi kaldırılan kullanıcı grubundaki kayıtları siler.
    this.userGroups.forEach(f => {
      if(!this.selectedUserGroups.find(r => r.id == f.id)) {
        const groupUser = this.folder.permissions.filter(u => u.userGroups &&  u.userGroups.includes(f.id) && !u.id);
        if(groupUser.length > 0) {
          groupUser.forEach(usr => {
            const idx = this.folder.permissions.findIndex(u => u.user.id == usr.user.id);
            if(idx) this.folder.permissions.splice(Number(idx), 1)
          })
        }
      }
    })

    // Seçilen gruplara ait kullanıcıları ekler.
    if(this.selectedUserGroups.length == 0) return;
    this.selectedUserGroups.forEach(g => {
      this.qdmsService.getUsersByGroups([g.id]).subscribe(r => {
        r.forEach(u => {
          if(!this.folder.permissions.find(f => f.user?.id == u.id)) {
            const folderPerm = new FolderPermissions();
            folderPerm.folder = EntityUtil.toSimple(this.folder);
            folderPerm.user = u;
            folderPerm.userGroups.push(g.id);
            this.folder.permissions.push(folderPerm);
          }
        })
      });

    })

  }
}
