import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AirMinimizedModel} from "../../../../models/air-minimized.model";
import {OfferExportService} from "../../../../services/offer-export.service";
import {CompanyMinimizedModel} from "../../../../models/company-minimized.model";
import {DistrictMinimizedModel} from "../../../../models/district-minimized.model";
import {CityMinimizedModel} from "../../../../models/city-minimized.model";
import {CountryMinimizedModel} from "../../../../models/country-minimized.model";
import {Company} from "../../../../models/company.model";
import {Activity} from "../../../../models/activity.model";
import {DatePipe} from "@angular/common";
import {ConfirmationService, MessageService} from "primeng/api";
import {PersonService} from 'src/app/services/person.service';
import {CompanyService} from 'src/app/services/company.service';
import {CreateCustomerComponent} from "../../../../shared/create-customer/create-customer.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ListService} from "../../../../services/list.service.service";
import {getbaseUrl} from 'ngx-doc-viewer';
import {UpdateOfferStatusDTO} from "../../../../models/update-offer-status-dto.model";
import {DashboardService} from "../../../../services/dashboard.service";
import b64toBlob from 'b64-to-blob';
import { UserService } from 'src/app/services/user.service';
import { GoodDefinationMinimized } from 'src/app/models/good-defination-minimized';

@Component({
    selector: 'app-air-offer-create',
    templateUrl: './air-offer-create.component.html',
    styleUrls: ['./air-offer-create.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AirOfferCreateComponent implements OnInit {
    @Output() previousPage = new EventEmitter<boolean>();
    @Input() offerOrTariff: any = [];
    @ViewChild('fubauto') uploader: any;
    file: any;
    stateOptions: any[];
    stateOptions2 = [{label2: 'Shipment', value2: 'shipment'}, {label2: 'Unit', value2: 'unit'}];
    state = '';
    stateStatus = 'customerDetail';
    state2 = 'shipment';
    state3 = 'buy';
    buyOrSell = 'sell';
    digitalStore: any = [];
    displayBasic = false;
    isLoading = false;
    requestSucceed = false;
    displayMail = false;
    reservation = false;
    showRevisionDescDialog: boolean = false;
    displayFrontEndCarryList = false;
    selectedFrontCarryTariff: any[] = [];
    selectedEndCarryTariff: any[] = [];
    frontTariffDetMobile: any = [];
    frontTariffDetMobileChosen: any = [];
    endTariffDetMobile: any = [];
    endTariffDetMobileChosen: any = [];
    showReservationConfirmDialog: boolean = false;
    reReservation = false;
    makeReservationId: number;
    tariffMadeByReservation: any;
    saved = false;
    reservationId: any[] = [];
    copyCount = 1;
    createdActivity: any;
    airFrightBuyTariffDetMobiles: any[] = [];
    frontEndTariffDetMobile: any = [];
    reportList: any = [];
    revisionDescription: any;
    showStatusHistory = false;
    offerStatusHistory: any;


    report: any;
    airlineNumber: any;
    isInvoiceItem: any;
    offerSubStatuse: any;
    rejectReason: any;
    invoiceItemType: any[];
    invoiceItem: any[];
    itemCompany: any;
    invoiceType = 'IN';
    countType: any[] = [{json: 'SF', name: 'Sabit Fiyat'}]; // , {json: 'SFB', name: 'Sabit Fiyat Barem'}, {json: 'BSF', name: 'Birim * Sabit Fiyat'}, {json: 'BB', name: 'Birim * Barem'}
    count: any[] = [{json: 'SF', name: 'Sabit Fiyat'}]; // , {json: 'SFB', name: 'Sabit Fiyat Barem'}, {json: 'BSF', name: 'Birim * Sabit Fiyat'}, {json: 'BB', name: 'Birim * Barem'}
    invoiceCountType = this.count[0];
    invoiceCurrency: any;
    invoiceBuyamount = 0;
    invoiceAmount = 0;
    isFreightCharge = false;
    invoice: any;


    editForm: UntypedFormGroup;
    createCustomerForm: UntypedFormGroup;
    reservationForm: UntypedFormGroup;

    // Aktivite'nin son durumu
    SubTypeText = {
        inProgress: 'Fiyatlandırma Yapılıyor.',
        confirmed: 'Fiyat Onaylandı',
        inProgressCompleted: 'Fiyatlandırma Yapıldı',
        accepted: 'Kabul Edildi',
        rejected: 'Red Edildi',
        revisionRequest: 'Revizyon Yapılıyor',
        pricing: 'Fiyatlandırma Bekleniyor',
        pendingCustomer: 'Müşteriye İletildi'
    };

    FieldTranslation = {
        customer: 'Müşteri',
        fromAirPort: 'Kalkış',
        toAirPort: 'Varış',
        offerType: 'Teklif Tipi',
        stackableType: 'İstiflenebilir',
        reversibleType: 'Çevrilebilir',
        goodDefination: 'Mal Cinsi',
        senderId: 'Gönderici',
        consigneId: 'Alıcı',
        estimatedAmount: 'Tahmini Tutar',
        estimatedAmountCurrency: 'Tahmini T. Para Birimi',
        operationRepresentativeId: 'Operasyon Temsilcisi',
        whoseBusiness: 'İş Tipi',
        salesChannel: 'Satış Kanalları',
        freightPayment: 'Navlun Ödeme',
        shipmentUnit: 'Yük Bilgileri',
        loadCountry: 'POL-Inland Ülkesi',
        unloadCountry: 'POD-Inland Ülkesi',
        loadCity: 'POL-Inland Şehri',
        unloadCity: 'POD-Inland Şehri',
        incoterm: 'Incoterm',
        salesRepresentative:'Satış Temsilcisi'
    };

    incotermType: any[] = [{name: 'CFR'}, {name: 'CIF'}, {name: 'CPT'}, {name: 'DAF'}, {name: 'DAP'}, {name: 'DAT'}, {name: 'DDP'}, {name: 'DDU'}, {name: 'DEO'}, {name: 'DES'}, {name: 'DPU'}, {name: 'EXW'}, {name: 'FAS'}, {name: 'FCA'}, {name: 'FOB'}];
    incoterm: any[] = [{name: 'CFR'}, {name: 'CIF'}, {name: 'CPT'}, {name: 'DAF'}, {name: 'DAP'}, {name: 'DAT'}, {name: 'DDP'}, {name: 'DDU'}, {name: 'DEO'}, {name: 'DES'}, {name: 'DPU'}, {name: 'EXW'}, {name: 'FAS'}, {name: 'FCA'}, {name: 'FOB'}];
    invoiceTypes: any[] = [{value: 'CN', label: 'Alacak Dekontu'}, {value: 'DN', label: 'Borç Dekontu'}, {value: 'IN', label: 'Fatura'}, {value: 'RI', label: 'İade Fatura'}, {value: 'IR', label: 'İç Dekont'}, {value: 'OC', label: 'Operasyon Gider'}];
    salesChannel: any[] = [{code: 'ST', name: 'Sales Team'}, {code: 'BD', name: 'BusinessDevelopment'}, {code: 'TM', name: 'Telemarketing'}, {code: 'DM', name: 'Digital Marketing'}, {code: 'NVO', name: 'NVOCC'}];
    freightPaymentType: any[] = ['CC', 'PP'];

    totalPriceType: string[] = ['PP', 'CP', 'PC', 'CC'];
    totalPrice: string[] = ['PP', 'CP', 'PC', 'CC'];

    transportType: string[] = ['PTP', 'DTP', 'PTD', 'DTD'];
    transport: string[] = ['PTP', 'DTP', 'PTD', 'DTD'];

    filteredCompanies: Company[] = [];
    companies: Company[] = [];

    filteredAirport: AirMinimizedModel[] = [];

    filteredUsers: any[] = [];

    airport: AirMinimizedModel[] = [];

    serviceCodeType: any[] = [{name: 'Ağır Kargo', json: 'HEA'},
        {name: 'Bozulabilir Kargo', json: 'VUL'},
        {name: 'Canlı Hayvan', json: 'AVI'},
        {name: 'Cenaze', json: 'HUM'},
        {name: 'Diplomatik Kargo', json: 'DIP'},
        {name: 'Genel Kargo', json: 'GCR'},
        {name: 'Perishable Cargo', json: 'PER'},
        {name: 'Spor Silahları', json: 'SWP'},
        {name: 'Tehlikeli Madde', json: 'DGR'},
        {name: 'Valuable Cargo', json: 'VAL'}];
    serviceCode: any[] = [{name: 'Ağır Kargo', json: 'HEA'},
        {name: 'Bozulabilir Kargo', json: 'VUL'},
        {name: 'Canlı Hayvan', json: 'AVI'},
        {name: 'Cenaze', json: 'HUM'},
        {name: 'Diplomatik Kargo', json: 'DIP'},
        {name: 'Genel Kargo', json: 'GCR'},
        {name: 'Perishable Cargo', json: 'PER'},
        {name: 'Spor Silahları', json: 'SWP'},
        {name: 'Tehlikeli Madde', json: 'DGR'},
        {name: 'Valuable Cargo', json: 'VAL'}];

    offerType: any[] = [{json: 'allin', name: 'ALLIN TEKLİF'}, {json: 'UNIQUE_CHARGE', name: 'Genel Teklif'}, {
        json: 'barem',
        name: 'Baremli Fiyat'
    }];
    offer: any[] = [{json: 'allin', name: 'ALLIN TEKLİF'}, {json: 'UNIQUE_CHARGE', name: 'Genel Teklif'}, {
        json: 'barem',
        name: 'Baremli Fiyat'
    }];

    goodDefinationType: any[] = [{name: 'askılı', json: 'ASKILI'},
        {name: 'Bag', json: 'BAG'},
        {name: 'BAGS', json: 'BAGS'},
        {name: 'BALE', json: 'BALE'},
        {name: 'bale', json: 'BALES'},
        {name: 'barrel', json: 'BARREL'},
        {name: 'bars', json: 'BARS'},
        {name: 'Big bag', json: 'BIG BAG'},
        {name: 'BIG BAGS', json: 'BIG BAGS'},
        {name: 'Bins', json: 'BINS'},
        {name: 'BLOCK', json: 'BLOCK'},
        {name: 'Bolts', json: 'BOLTS'},
        {name: 'BONDS', json: 'BONDS'},
        {name: 'Bottle', json: 'BOTTLES'},
        {name: 'Boxes', json: 'BOXES'},
        {name: 'Bucket', json: 'BUCKETS'},
        {name: 'bulks', json: 'BULKS'},
        {name: 'Bunches', json: 'BUNCHES'},
        {name: 'Bundles', json: 'BUNDLES'},
        {name: 'Cans', json: 'CANS'},
        {name: 'Canvas', json: 'CANVAS'},
        {name: 'cap', json: 'KAP'},
        {name: 'CAPS', json: 'CAPS'},
        {name: 'Cartons', json: 'CARTONS'},
        {name: 'Cases', json: 'CASES'},
        {name: 'coil', json: 'COIL'},
        {name: 'COLLIES', json: 'COLLIES'},
        {name: 'container', json: 'CONTAINER'},
        {name: 'CUPS', json: 'CUPS'},
        {name: 'Drums', json: 'DRUMS'},
        {name: 'euroPallet', json: 'EURO_PALLET'},
        {name: 'Frames', json: 'FRAMES'},
        {name: 'IBC', json: 'IBC'},
        {name: 'Jars', json: 'JARS'},
        {name: 'LOT', json: 'LOT'},
        {name: 'PACKAGE', json: 'PACKAGE'},
        {name: 'packages', json: 'PACKAGES'},
        {name: 'Pails', json: 'PAILS'},
        {name: 'Pairs', json: 'PAIRS'},
        {name: 'PALLET', json: 'PALLET'},
        {name: 'PALLETS', json: 'PALLETS'},
        {name: 'Parcels', json: 'PARCELS'},
        {name: 'Parcels', json: 'Parcels'},
        {name: 'Pieces', json: 'PIECES'},
        {name: 'Pipes', json: 'PIPES'},
        {name: 'PK', json: 'PK'},
        {name: 'Plates', json: 'PLATES'},
        {name: 'Reels', json: 'REELS'},
        {name: 'Rolls', json: 'ROLLS'},
        {name: 'Sacks', json: 'SACKS'},
        {name: 'SheetMetals', json: 'SHEETMETALS'},
        {name: 'Sheets', json: 'SHEETS'},
        {name: 'Shrink', json: 'SHRINK'},
        {name: 'SLING BAGS', json: 'SLING BAGS'},
        {name: 'Slipsheets', json: 'SLIPSHEETS'},
        {name: 'Tins', json: 'TINS'},
        {name: 'Tubes', json: 'TUBES'},
        {name: 'UNITS', json: 'UNITS'},
        {name: 'usPallet', json: 'us_pallet'},
        {name: 'WOODEN BOX', json: 'WOODEN BOX'},
        {name: 'WOODEN CASES', json: 'WOODEN CASES'},
        {name: 'WOODEN PACKAGES', json: 'WOODEN PACKAGES'},
        {name: 'WOODEN PALLETS', json: 'WOODEN PALLETS'},
        {name: 'Z69R', json: 'Z69R'},
    ];
    goodDefination: any[] = [{name: 'askılı', json: 'ASKILI'},
        {name: 'Bag', json: 'BAG'},
        {name: 'BAGS', json: 'BAGS'},
        {name: 'BALE', json: 'BALE'},
        {name: 'bale', json: 'BALES'},
        {name: 'barrel', json: 'BARREL'},
        {name: 'bars', json: 'BARS'},
        {name: 'Big bag', json: 'BIG BAG'},
        {name: 'BIG BAGS', json: 'BIG BAGS'},
        {name: 'Bins', json: 'BINS'},
        {name: 'BLOCK', json: 'BLOCK'},
        {name: 'Bolts', json: 'BOLTS'},
        {name: 'BONDS', json: 'BONDS'},
        {name: 'Bottle', json: 'BOTTLES'},
        {name: 'Boxes', json: 'BOXES'},
        {name: 'Bucket', json: 'BUCKETS'},
        {name: 'bulks', json: 'BULKS'},
        {name: 'Bunches', json: 'BUNCHES'},
        {name: 'Bundles', json: 'BUNDLES'},
        {name: 'Cans', json: 'CANS'},
        {name: 'Canvas', json: 'CANVAS'},
        {name: 'cap', json: 'KAP'},
        {name: 'CAPS', json: 'CAPS'},
        {name: 'Cartons', json: 'CARTONS'},
        {name: 'Cases', json: 'CASES'},
        {name: 'coil', json: 'COIL'},
        {name: 'COLLIES', json: 'COLLIES'},
        {name: 'container', json: 'CONTAINER'},
        {name: 'CUPS', json: 'CUPS'},
        {name: 'Drums', json: 'DRUMS'},
        {name: 'euroPallet', json: 'EURO_PALLET'},
        {name: 'Frames', json: 'FRAMES'},
        {name: 'IBC', json: 'IBC'},
        {name: 'Jars', json: 'JARS'},
        {name: 'LOT', json: 'LOT'},
        {name: 'PACKAGE', json: 'PACKAGE'},
        {name: 'packages', json: 'PACKAGES'},
        {name: 'Pails', json: 'PAILS'},
        {name: 'Pairs', json: 'PAIRS'},
        {name: 'PALLET', json: 'PALLET'},
        {name: 'PALLETS', json: 'PALLETS'},
        {name: 'Parcels', json: 'PARCELS'},
        {name: 'Parcels', json: 'Parcels'},
        {name: 'Pieces', json: 'PIECES'},
        {name: 'Pipes', json: 'PIPES'},
        {name: 'PK', json: 'PK'},
        {name: 'Plates', json: 'PLATES'},
        {name: 'Reels', json: 'REELS'},
        {name: 'Rolls', json: 'ROLLS'},
        {name: 'Sacks', json: 'SACKS'},
        {name: 'SheetMetals', json: 'SHEETMETALS'},
        {name: 'Sheets', json: 'SHEETS'},
        {name: 'Shrink', json: 'SHRINK'},
        {name: 'SLING BAGS', json: 'SLING BAGS'},
        {name: 'Slipsheets', json: 'SLIPSHEETS'},
        {name: 'Tins', json: 'TINS'},
        {name: 'Tubes', json: 'TUBES'},
        {name: 'UNITS', json: 'UNITS'},
        {name: 'usPallet', json: 'us_pallet'},
        {name: 'WOODEN BOX', json: 'WOODEN BOX'},
        {name: 'WOODEN CASES', json: 'WOODEN CASES'},
        {name: 'WOODEN PACKAGES', json: 'WOODEN PACKAGES'},
        {name: 'WOODEN PALLETS', json: 'WOODEN PALLETS'},
        {name: 'Z69R', json: 'Z69R'},
    ];

    clickCount = 0;

    filteredAirlineMinimized: CompanyMinimizedModel[] = [];
    airline: any[] = [];
    airlines: any[] = [];

    filteredDistrictMinimized: DistrictMinimizedModel[] = [];

    filteredGoodDefinationType:GoodDefinationMinimized[] = [];
    filteredGoodDefinationType2:GoodDefinationMinimized[] = [];

    district: DistrictMinimizedModel[] = [];
    filteredCityMinimized: CityMinimizedModel[] = [];

    city: CityMinimizedModel[] = [];
    filteredCountryMinimized: CountryMinimizedModel[] = [];

    country: CountryMinimizedModel[] = [];
    currencyType: any[];

    currency: any[];
    offerNameId: any[] = [];

    operationRepresentativeList: any[];
    operationRepresentative: any[];

    businessType: any[] = [{type: 'B', name: 'Bizim'}, {type: 'A', name: 'Acenta'}, {type: 'FRW', name: 'Forwarder'}];
    business: any[] = [{type: 'B', name: 'Bizim'}, {type: 'A', name: 'Acenta'}, {type: 'FRW', name: 'Forwarder'}];

    showPreviousBtn = false;
    listResultItem: any = null;
    showDetailDialog: boolean = false;
    displaySeaGoodDetail: boolean = false;
    multiDisabled: boolean = false;
    airGood: any;
    lastFilteredGoods: any [] = [];
    childGoods: any[] = [];
    constructor(private formBuilder: UntypedFormBuilder, private offerExportService: OfferExportService,
                private personService: PersonService, private dialog: MatDialog, private activatedRoute: ActivatedRoute,
                private datepipe: DatePipe, private messageService: MessageService, private companyService: CompanyService,
                private listService: ListService, private router: Router,
                private confirmService: ConfirmationService, private dahsboardService: DashboardService,private userService: UserService) {
        this.stateOptions = [
            {label: 'customerDetail', value: 'customerDetail'},
            // {label: 'Taşıma Bilgileri', value: 'Taşıma Bilgileri'},
        ];
        if (this.router?.getCurrentNavigation()?.extras?.state?.airGood) {
            this.airGood = this.router.getCurrentNavigation()?.extras?.state?.airGood;
        }
        this.editForm = this.formBuilder.group({
            customer: ['', [Validators.required]],
            sender: ['', []],
            abroadAgentId: ['', []],
            incoterm: [null, [Validators.required]],
            masterOnly: ['', []],
            freightPayment: ['', []],
            airlineNotShow: ['', []],
            transportDirection: [null, [Validators.required]],
            estimatedAmountCurrency: [null, []],
            estimatedAmount: [null, []],
            notes: ['', []],
            etd: ['', []],
            imo: ['', []],
            classInfo: ['', []],
            unNo: ['', []],
            flashPoint: ['', []],
            delivery: ['PTP', []],
            fromAirPort: [this.offerOrTariff[0]?.fromPort, [Validators.required]],
            toAirPort: [this.offerOrTariff[0]?.toPort, [Validators.required]],
            serviceCode: [{json: 'GCR', name: 'General Cargo'}, []],
            offerType: [{json: 'UNIQUE_CHARGE', name: 'Genel Teklif'}, [Validators.required]],
            whoseBusiness: ['', [Validators.required]],
            salesRepresentative: [null, [Validators.required]],
            stackableType: ['', [Validators.required]],
            reversibleType: ['', []],
            goodDefination: ['', []],
            goodDefinationType: ['', []],
            airlines: ['', []],
            loadCountry: ['', []],
            unloadCountry: ['', []],
            loadCity: ['', []],
            unloadCity: ['', []],
            loadDistrict: ['', []],
            unloadDistrict: ['', []],
            loadZip: ['', []],
            unloadZip: ['', []],
            loadAddress: ['', []],
            unloadAddress: ['', []],
            shipmentUnit: this.formBuilder.array([this.createShipmentUnit(null)]),
            currency: ['', []],
            price: ['', []],
        });
        this.createCustomerForm = this.formBuilder.group({
            name: ['', []],
            districtId: ['', []],
            cityId: ['', []],
            countryId: ['', []],
            addressDetail: ['', []],
            phoneNo: ['', []],
            notes: ['', []],
        });
        this.reservationForm = this.formBuilder.group({
            senderId: [this.editForm?.get(['customer'])?.value, [Validators.required]],
            consigneId: ['', [Validators.required]],
            abroadAgentId: ['', []],
            operationRepresentativeId: ['', [Validators.required]],
            whoseBusiness: ['', [Validators.required]],
            salesChannel: ['', [Validators.required]],
            freightPayment: ['', [Validators.required]],
            operationNote: ['', []],
            customerRef: ['', []],
            masterOnly: [false, []],
            shipmentUnit: this.formBuilder.array([this.createShipmentUnit(null)], [Validators.required])
        });
    }

    async ngOnInit(){
        console.log(this.offerOrTariff);
        if (this.activatedRoute.snapshot.params.id !== undefined) {
            this.requestSucceed = true;
            this.saved = true;
            this.offerExportService.getMyActivityById(this.activatedRoute.snapshot.params.id).subscribe(res => {
                this.createdActivity = res.body;
                this.offerNameId.push(res.body);
                this.state2 = this.createdActivity.shipmentUnit;
                this.editForm.patchValue({
                    id: this.createdActivity.id,
                    name: this.createdActivity.name,
                    customer: this.createdActivity.customer,
                    sender: this.createdActivity.sender,
                    abroadAgent: this.createdActivity.abroadAgent,
                    freightPayment: this.createdActivity.freightPayment,
                    transportDirection: this.createdActivity.transportDirection,
                    estimatedAmountCurrency: this.createdActivity.estimatedAmountCurrency,
                    estimatedAmount: this.createdActivity.estimatedAmount,
                    serviceCode: this.serviceCodeType.find(sC => sC.json === this.createdActivity.serviceCode),
                    etd: new Date(this.createdActivity.etd),
                    cutOffDate: new Date(this.createdActivity.cutOffDate),
                    goodDefination: this.createdActivity.goodDefination,
                    goodDefinationType: this.createdActivity.goodDefinationType,
                    airline: this.createdActivity.airline,
                    airlines: this.createdActivity?.airlines ? this.createdActivity?.airlines.filter(air => air !== null) : [],
                    airlineNotShow: this.createdActivity.airlineNotShow,
                    fak: this.createdActivity.fak,
                    imo: this.createdActivity.imo,
                    classInfo: this.createdActivity.classInfo,
                    stackableType: this.createdActivity.stackableType,
                    salesRepresentative:this.createdActivity.salesRepresentative,
                    reversibleType: this.createdActivity.reversibleType,
                    unNo: this.createdActivity.unNo,
                    flashPoint: this.createdActivity.flashPoint,
                    equipments: this.createdActivity.equipments,
                    delivery: this.createdActivity.delivery,
                    endDate: new Date(this.createdActivity.endDate),
                    notes: this.createdActivity.notes,
                    incoterm: {name: this.createdActivity.incoterm},
                    toAirPort: this.createdActivity.toAirPort,
                    fromAirPort: this.createdActivity.fromAirPort,
                    offerType: this.createdActivity.offerType === 'allin' ? {json: 'allin', name: 'ALLIN TEKLİF'} : this.createdActivity.offerType === 'barem' ? {json: 'barem', name: 'Baremli Fiyat'} : {json: 'UNIQUE_CHARGE', name: 'Genel Teklif'},
                    whoseBusiness: this.createdActivity.whoseBusiness === 'B' ? {type: 'B', name: 'Bizim'} : 'A' ? {type: 'A', name: 'Acenta'} : 'FRW' ? {type: 'FRW', name: 'Forwarder'} : '' ,
                    loading: this.createdActivity.loading,
                    loadCountry: this.createdActivity.loadCountry,
                    loadCity: this.createdActivity.loadCity,
                    loadDistrict: this.createdActivity.loadDistrict,
                    loadZip: this.createdActivity.loadZip,
                    loadAddress: this.createdActivity.loadAddress,
                    unloading: this.createdActivity.unloading,
                    unloadCountry: this.createdActivity.unloadCountry,
                    unloadCity: this.createdActivity.unloadCity,
                    unloadDistrict: this.createdActivity.unloadDistrict,
                    unloadZip: this.createdActivity.unloadZip,
                    unloadAddress: this.createdActivity.unloadAddress
                });
                this.airlines = this.createdActivity?.airlines ? this.createdActivity?.airlines.filter(air => air !== null) : [];
                (this.editForm.get('shipmentUnit') as UntypedFormArray).removeAt(0);
                if (!this.createdActivity.offerPacks?.length) {
                    (this.editForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit({
                        measureUnit: this.createdActivity.measureUnit,
                        quantity: this.createdActivity.quantity,
                        packing: this.createdActivity.packing,
                        volume: this.createdActivity.volume,
                        grossWeight: this.createdActivity.grossWeight,
                        length: this.createdActivity.length,
                        width: this.createdActivity.width,
                        height: this.createdActivity.height
                    }));
                } else {
                    this.createdActivity.offerPacks.forEach(of => {
                        (this.editForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit(of));
                    });
                }
                this.createdActivity.incoOfferAirDTOs.forEach(inc => {
                    this.makeReservationId = inc.offerDetailId;
                    if (inc.airGoods?.length > 0) {
                        this.tariffMadeByReservation = inc;
                    }
                    inc.airGoods.forEach((ai, index) => {
                        this.reservationId.push(ai);
                        if (inc.airGoods?.length - 1 === index) {
                            (this.reservationForm.get('shipmentUnit') as UntypedFormArray).removeAt(0);
                            ai.equipments.forEach(ec => {
                                (this.reservationForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit(ec));
                            });
                        }
                    });
                });
                console.log('create', this.createdActivity);
                this.transferDataFromOfferAndTariff();
                this.reservationForm.patchValue(this.reservationId[this.reservationId?.length - 1]);
                this.reservationForm.patchValue({
                    whoseBusiness: this.businessType.find(bs => bs.type === this.reservationId[this.reservationId?.length - 1]?.whoseBusiness),
                    customerRef: this.createdActivity.customerRef ? this.createdActivity.customerRef : ''
                });
            });
        } else {
            this.transferDataFromOfferAndTariff();
        }
        const promiseArray = [];
        if (this.airGood) {
            promiseArray.push(this.companyService.getAll(this.airGood.customer).toPromise());
            promiseArray.push(this.offerExportService.airportSearch(this.airGood.fromPort).toPromise());
            promiseArray.push(this.offerExportService.airportSearch(this.airGood.toPort).toPromise());
            promiseArray.push(this.offerExportService.getAllCurrency('I').toPromise());
        }
        Promise.all(promiseArray).then(response => {

            if(response && response.length > 0) {
                this.editForm.patchValue({
                    customer: response[0][0],
                    fromAirPort: response[1].body[0],
                    toAirPort: response[2].body[0],
                    transportDirection: this.airGood.transportDirection,
                    incoterm: {name: this.airGood.incoterm},
                });
            }

        });
        const user = this.userService.getUserByTransportDirection('I');
        this.editForm.patchValue({salesRepresentative: user})
        await this.createNewGoodDefinationList()
    }

    createNewGoodDefinationList(){
        this.offerExportService.filterGoodDefinationType().subscribe(res=>{
            this.filteredGoodDefinationType = res.body
            let topGoodType = this.filteredGoodDefinationType.filter(good => good.topGoodType == null)
            topGoodType.forEach(res =>{
                this.filteredGoodDefinationType.forEach(good=>{
                    if(good.topGoodType !=null && (good.topGoodType.id == res.id)){
                        this.childGoods.push(good);
                    }
                })
            })
            this.childGoods.forEach(childGood => {
                this.filteredGoodDefinationType.forEach(good => {
                    if(good.topGoodType != null && (childGood.id == good.topGoodType.id)){
                        this.lastFilteredGoods.push(good)
                    }
                })
            })
            this.filteredGoodDefinationType = this.lastFilteredGoods
            this.filteredGoodDefinationType2 = this.lastFilteredGoods
        })
    }

    filterGoodDefinationType(event){
        const filtered: any[] = [];
        const query = event.query;
        this.filteredGoodDefinationType.forEach(goodTypeDef => {
            if (goodTypeDef.name.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(goodTypeDef);
            }
        });
        this.filteredGoodDefinationType2 = filtered;
    }

    filterUsers(event) {
        if(event.query.lenght < 3) return;
        this.userService.searchUsers(event.query, null).subscribe(res => this.filteredUsers = res);

    }

    filterIncoterm(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.incoterm.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.incotermType = filtered;
    }


    filterTotalPrice(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.totalPrice.forEach(country => {
            if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.totalPriceType = filtered;
    }

    filterCompany(event) {
        this.offerExportService.getCompany(event.query).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

    filterTransport(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.transport.forEach(country => {
            if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.transportType = filtered;
    }

    filterOffer(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.offer.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.offerType = filtered;
    }

    filterGoodDefination(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.goodDefination.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.goodDefinationType = filtered;
    }

    filterDistrict(event, cityId?: any) {
        if (event?.query?.length > 2) {
            this.offerExportService.getOfferExportDistrict(event.query, cityId?.id).subscribe(res => {
                this.filteredDistrictMinimized = res.body;
            });
        }
    }

    filterCity(event, countryId?: any) {
        if (event.query.length > 2) {
            this.offerExportService.getOfferExportCity(event.query, countryId?.id).subscribe(res => {
                this.filteredCityMinimized = res.body;
            });
        }
    }

    filterCountry(event) {
        if (event.query.length > 2) {
            this.offerExportService.getOfferExportCountry(event.query).subscribe(res => {
                this.filteredCountryMinimized = res.body;
            });
        }
    }

    filterCurrency(event) {
        this.offerExportService.getAllCurrency().subscribe(res => {
            this.currencyType = res.body;
            this.currency = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.currency.forEach(country => {
                if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(country);
                }
            });
            this.currencyType = filtered;
        });
    }

    airlineSearch(event) {
        if (event.query.length > 1) {
            this.offerExportService.airlineSearch(event.query).subscribe(res => {
                this.filteredAirlineMinimized = res.body;
            });
        }
    }

    airlineSearchMultiSelect(event) {
        console.log(event);
        this.offerExportService.airlineSearch(event.target.value).subscribe(res => {
            this.airline = res.body;
            console.log(res.body);
            const query = event.target.value;
            this.airline.forEach(airline => {
                if (airline.name.toLowerCase().indexOf(query.toLowerCase()) === 0 || airline.airCode.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    const isExistIndx = this.airlines.findIndex(f => f?.id == airline.id);
                    if (isExistIndx < 0) this.airlines.push(airline);
                }
            });
        });
    }

    airlineSearchAutoComplete(event) {
        const filtered: any[] = [];
        this.offerExportService.airlineSearch(event.target.value).subscribe(res => {
            this.airline = res.body;
            console.log(res.body);
            const query = event.target.value;
            this.airline.forEach(airline => {
                if (airline.name.toLowerCase().indexOf(query.toLowerCase()) === 0 || airline.airCode.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(airline);

                }
            });
        });
    }

    airPortSearch(event, transportDirection: string) {
        if (event.query.length > 1) {
            this.offerExportService.airportSearch(event.query, 'I').subscribe(res => {
                this.filteredAirport = res.body;
                if (this.editForm.get('transportDirection')?.value !== 'I') {
                    if (transportDirection) {
                        this.filteredAirport = this.filteredAirport.filter(ai => ai.country === transportDirection);
                    } else if (this.editForm.get('transportDirection')?.value !== null) {
                        this.filteredAirport = this.filteredAirport.filter(ai => ai.country !== 'TR');
                    }
                }
                this.airport = res.body;
            });
        } else {
            this.filteredAirport = [];
        }
    }

    filterServiceCode(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.serviceCode.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.serviceCodeType = filtered;
    }

    onFocusOutCheckInputIsEmpty(name: any) {
        if (this.editForm?.get([name])?.value?.name === undefined) {
            this.editForm?.controls[name]?.setValue('');
        }
    }

    onFocusOutReservationCheckInputIsEmpty(name: any) {
        if (this.reservationForm?.get([name])?.value?.name === undefined) {
            this.reservationForm?.controls[name]?.setValue('');
        }
    }

    onSelectLoadDistrict(event) {
        this.editForm.patchValue({loadCity: event.city});
        this.editForm.patchValue({loadCountry: event.country});
    }

    onSelectCity(event, key) {
        this.editForm.patchValue({[key]: event.country});
    }

    onSelectUnLoadDistrict(event) {
        this.editForm.patchValue({unloadCity: event.city});
        this.editForm.patchValue({unloadCountry: event.country});
    }

    onSelectLoadDistrict2(event) {
        this.createCustomerForm.patchValue({cityId: event.city});
        this.createCustomerForm.patchValue({countryId: event.country});
    }

    onSelectLoadCity2(event) {
        this.createCustomerForm.patchValue({countryId: event.country});
    }

    addShipmentUnit() {
        (this.editForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit(null));
    }

    addShipmentUnitReservation() {
        (this.reservationForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit(null));
    }

    createShipmentUnit(good: any) {
        if (good === null || good === undefined) {
            return this.formBuilder.group({
                quantity: [null, [Validators.required]],
                packing: [null, [Validators.required]],
                volume: [null, []],
                id: [null, []],
                measureUnit: ['CM', []],
                grossWeight: [null, []],
                length: [null, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]],
                width: [null, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]],
                height: [null, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]]
            });
        } else {
            return this.formBuilder.group({
                quantity: [good?.quantity, [Validators.required]],
                packing: [good?.packing?.json ? good?.packing : {name: good?.packing, json: good?.packing}, [Validators.required]],
                volume: [good?.volume, []],
                id: [good?.id, []],
                measureUnit: [good?.measureUnit, []],
                grossWeight: [good?.grossWeight, []],
                length: [good?.length, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]],
                width: [good?.width, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]],
                height: [good?.height, [this.state2 === 'unit' ? Validators.required : Validators.nullValidator]]
            });
        }
    }

    private createFromForm(): Activity {
        return {
            ...new Activity(),
            id: this.createdActivity?.id,
            name: this.createdActivity?.name,
            subType: this.createdActivity?.subType,
            customer: this.editForm.get(['customer'])!.value,
            sender: this.editForm.get(['sender'])!.value === '' ? null : this.editForm.get(['sender'])!.value,
            etd: this.datepipe.transform((this.editForm?.get(['etd'])?.value === (undefined || null || '') ? Date.now() : this.editForm?.get(['etd'])?.value), 'yyyy-MM-dd HH:mm:ss'),
            endDate: this.datepipe.transform((this.editForm?.get(['etd'])?.value === (undefined || null || '') ? Date.now() : this.editForm?.get(['etd'])?.value), 'yyyy-MM-dd HH:mm:ss'),
            goodDefination: this.editForm.get(['goodDefination'])!.value,
            goodDefinationType: this.editForm.get(['goodDefinationType'])!.value,
            operationType: 'AIR',
            freightPayment: this.editForm.get(['freightPayment'])!.value,
            serviceCode: this.editForm.get(['serviceCode'])!.value?.json,
            stackableType: this.editForm.get(['stackableType'])!.value,
            reversibleType: this.editForm.get(['reversibleType'])!.value,
            estimatedAmountCurrency: this.editForm.get(['estimatedAmountCurrency'])!.value,
            estimatedAmount: this.editForm.get(['estimatedAmount'])!.value,
            airlines: this.editForm.get(['airlines'])!.value === '' ? [] : this.editForm.get(['airlines'])!.value,
            quantity: this.editForm.get(['shipmentUnit'])!.value[0].quantity,
            packing: this.editForm.get(['shipmentUnit'])!.value[0]?.packing?.json,
            grossWeight: this.editForm.get(['shipmentUnit'])!.value[0].grossWeight,
            volume: this.editForm.get(['shipmentUnit'])!.value[0].volume,
            revisedChargeableWeight: this.createdActivity ? this.createdActivity?.revisedChargeableWeight : null,
            offerPacks: this.state2 === 'unit' ? this.offerPackGetJson() : [],
            airlineNotShow: this.editForm.get(['airlineNotShow'])!.value,
            imo: this.editForm.get(['imo'])!.value,
            classInfo: this.editForm.get(['classInfo'])!.value,
            unNo: this.editForm.get(['unNo'])!.value,
            flashPoint: this.editForm.get(['flashPoint'])!.value,
            delivery: this.editForm.get(['delivery'])!.value,
            notes: this.editForm.get(['notes'])!.value,
            incoterm: this.editForm.get(['incoterm'])!.value?.name,
            salesRepresentative: this.editForm.get(['salesRepresentative'])!.value,
            equipments: [],
            seaAllocations: [],
            shipmentUnit: this.state2,
            toAirPort: this.editForm.get(['toAirPort'])!.value !== '' ? this.editForm.get(['toAirPort'])!.value : null,
            fromAirPort: this.editForm.get(['fromAirPort'])!.value !== '' ? this.editForm.get(['fromAirPort'])!.value : null,
            offerType: this.editForm.get(['offerType'])!.value.json,
            whoseBusiness: this.editForm.get(['whoseBusiness'])!.value?.type ? this.editForm.get(['whoseBusiness'])!.value?.type : null,
            // loading: this.editForm.get(['loading'])!.value,
            loadCountry: this.editForm.get(['loadCountry'])!.value !== '' ? this.editForm.get(['loadCountry'])!.value : null,
            loadCity: this.editForm.get(['loadCity'])!.value !== '' ? this.editForm.get(['loadCity'])!.value : null,
            loadDistrict: this.editForm.get(['loadDistrict'])!.value !== '' ? this.editForm.get(['loadDistrict'])!.value : null,
            loadZip: this.editForm.get(['loadZip'])!.value,
            loadAddress: this.editForm.get(['loadAddress'])!.value,
            // unloading: this.editForm.get(['unloading'])!.value !== '' ? this.editForm.get(['unloading'])!.value : null,
            unloadCountry: this.editForm.get(['unloadCountry'])!.value !== '' ? this.editForm.get(['unloadCountry'])!.value : null,
            unloadCity: this.editForm.get(['unloadCity'])!.value !== '' ? this.editForm.get(['unloadCity'])!.value : null,
            unloadDistrict: this.editForm.get(['unloadDistrict'])!.value !== '' ? this.editForm.get(['unloadDistrict'])!.value : null,
            unloadZip: this.editForm.get(['unloadZip'])!.value,
            unloadAddress: this.editForm.get(['unloadAddress'])!.value,
            airFrightBuyTariffDetMobiles: this.airFrightBuyTariffDetMobiles,
            frontEndTariffDetDTOs: this.createdActivity ? this.createdActivity.frontEndTariffDetDTOs : this.frontTariffDetMobile.concat(this.endTariffDetMobile),
            frontEndTariffDetMobiles : this.createdActivity ? this.createdActivity.frontEndTariffDetDTOs : this.frontTariffDetMobile.concat(this.endTariffDetMobile),
            incoOfferAirDTOs: this.createdActivity ? this.createdActivity?.incoOfferAirDTOs : [],
            transportDirection: this.editForm.get(['transportDirection'])!.value !== '' ? this.editForm.get(['transportDirection'])!.value : null,
            // activitySeaFullEditDTO: this.createdActivity?.activitySeaFullEditDTO
        };
    }

    sendRequest() {
        this.isLoading = true;
        if (this.checkValidation(this.editForm)) {
            this.isLoading = false;
            return;
        }
        this.offerExportService.createActivity(this.createFromForm()).subscribe(async res => {
            this.createdActivity = res.body;
            if (this.createdActivity?.shipmentUnit === 'unit') {
                this.createdActivity?.offerPacks.forEach(pc => {
                    pc.packing = this.goodDefinationType.find(gd => gd.json === pc.packing);
                });
                this.editForm?.get('shipmentUnit').patchValue(this.createdActivity?.offerPacks);
            }
            this.offerNameId.push(res.body);
            this.isLoading = false;
            this.requestSucceed = true;
            this.saved = true;
            // if (this.offerOrTariff) {
            //     this.stateOptions.push({label: 'Rezervasyon', value: 'Rezervasyon'});
            // }
            this.messageService.add({
                key: 'tst',
                severity: 'success',
                summary: 'Başarılı',
                detail: 'Teklif Başarıyla Kaydedildi'
            });
            if (this.airGood) {
                await this.setAirGoodToOffer(this.airGood.id, this.createdActivity?.incoOfferAirDTOs[0]?.offerDetailId);
            }
        }, error => {
            this.isLoading = false;
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: 'Hata',
                detail: 'Doldurulan alanlar hatalı!'
            });
        });
    }

    transferDataFromOfferAndTariff() {
        if (this.offerOrTariff.length) {
            this.airFrightBuyTariffDetMobiles = this.offerOrTariff;
            this.stateOptions.push({label: 'priceInformation', value: 'priceInformation'});
            this.editForm.patchValue({airlines: this.airlines});
            this.offerOrTariff.forEach(oo => {
                this.airlines.push(oo.airline);
            });
        }
        if (this.activatedRoute.snapshot.params.id !== undefined && (this.createdActivity?.offerStatus === 'inProgressCompleted' || this.createdActivity?.offerStatus === 'rejected' || this.createdActivity?.offerStatus === 'confirmed' || this.createdActivity?.offerStatus === 'accepted')) {
            // this.stateOptions.push({label: 'Fiyat Bilgileri', value: 'Fiyat Bilgileri'});
            // this.state = 'Fiyat Bilgileri';
            this.stateStatus = 'customerDetail+priceInformation';
            this.airlines = this.createdActivity?.airlines;
            if (this.createdActivity?.offerStatus === 'confirmed' || this.createdActivity?.offerStatus === 'accepted') {
                this.stateOptions.push({label: 'reservation', value: 'reservation'});
                this.stateStatus = 'customerDetail+priceInformation+reservation';
            }
        }
        this.state = 'customerDetail';
    }

    getFrontEndTariffDetMobileFront(type: string) {
        setTimeout(() => {
            this.offerExportService.getFrontEndTariffDetMobileFront(type, this.editForm?.get(['loadDistrict'])?.value.id, this.editForm?.get(['fromAirPort'])?.value.id, 'AIR', null,
                this.datepipe.transform((this.editForm?.get(['etd'])?.value === '' ? Date.now() : this.editForm?.get(['etd'])?.value), 'yyyy-MM-dd')).subscribe(res => {
                res.body.forEach(tf => {
                    const isExits = this.frontEndTariffDetMobile.find(fe => fe.id === tf.id);
                    if (!isExits) {
                        this.frontEndTariffDetMobile.push(tf);
                    }
                });
            });
        }, 500);
        console.log(this.createdActivity)
    }

    getFrontEndTariffDetMobileEnd(type: string) {
        setTimeout(() => {
            this.offerExportService.getFrontEndTariffDetMobileEnd(type, this.editForm?.get(['unloadDistrict'])?.value.id, this.editForm?.get(['toAirPort'])?.value.id, 'AIR', null,
                this.datepipe.transform((this.editForm?.get(['etd'])?.value === '' ? Date.now() : this.editForm?.get(['etd'])?.value), 'yyyy-MM-dd')).subscribe(res => {
                res.body.forEach(tf => {
                    const isExits = this.frontEndTariffDetMobile.find(fe => fe.id === tf.id);
                    if (!isExits) {
                        this.frontEndTariffDetMobile.push(tf);
                    }
                });
            });
        }, 500);
    }

    getReportList() {
        if (this.reportList?.length >= 0) {
            this.offerExportService.getReportList(this.createdActivity.offerId, 'I').subscribe(rp => {
                this.reportList = rp.body;
            });
        }
    }

    showPdf(report: any) {
        this.isLoading = true;
        this.offerExportService.getReport(report?.id, this.createdActivity?.offerId).subscribe(gt => {
            this.isLoading = false;
            const file = new Blob([gt], {type: 'application/pdf'});
            this.file = file;
            const fileUrl = window.URL.createObjectURL(file);
            window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
            this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu'});
        }, error => {
            this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'PDF oluşturulamadı!'});
            this.isLoading = false;
        });
    }

    showCreateCustomerDialog() {
        const dialogRef = this.dialog.open(CreateCustomerComponent, {
            panelClass: 'modal-md',
            width: '1000px',
            data: {}
        });
    }

    async setAirGoodToOffer(airGoodId: any, offerId) {
        this.confirmService.confirm({
            header: 'İşlem Onayı',
            message: 'Teklif oluştu, yük teklife bağlansın mı?',
            accept: async () => {
                this.isLoading = true;
                await this.dahsboardService.setOfferAirDetailToAirGoodWithId(airGoodId, offerId, 'I').toPromise().then(
                    res => { // Success
                        this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Kayıt başarılı." });
                    },
                    msg => { // Error
                        this.messageService.add({key: 'tst', severity: 'warning', summary: 'Hata', detail: "Yük teklife bağlanamadı" });
                    }
                );
                this.isLoading = false;
            }
        });
    }

    changePageReservation(tarif?: any) {
        this.isLoading  = true;
        this.reservation = true;
        console.log(tarif)
        this.makeReservationId = tarif?.offerDetailId;
        this.tariffMadeByReservation = tarif;

        if (this.createdActivity?.offerStatus !== 'confirmed' && this.createdActivity?.offerStatus !== 'accepted') {
            this.stateOptions.push({label: 'Rezervasyon', value: 'Rezervasyon'});
        }
        this.isLoading  = false;
        this.state = 'Rezervasyon';
        (this.reservationForm.get('shipmentUnit') as UntypedFormArray).removeAt(0);
        (this.editForm.get('shipmentUnit') as UntypedFormArray).controls.forEach(shp => {
            (this.reservationForm.get('shipmentUnit') as UntypedFormArray).push(this.createShipmentUnit(shp.value));
        });
        this.reservationForm.patchValue({
            senderId: this.editForm?.get(['sender'])?.value,
        });
        const scrollToPayment = window.setTimeout(() => {
            // this.scrollToElement("beginning");
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 4);
    }

    filterOperationRepresentative(event) {
        this.offerExportService.getUserWithName(event.query, 'I').subscribe(res => {
            this.operationRepresentativeList = res.body;
        });
    }

    filterBusinessType(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.business.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });
        this.businessType = filtered;
    }

    async makeHardCopyReservation() {
        if (this.checkValidation(this.reservationForm)) {
            this.isLoading  = false;
            return;
        }
        if (!this.showReservationConfirmDialog) {
            this.showReservationConfirmDialog = true;
            return;
        }
        for (let i = 0; i < this.copyCount; i++) {
            await this.makeReservation();
        }
        this.showReservationConfirmDialog = false;
    }

    async makeReservation() {

        for (let item in this.reservationForm.controls) {
            if (this.reservationForm?.controls[item]?.status === 'INVALID') {
                this.reservationForm?.get(item)?.markAsDirty();
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + item.toUpperCase() + ' alan doldurulmalı!'});
            }
        }

        if(String(this.reservationForm.value?.operationNote).length > 255) {
            this.messageService.add({key: 'tst',severity: 'warn',summary: 'Uyarı',detail: `Operasyon notu en fazla 255 karakter olabilir.`});
            return;
        }

        if (this.reservationForm.get(['senderId'])!.value === '' || this.reservationForm.get(['operationRepresentativeId'])!.value === '' || this.reservationForm?.invalid) {
            this.isLoading = false;
            return;
        } else {

            this.isLoading = true;
            const response = await this.offerExportService.createReservation({
                offerAirDetailId: this.makeReservationId,
                senderId: this.reservationForm.get(['senderId'])!.value?.id ? this.reservationForm.get(['senderId'])!.value?.id : null,
                consigneId: this.reservationForm.get(['consigneId'])!.value?.id ? this.reservationForm.get(['consigneId'])!.value?.id : null,
                abroadAgentId: this.reservationForm.get(['abroadAgentId'])!.value?.id ? this.reservationForm.get(['abroadAgentId'])!.value?.id : null,
                operationRepresentativeId: this.reservationForm.get(['operationRepresentativeId'])!.value?.id ? this.reservationForm.get(['operationRepresentativeId'])!.value?.id : '',
                freightPayment: this.reservationForm.get(['freightPayment'])!.value ? this.reservationForm.get(['freightPayment'])!.value : null,
                whoseBusiness: this.reservationForm.get(['whoseBusiness'])!.value?.type ? this.reservationForm.get(['whoseBusiness'])!.value?.type : null,
                salesChannel: this.reservationForm.get(['salesChannel'])!.value ? this.reservationForm.get(['salesChannel'])!.value : null,
                operationNote: this.reservationForm.get(['operationNote'])!.value ? this.reservationForm.get(['operationNote'])!.value : null,
                customerRef: this.reservationForm.get(['customerRef'])!.value ? this.reservationForm.get(['customerRef'])!.value : null,
                masterOnly: this.reservationForm.get(['masterOnly'])!.value ? this.reservationForm.get(['masterOnly'])!.value : null,
                offerPackages: this.getReservationPackages()
            }, 'I');
            console.log(response);
            if (response.status === 200) {
                this.reReservation = true;
                this.isLoading = false;
                this.createdActivity.subType = 'confirmed';
                this.reservationId.push(JSON.parse(response.body));
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Başarıyla Kaydedildi' });
            } else {
                this.isLoading = false;
                this.messageService.add({key: 'tst', severity: 'error', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!'});
            }
        }
    }

    getReservationPackages() {
        this.reservationForm.get(['shipmentUnit'])!.value.forEach(sh => sh.packing = sh?.packing?.json);
        return this.reservationForm.get(['shipmentUnit'])!.value;
    }


    private offerPackGetJson() {
        this.editForm.get(['shipmentUnit'])!.value.forEach(sh => {
            if (sh.packing?.json) {
                sh.packing = sh.packing?.json;
            }
        });
        return this.editForm.get(['shipmentUnit'])!.value;
    }

    deleteOfferPack(i: number, id?: number) {
        this.isLoading = true;
        if (this.createdActivity) {
            this.offerExportService.deleteOfferPack(id).subscribe(res => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Ambalaj bilgisi silindi!' });
            }, error => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Ambalaj bilgisi silinemedi!' });
                return;
            });
        }
        (this.editForm.get('shipmentUnit') as UntypedFormArray).removeAt(i);
    }

    deleteOfferPackFromReservation(i: number) {
        (this.reservationForm.get('shipmentUnit') as UntypedFormArray).removeAt(i);
    }

    async view(item: any) {
        // SeaGood Detail
        // const resp = await this.seaGoodMobileSearch({'seaGoodId': item.id})
        // this.displaySeaGoodDetail = true;
        // this.showPreviousBtn = false;
        // this.listResultItem = resp['datas'][0]
        // console.log(this.listResultItem, "item")
        // if (this.listResultItem) this.showDetailDialog = true;
    }

    async seaGoodMobileSearch(param) {
        return await this.listService.goodMobileSearch(param, '/air/list/airGoodMobileList/listSearch?name=airGoodMobileList&query=', 'E').toPromise();
    }

    updateRequest() {
        this.isLoading = true;
        if (this.checkValidation(this.editForm)) {
            this.isLoading = false;
            return;
        }
        // if (this.editForm?.get(['customer'])?.value !== ''  && this.editForm?.get(['endDate'])?.value !== ('' && null) && this.editForm?.get(['goodDefination'])?.value !== '') {
            this.offerExportService.updateActivity(this.createFromForm()).subscribe(res => {
                this.offerExportService.getReportList(res.body?.offerId).subscribe( rp => {
                    this.reportList = rp.body;
                });
                this.createdActivity = res.body;
                if (this.createdActivity?.shipmentUnit === 'unit') {
                    this.createdActivity?.offerPacks.forEach(pc => {
                        pc.packing = this.goodDefinationType.find(gd => gd.json === pc.packing);
                    });
                    this.editForm?.get('shipmentUnit').patchValue(this.createdActivity?.offerPacks);
                }
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Güncellendi' });
            }, error => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
            });
        // }
    }

    checkValidation(form: any): boolean {
        let isInvalid = false;
        for (const item in form.controls){
            if (form?.controls[item]?.status === 'INVALID') {
                if (item !== 'fromAirPort' || form?.controls?.incoterm?.value?.name !== 'EXW') {
                    form?.get(item)?.markAsDirty();
                    isInvalid = true;
                    this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + this.FieldTranslation[item]?.toUpperCase() + ' alan doldurulmalı!'});
                }
                if (item === 'shipmentUnit') {
                    form?.controls[item]?.controls.forEach(ct => {
                        for (const good in ct?.controls) {
                            if (ct.controls[good].status === 'INVALID') {
                                ct?.get(good)?.markAsDirty();
                            }
                        }
                    });
                }
            }
        }
        if((this.activatedRoute.snapshot.params.id !== undefined && this.editForm.get('goodDefination').value == '')){
            isInvalid = true;
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
        }
        if(((this.activatedRoute.snapshot.params.id === undefined || this.activatedRoute.snapshot.params.id !== undefined ) && this.editForm.get('goodDefinationType').value == '')){
            isInvalid = true;
            this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!'});
        }
        return isInvalid;
    }

    packingEmptyControl(i: number) {
        if (this.editForm?.get('shipmentUnit')['controls'][i]?.value?.packing?.name === undefined) {
            (this.editForm.get('shipmentUnit') as UntypedFormArray).controls[i].get('packing').setValue('');
        }
    }

    packingReservationEmptyControl(i: number) {
        if (this.reservationForm?.get('shipmentUnit')['controls'][i]?.value?.packing?.name === undefined) {
            (this.reservationForm.get('shipmentUnit') as UntypedFormArray).controls[i].get('packing').setValue('');
        }
    }

    createCopyOffer() {
        this.isLoading = true;
        this.offerExportService.createCopyOffer(this.createdActivity.id).subscribe(res => {
            this.offerNameId.push(res.body);
            this.openTheCopy(res.body.id);
            this.onUpdateStatus(this.createdActivity.offerStatus);
            this.onUpdateStatus('pricing', res.body);
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklifler başarıyla kopyalandı.' });
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: 'Teklifler kopyalanırken hata oluştu!' });
        });
    }

    openTheCopy(id: number) {
        window.open(getbaseUrl() + '/activities/air-offer-detail/' + id, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
    }

    sendRevision() {
        const payloadUpdateStatus = [];
        if(this.createdActivity && this.createdActivity?.incoOfferAirDTOs?.length > 0) {
            this.onUpdateStatus('revisionRequest');
            this.createdActivity.offerStatus = 'revisionRequest';
        }
    }

    async onUpdateStatus(updateStatus = null, activity = this.createdActivity) {
        this.isLoading = true;
        const payloadUpdateStatus = [];
        activity?.incoOfferAirDTOs.forEach(s => {
            payloadUpdateStatus.push({
                offerAirDetailId: s.offerDetailId,
                status: updateStatus,
                substatus: null,
                description: 'Status update',
            });
        });
        this.offerExportService.updateOfferStatus(payloadUpdateStatus, 'I').subscribe(
            data => {
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        ), () => {
            this.isLoading = false;
            if (this.showRevisionDescDialog) this.showRevisionDescDialog = !this.showRevisionDescDialog;
        }
    }
    async setPendingCustomer() {
        try {
            await this.onUpdateStatus('pendingCustomer')
            this.messageService.add({ key: 'tst', severity: 'success', summary: '', detail: 'Kaydedildi' });
            this.createdActivity.offerStatus = 'pendingCustomer';
        } catch {

        }


    }

    estimatedMakeRequired() {
        if ((this.editForm!.get(['estimatedAmountCurrency'])?.value !== null && this.editForm.get(['estimatedAmountCurrency'])?.value !== '') || (this.editForm!.get(['estimatedAmount'])?.value !== 0 && this.editForm!.get(['estimatedAmount'])?.value !== null)) {
            this.editForm!.get(['estimatedAmountCurrency'])?.setValidators([Validators.required]);
            this.editForm!.get(['estimatedAmount'])?.setValidators([Validators.required]);
            this.editForm?.get(['estimatedAmountCurrency']).updateValueAndValidity();
            this.editForm?.get(['estimatedAmount']).updateValueAndValidity();
        } else {
            this.editForm!.get(['estimatedAmountCurrency'])?.clearValidators();
            this.editForm!.get(['estimatedAmount'])?.clearValidators();
            this.editForm?.get(['estimatedAmountCurrency']).updateValueAndValidity();
            this.editForm?.get(['estimatedAmount']).updateValueAndValidity();
        }
    }

    filterInvoiceItem(event) {
        this.offerExportService.invoiceItemType('AIR', 'I').subscribe(res => {
            this.invoiceItemType = res.body;
            this.invoiceItem = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.invoiceItem.forEach(country => {
                if (country.name.toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) === 0) {
                    filtered.push(country);
                }
            });
            this.invoiceItemType = filtered;
        });
    }

    filterCountType(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.count.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });
        this.countType = filtered;
    }

    addSeaTariffDetMobiles(armatorNumber: number) {
        if (this.invoice !== undefined && this.invoice !== '' && this.invoiceCountType !== undefined && this.invoiceCountType.name !== '' && this.invoiceCurrency !== undefined && this.invoiceCurrency?.name !== '') {
            if (this.createdActivity) {
                if (this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirBuyCharges === (null || '')) {
                    if (this.invoiceBuyamount !== 0 && this.invoiceBuyamount !== null) {
                        this.createdActivity.incoOfferAirDTOs[armatorNumber].offerAirBuyCharges = [];
                        this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirBuyCharges.push({
                            amount: this.invoiceBuyamount,
                            invoiceType: this.invoiceType,
                            itemCompany: this.itemCompany,
                            calculateType: this.invoiceCountType?.json,
                            ctype: this.invoiceCountType?.json,
                            currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name},
                            invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name},
                            isFreightCharge: this.isFreightCharge
                        });
                    }
                } else {
                    if (this.invoiceBuyamount !== 0 && this.invoiceBuyamount !== null) {
                        this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirBuyCharges.push({
                            amount: this.invoiceBuyamount,
                            invoiceType: this.invoiceType,
                            itemCompany: this.itemCompany,
                            calculateType: this.invoiceCountType?.json,
                            ctype: this.invoiceCountType?.json,
                            currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name},
                            invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name},
                            isFreightCharge: this.isFreightCharge
                        });
                    }
                }
                if (this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirSaleCharges === (null || '')) {
                    if (this.invoiceAmount !== 0 && this.invoiceAmount !== null) {
                        this.createdActivity.incoOfferAirDTOs[armatorNumber].offerAirSaleCharges = [];
                        this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirSaleCharges.push({
                            amount: this.invoiceAmount,
                            invoiceType: this.invoiceType,
                            itemCompany: this.itemCompany,
                            calculateType: this.invoiceCountType?.json,
                            ctype: this.invoiceCountType?.json,
                            currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name},
                            invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name},
                            isFreightCharge: this.isFreightCharge
                        });
                    }
                } else {
                    if (this.invoiceAmount !== 0 && this.invoiceAmount !== null) {
                        this.createdActivity?.incoOfferAirDTOs[armatorNumber]?.offerAirSaleCharges.push({
                            amount: this.invoiceAmount,
                            invoiceType: this.invoiceType,
                            itemCompany: this.itemCompany,
                            calculateType: this.invoiceCountType?.json,
                            ctype: this.invoiceCountType?.json,
                            currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name},
                            invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name},
                            isFreightCharge: this.isFreightCharge
                        });
                    }
                }
            } else {
                this.airFrightBuyTariffDetMobiles[armatorNumber]?.airTariffDetMobiles.forEach(tf => {
                    if (tf?.seaTariffDetMobiles?.length) {
                        tf?.seaTariffDetMobiles.push({amount: this.invoiceAmount, sellOrBuyInvoice: this.buyOrSell, itemCompany: this.itemCompany, invoiceType: this.invoiceType, buyAmount: this.invoiceBuyamount, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name}, invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name}, isFreightCharge : this.isFreightCharge});
                    } else {
                        tf.seaTariffDetMobiles = [];
                        tf?.seaTariffDetMobiles.push({amount: this.invoiceAmount, sellOrBuyInvoice: this.buyOrSell, itemCompany: this.itemCompany, invoiceType: this.invoiceType, buyAmount: this.invoiceBuyamount, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: {id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name}, invoiceItemType: {id: this.invoice?.id, name: this.invoice?.name}, isFreightCharge : this.isFreightCharge});
                    }
                });
            }
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Ek masraf eklenemedi!' });
        }
        this.isInvoiceItem = false;
        this.invoice = ''; this.invoiceCurrency = ''; this.invoiceBuyamount = 0; this.invoiceAmount = 0; this.isFreightCharge = false; this.itemCompany = undefined;
    }

    async deleteOfferAirChargeWithId(id: number, name: string, deleteObject?: any, buyOrSell?: string, index?: number) {
        this.isLoading = true;
        if (this.createdActivity) {
            if (id === undefined) {
                if (buyOrSell === 'buy') {
                    deleteObject?.offerAirBuyCharges.splice(deleteObject?.offerAirBuyCharges.findIndex(q => q.invoiceItemType.name === name), 1);
                } else {
                    deleteObject?.offerAirSaleCharges.splice(deleteObject?.offerAirSaleCharges.findIndex(q => q.invoiceItemType.name === name), 1);
                }
                this.isLoading = false;
            } else {
                if (deleteObject?.offerAirBuyCharges.find(q => q.invoiceItemType.name === name) && buyOrSell === 'buy') {
                    const response = await this.offerExportService.deleteOfferAirChargeWithId(deleteObject?.offerAirBuyCharges.find(q => q.invoiceItemType.name === name).id, 'I').toPromise();
                    if (response.status === 200) {
                        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Ek masraf silindi!' });
                        deleteObject?.offerAirBuyCharges.splice(deleteObject?.offerAirBuyCharges.findIndex(q => q.invoiceItemType.name === name), 1);
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.messageService.add({key: 'messageSeaList', severity: 'warn', summary: 'Hata', detail: 'Ek masraf silinemedi!'});
                    }
                }
                if (deleteObject?.offerAirSaleCharges.find(q => q.invoiceItemType.name === name) && buyOrSell === 'sell') {
                    const response = await this.offerExportService.deleteOfferAirChargeWithId(deleteObject?.offerAirSaleCharges.find(q => q.invoiceItemType.name === name).id, 'I').toPromise();
                    if (response.status === 200) {
                        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Ek masraf silindi!' });
                        deleteObject?.offerAirSaleCharges.splice(deleteObject?.offerAirSaleCharges.findIndex(q => q.invoiceItemType.name === name), 1);
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.messageService.add({key: 'messageSeaList', severity: 'warn', summary: 'Hata', detail: 'Ek masraf silinemedi!'});
                    }
                }
            }
        } else {
            deleteObject?.offerAirBuyCharges.splice(deleteObject?.offerAirBuyCharges.findIndex(q => q.invoiceItemType.name === name), 1);
            deleteObject?.offerAirSaleCharges.splice(deleteObject?.offerAirSaleCharges.findIndex(q => q.invoiceItemType.name === name), 1);
            this.isLoading = false;
        }
    }

    changeShipmentUnitValidators() {
        const endOfTheArray = (this.editForm.get('shipmentUnit') as UntypedFormArray).length;
        for (let i = 0; i < endOfTheArray; i++) {
            (this.editForm.get('shipmentUnit') as UntypedFormArray).removeAt(0);
        }
        this.addShipmentUnit();
    }

    setFrontEndTariffList() {
        if (this.editForm.get('delivery').value.startsWith('D')) {
            this.frontTariffDetMobile.push({buyAmount: 0, amount: 0, frontEnd: 'frontTransport', name: '', supplier: null, calculateUnit: null, unit: null, currency: null, fromAirport: null, toAirport: {id: this.editForm?.get(['fromAirPort'])?.value?.id, name: this.editForm?.get(['fromAirPort'])?.value?.name}});
        } else {
            this.frontTariffDetMobile = [];
        }
        if (this.editForm.get('delivery').value.endsWith('D')) {
            this.endTariffDetMobile.push({buyAmount: 0, amount: 0, frontEnd: 'endTransport', name: '', supplier: null, calculateUnit: null, unit: null, currency: null, fromAirport: {id: this.editForm?.get(['toAirPort'])?.value?.id, name: this.editForm?.get(['toAirPort'])?.value?.name}, toAirport: null});
        } else {
            this.endTariffDetMobile = [];
        }
    }

    rejectAllOffer(substatus?: string, description?: string) {
        this.isLoading = true;
        const updateOfferStatusDTO: UpdateOfferStatusDTO[] = [];
        this.createdActivity?.incoOfferAirDTOs.forEach(s => {
            updateOfferStatusDTO.push({
                ...new UpdateOfferStatusDTO(),
                offerAirDetailId: s.offerDetailId,
                status: 'rejected',
                substatus: substatus,
                description
            });
        });
        this.offerExportService.updateOfferStatus(updateOfferStatusDTO, 'I').subscribe(res => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklifler başarıyla reddedildi.' });
            this.isLoading = false;
            this.createdActivity.offerStatus = 'rejected';
        }, error => {
            this.isLoading = false;
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklifler reddedilirken hata oluştu!' });
        });
    }

    async requestIncoOfferAirDTO(event) {

        if (this.createdActivity?.offerStatus === 'inProgress') {
            this.isLoading = true;
            this.multiDisabled = true;
            if (!event.itemValue && event.value.length === 0) {
                const incoOfferAirDtos = [...this.createdActivity.incoOfferAirDTOs];
                for (const inco of incoOfferAirDtos){
                    await this.deleteIncoOfferAirDto(inco.airline.id, inco === incoOfferAirDtos[incoOfferAirDtos.length - 1]);
                }
            } else if (!event.itemValue && event.value.length > 0) {
                const airlines = [...event.value];
                for (const airline of airlines) {
                    if (airline === airlines[0] && this.createdActivity.incoOfferAirDTOs.length === 1 && !this.createdActivity.incoOfferAirDTOs[0]?.airline){
                        await this.saveIncoOfferAirDto(airline.id, airline === airlines[airlines.length - 1]);
                    }else {
                        await this.createIncoOfferAirDto(airline, airline === airlines[airlines.length - 1]);
                    }
                }
            } else {
                if (this.createdActivity.incoOfferAirDTOs.length === 1 && !this.createdActivity.incoOfferAirDTOs[0]?.airline) {
                    this.saveIncoOfferAirDto(event.itemValue.id, true);
                } else {
                    if (!event.value.includes(event.itemValue)) {
                        this.deleteIncoOfferAirDto(event.itemValue.id, true);
                    } else {
                        this.createIncoOfferAirDto(event.itemValue, true);
                    }
                }
            }

        }
    }

    deleteIncoOfferAirDto(airlineId, disable) {
        return new Promise(resolve => {
            const incoOfferAirDTO = this.createdActivity.incoOfferAirDTOs.find(dto => dto.airline?.id === airlineId);
            this.offerExportService.deleteIncoOfferAirDTO(incoOfferAirDTO, 'I').subscribe(res => {
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif başarıyla silindi.'});
                this.createdActivity.incoOfferAirDTOs = res.body.incoOfferAirDTOs;
                if (disable) {
                    this.multiDisabled = false;
                    this.isLoading = false;
                }
                console.log(res);
                resolve(res);
            }, error => {
                console.log(error);
                if (disable) {
                    this.multiDisabled = false;
                    this.isLoading = false;
                }
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklif silinirken hata oluştu!'});
                resolve(error);
            });
        });
    }

    saveIncoOfferAirDto(airlineId, disable) {
        return new Promise(resolve => {
            this.createdActivity.incoOfferAirDTOs[0].airline = {id: airlineId};
            this.offerExportService.saveIncoOfferAirDTO(this.createdActivity.incoOfferAirDTOs[0], 'I').subscribe(res => {
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif başarıyla güncellendi.'});
                this.createdActivity.incoOfferAirDTOs = res.body.incoOfferAirDTOs;
                if (disable){
                    this.isLoading = false;
                    this.multiDisabled = false;
                }
                console.log(res);
                resolve(res);
            }, error => {
                console.log(error);
                if (disable){
                    this.isLoading = false;
                    this.multiDisabled = false;
                }
                resolve(error);
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklif güncellenirken hata oluştu!'});
            });
        });
    }

    createIncoOfferAirDto(airlineValue, disable) {
        return new Promise(resolve => {
            const incoOfferAirDTO = {
                airline: airlineValue,
                toPort: {
                    id: this.createdActivity.toAirPort.id
                },
                fromPort: {
                    id: this.createdActivity.fromAirPort.id
                },
                currency: {id: this.createdActivity.estimatedAmountCurrency?.id},
                buyCurrency: {id: this.createdActivity.estimatedAmountCurrency?.id}
            };
            this.offerExportService.createFromOffer(this.createdActivity?.offerId, incoOfferAirDTO, 'I').subscribe(res => {
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif başarıyla eklendi.'});
                console.log(res);
                if (disable) {
                    this.multiDisabled = false;
                    this.isLoading = false;
                }
                this.createdActivity.incoOfferAirDTOs = res.body.incoOfferAirDTOs;
                resolve(res);
            }, error => {
                console.log(error);
                if (disable) {
                    this.multiDisabled = false;
                    this.isLoading = false;
                }
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklif eklenirken hata oluştu!'});
                resolve(error);
            });
        });
    }

    onClickOffer(offer) {

        // todo birden durum seçilecek.
        this.isLoading = true;
        this.clickCount++;
        setTimeout(() => {
            if (this.clickCount === 1) {
                offer.status = 'rejected';
                this.setStatusFunction('rejected', offer.offerDetailId);
                this.messageService.add({key: 'tst', severity: 'warn', summary: 'Reddedildi', detail: 'Teklif reddedildi!'});
            } else if (this.clickCount === 2) {
                offer.status = 'confirmed';
                this.setStatusFunction('confirmed', offer.offerDetailId);
                this.messageService.add({key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif kabul edildi.'});
            }
            this.clickCount = 0;
        }, 250);
    }

    setStatusFunction(updateStatus, offerDetailId) {
        const payloadUpdateStatus = [];
        payloadUpdateStatus.push({
            offerAirDetailId: offerDetailId,
            status: updateStatus,
            substatus: null,
            description: 'Status update',
        });
        this.offerExportService.updateOfferStatus(payloadUpdateStatus, 'I').subscribe(
            data => {
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        );
        return payloadUpdateStatus;
    }

    updateTransportType() {
        this.setFrontEndTariffList();
        if (this.createdActivity?.delivery !== this.editForm.get(['delivery'])!.value) {
            if (!this.createdActivity.frontEndTariffDetDTOs?.length) {
                this.createdActivity.frontEndTariffDetDTOs = this.frontTariffDetMobile.concat(this.endTariffDetMobile);
                this.frontTariffDetMobile = [];
                this.endTariffDetMobile = [];
            }
        }
        this.editForm.get('delivery').value.startsWith('D') ? this.editForm.get('loadCountry')?.setValidators([Validators.required]) : this.editForm.get('loadCountry')?.clearValidators();
        this.editForm.get('delivery').value.startsWith('D') ? this.editForm.get('loadCity')?.setValidators([Validators.required]) : this.editForm.get('loadCity')?.clearValidators();
        this.editForm.get('delivery').value.endsWith('D') ? this.editForm.get('unloadCountry')?.setValidators([Validators.required]) : this.editForm.get('unloadCountry')?.clearValidators();
        this.editForm.get('delivery').value.endsWith('D') ? this.editForm.get('unloadCity')?.setValidators([Validators.required]) : this.editForm.get('unloadCity')?.clearValidators();
        this.editForm.get('loadCountry')?.updateValueAndValidity();
        this.editForm.get('loadCity')?.updateValueAndValidity();
        this.editForm.get('unloadCountry')?.updateValueAndValidity();
        this.editForm.get('unloadCity')?.updateValueAndValidity();
    }

    numberCheck(value: any) {
        const isnum = /\d/.test(value);
        if (isnum) {
            setTimeout(() => {
                this.editForm.patchValue({estimatedAmountCurrency: ''});
            }, 100);
        }
    }

    getDigitalArsiv() {
        this.isLoading = true;
        this.offerExportService.getDigitalArsiv(this.createdActivity?.offerId, 'Offer').subscribe(res => {
            this.digitalStore = res.body;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
      }

      getDigitalDocument(report: any) {
        this.isLoading = true;
        this.offerExportService.getDocumentType(report?.id, 'I').subscribe(res => {
            this.offerExportService.getDigitalDocument(res.body.reference, 'I').subscribe( gt => {
                this.isLoading = false;
                const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
                const fileUrl = window.URL.createObjectURL(file);
                if ( res.body.contentType === 'application/pdf' ||  res.body.contentType.startsWith('image')) {
                    window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                } else {
                    const nav = (window.navigator as any)
                    if (nav && nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
                    } else {
                        var a = document.createElement('a');
                        a.href = URL.createObjectURL(file);
                        a.download = res.body.fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
            }, error => {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
                this.isLoading = false;
            });
        },error => {
            this.isLoading = false;
        });
      }

      uploadDocumentsCheck(documents: any) {
        console.log(documents);
        if (documents.files && documents.files.length > 0) {
            for (const file of documents.files) {
                if (file.size <= 15728640) {
                    this.uploadDocuments(file);
                } else {
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
                }
            }

        } else if (documents.files && documents.files.length > 0) {
            for (const file of documents.files) {
                if (file.getAsFile().size <= 15728640) {
                    this.uploadDocuments(file);
                } else {
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
                }
            }
        }
      }

      uploadDocuments(file: any) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', file);
        this.offerExportService.uploadDocuments(formData, this.createdActivity?.offerId).subscribe(res => {
            const dct = res.body.res;
            this.offerExportService.getPagesByName('/offers/offer').subscribe(pages => {
                const pageObject = pages.body.find(pg => pg.path === '/offers/offer');
                if (res.status === 200) {
                    this.offerExportService.saveDocument({
                        entityid: +dct.entityid,
                        reference: dct.uid,
                        contentType: dct.contentType,
                        fileName: dct.fileName,
                        fileSize: dct.fileSize,
                        page: pageObject,
                        sourceType: 'Offer'
                    }).subscribe(sv => {
                        this.getDigitalArsiv();
                        this.uploader.clear();
                    });
                    // this.user = this.cacheService.get(Storage.USER);
                    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
                }
            }, error => {
                this.isLoading = false;
            });
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
      }

    showStatusStory(offerId: number) {
        this.offerExportService.getAllOfferStatusByOfferId(offerId, 'I').subscribe(res => {
            this.offerStatusHistory = res.body;
        }, error => {
            this.messageService.add({ key: 'tst', severity:'warning', summary: 'Uyarı', detail: 'Teklif durumu henüz değişmemiş' });
        });
    }
}
