import { Component, OnInit } from '@angular/core';
import { ChangePasswordDTO } from 'src/app/models/change-password-dto';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'primeng/api';
import {ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePassword = new ChangePasswordDTO();
  isLoading: boolean = false;
  token: string;
  isForgotPassword = false;
  username: string;
  constructor(private userService: UserService, private messageService: MessageService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
          this.token = params.token;
          this.username = params.username;
      });

      if (this.token != null) {
          this.isForgotPassword = true;
      }
  }

  onChangePassword() {
      this.isLoading  = true;
      if(this.changePassword.password != this.changePassword.passwordReply) {
          this.isLoading  = false;
          this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Şifre tekrarı hatalı!' });
          return;
      }
    if (this.isForgotPassword) {
        this.userService.changePasswordMail(this.changePassword, this.token, this.username).toPromise().then(data => {
            this.messageService.add({severity: 'success', summary: 'Başarılı', detail: 'Şifreniz Değiştirildi.' });
            this.changePassword.oldPassword = null;
            this.changePassword.password = null;
            this.changePassword.passwordReply = null;
            this.isLoading  = false;
        }, (err) => {
            console.log(err)
            this.messageService.add({severity: 'error', summary: 'Hata', detail: err.error.message });
            this.isLoading  = false;
        })
    } else {
        if(this.changePassword.oldPassword == this.changePassword.password) {
            this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Mevcut şifre ile yeni şifreniz aynı olamaz!' });
            this.isLoading  = false;
            return;
        }

        if(!this.changePassword.oldPassword || !this.changePassword.password || !this.changePassword.passwordReply) {
            this.isLoading  = false;
            this.messageService.add({severity: 'warn', summary: 'Uyarı', detail: 'Tüm alanları doldurmalısınız!' });
            return;
        }


        this.userService.changePassword(this.changePassword).then(r => {

            this.messageService.add({severity: 'success', summary: 'Başarılı', detail: 'Şifreniz Güncellendi.' });
            this.changePassword.oldPassword = null;
            this.changePassword.password = null;
            this.changePassword.passwordReply = null;
            this.isLoading  = false;
        }, (err) => {
            this.messageService.add({severity: 'error', summary: 'Hata', detail: 'Eski şifreniz hatalı!' });
            this.isLoading  = false;
        })
    }

  }


}
