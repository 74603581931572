import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'
import {Calendar, EventClickArg} from '@fullcalendar/core';
import { ActivityDTOModel } from 'src/app/models/activity-dto.model';
import {MessageService} from "primeng/api";
import {UserService} from "../../../services/user.service";
import {any} from 'codelyzer/util/function';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, AfterViewInit {

  public userTransportDirection = null;
  options: any;
  userId = JSON.parse(localStorage.getItem("user")).id;
  startDate: string;
  endDate: string;
  activities: ActivityDTOModel[] = [];
  selectedStatus: string = null;
  isLoading: boolean = false;
  contactTypes = {
    'call': 'Telefon',
    'sms': 'Sms',
    'travel': 'Seyahat',
    'office': 'Ofis',
    'mail': 'Email',
    'visit': 'Ziyaret',
    'survey': 'Anket'
  }


  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarApi: Calendar;

  constructor(private activityService: ActivityService,
              private messageService: MessageService,
              private userService: UserService,
              private route: Router) {
    this.options = {
      initialDate: new Date(),
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: '',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      plugins: [dayGridPlugin],
      initialView: 'dayGridWeek',
      datesSet: this.dateChanged.bind(this),
      height: 250,
      locale: 'tr',
      eventClick: this.handleEventClick.bind(this),
    };

    const user = userService.getUserFromLocalStorage();
    /*
     TODO: Adres kartına taşıma yönü eklenebilir. şimdilik ismine bakarak varsayılan taşıma yönüne belirlenmiştir.
     * */
    if(!user.company) {
      this.messageService.add({key: 'tst', severity:'warn', detail: 'Kullanıcı tanımına ait adres kartı bilgisi bulunamadı!' });
    } else {
      this.userTransportDirection = user.company.name.includes('KTL') ? 'I' : 'E';

      if(this.userTransportDirection == 'I') {
        if(localStorage.getItem('userimport'))  this.userId = JSON.parse(localStorage.getItem("userimport")).id;
      }
    }

  }

  ngOnInit(): void {  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
  }

  dateChanged(arg) {
    this.startDate = (arg.start).toJSON().replace(/\..*$/g, '')
    this.endDate = (arg.end).toJSON().replace(/\..*$/g, '')
    if(this.selectedStatus) this.getActivity(this.selectedStatus);

  }

  getActivity(status: string) {
    this.activities = [];
    this.selectedStatus = status;
    this.isLoading = true;
    let params: any;
    params = {
          'status': status,
          'contactDate/DT/GT': this.startDate,
          'contactDate/DT/LT': this.endDate
    };
    if (this.selectedStatus === 'yapılacak') {
          params = {
              'status': status,
              'activityDate/DT/GT': this.startDate,
              'activityDate/DT/LT': this.endDate
          };
    }

    this.activityService.search(params, this.userId, this.userTransportDirection).subscribe(r => {
      this.activities = r;
      this.isLoading = false;
      this.calendarApi.removeAllEvents();

      this.activities.forEach(e => {
        console.log(e, "activity")
        this.calendarApi.addEvent({
          title: e.company.name,
          start: params.status === 'yapılacak' ? e.activityDate : e.contactDate,
          publicId: e.id,
          allDay: true,
        });
      })
    })

  }


  navigateCustomers(activity: ActivityDTOModel) {
    this.route.navigate(['activities/customers'],{ queryParams: { id: activity.company.id, transportDirection: this.userTransportDirection } });
  }
  navigateCreateActivity() {
    this.route.navigate(['create-activity']);
  }
  navigateActivityDetail(activity) {
    this.route.navigate([`activity/${this.userTransportDirection}/${activity.id}`]);
  }
  handleEventClick(clickInfo: EventClickArg) {
    this.route.navigate([`activity/${this.userTransportDirection}/${clickInfo.event._def.extendedProps.publicId}`]);
  }

}
