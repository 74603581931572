import { SimpleEntity } from "./simple-entity.model";

export class OfferPartialActivityMobileFrontEndTariff {
    id: number;
    loadingType: string;
    zip: string;
    country: SimpleEntity;
    supplier: SimpleEntity;
    city: SimpleEntity;
    district: SimpleEntity;
    currency: SimpleEntity;
    amount: number;
    address: string;
    type: string = 'from'
}