import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CreateSurveyRequestModel } from 'src/app/models/survey-models/create-survey-request.model';
import { Question } from 'src/app/models/survey-models/question';
import { UserGroup } from 'src/app/models/user-group.model';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { SurveyService } from 'src/app/services/survey.service';

export interface QuestionType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss']
})
export class CreateSurveyComponent implements OnInit {

  surveyName: string;

  isPublic: boolean = false;

  questions: Question[] = [];

  isLoading: boolean = false;

  isReportable: boolean = false;

  userGroups: UserGroup[] = [];

  selectedUserGroups: UserGroup[] = [];

  constructor(private surveyService: SurveyService, private messageService: MessageService, private announcementService: AnnouncementService) {

  }

  ngOnInit(): void {


    var user = JSON.parse(localStorage.getItem("user"));

    this.announcementService.getUserGroupsByUserId(user.id).subscribe(res => {
      console.log(res);
      this.userGroups = res;
    })

    var question: Question = {
      id: null,
      isTextAnswer: false,
      questionAnswers: [
        {
          id: null,
          questionId: null,
          text: '',
          userId: null,
          companyId: null
        },
        {
          id: null,
          questionId: null,
          text: '',
          userId: null,
          companyId: null
        }
      ],
      text: ''
    }

    this.questions.push(question);

  }

  newAnswer(question: Question) {

    question.questionAnswers.push({
      id: null,
      questionId: null,
      text: '',
      userId: null,
      companyId: null
    })

  }

  removeAnswer(question: Question, index: number) {

    question.questionAnswers.splice(index, 1);

  }

  newQuestion() {

    this.questions.push({
      id: null,
      isTextAnswer: false,
      questionAnswers: [
        {
          id: null,
          questionId: null,
          text: '',
          userId: null,
          companyId: null,
        },
        {
          id: null,
          questionId: null,
          text: '',
          userId: null,
          companyId: null
        }
      ],
      text: ''
    });

  }

  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  changeAnswerType(question: Question) {
    question.questionAnswers = [];
    this.newAnswer(question);

  }
  save() {

    var user = JSON.parse(localStorage.getItem("user"));


    var userGroupIdList: string = "";

    if (this.selectedUserGroups) {

      userGroupIdList = this.selectedUserGroups.map(u => u.id).join(';');

    }


    var entity: CreateSurveyRequestModel = {
      questions: this.questions,
      sessionId: null,
      surveyName: this.surveyName,
      token: null,
      isPublic: this.isPublic,
      publisherUserId: user.id,
      isReportable: this.isReportable,
      userGroupIds: userGroupIdList
    }

    this.isLoading = true;

    this.surveyService.createSurvey(entity).subscribe(res => {

      this.isLoading = false;

      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Anket Başarıyla Kaydedildi" });


    }, err => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;
    })


  }



}
