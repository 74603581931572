import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IntranetLesson } from 'src/app/models/learning.model';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-list-lesson-watcher',
  templateUrl: './list-lesson-watcher.component.html',
  styleUrls: ['./list-lesson-watcher.component.scss']
})
export class ListLessonWatcherComponent implements OnInit {

  lessons: IntranetLesson[] = [];

  selectedLessons: IntranetLesson[] = [];

  constructor(private learningService: LearningService, public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {

    this.getLessons();

  }

  getLessons() {

    this.learningService.getAllMyLessons().subscribe(r => this.lessons = r);

    // this.learningService.getAllLessons().subscribe(res => {

    //   console.log(res);

    //   this.lessons = res;

    // })

  }

  navigate(lesson: IntranetLesson) {

    this.router.navigate(['/learning/watch-lesson/' + lesson.id]);

  }

}
