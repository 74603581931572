import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
    selector: 'app-offer-status',
    templateUrl: 'offer-status.component.html'
})
export class OfferStatusComponent {
    // Aktivite'nin son durumu
    SubTypeText = {'pendingCustomer' : 'Müşteriye İletildi','inProgress':'Fiyatlandırma Aşamasında', 'pendingConfirm': 'Fiyatlandırma Yapıldı.', 'confirmed': 'Fiyat Onaylandı', 'inProgressCompleted': 'Fiyatlandırma Yapıldı', 'accepted': 'Kabul Edildi', 'rejected': 'Red Edildi', 'revisionRequest': 'Revizyon Yapıldı', 'pricing': 'Fiyatlandırma Bekleniyor'}
    @Input() data: any;
    @Output() closeWindow = new EventEmitter<boolean>();
}
