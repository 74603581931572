import {GenericEntity} from '../../generic-entitiy.model';
import { MinDTO } from '../../min-dto.model';
import {SimpleEntity} from '../../simple-entity.model';

export default class EntityUtil {

    public static toSimple<E extends GenericEntity>(entity: E): SimpleEntity {
        let simple = new SimpleEntity();
        simple.id = entity.id;
        simple.firm = entity.firm;
        simple.version = entity.version;
        simple.name = entity["name"];
        return simple;
    }
    public static toMinDTO<E extends GenericEntity>(entity: E): MinDTO {
        let min = new MinDTO();
        min.id = entity.id;
        min.name = entity["name"];
        return min;
    }

}
