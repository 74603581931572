import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from '@firebase/util';
import { MessageService } from 'primeng/api';
import { IntranetDocument } from 'src/app/models/intranet-document.model';
import { IntranetLesson, IntranetLessonVideo } from 'src/app/models/learning.model';
import { FileElement } from 'src/app/pages/document/documents/documents.component';
import { FileDialogConfirmComponent } from 'src/app/pages/document/modals/file-dialog-confirm/file-dialog-confirm.component';
import { IntranetDocumentService } from 'src/app/services/document.service';
import { LessonAssignmentDialogComponent } from '../lesson-assignment-dialog/lesson-assignment-dialog.component';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'app-create-lesson-video-dialog',
  templateUrl: './create-lesson-video-dialog.component.html',
  styleUrls: ['./create-lesson-video-dialog.component.scss']
})
export class CreateLessonVideoDialogComponent implements OnInit {

  folderName: string;

  lesson: IntranetLesson = <IntranetLesson>{};

  private basePath = '/uploads/learning/videos';

  uploadedFiles: any[] = [];

  files: File[] = [];

  entity: IntranetLessonVideo = <IntranetLessonVideo>{};

  videos: IntranetLessonVideo[] = [];

  uploadProgress: number = 0;

  constructor(public dialogRef: MatDialogRef<CreateLessonVideoDialogComponent>, private learningService: LearningService, @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage, public dialog: MatDialog,
    private messageService: MessageService) {

    this.lesson = this.data.lesson;

  }

  ngOnInit(): void {
    this.getLessonVideos();
  }

  getLessonVideos() {

    this.learningService.getVideosByLessonId(this.lesson.id).subscribe(res => {

      this.videos = res;


    })

  }

  onFileChange(pFileList: File[]) {

    var file = pFileList[0];
    Array.from(pFileList).map(f => f.name).forEach(fName => {
      let extenstion = fName.split('.').pop();
      if(['avi', 'mp4', 'flv', 'mkv'].includes(extenstion)) {
        this.uploadedFiles.push(file);
        const filePath = `${this.basePath}/${file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, file);

        uploadTask.percentageChanges().subscribe(res => {
          this.uploadProgress = Number(res.toFixed());
        })
        this.storage.upload(file.name, file).snapshotChanges().subscribe(res => {

          if (res.state == 'success') {

            storageRef.getDownloadURL().subscribe(url => {

              this.entity.videoUrl = url;

              this.setFileInfo(file);

              this.messageService.add({ severity: 'info', summary: 'Dosya Yüklendi', detail: '' });

              this.files.push(file);

            });

          }

        });
      } else {
        this.messageService.add({ key:'tst', severity: 'warn', summary: 'Dosya formatı hatalı', detail: `${fName} video formatında olmalıdır.` });
      }
    })

  }

  myVideos = [];



  setFileInfo(file: File) {
    this.myVideos.push(file);
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;

      var durationRounded = Math.round((duration + Number.EPSILON) * 100) / 100

      var durationMilliseconds = durationRounded * 1000;

      console.log('Duration: ' + durationMilliseconds);

      this.entity.videoDuration = durationMilliseconds;
    }

    video.src = URL.createObjectURL(file);;
  }

  deleteFile(f) {
    this.files = this.files.filter(function (w) { return w.name != f.name });
    this.messageService.add({ severity: 'info', summary: 'Dosya Silindi', detail: '' });
  }

  openConfirmDialog(pIndex): void {
    const dialogRef = this.dialog.open(FileDialogConfirmComponent, {
      panelClass: 'modal-xs'
    });
    dialogRef.componentInstance.fName = this.files[pIndex].name;
    dialogRef.componentInstance.fIndex = pIndex;


    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.deleteFromArray(result);
      }
    });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  deleteVideo(video: IntranetLessonVideo) {

    this.learningService.deleteVideo(video.id).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Video Silindi" });
      this.getLessonVideos();
    })

  }

  isLoading = false;

  save() {

    if (!(this.folderName && this.folderName.length > 0)) {

      this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: "Dosya İsmi Boş Geçilemez!" });
      return;

    }


    this.entity.name = this.folderName;

    this.entity.lessonId = this.lesson.id;

    this.learningService.createVideo(this.entity).subscribe(res => {

      this.isLoading = false;
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: "Video Kaydedildi" });

      this.getLessonVideos();

    }, err => {

      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Hata', detail: "Server Hatası" });
      this.isLoading = false;

    })

  }

  openAssigmentDialog(video: IntranetLessonVideo) {
    console.log(video, "video")
    const dialogRef = this.dialog.open(LessonAssignmentDialogComponent, {
      width: '1200px',
      panelClass: 'modal-xs',
      minHeight: '500px',
      data: {videoId: video.id}
    });

  }

}
