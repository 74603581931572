import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Company } from '../../../../models/company.model';
import { CompanyService } from '../../../../services/company.service';
import { ContainerService } from '../../../../services/container.service';
import { OfferExportService } from '../../../../services/offer-export.service';
import { DistrictMinimizedModel } from '../../../../models/district-minimized.model';
import { CityMinimizedModel } from '../../../../models/city-minimized.model';
import { CountryMinimizedModel } from '../../../../models/country-minimized.model';
import { Activity } from '../../../../models/activity.model';
import { SeaportMinimizedModel } from '../../../../models/SeaportMinimized.model';
import { DatePipe } from '@angular/common';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateOfferStatusDTO } from '../../../../models/update-offer-status-dto.model';
import { getbaseUrl } from 'ngx-doc-viewer';
import { MatDialog } from '@angular/material/dialog';
import { CreateCustomerComponent } from '../../../../shared/create-customer/create-customer.component';
import { ListService } from "../../../../services/list.service.service";
import b64toBlob from 'b64-to-blob';
import { UserService } from 'src/app/services/user.service';
import { GoodDefinationMinimized } from 'src/app/models/good-defination-minimized';
import {SeaContainerRezervations, SeaFrightRequestDetMobiles} from "../../../../models/sea-fright-request-det-mobiles";

@Component({
    selector: 'app-offer-export',
    templateUrl: './offer-export.component.html',
    styleUrls: ['./offer-export.component.scss'],
    providers: [ConfirmationService]
})

export class OfferExportComponent implements OnInit, OnDestroy {

    @ViewChild('fubauto') uploader: any;
    @Input() activityId: number;
    @Output() previousPage = new EventEmitter<boolean>();
    @Input() equimenets: any = [];
    @Input() shipOwner: Company;
    @Input() changeBuyPrice: boolean;
    @Input() cutOff: string;
    @Input() etd: string;
    @Input() pol: string;
    @Input() pod: string;

    transportDirection = 'E';
    editDtoKey = 'activitySeaFullEditDTO';
    stateOptions: any[];
    value1 = 'customerDetail';
    value2 = '';
    editForm: UntypedFormGroup;
    containerForm: UntypedFormGroup;
    reservationForm: UntypedFormGroup;
    createCustomerForm: UntypedFormGroup;
    items: UntypedFormArray;
    items2: UntypedFormArray;
    eqProduct: any = [];
    ctequiments: any = [];
    ctequimentsReservation: any = [];
    createdActivity: any;
    updateConfirm: any;
    reportList: any = [];
    digitalStore: any = [];
    offerName: any;
    isLoading = false;
    requestSucceed = false;
    saved = false;
    reservation = false;
    reservationId: any[] = [];
    makeReservationId: number;
    frontTariffDetMobile: any = [];
    frontTariffDetMobileChosen: any = [];
    endTariffDetMobile: any = [];
    endTariffDetMobileChosen: any = [];
    reReservation = false;
    displayBasic = false;
    isInvoiceItem: any;
    armatorNumber: any;
    displayFrontEndCarryList = false;
    displayMail = false;
    context = '';
    rejectReason: any;
    offerSubStatuse: any;
    report: any;
    file: any;
    editContainer: any[] = [];
    editContainer2: any[] = [];
    editContainerType: any;
    editContainerType2: any;
    firstEquimenetsLenght: number;
    msgs: Message[] = [];
    position: string;
    selectedFrontCarryTariff: any[] = [];
    selectedEndCarryTariff: any[] = [];
    copyCount = 1;
    offerNameId: any[] = [];
    sellOrBuyInvoice = false;
    invoice: any;
    invoiceCurrency: any;
    invoiceAmount = 0;
    invoiceBuyamount = 0;
    isFreightCharge = false;
    itemCompany: any;
    invoiceType = 'IN';
    offerStatus = 'pricing';
    containerTonnageChecked = false;
    offerUpdated = false;
    containerTonnageSame = [];
    showPreviousBtn = false;
    listResultItem: any = null;
    showDetailDialog: boolean = false;
    displaySeaGoodDetail: boolean = false;

    filteredCompanies: Company[] = [];
    companies: Company[] = [];

    filteredContainers: string[] = [];
    containers: any[] = [];
    containersId: number[] = [];

    filteredDistrictMinimized: DistrictMinimizedModel[] = [];
    district: DistrictMinimizedModel[] = [];

    filteredCityMinimized: CityMinimizedModel[] = [];
    city: CityMinimizedModel[] = [];

    filteredGoodDefinationType: GoodDefinationMinimized[] = [];
    filteredGoodDefinationType2: GoodDefinationMinimized[] = [];

    filteredCountryMinimized: CountryMinimizedModel[] = [];
    country: CountryMinimizedModel[] = [];

    filteredSeaport: SeaportMinimizedModel[] = [];
    seaport: SeaportMinimizedModel[] = [];

    filteredUsers: any[] = [];
    filteredPricingPerson: any[] = [];

    lastFilteredGoods: any[] = [];
    childGoods: any[] = [];

    isPriceEditable: boolean = true;

    transportType: string[] = ['PTP', 'DTP', 'PTD', 'DTD'];
    transport: string[] = ['PTP', 'DTP', 'PTD', 'DTD'];

    offerType: any[] = [{ json: 'allin', name: 'ALLIN TEKLİF' }, { json: 'general', name: 'GENEL TEKLİF' }, { json: 'barem', name: 'BAREMLİ TEKLİF' }];
    offer: any[] = [{ json: 'allin', name: 'ALLIN TEKLİF' }, { json: 'general', name: 'GENEL TEKLİF' }, { json: 'barem', name: 'BAREMLİ TEKLİF' }];

    countType: any[] = [{ json: 'perContainer', name: 'Konteyner Başı' }, { json: 'perHouse', name: 'Yük Başı' }, { json: 'WM', name: 'WM' }, { json: 'per20', name: '20 lik' }, { json: 'per40', name: '40 lık' }, { json: 'perMaster', name: 'Master Başı' }, { json: 'freightSalesPercent', name: 'Navlun Satış Yüzdesi' }];
    count: any[] = [{ json: 'perContainer', name: 'Konteyner Başı' }, { json: 'perHouse', name: 'Yük Başı' }, { json: 'WM', name: 'WM' }, { json: 'per20', name: '20 lik' }, { json: 'per40', name: '40 lık' }, { json: 'perMaster', name: 'Master Başı' }, { json: 'freightSalesPercent', name: 'Navlun Satış Yüzdesi' }];
    invoiceCountType = this.count[0];

    portFillingType: any[] = [{ json: true, name: 'LİMAN DOLUM' }, { json: false, name: 'DIŞ DOLUM' }];
    portFilling: any[] = [{ json: true, name: 'LİMAN DOLUM' }, { json: false, name: 'DIŞ DOLUM' }];

    incotermType: string[] = ['CFR', 'CIF', 'CTP', 'CPT', 'DAF', 'DAP', 'DAT', 'DDP', 'DDU', 'DEO', 'DES', 'DPU', 'EXW', 'FAS', 'FCA', 'FOB'];
    incoterm: string[] = ['CFR', 'CIF', 'CTP', 'CPT', 'DAF', 'DAP', 'DAT', 'DDP', 'DDU', 'DEO', 'DES', 'DPU', 'EXW', 'FAS', 'FCA', 'FOB'];

    businessType: any[] = [{ type: 'B', name: 'Bizim' }, { type: 'A', name: 'Acenta' }];
    business: any[] = [{ type: 'B', name: 'Bizim' }, { type: 'A', name: 'Acenta' }, { type: 'O', name: 'Ortak' }];

    calculateUnitOpt = [{ code: 'B', name: 'Barem' }, { code: 'SB', name: 'Sabit Fiyat Barem' }, { code: 'K', name: 'Komple' }, { code: 'M', name: 'Minimum' }];

    freightPaymentType: any[] = ['CC', 'PP'];
    salesChannel: any[] = [{ code: 'ST', name: 'Sales Team' }, { code: 'BD', name: 'BusinessDevelopment' }, { code: 'TM', name: 'Telemarketing' }, { code: 'DM', name: 'Digital Marketing' }, { code: 'NVO', name: 'NVOCC' }];

    invoiceTypes: any[] = [{ value: 'CN', label: 'Alacak Dekontu' }, { value: 'DN', label: 'Borç Dekontu' }, { value: 'IN', label: 'Fatura' }, { value: 'RI', label: 'İade Fatura' }, { value: 'IR', label: 'İç Dekont' }, { value: 'OC', label: 'Operasyon Gider' }];

    currencyType: any[];
    currency: any[];

    SubTypeText = { pendingConfirm: 'Bekleniyor', confirmed: 'Rezervasyon Yapıldı', inProgressCompleted: 'Fiyatlandırma Yapıldı', accepted: 'Kabul Edildi', rejected: 'Red Edildi', revisionRequest: 'Revizyon Yapıldı', pricing: 'Fiyatlandırma Bekleniyor' };

    invoiceItemType: any[];
    invoiceItem: any[];

    operationRepresentativeList: any[];
    operationRepresentative: any[];

    showStatusHistory = false;
    offerStatusHistory: any;

    isCopyOffer: boolean = false;
    isPricing: boolean = false;
    tempActivity: any;

    seaFrightRequestDetMobiles: SeaFrightRequestDetMobiles[];
    seaContainerRezervations: any[];
    newSeaFrightRequestDetMobiles(seaContainerType: any): any {
        return {
            id: null,
            name: null,
            vessel: null,
            cutOffDate: null,
            amount: null,
            buyAmount: null,
            customer: this.editForm.value?.customer,
            shipOwner: null,
            fromPort: this.editForm.value?.fromSeaPort,
            toPort: this.editForm.value?.toSeaPort,
            currency: null,
            seaContainerRezervations: []
        };
    }

    constructor(private formBuilder: UntypedFormBuilder, private companyService: CompanyService, private containerService: ContainerService,
        private offerExportService: OfferExportService, public datepipe: DatePipe, private messageService: MessageService,
        private activatedRoute: ActivatedRoute, private route: Router, private router: ActivatedRoute, private confirmationService: ConfirmationService,
        private dialog: MatDialog, private listService: ListService, private userService: UserService
    ) {
        this.stateOptions = [
            { label: 'customerDetail', value: 'customerDetail' }];

        this.editForm = this.formBuilder.group({
            id: ['', []],
            name: ['', []],
            customer: ['', [Validators.required]],
            sender: ['', [Validators.required]],
            etd: ['', []],
            cutOffDate: ['', []],
            goodDefination: ['', []],
            goodDefinationType: ['', []],
            salesRepresentative: [null, []],
            pricingPerson: [null, []],
            shipOwner: ['', [this.changeBuyPrice === true ? Validators.required : Validators.nullValidator]],
            shipOwnerNotShow: ['', []],
            customerNote: ['', []],
            fak: ['', []],
            imo: ['', []],
            classInfo: ['', []],
            unNo: ['', []],
            transportDirection: ['E', []],
            flashPoint: ['', []],
            equipments: this.formBuilder.array([]),
            delivery: ['PTP', []],
            endDate: [''],
            validTo: ['', [Validators.required]],
            portFilling: ['', []],
            notes: ['', []],
            incoterm: [null, [Validators.required]],
            toSeaPort: [this.eqProduct?.length ? this.eqProduct[0]?.toPort : this.pod, [Validators.required]],
            fromSeaPort: [this.eqProduct?.length ? this.eqProduct[0]?.fromPort : this.pol, [Validators.required]],
            offerType: [{ json: 'general', name: 'GENEL TEKLİF' }, []],
            loading: ['', []],
            loadCountry: ['', []],
            loadCity: ['', []],
            loadDistrict: ['', []],
            loadZip: ['', []],
            loadAddress: ['', []],
            unloading: ['', []],
            unloadCountry: ['', []],
            unloadCity: ['', []],
            unloadDistrict: ['', []],
            unloadZip: ['', []],
            unloadAddress: ['', []],
            seaFrightRequestDetMobiles: this.seaFrightRequestDetMobiles,

        });
        this.containerForm = this.formBuilder.group({
            containers: [[], []]
        });
        this.seaFrightRequestDetMobiles =  [this.newSeaFrightRequestDetMobiles(null)];

        if (this.route.getCurrentNavigation()?.extras?.state) {
            this.etd = this.route.getCurrentNavigation()?.extras?.state?.etd;
            this.pol = this.route.getCurrentNavigation()?.extras?.state?.pol;
            this.pod = this.route.getCurrentNavigation()?.extras?.state?.pod;
        }


        this.tempActivity = this.route.getCurrentNavigation()?.extras?.state?.activity;
        this.isCopyOffer = this.route.getCurrentNavigation()?.extras?.state?.isCopyOffer;
        this.isPricing = this.route.getCurrentNavigation()?.extras?.state?.changeBuyPrice;
    }

    async ngOnInit() {
        if (this.activatedRoute.snapshot.params.transportDirection && this.activatedRoute.snapshot.params.transportDirection === 'import') {
            this.editDtoKey = 'activitySeaFullImportEditDTO';
        }
        if (this.activatedRoute.snapshot.params.transportDirection && this.activatedRoute.snapshot.params.transportDirection === 'export') {
            this.editDtoKey = 'activitySeaFullEditDTO';
        }

        this.transferDataFromEquipmenets();


        if (this.activatedRoute.snapshot.routeConfig.path == 'activities/offer/list/:transportDirection') {
            this.editForm.controls["shipOwner"].clearValidators()
        }




        this.reservationForm = this.formBuilder.group({
            senderId: [this.editForm?.get(['customer'])?.value, [Validators.required]],
            consigneId: ['', []],
            abroadAgentId: ['', [Validators.required]],
            operationRepresentativeId: ['', [Validators.required]],
            whoseBusiness: ['', [Validators.required]],
            operationNote: ['', []],
            masterOnly: ['', []],
            salesChannel: ['', [Validators.required]],
            freightPayment: ['', []],
            equipments: this.formBuilder.array([])
        });


        if (this.activatedRoute.snapshot.params.id !== undefined || this.activityId) {

            this.requestSucceed = true;
            this.saved = true;

            this.offerExportService.getMyActivityById(this.activatedRoute.snapshot.params.id || this.activityId).subscribe(res => {

                this.createdActivity = res.body;
                if (!this.createdActivity[this.editDtoKey]) {
                    this.editDtoKey = 'activitySeaFullImportEditDTO';
                }
                this.offerNameId.push(res.body);
                this.equimenets = this.createdActivity.seaAllocationEditDTOs;
                this.editForm.patchValue({
                    id: this.createdActivity.id,
                    name: this.createdActivity.name,
                    customer: this.createdActivity.customer,
                    sender: this.createdActivity.sender,
                    etd: new Date(this.createdActivity.etd),
                    cutOffDate: new Date(this.createdActivity.cutOffDate),
                    goodDefination: this.createdActivity.goodDefination,
                    goodDefinationType: this.createdActivity.goodDefinationType,
                    customerNote: this.createdActivity.customerNote,
                    salesRepresentative: this.createdActivity.salesRepresentative,
                    pricingPerson: this.createdActivity.pricingPerson,
                    shipOwner: this.createdActivity.shipOwner,
                    shipOwnerNotShow: this.createdActivity.shipOwnerNotShow,
                    fak: this.createdActivity.fak,
                    imo: this.createdActivity.imo,
                    classInfo: this.createdActivity.classInfo,
                    unNo: this.createdActivity.unNo,
                    flashPoint: this.createdActivity.flashPoint,
                    equipments: this.createdActivity.equipments,
                    delivery: this.createdActivity.delivery,
                    endDate: this.createdActivity.endDate ? new Date(this.createdActivity.endDate) : null,
                    validTo: this.createdActivity.validTo ? new Date(this.createdActivity.validTo) : null,
                    portFilling: this.createdActivity.portFilling === '' ? '' : (this.createdActivity.portFilling === true ? { json: true, name: 'LİMAN DOLUM' } : { json: false, name: 'DIŞ DOLUM' }),
                    notes: this.createdActivity.notes,
                    incoterm: this.createdActivity.incoterm,
                    toSeaPort: this.createdActivity.toSeaPort,
                    fromSeaPort: this.createdActivity.fromSeaPort,
                    offerType: this.createdActivity.offerType === 'allin' ? { json: 'allin', name: 'ALLIN TEKLİF' } : { json: 'general', name: 'GENEL TEKLİF' },
                    loading: this.createdActivity.loading,
                    loadCountry: this.createdActivity.loadCountry,
                    loadCity: this.createdActivity.loadCity,
                    loadDistrict: this.createdActivity.loadDistrict,
                    loadZip: this.createdActivity.loadZip,
                    loadAddress: this.createdActivity.loadAddress,
                    unloading: this.createdActivity.unloading,
                    unloadCountry: this.createdActivity.unloadCountry,
                    unloadCity: this.createdActivity.unloadCity,
                    unloadDistrict: this.createdActivity.unloadDistrict,
                    unloadZip: this.createdActivity.unloadZip,
                    unloadAddress: this.createdActivity.unloadAddress
                });
                this.equimenets = [];
                this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs.forEach(product => {
                    product.buyOfferSeaChargesDTOs.forEach((by, index) => { Object.assign(by, { buyAmount: product.offerSeaChargesDTOs[index]?.amount }); });
                    product.activityOperationDetailEditDTOs.forEach(tr => { Object.assign(tr, { seaTariffDetMobiles: product.buyOfferSeaChargesDTOs }); Object.assign(tr, { selected: true }); });
                    product.seaGoods.forEach(rz => {
                        this.makeReservationId = product.offerSeaDetailId;
                        this.reservationId.push(rz);
                        if (rz.positionId != null) {
                            this.isPriceEditable = false;
                        }
                        else {
                            this.isPriceEditable = true;
                        }
                    });
                    this.equimenets.push({
                        project: {
                            currency: product.totalCurrency,
                            cutOffDate: product.etd,
                            fromPort: product.fromPort,
                            toPort: product.toPort,
                            seaContainerRezervations: product.activityOperationDetailEditDTOs,
                            shipOwner: product.shipOwner,
                            vessel: product.vessel
                        }
                    });

                    this.containerService.getAll().subscribe(container => {
                        container.forEach(ct => {
                            this.containers.push(ct);
                            this.filteredContainers.push(ct.name);
                            this.containersId.push(ct.id);
                        });
                        if (this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs.indexOf(product) === 0) {
                            this.createdActivity.equipments.map(c => (this.containerForm.get('containers') as UntypedFormArray).push(this.createContainerFormControl(c)));
                            this.firstEquimenetsLenght = this.createdActivity?.equipments?.length;
                        }
                        if (this.reservationId?.length) {
                            product.seaGoods[product.seaGoods?.length - 1]?.seaContainerDetails?.forEach(ct => {
                                (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(this.lastReservationContainers(ct)));
                            });
                            this.reservationForm.patchValue(this.reservationId[this.reservationId?.length - 1]);
                            this.reservationForm.patchValue({ whoseBusiness: this.businessType.find(bs => bs.type === this.reservationId[this.reservationId?.length - 1].whoseBusiness).type });

                        }
                    });
                });
                this.createdActivity[this.editDtoKey].seaAllocationEditDTOs = this.createdActivity[this.editDtoKey].seaAllocationEditDTOs.reverse();
                this.transferDataFromEquipmenets();
                if (this.equimenets?.length) {
                    this.updateForm();
                }
                this.containerForm = this.formBuilder.group({
                    containers: this.formBuilder.array([])
                });
                this.updateConfirm = JSON.stringify(this.createFromForm());
            });
        }

        this.createCustomerForm = this.formBuilder.group({
            name: ['', []],
            districtId: ['', []],
            cityId: ['', []],
            countryId: ['', []],
            addressDetail: ['', []],
            phoneNo: ['', []],
            notes: ['', []],
        });
        if (this.activatedRoute.snapshot.params.id === undefined && !this.activityId) {

            const user = this.userService.getUserByTransportDirection('E');
            this.editForm.patchValue({ salesRepresentative: user })
            this.editForm.patchValue({ pricingPerson: user })
            if (this.equimenets?.length) {
                this.updateForm();
            }

            if (this.isCopyOffer) {
                this.editForm.patchValue({
                    id: 0,
                    customer: this.tempActivity.customer,
                    sender: this.tempActivity.sender,
                    endDate: this.tempActivity.endDate ? new Date(this.tempActivity.endDate) : null,
                    validTo: this.tempActivity.validTo ? new Date(this.tempActivity.validTo) : null,
                    goodDefination: this.tempActivity.goodDefination ? this.tempActivity.goodDefination : '',
                    goodDefinationType: this.tempActivity.goodDefinationType ? this.tempActivity.goodDefinationType : '',
                    shipOwner: this.tempActivity.shipOwner,
                    fak: this.tempActivity.fak,
                    imo: this.tempActivity.imo,
                    salesRepresentative: this.tempActivity.salesRepresentative,
                    pricingPerson: this.tempActivity.pricingPerson,
                    delivery: this.tempActivity.delivery,
                    portFilling: this.tempActivity.portFilling === '' ? '' : (this.tempActivity.portFilling === true ? { json: true, name: 'LİMAN DOLUM' } : { json: false, name: 'DIŞ DOLUM' }),
                    notes: this.tempActivity.notes,
                    incoterm: this.tempActivity.incoterm,
                    toSeaPort: this.tempActivity.toSeaPort,
                    fromSeaPort: this.tempActivity.fromSeaPort,
                    offerType: this.tempActivity.offerType === 'allin' ? { json: 'allin', name: 'ALLIN TEKLİF' } : { json: 'general', name: 'GENEL TEKLİF' },
                    equipments: this.tempActivity.equipments,
                    loading: this.tempActivity.loading,
                    loadCountry: this.tempActivity.loadCountry,
                    loadCity: this.tempActivity.loadCity,
                    loadDistrict: this.tempActivity.loadDistrict,
                    loadZip: this.tempActivity.loadZip,
                    loadAddress: this.tempActivity.loadAddress,
                    unloading: this.tempActivity.unloading,
                    unloadCountry: this.tempActivity.unloadCountry,
                    unloadCity: this.tempActivity.unloadCity,
                    unloadDistrict: this.tempActivity.unloadDistrict,
                    unloadZip: this.tempActivity.unloadZip,
                    unloadAddress: this.tempActivity.unloadAddress
                })
            }

            if (this.isCopyOffer) {
                this.equimenets = [];
                this.tempActivity[this.editDtoKey]?.seaAllocationEditDTOs.forEach(product => {
                    this.equimenets.push({
                        project: {
                            seaContainerRezervations: product.activityOperationDetailEditDTOs,
                        }
                    });
                });
                this.changeBuyPrice = this.isPricing;
            }

            this.containerForm = this.formBuilder.group({ containers: this.formBuilder.array([]) });

            this.containerService.getAll().subscribe(res => {
                res.forEach(ct => {
                    this.containers.push(ct);
                    this.filteredContainers.push(ct.name);
                    this.containersId.push(ct.id);
                });
            });

            const uniqueContainers = this.getUniqueContainerByType(this.equimenets);
            uniqueContainers.map(c => (this.containerForm.get('containers') as UntypedFormArray).push(this.createContainerFormControl(c)));
            this.firstEquimenetsLenght = uniqueContainers?.length;

            if (!this.isCopyOffer) {
                this.eqProduct?.length ? this.editForm.patchValue({ toSeaPort: this.eqProduct[0]?.toPort }) : this.editForm.patchValue({ toSeaPort: this.pod })
                this.eqProduct?.length ? this.editForm.patchValue({ fromSeaPort: this.eqProduct[0]?.fromPort }) : this.editForm.patchValue({ fromSeaPort: this.pol })
                this.eqProduct?.length ? this.editForm.patchValue({ endDate: this.eqProduct[0]?.endDate }) : this.editForm.patchValue({ endDate: this.etd })
            }
        }
        await this.createNewGoodDefinationList()
        this.equimenets = [];
    }

    createNewGoodDefinationList() {
        this.offerExportService.filterGoodDefinationType().subscribe(res => {
            this.filteredGoodDefinationType = res.body
            let topGoodType = this.filteredGoodDefinationType.filter(good => good.topGoodType == null)
            topGoodType.forEach(res => {
                this.filteredGoodDefinationType.forEach(good => {
                    if (good.topGoodType != null && (good.topGoodType.id == res.id)) {
                        this.childGoods.push(good);
                    }
                })
            })
            this.childGoods.forEach(childGood => {
                this.filteredGoodDefinationType.forEach(good => {
                    if (good.topGoodType != null && (childGood.id == good.topGoodType.id)) {
                        this.lastFilteredGoods.push(good)
                    }
                })
            })
            this.filteredGoodDefinationType = this.lastFilteredGoods
            this.filteredGoodDefinationType2 = this.lastFilteredGoods
        })
    }

    ngOnDestroy(): void {
        this.equimenets = [];
        this.eqProduct = [];
        this.editForm.reset();
    }

    lastReservationContainers(eq: any) {
        return { seaContainerType: eq.containerType, count: eq.quantity };
    }

    filterGoodDefinationType(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.filteredGoodDefinationType.forEach(goodTypeDef => {
            if (goodTypeDef.name.toLowerCase().includes(query.toLowerCase())) {
                filtered.push(goodTypeDef);
            }
        });
        this.filteredGoodDefinationType2 = filtered;
    }

    filterUsers(event) {
        if (event.query.lenght < 3) return;
        this.userService.searchUsers(event.query, null).subscribe(res => this.filteredUsers = res);
    }
    filterPricingPerson(event) {
        if (event.query.lenght < 3) return;
        this.userService.searchUsers(event.query, null, 'ACTIVE').subscribe(res => this.filteredPricingPerson = res);
    }

    transferDataFromEquipmenets() {
        if (this.equimenets?.length) {
            this.getProductFromContainer();
            if (this.activatedRoute.snapshot.params.id === undefined || this.createdActivity?.offerStatus === 'onlinequote' || this.createdActivity?.offerStatus === 'inProgressCompleted' || this.createdActivity?.offerStatus === 'confirmed' || this.createdActivity?.offerStatus === 'accepted') {
                this.addLabels({ label: 'priceInformation', value: 'priceInformation' });
                if (this.createdActivity?.offerStatus === 'confirmed' || this.createdActivity?.offerStatus === 'accepted') {
                    this.addLabels({ label: 'reservation', value: 'reservation' });
                }
            }

            this.equimenets.forEach(q => {
                q.project.seaContainerRezervations.forEach(s => {
                    if (!s.fareAmount) { s.fareAmount = s.buyFareAmount; }
                    s?.seaTariffDetMobiles?.forEach(f => {
                        if (!f.amount) { f.amount = f.buyAmount; }
                    });
                });
            });
        }
    }

    checkValidation() {
        let error;
        for (const item in this.editForm.controls) {
            if (this.editForm?.controls[item]?.status === 'INVALID') {
                error = 'Zorunlu alanlar doldurulmalı!';
                this.editForm?.get(item)?.markAsDirty();
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + item.toUpperCase() + ' alan doldurulmalı!' });
            }
        }

        if (this.activatedRoute.snapshot.params.id !== undefined && this.editForm.get('goodDefination').value == '') {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!' });
            return false;
        }
        if (this.activatedRoute.snapshot.params.id === undefined && this.editForm.get('goodDefinationType').value == '') {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!' });
            return false;
        }
        if (this.activatedRoute.snapshot.params.id !== undefined && this.editForm.get('goodDefinationType').value == '') {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + 'Mal cinsi doldurulmalı!' });
            return false;
        }
        if (this.checkQuantitiyValidation()){
            return false;
        }
        if (error) {
            this.isLoading = false;
            throw new Error(error);
        }
        return true;
    }
    checkQuantitiyValidation(): boolean{
        let hasError = false;
        if (this.containerForm.valid) {
            const containersFormArray = this.containerForm.get('containers') as UntypedFormArray;
            if (this.activatedRoute.snapshot.params.id === undefined && (containersFormArray.controls == null || containersFormArray.controls.length === 0)){
                this.messageService.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: 'Hata',
                    detail: `Konteyner Tipi, Miktar, Tonaj alanlarının doldurulması zorunludur!`
                });
                hasError =  true;
            }
            containersFormArray.controls.forEach((containerGroup: UntypedFormGroup, index: number) => {
                const countControl = containerGroup.get('count') as UntypedFormControl;
                const tonnageControl = containerGroup.get('tonnage') as UntypedFormControl;
                const seaContainerTypeControl = containerGroup.get('seaContainerType') as UntypedFormControl;
                if(this.activatedRoute.snapshot.params.id === undefined){
                    if (tonnageControl.value == null || tonnageControl.value === '' || tonnageControl.value === 0){
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: 'Hata',
                            detail: `Müşteri Bilgileri sayfasında ${index + 1}. sıradaki konteyner için, Tonaj boş veya 0 olamaz!`
                        });
                        hasError =  true;
                    }
                    if (seaContainerTypeControl.value == null || seaContainerTypeControl.value == ''){
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: 'Hata',
                            detail: `Müşteri Bilgileri sayfasında ${index + 1}. sıradaki konteyner için, Konteyner Tipi boş olamaz!`
                        });
                        hasError =  true;
                    }
                    if (countControl.value == null || countControl.value === 0){
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: 'Hata',
                            detail: `Müşteri Bilgileri sayfasında ${index + 1}. sıradaki konteyner için, Miktar boş veya 0 olamaz!`
                        });
                        hasError =  true;
                    }
                }
                if (countControl.value != null && countControl.value > 100) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'warn',
                        summary: 'Hata',
                        detail: `Müşteri Bilgileri sayfasında ${index + 1}. sıradaki konteyner için, Miktar 100'den büyük olamaz!`
                    });
                    hasError =  true;
                }
            });
        }
        if (this.reservationForm.valid) {
            const equipmentsFormArray = this.reservationForm.get('equipments') as UntypedFormArray;
            equipmentsFormArray.controls.forEach((equipmentGroup: UntypedFormGroup, index: number) => {
                const quantityControl = equipmentGroup.get('count') as UntypedFormControl;

                if (quantityControl.value != null && quantityControl.value > 100) {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'warn',
                        summary: 'Hata',
                        detail: `Rezervasyon  sayfasında ${index + 1}. sıradaki konteyner için, Miktar 100'den büyük olamaz!`
                    });
                    hasError =  true;
                }
            });
        }
        return hasError;
    }

    sendRequest() {
        if (!this.checkValidation()) return;
        if (this.editForm?.get(['customer'])?.value !== '' && this.editForm?.get(['sender'])?.value !== '' && this.editForm?.get(['validTo'])?.value !== ('' && null)) {
            this.isLoading = true;
            this.saved = true;
            this.offerStatus = 'inProgressCompleted';
            this.fillCtequimentsWithContainerForm();
            this.ctequiments.forEach(ct => delete ct.id);

            this.offerExportService.createActivity(this.createFromForm()).subscribe(res => {
                this.createdActivity = res.body;
                this.offerNameId.push(res.body);
                this.createdActivity.offerStatus = 'inProgressCompleted';
                this.containerForm.get('containers').patchValue(this.createdActivity.equipments);
                this.offerExportService.getReportList(res.body[this.editDtoKey]?.offerId).subscribe(rp => {
                    this.reportList = rp.body;
                });
                this.isLoading = false;
                this.requestSucceed = true;
                this.value1 = 'Fiyat Bilgileri';
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Kaydedildi' });
            }, error => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
            });
        }
    }

    getReportList() {
        if (this.reportList?.length >= 0) {
            this.offerExportService.getReportList(this.createdActivity[this.editDtoKey]?.offerId).subscribe(rp => {
                this.reportList = rp.body;
            });
        }
    }

    getDigitalArsiv() {
        this.isLoading = true;
        this.offerExportService.getDigitalArsiv(this.createdActivity[this.editDtoKey]?.offerId, 'Offer').subscribe(res => {
            this.digitalStore = res.body;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    getDigitalDocument(report: any) {
        this.isLoading = true;
        this.offerExportService.getDocumentType(report?.id, 'E').subscribe(res => {
            this.offerExportService.getDigitalDocument(res.body.reference, 'E').subscribe(gt => {
                this.isLoading = false;
                const file = b64toBlob(gt.body.obj.file.data, res.body.contentType);
                const fileUrl = window.URL.createObjectURL(file);
                if (res.body.contentType === 'application/pdf' || res.body.contentType.startsWith('image')) {
                    window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
                } else {
                    const nav = (window.navigator as any)
                    if (nav && nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(gt.body.obj.file.data, res.body.contentType);
                    } else {
                        var a = document.createElement('a');
                        a.href = URL.createObjectURL(file);
                        a.download = res.body.fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
            }, error => {
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
                this.isLoading = false;
            });
        }, error => {
            this.isLoading = false;
        });
    }

    uploadDocumentsCheck(documents: any) {
        console.log(documents);
        if (documents.files && documents.files.length > 0) {
            for (const file of documents.files) {
                if (file.size <= 15728640) {
                    this.uploadDocuments(file);
                } else {
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.getAsFile().size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
                }
            }

        } else if (documents.files && documents.files.length > 0) {
            for (const file of documents.files) {
                if (file.getAsFile().size <= 15728640) {
                    this.uploadDocuments(file);
                } else {
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: file.size >= 15728640 ? `Belge Boyut Hatası(Limit 15MB). Dosyanız (${(file.size / 1048576).toFixed(2)}MB) boyutundadır` : '' });
                }
            }
        }
    }

    uploadDocuments(file: any) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', file);
        this.offerExportService.uploadDocuments(formData, this.createdActivity?.offerId).subscribe(res => {
            const dct = res.body.res;
            this.offerExportService.getPagesByName('/offers/offer').subscribe(pages => {
                const pageObject = pages.body.find(pg => pg.name === 'Teklif');
                if (res.status === 200) {
                    this.offerExportService.saveDocument({
                        entityid: +dct.entityid,
                        reference: dct.uid,
                        contentType: dct.contentType,
                        fileName: dct.fileName,
                        fileSize: dct.fileSize,
                        page: pageObject,
                        sourceType: 'Offer'
                    }).subscribe(sv => {
                        this.getDigitalArsiv();
                        this.uploader.clear();
                    });
                    // this.user = this.cacheService.get(Storage.USER);
                    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Evrak Başarıyla Kaydedildi' });
                }
            }, error => {
                this.isLoading = false;
            });
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    updateRequest(makeHardCopyReservation?: boolean, isOnlyUpdate?: boolean) {

        if (isOnlyUpdate) {
            if (!this.checkValidation()) {
                this.offerUpdated = false;
                return;
            }
        } else {
            if (!this.checkAllCompanies() || !this.checkValidation()) {
                this.offerUpdated = false;
                return;
            }
        }


        this.isLoading = true;
        if (this.editForm?.get(['customer'])?.value !== '' && this.editForm?.get(['sender'])?.value !== '' && this.editForm?.get(['validTo'])?.value !== ('' && null)) {
            this.ctequiments = [];
            this.fillCtequimentsWithContainerForm();
            this.offerExportService.updateActivity(this.createFromForm()).subscribe(res => {
                this.editDtoKey = res.body.activitySeaFullEditDTO ? 'activitySeaFullEditDTO' : 'activitySeaFullImportEditDTO';
                this.offerExportService.getReportList(res.body[this.editDtoKey]?.offerId).subscribe(rp => {
                    this.reportList = rp.body;
                });
                this.createdActivity = res.body;
                this.containerForm.patchValue({ container: res.body.equipments });
                this.updateConfirm = JSON.stringify(this.createFromForm());
                this.isLoading = false;

                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Güncellendi' });
                if (makeHardCopyReservation) {
                    this.makeHardCopyReservation();
                }
            }, error => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
            });
        }
    }

    changePageReservation(index: number) {
        if (this.isSellAmountEmpty()) {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Satış Tutarları girilmemiş' })
            return;
        }
        this.makeReservationId = this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[this.eqProduct?.length - 1 - index].offerSeaDetailId;
        this.isLoading = true;
        this.reservation = true;
        if (this.createdActivity?.offerStatus !== 'confirmed' && this.createdActivity?.offerStatus !== 'accepted') {
            this.addLabels({ label: 'reservation', value: 'reservation' });
        }
        (this.containerForm.get('containers') as UntypedFormArray).controls.forEach(ct => {
            (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(ct.value));
        });
        this.reservationForm.patchValue({
            senderId: this.editForm?.get(['sender'])?.value,
        });
        this.isLoading = false;
        this.value1 = 'reservation';
        const scrollToPayment = window.setTimeout(() => {
            // this.scrollToElement("beginning");
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }, 4);
    }

    private updateForm() {
        this.editForm.patchValue({
            shipOwner: this.eqProduct[0].shipOwner,
            cutOffDate: this.cutOff
        });

        this.equimenets.forEach(w => {
            const datacontainer = w.project.seaContainerRezervations.filter(e => e.selected);
        });
    }

    getProductFromContainer() {
        this.equimenets.forEach(eq => {
            eq.project.seaContainerRezervations.forEach(ct => {
                Object.assign(ct, { currency: eq.project.currency });
            });
            const selectedContainer = eq.project.seaContainerRezervations.filter(e => e.selected);
            eq.project.seaContainerRezervations = selectedContainer;
            this.eqProduct.push(eq.project);
        });
        this.cutOff = this.datepipe.transform(this.eqProduct[0].cutOffDate, 'yyyy-MM-dd');
    }

    private createFromForm(): Activity {
        if(this.seaFrightRequestDetMobiles && this.seaFrightRequestDetMobiles.length > 0 && this.seaFrightRequestDetMobiles[0].seaContainerRezervations.length > 0  ) {
            this.seaFrightRequestDetMobiles.forEach(data => {
                if (!data.currency){
                    this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: 'Lütfen para birimi seçin.' });
                    return;
                }
            })
        }
        if(this.seaFrightRequestDetMobiles) {

            this.seaFrightRequestDetMobiles.forEach(f => {
                f.customer = this.editForm.get(['customer'])!.value;
                f.seaContainerRezervations = this.seaFrightRequestDetMobiles[0].seaContainerRezervations;
            });
        }
        return {
            ...new Activity(),
            id: this.createdActivity?.id,
            name: this.createdActivity?.name,
            offerStatus: this.createdActivity?.offerStatus ? this.createdActivity?.offerStatus : this.offerStatus,
            subType: this.createdActivity?.subType,
            customer: this.editForm.get(['customer'])!.value,
            sender: this.editForm.get(['sender'])!.value,
            etd: this.datepipe.transform((this.editForm?.get(['etd'])?.value === (undefined || null || '') ? Date.now() : this.editForm?.get(['etd'])?.value), 'yyyy-MM-dd HH:mm:ss'),
            startDate: this.createdActivity ? this.datepipe.transform(this.createdActivity?.startDate, 'yyyy-MM-dd HH:mm:ss') : this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            endDate: this.datepipe.transform(this.editForm?.get(['endDate'])?.value, 'yyyy-MM-dd HH:mm:ss'),
            validTo: this.datepipe.transform(this.editForm?.get(['validTo'])?.value, 'yyyy-MM-dd HH:mm:ss'),
            goodDefination: this.editForm.get(['goodDefination'])!.value,
            goodDefinationType: this.editForm.get(['goodDefinationType'])!.value,
            operationType: 'SEA',
            salesRepresentative: this.editForm.get(['salesRepresentative'])!.value === (undefined || null || '') ? null : this.editForm.get(['salesRepresentative'])!.value,
            pricingPerson: this.editForm.get(['pricingPerson'])!.value === (undefined || null || '') ? null : this.editForm.get(['pricingPerson'])!.value,
            shipOwner: this.editForm.get(['shipOwner'])!.value === (undefined || null || '') ? null : this.editForm.get(['shipOwner'])!.value,
            portFilling: this.editForm.get(['delivery'])!.value === 'PTP' ? this.editForm.get(['portFilling'])!.value.json : '',
            shipOwnerNotShow: this.editForm.get(['shipOwnerNotShow'])!.value,
            fak: this.editForm.get(['fak'])!.value,
            imo: this.editForm.get(['imo'])!.value,
            classInfo: this.editForm.get(['classInfo'])!.value,
            transportDirection: 'E',
            unNo: this.editForm.get(['unNo'])!.value,
            flashPoint: this.editForm.get(['flashPoint'])!.value,
            equipments: this.ctequiments,
            seaAllocations: [],
            delivery: this.editForm.get(['delivery'])!.value,
            notes: this.editForm.get(['notes'])!.value,
            incoterm: this.editForm.get(['incoterm'])!.value,
            toSeaPort: this.editForm.get(['toSeaPort'])!.value !== '' ? this.editForm.get(['toSeaPort'])!.value : null,
            fromSeaPort: this.editForm.get(['fromSeaPort'])!.value !== '' ? this.editForm.get(['fromSeaPort'])!.value : null,
            offerType: this.editForm.get(['offerType'])!.value.json,
            loading: this.editForm.get(['loading'])!.value,
            loadCountry: this.editForm.get(['loadCountry'])!.value !== '' ? this.editForm.get(['loadCountry'])!.value : null,
            loadCity: this.editForm.get(['loadCity'])!.value !== '' ? this.editForm.get(['loadCity'])!.value : null,
            loadDistrict: this.editForm.get(['loadDistrict'])!.value !== '' ? this.editForm.get(['loadDistrict'])!.value : null,
            loadZip: this.editForm.get(['loadZip'])!.value,
            loadAddress: this.editForm.get(['loadAddress'])!.value,
            unloading: this.editForm.get(['unloading'])!.value !== '' ? this.editForm.get(['unloading'])!.value : null,
            unloadCountry: this.editForm.get(['unloadCountry'])!.value !== '' ? this.editForm.get(['unloadCountry'])!.value : null,
            unloadCity: this.editForm.get(['unloadCity'])!.value !== '' ? this.editForm.get(['unloadCity'])!.value : null,
            unloadDistrict: this.editForm.get(['unloadDistrict'])!.value !== '' ? this.editForm.get(['unloadDistrict'])!.value : null,
            unloadZip: this.editForm.get(['unloadZip'])!.value,
            unloadAddress: this.editForm.get(['unloadAddress'])!.value,
            isPricing: this.createdActivity ? this.createdActivity.isPricing : (this.changeBuyPrice ? !this.changeBuyPrice : true),
            seaFrightBuyTariffDetMobiles: this.eqProduct,
            frontEndTariffDetDTOs: this.createdActivity ? this.createdActivity.frontEndTariffDetDTOs : this.frontTariffDetMobile.concat(this.endTariffDetMobile),
            frontEndTariffDetMobiles: this.createdActivity ? this.createdActivity.frontEndTariffDetDTOs : this.frontTariffDetMobile.concat(this.endTariffDetMobile),
            activitySeaFullEditDTO: this.createdActivity?.activitySeaFullEditDTO,
            activitySeaFullImportEditDTO: this.createdActivity?.activitySeaFullImportEditDTO,
            seaFrightRequestDetMobiles: this.seaFrightRequestDetMobiles
        };
    }

    filterCompany(event) {
        this.offerExportService.getCompany(event.query).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

    filterShipownerCompany(event) {
        this.offerExportService.getCompany(event.query, ['SO']).subscribe(res => {
            this.filteredCompanies = res.body;
        });
    }

    filterContainer(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.containers.forEach(c => {
            if (c.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(c);
            }
        });
        this.filteredContainers = filtered;
    }

    filterTransport(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.transport.forEach(country => {
            if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.transportType = filtered;
    }

    filterDistrict(event, cityId?: any) {
        if (event?.query?.length > 2) {
            this.offerExportService.getOfferExportDistrict(event.query, cityId?.id).subscribe(res => {
                this.filteredDistrictMinimized = res.body;
            });
        }
    }

    filterCity(event, countryId?: any) {
        this.offerExportService.getOfferExportCity(event.query, countryId?.id).subscribe(res => {
            this.filteredCityMinimized = res.body;
        });
    }

    filterCountry(event) {
        if (event.query.length > 2) {
            this.offerExportService.getOfferExportCountry(event.query).subscribe(res => {
                this.filteredCountryMinimized = res.body;
            });
        }
    }

    filterOffer(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.offer.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.offerType = filtered;
    }

    filterCountType(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.count.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });
        this.countType = filtered;
    }

    filterPortFilling(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.portFilling.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.portFillingType = filtered;
    }

    filterIncoterm(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.incoterm.forEach(country => {
            if (country.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });

        this.incotermType = filtered;
    }

    filterSeaport(event) {
        this.offerExportService.getOfferExportSeaport(event.query, null, 'E').subscribe(res => {
            this.filteredSeaport = res.body;
        });
    }

    filterCurrency(event) {
        this.offerExportService.getAllCurrency().subscribe(res => {
            this.currencyType = res.body;
            this.currency = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.currency.forEach(country => {
                if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                    filtered.push(country);
                }
            });
            this.currencyType = filtered;
        });
    }

    filterInvoiceItem(event) {
        this.offerExportService.invoiceItemType('SEA').subscribe(res => {
            this.invoiceItemType = res.body;
            this.invoiceItem = res.body;
            const filtered: any[] = [];
            const query = event.query;
            this.invoiceItem.forEach(country => {
                if (country.name.toLocaleLowerCase('tr-TR').indexOf(query.toLocaleLowerCase('tr-TR')) === 0) {
                    filtered.push(country);
                }
            });
            this.invoiceItemType = filtered;
        });
    }

    filterBusinessType(event) {
        const filtered: any[] = [];
        const query = event.query;
        this.business.forEach(country => {
            if (country.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(country);
            }
        });
        this.businessType = filtered;
    }

    filterOperationRepresentative(event) {
        this.offerExportService.getUserWithName(event.query, 'E').subscribe(res => {
            this.operationRepresentativeList = res.body;
        });
    }

    addLabels(label: any) {
        if(!this.stateOptions.find(f => f == label)) {
            this.stateOptions.push(label);
        }
        let opts = JSON.stringify(this.stateOptions);
        this.stateOptions = JSON.parse(opts);

    }
    /* Taşıma şekli DTD durumun seçilen il ilçelerin ülke ve il bilgisini fetch için kullanılmakta */
    onSelectUnLoadDistrict(event) {
        this.editForm.patchValue({ unloadCity: event.city });
        this.editForm.patchValue({ unloadCountry: event.country });
    }
    onSelectLoadDistrict(event) {
        this.editForm.patchValue({ loadCity: event.city });
        this.editForm.patchValue({ loadCountry: event.country });
    }
    onSelectCity(event, key) {
        this.editForm.patchValue({ [key]: event.country });
    }

    onChangeCount(container) {
        if (container.count === null) {
            container.count = 1;
        }
        /*  fiyat bilgisi sekmesinde listelenen konteynerların adet bilgisi konteyner tipine göre günceller.
            40 DC - 20 DC gibi.
        */
        this.eqProduct.forEach(eq => {
            if (!this.createdActivity) {
                eq.seaContainerRezervations.forEach(rz => {
                    if (rz.seaContainerType.name === container.seaContainerType.name && rz.tonnage === container.tonnage) {
                        rz.count = container.count;
                    }
                });
            } else {
                this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs.forEach(eq => {
                    eq.activityOperationDetailEditDTOs.forEach(rz => {
                        if (rz.seaContainerType.name === container.seaContainerType.name && rz.tonnage === container.tonnage) {
                            rz.count = container.count;
                        }
                    });
                });
            }
        });
    }

    onChangeContainerName(container, idx: number) {
        this.eqProduct.forEach(eq => {
            eq.seaContainerRezervations.forEach(rz => {
                if (rz.index === idx) {
                    rz.seaContainerType.name = container?.seaContainerType?.name;
                    rz.seaContainerType.id = container?.seaContainerType?.id;
                }
            });
        });
        this.setFrontEndTariffList();

        if(this.seaFrightRequestDetMobiles.length == 0) {
            const seaFrightRequestDetMobiles = new SeaFrightRequestDetMobiles();
            seaFrightRequestDetMobiles.customer = this.editForm.value?.customer;
            seaFrightRequestDetMobiles.fromPort = this.editForm.value?.fromSeaPort;
            seaFrightRequestDetMobiles.toPort = this.editForm.value?.toSeaPort;
            this.seaFrightRequestDetMobiles.push(seaFrightRequestDetMobiles);
        } else {
            this.seaFrightRequestDetMobiles[0].customer = this.editForm.value?.customer;
            this.seaFrightRequestDetMobiles[0].fromPort = this.editForm.value?.fromSeaPort;
            this.seaFrightRequestDetMobiles[0].toPort = this.editForm.value?.toSeaPort;
        }

        if(this.seaFrightRequestDetMobiles && this.seaFrightRequestDetMobiles.length > 0) {
            if(!this.seaFrightRequestDetMobiles[0].seaContainerRezervations.find(r => r.seaContainerType.name == container.name)) {
                const seaContainer = new SeaContainerRezervations();
                seaContainer.seaContainerType = container;
                seaContainer.fareAmount = 0;
                this.seaFrightRequestDetMobiles[0].seaContainerRezervations.push(seaContainer);
            }

        }
    }

    getUniqueContainerByType(equimenets) {
        let containers = [];

        equimenets.forEach(w => {
            /// Tüm equimenets içindeki seçili container'ları bir array de benzersiz tipe sahip olanları filtrelenmek üzere toplanır.
            const datacontainer = w.project.seaContainerRezervations.filter(e => e.selected);
            containers = containers.concat(datacontainer);
        });
        // containers da toplanan container bilgisi benzersiz olacak şekilde filtrelenir.
        // return [...new Map(containers.map(item => [item.seaContainerType.name, item])).values()];
        return [...new Map(containers.map(item => [[item.seaContainerType.name, item.tonnage].join('|'), item])).values()];
    }

    addItem() {
        (this.containerForm.get('containers') as UntypedFormArray).push(this.createContainerFormControl(null));
        if (this.eqProduct?.length && this.value1 !== 'reservation') {
            this.eqProduct.forEach(eq => {
                eq.seaContainerRezervations.push({
                    buyFareAmount: 0, count: 1, fareAmount: 0, id: null, seaTariffDetMobiles: eq.seaContainerRezervations[0].seaTariffDetMobiles,
                    seaContainerType: { name: '-', id: 0 }, tonnage: null, totalAmount: null, index: ((this.containerForm.get('containers') as UntypedFormArray).length - 1)
                });
            });
        }
        // this.ctequiments.push(this.createContainerFormControl(container));
    }

    addItemReservation() {
        (this.reservationForm.get('equipments') as UntypedFormArray).push(this.createContainerFormControl(null));
    }

    deleteItem(idx: number) {
        (this.containerForm.get('containers') as UntypedFormArray).removeAt(idx);
        if (this.eqProduct?.length && this.value1 !== 'reservation') {
            this.eqProduct.forEach(eq => {
                eq.seaContainerRezervations.splice(eq?.seaContainerRezervations?.length - 1, 1);
            });
        }
    }

    deleteItemFromReservation(idx: number) {
        (this.reservationForm.get('equipments') as UntypedFormArray).removeAt(idx);
    }

    getContainerCodeAndName(name: any) {
        return this.containers.find(e => e.name === name);
    }

    createContainerFormControl(container: any) {
        if (container !== null && container !== undefined) {
            return this.formBuilder.group({
                id: container?.id,
                seaContainerType: [container?.seaContainerType?.name ? container?.seaContainerType : this.getContainerCodeAndName(container?.name)],
                count: [container?.count || 1],
                tonnage: [container?.tonnage],
                overflow: [container?.overflow],
                length: [container?.length],
                width: [container?.width],
                height: [container?.height],
                setDegree: [container?.setDegree],
                frontTransport: this.formBuilder.group({
                    buyAmount: [0],
                    amount: [0],
                    currency: ['']
                }),
                endTransport: this.formBuilder.group({
                    buyAmount: [0],
                    amount: [0],
                    currency: ['']
                }),
            });
        } else {
            return this.formBuilder.group({
                id: null,
                seaContainerType: [''],
                count: [1],
                tonnage: [''],
                overflow: [false],
                length: [''],
                width: [''],
                height: [''],
                setDegree: [''],
                frontTransport: this.formBuilder.group({
                    buyAmount: [0],
                    amount: [0],
                    currency: ['']
                }),
                endTransport: this.formBuilder.group({
                    buyAmount: [0],
                    amount: [0],
                    currency: ['']
                }),
            });
        }
    }

    showPdf(report: any) {
        this.isLoading = true;
        this.offerExportService.getReport(report?.id, this.createdActivity[this.editDtoKey]?.offerId).subscribe(gt => {
            this.isLoading = false;
            const file = new Blob([gt], { type: 'application/pdf' });
            this.file = file;
            const fileUrl = window.URL.createObjectURL(file);
            window.open(fileUrl, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'PDF Oluşturuldu' });
        }, error => {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'PDF oluşturulamadı!' });
            this.isLoading = false;
        });
    }

    checkContainersTonnage() {
        if (this.updateConfirm !== JSON.stringify(this.createFromForm()) && this.offerUpdated !== true) {
            this.confirmationService.confirm({
                key: 'confirmMultipleCustomer',
                message: 'Teklifteki güncellemeleri kaydetmediniz.\nRezervasyon oluşturmadan önce \ngüncellemek ister misiniz?',
                header: 'DEĞİŞİKLİK KONTROL',
                icon: 'pi pi-exclamation-triangle',
                rejectLabel: 'İptal',
                acceptLabel: 'Güncelle',
                accept: () => {
                    this.offerUpdated = true;
                    this.updateRequest(true);
                },
                reject: () => {
                }
            });
        } else {
            if (!this.containerTonnageChecked) {
                (this.reservationForm.get('equipments') as UntypedFormArray).controls.forEach((ct, index) => {
                    if (ct.get(['tonnage']).dirty) {
                        if (ct.get(['tonnage']).value !== (this.containerForm.get('containers') as UntypedFormArray).controls[index].get('tonnage').value) {
                            this.confirmationService.confirm({
                                key: 'confirmMultipleCustomer',
                                message: 'Teklifte istenen konteyner tonajı ile\nrezervasyon oluşturular tonajlar farklı.',
                                header: 'KONTEYNER KONTROL',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Oluştur',
                                rejectLabel: 'İptal',
                                accept: () => {
                                    this.containerTonnageChecked = true;
                                    this.makeHardCopyReservation();
                                }
                            });
                            this.containerTonnageSame.push(true);
                        }
                    }
                });
                if (!this.containerTonnageSame.length) {
                    this.makeHardCopyReservation();
                }
            } else {
                this.makeHardCopyReservation();
            }
            this.offerUpdated = false;
        }
    }

    async makeHardCopyReservation() {
        for (let i = 0; i < this.copyCount; i++) {
            await this.makeReservation();
        }
    }

    async makeReservation() {

        if (!this.checkAllCompanies()) {
            return;
        }

        for (const item in this.reservationForm.controls) {
            if (this.reservationForm?.controls[item]?.status === 'INVALID') {
                this.reservationForm?.get(item)?.markAsDirty();
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Zorunlu ' + item.toUpperCase() + ' alan doldurulmalı!' });
            }
        }

        if (String(this.reservationForm.value?.operationNote).length > 255) {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Uyarı', detail: `Operasyon notu en fazla 255 karakter olabilir.` });
            return;
        }
        if (this.checkQuantitiyValidation()){
            return;
        }

        if (this.reservationForm.get(['senderId'])!.value === '' || this.reservationForm.get(['operationRepresentativeId'])!.value === '' || this.reservationForm?.invalid) {
            this.isLoading = false;
            return;
        } else {

            this.isLoading = true;
            if (this.ctequimentsReservation === null || this.ctequimentsReservation.length <= 0) {
                this.fillctequimentsReservationWithReservationForm();
            }

            const response = await this.offerExportService.createReservation({
                offerSeaDetailId: this.makeReservationId,
                senderId: this.reservationForm.get(['senderId'])!.value?.id,
                consigneId: this.reservationForm.get(['consigneId'])!.value?.id,
                abroadAgentId: this.reservationForm.get(['abroadAgentId'])!.value?.id,
                operationRepresentativeId: this.reservationForm.get(['operationRepresentativeId'])!.value?.id,
                whoseBusiness: this.reservationForm.get(['whoseBusiness'])!.value,
                operationNote: this.reservationForm.get(['operationNote'])!.value,
                masterOnly: this.reservationForm.get(['masterOnly'])!.value,
                salesChannel: this.reservationForm.get(['salesChannel'])!.value,
                freightPayment: this.reservationForm.get(['freightPayment'])!.value,
                equipments: this.ctequimentsReservation
            }, 'E');
            if (response.status === 200) {
                this.reReservation = true;
                this.isLoading = false;
                this.createdActivity.subType = 'confirmed';
                this.reservationId.push(JSON.parse(response.body));
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Rezervasyon Başarıyla Kaydedildi' });
                this.ctequimentsReservation = [];
            } else {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
            }
        }
    }

    fillCtequimentsWithContainerForm() {
        this.ctequiments = [];
        (this.containerForm.get('containers') as UntypedFormArray).controls.forEach(ct => {
            this.ctequiments.push({
                id: ct?.value?.id,
                containerId: ct?.value?.seaContainerType?.id,
                name: ct?.value?.seaContainerType?.name,
                tonnage: ct?.value?.tonnage,
                count: ct?.value?.count,
                overflow: ct?.value?.overflow,
                length: ct?.value?.length,
                width: ct?.value?.width,
                height: ct?.value?.height,
                setDegree: ct?.value?.setDegree,
            });
        });
    }

    fillctequimentsReservationWithReservationForm() {
        (this.reservationForm.get('equipments') as UntypedFormArray).controls.forEach(ct => {
            this.ctequimentsReservation.push({
                containerId: ct?.value?.seaContainerType?.id,
                name: ct?.value?.seaContainerType?.name,
                tonnage: ct?.value?.tonnage,
                count: ct?.value?.count,
                overflow: ct?.value?.overflow,
                length: ct?.value?.length,
                width: ct?.value?.width,
                height: ct?.value?.height,
                setDegree: ct?.value?.setDegree,
            });
        });
    }

    createOfferExport() {
        if (!this.checkValidation()) return;
        if (this.editForm?.get(['customer'])?.value !== '' && this.editForm?.get(['sender'])?.value !== '' && this.editForm?.get(['validTo'])?.value !== '') {
            this.fillCtequimentsWithContainerForm();
            this.isLoading = true;

            this.offerExportService.createActivity(this.createFromForm()).subscribe(res => {
                this.createdActivity = res.body;
                this.offerNameId.push(res.body);
                if (!this.createdActivity[this.editDtoKey]) {
                    this.editDtoKey = 'activitySeaFullImportEditDTO';
                }
                (this.containerForm.get('containers') as UntypedFormArray).clear();
                this.createdActivity.equipments.map(c => (this.containerForm.get('containers') as UntypedFormArray).push(this.createContainerFormControl(c)));
                this.eqProduct = this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs;
                this.isLoading = false;
                this.saved = true;
                this.requestSucceed = true;
                this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklif Başarıyla Kaydedildi' });
                this.offerStatus = this.createdActivity?.offerStatus;
                let exitsPrice: any;
                this.createdActivity[this.editDtoKey].seaAllocationEditDTOs.forEach(of => {
                    exitsPrice = of.activityOperationDetailEditDTOs.find(pr => pr.amount !== 0 || pr.buyAmount !== 0);
                });
                if ((this.etd || exitsPrice || this.isCopyOffer) && (this.createdActivity?.offerStatus === 'onlinequote' || this.createdActivity?.offerStatus === 'inProgressCompleted' || this.createdActivity?.offerStatus === 'confirmed' || this.createdActivity?.offerStatus === 'accepted')) {
                    this.addLabels({ label: 'priceInformation', value: 'priceInformation' });
                    this.value1 = 'priceInformation';
                }
                this.offerExportService.getReportList(res.body[this.editDtoKey]?.offerId).subscribe(rp => {
                    this.reportList = rp.body;
                });
            }, error => {
                this.isLoading = false;
                this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Doldurulan alanlar hatalı!' });
            });
        }
    }

    setFrontEndTariffList() {
        this.equimenets?.forEach(eq => {
            if (this.editForm.get(['delivery'])!.value.startsWith('D')) {
                (this.containerForm.get('containers') as UntypedFormArray).controls.forEach(ct => {
                    let exitsContainerType = this.frontTariffDetMobile.find(ft => ft?.seaContainerType?.id === ct?.value?.seaContainerType?.id && ft?.toSeaport?.id === eq?.project?.fromPort?.id);
                    if (this.createdActivity) {
                        exitsContainerType = this.createdActivity?.frontEndTariffDetDTOs.find(ft => ft?.seaContainerType?.id === ct?.value?.seaContainerType?.id && ft?.toSeaPort?.id === eq?.project?.fromPort?.id);
                    }
                    if (!exitsContainerType && ct?.get('seaContainerType')?.value?.id) {
                        this.frontTariffDetMobile.push({ buyAmount: 0, amount: 0, frontEnd: 'frontTransport', calculateUnit: 'K', name: '', supplier: null, unit: null, currency: { name: eq?.project?.currency?.name, id: eq?.project?.currency?.id }, seaContainerType: { id: ct?.get('seaContainerType')?.value?.id, name: ct?.get('seaContainerType')?.value?.name }, fromSeaport: null, toSeaport: { id: eq?.project?.fromPort?.id, name: eq?.project?.fromPort?.name } });
                        this.createdActivity?.frontEndTariffDetDTOs.push({ buyAmount: 0, amount: 0, frontEnd: 'frontTransport', calculateUnit: 'K', name: '', supplier: null, unit: null, currency: { name: eq?.project?.currency?.name, id: eq?.project?.currency?.id }, seaContainerType: { id: ct?.get('seaContainerType')?.value?.id, name: ct?.get('seaContainerType')?.value?.name }, fromSeaport: null, toSeaport: { id: eq?.project?.fromPort?.id, name: eq?.project?.fromPort?.name } });
                    }
                });
            } else {
                this.frontTariffDetMobile = [];
            }
            if (this.editForm.get(['delivery'])!.value.endsWith('D')) {
                (this.containerForm.get('containers') as UntypedFormArray).controls.forEach(ct => {
                    let exitsContainerType = this.endTariffDetMobile.find(ft => ft?.seaContainerType?.id === ct?.value?.seaContainerType?.id && ft?.fromSeaport?.id === eq?.project?.toPort?.id);
                    if (this.createdActivity) {
                        exitsContainerType = this.createdActivity?.frontEndTariffDetDTOs.find(ft => ft?.seaContainerType?.id === ct?.value?.seaContainerType?.id && ft?.fromSeaPort?.id === eq?.project?.toPort?.id);
                    }
                    if (!exitsContainerType && ct?.get('seaContainerType')?.value?.id) {
                        this.endTariffDetMobile.push({ buyAmount: 0, amount: 0, frontEnd: 'endTransport', calculateUnit: 'K', name: '', supplier: null, unit: null, currency: { name: eq?.project?.currency?.name, id: eq?.project?.currency?.id }, seaContainerType: { id: ct?.get('seaContainerType')?.value?.id, name: ct?.get('seaContainerType')?.value?.name }, fromSeaport: { id: eq?.project?.toPort?.id, name: eq?.project?.toPort?.name }, toSeaport: null });
                        this.createdActivity?.frontEndTariffDetDTOs.push({ buyAmount: 0, amount: 0, frontEnd: 'endTransport', calculateUnit: 'K', name: '', supplier: null, unit: null, currency: { name: eq?.project?.currency?.name, id: eq?.project?.currency?.id }, seaContainerType: { id: ct?.get('seaContainerType')?.value?.id, name: ct?.get('seaContainerType')?.value?.name }, fromSeaport: { id: eq?.project?.toPort?.id, name: eq?.project?.toPort?.name }, toSeaport: null });
                    }
                });
            } else {
                this.endTariffDetMobile = [];
            }
        });
    }

    getFrontEndTariffDetMobileFront(type: string) {
        this.equimenets.forEach(eq => {
            if (this.editForm?.get(['loadDistrict'])?.value?.id && eq?.project?.fromPort?.id) {
                this.offerExportService.getFrontEndTariffDetMobileFront(type, this.editForm?.get(['loadDistrict'])?.value.id, eq.project.fromPort.id, 'SEA', this.getSeaContainerTypeIds(),
                    this.datepipe.transform((this.editForm?.get(['validTo'])?.value === '' ? Date.now() : this.editForm?.get(['validTo'])?.value), 'yyyy-MM-dd')).subscribe(res => {
                        const list: any[] = [];
                        res.body.forEach(tff => {
                            const exitstariff = this.frontTariffDetMobileChosen.find(al => al?.id === tff.id);
                            if (!exitstariff) {
                                list.push(tff);
                            }
                        });
                        this.frontTariffDetMobileChosen = this.frontTariffDetMobileChosen.concat(list);
                    });
            }
        });
    }

    getFrontEndTariffDetMobileEnd(type: string) {
        this.equimenets.forEach(eq => {
            if (this.editForm?.get(['unloadDistrict'])?.value?.id && eq?.project?.toPort?.id) {
                this.offerExportService.getFrontEndTariffDetMobileEnd(type, this.editForm?.get(['unloadDistrict'])?.value?.id, eq.project.toPort.id, 'SEA', this.getSeaContainerTypeIds(),
                    this.datepipe.transform((this.editForm?.get(['validTo'])?.value === '' ? Date.now() : this.editForm?.get(['validTo'])?.value), 'yyyy-MM-dd')).subscribe(res => {
                        const list: any[] = [];
                        res.body.forEach(tff => {
                            const exitstariff = this.endTariffDetMobileChosen.find(al => al?.id === tff.id);
                            if (!exitstariff) {
                                list.push(tff);
                            }
                        });
                        this.endTariffDetMobileChosen = this.endTariffDetMobileChosen.concat(list);
                    });
            }
        });
    }

    addSelectedFrontEndTariff() {
        const frontList = [];
        const endList = [];
        this.selectedFrontCarryTariff.forEach(sc => {
            // Basetariff without id, exitsTariff has an id
            let baseTariff = this.frontTariffDetMobile.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0);
            if (baseTariff !== -1) {
                this.frontTariffDetMobile.splice(baseTariff, 1);
            }
            let exitsTariff = this.frontTariffDetMobile.find(fr => fr?.id === sc.id);
            if (!exitsTariff) {
                frontList.push(sc);
            }
            if (this.createdActivity) {
                baseTariff = this.createdActivity?.frontEndTariffDetDTOs.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0 && fr?.frontEnd === 'frontTransport');
                if (baseTariff !== -1) {
                    this.createdActivity?.frontEndTariffDetDTOs.splice(baseTariff, 1);
                }
                exitsTariff = this.createdActivity?.frontEndTariffDetDTOs.find(fr => fr?.id === sc.id);
                if (!exitsTariff) {
                    sc.buyCurrency = sc.currency;
                    this.createdActivity?.frontEndTariffDetDTOs.push(sc);
                }
            }
        });
        this.selectedEndCarryTariff.forEach(sc => {
            let baseTariff = this.endTariffDetMobile.findIndex(fr => fr?.fromSeaport?.id === sc?.fromSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0 && fr?.frontEnd === 'endTransport');
            if (baseTariff !== -1) {
                this.endTariffDetMobile.splice(baseTariff, 1);
            }
            let exitsTariff = this.endTariffDetMobile.find(fr => fr?.id === sc.id);
            if (!exitsTariff) {
                endList.push(sc);
            }
            if (this.createdActivity) {
                baseTariff = this.createdActivity?.frontEndTariffDetDTOs.findIndex(fr => fr?.toSeaport?.id === sc?.toSeaport?.id && fr?.seaContainerType?.id === sc?.seaContainerType?.id && fr?.buyAmount === 0 && fr?.amount === 0);
                if (baseTariff !== -1) {
                    this.createdActivity?.frontEndTariffDetDTOs.splice(baseTariff, 1);
                }
                exitsTariff = this.createdActivity?.frontEndTariffDetDTOs.find(fr => fr?.id === sc.id);
                if (!exitsTariff) {
                    sc.buyCurrency = sc.currency;
                    this.createdActivity?.frontEndTariffDetDTOs.push(sc);
                }
            }
        });
        this.frontTariffDetMobile = this.frontTariffDetMobile.concat(frontList);
        this.endTariffDetMobile = this.endTariffDetMobile.concat(endList);

        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Tarifeler eklendi.' });
        this.displayFrontEndCarryList = false;
    }

    getSeaContainerTypeIds() {
        const seaContainerTypeIds: number[] = [];
        (this.containerForm.get('containers') as UntypedFormArray).controls.forEach(ct => {
            seaContainerTypeIds.push(ct.value.seaContainerType.id);
        });
        return seaContainerTypeIds;
    }

    onSelectLoadDistrict2(event) {
        this.createCustomerForm.patchValue({ cityId: event.city });
        this.createCustomerForm.patchValue({ countryId: event.country });
    }

    onSelectLoadCity2(event) {
        this.createCustomerForm.patchValue({ countryId: event.country });
    }

    rejectAllOffer(substatus?: string, description?: string) {
        this.isLoading = true;
        const updateOfferStatusDTO: UpdateOfferStatusDTO[] = [];
        this.createdActivity[this.editDtoKey].seaAllocationEditDTOs?.forEach(of => {
            updateOfferStatusDTO.push({
                ...new UpdateOfferStatusDTO(),
                status: 'rejected',
                substatus: substatus,
                offerSeaDetailId: of.offerSeaDetailId,
                description
            });
        });
        this.offerExportService.updateOfferStatus(updateOfferStatusDTO, 'E').subscribe(res => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Teklifler başarıyla reddedildi.' });
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Teklifler reddedilirken hata oluştu!' });
        });
    }

    createCopyOffer() {
        this.route.navigate(['activities/offer/create'], { state: { activity: this.createdActivity, isCopyOffer: true, changeBuyPrice: true } });
    }

    setSeaFrightBuyTariffDetMobiles(amount: any, tarifIndex: number, detMobileIndex: number, sellOrBuy: string) {
        if (sellOrBuy === 'sell') {
            this.eqProduct[tarifIndex].seaContainerRezervations.forEach(detM => {
                detM.seaTariffDetMobiles[detMobileIndex].amount = +amount.target?.value;
            });
        } else {
            this.eqProduct[tarifIndex].seaContainerRezervations.forEach(detM => {
                detM.buyOfferSeaChargesDTOs[detMobileIndex].amount = +amount.target?.value;
            });
        }
    }

    onFocusOutCheckInputIsEmpty(name: string) {
        if (this.editForm?.get([name])?.value?.name === undefined) {
            this.editForm?.controls[name]?.setValue('');
        }
    }
    // Deprecated
    // calculatorBuyAllin(eq: any) {
    //     let totalAllin = 0;
    //     eq.seaContainerRezervations[0]?.seaTariffDetMobiles?.forEach(rz => {
    //         if (rz.ctype === 'perContainer') {
    //             totalAllin += rz.buyAmount;
    //         }
    //         if (rz.ctype === 'perHouse') {
    //             totalAllin += rz.buyAmount * eq.seaContainerRezervations?.length;
    //         }
    //     });
    //     eq.seaContainerRezervations?.forEach(rz => {
    //         totalAllin += rz?.buyFareAmount;
    //     });
    //     return totalAllin;
    // }

    cleanDialog() {
        this.isInvoiceItem = false;
        this.invoice = '';
        this.invoiceCurrency = '';
        this.invoiceBuyamount = 0;
        this.invoiceAmount = 0;
        this.isFreightCharge = false;
        this.itemCompany = undefined;
        this.invoiceCountType = this.count[0];
    }

    addSeaTariffDetMobiles(armatorNumber: number) {
        if (this.itemCompany === '' || !this.itemCompany || !this.itemCompany?.id) {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Müşteri Seçiniz!' });
            return;
        }
        if (this.invoice !== undefined && this.invoice !== '' && this.invoiceCountType !== undefined && this.invoiceCountType.name !== '' && this.invoiceCurrency !== undefined && this.invoiceCurrency?.name !== '') {
            if (this.createdActivity) {
                if (this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].buyOfferSeaChargesDTOs === (null || '') && this.sellOrBuyInvoice === true) {
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].buyOfferSeaChargesDTOs = [];
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].buyOfferSeaChargesDTOs.push({ amount: this.invoiceBuyamount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                } else if (this.sellOrBuyInvoice === true) {
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].buyOfferSeaChargesDTOs.push({ amount: this.invoiceBuyamount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                }
                if (this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].offerSeaChargesDTOs === (null || '') && this.sellOrBuyInvoice === false) {
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].offerSeaChargesDTOs = [];
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].offerSeaChargesDTOs.push({ amount: this.invoiceAmount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                } else if (this.sellOrBuyInvoice === false) {
                    this.createdActivity[this.editDtoKey].seaAllocationEditDTOs[armatorNumber].offerSeaChargesDTOs.push({ amount: this.invoiceAmount, invoiceType: this.invoiceType, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                }
            } else {
                this.equimenets[armatorNumber].project.seaContainerRezervations.forEach(tf => {
                    if (tf?.seaTariffDetMobiles?.length) {
                        tf?.seaTariffDetMobiles.push({ amount: this.invoiceAmount, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, invoiceType: this.invoiceType, buyAmount: this.invoiceBuyamount, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                    } else {
                        tf.seaTariffDetMobiles = [];
                        tf?.seaTariffDetMobiles.push({ amount: this.invoiceAmount, sellOrBuyInvoice: this.sellOrBuyInvoice, itemCompany: this.itemCompany, invoiceType: this.invoiceType, buyAmount: this.invoiceBuyamount, calculateType: this.invoiceCountType?.json, ctype: this.invoiceCountType?.json, currency: { id: this.invoiceCurrency?.id, name: this.invoiceCurrency?.name }, invoiceItemType: { id: this.invoice?.id, name: this.invoice?.name }, isFreightCharge: this.isFreightCharge, isNew: true });
                    }
                });
            }
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: 'Hata', detail: 'Ek masraf eklenemedi!' });
        }
        this.cleanDialog();
    }

    openTheCopy(id: number) {
        window.open(getbaseUrl() + '/activities/offer-export-detail/' + id, '_blank', 'location=yes,height=600,width=800,scrollbars=yes,status=yes');
    }

    calculatorSellAllin(eq: any) {
        let totalAllin = 0;
        if (this.createdActivity) {
            eq.activityOperationDetailEditDTOs.forEach(ct => {
                totalAllin += ct?.amount * ct?.count;
            });
        } else {
            eq.seaContainerRezervations?.forEach(rz => {
                totalAllin += rz?.fareAmount * rz?.count;
            });
        }
        return totalAllin;
    }

    showCreateCustomerDialog() {
        const dialogRef = this.dialog.open(CreateCustomerComponent, {
            panelClass: 'modal-md',
            width: '1000px',
            data: {}
        });
    }

    filterDetTariffIsFreight(tariff: any, trueOrFalse: boolean, buyOrsell: boolean) {
        if (trueOrFalse === true) {
            if (this.createdActivity) {
                return tariff?.filter(tff => tff?.isFreightCharge === true);
            } else {
                return tariff?.filter(tff => tff?.isFreightCharge === true && tff?.sellOrBuyInvoice === buyOrsell);
            }
        } else {
            if (this.createdActivity) {
                return tariff?.filter(tff => tff?.isFreightCharge !== true);
            } else {
                return tariff?.filter(tff => tff?.isFreightCharge !== true && tff?.sellOrBuyInvoice === buyOrsell);
            }
        }
    }

    resetReservationForm() {
        this.reReservation = false;
        this.ctequimentsReservation = [];
        this.reservationForm.patchValue({
            senderId: '',
            consigneId: '',
            abroadAgentId: '',
            operationRepresentativeId: '',
            whoseBusiness: '',
            operationNote: '',
            salesChannel: '',
            freightPayment: '',
            masterOnly: false
        });
    }

    deleteOfferSeaChargeWithId(id: number, deleteObject?: any, buyOrSell?: string, index?: number) {
        this.isLoading = true;
        console.log('id', id);
        if (this.createdActivity) {
            if (id === undefined) {
                if (buyOrSell === 'buy') {
                    deleteObject?.buyOfferSeaChargesDTOs.splice(deleteObject?.buyOfferSeaChargesDTOs.findIndex(q => q.id === undefined), 1);
                } else {
                    deleteObject?.offerSeaChargesDTOs.splice(index, 1);
                }
                this.isLoading = false;
            } else {
                this.offerExportService.deleteOfferSeaChargeWithId(id, 'E').subscribe(res => {
                    this.messageService.add({ key: 'tst', severity: 'success', summary: 'Başarılı', detail: 'Ek masraf silindi!' });
                    if (buyOrSell === 'buy') {
                        deleteObject?.buyOfferSeaChargesDTOs.splice(deleteObject?.buyOfferSeaChargesDTOs.findIndex(q => q.id === id), 1);
                    } else {
                        deleteObject?.offerSeaChargesDTOs.splice(deleteObject?.offerSeaChargesDTOs.findIndex(q => q.id === id), 1);
                    }
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    this.messageService.add({ key: 'messageSeaList', severity: 'warn', summary: 'Hata', detail: error.error ? error.error.message : error.message });
                });
            }
        } else {
            deleteObject?.seaContainerRezervations[0]?.seaTariffDetMobiles.splice(deleteObject?.seaContainerRezervations[0]?.seaTariffDetMobiles.findIndex(q => q.id === id), 1);
            this.isLoading = false;
        }
    }

    changeAmountWithCount(event: any, rz: any, buyOrSell: string) {
        if (buyOrSell === 'buy') {
            rz.buyAmount = event * rz.count;
        }
        if (buyOrSell === 'sell') {
            if (!this.createdActivity) {
                rz = event;
            } else {
                rz.amount = event * rz.count;
            }
        }
    }

    updateTransportType() {
        this.setFrontEndTariffList();
        if (this.createdActivity?.delivery !== this.editForm.get(['delivery'])!.value) {
            if (!this.createdActivity.frontEndTariffDetDTOs?.length) {
                this.createdActivity.frontEndTariffDetDTOs = this.frontTariffDetMobile.concat(this.endTariffDetMobile);
            }
        }
        this.editForm.get('delivery').value.startsWith('D') ? this.editForm.get('loadCountry')?.setValidators([Validators.required]) : this.editForm.get('loadCountry')?.clearValidators();
        this.editForm.get('delivery').value.startsWith('D') ? this.editForm.get('loadCity')?.setValidators([Validators.required]) : this.editForm.get('loadCity')?.clearValidators();
        this.editForm.get('delivery').value.endsWith('D') ? this.editForm.get('unloadCountry')?.setValidators([Validators.required]) : this.editForm.get('unloadCountry')?.clearValidators();
        this.editForm.get('delivery').value.endsWith('D') ? this.editForm.get('unloadCity')?.setValidators([Validators.required]) : this.editForm.get('unloadCity')?.clearValidators();
        this.editForm.get('loadCountry')?.updateValueAndValidity();
        this.editForm.get('loadCity')?.updateValueAndValidity();
        this.editForm.get('unloadCountry')?.updateValueAndValidity();
        this.editForm.get('unloadCity')?.updateValueAndValidity();
    }

    async view(item: any) {
        // SeaGood Detail
        const resp = await this.seaGoodMobileSearch({ 'seaGoodId': item.id })
        this.displaySeaGoodDetail = true;
        this.showPreviousBtn = false;
        this.listResultItem = resp['datas'][0]
        if (this.listResultItem) this.showDetailDialog = true;
    }

    async seaGoodMobileSearch(param) {
        let response = [];
        await this.listService.goodMobileSearch(param, '/sea/list/seaGoodMobileList/listSearch?name=seaGoodMobileList&query=', 'E').toPromise().then(res => {
            response = res.body;
        });
        return response;
    }

    onCloseDetail() {
        this.showDetailDialog = false;
    }

    changeFormRequired() {
        if (!this.reservationForm?.get(['masterOnly'])?.value) {
            this.reservationForm?.get(['abroadAgentId']).setValidators([Validators.required]);
            this.reservationForm?.get(['abroadAgentId']).updateValueAndValidity();
        } else {
            this.reservationForm?.get(['abroadAgentId']).clearValidators();
            this.reservationForm?.get(['abroadAgentId']).updateValueAndValidity();
        }
    }

    onUpdateStatus(updateStatus = null, activity = this.createdActivity,) {
        this.isLoading = true;
        const payloadUpdateStatus = [];
        activity[this.editDtoKey]?.seaAllocationEditDTOs.forEach(s => {
            payloadUpdateStatus.push({
                offerSeaDetailId: s.offerSeaDetailId,
                status: updateStatus,
                substatus: null,
                description: 'Status update',
            });
        });
        this.offerExportService.updateOfferStatus(payloadUpdateStatus, 'E').subscribe(
            data => {
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
            }
        )
    }

    deleteEquipments(index: any) {
        (this.containerForm.get('containers') as UntypedFormArray).removeAt(index);
    }
    checkAllCompanies() {

        if (this.createdActivity?.customer?.status !== 'A') {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Seçilen müşterinin muhasebe kaydı bulunmamaktadır.' });
            return false;
        }

        const eq = this.createdActivity ? this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs : this.eqProduct;
        const allBuyCharges: any[] = [];
        const allSellCharges: any[] = [];
        eq.forEach(data => this.createdActivity ? allBuyCharges.push(...data?.buyOfferSeaChargesDTOs) : allBuyCharges.push(...eq?.seaContainerRezervations[0]?.seaTariffDetMobiles));
        eq.forEach(data => this.createdActivity ? allSellCharges.push(...data?.offerSeaChargesDTOs) : allSellCharges.push(...eq?.seaContainerRezervations[0]?.seaTariffDetMobiles));
        console.log(allBuyCharges, 'invalidDatas', allSellCharges);

        const invalidBuyDatas = allBuyCharges.filter(data => data.itemCompany?.status && data.itemCompany?.status !== 'A');
        const invalidSellDatas = allSellCharges.filter(data => data.itemCompany?.status && data.itemCompany?.status !== 'A');


        if (invalidBuyDatas.length > 0) {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Alış Ek Masraflardaki seçilen Müşterilerin Muhasebe kaydı yoktur.' });
            return false;
        }

        if (invalidSellDatas.length > 0) {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Satış Ek Masraflardaki seçilen Müşterilerin Muhasebe kaydı yoktur.' });
            return false;
        }
        return true;
    }

    checkCompany(company: Company) {
        if (company?.name === undefined) {
            return null;
        }
        if (company.status !== 'A') {
            return company;
        }
        return company;
    }

    showStatusStory(offerId: number) {
        this.offerExportService.getAllOfferStatusByOfferId(offerId, 'E').subscribe(res => {
            this.offerStatusHistory = res.body;
        }, error => {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Teklif durumu henüz değişmemiş' });
        });
    }

    isSellAmountEmpty() {
        let isAmountEmpty = [];
        this.createdActivity[this.editDtoKey]?.seaAllocationEditDTOs?.forEach(s => {
            if (!s.totalAmount && !s.totalChargeAmount) {
                isAmountEmpty.push(s);
            }
        });
        return isAmountEmpty.length > 0;
    }

    checkSellAmounts(event: any) {
        if (event.option.label == 'reservation' && this.isSellAmountEmpty()) {
            this.messageService.add({ key: 'tst', severity: 'warning', summary: 'Uyarı', detail: 'Satış Tutarları girilmemiş' })
            setTimeout(() => this.value1 = 'priceInformation', 200);
        }

    }

    createRequestDetMobile() {
        this.seaFrightRequestDetMobiles.push(this.newSeaFrightRequestDetMobiles(null));

    }
}
