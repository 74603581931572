import { Company } from "./company.model";
import { SeaPort } from "./seaport.model";

export class Allocation {
    id: number;
    voyage: string;
    description: string;
    etd: Date;
    eta: Date;
    cutoffDate: string;
    lineAgent: Company;
    agent: Company;
    vessel: any;
    fromPort: SeaPort;
    toPort: SeaPort;
    referenceNo: string;
    whoseBusiness: string;

    allocation: number = 0;
    reserved: number = 0;
    shipped: number = 0;
    freeSpace: number = 0;
    seaFreightTariffDetMobiles: seaFreightTariffDetMobiles[] = [];
}

export class ShipOwner {
    id: number = null;
    name: string = '';
}

export class FromPort {
    id: number = null;
    name: string = '';
}

export class ToPort {
    id: number = null;
    name: string = '';
}

export class Currency {
    id: number = null;
    name: string = '';
}

export class SeaContainerType {
    id: number = null;
    name: string = '';
}

export class SeaContainerRezervation {
    id: number = 0;
    fareAmount: number = 0;
    buyFareAmount?: any = null;
    tonnage?: any = null;
    totalAmount?: any = null;
    seaContainerType: SeaContainerType = null;
    seaTariffDetMobiles?: any = null;
}

export class seaFreightTariffDetMobiles {
    id: number = null;
    vessel?: any = null;
    cutOffDate?: any = null;
    seaAllocationId: number = 0;
    freeTime?: number = null;
    amount?: any = null;
    buyAmount?: any = null;
    shipOwner: ShipOwner = null;
    fromPort: FromPort = null;
    toPort: ToPort = null;
    currency: Currency = null;
    seaContainerRezervations: SeaContainerRezervation[] = [];
}